/**
 * This file centralizes all routing-related logic.
 * Keeping route handling in one place ensures consistent navigation behavior
 * and makes maintenance/updates easier across the application.
 */

import { TaskSTATUS } from 'protos/pb/v1alpha2/tasks_service';
import { APP_ROUTES } from './Routes';
import { ArchivedResourceType, TASKS_TAB_INDEX } from '../../utils/constants';
import { WORKFLOW_PAGE } from '../MyWorkflows';

const COLLAPSIBLE_PATH_PATTERNS = [
  /^\/(?:organizations\/[^/]+\/)?executions\//,
  /^\/(?:organizations\/[^/]+\/)?workflow\//,
  /^\/(?:organizations\/[^/]+\/)?workflows\//,
  /^\/(?:organizations\/[^/]+\/)?workflows\/[^/]+\/tasks\/[^/]+\/automation-review$/,
  /^\/(?:organizations\/[^/]+\/)?dev-testing\/document\//,
  /^\/(?:organizations\/[^/]+\/)?orbot_workflow_tasks\//,
  /^\/(?:organizations\/[^/]+\/)?process-discovery\//,
];

export const shouldCollapseSideDrawer = (pathname: string) => {
  // Check if the pathname matches any dynamic pattern using regular expressions
  return COLLAPSIBLE_PATH_PATTERNS.some((pattern) => pattern.test(pathname));
};

const getPathAccToTaskStatus = (status: TaskSTATUS): string => {
  if (
    [
      TaskSTATUS.REJECTED_INCORRECT,
      TaskSTATUS.REJECTED_ALREADY_COMPLETED,
      TaskSTATUS.OBSOLETE, // System Declined
    ].includes(status)
  ) {
    return `${APP_ROUTES.TASKS}?tab=${TASKS_TAB_INDEX.DECLINED_TAB}`;
  } else if ([TaskSTATUS.ACCEPTED, TaskSTATUS.COMPLETED].includes(status)) {
    return `${APP_ROUTES.TASKS}?tab=${TASKS_TAB_INDEX.COMPLETED_TAB}`;
  } else if ([TaskSTATUS.READY, TaskSTATUS.CREATED].includes(status)) {
    return `${APP_ROUTES.TASKS}?tab=${TASKS_TAB_INDEX.PENDING_TAB}`;
  }
  return '';
};

export const getPathAccToResource = (
  resourceType: ArchivedResourceType,
  status?: TaskSTATUS,
) => {
  switch (resourceType) {
    case ArchivedResourceType.TASK: {
      return status && TaskSTATUS[status] ? getPathAccToTaskStatus(status) : '';
    }
    case ArchivedResourceType.CONNECTOR: {
      return `${APP_ROUTES.WORKFLOW}?tab=${WORKFLOW_PAGE.CONNECTOR_TAB_INDEX}`;
    }
    case ArchivedResourceType.UI_EXECUTION: {
      return `${APP_ROUTES.WORKFLOW}?tab=${WORKFLOW_PAGE.UI_EXECUTION_TAB_INDEX}`;
    }
    default: {
      return `${APP_ROUTES.WORKFLOW}?tab=${WORKFLOW_PAGE.API_AUTOMATION_TAB_INDEX}`;
    }
  }
};

export const getPathForTaskDetailsPage = (
  taskResourceName: string,
  generateOrgPath: (path: string, params?: Record<string, string>) => string,
  isOrbotTask = false,
) => {
  const [, workflowId, , taskId] = taskResourceName.split('/');
  // If it's an orbot task, use the HITL_DETAILS route
  if (isOrbotTask) {
    return generateOrgPath(APP_ROUTES.HITL_DETAILS, {
      task_id: taskId,
      workflow_id: workflowId,
    });
  }

  // For regular tasks, use the new TASK_REVIEW route
  return generateOrgPath(APP_ROUTES.TASK_REVIEW, {
    task_id: taskId,
    workflow_id: workflowId,
  });
};
