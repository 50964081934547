/* eslint-disable */
// @ts-nocheck
import _m0 from "protobufjs/minimal";
import { Hyperparameter } from "../../common/hyperparameter";
import { messageTypeRegistry } from "../../typeRegistry";
import { Document } from "./document";
import { Field, FieldGroup } from "./field";
import { Function } from "./function";
import { UiState } from "./orbot_assets";
import { Variable, VariableType, VariableValue } from "./variables";

export const protobufPackage = "pb.v1alpha1";

/**
 * Extract fields from a document.
 * Currently only used in document processing.
 */
export interface ExtractFields {
  $type?: "pb.v1alpha1.ExtractFields";
  /**
   * Deprecated: Use the `source` field instead.
   *
   * @deprecated
   */
  fields?:
    | Field[]
    | undefined;
  /** @deprecated */
  document?: Document | undefined;
  rule?: string | undefined;
  source?: ItemDocument | undefined;
}

/** Extract fields from a document. */
export interface ExtractFieldsRequestUnified {
  $type?: "pb.v1alpha1.ExtractFieldsRequestUnified";
  rule?: string | undefined;
  source?: ExtractionDocument | undefined;
}

/** Extracted fields from a document. */
export interface ExtractFieldsResult {
  $type?: "pb.v1alpha1.ExtractFieldsResult";
  results?:
    | ExtractFieldsResultPerFieldResult[]
    | undefined;
  /**
   * GCS file path for the DocumentAI document Protobuf binary file, which is
   * used in the following places:
   * 1. showing location for the extracted entities in the docprocessing HITL UI
   * 2. used as the input format for the current few-shot learning pipline
   *
   * The file is generated on the ML side, gets persisted in a temporary ML GCS
   * bucket. When it gets passed back on the server side, we would copy it to a
   * permanent GCS location. We also update this field to the permanent GCS path
   * before saving it to MongoDB and then get populated in ClickHouse. For ML
   * few-shot learning, we query the ClickHouse and read the file from this
   * updated field.
   *
   * NOTE that the extracted entities information are duplicated between this
   * file and ExtractedFieldsResult.results. We need to make sure the information
   * is in sync in two places:
   * 1. when generating the protobuf files on the ML side.
   * 2. when updating the fields on the HITL UI, we need to update both the
   *    results field and the protobuf file.
   * The main reasons we are duplicating the information are that we have more
   * control over our Protobuf messages, and we may consider moving away from
   * the DocumentAI messages in the future.
   */
  docaiProtoPath?: string | undefined;
}

/** new format to account for per-field confidence scores */
export interface ExtractFieldsResultPerFieldResult {
  $type?: "pb.v1alpha1.ExtractFieldsResult.PerFieldResult";
  extractedField?:
    | Field
    | undefined;
  /** per-field confidence score */
  confidence?: number | undefined;
}

/** Extracted fields from a document. */
export interface ExtractFieldsResultUnified {
  $type?: "pb.v1alpha1.ExtractFieldsResultUnified";
  result?: ExtractionDocument | undefined;
}

/**
 * Validate the values between form data and a document.
 * Currently only used in document processing.
 */
export interface ValidateFieldValues {
  $type?: "pb.v1alpha1.ValidateFieldValues";
  /**
   * Deprecated: Use the `source` and `target` fields instead.
   *
   * @deprecated
   */
  document?:
    | Document
    | undefined;
  /** @deprecated */
  fields?: Field[] | undefined;
  rule?:
    | string
    | undefined;
  /**
   * Source is considered as the ground truth for the validation
   * It may contain additional fields as extra context
   */
  source?:
    | Item
    | undefined;
  /**
   * Target is the data to be validated
   * It may contain additional fields as extra context
   */
  target?:
    | Item
    | undefined;
  /** List of field names to be validated */
  validateFields?: string[] | undefined;
}

/** Result of validation between form data and a document. */
export interface ValidateFieldValuesResult {
  $type?: "pb.v1alpha1.ValidateFieldValuesResult";
  results?:
    | ValidateFieldValuesResultPerFieldResult[]
    | undefined;
  /**
   * Keep the following global fields to be backward-compatible
   * TODO: remove the fields after all installations are upgraded
   */
  status?: ValidateFieldValuesResultStatus | undefined;
  confidence?: number | undefined;
  explanation?: string | undefined;
  extractedFields?: Field[] | undefined;
}

export enum ValidateFieldValuesResultStatus {
  UNSPECIFIED = 0,
  MATCH = 1,
  MISMATCH = 2,
  UNRECOGNIZED = -1,
}

export function validateFieldValuesResultStatusFromJSON(object: any): ValidateFieldValuesResultStatus {
  switch (object) {
    case 0:
    case "STATUS_UNSPECIFIED":
      return ValidateFieldValuesResultStatus.UNSPECIFIED;
    case 1:
    case "MATCH":
      return ValidateFieldValuesResultStatus.MATCH;
    case 2:
    case "MISMATCH":
      return ValidateFieldValuesResultStatus.MISMATCH;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ValidateFieldValuesResultStatus.UNRECOGNIZED;
  }
}

export function validateFieldValuesResultStatusToJSON(object: ValidateFieldValuesResultStatus): string {
  switch (object) {
    case ValidateFieldValuesResultStatus.UNSPECIFIED:
      return "STATUS_UNSPECIFIED";
    case ValidateFieldValuesResultStatus.MATCH:
      return "MATCH";
    case ValidateFieldValuesResultStatus.MISMATCH:
      return "MISMATCH";
    case ValidateFieldValuesResultStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ValidateFieldValuesResultPerFieldResult {
  $type?: "pb.v1alpha1.ValidateFieldValuesResult.PerFieldResult";
  extractedField?: Field | undefined;
  status?:
    | ValidateFieldValuesResultStatus
    | undefined;
  /** Text explaining why the extracted value does not match. */
  explanation?:
    | string
    | undefined;
  /** per-field confidence score */
  confidence?: number | undefined;
}

/** Flag the given keywords in documents and form fields. */
export interface FlagKeywords {
  $type?: "pb.v1alpha1.FlagKeywords";
  keywords?: string[] | undefined;
  fieldGroups?: FieldGroup[] | undefined;
  documents?: Document[] | undefined;
  rule?: string | undefined;
}

/** Keywords flagged. */
export interface FlagKeywordsResult {
  $type?: "pb.v1alpha1.FlagKeywordsResult";
  results?:
    | FlagKeywordsResultResult[]
    | undefined;
  /** TODO: remove it after all installations are upgraded */
  detectedKeywords?: string[] | undefined;
}

/** For keywords detected in field groups, we return the per-field result */
export interface FlagKeywordsResultFieldResult {
  $type?: "pb.v1alpha1.FlagKeywordsResult.FieldResult";
  fieldGroupIndex?: number | undefined;
  fieldIndex?: number | undefined;
  detectedKeywords?: string[] | undefined;
}

/** For keywords detected in a document, we return the per-page results. */
export interface FlagKeywordsResultDocumentResult {
  $type?: "pb.v1alpha1.FlagKeywordsResult.DocumentResult";
  documentIndex?:
    | number
    | undefined;
  /** 1-based index similar to DocAI */
  pageNumber?: number | undefined;
  detectedKeywords?: string[] | undefined;
}

export interface FlagKeywordsResultResult {
  $type?: "pb.v1alpha1.FlagKeywordsResult.Result";
  fieldResult?: FlagKeywordsResultFieldResult | undefined;
  documentResult?: FlagKeywordsResultDocumentResult | undefined;
}

/**
 * A single item can contain one or more (structured) field groups, and one or
 * more (unstructured) documents. The field groups can either represent one or
 * more HTML forms, or one or more rows in a data table.
 *
 * This aims to be a generic data structure that can be used in a wide range of
 * applications.
 *
 * For example, an expense item can have the following
 *   1. fields from user provided form
 *   2. fields provided by the credit card system
 *   3. user submitted receipt files
 * each of the them can contain some information about the expense, such as
 * merchant name, amount, some identifier etc.
 *
 * Another example is for purchasing order reconciliation, where an item can
 * contain a data table that contains line items and a optional PDF document
 * (such as receipt PDF).
 */
export interface Item {
  $type?: "pb.v1alpha1.Item";
  /** it can come from one or more forms, or rows from a single HTML table */
  fieldGroups?: FieldGroup[] | undefined;
  documents?: ItemDocument[] | undefined;
  smartActionRecords?: SmartActionRequestAndResult[] | undefined;
}

/**
 * Set of entities. Some can be partially filled and some needed to be extracted
 * from  the attached documents.
 */
export interface EnrichEntitiesRequest {
  $type?: "pb.v1alpha1.EnrichEntitiesRequest";
  /** Already extracted entities. */
  fieldGroup?:
    | FieldGroup
    | undefined;
  /**
   * List of documents and fields to extract in each. Different fields can come from
   * different documents.
   */
  documents?: ExtractionDocument[] | undefined;
}

/** A supporting document for an item which contains metadata */
export interface ItemDocument {
  $type?: "pb.v1alpha1.ItemDocument";
  document?:
    | Document
    | undefined;
  /** List of fields we expect to extract and use for reconciliation from document */
  fields?: Field[] | undefined;
}

/**
 * Represents a document unit as a whole, containing other related infrmation like
 * the expected schema for extraction, and the extracted entities.
 */
export interface ExtractionDocument {
  $type?: "pb.v1alpha1.ExtractionDocument";
  /** The document to extract from. */
  document?:
    | Document
    | undefined;
  /** Extracted fields from the document. */
  fieldGroup?: FieldGroup | undefined;
}

export interface SmartActionRequestAndResult {
  $type?: "pb.v1alpha1.SmartActionRequestAndResult";
  request?: ProcessSmartActionsRequest | undefined;
  result?: SmartActionResult | undefined;
}

export interface FieldGroupMatch {
  $type?: "pb.v1alpha1.FieldGroupMatch";
  match?: FieldGroupMatchMatchedFieldGroup | undefined;
  unmatchedSource?: FieldGroupMatchUnmatchedFieldGroup | undefined;
  unmatchedTarget?: FieldGroupMatchUnmatchedFieldGroup | undefined;
  confidence?:
    | number
    | undefined;
  /** natural language explanation for the match/unmatch prediction. */
  explanation?: string | undefined;
}

/**
 * depending on the use case, there might be 1:N mapping to N:1 mapping between
 * source groups and target groups. For those cases, there would be N entries
 * of the FiledGroupMatch, each with it's own confidence score.
 */
export interface FieldGroupMatchMatchedFieldGroup {
  $type?: "pb.v1alpha1.FieldGroupMatch.MatchedFieldGroup";
  sourceIndex?: number | undefined;
  targetIndex?:
    | number
    | undefined;
  /** optionally, we can update some fields on the source side for the match. */
  sourceFieldUpdates?:
    | Field[]
    | undefined;
  /**
   * indicate that we cannot generate field updates to match the target fields
   * and human review is required. For the case of JLL, the field is set when
   * we cannot generate the updates required to set the quantity filed by
   * dividing amount by unit cost.
   * Note: this field is independent from the matching confidence. i.e. we could
   * have both high and low confidence of matching when this field is set.
   */
  updateError?: string | undefined;
}

/** Unmatched field group either on the source side on the target side. */
export interface FieldGroupMatchUnmatchedFieldGroup {
  $type?: "pb.v1alpha1.FieldGroupMatch.UnmatchedFieldGroup";
  index?: number | undefined;
}

/** Represents a match (or mismatch) between a source field and a target field. */
export interface FieldMatch {
  $type?: "pb.v1alpha1.FieldMatch";
  /** List of matched source field identifiers. */
  sourceIds?:
    | string[]
    | undefined;
  /** List of matched target field identifiers. */
  targetIds?:
    | string[]
    | undefined;
  /**
   * Optional updates to the source fields to improve the match.
   * Even if only one child field is being updated, the entire updated field should be returned
   * Although we're making this a FieldGroup, for now the size of the group should be <=1,
   * since we're only implementing updates for 1:1 or 1:N, not N:1
   */
  updatedSourceFields?:
    | FieldGroup
    | undefined;
  /** Indicates that automatic field updates failed and human review is needed. */
  updateError?:
    | string
    | undefined;
  /** Confidence score for the match. */
  confidence?:
    | number
    | undefined;
  /**
   * Natural language explanation for the match/unmatch prediction.
   * For now, we'll keep this blank
   */
  explanation?: string | undefined;
}

/** Detect duplicate line items. */
export interface DetectDuplicateLineItems {
  $type?: "pb.v1alpha1.DetectDuplicateLineItems";
  items?: Item[] | undefined;
  rule?: string | undefined;
}

/** Duplicate line items detected. */
export interface DetectDuplicateLineItemsResult {
  $type?: "pb.v1alpha1.DetectDuplicateLineItemsResult";
  /** we may find zero or more duplicate groups from the table */
  duplicates?:
    | DetectDuplicateLineItemsResultDuplicateGroup[]
    | undefined;
  /** overall confidence score for all predicted duplicates */
  confidence?: number | undefined;
}

/** a duplicate group contains at least two row indices. */
export interface DetectDuplicateLineItemsResultDuplicateGroup {
  $type?: "pb.v1alpha1.DetectDuplicateLineItemsResult.DuplicateGroup";
  /** 0-based row indices from the given table */
  itemIndices?:
    | number[]
    | undefined;
  /** reason on why we think this is a duplicate */
  explanation?: string | undefined;
}

/**
 * Generate some text given some context (FieldGroups or documents)
 * and a prompt.
 */
export interface GenerateText {
  $type?: "pb.v1alpha1.GenerateText";
  items?: Item[] | undefined;
  prompt?: string | undefined;
}

/** AI generated text. */
export interface GenerateTextResult {
  $type?: "pb.v1alpha1.GenerateTextResult";
  generatedText?: string | undefined;
  confidence?: number | undefined;
}

export interface Classify {
  $type?: "pb.v1alpha1.Classify";
  items?: Item[] | undefined;
  prompt?: string | undefined;
  preset?: ClassifyClassifyPreset | undefined;
}

/**
 * Predefined operations that have optimized implementation from the ML side.
 * Each preset has its predefined prompt and preferred input format.
 * If preset is defined, the prompt field is optional.
 */
export enum ClassifyClassifyPreset {
  UNSPECIFIED = 0,
  /**
   * GOOGLE_EXPENSE_RISK - predict risk level for Google Concur expense auditing.
   * - input: would be either expense form or list of expenses
   * - output: binary prediction and confidence score can be interpreted as
   *   risk level (higher confidence means higher risk)
   */
  GOOGLE_EXPENSE_RISK = 1,
  /**
   * GOOGLE_EXPENSE_DOCUMENT_VALIDATION - validate whether an expense document is a valid receipts/invoice.
   * - input: a single PDF/image
   * - output: binary prediction
   */
  GOOGLE_EXPENSE_DOCUMENT_VALIDATION = 2,
  UNRECOGNIZED = -1,
}

export function classifyClassifyPresetFromJSON(object: any): ClassifyClassifyPreset {
  switch (object) {
    case 0:
    case "CLASSIFY_PRESET_UNSPECIFIED":
      return ClassifyClassifyPreset.UNSPECIFIED;
    case 1:
    case "GOOGLE_EXPENSE_RISK":
      return ClassifyClassifyPreset.GOOGLE_EXPENSE_RISK;
    case 2:
    case "GOOGLE_EXPENSE_DOCUMENT_VALIDATION":
      return ClassifyClassifyPreset.GOOGLE_EXPENSE_DOCUMENT_VALIDATION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ClassifyClassifyPreset.UNRECOGNIZED;
  }
}

export function classifyClassifyPresetToJSON(object: ClassifyClassifyPreset): string {
  switch (object) {
    case ClassifyClassifyPreset.UNSPECIFIED:
      return "CLASSIFY_PRESET_UNSPECIFIED";
    case ClassifyClassifyPreset.GOOGLE_EXPENSE_RISK:
      return "GOOGLE_EXPENSE_RISK";
    case ClassifyClassifyPreset.GOOGLE_EXPENSE_DOCUMENT_VALIDATION:
      return "GOOGLE_EXPENSE_DOCUMENT_VALIDATION";
    case ClassifyClassifyPreset.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ClassifyResult {
  $type?: "pb.v1alpha1.ClassifyResult";
  category?: string | undefined;
  binary?:
    | ClassifyResultBinaryPrediction
    | undefined;
  /** ML confidence between 0 to 1. */
  confidence?: number | undefined;
  explanation?: string | undefined;
}

export enum ClassifyResultBinaryPrediction {
  UNSPECIFIED = 0,
  NEGATIVE = 1,
  POSITIVE = 2,
  UNRECOGNIZED = -1,
}

export function classifyResultBinaryPredictionFromJSON(object: any): ClassifyResultBinaryPrediction {
  switch (object) {
    case 0:
    case "UNSPECIFIED":
      return ClassifyResultBinaryPrediction.UNSPECIFIED;
    case 1:
    case "NEGATIVE":
      return ClassifyResultBinaryPrediction.NEGATIVE;
    case 2:
    case "POSITIVE":
      return ClassifyResultBinaryPrediction.POSITIVE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ClassifyResultBinaryPrediction.UNRECOGNIZED;
  }
}

export function classifyResultBinaryPredictionToJSON(object: ClassifyResultBinaryPrediction): string {
  switch (object) {
    case ClassifyResultBinaryPrediction.UNSPECIFIED:
      return "UNSPECIFIED";
    case ClassifyResultBinaryPrediction.NEGATIVE:
      return "NEGATIVE";
    case ClassifyResultBinaryPrediction.POSITIVE:
      return "POSITIVE";
    case ClassifyResultBinaryPrediction.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * A common use case in accounting is to purchase order (PO) reconciliation, which
 * matches line items between PO against invoices. The result of this process
 * would be updating line items in PO and link invoice items to the corresponding
 * PO ones. We model those operations as taking in two data tables that contains
 * those line items (as two Items) and generates necessary data table updates as ItemUpdates.
 */
export interface ReconcileItems {
  $type?: "pb.v1alpha1.ReconcileItems";
  rule?:
    | string
    | undefined;
  /**
   * List of fields to reconcile. If a field is included here, we consider it
   * to be mutable and include it in UpdateFieldGroups.
   */
  fields?:
    | Field[]
    | undefined;
  /**
   * Reconciliation happens between two items:
   * - source: mutable item that we'd like to update in order to match the target item
   * - target: immutable item that we want to reconcile against.
   * In the context of reconciliation between invoice and purchase order, we'd
   * like to update the purchase order entries to match the ones in the invoice.
   * Thus the invoice would target and purchase order would be the source.
   */
  source?: Item | undefined;
  target?: Item | undefined;
}

/**
 * Reconcile entities in source with entities in target.
 * A common use case in accounting is to purchase order (PO) reconciliation, which
 * matches line items between PO against invoices. The result of this process
 * would be updating line items in PO and link invoice items to the corresponding
 * PO ones. We model those operations as taking in two data tables that contains
 * those line items (as two Items) and generates necessary data table updates as ItemUpdates.
 */
export interface ReconcileItemsRequestUnified {
  $type?: "pb.v1alpha1.ReconcileItemsRequestUnified";
  /** Instructions that are passed into the LLM (same proto as the one in Compare SA) */
  policy?:
    | Policy
    | undefined;
  /** The two field groups being reconciled. For each (parent) field, unique_id must be set, as recon is only done at the parent level */
  sourceGroup?: FieldGroup | undefined;
  targetGroup?:
    | FieldGroup
    | undefined;
  /** This only needs to be set for the fields/children in which one or more of clustering, aggregation, or updating is enabled */
  fieldConfigs?: FieldReconciliationConfig[] | undefined;
  matchingCardinality?: ReconcileItemsRequestUnifiedMatchingCardinality | undefined;
}

/**
 * For best results, the user should specify which subset of {1:1, 1:many, many:1} are possibilities
 * Naming convention: X_to_Y means that X source fields can match with Y target fields
 */
export enum ReconcileItemsRequestUnifiedMatchingCardinality {
  /** UNSPECIFIED - If unspecified, assume that only 1:1 is possible */
  UNSPECIFIED = 0,
  ONE_TO_ONE = 1,
  /** ONE_TO_MANY - Everything below this implies that 1:1 can also be the case */
  ONE_TO_MANY = 2,
  MANY_TO_ONE = 3,
  /** MANY_TO_MANY - All of the above, but M:N is still not possible */
  MANY_TO_MANY = 4,
  UNRECOGNIZED = -1,
}

export function reconcileItemsRequestUnifiedMatchingCardinalityFromJSON(
  object: any,
): ReconcileItemsRequestUnifiedMatchingCardinality {
  switch (object) {
    case 0:
    case "MATCHING_CARDINALITY_UNSPECIFIED":
      return ReconcileItemsRequestUnifiedMatchingCardinality.UNSPECIFIED;
    case 1:
    case "ONE_TO_ONE":
      return ReconcileItemsRequestUnifiedMatchingCardinality.ONE_TO_ONE;
    case 2:
    case "ONE_TO_MANY":
      return ReconcileItemsRequestUnifiedMatchingCardinality.ONE_TO_MANY;
    case 3:
    case "MANY_TO_ONE":
      return ReconcileItemsRequestUnifiedMatchingCardinality.MANY_TO_ONE;
    case 4:
    case "MANY_TO_MANY":
      return ReconcileItemsRequestUnifiedMatchingCardinality.MANY_TO_MANY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ReconcileItemsRequestUnifiedMatchingCardinality.UNRECOGNIZED;
  }
}

export function reconcileItemsRequestUnifiedMatchingCardinalityToJSON(
  object: ReconcileItemsRequestUnifiedMatchingCardinality,
): string {
  switch (object) {
    case ReconcileItemsRequestUnifiedMatchingCardinality.UNSPECIFIED:
      return "MATCHING_CARDINALITY_UNSPECIFIED";
    case ReconcileItemsRequestUnifiedMatchingCardinality.ONE_TO_ONE:
      return "ONE_TO_ONE";
    case ReconcileItemsRequestUnifiedMatchingCardinality.ONE_TO_MANY:
      return "ONE_TO_MANY";
    case ReconcileItemsRequestUnifiedMatchingCardinality.MANY_TO_ONE:
      return "MANY_TO_ONE";
    case ReconcileItemsRequestUnifiedMatchingCardinality.MANY_TO_MANY:
      return "MANY_TO_MANY";
    case ReconcileItemsRequestUnifiedMatchingCardinality.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface FieldName {
  $type?: "pb.v1alpha1.FieldName";
  parentName?: string | undefined;
  childName?: string | undefined;
}

/** Defines how reconciliation should be performed for specific fields (applies at child level). */
export interface FieldReconciliationConfig {
  $type?: "pb.v1alpha1.FieldReconciliationConfig";
  /**
   * We include both parent and child entities in order to differentiate between the two
   * If the child name is empty, we know that this field is referring to a parent entity
   */
  sourceName?: FieldName | undefined;
  targetName?: FieldName | undefined;
  clusteringMethod?: FieldReconciliationConfigClusteringMethod | undefined;
  aggregationMethod?:
    | FieldReconciliationConfigAggregationMethod
    | undefined;
  /**
   * Whether this field should be updated if a match is found.
   * If the field is a parent field with children, then all the children will be updated
   */
  updateConfig?: UpdateConfig | undefined;
}

/**
 * Whether clustering (e.g., fuzzy matching) should be applied.
 * This should be enabled if and only if there are many (>=50 on each side) items to be reconciled
 */
export enum FieldReconciliationConfigClusteringMethod {
  UNSPECIFIED = 0,
  EXACT = 1,
  FUZZY = 2,
  /** SEMANTIC - TODO: Implement semantic clustering: https://www.pinecone.io/learn/series/faiss/locality-sensitive-hashing-random-projection/ */
  SEMANTIC = 3,
  UNRECOGNIZED = -1,
}

export function fieldReconciliationConfigClusteringMethodFromJSON(
  object: any,
): FieldReconciliationConfigClusteringMethod {
  switch (object) {
    case 0:
    case "CLUSTERING_METHOD_UNSPECIFIED":
      return FieldReconciliationConfigClusteringMethod.UNSPECIFIED;
    case 1:
    case "CLUSTERING_METHOD_EXACT":
      return FieldReconciliationConfigClusteringMethod.EXACT;
    case 2:
    case "CLUSTERING_METHOD_FUZZY":
      return FieldReconciliationConfigClusteringMethod.FUZZY;
    case 3:
    case "CLUSTERING_METHOD_SEMANTIC":
      return FieldReconciliationConfigClusteringMethod.SEMANTIC;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FieldReconciliationConfigClusteringMethod.UNRECOGNIZED;
  }
}

export function fieldReconciliationConfigClusteringMethodToJSON(
  object: FieldReconciliationConfigClusteringMethod,
): string {
  switch (object) {
    case FieldReconciliationConfigClusteringMethod.UNSPECIFIED:
      return "CLUSTERING_METHOD_UNSPECIFIED";
    case FieldReconciliationConfigClusteringMethod.EXACT:
      return "CLUSTERING_METHOD_EXACT";
    case FieldReconciliationConfigClusteringMethod.FUZZY:
      return "CLUSTERING_METHOD_FUZZY";
    case FieldReconciliationConfigClusteringMethod.SEMANTIC:
      return "CLUSTERING_METHOD_SEMANTIC";
    case FieldReconciliationConfigClusteringMethod.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Whether aggregation should be performed on this field.
 * If enabled, a postprocessing step will occur that will remove the match if the aggregation condition is not met
 */
export enum FieldReconciliationConfigAggregationMethod {
  UNSPECIFIED = 0,
  SUM = 1,
  UNRECOGNIZED = -1,
}

export function fieldReconciliationConfigAggregationMethodFromJSON(
  object: any,
): FieldReconciliationConfigAggregationMethod {
  switch (object) {
    case 0:
    case "AGGREGATION_METHOD_UNSPECIFIED":
      return FieldReconciliationConfigAggregationMethod.UNSPECIFIED;
    case 1:
    case "AGGREGATION_METHOD_SUM":
      return FieldReconciliationConfigAggregationMethod.SUM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FieldReconciliationConfigAggregationMethod.UNRECOGNIZED;
  }
}

export function fieldReconciliationConfigAggregationMethodToJSON(
  object: FieldReconciliationConfigAggregationMethod,
): string {
  switch (object) {
    case FieldReconciliationConfigAggregationMethod.UNSPECIFIED:
      return "AGGREGATION_METHOD_UNSPECIFIED";
    case FieldReconciliationConfigAggregationMethod.SUM:
      return "AGGREGATION_METHOD_SUM";
    case FieldReconciliationConfigAggregationMethod.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface UpdateConfig {
  $type?: "pb.v1alpha1.UpdateConfig";
  updateMethod?: UpdateConfigUpdateMethod | undefined;
  llmUpdateInstructions?: string | undefined;
}

export enum UpdateConfigUpdateMethod {
  UNSPECIFIED = 0,
  EXACT = 1,
  /** SUM - only supported for 1:N matches, and fields with numbers */
  SUM = 2,
  LLM = 3,
  UNRECOGNIZED = -1,
}

export function updateConfigUpdateMethodFromJSON(object: any): UpdateConfigUpdateMethod {
  switch (object) {
    case 0:
    case "UPDATE_METHOD_UNSPECIFIED":
      return UpdateConfigUpdateMethod.UNSPECIFIED;
    case 1:
    case "UPDATE_METHOD_EXACT":
      return UpdateConfigUpdateMethod.EXACT;
    case 2:
    case "UPDATE_METHOD_SUM":
      return UpdateConfigUpdateMethod.SUM;
    case 3:
    case "UPDATE_METHOD_LLM":
      return UpdateConfigUpdateMethod.LLM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UpdateConfigUpdateMethod.UNRECOGNIZED;
  }
}

export function updateConfigUpdateMethodToJSON(object: UpdateConfigUpdateMethod): string {
  switch (object) {
    case UpdateConfigUpdateMethod.UNSPECIFIED:
      return "UPDATE_METHOD_UNSPECIFIED";
    case UpdateConfigUpdateMethod.EXACT:
      return "UPDATE_METHOD_EXACT";
    case UpdateConfigUpdateMethod.SUM:
      return "UPDATE_METHOD_SUM";
    case UpdateConfigUpdateMethod.LLM:
      return "UPDATE_METHOD_LLM";
    case UpdateConfigUpdateMethod.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface FieldGroupExtractedFields {
  $type?: "pb.v1alpha1.FieldGroupExtractedFields";
  fieldGroupIndex?: number | undefined;
  fields?: Field[] | undefined;
  confidence?: number | undefined;
}

export interface ReconcileItemsResult {
  $type?: "pb.v1alpha1.ReconcileItemsResult";
  /** field group matches between source and target items */
  fieldGroupMatches?: FieldGroupMatch[] | undefined;
  sourceExtractedFields?: FieldGroupExtractedFields[] | undefined;
  targetExtractedFields?: FieldGroupExtractedFields[] | undefined;
}

export interface ReconcileItemsResultUnified {
  $type?: "pb.v1alpha1.ReconcileItemsResultUnified";
  /** Final, updated matches between the source and target groups. */
  finalFieldMatches?: FieldMatch[] | undefined;
  sourceNonMatchIds?: string[] | undefined;
  targetNonMatchIds?: string[] | undefined;
}

/** A policy or instructions to follow. */
export interface Policy {
  $type?: "pb.v1alpha1.Policy";
  /** Instructions take a list of rules in text format. */
  instruction?:
    | PolicyInstruction
    | undefined;
  /** A document (pdf/image/etc) containing the rules or policy to follow. */
  policyDoc?: Document | undefined;
}

export interface PolicyInstruction {
  $type?: "pb.v1alpha1.Policy.Instruction";
  rules?: string[] | undefined;
}

export interface FieldGroupCompareRequest {
  $type?: "pb.v1alpha1.FieldGroupCompareRequest";
  /** Field groups to compare. */
  fieldGroupLeft?: FieldGroup | undefined;
  fieldGroupRight?:
    | FieldGroup
    | undefined;
  /** List of field name pairs in field_group to compare. */
  fieldToCompare?: FieldPair[] | undefined;
}

export interface FieldPair {
  $type?: "pb.v1alpha1.FieldPair";
  fieldLeft?: string | undefined;
  fieldRight?: string | undefined;
}

export interface FieldGroupCompareResult {
  $type?: "pb.v1alpha1.FieldGroupCompareResult";
  fieldCompareResults?: FieldGroupCompareResultFieldCompareResult[] | undefined;
}

export interface FieldGroupCompareResultFieldCompareResult {
  $type?: "pb.v1alpha1.FieldGroupCompareResult.FieldCompareResult";
  /** Copied from FieldCompareRequest for completeness. */
  fieldToCompare?:
    | FieldPair
    | undefined;
  /** The result of the comparison. */
  isMatch?:
    | boolean
    | undefined;
  /** Text explaining why the fields match or do not match. */
  explanation?:
    | string
    | undefined;
  /** Confidence score for the result. */
  confidence?: number | undefined;
}

/** Compare SA request message. See go/compare-sa-design for details. */
export interface CompareRequest {
  $type?: "pb.v1alpha1.CompareRequest";
  /** Policy or instructions to follow to compare the provided data. */
  policy?:
    | Policy
    | undefined;
  /** Request to compare field groups. */
  fieldGroupCompareRequest?: FieldGroupCompareRequest | undefined;
}

/** Compare SA response message. See go/compare-sa-design for details. */
export interface CompareResult {
  $type?: "pb.v1alpha1.CompareResult";
  /** Result of comparing field groups. */
  fieldGroupCompareResult?: FieldGroupCompareResult | undefined;
}

/**
 * A pair of input and output examples to test the generated function with.
 * Next ID: 3
 */
export interface GenerateFunctionTestCase {
  $type?: "pb.v1alpha1.GenerateFunctionTestCase";
  /**
   * The input variables to the function.
   * The order must match the order of the parameters in the function signature.
   */
  input?:
    | Variable[]
    | undefined;
  /** The output variable from the function. */
  output?: Variable | undefined;
}

/**
 * Generate function SA request message. See go/code-gen-ui-fallback-design for rough design.
 * Next ID: 5
 */
export interface GenerateFunctionRequest {
  $type?: "pb.v1alpha1.GenerateFunctionRequest";
  /**
   * The instructions to following to generate the function.
   * This is most likely just a wrapper for a string for now.
   */
  policy?:
    | Policy
    | undefined;
  /** The paremeters that the generated function should satisfy. */
  inputSchema?:
    | VariableType[]
    | undefined;
  /** The output types that the generated function should produce. */
  outputSchema?:
    | VariableType
    | undefined;
  /** The unit test cases that the user wants the generated function to pass. */
  demonstrations?: GenerateFunctionTestCase[] | undefined;
}

/**
 * Generate function SA response message. See go/code-gen-ui-fallback-design for rough design.
 * Next ID: 4
 */
export interface GenerateFunctionResult {
  $type?: "pb.v1alpha1.GenerateFunctionResult";
  /**
   * The generated code.
   * Note: the code does NOT satisfy the requirements if the error field is set.
   */
  function?:
    | Function
    | undefined;
  /** Error code if the function generation process failed. */
  error?:
    | GenerateFunctionResultError
    | undefined;
  /** The test case results. */
  testCaseResults?: GenerateFunctionResultTestCaseResult[] | undefined;
}

export enum GenerateFunctionResultError {
  UNSPECIFIED = 0,
  /** EXCEEDED_MAX_ITERATIONS - The function generation process exceeded the maximum number of iterations without producing a function that passes all tests. */
  EXCEEDED_MAX_ITERATIONS = 1,
  UNKNOWN = 2,
  UNRECOGNIZED = -1,
}

export function generateFunctionResultErrorFromJSON(object: any): GenerateFunctionResultError {
  switch (object) {
    case 0:
    case "ERROR_UNSPECIFIED":
      return GenerateFunctionResultError.UNSPECIFIED;
    case 1:
    case "EXCEEDED_MAX_ITERATIONS":
      return GenerateFunctionResultError.EXCEEDED_MAX_ITERATIONS;
    case 2:
    case "ERROR_UNKNOWN":
      return GenerateFunctionResultError.UNKNOWN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return GenerateFunctionResultError.UNRECOGNIZED;
  }
}

export function generateFunctionResultErrorToJSON(object: GenerateFunctionResultError): string {
  switch (object) {
    case GenerateFunctionResultError.UNSPECIFIED:
      return "ERROR_UNSPECIFIED";
    case GenerateFunctionResultError.EXCEEDED_MAX_ITERATIONS:
      return "EXCEEDED_MAX_ITERATIONS";
    case GenerateFunctionResultError.UNKNOWN:
      return "ERROR_UNKNOWN";
    case GenerateFunctionResultError.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Storing information about a test case and the result of the generated function. */
export interface GenerateFunctionResultTestCaseResult {
  $type?: "pb.v1alpha1.GenerateFunctionResult.TestCaseResult";
  demonstration?: GenerateFunctionTestCase | undefined;
  output?: Variable | undefined;
  diff?: string | undefined;
}

/** a demonstration for extractVariables */
export interface ExtractVariablesTestCase {
  $type?: "pb.v1alpha1.ExtractVariablesTestCase";
  uiState?: UiState | undefined;
  result?: VariableValue | undefined;
}

export interface GenerateExtractionScriptRequest {
  $type?: "pb.v1alpha1.GenerateExtractionScriptRequest";
  /**
   * The instructions to following to generate the extraction script.
   * This is most likely just a wrapper for a string for now.
   */
  policy?: Policy | undefined;
  schema?:
    | VariableType
    | undefined;
  /** The input observation */
  uiState?: UiState | undefined;
  demonstrations?: ExtractVariablesTestCase[] | undefined;
}

export interface GenerateExtractionScriptResult {
  $type?: "pb.v1alpha1.GenerateExtractionScriptResult";
  extractionScript?: string | undefined;
  result?: VariableValue | undefined;
}

export interface ExtractVariablesRequest {
  $type?: "pb.v1alpha1.ExtractVariablesRequest";
  /**
   * The instructions to following to extract variables
   * This is most likely just a wrapper for a string for now.
   */
  policy?: Policy | undefined;
  schema?:
    | VariableType
    | undefined;
  /** The input observation */
  uiState?: UiState | undefined;
  demonstrations?:
    | ExtractVariablesTestCase[]
    | undefined;
  /** the optional generated extraction JS code for extract the variables */
  extractionScript?: string | undefined;
}

export interface ExtractVariablesResult {
  $type?: "pb.v1alpha1.ExtractVariablesResult";
  result?: VariableValue | undefined;
}

/**
 * Extract structured data SA request message.
 * This request allows the user to extract information from the provided input data and
 * output in a structured format.
 * Next ID: 4
 */
export interface ExtractStructuredDataRequest {
  $type?: "pb.v1alpha1.ExtractStructuredDataRequest";
  /**
   * The instructions to following to generate the variable
   * This is most likely just a wrapper for a string for now.
   */
  policy?:
    | Policy
    | undefined;
  /** A structure input data that we can process to generate the structured output */
  input?:
    | Variable
    | undefined;
  /** The output format we need to generate */
  outputSchema?: VariableType | undefined;
}

/**
 * Extract structured data SA response message.
 * This response contains the extracted structured data based on the ExtractStructuredDataRequest.
 * Next ID: 2
 */
export interface ExtractStructuredDataResult {
  $type?: "pb.v1alpha1.ExtractStructuredDataResult";
  /** The extracted structured data. */
  result?: Variable | undefined;
}

export interface SmartActionOptions {
  $type?: "pb.v1alpha1.SmartActionOptions";
  /**
   * Set to true if the request is using the new smart action framework. See go/sa-framework-details for more details.
   * This flag is used while we are migrating to new smart action framework. Deprecate this flag once the migration is complete.
   */
  isNewSmartActionFramework?:
    | boolean
    | undefined;
  /** Set to true if the request and response is in legacy proto. */
  convertLegacyProto?: boolean | undefined;
}

export interface ProcessSmartActionsRequest {
  $type?: "pb.v1alpha1.ProcessSmartActionsRequest";
  actions?:
    | SmartAction[]
    | undefined;
  /** The corresponding Orbot execution that initiates this request, used for error tracing */
  taskId?:
    | string
    | undefined;
  /** The action that initiates this request, used for error tracing */
  actionId?:
    | string
    | undefined;
  /** Parameter used internally for DocAI API monitoring */
  bypassDocaiCache?:
    | boolean
    | undefined;
  /** Parameter used internally for LLM API monitoring */
  bypassLlmCache?:
    | boolean
    | undefined;
  /** This field indicates the operation need to run async. */
  runAsync?:
    | boolean
    | undefined;
  /**
   * The corresponding review task created by the frontend.
   * The backend will use this field to fill in output from ML.
   */
  reviewTaskId?:
    | string
    | undefined;
  /** Custom options for the request to be used by the OrbotML. */
  smartActionOptions?:
    | SmartActionOptions
    | undefined;
  /**
   * Right now ML needs to query ClickHouse directly to fetch few-shot examples.
   * Since we store data in separate databases for organizations with single
   * tenant setup, we need to pass down this information to the ML side. This is
   * only temporary before we set up and migrate to using a proxy service on the
   * server side which can handle single tenancy as well as better access control.
   * NOTE: this field is populated on the server side before sending the request
   * to the ML side via Temporal call.
   * Sample values:
   * - shared tenant: default
   * - single tenant: dev_672a6c16aa7b2a6c12408fa7
   * TODO: remove this once we migrate to the proxy service.
   */
  clickhouseDatabase?:
    | string
    | undefined;
  /**
   * Temporal Context, used for tracing, logging, and metrics.
   * Can be populated in automl, no need for BE to fill them up
   */
  temporalWorkflowId?: string | undefined;
  temporalRunId?: string | undefined;
}

/** Next ID: 15 */
export interface SmartAction {
  $type?: "pb.v1alpha1.SmartAction";
  extractFields?: ExtractFields | undefined;
  validateFieldValues?: ValidateFieldValues | undefined;
  flagKeywords?: FlagKeywords | undefined;
  detectDuplicateLineItems?: DetectDuplicateLineItems | undefined;
  reconcileLineItems?: ReconcileItems | undefined;
  generateText?: GenerateText | undefined;
  classify?:
    | Classify
    | undefined;
  /**
   * New unified protos for smart actions. These fields should have suffix "_unified".
   * TODO: Deprecate the old protos once the migration is complete.
   */
  extractFieldsUnified?: ExtractFieldsRequestUnified | undefined;
  reconcileLineItemsUnified?: ReconcileItemsRequestUnified | undefined;
  compareRequest?: CompareRequest | undefined;
  generateFunctionRequest?: GenerateFunctionRequest | undefined;
  generateExtractionScript?: GenerateExtractionScriptRequest | undefined;
  extractVariables?: ExtractVariablesRequest | undefined;
  extractStructuredData?: ExtractStructuredDataRequest | undefined;
  hyperparameter?: Hyperparameter | undefined;
}

export interface ProcessSmartActionsResponse {
  $type?: "pb.v1alpha1.ProcessSmartActionsResponse";
  results?: SmartActionResult[] | undefined;
}

/** Next ID: 16 */
export interface SmartActionResult {
  $type?: "pb.v1alpha1.SmartActionResult";
  extractFieldsResult?: ExtractFieldsResult | undefined;
  validateFieldValuesResult?: ValidateFieldValuesResult | undefined;
  flagKeywordsResult?: FlagKeywordsResult | undefined;
  detectDuplicateLineItemsResult?: DetectDuplicateLineItemsResult | undefined;
  smartActionError?: SmartActionResultSmartActionError | undefined;
  reconcileLineItemsResult?: ReconcileItemsResult | undefined;
  generateTextResult?: GenerateTextResult | undefined;
  classifyResult?:
    | ClassifyResult
    | undefined;
  /**
   * New unified protos for smart actions.
   * TODO: Deprecate the old protos once the migration is complete.
   */
  extractFieldsResultUnified?: ExtractFieldsResultUnified | undefined;
  reconcileLineItemsResultUnified?: ReconcileItemsResultUnified | undefined;
  compareResult?: CompareResult | undefined;
  generateFunctionResult?: GenerateFunctionResult | undefined;
  generateExtractionScriptResult?: GenerateExtractionScriptResult | undefined;
  extractVariablesResult?: ExtractVariablesResult | undefined;
  extractStructuredDataResult?:
    | ExtractStructuredDataResult
    | undefined;
  /** for billing purpose */
  numProcessedPages?: number | undefined;
}

export enum SmartActionResultSmartActionError {
  PROCESS_DOCUMENT_ERROR_TYPE_UNSPECIFIED = 0,
  READABILITY_ERROR = 1,
  /**
   * PAGE_LIMIT_EXCEEDED_ERROR - One of the PDF files in the request contains more pages than what we can support.
   * Currently it's 15 due to DocAI's limit on synchronous OCR request.
   */
  PAGE_LIMIT_EXCEEDED_ERROR = 2,
  UNKNOWN_ERROR = 3,
  EXTRACTION_ERROR = 4,
  UNRECOGNIZED = -1,
}

export function smartActionResultSmartActionErrorFromJSON(object: any): SmartActionResultSmartActionError {
  switch (object) {
    case 0:
    case "PROCESS_DOCUMENT_ERROR_TYPE_UNSPECIFIED":
      return SmartActionResultSmartActionError.PROCESS_DOCUMENT_ERROR_TYPE_UNSPECIFIED;
    case 1:
    case "READABILITY_ERROR":
      return SmartActionResultSmartActionError.READABILITY_ERROR;
    case 2:
    case "PAGE_LIMIT_EXCEEDED_ERROR":
      return SmartActionResultSmartActionError.PAGE_LIMIT_EXCEEDED_ERROR;
    case 3:
    case "UNKNOWN_ERROR":
      return SmartActionResultSmartActionError.UNKNOWN_ERROR;
    case 4:
    case "EXTRACTION_ERROR":
      return SmartActionResultSmartActionError.EXTRACTION_ERROR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SmartActionResultSmartActionError.UNRECOGNIZED;
  }
}

export function smartActionResultSmartActionErrorToJSON(object: SmartActionResultSmartActionError): string {
  switch (object) {
    case SmartActionResultSmartActionError.PROCESS_DOCUMENT_ERROR_TYPE_UNSPECIFIED:
      return "PROCESS_DOCUMENT_ERROR_TYPE_UNSPECIFIED";
    case SmartActionResultSmartActionError.READABILITY_ERROR:
      return "READABILITY_ERROR";
    case SmartActionResultSmartActionError.PAGE_LIMIT_EXCEEDED_ERROR:
      return "PAGE_LIMIT_EXCEEDED_ERROR";
    case SmartActionResultSmartActionError.UNKNOWN_ERROR:
      return "UNKNOWN_ERROR";
    case SmartActionResultSmartActionError.EXTRACTION_ERROR:
      return "EXTRACTION_ERROR";
    case SmartActionResultSmartActionError.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Used to construct examples of actions and their expected results together
 * for test cases and evaluation datasets.
 */
export interface SmartActionExample {
  $type?: "pb.v1alpha1.SmartActionExample";
  action?: SmartAction | undefined;
  result?: SmartActionResult | undefined;
  id?: number | undefined;
}

/** Group a set of SmartActionExamples with an optional name. */
export interface SmartActionExamples {
  $type?: "pb.v1alpha1.SmartActionExamples";
  /** identify the set of examples. */
  name?: string | undefined;
  examples?: SmartActionExample[] | undefined;
}

export interface SmartActionHITLResult {
  $type?: "pb.v1alpha1.SmartActionHITLResult";
  /** It stores the original result from the ML if the user modifies the result */
  smartActionResult?:
    | SmartActionResult
    | undefined;
  /** Response from the ML or after the user modifies the result */
  correctedSmartActionResult?: SmartActionResult | undefined;
  isFallout?: boolean | undefined;
  falloutReason?: string | undefined;
}

function createBaseExtractFields(): ExtractFields {
  return { $type: "pb.v1alpha1.ExtractFields", fields: [], document: undefined, rule: "", source: undefined };
}

export const ExtractFields = {
  $type: "pb.v1alpha1.ExtractFields" as const,

  encode(message: ExtractFields, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fields !== undefined && message.fields.length !== 0) {
      for (const v of message.fields) {
        Field.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    if (message.document !== undefined) {
      Document.encode(message.document, writer.uint32(18).fork()).ldelim();
    }
    if (message.rule !== undefined && message.rule !== "") {
      writer.uint32(26).string(message.rule);
    }
    if (message.source !== undefined) {
      ItemDocument.encode(message.source, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExtractFields {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExtractFields();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fields!.push(Field.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.document = Document.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.rule = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.source = ItemDocument.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExtractFields {
    return {
      $type: ExtractFields.$type,
      fields: globalThis.Array.isArray(object?.fields) ? object.fields.map((e: any) => Field.fromJSON(e)) : [],
      document: isSet(object.document) ? Document.fromJSON(object.document) : undefined,
      rule: isSet(object.rule) ? globalThis.String(object.rule) : "",
      source: isSet(object.source) ? ItemDocument.fromJSON(object.source) : undefined,
    };
  },

  toJSON(message: ExtractFields): unknown {
    const obj: any = {};
    if (message.fields?.length) {
      obj.fields = message.fields.map((e) => Field.toJSON(e));
    }
    if (message.document !== undefined) {
      obj.document = Document.toJSON(message.document);
    }
    if (message.rule !== undefined && message.rule !== "") {
      obj.rule = message.rule;
    }
    if (message.source !== undefined) {
      obj.source = ItemDocument.toJSON(message.source);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExtractFields>, I>>(base?: I): ExtractFields {
    return ExtractFields.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExtractFields>, I>>(object: I): ExtractFields {
    const message = createBaseExtractFields();
    message.fields = object.fields?.map((e) => Field.fromPartial(e)) || [];
    message.document = (object.document !== undefined && object.document !== null)
      ? Document.fromPartial(object.document)
      : undefined;
    message.rule = object.rule ?? "";
    message.source = (object.source !== undefined && object.source !== null)
      ? ItemDocument.fromPartial(object.source)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(ExtractFields.$type, ExtractFields);

function createBaseExtractFieldsRequestUnified(): ExtractFieldsRequestUnified {
  return { $type: "pb.v1alpha1.ExtractFieldsRequestUnified", rule: "", source: undefined };
}

export const ExtractFieldsRequestUnified = {
  $type: "pb.v1alpha1.ExtractFieldsRequestUnified" as const,

  encode(message: ExtractFieldsRequestUnified, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rule !== undefined && message.rule !== "") {
      writer.uint32(10).string(message.rule);
    }
    if (message.source !== undefined) {
      ExtractionDocument.encode(message.source, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExtractFieldsRequestUnified {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExtractFieldsRequestUnified();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.rule = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.source = ExtractionDocument.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExtractFieldsRequestUnified {
    return {
      $type: ExtractFieldsRequestUnified.$type,
      rule: isSet(object.rule) ? globalThis.String(object.rule) : "",
      source: isSet(object.source) ? ExtractionDocument.fromJSON(object.source) : undefined,
    };
  },

  toJSON(message: ExtractFieldsRequestUnified): unknown {
    const obj: any = {};
    if (message.rule !== undefined && message.rule !== "") {
      obj.rule = message.rule;
    }
    if (message.source !== undefined) {
      obj.source = ExtractionDocument.toJSON(message.source);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExtractFieldsRequestUnified>, I>>(base?: I): ExtractFieldsRequestUnified {
    return ExtractFieldsRequestUnified.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExtractFieldsRequestUnified>, I>>(object: I): ExtractFieldsRequestUnified {
    const message = createBaseExtractFieldsRequestUnified();
    message.rule = object.rule ?? "";
    message.source = (object.source !== undefined && object.source !== null)
      ? ExtractionDocument.fromPartial(object.source)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(ExtractFieldsRequestUnified.$type, ExtractFieldsRequestUnified);

function createBaseExtractFieldsResult(): ExtractFieldsResult {
  return { $type: "pb.v1alpha1.ExtractFieldsResult", results: [], docaiProtoPath: "" };
}

export const ExtractFieldsResult = {
  $type: "pb.v1alpha1.ExtractFieldsResult" as const,

  encode(message: ExtractFieldsResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.results !== undefined && message.results.length !== 0) {
      for (const v of message.results) {
        ExtractFieldsResultPerFieldResult.encode(v!, writer.uint32(18).fork()).ldelim();
      }
    }
    if (message.docaiProtoPath !== undefined && message.docaiProtoPath !== "") {
      writer.uint32(26).string(message.docaiProtoPath);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExtractFieldsResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExtractFieldsResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.results!.push(ExtractFieldsResultPerFieldResult.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.docaiProtoPath = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExtractFieldsResult {
    return {
      $type: ExtractFieldsResult.$type,
      results: globalThis.Array.isArray(object?.results)
        ? object.results.map((e: any) => ExtractFieldsResultPerFieldResult.fromJSON(e))
        : [],
      docaiProtoPath: isSet(object.docaiProtoPath) ? globalThis.String(object.docaiProtoPath) : "",
    };
  },

  toJSON(message: ExtractFieldsResult): unknown {
    const obj: any = {};
    if (message.results?.length) {
      obj.results = message.results.map((e) => ExtractFieldsResultPerFieldResult.toJSON(e));
    }
    if (message.docaiProtoPath !== undefined && message.docaiProtoPath !== "") {
      obj.docaiProtoPath = message.docaiProtoPath;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExtractFieldsResult>, I>>(base?: I): ExtractFieldsResult {
    return ExtractFieldsResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExtractFieldsResult>, I>>(object: I): ExtractFieldsResult {
    const message = createBaseExtractFieldsResult();
    message.results = object.results?.map((e) => ExtractFieldsResultPerFieldResult.fromPartial(e)) || [];
    message.docaiProtoPath = object.docaiProtoPath ?? "";
    return message;
  },
};

messageTypeRegistry.set(ExtractFieldsResult.$type, ExtractFieldsResult);

function createBaseExtractFieldsResultPerFieldResult(): ExtractFieldsResultPerFieldResult {
  return { $type: "pb.v1alpha1.ExtractFieldsResult.PerFieldResult", extractedField: undefined, confidence: 0 };
}

export const ExtractFieldsResultPerFieldResult = {
  $type: "pb.v1alpha1.ExtractFieldsResult.PerFieldResult" as const,

  encode(message: ExtractFieldsResultPerFieldResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.extractedField !== undefined) {
      Field.encode(message.extractedField, writer.uint32(10).fork()).ldelim();
    }
    if (message.confidence !== undefined && message.confidence !== 0) {
      writer.uint32(21).float(message.confidence);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExtractFieldsResultPerFieldResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExtractFieldsResultPerFieldResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.extractedField = Field.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.confidence = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExtractFieldsResultPerFieldResult {
    return {
      $type: ExtractFieldsResultPerFieldResult.$type,
      extractedField: isSet(object.extractedField) ? Field.fromJSON(object.extractedField) : undefined,
      confidence: isSet(object.confidence) ? globalThis.Number(object.confidence) : 0,
    };
  },

  toJSON(message: ExtractFieldsResultPerFieldResult): unknown {
    const obj: any = {};
    if (message.extractedField !== undefined) {
      obj.extractedField = Field.toJSON(message.extractedField);
    }
    if (message.confidence !== undefined && message.confidence !== 0) {
      obj.confidence = message.confidence;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExtractFieldsResultPerFieldResult>, I>>(
    base?: I,
  ): ExtractFieldsResultPerFieldResult {
    return ExtractFieldsResultPerFieldResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExtractFieldsResultPerFieldResult>, I>>(
    object: I,
  ): ExtractFieldsResultPerFieldResult {
    const message = createBaseExtractFieldsResultPerFieldResult();
    message.extractedField = (object.extractedField !== undefined && object.extractedField !== null)
      ? Field.fromPartial(object.extractedField)
      : undefined;
    message.confidence = object.confidence ?? 0;
    return message;
  },
};

messageTypeRegistry.set(ExtractFieldsResultPerFieldResult.$type, ExtractFieldsResultPerFieldResult);

function createBaseExtractFieldsResultUnified(): ExtractFieldsResultUnified {
  return { $type: "pb.v1alpha1.ExtractFieldsResultUnified", result: undefined };
}

export const ExtractFieldsResultUnified = {
  $type: "pb.v1alpha1.ExtractFieldsResultUnified" as const,

  encode(message: ExtractFieldsResultUnified, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.result !== undefined) {
      ExtractionDocument.encode(message.result, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExtractFieldsResultUnified {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExtractFieldsResultUnified();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.result = ExtractionDocument.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExtractFieldsResultUnified {
    return {
      $type: ExtractFieldsResultUnified.$type,
      result: isSet(object.result) ? ExtractionDocument.fromJSON(object.result) : undefined,
    };
  },

  toJSON(message: ExtractFieldsResultUnified): unknown {
    const obj: any = {};
    if (message.result !== undefined) {
      obj.result = ExtractionDocument.toJSON(message.result);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExtractFieldsResultUnified>, I>>(base?: I): ExtractFieldsResultUnified {
    return ExtractFieldsResultUnified.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExtractFieldsResultUnified>, I>>(object: I): ExtractFieldsResultUnified {
    const message = createBaseExtractFieldsResultUnified();
    message.result = (object.result !== undefined && object.result !== null)
      ? ExtractionDocument.fromPartial(object.result)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(ExtractFieldsResultUnified.$type, ExtractFieldsResultUnified);

function createBaseValidateFieldValues(): ValidateFieldValues {
  return {
    $type: "pb.v1alpha1.ValidateFieldValues",
    document: undefined,
    fields: [],
    rule: "",
    source: undefined,
    target: undefined,
    validateFields: [],
  };
}

export const ValidateFieldValues = {
  $type: "pb.v1alpha1.ValidateFieldValues" as const,

  encode(message: ValidateFieldValues, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.document !== undefined) {
      Document.encode(message.document, writer.uint32(26).fork()).ldelim();
    }
    if (message.fields !== undefined && message.fields.length !== 0) {
      for (const v of message.fields) {
        Field.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    if (message.rule !== undefined && message.rule !== "") {
      writer.uint32(18).string(message.rule);
    }
    if (message.source !== undefined) {
      Item.encode(message.source, writer.uint32(34).fork()).ldelim();
    }
    if (message.target !== undefined) {
      Item.encode(message.target, writer.uint32(42).fork()).ldelim();
    }
    if (message.validateFields !== undefined && message.validateFields.length !== 0) {
      for (const v of message.validateFields) {
        writer.uint32(50).string(v!);
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ValidateFieldValues {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValidateFieldValues();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.document = Document.decode(reader, reader.uint32());
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fields!.push(Field.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.rule = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.source = Item.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.target = Item.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.validateFields!.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ValidateFieldValues {
    return {
      $type: ValidateFieldValues.$type,
      document: isSet(object.document) ? Document.fromJSON(object.document) : undefined,
      fields: globalThis.Array.isArray(object?.fields) ? object.fields.map((e: any) => Field.fromJSON(e)) : [],
      rule: isSet(object.rule) ? globalThis.String(object.rule) : "",
      source: isSet(object.source) ? Item.fromJSON(object.source) : undefined,
      target: isSet(object.target) ? Item.fromJSON(object.target) : undefined,
      validateFields: globalThis.Array.isArray(object?.validateFields)
        ? object.validateFields.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: ValidateFieldValues): unknown {
    const obj: any = {};
    if (message.document !== undefined) {
      obj.document = Document.toJSON(message.document);
    }
    if (message.fields?.length) {
      obj.fields = message.fields.map((e) => Field.toJSON(e));
    }
    if (message.rule !== undefined && message.rule !== "") {
      obj.rule = message.rule;
    }
    if (message.source !== undefined) {
      obj.source = Item.toJSON(message.source);
    }
    if (message.target !== undefined) {
      obj.target = Item.toJSON(message.target);
    }
    if (message.validateFields?.length) {
      obj.validateFields = message.validateFields;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ValidateFieldValues>, I>>(base?: I): ValidateFieldValues {
    return ValidateFieldValues.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ValidateFieldValues>, I>>(object: I): ValidateFieldValues {
    const message = createBaseValidateFieldValues();
    message.document = (object.document !== undefined && object.document !== null)
      ? Document.fromPartial(object.document)
      : undefined;
    message.fields = object.fields?.map((e) => Field.fromPartial(e)) || [];
    message.rule = object.rule ?? "";
    message.source = (object.source !== undefined && object.source !== null)
      ? Item.fromPartial(object.source)
      : undefined;
    message.target = (object.target !== undefined && object.target !== null)
      ? Item.fromPartial(object.target)
      : undefined;
    message.validateFields = object.validateFields?.map((e) => e) || [];
    return message;
  },
};

messageTypeRegistry.set(ValidateFieldValues.$type, ValidateFieldValues);

function createBaseValidateFieldValuesResult(): ValidateFieldValuesResult {
  return {
    $type: "pb.v1alpha1.ValidateFieldValuesResult",
    results: [],
    status: 0,
    confidence: 0,
    explanation: "",
    extractedFields: [],
  };
}

export const ValidateFieldValuesResult = {
  $type: "pb.v1alpha1.ValidateFieldValuesResult" as const,

  encode(message: ValidateFieldValuesResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.results !== undefined && message.results.length !== 0) {
      for (const v of message.results) {
        ValidateFieldValuesResultPerFieldResult.encode(v!, writer.uint32(42).fork()).ldelim();
      }
    }
    if (message.status !== undefined && message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.confidence !== undefined && message.confidence !== 0) {
      writer.uint32(21).float(message.confidence);
    }
    if (message.explanation !== undefined && message.explanation !== "") {
      writer.uint32(26).string(message.explanation);
    }
    if (message.extractedFields !== undefined && message.extractedFields.length !== 0) {
      for (const v of message.extractedFields) {
        Field.encode(v!, writer.uint32(34).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ValidateFieldValuesResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValidateFieldValuesResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 5:
          if (tag !== 42) {
            break;
          }

          message.results!.push(ValidateFieldValuesResultPerFieldResult.decode(reader, reader.uint32()));
          continue;
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.confidence = reader.float();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.explanation = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.extractedFields!.push(Field.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ValidateFieldValuesResult {
    return {
      $type: ValidateFieldValuesResult.$type,
      results: globalThis.Array.isArray(object?.results)
        ? object.results.map((e: any) => ValidateFieldValuesResultPerFieldResult.fromJSON(e))
        : [],
      status: isSet(object.status) ? validateFieldValuesResultStatusFromJSON(object.status) : 0,
      confidence: isSet(object.confidence) ? globalThis.Number(object.confidence) : 0,
      explanation: isSet(object.explanation) ? globalThis.String(object.explanation) : "",
      extractedFields: globalThis.Array.isArray(object?.extractedFields)
        ? object.extractedFields.map((e: any) => Field.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ValidateFieldValuesResult): unknown {
    const obj: any = {};
    if (message.results?.length) {
      obj.results = message.results.map((e) => ValidateFieldValuesResultPerFieldResult.toJSON(e));
    }
    if (message.status !== undefined && message.status !== 0) {
      obj.status = validateFieldValuesResultStatusToJSON(message.status);
    }
    if (message.confidence !== undefined && message.confidence !== 0) {
      obj.confidence = message.confidence;
    }
    if (message.explanation !== undefined && message.explanation !== "") {
      obj.explanation = message.explanation;
    }
    if (message.extractedFields?.length) {
      obj.extractedFields = message.extractedFields.map((e) => Field.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ValidateFieldValuesResult>, I>>(base?: I): ValidateFieldValuesResult {
    return ValidateFieldValuesResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ValidateFieldValuesResult>, I>>(object: I): ValidateFieldValuesResult {
    const message = createBaseValidateFieldValuesResult();
    message.results = object.results?.map((e) => ValidateFieldValuesResultPerFieldResult.fromPartial(e)) || [];
    message.status = object.status ?? 0;
    message.confidence = object.confidence ?? 0;
    message.explanation = object.explanation ?? "";
    message.extractedFields = object.extractedFields?.map((e) => Field.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(ValidateFieldValuesResult.$type, ValidateFieldValuesResult);

function createBaseValidateFieldValuesResultPerFieldResult(): ValidateFieldValuesResultPerFieldResult {
  return {
    $type: "pb.v1alpha1.ValidateFieldValuesResult.PerFieldResult",
    extractedField: undefined,
    status: 0,
    explanation: "",
    confidence: 0,
  };
}

export const ValidateFieldValuesResultPerFieldResult = {
  $type: "pb.v1alpha1.ValidateFieldValuesResult.PerFieldResult" as const,

  encode(message: ValidateFieldValuesResultPerFieldResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.extractedField !== undefined) {
      Field.encode(message.extractedField, writer.uint32(10).fork()).ldelim();
    }
    if (message.status !== undefined && message.status !== 0) {
      writer.uint32(16).int32(message.status);
    }
    if (message.explanation !== undefined && message.explanation !== "") {
      writer.uint32(26).string(message.explanation);
    }
    if (message.confidence !== undefined && message.confidence !== 0) {
      writer.uint32(37).float(message.confidence);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ValidateFieldValuesResultPerFieldResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValidateFieldValuesResultPerFieldResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.extractedField = Field.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.explanation = reader.string();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.confidence = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ValidateFieldValuesResultPerFieldResult {
    return {
      $type: ValidateFieldValuesResultPerFieldResult.$type,
      extractedField: isSet(object.extractedField) ? Field.fromJSON(object.extractedField) : undefined,
      status: isSet(object.status) ? validateFieldValuesResultStatusFromJSON(object.status) : 0,
      explanation: isSet(object.explanation) ? globalThis.String(object.explanation) : "",
      confidence: isSet(object.confidence) ? globalThis.Number(object.confidence) : 0,
    };
  },

  toJSON(message: ValidateFieldValuesResultPerFieldResult): unknown {
    const obj: any = {};
    if (message.extractedField !== undefined) {
      obj.extractedField = Field.toJSON(message.extractedField);
    }
    if (message.status !== undefined && message.status !== 0) {
      obj.status = validateFieldValuesResultStatusToJSON(message.status);
    }
    if (message.explanation !== undefined && message.explanation !== "") {
      obj.explanation = message.explanation;
    }
    if (message.confidence !== undefined && message.confidence !== 0) {
      obj.confidence = message.confidence;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ValidateFieldValuesResultPerFieldResult>, I>>(
    base?: I,
  ): ValidateFieldValuesResultPerFieldResult {
    return ValidateFieldValuesResultPerFieldResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ValidateFieldValuesResultPerFieldResult>, I>>(
    object: I,
  ): ValidateFieldValuesResultPerFieldResult {
    const message = createBaseValidateFieldValuesResultPerFieldResult();
    message.extractedField = (object.extractedField !== undefined && object.extractedField !== null)
      ? Field.fromPartial(object.extractedField)
      : undefined;
    message.status = object.status ?? 0;
    message.explanation = object.explanation ?? "";
    message.confidence = object.confidence ?? 0;
    return message;
  },
};

messageTypeRegistry.set(ValidateFieldValuesResultPerFieldResult.$type, ValidateFieldValuesResultPerFieldResult);

function createBaseFlagKeywords(): FlagKeywords {
  return { $type: "pb.v1alpha1.FlagKeywords", keywords: [], fieldGroups: [], documents: [], rule: "" };
}

export const FlagKeywords = {
  $type: "pb.v1alpha1.FlagKeywords" as const,

  encode(message: FlagKeywords, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.keywords !== undefined && message.keywords.length !== 0) {
      for (const v of message.keywords) {
        writer.uint32(10).string(v!);
      }
    }
    if (message.fieldGroups !== undefined && message.fieldGroups.length !== 0) {
      for (const v of message.fieldGroups) {
        FieldGroup.encode(v!, writer.uint32(18).fork()).ldelim();
      }
    }
    if (message.documents !== undefined && message.documents.length !== 0) {
      for (const v of message.documents) {
        Document.encode(v!, writer.uint32(26).fork()).ldelim();
      }
    }
    if (message.rule !== undefined && message.rule !== "") {
      writer.uint32(34).string(message.rule);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FlagKeywords {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFlagKeywords();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.keywords!.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fieldGroups!.push(FieldGroup.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.documents!.push(Document.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.rule = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FlagKeywords {
    return {
      $type: FlagKeywords.$type,
      keywords: globalThis.Array.isArray(object?.keywords) ? object.keywords.map((e: any) => globalThis.String(e)) : [],
      fieldGroups: globalThis.Array.isArray(object?.fieldGroups)
        ? object.fieldGroups.map((e: any) => FieldGroup.fromJSON(e))
        : [],
      documents: globalThis.Array.isArray(object?.documents)
        ? object.documents.map((e: any) => Document.fromJSON(e))
        : [],
      rule: isSet(object.rule) ? globalThis.String(object.rule) : "",
    };
  },

  toJSON(message: FlagKeywords): unknown {
    const obj: any = {};
    if (message.keywords?.length) {
      obj.keywords = message.keywords;
    }
    if (message.fieldGroups?.length) {
      obj.fieldGroups = message.fieldGroups.map((e) => FieldGroup.toJSON(e));
    }
    if (message.documents?.length) {
      obj.documents = message.documents.map((e) => Document.toJSON(e));
    }
    if (message.rule !== undefined && message.rule !== "") {
      obj.rule = message.rule;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FlagKeywords>, I>>(base?: I): FlagKeywords {
    return FlagKeywords.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FlagKeywords>, I>>(object: I): FlagKeywords {
    const message = createBaseFlagKeywords();
    message.keywords = object.keywords?.map((e) => e) || [];
    message.fieldGroups = object.fieldGroups?.map((e) => FieldGroup.fromPartial(e)) || [];
    message.documents = object.documents?.map((e) => Document.fromPartial(e)) || [];
    message.rule = object.rule ?? "";
    return message;
  },
};

messageTypeRegistry.set(FlagKeywords.$type, FlagKeywords);

function createBaseFlagKeywordsResult(): FlagKeywordsResult {
  return { $type: "pb.v1alpha1.FlagKeywordsResult", results: [], detectedKeywords: [] };
}

export const FlagKeywordsResult = {
  $type: "pb.v1alpha1.FlagKeywordsResult" as const,

  encode(message: FlagKeywordsResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.results !== undefined && message.results.length !== 0) {
      for (const v of message.results) {
        FlagKeywordsResultResult.encode(v!, writer.uint32(18).fork()).ldelim();
      }
    }
    if (message.detectedKeywords !== undefined && message.detectedKeywords.length !== 0) {
      for (const v of message.detectedKeywords) {
        writer.uint32(10).string(v!);
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FlagKeywordsResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFlagKeywordsResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.results!.push(FlagKeywordsResultResult.decode(reader, reader.uint32()));
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.detectedKeywords!.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FlagKeywordsResult {
    return {
      $type: FlagKeywordsResult.$type,
      results: globalThis.Array.isArray(object?.results)
        ? object.results.map((e: any) => FlagKeywordsResultResult.fromJSON(e))
        : [],
      detectedKeywords: globalThis.Array.isArray(object?.detectedKeywords)
        ? object.detectedKeywords.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: FlagKeywordsResult): unknown {
    const obj: any = {};
    if (message.results?.length) {
      obj.results = message.results.map((e) => FlagKeywordsResultResult.toJSON(e));
    }
    if (message.detectedKeywords?.length) {
      obj.detectedKeywords = message.detectedKeywords;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FlagKeywordsResult>, I>>(base?: I): FlagKeywordsResult {
    return FlagKeywordsResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FlagKeywordsResult>, I>>(object: I): FlagKeywordsResult {
    const message = createBaseFlagKeywordsResult();
    message.results = object.results?.map((e) => FlagKeywordsResultResult.fromPartial(e)) || [];
    message.detectedKeywords = object.detectedKeywords?.map((e) => e) || [];
    return message;
  },
};

messageTypeRegistry.set(FlagKeywordsResult.$type, FlagKeywordsResult);

function createBaseFlagKeywordsResultFieldResult(): FlagKeywordsResultFieldResult {
  return {
    $type: "pb.v1alpha1.FlagKeywordsResult.FieldResult",
    fieldGroupIndex: 0,
    fieldIndex: 0,
    detectedKeywords: [],
  };
}

export const FlagKeywordsResultFieldResult = {
  $type: "pb.v1alpha1.FlagKeywordsResult.FieldResult" as const,

  encode(message: FlagKeywordsResultFieldResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fieldGroupIndex !== undefined && message.fieldGroupIndex !== 0) {
      writer.uint32(8).int32(message.fieldGroupIndex);
    }
    if (message.fieldIndex !== undefined && message.fieldIndex !== 0) {
      writer.uint32(16).int32(message.fieldIndex);
    }
    if (message.detectedKeywords !== undefined && message.detectedKeywords.length !== 0) {
      for (const v of message.detectedKeywords) {
        writer.uint32(26).string(v!);
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FlagKeywordsResultFieldResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFlagKeywordsResultFieldResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.fieldGroupIndex = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.fieldIndex = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.detectedKeywords!.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FlagKeywordsResultFieldResult {
    return {
      $type: FlagKeywordsResultFieldResult.$type,
      fieldGroupIndex: isSet(object.fieldGroupIndex) ? globalThis.Number(object.fieldGroupIndex) : 0,
      fieldIndex: isSet(object.fieldIndex) ? globalThis.Number(object.fieldIndex) : 0,
      detectedKeywords: globalThis.Array.isArray(object?.detectedKeywords)
        ? object.detectedKeywords.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: FlagKeywordsResultFieldResult): unknown {
    const obj: any = {};
    if (message.fieldGroupIndex !== undefined && message.fieldGroupIndex !== 0) {
      obj.fieldGroupIndex = Math.round(message.fieldGroupIndex);
    }
    if (message.fieldIndex !== undefined && message.fieldIndex !== 0) {
      obj.fieldIndex = Math.round(message.fieldIndex);
    }
    if (message.detectedKeywords?.length) {
      obj.detectedKeywords = message.detectedKeywords;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FlagKeywordsResultFieldResult>, I>>(base?: I): FlagKeywordsResultFieldResult {
    return FlagKeywordsResultFieldResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FlagKeywordsResultFieldResult>, I>>(
    object: I,
  ): FlagKeywordsResultFieldResult {
    const message = createBaseFlagKeywordsResultFieldResult();
    message.fieldGroupIndex = object.fieldGroupIndex ?? 0;
    message.fieldIndex = object.fieldIndex ?? 0;
    message.detectedKeywords = object.detectedKeywords?.map((e) => e) || [];
    return message;
  },
};

messageTypeRegistry.set(FlagKeywordsResultFieldResult.$type, FlagKeywordsResultFieldResult);

function createBaseFlagKeywordsResultDocumentResult(): FlagKeywordsResultDocumentResult {
  return {
    $type: "pb.v1alpha1.FlagKeywordsResult.DocumentResult",
    documentIndex: 0,
    pageNumber: 0,
    detectedKeywords: [],
  };
}

export const FlagKeywordsResultDocumentResult = {
  $type: "pb.v1alpha1.FlagKeywordsResult.DocumentResult" as const,

  encode(message: FlagKeywordsResultDocumentResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.documentIndex !== undefined && message.documentIndex !== 0) {
      writer.uint32(8).int32(message.documentIndex);
    }
    if (message.pageNumber !== undefined && message.pageNumber !== 0) {
      writer.uint32(16).int32(message.pageNumber);
    }
    if (message.detectedKeywords !== undefined && message.detectedKeywords.length !== 0) {
      for (const v of message.detectedKeywords) {
        writer.uint32(26).string(v!);
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FlagKeywordsResultDocumentResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFlagKeywordsResultDocumentResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.documentIndex = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.pageNumber = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.detectedKeywords!.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FlagKeywordsResultDocumentResult {
    return {
      $type: FlagKeywordsResultDocumentResult.$type,
      documentIndex: isSet(object.documentIndex) ? globalThis.Number(object.documentIndex) : 0,
      pageNumber: isSet(object.pageNumber) ? globalThis.Number(object.pageNumber) : 0,
      detectedKeywords: globalThis.Array.isArray(object?.detectedKeywords)
        ? object.detectedKeywords.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: FlagKeywordsResultDocumentResult): unknown {
    const obj: any = {};
    if (message.documentIndex !== undefined && message.documentIndex !== 0) {
      obj.documentIndex = Math.round(message.documentIndex);
    }
    if (message.pageNumber !== undefined && message.pageNumber !== 0) {
      obj.pageNumber = Math.round(message.pageNumber);
    }
    if (message.detectedKeywords?.length) {
      obj.detectedKeywords = message.detectedKeywords;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FlagKeywordsResultDocumentResult>, I>>(
    base?: I,
  ): FlagKeywordsResultDocumentResult {
    return FlagKeywordsResultDocumentResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FlagKeywordsResultDocumentResult>, I>>(
    object: I,
  ): FlagKeywordsResultDocumentResult {
    const message = createBaseFlagKeywordsResultDocumentResult();
    message.documentIndex = object.documentIndex ?? 0;
    message.pageNumber = object.pageNumber ?? 0;
    message.detectedKeywords = object.detectedKeywords?.map((e) => e) || [];
    return message;
  },
};

messageTypeRegistry.set(FlagKeywordsResultDocumentResult.$type, FlagKeywordsResultDocumentResult);

function createBaseFlagKeywordsResultResult(): FlagKeywordsResultResult {
  return { $type: "pb.v1alpha1.FlagKeywordsResult.Result", fieldResult: undefined, documentResult: undefined };
}

export const FlagKeywordsResultResult = {
  $type: "pb.v1alpha1.FlagKeywordsResult.Result" as const,

  encode(message: FlagKeywordsResultResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fieldResult !== undefined) {
      FlagKeywordsResultFieldResult.encode(message.fieldResult, writer.uint32(10).fork()).ldelim();
    }
    if (message.documentResult !== undefined) {
      FlagKeywordsResultDocumentResult.encode(message.documentResult, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FlagKeywordsResultResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFlagKeywordsResultResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fieldResult = FlagKeywordsResultFieldResult.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.documentResult = FlagKeywordsResultDocumentResult.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FlagKeywordsResultResult {
    return {
      $type: FlagKeywordsResultResult.$type,
      fieldResult: isSet(object.fieldResult) ? FlagKeywordsResultFieldResult.fromJSON(object.fieldResult) : undefined,
      documentResult: isSet(object.documentResult)
        ? FlagKeywordsResultDocumentResult.fromJSON(object.documentResult)
        : undefined,
    };
  },

  toJSON(message: FlagKeywordsResultResult): unknown {
    const obj: any = {};
    if (message.fieldResult !== undefined) {
      obj.fieldResult = FlagKeywordsResultFieldResult.toJSON(message.fieldResult);
    }
    if (message.documentResult !== undefined) {
      obj.documentResult = FlagKeywordsResultDocumentResult.toJSON(message.documentResult);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FlagKeywordsResultResult>, I>>(base?: I): FlagKeywordsResultResult {
    return FlagKeywordsResultResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FlagKeywordsResultResult>, I>>(object: I): FlagKeywordsResultResult {
    const message = createBaseFlagKeywordsResultResult();
    message.fieldResult = (object.fieldResult !== undefined && object.fieldResult !== null)
      ? FlagKeywordsResultFieldResult.fromPartial(object.fieldResult)
      : undefined;
    message.documentResult = (object.documentResult !== undefined && object.documentResult !== null)
      ? FlagKeywordsResultDocumentResult.fromPartial(object.documentResult)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(FlagKeywordsResultResult.$type, FlagKeywordsResultResult);

function createBaseItem(): Item {
  return { $type: "pb.v1alpha1.Item", fieldGroups: [], documents: [], smartActionRecords: [] };
}

export const Item = {
  $type: "pb.v1alpha1.Item" as const,

  encode(message: Item, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fieldGroups !== undefined && message.fieldGroups.length !== 0) {
      for (const v of message.fieldGroups) {
        FieldGroup.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    if (message.documents !== undefined && message.documents.length !== 0) {
      for (const v of message.documents) {
        ItemDocument.encode(v!, writer.uint32(18).fork()).ldelim();
      }
    }
    if (message.smartActionRecords !== undefined && message.smartActionRecords.length !== 0) {
      for (const v of message.smartActionRecords) {
        SmartActionRequestAndResult.encode(v!, writer.uint32(26).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Item {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fieldGroups!.push(FieldGroup.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.documents!.push(ItemDocument.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.smartActionRecords!.push(SmartActionRequestAndResult.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Item {
    return {
      $type: Item.$type,
      fieldGroups: globalThis.Array.isArray(object?.fieldGroups)
        ? object.fieldGroups.map((e: any) => FieldGroup.fromJSON(e))
        : [],
      documents: globalThis.Array.isArray(object?.documents)
        ? object.documents.map((e: any) => ItemDocument.fromJSON(e))
        : [],
      smartActionRecords: globalThis.Array.isArray(object?.smartActionRecords)
        ? object.smartActionRecords.map((e: any) => SmartActionRequestAndResult.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Item): unknown {
    const obj: any = {};
    if (message.fieldGroups?.length) {
      obj.fieldGroups = message.fieldGroups.map((e) => FieldGroup.toJSON(e));
    }
    if (message.documents?.length) {
      obj.documents = message.documents.map((e) => ItemDocument.toJSON(e));
    }
    if (message.smartActionRecords?.length) {
      obj.smartActionRecords = message.smartActionRecords.map((e) => SmartActionRequestAndResult.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Item>, I>>(base?: I): Item {
    return Item.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Item>, I>>(object: I): Item {
    const message = createBaseItem();
    message.fieldGroups = object.fieldGroups?.map((e) => FieldGroup.fromPartial(e)) || [];
    message.documents = object.documents?.map((e) => ItemDocument.fromPartial(e)) || [];
    message.smartActionRecords = object.smartActionRecords?.map((e) => SmartActionRequestAndResult.fromPartial(e)) ||
      [];
    return message;
  },
};

messageTypeRegistry.set(Item.$type, Item);

function createBaseEnrichEntitiesRequest(): EnrichEntitiesRequest {
  return { $type: "pb.v1alpha1.EnrichEntitiesRequest", fieldGroup: undefined, documents: [] };
}

export const EnrichEntitiesRequest = {
  $type: "pb.v1alpha1.EnrichEntitiesRequest" as const,

  encode(message: EnrichEntitiesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fieldGroup !== undefined) {
      FieldGroup.encode(message.fieldGroup, writer.uint32(10).fork()).ldelim();
    }
    if (message.documents !== undefined && message.documents.length !== 0) {
      for (const v of message.documents) {
        ExtractionDocument.encode(v!, writer.uint32(18).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnrichEntitiesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrichEntitiesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fieldGroup = FieldGroup.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.documents!.push(ExtractionDocument.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnrichEntitiesRequest {
    return {
      $type: EnrichEntitiesRequest.$type,
      fieldGroup: isSet(object.fieldGroup) ? FieldGroup.fromJSON(object.fieldGroup) : undefined,
      documents: globalThis.Array.isArray(object?.documents)
        ? object.documents.map((e: any) => ExtractionDocument.fromJSON(e))
        : [],
    };
  },

  toJSON(message: EnrichEntitiesRequest): unknown {
    const obj: any = {};
    if (message.fieldGroup !== undefined) {
      obj.fieldGroup = FieldGroup.toJSON(message.fieldGroup);
    }
    if (message.documents?.length) {
      obj.documents = message.documents.map((e) => ExtractionDocument.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EnrichEntitiesRequest>, I>>(base?: I): EnrichEntitiesRequest {
    return EnrichEntitiesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EnrichEntitiesRequest>, I>>(object: I): EnrichEntitiesRequest {
    const message = createBaseEnrichEntitiesRequest();
    message.fieldGroup = (object.fieldGroup !== undefined && object.fieldGroup !== null)
      ? FieldGroup.fromPartial(object.fieldGroup)
      : undefined;
    message.documents = object.documents?.map((e) => ExtractionDocument.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(EnrichEntitiesRequest.$type, EnrichEntitiesRequest);

function createBaseItemDocument(): ItemDocument {
  return { $type: "pb.v1alpha1.ItemDocument", document: undefined, fields: [] };
}

export const ItemDocument = {
  $type: "pb.v1alpha1.ItemDocument" as const,

  encode(message: ItemDocument, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.document !== undefined) {
      Document.encode(message.document, writer.uint32(10).fork()).ldelim();
    }
    if (message.fields !== undefined && message.fields.length !== 0) {
      for (const v of message.fields) {
        Field.encode(v!, writer.uint32(18).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ItemDocument {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseItemDocument();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.document = Document.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fields!.push(Field.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ItemDocument {
    return {
      $type: ItemDocument.$type,
      document: isSet(object.document) ? Document.fromJSON(object.document) : undefined,
      fields: globalThis.Array.isArray(object?.fields) ? object.fields.map((e: any) => Field.fromJSON(e)) : [],
    };
  },

  toJSON(message: ItemDocument): unknown {
    const obj: any = {};
    if (message.document !== undefined) {
      obj.document = Document.toJSON(message.document);
    }
    if (message.fields?.length) {
      obj.fields = message.fields.map((e) => Field.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ItemDocument>, I>>(base?: I): ItemDocument {
    return ItemDocument.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ItemDocument>, I>>(object: I): ItemDocument {
    const message = createBaseItemDocument();
    message.document = (object.document !== undefined && object.document !== null)
      ? Document.fromPartial(object.document)
      : undefined;
    message.fields = object.fields?.map((e) => Field.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(ItemDocument.$type, ItemDocument);

function createBaseExtractionDocument(): ExtractionDocument {
  return { $type: "pb.v1alpha1.ExtractionDocument", document: undefined, fieldGroup: undefined };
}

export const ExtractionDocument = {
  $type: "pb.v1alpha1.ExtractionDocument" as const,

  encode(message: ExtractionDocument, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.document !== undefined) {
      Document.encode(message.document, writer.uint32(10).fork()).ldelim();
    }
    if (message.fieldGroup !== undefined) {
      FieldGroup.encode(message.fieldGroup, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExtractionDocument {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExtractionDocument();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.document = Document.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fieldGroup = FieldGroup.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExtractionDocument {
    return {
      $type: ExtractionDocument.$type,
      document: isSet(object.document) ? Document.fromJSON(object.document) : undefined,
      fieldGroup: isSet(object.fieldGroup) ? FieldGroup.fromJSON(object.fieldGroup) : undefined,
    };
  },

  toJSON(message: ExtractionDocument): unknown {
    const obj: any = {};
    if (message.document !== undefined) {
      obj.document = Document.toJSON(message.document);
    }
    if (message.fieldGroup !== undefined) {
      obj.fieldGroup = FieldGroup.toJSON(message.fieldGroup);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExtractionDocument>, I>>(base?: I): ExtractionDocument {
    return ExtractionDocument.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExtractionDocument>, I>>(object: I): ExtractionDocument {
    const message = createBaseExtractionDocument();
    message.document = (object.document !== undefined && object.document !== null)
      ? Document.fromPartial(object.document)
      : undefined;
    message.fieldGroup = (object.fieldGroup !== undefined && object.fieldGroup !== null)
      ? FieldGroup.fromPartial(object.fieldGroup)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(ExtractionDocument.$type, ExtractionDocument);

function createBaseSmartActionRequestAndResult(): SmartActionRequestAndResult {
  return { $type: "pb.v1alpha1.SmartActionRequestAndResult", request: undefined, result: undefined };
}

export const SmartActionRequestAndResult = {
  $type: "pb.v1alpha1.SmartActionRequestAndResult" as const,

  encode(message: SmartActionRequestAndResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.request !== undefined) {
      ProcessSmartActionsRequest.encode(message.request, writer.uint32(10).fork()).ldelim();
    }
    if (message.result !== undefined) {
      SmartActionResult.encode(message.result, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SmartActionRequestAndResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSmartActionRequestAndResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.request = ProcessSmartActionsRequest.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.result = SmartActionResult.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SmartActionRequestAndResult {
    return {
      $type: SmartActionRequestAndResult.$type,
      request: isSet(object.request) ? ProcessSmartActionsRequest.fromJSON(object.request) : undefined,
      result: isSet(object.result) ? SmartActionResult.fromJSON(object.result) : undefined,
    };
  },

  toJSON(message: SmartActionRequestAndResult): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = ProcessSmartActionsRequest.toJSON(message.request);
    }
    if (message.result !== undefined) {
      obj.result = SmartActionResult.toJSON(message.result);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SmartActionRequestAndResult>, I>>(base?: I): SmartActionRequestAndResult {
    return SmartActionRequestAndResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SmartActionRequestAndResult>, I>>(object: I): SmartActionRequestAndResult {
    const message = createBaseSmartActionRequestAndResult();
    message.request = (object.request !== undefined && object.request !== null)
      ? ProcessSmartActionsRequest.fromPartial(object.request)
      : undefined;
    message.result = (object.result !== undefined && object.result !== null)
      ? SmartActionResult.fromPartial(object.result)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(SmartActionRequestAndResult.$type, SmartActionRequestAndResult);

function createBaseFieldGroupMatch(): FieldGroupMatch {
  return {
    $type: "pb.v1alpha1.FieldGroupMatch",
    match: undefined,
    unmatchedSource: undefined,
    unmatchedTarget: undefined,
    confidence: 0,
    explanation: "",
  };
}

export const FieldGroupMatch = {
  $type: "pb.v1alpha1.FieldGroupMatch" as const,

  encode(message: FieldGroupMatch, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.match !== undefined) {
      FieldGroupMatchMatchedFieldGroup.encode(message.match, writer.uint32(18).fork()).ldelim();
    }
    if (message.unmatchedSource !== undefined) {
      FieldGroupMatchUnmatchedFieldGroup.encode(message.unmatchedSource, writer.uint32(26).fork()).ldelim();
    }
    if (message.unmatchedTarget !== undefined) {
      FieldGroupMatchUnmatchedFieldGroup.encode(message.unmatchedTarget, writer.uint32(34).fork()).ldelim();
    }
    if (message.confidence !== undefined && message.confidence !== 0) {
      writer.uint32(45).float(message.confidence);
    }
    if (message.explanation !== undefined && message.explanation !== "") {
      writer.uint32(50).string(message.explanation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FieldGroupMatch {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFieldGroupMatch();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.match = FieldGroupMatchMatchedFieldGroup.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.unmatchedSource = FieldGroupMatchUnmatchedFieldGroup.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.unmatchedTarget = FieldGroupMatchUnmatchedFieldGroup.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.confidence = reader.float();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.explanation = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FieldGroupMatch {
    return {
      $type: FieldGroupMatch.$type,
      match: isSet(object.match) ? FieldGroupMatchMatchedFieldGroup.fromJSON(object.match) : undefined,
      unmatchedSource: isSet(object.unmatchedSource)
        ? FieldGroupMatchUnmatchedFieldGroup.fromJSON(object.unmatchedSource)
        : undefined,
      unmatchedTarget: isSet(object.unmatchedTarget)
        ? FieldGroupMatchUnmatchedFieldGroup.fromJSON(object.unmatchedTarget)
        : undefined,
      confidence: isSet(object.confidence) ? globalThis.Number(object.confidence) : 0,
      explanation: isSet(object.explanation) ? globalThis.String(object.explanation) : "",
    };
  },

  toJSON(message: FieldGroupMatch): unknown {
    const obj: any = {};
    if (message.match !== undefined) {
      obj.match = FieldGroupMatchMatchedFieldGroup.toJSON(message.match);
    }
    if (message.unmatchedSource !== undefined) {
      obj.unmatchedSource = FieldGroupMatchUnmatchedFieldGroup.toJSON(message.unmatchedSource);
    }
    if (message.unmatchedTarget !== undefined) {
      obj.unmatchedTarget = FieldGroupMatchUnmatchedFieldGroup.toJSON(message.unmatchedTarget);
    }
    if (message.confidence !== undefined && message.confidence !== 0) {
      obj.confidence = message.confidence;
    }
    if (message.explanation !== undefined && message.explanation !== "") {
      obj.explanation = message.explanation;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FieldGroupMatch>, I>>(base?: I): FieldGroupMatch {
    return FieldGroupMatch.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FieldGroupMatch>, I>>(object: I): FieldGroupMatch {
    const message = createBaseFieldGroupMatch();
    message.match = (object.match !== undefined && object.match !== null)
      ? FieldGroupMatchMatchedFieldGroup.fromPartial(object.match)
      : undefined;
    message.unmatchedSource = (object.unmatchedSource !== undefined && object.unmatchedSource !== null)
      ? FieldGroupMatchUnmatchedFieldGroup.fromPartial(object.unmatchedSource)
      : undefined;
    message.unmatchedTarget = (object.unmatchedTarget !== undefined && object.unmatchedTarget !== null)
      ? FieldGroupMatchUnmatchedFieldGroup.fromPartial(object.unmatchedTarget)
      : undefined;
    message.confidence = object.confidence ?? 0;
    message.explanation = object.explanation ?? "";
    return message;
  },
};

messageTypeRegistry.set(FieldGroupMatch.$type, FieldGroupMatch);

function createBaseFieldGroupMatchMatchedFieldGroup(): FieldGroupMatchMatchedFieldGroup {
  return {
    $type: "pb.v1alpha1.FieldGroupMatch.MatchedFieldGroup",
    sourceIndex: 0,
    targetIndex: 0,
    sourceFieldUpdates: [],
    updateError: "",
  };
}

export const FieldGroupMatchMatchedFieldGroup = {
  $type: "pb.v1alpha1.FieldGroupMatch.MatchedFieldGroup" as const,

  encode(message: FieldGroupMatchMatchedFieldGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sourceIndex !== undefined && message.sourceIndex !== 0) {
      writer.uint32(8).int32(message.sourceIndex);
    }
    if (message.targetIndex !== undefined && message.targetIndex !== 0) {
      writer.uint32(16).int32(message.targetIndex);
    }
    if (message.sourceFieldUpdates !== undefined && message.sourceFieldUpdates.length !== 0) {
      for (const v of message.sourceFieldUpdates) {
        Field.encode(v!, writer.uint32(26).fork()).ldelim();
      }
    }
    if (message.updateError !== undefined && message.updateError !== "") {
      writer.uint32(34).string(message.updateError);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FieldGroupMatchMatchedFieldGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFieldGroupMatchMatchedFieldGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.sourceIndex = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.targetIndex = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.sourceFieldUpdates!.push(Field.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.updateError = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FieldGroupMatchMatchedFieldGroup {
    return {
      $type: FieldGroupMatchMatchedFieldGroup.$type,
      sourceIndex: isSet(object.sourceIndex) ? globalThis.Number(object.sourceIndex) : 0,
      targetIndex: isSet(object.targetIndex) ? globalThis.Number(object.targetIndex) : 0,
      sourceFieldUpdates: globalThis.Array.isArray(object?.sourceFieldUpdates)
        ? object.sourceFieldUpdates.map((e: any) => Field.fromJSON(e))
        : [],
      updateError: isSet(object.updateError) ? globalThis.String(object.updateError) : "",
    };
  },

  toJSON(message: FieldGroupMatchMatchedFieldGroup): unknown {
    const obj: any = {};
    if (message.sourceIndex !== undefined && message.sourceIndex !== 0) {
      obj.sourceIndex = Math.round(message.sourceIndex);
    }
    if (message.targetIndex !== undefined && message.targetIndex !== 0) {
      obj.targetIndex = Math.round(message.targetIndex);
    }
    if (message.sourceFieldUpdates?.length) {
      obj.sourceFieldUpdates = message.sourceFieldUpdates.map((e) => Field.toJSON(e));
    }
    if (message.updateError !== undefined && message.updateError !== "") {
      obj.updateError = message.updateError;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FieldGroupMatchMatchedFieldGroup>, I>>(
    base?: I,
  ): FieldGroupMatchMatchedFieldGroup {
    return FieldGroupMatchMatchedFieldGroup.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FieldGroupMatchMatchedFieldGroup>, I>>(
    object: I,
  ): FieldGroupMatchMatchedFieldGroup {
    const message = createBaseFieldGroupMatchMatchedFieldGroup();
    message.sourceIndex = object.sourceIndex ?? 0;
    message.targetIndex = object.targetIndex ?? 0;
    message.sourceFieldUpdates = object.sourceFieldUpdates?.map((e) => Field.fromPartial(e)) || [];
    message.updateError = object.updateError ?? "";
    return message;
  },
};

messageTypeRegistry.set(FieldGroupMatchMatchedFieldGroup.$type, FieldGroupMatchMatchedFieldGroup);

function createBaseFieldGroupMatchUnmatchedFieldGroup(): FieldGroupMatchUnmatchedFieldGroup {
  return { $type: "pb.v1alpha1.FieldGroupMatch.UnmatchedFieldGroup", index: 0 };
}

export const FieldGroupMatchUnmatchedFieldGroup = {
  $type: "pb.v1alpha1.FieldGroupMatch.UnmatchedFieldGroup" as const,

  encode(message: FieldGroupMatchUnmatchedFieldGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.index !== undefined && message.index !== 0) {
      writer.uint32(8).int32(message.index);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FieldGroupMatchUnmatchedFieldGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFieldGroupMatchUnmatchedFieldGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.index = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FieldGroupMatchUnmatchedFieldGroup {
    return {
      $type: FieldGroupMatchUnmatchedFieldGroup.$type,
      index: isSet(object.index) ? globalThis.Number(object.index) : 0,
    };
  },

  toJSON(message: FieldGroupMatchUnmatchedFieldGroup): unknown {
    const obj: any = {};
    if (message.index !== undefined && message.index !== 0) {
      obj.index = Math.round(message.index);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FieldGroupMatchUnmatchedFieldGroup>, I>>(
    base?: I,
  ): FieldGroupMatchUnmatchedFieldGroup {
    return FieldGroupMatchUnmatchedFieldGroup.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FieldGroupMatchUnmatchedFieldGroup>, I>>(
    object: I,
  ): FieldGroupMatchUnmatchedFieldGroup {
    const message = createBaseFieldGroupMatchUnmatchedFieldGroup();
    message.index = object.index ?? 0;
    return message;
  },
};

messageTypeRegistry.set(FieldGroupMatchUnmatchedFieldGroup.$type, FieldGroupMatchUnmatchedFieldGroup);

function createBaseFieldMatch(): FieldMatch {
  return {
    $type: "pb.v1alpha1.FieldMatch",
    sourceIds: [],
    targetIds: [],
    updatedSourceFields: undefined,
    updateError: "",
    confidence: 0,
    explanation: "",
  };
}

export const FieldMatch = {
  $type: "pb.v1alpha1.FieldMatch" as const,

  encode(message: FieldMatch, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sourceIds !== undefined && message.sourceIds.length !== 0) {
      for (const v of message.sourceIds) {
        writer.uint32(10).string(v!);
      }
    }
    if (message.targetIds !== undefined && message.targetIds.length !== 0) {
      for (const v of message.targetIds) {
        writer.uint32(18).string(v!);
      }
    }
    if (message.updatedSourceFields !== undefined) {
      FieldGroup.encode(message.updatedSourceFields, writer.uint32(26).fork()).ldelim();
    }
    if (message.updateError !== undefined && message.updateError !== "") {
      writer.uint32(34).string(message.updateError);
    }
    if (message.confidence !== undefined && message.confidence !== 0) {
      writer.uint32(45).float(message.confidence);
    }
    if (message.explanation !== undefined && message.explanation !== "") {
      writer.uint32(50).string(message.explanation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FieldMatch {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFieldMatch();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sourceIds!.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.targetIds!.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.updatedSourceFields = FieldGroup.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.updateError = reader.string();
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.confidence = reader.float();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.explanation = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FieldMatch {
    return {
      $type: FieldMatch.$type,
      sourceIds: globalThis.Array.isArray(object?.sourceIds)
        ? object.sourceIds.map((e: any) => globalThis.String(e))
        : [],
      targetIds: globalThis.Array.isArray(object?.targetIds)
        ? object.targetIds.map((e: any) => globalThis.String(e))
        : [],
      updatedSourceFields: isSet(object.updatedSourceFields)
        ? FieldGroup.fromJSON(object.updatedSourceFields)
        : undefined,
      updateError: isSet(object.updateError) ? globalThis.String(object.updateError) : "",
      confidence: isSet(object.confidence) ? globalThis.Number(object.confidence) : 0,
      explanation: isSet(object.explanation) ? globalThis.String(object.explanation) : "",
    };
  },

  toJSON(message: FieldMatch): unknown {
    const obj: any = {};
    if (message.sourceIds?.length) {
      obj.sourceIds = message.sourceIds;
    }
    if (message.targetIds?.length) {
      obj.targetIds = message.targetIds;
    }
    if (message.updatedSourceFields !== undefined) {
      obj.updatedSourceFields = FieldGroup.toJSON(message.updatedSourceFields);
    }
    if (message.updateError !== undefined && message.updateError !== "") {
      obj.updateError = message.updateError;
    }
    if (message.confidence !== undefined && message.confidence !== 0) {
      obj.confidence = message.confidence;
    }
    if (message.explanation !== undefined && message.explanation !== "") {
      obj.explanation = message.explanation;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FieldMatch>, I>>(base?: I): FieldMatch {
    return FieldMatch.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FieldMatch>, I>>(object: I): FieldMatch {
    const message = createBaseFieldMatch();
    message.sourceIds = object.sourceIds?.map((e) => e) || [];
    message.targetIds = object.targetIds?.map((e) => e) || [];
    message.updatedSourceFields = (object.updatedSourceFields !== undefined && object.updatedSourceFields !== null)
      ? FieldGroup.fromPartial(object.updatedSourceFields)
      : undefined;
    message.updateError = object.updateError ?? "";
    message.confidence = object.confidence ?? 0;
    message.explanation = object.explanation ?? "";
    return message;
  },
};

messageTypeRegistry.set(FieldMatch.$type, FieldMatch);

function createBaseDetectDuplicateLineItems(): DetectDuplicateLineItems {
  return { $type: "pb.v1alpha1.DetectDuplicateLineItems", items: [], rule: "" };
}

export const DetectDuplicateLineItems = {
  $type: "pb.v1alpha1.DetectDuplicateLineItems" as const,

  encode(message: DetectDuplicateLineItems, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.items !== undefined && message.items.length !== 0) {
      for (const v of message.items) {
        Item.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    if (message.rule !== undefined && message.rule !== "") {
      writer.uint32(18).string(message.rule);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DetectDuplicateLineItems {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDetectDuplicateLineItems();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.items!.push(Item.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.rule = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DetectDuplicateLineItems {
    return {
      $type: DetectDuplicateLineItems.$type,
      items: globalThis.Array.isArray(object?.items) ? object.items.map((e: any) => Item.fromJSON(e)) : [],
      rule: isSet(object.rule) ? globalThis.String(object.rule) : "",
    };
  },

  toJSON(message: DetectDuplicateLineItems): unknown {
    const obj: any = {};
    if (message.items?.length) {
      obj.items = message.items.map((e) => Item.toJSON(e));
    }
    if (message.rule !== undefined && message.rule !== "") {
      obj.rule = message.rule;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DetectDuplicateLineItems>, I>>(base?: I): DetectDuplicateLineItems {
    return DetectDuplicateLineItems.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DetectDuplicateLineItems>, I>>(object: I): DetectDuplicateLineItems {
    const message = createBaseDetectDuplicateLineItems();
    message.items = object.items?.map((e) => Item.fromPartial(e)) || [];
    message.rule = object.rule ?? "";
    return message;
  },
};

messageTypeRegistry.set(DetectDuplicateLineItems.$type, DetectDuplicateLineItems);

function createBaseDetectDuplicateLineItemsResult(): DetectDuplicateLineItemsResult {
  return { $type: "pb.v1alpha1.DetectDuplicateLineItemsResult", duplicates: [], confidence: 0 };
}

export const DetectDuplicateLineItemsResult = {
  $type: "pb.v1alpha1.DetectDuplicateLineItemsResult" as const,

  encode(message: DetectDuplicateLineItemsResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.duplicates !== undefined && message.duplicates.length !== 0) {
      for (const v of message.duplicates) {
        DetectDuplicateLineItemsResultDuplicateGroup.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    if (message.confidence !== undefined && message.confidence !== 0) {
      writer.uint32(21).float(message.confidence);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DetectDuplicateLineItemsResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDetectDuplicateLineItemsResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.duplicates!.push(DetectDuplicateLineItemsResultDuplicateGroup.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.confidence = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DetectDuplicateLineItemsResult {
    return {
      $type: DetectDuplicateLineItemsResult.$type,
      duplicates: globalThis.Array.isArray(object?.duplicates)
        ? object.duplicates.map((e: any) => DetectDuplicateLineItemsResultDuplicateGroup.fromJSON(e))
        : [],
      confidence: isSet(object.confidence) ? globalThis.Number(object.confidence) : 0,
    };
  },

  toJSON(message: DetectDuplicateLineItemsResult): unknown {
    const obj: any = {};
    if (message.duplicates?.length) {
      obj.duplicates = message.duplicates.map((e) => DetectDuplicateLineItemsResultDuplicateGroup.toJSON(e));
    }
    if (message.confidence !== undefined && message.confidence !== 0) {
      obj.confidence = message.confidence;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DetectDuplicateLineItemsResult>, I>>(base?: I): DetectDuplicateLineItemsResult {
    return DetectDuplicateLineItemsResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DetectDuplicateLineItemsResult>, I>>(
    object: I,
  ): DetectDuplicateLineItemsResult {
    const message = createBaseDetectDuplicateLineItemsResult();
    message.duplicates = object.duplicates?.map((e) => DetectDuplicateLineItemsResultDuplicateGroup.fromPartial(e)) ||
      [];
    message.confidence = object.confidence ?? 0;
    return message;
  },
};

messageTypeRegistry.set(DetectDuplicateLineItemsResult.$type, DetectDuplicateLineItemsResult);

function createBaseDetectDuplicateLineItemsResultDuplicateGroup(): DetectDuplicateLineItemsResultDuplicateGroup {
  return { $type: "pb.v1alpha1.DetectDuplicateLineItemsResult.DuplicateGroup", itemIndices: [], explanation: "" };
}

export const DetectDuplicateLineItemsResultDuplicateGroup = {
  $type: "pb.v1alpha1.DetectDuplicateLineItemsResult.DuplicateGroup" as const,

  encode(message: DetectDuplicateLineItemsResultDuplicateGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.itemIndices !== undefined && message.itemIndices.length !== 0) {
      writer.uint32(10).fork();
      for (const v of message.itemIndices) {
        writer.int32(v);
      }
      writer.ldelim();
    }
    if (message.explanation !== undefined && message.explanation !== "") {
      writer.uint32(18).string(message.explanation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DetectDuplicateLineItemsResultDuplicateGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDetectDuplicateLineItemsResultDuplicateGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.itemIndices!.push(reader.int32());

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.itemIndices!.push(reader.int32());
            }

            continue;
          }

          break;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.explanation = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DetectDuplicateLineItemsResultDuplicateGroup {
    return {
      $type: DetectDuplicateLineItemsResultDuplicateGroup.$type,
      itemIndices: globalThis.Array.isArray(object?.itemIndices)
        ? object.itemIndices.map((e: any) => globalThis.Number(e))
        : [],
      explanation: isSet(object.explanation) ? globalThis.String(object.explanation) : "",
    };
  },

  toJSON(message: DetectDuplicateLineItemsResultDuplicateGroup): unknown {
    const obj: any = {};
    if (message.itemIndices?.length) {
      obj.itemIndices = message.itemIndices.map((e) => Math.round(e));
    }
    if (message.explanation !== undefined && message.explanation !== "") {
      obj.explanation = message.explanation;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DetectDuplicateLineItemsResultDuplicateGroup>, I>>(
    base?: I,
  ): DetectDuplicateLineItemsResultDuplicateGroup {
    return DetectDuplicateLineItemsResultDuplicateGroup.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DetectDuplicateLineItemsResultDuplicateGroup>, I>>(
    object: I,
  ): DetectDuplicateLineItemsResultDuplicateGroup {
    const message = createBaseDetectDuplicateLineItemsResultDuplicateGroup();
    message.itemIndices = object.itemIndices?.map((e) => e) || [];
    message.explanation = object.explanation ?? "";
    return message;
  },
};

messageTypeRegistry.set(
  DetectDuplicateLineItemsResultDuplicateGroup.$type,
  DetectDuplicateLineItemsResultDuplicateGroup,
);

function createBaseGenerateText(): GenerateText {
  return { $type: "pb.v1alpha1.GenerateText", items: [], prompt: "" };
}

export const GenerateText = {
  $type: "pb.v1alpha1.GenerateText" as const,

  encode(message: GenerateText, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.items !== undefined && message.items.length !== 0) {
      for (const v of message.items) {
        Item.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    if (message.prompt !== undefined && message.prompt !== "") {
      writer.uint32(18).string(message.prompt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GenerateText {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateText();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.items!.push(Item.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.prompt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GenerateText {
    return {
      $type: GenerateText.$type,
      items: globalThis.Array.isArray(object?.items) ? object.items.map((e: any) => Item.fromJSON(e)) : [],
      prompt: isSet(object.prompt) ? globalThis.String(object.prompt) : "",
    };
  },

  toJSON(message: GenerateText): unknown {
    const obj: any = {};
    if (message.items?.length) {
      obj.items = message.items.map((e) => Item.toJSON(e));
    }
    if (message.prompt !== undefined && message.prompt !== "") {
      obj.prompt = message.prompt;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GenerateText>, I>>(base?: I): GenerateText {
    return GenerateText.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GenerateText>, I>>(object: I): GenerateText {
    const message = createBaseGenerateText();
    message.items = object.items?.map((e) => Item.fromPartial(e)) || [];
    message.prompt = object.prompt ?? "";
    return message;
  },
};

messageTypeRegistry.set(GenerateText.$type, GenerateText);

function createBaseGenerateTextResult(): GenerateTextResult {
  return { $type: "pb.v1alpha1.GenerateTextResult", generatedText: "", confidence: 0 };
}

export const GenerateTextResult = {
  $type: "pb.v1alpha1.GenerateTextResult" as const,

  encode(message: GenerateTextResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.generatedText !== undefined && message.generatedText !== "") {
      writer.uint32(10).string(message.generatedText);
    }
    if (message.confidence !== undefined && message.confidence !== 0) {
      writer.uint32(21).float(message.confidence);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GenerateTextResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateTextResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.generatedText = reader.string();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.confidence = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GenerateTextResult {
    return {
      $type: GenerateTextResult.$type,
      generatedText: isSet(object.generatedText) ? globalThis.String(object.generatedText) : "",
      confidence: isSet(object.confidence) ? globalThis.Number(object.confidence) : 0,
    };
  },

  toJSON(message: GenerateTextResult): unknown {
    const obj: any = {};
    if (message.generatedText !== undefined && message.generatedText !== "") {
      obj.generatedText = message.generatedText;
    }
    if (message.confidence !== undefined && message.confidence !== 0) {
      obj.confidence = message.confidence;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GenerateTextResult>, I>>(base?: I): GenerateTextResult {
    return GenerateTextResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GenerateTextResult>, I>>(object: I): GenerateTextResult {
    const message = createBaseGenerateTextResult();
    message.generatedText = object.generatedText ?? "";
    message.confidence = object.confidence ?? 0;
    return message;
  },
};

messageTypeRegistry.set(GenerateTextResult.$type, GenerateTextResult);

function createBaseClassify(): Classify {
  return { $type: "pb.v1alpha1.Classify", items: [], prompt: "", preset: 0 };
}

export const Classify = {
  $type: "pb.v1alpha1.Classify" as const,

  encode(message: Classify, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.items !== undefined && message.items.length !== 0) {
      for (const v of message.items) {
        Item.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    if (message.prompt !== undefined && message.prompt !== "") {
      writer.uint32(18).string(message.prompt);
    }
    if (message.preset !== undefined && message.preset !== 0) {
      writer.uint32(24).int32(message.preset);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Classify {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClassify();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.items!.push(Item.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.prompt = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.preset = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Classify {
    return {
      $type: Classify.$type,
      items: globalThis.Array.isArray(object?.items) ? object.items.map((e: any) => Item.fromJSON(e)) : [],
      prompt: isSet(object.prompt) ? globalThis.String(object.prompt) : "",
      preset: isSet(object.preset) ? classifyClassifyPresetFromJSON(object.preset) : 0,
    };
  },

  toJSON(message: Classify): unknown {
    const obj: any = {};
    if (message.items?.length) {
      obj.items = message.items.map((e) => Item.toJSON(e));
    }
    if (message.prompt !== undefined && message.prompt !== "") {
      obj.prompt = message.prompt;
    }
    if (message.preset !== undefined && message.preset !== 0) {
      obj.preset = classifyClassifyPresetToJSON(message.preset);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Classify>, I>>(base?: I): Classify {
    return Classify.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Classify>, I>>(object: I): Classify {
    const message = createBaseClassify();
    message.items = object.items?.map((e) => Item.fromPartial(e)) || [];
    message.prompt = object.prompt ?? "";
    message.preset = object.preset ?? 0;
    return message;
  },
};

messageTypeRegistry.set(Classify.$type, Classify);

function createBaseClassifyResult(): ClassifyResult {
  return {
    $type: "pb.v1alpha1.ClassifyResult",
    category: undefined,
    binary: undefined,
    confidence: 0,
    explanation: "",
  };
}

export const ClassifyResult = {
  $type: "pb.v1alpha1.ClassifyResult" as const,

  encode(message: ClassifyResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.category !== undefined) {
      writer.uint32(10).string(message.category);
    }
    if (message.binary !== undefined) {
      writer.uint32(32).int32(message.binary);
    }
    if (message.confidence !== undefined && message.confidence !== 0) {
      writer.uint32(21).float(message.confidence);
    }
    if (message.explanation !== undefined && message.explanation !== "") {
      writer.uint32(26).string(message.explanation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClassifyResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClassifyResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.category = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.binary = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.confidence = reader.float();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.explanation = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClassifyResult {
    return {
      $type: ClassifyResult.$type,
      category: isSet(object.category) ? globalThis.String(object.category) : undefined,
      binary: isSet(object.binary) ? classifyResultBinaryPredictionFromJSON(object.binary) : undefined,
      confidence: isSet(object.confidence) ? globalThis.Number(object.confidence) : 0,
      explanation: isSet(object.explanation) ? globalThis.String(object.explanation) : "",
    };
  },

  toJSON(message: ClassifyResult): unknown {
    const obj: any = {};
    if (message.category !== undefined) {
      obj.category = message.category;
    }
    if (message.binary !== undefined) {
      obj.binary = classifyResultBinaryPredictionToJSON(message.binary);
    }
    if (message.confidence !== undefined && message.confidence !== 0) {
      obj.confidence = message.confidence;
    }
    if (message.explanation !== undefined && message.explanation !== "") {
      obj.explanation = message.explanation;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ClassifyResult>, I>>(base?: I): ClassifyResult {
    return ClassifyResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ClassifyResult>, I>>(object: I): ClassifyResult {
    const message = createBaseClassifyResult();
    message.category = object.category ?? undefined;
    message.binary = object.binary ?? undefined;
    message.confidence = object.confidence ?? 0;
    message.explanation = object.explanation ?? "";
    return message;
  },
};

messageTypeRegistry.set(ClassifyResult.$type, ClassifyResult);

function createBaseReconcileItems(): ReconcileItems {
  return { $type: "pb.v1alpha1.ReconcileItems", rule: "", fields: [], source: undefined, target: undefined };
}

export const ReconcileItems = {
  $type: "pb.v1alpha1.ReconcileItems" as const,

  encode(message: ReconcileItems, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rule !== undefined && message.rule !== "") {
      writer.uint32(18).string(message.rule);
    }
    if (message.fields !== undefined && message.fields.length !== 0) {
      for (const v of message.fields) {
        Field.encode(v!, writer.uint32(26).fork()).ldelim();
      }
    }
    if (message.source !== undefined) {
      Item.encode(message.source, writer.uint32(34).fork()).ldelim();
    }
    if (message.target !== undefined) {
      Item.encode(message.target, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReconcileItems {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReconcileItems();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.rule = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fields!.push(Field.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.source = Item.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.target = Item.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ReconcileItems {
    return {
      $type: ReconcileItems.$type,
      rule: isSet(object.rule) ? globalThis.String(object.rule) : "",
      fields: globalThis.Array.isArray(object?.fields) ? object.fields.map((e: any) => Field.fromJSON(e)) : [],
      source: isSet(object.source) ? Item.fromJSON(object.source) : undefined,
      target: isSet(object.target) ? Item.fromJSON(object.target) : undefined,
    };
  },

  toJSON(message: ReconcileItems): unknown {
    const obj: any = {};
    if (message.rule !== undefined && message.rule !== "") {
      obj.rule = message.rule;
    }
    if (message.fields?.length) {
      obj.fields = message.fields.map((e) => Field.toJSON(e));
    }
    if (message.source !== undefined) {
      obj.source = Item.toJSON(message.source);
    }
    if (message.target !== undefined) {
      obj.target = Item.toJSON(message.target);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ReconcileItems>, I>>(base?: I): ReconcileItems {
    return ReconcileItems.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ReconcileItems>, I>>(object: I): ReconcileItems {
    const message = createBaseReconcileItems();
    message.rule = object.rule ?? "";
    message.fields = object.fields?.map((e) => Field.fromPartial(e)) || [];
    message.source = (object.source !== undefined && object.source !== null)
      ? Item.fromPartial(object.source)
      : undefined;
    message.target = (object.target !== undefined && object.target !== null)
      ? Item.fromPartial(object.target)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(ReconcileItems.$type, ReconcileItems);

function createBaseReconcileItemsRequestUnified(): ReconcileItemsRequestUnified {
  return {
    $type: "pb.v1alpha1.ReconcileItemsRequestUnified",
    policy: undefined,
    sourceGroup: undefined,
    targetGroup: undefined,
    fieldConfigs: [],
    matchingCardinality: 0,
  };
}

export const ReconcileItemsRequestUnified = {
  $type: "pb.v1alpha1.ReconcileItemsRequestUnified" as const,

  encode(message: ReconcileItemsRequestUnified, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.policy !== undefined) {
      Policy.encode(message.policy, writer.uint32(10).fork()).ldelim();
    }
    if (message.sourceGroup !== undefined) {
      FieldGroup.encode(message.sourceGroup, writer.uint32(18).fork()).ldelim();
    }
    if (message.targetGroup !== undefined) {
      FieldGroup.encode(message.targetGroup, writer.uint32(26).fork()).ldelim();
    }
    if (message.fieldConfigs !== undefined && message.fieldConfigs.length !== 0) {
      for (const v of message.fieldConfigs) {
        FieldReconciliationConfig.encode(v!, writer.uint32(34).fork()).ldelim();
      }
    }
    if (message.matchingCardinality !== undefined && message.matchingCardinality !== 0) {
      writer.uint32(40).int32(message.matchingCardinality);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReconcileItemsRequestUnified {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReconcileItemsRequestUnified();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.policy = Policy.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sourceGroup = FieldGroup.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.targetGroup = FieldGroup.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.fieldConfigs!.push(FieldReconciliationConfig.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.matchingCardinality = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ReconcileItemsRequestUnified {
    return {
      $type: ReconcileItemsRequestUnified.$type,
      policy: isSet(object.policy) ? Policy.fromJSON(object.policy) : undefined,
      sourceGroup: isSet(object.sourceGroup) ? FieldGroup.fromJSON(object.sourceGroup) : undefined,
      targetGroup: isSet(object.targetGroup) ? FieldGroup.fromJSON(object.targetGroup) : undefined,
      fieldConfigs: globalThis.Array.isArray(object?.fieldConfigs)
        ? object.fieldConfigs.map((e: any) => FieldReconciliationConfig.fromJSON(e))
        : [],
      matchingCardinality: isSet(object.matchingCardinality)
        ? reconcileItemsRequestUnifiedMatchingCardinalityFromJSON(object.matchingCardinality)
        : 0,
    };
  },

  toJSON(message: ReconcileItemsRequestUnified): unknown {
    const obj: any = {};
    if (message.policy !== undefined) {
      obj.policy = Policy.toJSON(message.policy);
    }
    if (message.sourceGroup !== undefined) {
      obj.sourceGroup = FieldGroup.toJSON(message.sourceGroup);
    }
    if (message.targetGroup !== undefined) {
      obj.targetGroup = FieldGroup.toJSON(message.targetGroup);
    }
    if (message.fieldConfigs?.length) {
      obj.fieldConfigs = message.fieldConfigs.map((e) => FieldReconciliationConfig.toJSON(e));
    }
    if (message.matchingCardinality !== undefined && message.matchingCardinality !== 0) {
      obj.matchingCardinality = reconcileItemsRequestUnifiedMatchingCardinalityToJSON(message.matchingCardinality);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ReconcileItemsRequestUnified>, I>>(base?: I): ReconcileItemsRequestUnified {
    return ReconcileItemsRequestUnified.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ReconcileItemsRequestUnified>, I>>(object: I): ReconcileItemsRequestUnified {
    const message = createBaseReconcileItemsRequestUnified();
    message.policy = (object.policy !== undefined && object.policy !== null)
      ? Policy.fromPartial(object.policy)
      : undefined;
    message.sourceGroup = (object.sourceGroup !== undefined && object.sourceGroup !== null)
      ? FieldGroup.fromPartial(object.sourceGroup)
      : undefined;
    message.targetGroup = (object.targetGroup !== undefined && object.targetGroup !== null)
      ? FieldGroup.fromPartial(object.targetGroup)
      : undefined;
    message.fieldConfigs = object.fieldConfigs?.map((e) => FieldReconciliationConfig.fromPartial(e)) || [];
    message.matchingCardinality = object.matchingCardinality ?? 0;
    return message;
  },
};

messageTypeRegistry.set(ReconcileItemsRequestUnified.$type, ReconcileItemsRequestUnified);

function createBaseFieldName(): FieldName {
  return { $type: "pb.v1alpha1.FieldName", parentName: "", childName: "" };
}

export const FieldName = {
  $type: "pb.v1alpha1.FieldName" as const,

  encode(message: FieldName, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.parentName !== undefined && message.parentName !== "") {
      writer.uint32(10).string(message.parentName);
    }
    if (message.childName !== undefined && message.childName !== "") {
      writer.uint32(18).string(message.childName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FieldName {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFieldName();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.parentName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.childName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FieldName {
    return {
      $type: FieldName.$type,
      parentName: isSet(object.parentName) ? globalThis.String(object.parentName) : "",
      childName: isSet(object.childName) ? globalThis.String(object.childName) : "",
    };
  },

  toJSON(message: FieldName): unknown {
    const obj: any = {};
    if (message.parentName !== undefined && message.parentName !== "") {
      obj.parentName = message.parentName;
    }
    if (message.childName !== undefined && message.childName !== "") {
      obj.childName = message.childName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FieldName>, I>>(base?: I): FieldName {
    return FieldName.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FieldName>, I>>(object: I): FieldName {
    const message = createBaseFieldName();
    message.parentName = object.parentName ?? "";
    message.childName = object.childName ?? "";
    return message;
  },
};

messageTypeRegistry.set(FieldName.$type, FieldName);

function createBaseFieldReconciliationConfig(): FieldReconciliationConfig {
  return {
    $type: "pb.v1alpha1.FieldReconciliationConfig",
    sourceName: undefined,
    targetName: undefined,
    clusteringMethod: 0,
    aggregationMethod: 0,
    updateConfig: undefined,
  };
}

export const FieldReconciliationConfig = {
  $type: "pb.v1alpha1.FieldReconciliationConfig" as const,

  encode(message: FieldReconciliationConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sourceName !== undefined) {
      FieldName.encode(message.sourceName, writer.uint32(10).fork()).ldelim();
    }
    if (message.targetName !== undefined) {
      FieldName.encode(message.targetName, writer.uint32(18).fork()).ldelim();
    }
    if (message.clusteringMethod !== undefined && message.clusteringMethod !== 0) {
      writer.uint32(24).int32(message.clusteringMethod);
    }
    if (message.aggregationMethod !== undefined && message.aggregationMethod !== 0) {
      writer.uint32(32).int32(message.aggregationMethod);
    }
    if (message.updateConfig !== undefined) {
      UpdateConfig.encode(message.updateConfig, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FieldReconciliationConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFieldReconciliationConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sourceName = FieldName.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.targetName = FieldName.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.clusteringMethod = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.aggregationMethod = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.updateConfig = UpdateConfig.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FieldReconciliationConfig {
    return {
      $type: FieldReconciliationConfig.$type,
      sourceName: isSet(object.sourceName) ? FieldName.fromJSON(object.sourceName) : undefined,
      targetName: isSet(object.targetName) ? FieldName.fromJSON(object.targetName) : undefined,
      clusteringMethod: isSet(object.clusteringMethod)
        ? fieldReconciliationConfigClusteringMethodFromJSON(object.clusteringMethod)
        : 0,
      aggregationMethod: isSet(object.aggregationMethod)
        ? fieldReconciliationConfigAggregationMethodFromJSON(object.aggregationMethod)
        : 0,
      updateConfig: isSet(object.updateConfig) ? UpdateConfig.fromJSON(object.updateConfig) : undefined,
    };
  },

  toJSON(message: FieldReconciliationConfig): unknown {
    const obj: any = {};
    if (message.sourceName !== undefined) {
      obj.sourceName = FieldName.toJSON(message.sourceName);
    }
    if (message.targetName !== undefined) {
      obj.targetName = FieldName.toJSON(message.targetName);
    }
    if (message.clusteringMethod !== undefined && message.clusteringMethod !== 0) {
      obj.clusteringMethod = fieldReconciliationConfigClusteringMethodToJSON(message.clusteringMethod);
    }
    if (message.aggregationMethod !== undefined && message.aggregationMethod !== 0) {
      obj.aggregationMethod = fieldReconciliationConfigAggregationMethodToJSON(message.aggregationMethod);
    }
    if (message.updateConfig !== undefined) {
      obj.updateConfig = UpdateConfig.toJSON(message.updateConfig);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FieldReconciliationConfig>, I>>(base?: I): FieldReconciliationConfig {
    return FieldReconciliationConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FieldReconciliationConfig>, I>>(object: I): FieldReconciliationConfig {
    const message = createBaseFieldReconciliationConfig();
    message.sourceName = (object.sourceName !== undefined && object.sourceName !== null)
      ? FieldName.fromPartial(object.sourceName)
      : undefined;
    message.targetName = (object.targetName !== undefined && object.targetName !== null)
      ? FieldName.fromPartial(object.targetName)
      : undefined;
    message.clusteringMethod = object.clusteringMethod ?? 0;
    message.aggregationMethod = object.aggregationMethod ?? 0;
    message.updateConfig = (object.updateConfig !== undefined && object.updateConfig !== null)
      ? UpdateConfig.fromPartial(object.updateConfig)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(FieldReconciliationConfig.$type, FieldReconciliationConfig);

function createBaseUpdateConfig(): UpdateConfig {
  return { $type: "pb.v1alpha1.UpdateConfig", updateMethod: 0, llmUpdateInstructions: "" };
}

export const UpdateConfig = {
  $type: "pb.v1alpha1.UpdateConfig" as const,

  encode(message: UpdateConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.updateMethod !== undefined && message.updateMethod !== 0) {
      writer.uint32(72).int32(message.updateMethod);
    }
    if (message.llmUpdateInstructions !== undefined && message.llmUpdateInstructions !== "") {
      writer.uint32(82).string(message.llmUpdateInstructions);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 9:
          if (tag !== 72) {
            break;
          }

          message.updateMethod = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.llmUpdateInstructions = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateConfig {
    return {
      $type: UpdateConfig.$type,
      updateMethod: isSet(object.updateMethod) ? updateConfigUpdateMethodFromJSON(object.updateMethod) : 0,
      llmUpdateInstructions: isSet(object.llmUpdateInstructions) ? globalThis.String(object.llmUpdateInstructions) : "",
    };
  },

  toJSON(message: UpdateConfig): unknown {
    const obj: any = {};
    if (message.updateMethod !== undefined && message.updateMethod !== 0) {
      obj.updateMethod = updateConfigUpdateMethodToJSON(message.updateMethod);
    }
    if (message.llmUpdateInstructions !== undefined && message.llmUpdateInstructions !== "") {
      obj.llmUpdateInstructions = message.llmUpdateInstructions;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateConfig>, I>>(base?: I): UpdateConfig {
    return UpdateConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateConfig>, I>>(object: I): UpdateConfig {
    const message = createBaseUpdateConfig();
    message.updateMethod = object.updateMethod ?? 0;
    message.llmUpdateInstructions = object.llmUpdateInstructions ?? "";
    return message;
  },
};

messageTypeRegistry.set(UpdateConfig.$type, UpdateConfig);

function createBaseFieldGroupExtractedFields(): FieldGroupExtractedFields {
  return { $type: "pb.v1alpha1.FieldGroupExtractedFields", fieldGroupIndex: 0, fields: [], confidence: 0 };
}

export const FieldGroupExtractedFields = {
  $type: "pb.v1alpha1.FieldGroupExtractedFields" as const,

  encode(message: FieldGroupExtractedFields, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fieldGroupIndex !== undefined && message.fieldGroupIndex !== 0) {
      writer.uint32(24).int32(message.fieldGroupIndex);
    }
    if (message.fields !== undefined && message.fields.length !== 0) {
      for (const v of message.fields) {
        Field.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    if (message.confidence !== undefined && message.confidence !== 0) {
      writer.uint32(21).float(message.confidence);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FieldGroupExtractedFields {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFieldGroupExtractedFields();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 24) {
            break;
          }

          message.fieldGroupIndex = reader.int32();
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fields!.push(Field.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.confidence = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FieldGroupExtractedFields {
    return {
      $type: FieldGroupExtractedFields.$type,
      fieldGroupIndex: isSet(object.fieldGroupIndex) ? globalThis.Number(object.fieldGroupIndex) : 0,
      fields: globalThis.Array.isArray(object?.fields) ? object.fields.map((e: any) => Field.fromJSON(e)) : [],
      confidence: isSet(object.confidence) ? globalThis.Number(object.confidence) : 0,
    };
  },

  toJSON(message: FieldGroupExtractedFields): unknown {
    const obj: any = {};
    if (message.fieldGroupIndex !== undefined && message.fieldGroupIndex !== 0) {
      obj.fieldGroupIndex = Math.round(message.fieldGroupIndex);
    }
    if (message.fields?.length) {
      obj.fields = message.fields.map((e) => Field.toJSON(e));
    }
    if (message.confidence !== undefined && message.confidence !== 0) {
      obj.confidence = message.confidence;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FieldGroupExtractedFields>, I>>(base?: I): FieldGroupExtractedFields {
    return FieldGroupExtractedFields.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FieldGroupExtractedFields>, I>>(object: I): FieldGroupExtractedFields {
    const message = createBaseFieldGroupExtractedFields();
    message.fieldGroupIndex = object.fieldGroupIndex ?? 0;
    message.fields = object.fields?.map((e) => Field.fromPartial(e)) || [];
    message.confidence = object.confidence ?? 0;
    return message;
  },
};

messageTypeRegistry.set(FieldGroupExtractedFields.$type, FieldGroupExtractedFields);

function createBaseReconcileItemsResult(): ReconcileItemsResult {
  return {
    $type: "pb.v1alpha1.ReconcileItemsResult",
    fieldGroupMatches: [],
    sourceExtractedFields: [],
    targetExtractedFields: [],
  };
}

export const ReconcileItemsResult = {
  $type: "pb.v1alpha1.ReconcileItemsResult" as const,

  encode(message: ReconcileItemsResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fieldGroupMatches !== undefined && message.fieldGroupMatches.length !== 0) {
      for (const v of message.fieldGroupMatches) {
        FieldGroupMatch.encode(v!, writer.uint32(42).fork()).ldelim();
      }
    }
    if (message.sourceExtractedFields !== undefined && message.sourceExtractedFields.length !== 0) {
      for (const v of message.sourceExtractedFields) {
        FieldGroupExtractedFields.encode(v!, writer.uint32(50).fork()).ldelim();
      }
    }
    if (message.targetExtractedFields !== undefined && message.targetExtractedFields.length !== 0) {
      for (const v of message.targetExtractedFields) {
        FieldGroupExtractedFields.encode(v!, writer.uint32(58).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReconcileItemsResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReconcileItemsResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 5:
          if (tag !== 42) {
            break;
          }

          message.fieldGroupMatches!.push(FieldGroupMatch.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.sourceExtractedFields!.push(FieldGroupExtractedFields.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.targetExtractedFields!.push(FieldGroupExtractedFields.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ReconcileItemsResult {
    return {
      $type: ReconcileItemsResult.$type,
      fieldGroupMatches: globalThis.Array.isArray(object?.fieldGroupMatches)
        ? object.fieldGroupMatches.map((e: any) => FieldGroupMatch.fromJSON(e))
        : [],
      sourceExtractedFields: globalThis.Array.isArray(object?.sourceExtractedFields)
        ? object.sourceExtractedFields.map((e: any) => FieldGroupExtractedFields.fromJSON(e))
        : [],
      targetExtractedFields: globalThis.Array.isArray(object?.targetExtractedFields)
        ? object.targetExtractedFields.map((e: any) => FieldGroupExtractedFields.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ReconcileItemsResult): unknown {
    const obj: any = {};
    if (message.fieldGroupMatches?.length) {
      obj.fieldGroupMatches = message.fieldGroupMatches.map((e) => FieldGroupMatch.toJSON(e));
    }
    if (message.sourceExtractedFields?.length) {
      obj.sourceExtractedFields = message.sourceExtractedFields.map((e) => FieldGroupExtractedFields.toJSON(e));
    }
    if (message.targetExtractedFields?.length) {
      obj.targetExtractedFields = message.targetExtractedFields.map((e) => FieldGroupExtractedFields.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ReconcileItemsResult>, I>>(base?: I): ReconcileItemsResult {
    return ReconcileItemsResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ReconcileItemsResult>, I>>(object: I): ReconcileItemsResult {
    const message = createBaseReconcileItemsResult();
    message.fieldGroupMatches = object.fieldGroupMatches?.map((e) => FieldGroupMatch.fromPartial(e)) || [];
    message.sourceExtractedFields =
      object.sourceExtractedFields?.map((e) => FieldGroupExtractedFields.fromPartial(e)) || [];
    message.targetExtractedFields =
      object.targetExtractedFields?.map((e) => FieldGroupExtractedFields.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(ReconcileItemsResult.$type, ReconcileItemsResult);

function createBaseReconcileItemsResultUnified(): ReconcileItemsResultUnified {
  return {
    $type: "pb.v1alpha1.ReconcileItemsResultUnified",
    finalFieldMatches: [],
    sourceNonMatchIds: [],
    targetNonMatchIds: [],
  };
}

export const ReconcileItemsResultUnified = {
  $type: "pb.v1alpha1.ReconcileItemsResultUnified" as const,

  encode(message: ReconcileItemsResultUnified, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.finalFieldMatches !== undefined && message.finalFieldMatches.length !== 0) {
      for (const v of message.finalFieldMatches) {
        FieldMatch.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    if (message.sourceNonMatchIds !== undefined && message.sourceNonMatchIds.length !== 0) {
      for (const v of message.sourceNonMatchIds) {
        writer.uint32(18).string(v!);
      }
    }
    if (message.targetNonMatchIds !== undefined && message.targetNonMatchIds.length !== 0) {
      for (const v of message.targetNonMatchIds) {
        writer.uint32(26).string(v!);
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReconcileItemsResultUnified {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReconcileItemsResultUnified();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.finalFieldMatches!.push(FieldMatch.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sourceNonMatchIds!.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.targetNonMatchIds!.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ReconcileItemsResultUnified {
    return {
      $type: ReconcileItemsResultUnified.$type,
      finalFieldMatches: globalThis.Array.isArray(object?.finalFieldMatches)
        ? object.finalFieldMatches.map((e: any) => FieldMatch.fromJSON(e))
        : [],
      sourceNonMatchIds: globalThis.Array.isArray(object?.sourceNonMatchIds)
        ? object.sourceNonMatchIds.map((e: any) => globalThis.String(e))
        : [],
      targetNonMatchIds: globalThis.Array.isArray(object?.targetNonMatchIds)
        ? object.targetNonMatchIds.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: ReconcileItemsResultUnified): unknown {
    const obj: any = {};
    if (message.finalFieldMatches?.length) {
      obj.finalFieldMatches = message.finalFieldMatches.map((e) => FieldMatch.toJSON(e));
    }
    if (message.sourceNonMatchIds?.length) {
      obj.sourceNonMatchIds = message.sourceNonMatchIds;
    }
    if (message.targetNonMatchIds?.length) {
      obj.targetNonMatchIds = message.targetNonMatchIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ReconcileItemsResultUnified>, I>>(base?: I): ReconcileItemsResultUnified {
    return ReconcileItemsResultUnified.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ReconcileItemsResultUnified>, I>>(object: I): ReconcileItemsResultUnified {
    const message = createBaseReconcileItemsResultUnified();
    message.finalFieldMatches = object.finalFieldMatches?.map((e) => FieldMatch.fromPartial(e)) || [];
    message.sourceNonMatchIds = object.sourceNonMatchIds?.map((e) => e) || [];
    message.targetNonMatchIds = object.targetNonMatchIds?.map((e) => e) || [];
    return message;
  },
};

messageTypeRegistry.set(ReconcileItemsResultUnified.$type, ReconcileItemsResultUnified);

function createBasePolicy(): Policy {
  return { $type: "pb.v1alpha1.Policy", instruction: undefined, policyDoc: undefined };
}

export const Policy = {
  $type: "pb.v1alpha1.Policy" as const,

  encode(message: Policy, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.instruction !== undefined) {
      PolicyInstruction.encode(message.instruction, writer.uint32(18).fork()).ldelim();
    }
    if (message.policyDoc !== undefined) {
      Document.encode(message.policyDoc, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Policy {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePolicy();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.instruction = PolicyInstruction.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.policyDoc = Document.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Policy {
    return {
      $type: Policy.$type,
      instruction: isSet(object.instruction) ? PolicyInstruction.fromJSON(object.instruction) : undefined,
      policyDoc: isSet(object.policyDoc) ? Document.fromJSON(object.policyDoc) : undefined,
    };
  },

  toJSON(message: Policy): unknown {
    const obj: any = {};
    if (message.instruction !== undefined) {
      obj.instruction = PolicyInstruction.toJSON(message.instruction);
    }
    if (message.policyDoc !== undefined) {
      obj.policyDoc = Document.toJSON(message.policyDoc);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Policy>, I>>(base?: I): Policy {
    return Policy.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Policy>, I>>(object: I): Policy {
    const message = createBasePolicy();
    message.instruction = (object.instruction !== undefined && object.instruction !== null)
      ? PolicyInstruction.fromPartial(object.instruction)
      : undefined;
    message.policyDoc = (object.policyDoc !== undefined && object.policyDoc !== null)
      ? Document.fromPartial(object.policyDoc)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(Policy.$type, Policy);

function createBasePolicyInstruction(): PolicyInstruction {
  return { $type: "pb.v1alpha1.Policy.Instruction", rules: [] };
}

export const PolicyInstruction = {
  $type: "pb.v1alpha1.Policy.Instruction" as const,

  encode(message: PolicyInstruction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rules !== undefined && message.rules.length !== 0) {
      for (const v of message.rules) {
        writer.uint32(10).string(v!);
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PolicyInstruction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePolicyInstruction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.rules!.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PolicyInstruction {
    return {
      $type: PolicyInstruction.$type,
      rules: globalThis.Array.isArray(object?.rules) ? object.rules.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: PolicyInstruction): unknown {
    const obj: any = {};
    if (message.rules?.length) {
      obj.rules = message.rules;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PolicyInstruction>, I>>(base?: I): PolicyInstruction {
    return PolicyInstruction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PolicyInstruction>, I>>(object: I): PolicyInstruction {
    const message = createBasePolicyInstruction();
    message.rules = object.rules?.map((e) => e) || [];
    return message;
  },
};

messageTypeRegistry.set(PolicyInstruction.$type, PolicyInstruction);

function createBaseFieldGroupCompareRequest(): FieldGroupCompareRequest {
  return {
    $type: "pb.v1alpha1.FieldGroupCompareRequest",
    fieldGroupLeft: undefined,
    fieldGroupRight: undefined,
    fieldToCompare: [],
  };
}

export const FieldGroupCompareRequest = {
  $type: "pb.v1alpha1.FieldGroupCompareRequest" as const,

  encode(message: FieldGroupCompareRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fieldGroupLeft !== undefined) {
      FieldGroup.encode(message.fieldGroupLeft, writer.uint32(10).fork()).ldelim();
    }
    if (message.fieldGroupRight !== undefined) {
      FieldGroup.encode(message.fieldGroupRight, writer.uint32(18).fork()).ldelim();
    }
    if (message.fieldToCompare !== undefined && message.fieldToCompare.length !== 0) {
      for (const v of message.fieldToCompare) {
        FieldPair.encode(v!, writer.uint32(26).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FieldGroupCompareRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFieldGroupCompareRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fieldGroupLeft = FieldGroup.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fieldGroupRight = FieldGroup.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fieldToCompare!.push(FieldPair.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FieldGroupCompareRequest {
    return {
      $type: FieldGroupCompareRequest.$type,
      fieldGroupLeft: isSet(object.fieldGroupLeft) ? FieldGroup.fromJSON(object.fieldGroupLeft) : undefined,
      fieldGroupRight: isSet(object.fieldGroupRight) ? FieldGroup.fromJSON(object.fieldGroupRight) : undefined,
      fieldToCompare: globalThis.Array.isArray(object?.fieldToCompare)
        ? object.fieldToCompare.map((e: any) => FieldPair.fromJSON(e))
        : [],
    };
  },

  toJSON(message: FieldGroupCompareRequest): unknown {
    const obj: any = {};
    if (message.fieldGroupLeft !== undefined) {
      obj.fieldGroupLeft = FieldGroup.toJSON(message.fieldGroupLeft);
    }
    if (message.fieldGroupRight !== undefined) {
      obj.fieldGroupRight = FieldGroup.toJSON(message.fieldGroupRight);
    }
    if (message.fieldToCompare?.length) {
      obj.fieldToCompare = message.fieldToCompare.map((e) => FieldPair.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FieldGroupCompareRequest>, I>>(base?: I): FieldGroupCompareRequest {
    return FieldGroupCompareRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FieldGroupCompareRequest>, I>>(object: I): FieldGroupCompareRequest {
    const message = createBaseFieldGroupCompareRequest();
    message.fieldGroupLeft = (object.fieldGroupLeft !== undefined && object.fieldGroupLeft !== null)
      ? FieldGroup.fromPartial(object.fieldGroupLeft)
      : undefined;
    message.fieldGroupRight = (object.fieldGroupRight !== undefined && object.fieldGroupRight !== null)
      ? FieldGroup.fromPartial(object.fieldGroupRight)
      : undefined;
    message.fieldToCompare = object.fieldToCompare?.map((e) => FieldPair.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(FieldGroupCompareRequest.$type, FieldGroupCompareRequest);

function createBaseFieldPair(): FieldPair {
  return { $type: "pb.v1alpha1.FieldPair", fieldLeft: "", fieldRight: "" };
}

export const FieldPair = {
  $type: "pb.v1alpha1.FieldPair" as const,

  encode(message: FieldPair, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fieldLeft !== undefined && message.fieldLeft !== "") {
      writer.uint32(10).string(message.fieldLeft);
    }
    if (message.fieldRight !== undefined && message.fieldRight !== "") {
      writer.uint32(18).string(message.fieldRight);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FieldPair {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFieldPair();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fieldLeft = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fieldRight = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FieldPair {
    return {
      $type: FieldPair.$type,
      fieldLeft: isSet(object.fieldLeft) ? globalThis.String(object.fieldLeft) : "",
      fieldRight: isSet(object.fieldRight) ? globalThis.String(object.fieldRight) : "",
    };
  },

  toJSON(message: FieldPair): unknown {
    const obj: any = {};
    if (message.fieldLeft !== undefined && message.fieldLeft !== "") {
      obj.fieldLeft = message.fieldLeft;
    }
    if (message.fieldRight !== undefined && message.fieldRight !== "") {
      obj.fieldRight = message.fieldRight;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FieldPair>, I>>(base?: I): FieldPair {
    return FieldPair.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FieldPair>, I>>(object: I): FieldPair {
    const message = createBaseFieldPair();
    message.fieldLeft = object.fieldLeft ?? "";
    message.fieldRight = object.fieldRight ?? "";
    return message;
  },
};

messageTypeRegistry.set(FieldPair.$type, FieldPair);

function createBaseFieldGroupCompareResult(): FieldGroupCompareResult {
  return { $type: "pb.v1alpha1.FieldGroupCompareResult", fieldCompareResults: [] };
}

export const FieldGroupCompareResult = {
  $type: "pb.v1alpha1.FieldGroupCompareResult" as const,

  encode(message: FieldGroupCompareResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fieldCompareResults !== undefined && message.fieldCompareResults.length !== 0) {
      for (const v of message.fieldCompareResults) {
        FieldGroupCompareResultFieldCompareResult.encode(v!, writer.uint32(42).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FieldGroupCompareResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFieldGroupCompareResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 5:
          if (tag !== 42) {
            break;
          }

          message.fieldCompareResults!.push(FieldGroupCompareResultFieldCompareResult.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FieldGroupCompareResult {
    return {
      $type: FieldGroupCompareResult.$type,
      fieldCompareResults: globalThis.Array.isArray(object?.fieldCompareResults)
        ? object.fieldCompareResults.map((e: any) => FieldGroupCompareResultFieldCompareResult.fromJSON(e))
        : [],
    };
  },

  toJSON(message: FieldGroupCompareResult): unknown {
    const obj: any = {};
    if (message.fieldCompareResults?.length) {
      obj.fieldCompareResults = message.fieldCompareResults.map((e) =>
        FieldGroupCompareResultFieldCompareResult.toJSON(e)
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FieldGroupCompareResult>, I>>(base?: I): FieldGroupCompareResult {
    return FieldGroupCompareResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FieldGroupCompareResult>, I>>(object: I): FieldGroupCompareResult {
    const message = createBaseFieldGroupCompareResult();
    message.fieldCompareResults =
      object.fieldCompareResults?.map((e) => FieldGroupCompareResultFieldCompareResult.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(FieldGroupCompareResult.$type, FieldGroupCompareResult);

function createBaseFieldGroupCompareResultFieldCompareResult(): FieldGroupCompareResultFieldCompareResult {
  return {
    $type: "pb.v1alpha1.FieldGroupCompareResult.FieldCompareResult",
    fieldToCompare: undefined,
    isMatch: false,
    explanation: "",
    confidence: 0,
  };
}

export const FieldGroupCompareResultFieldCompareResult = {
  $type: "pb.v1alpha1.FieldGroupCompareResult.FieldCompareResult" as const,

  encode(message: FieldGroupCompareResultFieldCompareResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fieldToCompare !== undefined) {
      FieldPair.encode(message.fieldToCompare, writer.uint32(10).fork()).ldelim();
    }
    if (message.isMatch !== undefined && message.isMatch !== false) {
      writer.uint32(16).bool(message.isMatch);
    }
    if (message.explanation !== undefined && message.explanation !== "") {
      writer.uint32(26).string(message.explanation);
    }
    if (message.confidence !== undefined && message.confidence !== 0) {
      writer.uint32(33).double(message.confidence);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FieldGroupCompareResultFieldCompareResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFieldGroupCompareResultFieldCompareResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fieldToCompare = FieldPair.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isMatch = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.explanation = reader.string();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.confidence = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FieldGroupCompareResultFieldCompareResult {
    return {
      $type: FieldGroupCompareResultFieldCompareResult.$type,
      fieldToCompare: isSet(object.fieldToCompare) ? FieldPair.fromJSON(object.fieldToCompare) : undefined,
      isMatch: isSet(object.isMatch) ? globalThis.Boolean(object.isMatch) : false,
      explanation: isSet(object.explanation) ? globalThis.String(object.explanation) : "",
      confidence: isSet(object.confidence) ? globalThis.Number(object.confidence) : 0,
    };
  },

  toJSON(message: FieldGroupCompareResultFieldCompareResult): unknown {
    const obj: any = {};
    if (message.fieldToCompare !== undefined) {
      obj.fieldToCompare = FieldPair.toJSON(message.fieldToCompare);
    }
    if (message.isMatch !== undefined && message.isMatch !== false) {
      obj.isMatch = message.isMatch;
    }
    if (message.explanation !== undefined && message.explanation !== "") {
      obj.explanation = message.explanation;
    }
    if (message.confidence !== undefined && message.confidence !== 0) {
      obj.confidence = message.confidence;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FieldGroupCompareResultFieldCompareResult>, I>>(
    base?: I,
  ): FieldGroupCompareResultFieldCompareResult {
    return FieldGroupCompareResultFieldCompareResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FieldGroupCompareResultFieldCompareResult>, I>>(
    object: I,
  ): FieldGroupCompareResultFieldCompareResult {
    const message = createBaseFieldGroupCompareResultFieldCompareResult();
    message.fieldToCompare = (object.fieldToCompare !== undefined && object.fieldToCompare !== null)
      ? FieldPair.fromPartial(object.fieldToCompare)
      : undefined;
    message.isMatch = object.isMatch ?? false;
    message.explanation = object.explanation ?? "";
    message.confidence = object.confidence ?? 0;
    return message;
  },
};

messageTypeRegistry.set(FieldGroupCompareResultFieldCompareResult.$type, FieldGroupCompareResultFieldCompareResult);

function createBaseCompareRequest(): CompareRequest {
  return { $type: "pb.v1alpha1.CompareRequest", policy: undefined, fieldGroupCompareRequest: undefined };
}

export const CompareRequest = {
  $type: "pb.v1alpha1.CompareRequest" as const,

  encode(message: CompareRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.policy !== undefined) {
      Policy.encode(message.policy, writer.uint32(10).fork()).ldelim();
    }
    if (message.fieldGroupCompareRequest !== undefined) {
      FieldGroupCompareRequest.encode(message.fieldGroupCompareRequest, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompareRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompareRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.policy = Policy.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fieldGroupCompareRequest = FieldGroupCompareRequest.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CompareRequest {
    return {
      $type: CompareRequest.$type,
      policy: isSet(object.policy) ? Policy.fromJSON(object.policy) : undefined,
      fieldGroupCompareRequest: isSet(object.fieldGroupCompareRequest)
        ? FieldGroupCompareRequest.fromJSON(object.fieldGroupCompareRequest)
        : undefined,
    };
  },

  toJSON(message: CompareRequest): unknown {
    const obj: any = {};
    if (message.policy !== undefined) {
      obj.policy = Policy.toJSON(message.policy);
    }
    if (message.fieldGroupCompareRequest !== undefined) {
      obj.fieldGroupCompareRequest = FieldGroupCompareRequest.toJSON(message.fieldGroupCompareRequest);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CompareRequest>, I>>(base?: I): CompareRequest {
    return CompareRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CompareRequest>, I>>(object: I): CompareRequest {
    const message = createBaseCompareRequest();
    message.policy = (object.policy !== undefined && object.policy !== null)
      ? Policy.fromPartial(object.policy)
      : undefined;
    message.fieldGroupCompareRequest =
      (object.fieldGroupCompareRequest !== undefined && object.fieldGroupCompareRequest !== null)
        ? FieldGroupCompareRequest.fromPartial(object.fieldGroupCompareRequest)
        : undefined;
    return message;
  },
};

messageTypeRegistry.set(CompareRequest.$type, CompareRequest);

function createBaseCompareResult(): CompareResult {
  return { $type: "pb.v1alpha1.CompareResult", fieldGroupCompareResult: undefined };
}

export const CompareResult = {
  $type: "pb.v1alpha1.CompareResult" as const,

  encode(message: CompareResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fieldGroupCompareResult !== undefined) {
      FieldGroupCompareResult.encode(message.fieldGroupCompareResult, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompareResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompareResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fieldGroupCompareResult = FieldGroupCompareResult.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CompareResult {
    return {
      $type: CompareResult.$type,
      fieldGroupCompareResult: isSet(object.fieldGroupCompareResult)
        ? FieldGroupCompareResult.fromJSON(object.fieldGroupCompareResult)
        : undefined,
    };
  },

  toJSON(message: CompareResult): unknown {
    const obj: any = {};
    if (message.fieldGroupCompareResult !== undefined) {
      obj.fieldGroupCompareResult = FieldGroupCompareResult.toJSON(message.fieldGroupCompareResult);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CompareResult>, I>>(base?: I): CompareResult {
    return CompareResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CompareResult>, I>>(object: I): CompareResult {
    const message = createBaseCompareResult();
    message.fieldGroupCompareResult =
      (object.fieldGroupCompareResult !== undefined && object.fieldGroupCompareResult !== null)
        ? FieldGroupCompareResult.fromPartial(object.fieldGroupCompareResult)
        : undefined;
    return message;
  },
};

messageTypeRegistry.set(CompareResult.$type, CompareResult);

function createBaseGenerateFunctionTestCase(): GenerateFunctionTestCase {
  return { $type: "pb.v1alpha1.GenerateFunctionTestCase", input: [], output: undefined };
}

export const GenerateFunctionTestCase = {
  $type: "pb.v1alpha1.GenerateFunctionTestCase" as const,

  encode(message: GenerateFunctionTestCase, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.input !== undefined && message.input.length !== 0) {
      for (const v of message.input) {
        Variable.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    if (message.output !== undefined) {
      Variable.encode(message.output, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GenerateFunctionTestCase {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateFunctionTestCase();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.input!.push(Variable.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.output = Variable.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GenerateFunctionTestCase {
    return {
      $type: GenerateFunctionTestCase.$type,
      input: globalThis.Array.isArray(object?.input) ? object.input.map((e: any) => Variable.fromJSON(e)) : [],
      output: isSet(object.output) ? Variable.fromJSON(object.output) : undefined,
    };
  },

  toJSON(message: GenerateFunctionTestCase): unknown {
    const obj: any = {};
    if (message.input?.length) {
      obj.input = message.input.map((e) => Variable.toJSON(e));
    }
    if (message.output !== undefined) {
      obj.output = Variable.toJSON(message.output);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GenerateFunctionTestCase>, I>>(base?: I): GenerateFunctionTestCase {
    return GenerateFunctionTestCase.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GenerateFunctionTestCase>, I>>(object: I): GenerateFunctionTestCase {
    const message = createBaseGenerateFunctionTestCase();
    message.input = object.input?.map((e) => Variable.fromPartial(e)) || [];
    message.output = (object.output !== undefined && object.output !== null)
      ? Variable.fromPartial(object.output)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(GenerateFunctionTestCase.$type, GenerateFunctionTestCase);

function createBaseGenerateFunctionRequest(): GenerateFunctionRequest {
  return {
    $type: "pb.v1alpha1.GenerateFunctionRequest",
    policy: undefined,
    inputSchema: [],
    outputSchema: undefined,
    demonstrations: [],
  };
}

export const GenerateFunctionRequest = {
  $type: "pb.v1alpha1.GenerateFunctionRequest" as const,

  encode(message: GenerateFunctionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.policy !== undefined) {
      Policy.encode(message.policy, writer.uint32(10).fork()).ldelim();
    }
    if (message.inputSchema !== undefined && message.inputSchema.length !== 0) {
      for (const v of message.inputSchema) {
        VariableType.encode(v!, writer.uint32(18).fork()).ldelim();
      }
    }
    if (message.outputSchema !== undefined) {
      VariableType.encode(message.outputSchema, writer.uint32(26).fork()).ldelim();
    }
    if (message.demonstrations !== undefined && message.demonstrations.length !== 0) {
      for (const v of message.demonstrations) {
        GenerateFunctionTestCase.encode(v!, writer.uint32(34).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GenerateFunctionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateFunctionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.policy = Policy.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.inputSchema!.push(VariableType.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.outputSchema = VariableType.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.demonstrations!.push(GenerateFunctionTestCase.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GenerateFunctionRequest {
    return {
      $type: GenerateFunctionRequest.$type,
      policy: isSet(object.policy) ? Policy.fromJSON(object.policy) : undefined,
      inputSchema: globalThis.Array.isArray(object?.inputSchema)
        ? object.inputSchema.map((e: any) => VariableType.fromJSON(e))
        : [],
      outputSchema: isSet(object.outputSchema) ? VariableType.fromJSON(object.outputSchema) : undefined,
      demonstrations: globalThis.Array.isArray(object?.demonstrations)
        ? object.demonstrations.map((e: any) => GenerateFunctionTestCase.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GenerateFunctionRequest): unknown {
    const obj: any = {};
    if (message.policy !== undefined) {
      obj.policy = Policy.toJSON(message.policy);
    }
    if (message.inputSchema?.length) {
      obj.inputSchema = message.inputSchema.map((e) => VariableType.toJSON(e));
    }
    if (message.outputSchema !== undefined) {
      obj.outputSchema = VariableType.toJSON(message.outputSchema);
    }
    if (message.demonstrations?.length) {
      obj.demonstrations = message.demonstrations.map((e) => GenerateFunctionTestCase.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GenerateFunctionRequest>, I>>(base?: I): GenerateFunctionRequest {
    return GenerateFunctionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GenerateFunctionRequest>, I>>(object: I): GenerateFunctionRequest {
    const message = createBaseGenerateFunctionRequest();
    message.policy = (object.policy !== undefined && object.policy !== null)
      ? Policy.fromPartial(object.policy)
      : undefined;
    message.inputSchema = object.inputSchema?.map((e) => VariableType.fromPartial(e)) || [];
    message.outputSchema = (object.outputSchema !== undefined && object.outputSchema !== null)
      ? VariableType.fromPartial(object.outputSchema)
      : undefined;
    message.demonstrations = object.demonstrations?.map((e) => GenerateFunctionTestCase.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(GenerateFunctionRequest.$type, GenerateFunctionRequest);

function createBaseGenerateFunctionResult(): GenerateFunctionResult {
  return { $type: "pb.v1alpha1.GenerateFunctionResult", function: undefined, error: 0, testCaseResults: [] };
}

export const GenerateFunctionResult = {
  $type: "pb.v1alpha1.GenerateFunctionResult" as const,

  encode(message: GenerateFunctionResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.function !== undefined) {
      Function.encode(message.function, writer.uint32(10).fork()).ldelim();
    }
    if (message.error !== undefined && message.error !== 0) {
      writer.uint32(16).int32(message.error);
    }
    if (message.testCaseResults !== undefined && message.testCaseResults.length !== 0) {
      for (const v of message.testCaseResults) {
        GenerateFunctionResultTestCaseResult.encode(v!, writer.uint32(26).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GenerateFunctionResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateFunctionResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.function = Function.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.error = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.testCaseResults!.push(GenerateFunctionResultTestCaseResult.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GenerateFunctionResult {
    return {
      $type: GenerateFunctionResult.$type,
      function: isSet(object.function) ? Function.fromJSON(object.function) : undefined,
      error: isSet(object.error) ? generateFunctionResultErrorFromJSON(object.error) : 0,
      testCaseResults: globalThis.Array.isArray(object?.testCaseResults)
        ? object.testCaseResults.map((e: any) => GenerateFunctionResultTestCaseResult.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GenerateFunctionResult): unknown {
    const obj: any = {};
    if (message.function !== undefined) {
      obj.function = Function.toJSON(message.function);
    }
    if (message.error !== undefined && message.error !== 0) {
      obj.error = generateFunctionResultErrorToJSON(message.error);
    }
    if (message.testCaseResults?.length) {
      obj.testCaseResults = message.testCaseResults.map((e) => GenerateFunctionResultTestCaseResult.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GenerateFunctionResult>, I>>(base?: I): GenerateFunctionResult {
    return GenerateFunctionResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GenerateFunctionResult>, I>>(object: I): GenerateFunctionResult {
    const message = createBaseGenerateFunctionResult();
    message.function = (object.function !== undefined && object.function !== null)
      ? Function.fromPartial(object.function)
      : undefined;
    message.error = object.error ?? 0;
    message.testCaseResults = object.testCaseResults?.map((e) => GenerateFunctionResultTestCaseResult.fromPartial(e)) ||
      [];
    return message;
  },
};

messageTypeRegistry.set(GenerateFunctionResult.$type, GenerateFunctionResult);

function createBaseGenerateFunctionResultTestCaseResult(): GenerateFunctionResultTestCaseResult {
  return {
    $type: "pb.v1alpha1.GenerateFunctionResult.TestCaseResult",
    demonstration: undefined,
    output: undefined,
    diff: "",
  };
}

export const GenerateFunctionResultTestCaseResult = {
  $type: "pb.v1alpha1.GenerateFunctionResult.TestCaseResult" as const,

  encode(message: GenerateFunctionResultTestCaseResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.demonstration !== undefined) {
      GenerateFunctionTestCase.encode(message.demonstration, writer.uint32(10).fork()).ldelim();
    }
    if (message.output !== undefined) {
      Variable.encode(message.output, writer.uint32(18).fork()).ldelim();
    }
    if (message.diff !== undefined && message.diff !== "") {
      writer.uint32(26).string(message.diff);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GenerateFunctionResultTestCaseResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateFunctionResultTestCaseResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.demonstration = GenerateFunctionTestCase.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.output = Variable.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.diff = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GenerateFunctionResultTestCaseResult {
    return {
      $type: GenerateFunctionResultTestCaseResult.$type,
      demonstration: isSet(object.demonstration) ? GenerateFunctionTestCase.fromJSON(object.demonstration) : undefined,
      output: isSet(object.output) ? Variable.fromJSON(object.output) : undefined,
      diff: isSet(object.diff) ? globalThis.String(object.diff) : "",
    };
  },

  toJSON(message: GenerateFunctionResultTestCaseResult): unknown {
    const obj: any = {};
    if (message.demonstration !== undefined) {
      obj.demonstration = GenerateFunctionTestCase.toJSON(message.demonstration);
    }
    if (message.output !== undefined) {
      obj.output = Variable.toJSON(message.output);
    }
    if (message.diff !== undefined && message.diff !== "") {
      obj.diff = message.diff;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GenerateFunctionResultTestCaseResult>, I>>(
    base?: I,
  ): GenerateFunctionResultTestCaseResult {
    return GenerateFunctionResultTestCaseResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GenerateFunctionResultTestCaseResult>, I>>(
    object: I,
  ): GenerateFunctionResultTestCaseResult {
    const message = createBaseGenerateFunctionResultTestCaseResult();
    message.demonstration = (object.demonstration !== undefined && object.demonstration !== null)
      ? GenerateFunctionTestCase.fromPartial(object.demonstration)
      : undefined;
    message.output = (object.output !== undefined && object.output !== null)
      ? Variable.fromPartial(object.output)
      : undefined;
    message.diff = object.diff ?? "";
    return message;
  },
};

messageTypeRegistry.set(GenerateFunctionResultTestCaseResult.$type, GenerateFunctionResultTestCaseResult);

function createBaseExtractVariablesTestCase(): ExtractVariablesTestCase {
  return { $type: "pb.v1alpha1.ExtractVariablesTestCase", uiState: undefined, result: undefined };
}

export const ExtractVariablesTestCase = {
  $type: "pb.v1alpha1.ExtractVariablesTestCase" as const,

  encode(message: ExtractVariablesTestCase, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uiState !== undefined) {
      UiState.encode(message.uiState, writer.uint32(10).fork()).ldelim();
    }
    if (message.result !== undefined) {
      VariableValue.encode(message.result, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExtractVariablesTestCase {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExtractVariablesTestCase();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uiState = UiState.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.result = VariableValue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExtractVariablesTestCase {
    return {
      $type: ExtractVariablesTestCase.$type,
      uiState: isSet(object.uiState) ? UiState.fromJSON(object.uiState) : undefined,
      result: isSet(object.result) ? VariableValue.fromJSON(object.result) : undefined,
    };
  },

  toJSON(message: ExtractVariablesTestCase): unknown {
    const obj: any = {};
    if (message.uiState !== undefined) {
      obj.uiState = UiState.toJSON(message.uiState);
    }
    if (message.result !== undefined) {
      obj.result = VariableValue.toJSON(message.result);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExtractVariablesTestCase>, I>>(base?: I): ExtractVariablesTestCase {
    return ExtractVariablesTestCase.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExtractVariablesTestCase>, I>>(object: I): ExtractVariablesTestCase {
    const message = createBaseExtractVariablesTestCase();
    message.uiState = (object.uiState !== undefined && object.uiState !== null)
      ? UiState.fromPartial(object.uiState)
      : undefined;
    message.result = (object.result !== undefined && object.result !== null)
      ? VariableValue.fromPartial(object.result)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(ExtractVariablesTestCase.$type, ExtractVariablesTestCase);

function createBaseGenerateExtractionScriptRequest(): GenerateExtractionScriptRequest {
  return {
    $type: "pb.v1alpha1.GenerateExtractionScriptRequest",
    policy: undefined,
    schema: undefined,
    uiState: undefined,
    demonstrations: [],
  };
}

export const GenerateExtractionScriptRequest = {
  $type: "pb.v1alpha1.GenerateExtractionScriptRequest" as const,

  encode(message: GenerateExtractionScriptRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.policy !== undefined) {
      Policy.encode(message.policy, writer.uint32(10).fork()).ldelim();
    }
    if (message.schema !== undefined) {
      VariableType.encode(message.schema, writer.uint32(18).fork()).ldelim();
    }
    if (message.uiState !== undefined) {
      UiState.encode(message.uiState, writer.uint32(26).fork()).ldelim();
    }
    if (message.demonstrations !== undefined && message.demonstrations.length !== 0) {
      for (const v of message.demonstrations) {
        ExtractVariablesTestCase.encode(v!, writer.uint32(34).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GenerateExtractionScriptRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateExtractionScriptRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.policy = Policy.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.schema = VariableType.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.uiState = UiState.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.demonstrations!.push(ExtractVariablesTestCase.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GenerateExtractionScriptRequest {
    return {
      $type: GenerateExtractionScriptRequest.$type,
      policy: isSet(object.policy) ? Policy.fromJSON(object.policy) : undefined,
      schema: isSet(object.schema) ? VariableType.fromJSON(object.schema) : undefined,
      uiState: isSet(object.uiState) ? UiState.fromJSON(object.uiState) : undefined,
      demonstrations: globalThis.Array.isArray(object?.demonstrations)
        ? object.demonstrations.map((e: any) => ExtractVariablesTestCase.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GenerateExtractionScriptRequest): unknown {
    const obj: any = {};
    if (message.policy !== undefined) {
      obj.policy = Policy.toJSON(message.policy);
    }
    if (message.schema !== undefined) {
      obj.schema = VariableType.toJSON(message.schema);
    }
    if (message.uiState !== undefined) {
      obj.uiState = UiState.toJSON(message.uiState);
    }
    if (message.demonstrations?.length) {
      obj.demonstrations = message.demonstrations.map((e) => ExtractVariablesTestCase.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GenerateExtractionScriptRequest>, I>>(base?: I): GenerateExtractionScriptRequest {
    return GenerateExtractionScriptRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GenerateExtractionScriptRequest>, I>>(
    object: I,
  ): GenerateExtractionScriptRequest {
    const message = createBaseGenerateExtractionScriptRequest();
    message.policy = (object.policy !== undefined && object.policy !== null)
      ? Policy.fromPartial(object.policy)
      : undefined;
    message.schema = (object.schema !== undefined && object.schema !== null)
      ? VariableType.fromPartial(object.schema)
      : undefined;
    message.uiState = (object.uiState !== undefined && object.uiState !== null)
      ? UiState.fromPartial(object.uiState)
      : undefined;
    message.demonstrations = object.demonstrations?.map((e) => ExtractVariablesTestCase.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(GenerateExtractionScriptRequest.$type, GenerateExtractionScriptRequest);

function createBaseGenerateExtractionScriptResult(): GenerateExtractionScriptResult {
  return { $type: "pb.v1alpha1.GenerateExtractionScriptResult", extractionScript: "", result: undefined };
}

export const GenerateExtractionScriptResult = {
  $type: "pb.v1alpha1.GenerateExtractionScriptResult" as const,

  encode(message: GenerateExtractionScriptResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.extractionScript !== undefined && message.extractionScript !== "") {
      writer.uint32(10).string(message.extractionScript);
    }
    if (message.result !== undefined) {
      VariableValue.encode(message.result, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GenerateExtractionScriptResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateExtractionScriptResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.extractionScript = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.result = VariableValue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GenerateExtractionScriptResult {
    return {
      $type: GenerateExtractionScriptResult.$type,
      extractionScript: isSet(object.extractionScript) ? globalThis.String(object.extractionScript) : "",
      result: isSet(object.result) ? VariableValue.fromJSON(object.result) : undefined,
    };
  },

  toJSON(message: GenerateExtractionScriptResult): unknown {
    const obj: any = {};
    if (message.extractionScript !== undefined && message.extractionScript !== "") {
      obj.extractionScript = message.extractionScript;
    }
    if (message.result !== undefined) {
      obj.result = VariableValue.toJSON(message.result);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GenerateExtractionScriptResult>, I>>(base?: I): GenerateExtractionScriptResult {
    return GenerateExtractionScriptResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GenerateExtractionScriptResult>, I>>(
    object: I,
  ): GenerateExtractionScriptResult {
    const message = createBaseGenerateExtractionScriptResult();
    message.extractionScript = object.extractionScript ?? "";
    message.result = (object.result !== undefined && object.result !== null)
      ? VariableValue.fromPartial(object.result)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(GenerateExtractionScriptResult.$type, GenerateExtractionScriptResult);

function createBaseExtractVariablesRequest(): ExtractVariablesRequest {
  return {
    $type: "pb.v1alpha1.ExtractVariablesRequest",
    policy: undefined,
    schema: undefined,
    uiState: undefined,
    demonstrations: [],
    extractionScript: "",
  };
}

export const ExtractVariablesRequest = {
  $type: "pb.v1alpha1.ExtractVariablesRequest" as const,

  encode(message: ExtractVariablesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.policy !== undefined) {
      Policy.encode(message.policy, writer.uint32(10).fork()).ldelim();
    }
    if (message.schema !== undefined) {
      VariableType.encode(message.schema, writer.uint32(18).fork()).ldelim();
    }
    if (message.uiState !== undefined) {
      UiState.encode(message.uiState, writer.uint32(26).fork()).ldelim();
    }
    if (message.demonstrations !== undefined && message.demonstrations.length !== 0) {
      for (const v of message.demonstrations) {
        ExtractVariablesTestCase.encode(v!, writer.uint32(34).fork()).ldelim();
      }
    }
    if (message.extractionScript !== undefined && message.extractionScript !== "") {
      writer.uint32(42).string(message.extractionScript);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExtractVariablesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExtractVariablesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.policy = Policy.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.schema = VariableType.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.uiState = UiState.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.demonstrations!.push(ExtractVariablesTestCase.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.extractionScript = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExtractVariablesRequest {
    return {
      $type: ExtractVariablesRequest.$type,
      policy: isSet(object.policy) ? Policy.fromJSON(object.policy) : undefined,
      schema: isSet(object.schema) ? VariableType.fromJSON(object.schema) : undefined,
      uiState: isSet(object.uiState) ? UiState.fromJSON(object.uiState) : undefined,
      demonstrations: globalThis.Array.isArray(object?.demonstrations)
        ? object.demonstrations.map((e: any) => ExtractVariablesTestCase.fromJSON(e))
        : [],
      extractionScript: isSet(object.extractionScript) ? globalThis.String(object.extractionScript) : "",
    };
  },

  toJSON(message: ExtractVariablesRequest): unknown {
    const obj: any = {};
    if (message.policy !== undefined) {
      obj.policy = Policy.toJSON(message.policy);
    }
    if (message.schema !== undefined) {
      obj.schema = VariableType.toJSON(message.schema);
    }
    if (message.uiState !== undefined) {
      obj.uiState = UiState.toJSON(message.uiState);
    }
    if (message.demonstrations?.length) {
      obj.demonstrations = message.demonstrations.map((e) => ExtractVariablesTestCase.toJSON(e));
    }
    if (message.extractionScript !== undefined && message.extractionScript !== "") {
      obj.extractionScript = message.extractionScript;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExtractVariablesRequest>, I>>(base?: I): ExtractVariablesRequest {
    return ExtractVariablesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExtractVariablesRequest>, I>>(object: I): ExtractVariablesRequest {
    const message = createBaseExtractVariablesRequest();
    message.policy = (object.policy !== undefined && object.policy !== null)
      ? Policy.fromPartial(object.policy)
      : undefined;
    message.schema = (object.schema !== undefined && object.schema !== null)
      ? VariableType.fromPartial(object.schema)
      : undefined;
    message.uiState = (object.uiState !== undefined && object.uiState !== null)
      ? UiState.fromPartial(object.uiState)
      : undefined;
    message.demonstrations = object.demonstrations?.map((e) => ExtractVariablesTestCase.fromPartial(e)) || [];
    message.extractionScript = object.extractionScript ?? "";
    return message;
  },
};

messageTypeRegistry.set(ExtractVariablesRequest.$type, ExtractVariablesRequest);

function createBaseExtractVariablesResult(): ExtractVariablesResult {
  return { $type: "pb.v1alpha1.ExtractVariablesResult", result: undefined };
}

export const ExtractVariablesResult = {
  $type: "pb.v1alpha1.ExtractVariablesResult" as const,

  encode(message: ExtractVariablesResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.result !== undefined) {
      VariableValue.encode(message.result, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExtractVariablesResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExtractVariablesResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.result = VariableValue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExtractVariablesResult {
    return {
      $type: ExtractVariablesResult.$type,
      result: isSet(object.result) ? VariableValue.fromJSON(object.result) : undefined,
    };
  },

  toJSON(message: ExtractVariablesResult): unknown {
    const obj: any = {};
    if (message.result !== undefined) {
      obj.result = VariableValue.toJSON(message.result);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExtractVariablesResult>, I>>(base?: I): ExtractVariablesResult {
    return ExtractVariablesResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExtractVariablesResult>, I>>(object: I): ExtractVariablesResult {
    const message = createBaseExtractVariablesResult();
    message.result = (object.result !== undefined && object.result !== null)
      ? VariableValue.fromPartial(object.result)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(ExtractVariablesResult.$type, ExtractVariablesResult);

function createBaseExtractStructuredDataRequest(): ExtractStructuredDataRequest {
  return {
    $type: "pb.v1alpha1.ExtractStructuredDataRequest",
    policy: undefined,
    input: undefined,
    outputSchema: undefined,
  };
}

export const ExtractStructuredDataRequest = {
  $type: "pb.v1alpha1.ExtractStructuredDataRequest" as const,

  encode(message: ExtractStructuredDataRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.policy !== undefined) {
      Policy.encode(message.policy, writer.uint32(10).fork()).ldelim();
    }
    if (message.input !== undefined) {
      Variable.encode(message.input, writer.uint32(18).fork()).ldelim();
    }
    if (message.outputSchema !== undefined) {
      VariableType.encode(message.outputSchema, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExtractStructuredDataRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExtractStructuredDataRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.policy = Policy.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.input = Variable.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.outputSchema = VariableType.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExtractStructuredDataRequest {
    return {
      $type: ExtractStructuredDataRequest.$type,
      policy: isSet(object.policy) ? Policy.fromJSON(object.policy) : undefined,
      input: isSet(object.input) ? Variable.fromJSON(object.input) : undefined,
      outputSchema: isSet(object.outputSchema) ? VariableType.fromJSON(object.outputSchema) : undefined,
    };
  },

  toJSON(message: ExtractStructuredDataRequest): unknown {
    const obj: any = {};
    if (message.policy !== undefined) {
      obj.policy = Policy.toJSON(message.policy);
    }
    if (message.input !== undefined) {
      obj.input = Variable.toJSON(message.input);
    }
    if (message.outputSchema !== undefined) {
      obj.outputSchema = VariableType.toJSON(message.outputSchema);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExtractStructuredDataRequest>, I>>(base?: I): ExtractStructuredDataRequest {
    return ExtractStructuredDataRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExtractStructuredDataRequest>, I>>(object: I): ExtractStructuredDataRequest {
    const message = createBaseExtractStructuredDataRequest();
    message.policy = (object.policy !== undefined && object.policy !== null)
      ? Policy.fromPartial(object.policy)
      : undefined;
    message.input = (object.input !== undefined && object.input !== null)
      ? Variable.fromPartial(object.input)
      : undefined;
    message.outputSchema = (object.outputSchema !== undefined && object.outputSchema !== null)
      ? VariableType.fromPartial(object.outputSchema)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(ExtractStructuredDataRequest.$type, ExtractStructuredDataRequest);

function createBaseExtractStructuredDataResult(): ExtractStructuredDataResult {
  return { $type: "pb.v1alpha1.ExtractStructuredDataResult", result: undefined };
}

export const ExtractStructuredDataResult = {
  $type: "pb.v1alpha1.ExtractStructuredDataResult" as const,

  encode(message: ExtractStructuredDataResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.result !== undefined) {
      Variable.encode(message.result, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExtractStructuredDataResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExtractStructuredDataResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.result = Variable.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExtractStructuredDataResult {
    return {
      $type: ExtractStructuredDataResult.$type,
      result: isSet(object.result) ? Variable.fromJSON(object.result) : undefined,
    };
  },

  toJSON(message: ExtractStructuredDataResult): unknown {
    const obj: any = {};
    if (message.result !== undefined) {
      obj.result = Variable.toJSON(message.result);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExtractStructuredDataResult>, I>>(base?: I): ExtractStructuredDataResult {
    return ExtractStructuredDataResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExtractStructuredDataResult>, I>>(object: I): ExtractStructuredDataResult {
    const message = createBaseExtractStructuredDataResult();
    message.result = (object.result !== undefined && object.result !== null)
      ? Variable.fromPartial(object.result)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(ExtractStructuredDataResult.$type, ExtractStructuredDataResult);

function createBaseSmartActionOptions(): SmartActionOptions {
  return { $type: "pb.v1alpha1.SmartActionOptions", isNewSmartActionFramework: false, convertLegacyProto: false };
}

export const SmartActionOptions = {
  $type: "pb.v1alpha1.SmartActionOptions" as const,

  encode(message: SmartActionOptions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isNewSmartActionFramework !== undefined && message.isNewSmartActionFramework !== false) {
      writer.uint32(8).bool(message.isNewSmartActionFramework);
    }
    if (message.convertLegacyProto !== undefined && message.convertLegacyProto !== false) {
      writer.uint32(16).bool(message.convertLegacyProto);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SmartActionOptions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSmartActionOptions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isNewSmartActionFramework = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.convertLegacyProto = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SmartActionOptions {
    return {
      $type: SmartActionOptions.$type,
      isNewSmartActionFramework: isSet(object.isNewSmartActionFramework)
        ? globalThis.Boolean(object.isNewSmartActionFramework)
        : false,
      convertLegacyProto: isSet(object.convertLegacyProto) ? globalThis.Boolean(object.convertLegacyProto) : false,
    };
  },

  toJSON(message: SmartActionOptions): unknown {
    const obj: any = {};
    if (message.isNewSmartActionFramework !== undefined && message.isNewSmartActionFramework !== false) {
      obj.isNewSmartActionFramework = message.isNewSmartActionFramework;
    }
    if (message.convertLegacyProto !== undefined && message.convertLegacyProto !== false) {
      obj.convertLegacyProto = message.convertLegacyProto;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SmartActionOptions>, I>>(base?: I): SmartActionOptions {
    return SmartActionOptions.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SmartActionOptions>, I>>(object: I): SmartActionOptions {
    const message = createBaseSmartActionOptions();
    message.isNewSmartActionFramework = object.isNewSmartActionFramework ?? false;
    message.convertLegacyProto = object.convertLegacyProto ?? false;
    return message;
  },
};

messageTypeRegistry.set(SmartActionOptions.$type, SmartActionOptions);

function createBaseProcessSmartActionsRequest(): ProcessSmartActionsRequest {
  return {
    $type: "pb.v1alpha1.ProcessSmartActionsRequest",
    actions: [],
    taskId: "",
    actionId: "",
    bypassDocaiCache: false,
    bypassLlmCache: false,
    runAsync: false,
    reviewTaskId: "",
    smartActionOptions: undefined,
    clickhouseDatabase: "",
    temporalWorkflowId: "",
    temporalRunId: "",
  };
}

export const ProcessSmartActionsRequest = {
  $type: "pb.v1alpha1.ProcessSmartActionsRequest" as const,

  encode(message: ProcessSmartActionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.actions !== undefined && message.actions.length !== 0) {
      for (const v of message.actions) {
        SmartAction.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    if (message.taskId !== undefined && message.taskId !== "") {
      writer.uint32(18).string(message.taskId);
    }
    if (message.actionId !== undefined && message.actionId !== "") {
      writer.uint32(26).string(message.actionId);
    }
    if (message.bypassDocaiCache !== undefined && message.bypassDocaiCache !== false) {
      writer.uint32(32).bool(message.bypassDocaiCache);
    }
    if (message.bypassLlmCache !== undefined && message.bypassLlmCache !== false) {
      writer.uint32(40).bool(message.bypassLlmCache);
    }
    if (message.runAsync !== undefined && message.runAsync !== false) {
      writer.uint32(48).bool(message.runAsync);
    }
    if (message.reviewTaskId !== undefined && message.reviewTaskId !== "") {
      writer.uint32(58).string(message.reviewTaskId);
    }
    if (message.smartActionOptions !== undefined) {
      SmartActionOptions.encode(message.smartActionOptions, writer.uint32(66).fork()).ldelim();
    }
    if (message.clickhouseDatabase !== undefined && message.clickhouseDatabase !== "") {
      writer.uint32(74).string(message.clickhouseDatabase);
    }
    if (message.temporalWorkflowId !== undefined && message.temporalWorkflowId !== "") {
      writer.uint32(82).string(message.temporalWorkflowId);
    }
    if (message.temporalRunId !== undefined && message.temporalRunId !== "") {
      writer.uint32(90).string(message.temporalRunId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProcessSmartActionsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProcessSmartActionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.actions!.push(SmartAction.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.taskId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.actionId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.bypassDocaiCache = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.bypassLlmCache = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.runAsync = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.reviewTaskId = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.smartActionOptions = SmartActionOptions.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.clickhouseDatabase = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.temporalWorkflowId = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.temporalRunId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProcessSmartActionsRequest {
    return {
      $type: ProcessSmartActionsRequest.$type,
      actions: globalThis.Array.isArray(object?.actions) ? object.actions.map((e: any) => SmartAction.fromJSON(e)) : [],
      taskId: isSet(object.taskId) ? globalThis.String(object.taskId) : "",
      actionId: isSet(object.actionId) ? globalThis.String(object.actionId) : "",
      bypassDocaiCache: isSet(object.bypassDocaiCache) ? globalThis.Boolean(object.bypassDocaiCache) : false,
      bypassLlmCache: isSet(object.bypassLlmCache) ? globalThis.Boolean(object.bypassLlmCache) : false,
      runAsync: isSet(object.runAsync) ? globalThis.Boolean(object.runAsync) : false,
      reviewTaskId: isSet(object.reviewTaskId) ? globalThis.String(object.reviewTaskId) : "",
      smartActionOptions: isSet(object.smartActionOptions)
        ? SmartActionOptions.fromJSON(object.smartActionOptions)
        : undefined,
      clickhouseDatabase: isSet(object.clickhouseDatabase) ? globalThis.String(object.clickhouseDatabase) : "",
      temporalWorkflowId: isSet(object.temporalWorkflowId) ? globalThis.String(object.temporalWorkflowId) : "",
      temporalRunId: isSet(object.temporalRunId) ? globalThis.String(object.temporalRunId) : "",
    };
  },

  toJSON(message: ProcessSmartActionsRequest): unknown {
    const obj: any = {};
    if (message.actions?.length) {
      obj.actions = message.actions.map((e) => SmartAction.toJSON(e));
    }
    if (message.taskId !== undefined && message.taskId !== "") {
      obj.taskId = message.taskId;
    }
    if (message.actionId !== undefined && message.actionId !== "") {
      obj.actionId = message.actionId;
    }
    if (message.bypassDocaiCache !== undefined && message.bypassDocaiCache !== false) {
      obj.bypassDocaiCache = message.bypassDocaiCache;
    }
    if (message.bypassLlmCache !== undefined && message.bypassLlmCache !== false) {
      obj.bypassLlmCache = message.bypassLlmCache;
    }
    if (message.runAsync !== undefined && message.runAsync !== false) {
      obj.runAsync = message.runAsync;
    }
    if (message.reviewTaskId !== undefined && message.reviewTaskId !== "") {
      obj.reviewTaskId = message.reviewTaskId;
    }
    if (message.smartActionOptions !== undefined) {
      obj.smartActionOptions = SmartActionOptions.toJSON(message.smartActionOptions);
    }
    if (message.clickhouseDatabase !== undefined && message.clickhouseDatabase !== "") {
      obj.clickhouseDatabase = message.clickhouseDatabase;
    }
    if (message.temporalWorkflowId !== undefined && message.temporalWorkflowId !== "") {
      obj.temporalWorkflowId = message.temporalWorkflowId;
    }
    if (message.temporalRunId !== undefined && message.temporalRunId !== "") {
      obj.temporalRunId = message.temporalRunId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProcessSmartActionsRequest>, I>>(base?: I): ProcessSmartActionsRequest {
    return ProcessSmartActionsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProcessSmartActionsRequest>, I>>(object: I): ProcessSmartActionsRequest {
    const message = createBaseProcessSmartActionsRequest();
    message.actions = object.actions?.map((e) => SmartAction.fromPartial(e)) || [];
    message.taskId = object.taskId ?? "";
    message.actionId = object.actionId ?? "";
    message.bypassDocaiCache = object.bypassDocaiCache ?? false;
    message.bypassLlmCache = object.bypassLlmCache ?? false;
    message.runAsync = object.runAsync ?? false;
    message.reviewTaskId = object.reviewTaskId ?? "";
    message.smartActionOptions = (object.smartActionOptions !== undefined && object.smartActionOptions !== null)
      ? SmartActionOptions.fromPartial(object.smartActionOptions)
      : undefined;
    message.clickhouseDatabase = object.clickhouseDatabase ?? "";
    message.temporalWorkflowId = object.temporalWorkflowId ?? "";
    message.temporalRunId = object.temporalRunId ?? "";
    return message;
  },
};

messageTypeRegistry.set(ProcessSmartActionsRequest.$type, ProcessSmartActionsRequest);

function createBaseSmartAction(): SmartAction {
  return {
    $type: "pb.v1alpha1.SmartAction",
    extractFields: undefined,
    validateFieldValues: undefined,
    flagKeywords: undefined,
    detectDuplicateLineItems: undefined,
    reconcileLineItems: undefined,
    generateText: undefined,
    classify: undefined,
    extractFieldsUnified: undefined,
    reconcileLineItemsUnified: undefined,
    compareRequest: undefined,
    generateFunctionRequest: undefined,
    generateExtractionScript: undefined,
    extractVariables: undefined,
    extractStructuredData: undefined,
    hyperparameter: undefined,
  };
}

export const SmartAction = {
  $type: "pb.v1alpha1.SmartAction" as const,

  encode(message: SmartAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.extractFields !== undefined) {
      ExtractFields.encode(message.extractFields, writer.uint32(10).fork()).ldelim();
    }
    if (message.validateFieldValues !== undefined) {
      ValidateFieldValues.encode(message.validateFieldValues, writer.uint32(18).fork()).ldelim();
    }
    if (message.flagKeywords !== undefined) {
      FlagKeywords.encode(message.flagKeywords, writer.uint32(26).fork()).ldelim();
    }
    if (message.detectDuplicateLineItems !== undefined) {
      DetectDuplicateLineItems.encode(message.detectDuplicateLineItems, writer.uint32(34).fork()).ldelim();
    }
    if (message.reconcileLineItems !== undefined) {
      ReconcileItems.encode(message.reconcileLineItems, writer.uint32(42).fork()).ldelim();
    }
    if (message.generateText !== undefined) {
      GenerateText.encode(message.generateText, writer.uint32(50).fork()).ldelim();
    }
    if (message.classify !== undefined) {
      Classify.encode(message.classify, writer.uint32(58).fork()).ldelim();
    }
    if (message.extractFieldsUnified !== undefined) {
      ExtractFieldsRequestUnified.encode(message.extractFieldsUnified, writer.uint32(66).fork()).ldelim();
    }
    if (message.reconcileLineItemsUnified !== undefined) {
      ReconcileItemsRequestUnified.encode(message.reconcileLineItemsUnified, writer.uint32(74).fork()).ldelim();
    }
    if (message.compareRequest !== undefined) {
      CompareRequest.encode(message.compareRequest, writer.uint32(90).fork()).ldelim();
    }
    if (message.generateFunctionRequest !== undefined) {
      GenerateFunctionRequest.encode(message.generateFunctionRequest, writer.uint32(98).fork()).ldelim();
    }
    if (message.generateExtractionScript !== undefined) {
      GenerateExtractionScriptRequest.encode(message.generateExtractionScript, writer.uint32(106).fork()).ldelim();
    }
    if (message.extractVariables !== undefined) {
      ExtractVariablesRequest.encode(message.extractVariables, writer.uint32(114).fork()).ldelim();
    }
    if (message.extractStructuredData !== undefined) {
      ExtractStructuredDataRequest.encode(message.extractStructuredData, writer.uint32(122).fork()).ldelim();
    }
    if (message.hyperparameter !== undefined) {
      Hyperparameter.encode(message.hyperparameter, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SmartAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSmartAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.extractFields = ExtractFields.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.validateFieldValues = ValidateFieldValues.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.flagKeywords = FlagKeywords.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.detectDuplicateLineItems = DetectDuplicateLineItems.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.reconcileLineItems = ReconcileItems.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.generateText = GenerateText.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.classify = Classify.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.extractFieldsUnified = ExtractFieldsRequestUnified.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.reconcileLineItemsUnified = ReconcileItemsRequestUnified.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.compareRequest = CompareRequest.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.generateFunctionRequest = GenerateFunctionRequest.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.generateExtractionScript = GenerateExtractionScriptRequest.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.extractVariables = ExtractVariablesRequest.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.extractStructuredData = ExtractStructuredDataRequest.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.hyperparameter = Hyperparameter.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SmartAction {
    return {
      $type: SmartAction.$type,
      extractFields: isSet(object.extractFields) ? ExtractFields.fromJSON(object.extractFields) : undefined,
      validateFieldValues: isSet(object.validateFieldValues)
        ? ValidateFieldValues.fromJSON(object.validateFieldValues)
        : undefined,
      flagKeywords: isSet(object.flagKeywords) ? FlagKeywords.fromJSON(object.flagKeywords) : undefined,
      detectDuplicateLineItems: isSet(object.detectDuplicateLineItems)
        ? DetectDuplicateLineItems.fromJSON(object.detectDuplicateLineItems)
        : undefined,
      reconcileLineItems: isSet(object.reconcileLineItems)
        ? ReconcileItems.fromJSON(object.reconcileLineItems)
        : undefined,
      generateText: isSet(object.generateText) ? GenerateText.fromJSON(object.generateText) : undefined,
      classify: isSet(object.classify) ? Classify.fromJSON(object.classify) : undefined,
      extractFieldsUnified: isSet(object.extractFieldsUnified)
        ? ExtractFieldsRequestUnified.fromJSON(object.extractFieldsUnified)
        : undefined,
      reconcileLineItemsUnified: isSet(object.reconcileLineItemsUnified)
        ? ReconcileItemsRequestUnified.fromJSON(object.reconcileLineItemsUnified)
        : undefined,
      compareRequest: isSet(object.compareRequest) ? CompareRequest.fromJSON(object.compareRequest) : undefined,
      generateFunctionRequest: isSet(object.generateFunctionRequest)
        ? GenerateFunctionRequest.fromJSON(object.generateFunctionRequest)
        : undefined,
      generateExtractionScript: isSet(object.generateExtractionScript)
        ? GenerateExtractionScriptRequest.fromJSON(object.generateExtractionScript)
        : undefined,
      extractVariables: isSet(object.extractVariables)
        ? ExtractVariablesRequest.fromJSON(object.extractVariables)
        : undefined,
      extractStructuredData: isSet(object.extractStructuredData)
        ? ExtractStructuredDataRequest.fromJSON(object.extractStructuredData)
        : undefined,
      hyperparameter: isSet(object.hyperparameter) ? Hyperparameter.fromJSON(object.hyperparameter) : undefined,
    };
  },

  toJSON(message: SmartAction): unknown {
    const obj: any = {};
    if (message.extractFields !== undefined) {
      obj.extractFields = ExtractFields.toJSON(message.extractFields);
    }
    if (message.validateFieldValues !== undefined) {
      obj.validateFieldValues = ValidateFieldValues.toJSON(message.validateFieldValues);
    }
    if (message.flagKeywords !== undefined) {
      obj.flagKeywords = FlagKeywords.toJSON(message.flagKeywords);
    }
    if (message.detectDuplicateLineItems !== undefined) {
      obj.detectDuplicateLineItems = DetectDuplicateLineItems.toJSON(message.detectDuplicateLineItems);
    }
    if (message.reconcileLineItems !== undefined) {
      obj.reconcileLineItems = ReconcileItems.toJSON(message.reconcileLineItems);
    }
    if (message.generateText !== undefined) {
      obj.generateText = GenerateText.toJSON(message.generateText);
    }
    if (message.classify !== undefined) {
      obj.classify = Classify.toJSON(message.classify);
    }
    if (message.extractFieldsUnified !== undefined) {
      obj.extractFieldsUnified = ExtractFieldsRequestUnified.toJSON(message.extractFieldsUnified);
    }
    if (message.reconcileLineItemsUnified !== undefined) {
      obj.reconcileLineItemsUnified = ReconcileItemsRequestUnified.toJSON(message.reconcileLineItemsUnified);
    }
    if (message.compareRequest !== undefined) {
      obj.compareRequest = CompareRequest.toJSON(message.compareRequest);
    }
    if (message.generateFunctionRequest !== undefined) {
      obj.generateFunctionRequest = GenerateFunctionRequest.toJSON(message.generateFunctionRequest);
    }
    if (message.generateExtractionScript !== undefined) {
      obj.generateExtractionScript = GenerateExtractionScriptRequest.toJSON(message.generateExtractionScript);
    }
    if (message.extractVariables !== undefined) {
      obj.extractVariables = ExtractVariablesRequest.toJSON(message.extractVariables);
    }
    if (message.extractStructuredData !== undefined) {
      obj.extractStructuredData = ExtractStructuredDataRequest.toJSON(message.extractStructuredData);
    }
    if (message.hyperparameter !== undefined) {
      obj.hyperparameter = Hyperparameter.toJSON(message.hyperparameter);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SmartAction>, I>>(base?: I): SmartAction {
    return SmartAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SmartAction>, I>>(object: I): SmartAction {
    const message = createBaseSmartAction();
    message.extractFields = (object.extractFields !== undefined && object.extractFields !== null)
      ? ExtractFields.fromPartial(object.extractFields)
      : undefined;
    message.validateFieldValues = (object.validateFieldValues !== undefined && object.validateFieldValues !== null)
      ? ValidateFieldValues.fromPartial(object.validateFieldValues)
      : undefined;
    message.flagKeywords = (object.flagKeywords !== undefined && object.flagKeywords !== null)
      ? FlagKeywords.fromPartial(object.flagKeywords)
      : undefined;
    message.detectDuplicateLineItems =
      (object.detectDuplicateLineItems !== undefined && object.detectDuplicateLineItems !== null)
        ? DetectDuplicateLineItems.fromPartial(object.detectDuplicateLineItems)
        : undefined;
    message.reconcileLineItems = (object.reconcileLineItems !== undefined && object.reconcileLineItems !== null)
      ? ReconcileItems.fromPartial(object.reconcileLineItems)
      : undefined;
    message.generateText = (object.generateText !== undefined && object.generateText !== null)
      ? GenerateText.fromPartial(object.generateText)
      : undefined;
    message.classify = (object.classify !== undefined && object.classify !== null)
      ? Classify.fromPartial(object.classify)
      : undefined;
    message.extractFieldsUnified = (object.extractFieldsUnified !== undefined && object.extractFieldsUnified !== null)
      ? ExtractFieldsRequestUnified.fromPartial(object.extractFieldsUnified)
      : undefined;
    message.reconcileLineItemsUnified =
      (object.reconcileLineItemsUnified !== undefined && object.reconcileLineItemsUnified !== null)
        ? ReconcileItemsRequestUnified.fromPartial(object.reconcileLineItemsUnified)
        : undefined;
    message.compareRequest = (object.compareRequest !== undefined && object.compareRequest !== null)
      ? CompareRequest.fromPartial(object.compareRequest)
      : undefined;
    message.generateFunctionRequest =
      (object.generateFunctionRequest !== undefined && object.generateFunctionRequest !== null)
        ? GenerateFunctionRequest.fromPartial(object.generateFunctionRequest)
        : undefined;
    message.generateExtractionScript =
      (object.generateExtractionScript !== undefined && object.generateExtractionScript !== null)
        ? GenerateExtractionScriptRequest.fromPartial(object.generateExtractionScript)
        : undefined;
    message.extractVariables = (object.extractVariables !== undefined && object.extractVariables !== null)
      ? ExtractVariablesRequest.fromPartial(object.extractVariables)
      : undefined;
    message.extractStructuredData =
      (object.extractStructuredData !== undefined && object.extractStructuredData !== null)
        ? ExtractStructuredDataRequest.fromPartial(object.extractStructuredData)
        : undefined;
    message.hyperparameter = (object.hyperparameter !== undefined && object.hyperparameter !== null)
      ? Hyperparameter.fromPartial(object.hyperparameter)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(SmartAction.$type, SmartAction);

function createBaseProcessSmartActionsResponse(): ProcessSmartActionsResponse {
  return { $type: "pb.v1alpha1.ProcessSmartActionsResponse", results: [] };
}

export const ProcessSmartActionsResponse = {
  $type: "pb.v1alpha1.ProcessSmartActionsResponse" as const,

  encode(message: ProcessSmartActionsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.results !== undefined && message.results.length !== 0) {
      for (const v of message.results) {
        SmartActionResult.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProcessSmartActionsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProcessSmartActionsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.results!.push(SmartActionResult.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProcessSmartActionsResponse {
    return {
      $type: ProcessSmartActionsResponse.$type,
      results: globalThis.Array.isArray(object?.results)
        ? object.results.map((e: any) => SmartActionResult.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProcessSmartActionsResponse): unknown {
    const obj: any = {};
    if (message.results?.length) {
      obj.results = message.results.map((e) => SmartActionResult.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProcessSmartActionsResponse>, I>>(base?: I): ProcessSmartActionsResponse {
    return ProcessSmartActionsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProcessSmartActionsResponse>, I>>(object: I): ProcessSmartActionsResponse {
    const message = createBaseProcessSmartActionsResponse();
    message.results = object.results?.map((e) => SmartActionResult.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(ProcessSmartActionsResponse.$type, ProcessSmartActionsResponse);

function createBaseSmartActionResult(): SmartActionResult {
  return {
    $type: "pb.v1alpha1.SmartActionResult",
    extractFieldsResult: undefined,
    validateFieldValuesResult: undefined,
    flagKeywordsResult: undefined,
    detectDuplicateLineItemsResult: undefined,
    smartActionError: undefined,
    reconcileLineItemsResult: undefined,
    generateTextResult: undefined,
    classifyResult: undefined,
    extractFieldsResultUnified: undefined,
    reconcileLineItemsResultUnified: undefined,
    compareResult: undefined,
    generateFunctionResult: undefined,
    generateExtractionScriptResult: undefined,
    extractVariablesResult: undefined,
    extractStructuredDataResult: undefined,
    numProcessedPages: 0,
  };
}

export const SmartActionResult = {
  $type: "pb.v1alpha1.SmartActionResult" as const,

  encode(message: SmartActionResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.extractFieldsResult !== undefined) {
      ExtractFieldsResult.encode(message.extractFieldsResult, writer.uint32(10).fork()).ldelim();
    }
    if (message.validateFieldValuesResult !== undefined) {
      ValidateFieldValuesResult.encode(message.validateFieldValuesResult, writer.uint32(18).fork()).ldelim();
    }
    if (message.flagKeywordsResult !== undefined) {
      FlagKeywordsResult.encode(message.flagKeywordsResult, writer.uint32(26).fork()).ldelim();
    }
    if (message.detectDuplicateLineItemsResult !== undefined) {
      DetectDuplicateLineItemsResult.encode(message.detectDuplicateLineItemsResult, writer.uint32(34).fork()).ldelim();
    }
    if (message.smartActionError !== undefined) {
      writer.uint32(40).int32(message.smartActionError);
    }
    if (message.reconcileLineItemsResult !== undefined) {
      ReconcileItemsResult.encode(message.reconcileLineItemsResult, writer.uint32(50).fork()).ldelim();
    }
    if (message.generateTextResult !== undefined) {
      GenerateTextResult.encode(message.generateTextResult, writer.uint32(66).fork()).ldelim();
    }
    if (message.classifyResult !== undefined) {
      ClassifyResult.encode(message.classifyResult, writer.uint32(74).fork()).ldelim();
    }
    if (message.extractFieldsResultUnified !== undefined) {
      ExtractFieldsResultUnified.encode(message.extractFieldsResultUnified, writer.uint32(82).fork()).ldelim();
    }
    if (message.reconcileLineItemsResultUnified !== undefined) {
      ReconcileItemsResultUnified.encode(message.reconcileLineItemsResultUnified, writer.uint32(90).fork()).ldelim();
    }
    if (message.compareResult !== undefined) {
      CompareResult.encode(message.compareResult, writer.uint32(98).fork()).ldelim();
    }
    if (message.generateFunctionResult !== undefined) {
      GenerateFunctionResult.encode(message.generateFunctionResult, writer.uint32(106).fork()).ldelim();
    }
    if (message.generateExtractionScriptResult !== undefined) {
      GenerateExtractionScriptResult.encode(message.generateExtractionScriptResult, writer.uint32(114).fork()).ldelim();
    }
    if (message.extractVariablesResult !== undefined) {
      ExtractVariablesResult.encode(message.extractVariablesResult, writer.uint32(122).fork()).ldelim();
    }
    if (message.extractStructuredDataResult !== undefined) {
      ExtractStructuredDataResult.encode(message.extractStructuredDataResult, writer.uint32(130).fork()).ldelim();
    }
    if (message.numProcessedPages !== undefined && message.numProcessedPages !== 0) {
      writer.uint32(56).int32(message.numProcessedPages);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SmartActionResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSmartActionResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.extractFieldsResult = ExtractFieldsResult.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.validateFieldValuesResult = ValidateFieldValuesResult.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.flagKeywordsResult = FlagKeywordsResult.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.detectDuplicateLineItemsResult = DetectDuplicateLineItemsResult.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.smartActionError = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.reconcileLineItemsResult = ReconcileItemsResult.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.generateTextResult = GenerateTextResult.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.classifyResult = ClassifyResult.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.extractFieldsResultUnified = ExtractFieldsResultUnified.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.reconcileLineItemsResultUnified = ReconcileItemsResultUnified.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.compareResult = CompareResult.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.generateFunctionResult = GenerateFunctionResult.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.generateExtractionScriptResult = GenerateExtractionScriptResult.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.extractVariablesResult = ExtractVariablesResult.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.extractStructuredDataResult = ExtractStructuredDataResult.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.numProcessedPages = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SmartActionResult {
    return {
      $type: SmartActionResult.$type,
      extractFieldsResult: isSet(object.extractFieldsResult)
        ? ExtractFieldsResult.fromJSON(object.extractFieldsResult)
        : undefined,
      validateFieldValuesResult: isSet(object.validateFieldValuesResult)
        ? ValidateFieldValuesResult.fromJSON(object.validateFieldValuesResult)
        : undefined,
      flagKeywordsResult: isSet(object.flagKeywordsResult)
        ? FlagKeywordsResult.fromJSON(object.flagKeywordsResult)
        : undefined,
      detectDuplicateLineItemsResult: isSet(object.detectDuplicateLineItemsResult)
        ? DetectDuplicateLineItemsResult.fromJSON(object.detectDuplicateLineItemsResult)
        : undefined,
      smartActionError: isSet(object.smartActionError)
        ? smartActionResultSmartActionErrorFromJSON(object.smartActionError)
        : undefined,
      reconcileLineItemsResult: isSet(object.reconcileLineItemsResult)
        ? ReconcileItemsResult.fromJSON(object.reconcileLineItemsResult)
        : undefined,
      generateTextResult: isSet(object.generateTextResult)
        ? GenerateTextResult.fromJSON(object.generateTextResult)
        : undefined,
      classifyResult: isSet(object.classifyResult) ? ClassifyResult.fromJSON(object.classifyResult) : undefined,
      extractFieldsResultUnified: isSet(object.extractFieldsResultUnified)
        ? ExtractFieldsResultUnified.fromJSON(object.extractFieldsResultUnified)
        : undefined,
      reconcileLineItemsResultUnified: isSet(object.reconcileLineItemsResultUnified)
        ? ReconcileItemsResultUnified.fromJSON(object.reconcileLineItemsResultUnified)
        : undefined,
      compareResult: isSet(object.compareResult) ? CompareResult.fromJSON(object.compareResult) : undefined,
      generateFunctionResult: isSet(object.generateFunctionResult)
        ? GenerateFunctionResult.fromJSON(object.generateFunctionResult)
        : undefined,
      generateExtractionScriptResult: isSet(object.generateExtractionScriptResult)
        ? GenerateExtractionScriptResult.fromJSON(object.generateExtractionScriptResult)
        : undefined,
      extractVariablesResult: isSet(object.extractVariablesResult)
        ? ExtractVariablesResult.fromJSON(object.extractVariablesResult)
        : undefined,
      extractStructuredDataResult: isSet(object.extractStructuredDataResult)
        ? ExtractStructuredDataResult.fromJSON(object.extractStructuredDataResult)
        : undefined,
      numProcessedPages: isSet(object.numProcessedPages) ? globalThis.Number(object.numProcessedPages) : 0,
    };
  },

  toJSON(message: SmartActionResult): unknown {
    const obj: any = {};
    if (message.extractFieldsResult !== undefined) {
      obj.extractFieldsResult = ExtractFieldsResult.toJSON(message.extractFieldsResult);
    }
    if (message.validateFieldValuesResult !== undefined) {
      obj.validateFieldValuesResult = ValidateFieldValuesResult.toJSON(message.validateFieldValuesResult);
    }
    if (message.flagKeywordsResult !== undefined) {
      obj.flagKeywordsResult = FlagKeywordsResult.toJSON(message.flagKeywordsResult);
    }
    if (message.detectDuplicateLineItemsResult !== undefined) {
      obj.detectDuplicateLineItemsResult = DetectDuplicateLineItemsResult.toJSON(
        message.detectDuplicateLineItemsResult,
      );
    }
    if (message.smartActionError !== undefined) {
      obj.smartActionError = smartActionResultSmartActionErrorToJSON(message.smartActionError);
    }
    if (message.reconcileLineItemsResult !== undefined) {
      obj.reconcileLineItemsResult = ReconcileItemsResult.toJSON(message.reconcileLineItemsResult);
    }
    if (message.generateTextResult !== undefined) {
      obj.generateTextResult = GenerateTextResult.toJSON(message.generateTextResult);
    }
    if (message.classifyResult !== undefined) {
      obj.classifyResult = ClassifyResult.toJSON(message.classifyResult);
    }
    if (message.extractFieldsResultUnified !== undefined) {
      obj.extractFieldsResultUnified = ExtractFieldsResultUnified.toJSON(message.extractFieldsResultUnified);
    }
    if (message.reconcileLineItemsResultUnified !== undefined) {
      obj.reconcileLineItemsResultUnified = ReconcileItemsResultUnified.toJSON(message.reconcileLineItemsResultUnified);
    }
    if (message.compareResult !== undefined) {
      obj.compareResult = CompareResult.toJSON(message.compareResult);
    }
    if (message.generateFunctionResult !== undefined) {
      obj.generateFunctionResult = GenerateFunctionResult.toJSON(message.generateFunctionResult);
    }
    if (message.generateExtractionScriptResult !== undefined) {
      obj.generateExtractionScriptResult = GenerateExtractionScriptResult.toJSON(
        message.generateExtractionScriptResult,
      );
    }
    if (message.extractVariablesResult !== undefined) {
      obj.extractVariablesResult = ExtractVariablesResult.toJSON(message.extractVariablesResult);
    }
    if (message.extractStructuredDataResult !== undefined) {
      obj.extractStructuredDataResult = ExtractStructuredDataResult.toJSON(message.extractStructuredDataResult);
    }
    if (message.numProcessedPages !== undefined && message.numProcessedPages !== 0) {
      obj.numProcessedPages = Math.round(message.numProcessedPages);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SmartActionResult>, I>>(base?: I): SmartActionResult {
    return SmartActionResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SmartActionResult>, I>>(object: I): SmartActionResult {
    const message = createBaseSmartActionResult();
    message.extractFieldsResult = (object.extractFieldsResult !== undefined && object.extractFieldsResult !== null)
      ? ExtractFieldsResult.fromPartial(object.extractFieldsResult)
      : undefined;
    message.validateFieldValuesResult =
      (object.validateFieldValuesResult !== undefined && object.validateFieldValuesResult !== null)
        ? ValidateFieldValuesResult.fromPartial(object.validateFieldValuesResult)
        : undefined;
    message.flagKeywordsResult = (object.flagKeywordsResult !== undefined && object.flagKeywordsResult !== null)
      ? FlagKeywordsResult.fromPartial(object.flagKeywordsResult)
      : undefined;
    message.detectDuplicateLineItemsResult =
      (object.detectDuplicateLineItemsResult !== undefined && object.detectDuplicateLineItemsResult !== null)
        ? DetectDuplicateLineItemsResult.fromPartial(object.detectDuplicateLineItemsResult)
        : undefined;
    message.smartActionError = object.smartActionError ?? undefined;
    message.reconcileLineItemsResult =
      (object.reconcileLineItemsResult !== undefined && object.reconcileLineItemsResult !== null)
        ? ReconcileItemsResult.fromPartial(object.reconcileLineItemsResult)
        : undefined;
    message.generateTextResult = (object.generateTextResult !== undefined && object.generateTextResult !== null)
      ? GenerateTextResult.fromPartial(object.generateTextResult)
      : undefined;
    message.classifyResult = (object.classifyResult !== undefined && object.classifyResult !== null)
      ? ClassifyResult.fromPartial(object.classifyResult)
      : undefined;
    message.extractFieldsResultUnified =
      (object.extractFieldsResultUnified !== undefined && object.extractFieldsResultUnified !== null)
        ? ExtractFieldsResultUnified.fromPartial(object.extractFieldsResultUnified)
        : undefined;
    message.reconcileLineItemsResultUnified =
      (object.reconcileLineItemsResultUnified !== undefined && object.reconcileLineItemsResultUnified !== null)
        ? ReconcileItemsResultUnified.fromPartial(object.reconcileLineItemsResultUnified)
        : undefined;
    message.compareResult = (object.compareResult !== undefined && object.compareResult !== null)
      ? CompareResult.fromPartial(object.compareResult)
      : undefined;
    message.generateFunctionResult =
      (object.generateFunctionResult !== undefined && object.generateFunctionResult !== null)
        ? GenerateFunctionResult.fromPartial(object.generateFunctionResult)
        : undefined;
    message.generateExtractionScriptResult =
      (object.generateExtractionScriptResult !== undefined && object.generateExtractionScriptResult !== null)
        ? GenerateExtractionScriptResult.fromPartial(object.generateExtractionScriptResult)
        : undefined;
    message.extractVariablesResult =
      (object.extractVariablesResult !== undefined && object.extractVariablesResult !== null)
        ? ExtractVariablesResult.fromPartial(object.extractVariablesResult)
        : undefined;
    message.extractStructuredDataResult =
      (object.extractStructuredDataResult !== undefined && object.extractStructuredDataResult !== null)
        ? ExtractStructuredDataResult.fromPartial(object.extractStructuredDataResult)
        : undefined;
    message.numProcessedPages = object.numProcessedPages ?? 0;
    return message;
  },
};

messageTypeRegistry.set(SmartActionResult.$type, SmartActionResult);

function createBaseSmartActionExample(): SmartActionExample {
  return { $type: "pb.v1alpha1.SmartActionExample", action: undefined, result: undefined, id: 0 };
}

export const SmartActionExample = {
  $type: "pb.v1alpha1.SmartActionExample" as const,

  encode(message: SmartActionExample, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.action !== undefined) {
      SmartAction.encode(message.action, writer.uint32(10).fork()).ldelim();
    }
    if (message.result !== undefined) {
      SmartActionResult.encode(message.result, writer.uint32(18).fork()).ldelim();
    }
    if (message.id !== undefined && message.id !== 0) {
      writer.uint32(24).int32(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SmartActionExample {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSmartActionExample();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.action = SmartAction.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.result = SmartActionResult.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.id = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SmartActionExample {
    return {
      $type: SmartActionExample.$type,
      action: isSet(object.action) ? SmartAction.fromJSON(object.action) : undefined,
      result: isSet(object.result) ? SmartActionResult.fromJSON(object.result) : undefined,
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
    };
  },

  toJSON(message: SmartActionExample): unknown {
    const obj: any = {};
    if (message.action !== undefined) {
      obj.action = SmartAction.toJSON(message.action);
    }
    if (message.result !== undefined) {
      obj.result = SmartActionResult.toJSON(message.result);
    }
    if (message.id !== undefined && message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SmartActionExample>, I>>(base?: I): SmartActionExample {
    return SmartActionExample.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SmartActionExample>, I>>(object: I): SmartActionExample {
    const message = createBaseSmartActionExample();
    message.action = (object.action !== undefined && object.action !== null)
      ? SmartAction.fromPartial(object.action)
      : undefined;
    message.result = (object.result !== undefined && object.result !== null)
      ? SmartActionResult.fromPartial(object.result)
      : undefined;
    message.id = object.id ?? 0;
    return message;
  },
};

messageTypeRegistry.set(SmartActionExample.$type, SmartActionExample);

function createBaseSmartActionExamples(): SmartActionExamples {
  return { $type: "pb.v1alpha1.SmartActionExamples", name: "", examples: [] };
}

export const SmartActionExamples = {
  $type: "pb.v1alpha1.SmartActionExamples" as const,

  encode(message: SmartActionExamples, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== undefined && message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.examples !== undefined && message.examples.length !== 0) {
      for (const v of message.examples) {
        SmartActionExample.encode(v!, writer.uint32(18).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SmartActionExamples {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSmartActionExamples();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.examples!.push(SmartActionExample.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SmartActionExamples {
    return {
      $type: SmartActionExamples.$type,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      examples: globalThis.Array.isArray(object?.examples)
        ? object.examples.map((e: any) => SmartActionExample.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SmartActionExamples): unknown {
    const obj: any = {};
    if (message.name !== undefined && message.name !== "") {
      obj.name = message.name;
    }
    if (message.examples?.length) {
      obj.examples = message.examples.map((e) => SmartActionExample.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SmartActionExamples>, I>>(base?: I): SmartActionExamples {
    return SmartActionExamples.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SmartActionExamples>, I>>(object: I): SmartActionExamples {
    const message = createBaseSmartActionExamples();
    message.name = object.name ?? "";
    message.examples = object.examples?.map((e) => SmartActionExample.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(SmartActionExamples.$type, SmartActionExamples);

function createBaseSmartActionHITLResult(): SmartActionHITLResult {
  return {
    $type: "pb.v1alpha1.SmartActionHITLResult",
    smartActionResult: undefined,
    correctedSmartActionResult: undefined,
    isFallout: false,
    falloutReason: "",
  };
}

export const SmartActionHITLResult = {
  $type: "pb.v1alpha1.SmartActionHITLResult" as const,

  encode(message: SmartActionHITLResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.smartActionResult !== undefined) {
      SmartActionResult.encode(message.smartActionResult, writer.uint32(10).fork()).ldelim();
    }
    if (message.correctedSmartActionResult !== undefined) {
      SmartActionResult.encode(message.correctedSmartActionResult, writer.uint32(18).fork()).ldelim();
    }
    if (message.isFallout !== undefined && message.isFallout !== false) {
      writer.uint32(24).bool(message.isFallout);
    }
    if (message.falloutReason !== undefined && message.falloutReason !== "") {
      writer.uint32(34).string(message.falloutReason);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SmartActionHITLResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSmartActionHITLResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.smartActionResult = SmartActionResult.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.correctedSmartActionResult = SmartActionResult.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isFallout = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.falloutReason = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SmartActionHITLResult {
    return {
      $type: SmartActionHITLResult.$type,
      smartActionResult: isSet(object.smartActionResult)
        ? SmartActionResult.fromJSON(object.smartActionResult)
        : undefined,
      correctedSmartActionResult: isSet(object.correctedSmartActionResult)
        ? SmartActionResult.fromJSON(object.correctedSmartActionResult)
        : undefined,
      isFallout: isSet(object.isFallout) ? globalThis.Boolean(object.isFallout) : false,
      falloutReason: isSet(object.falloutReason) ? globalThis.String(object.falloutReason) : "",
    };
  },

  toJSON(message: SmartActionHITLResult): unknown {
    const obj: any = {};
    if (message.smartActionResult !== undefined) {
      obj.smartActionResult = SmartActionResult.toJSON(message.smartActionResult);
    }
    if (message.correctedSmartActionResult !== undefined) {
      obj.correctedSmartActionResult = SmartActionResult.toJSON(message.correctedSmartActionResult);
    }
    if (message.isFallout !== undefined && message.isFallout !== false) {
      obj.isFallout = message.isFallout;
    }
    if (message.falloutReason !== undefined && message.falloutReason !== "") {
      obj.falloutReason = message.falloutReason;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SmartActionHITLResult>, I>>(base?: I): SmartActionHITLResult {
    return SmartActionHITLResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SmartActionHITLResult>, I>>(object: I): SmartActionHITLResult {
    const message = createBaseSmartActionHITLResult();
    message.smartActionResult = (object.smartActionResult !== undefined && object.smartActionResult !== null)
      ? SmartActionResult.fromPartial(object.smartActionResult)
      : undefined;
    message.correctedSmartActionResult =
      (object.correctedSmartActionResult !== undefined && object.correctedSmartActionResult !== null)
        ? SmartActionResult.fromPartial(object.correctedSmartActionResult)
        : undefined;
    message.isFallout = object.isFallout ?? false;
    message.falloutReason = object.falloutReason ?? "";
    return message;
  },
};

messageTypeRegistry.set(SmartActionHITLResult.$type, SmartActionHITLResult);

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P> | "$type">]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
