import { createSelector } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { UserDefinedProcess } from 'protos/pb/v1alpha2/process_discovery_service';
import { processDiscoverySelector } from './app.selectors';

/**
 * Base selector for active draft process
 */
export const activeDraftProcessSelector = createSelector(
  [processDiscoverySelector],
  (processDiscovery) => processDiscovery.activeDraftProcess,
);

/**
 * Base selector for saved draft process
 */
export const savedDraftProcessSelector = createSelector(
  [processDiscoverySelector],
  (processDiscovery) => processDiscovery.savedDraftProcess,
);

/**
 * Selector to check if there are any unsaved changes
 * Uses lodash isEqual for reliable deep comparison
 */
export const hasUnsavedChangesSelector = createSelector(
  [activeDraftProcessSelector, savedDraftProcessSelector],
  (activeDraft, savedDraft) => {
    if (!activeDraft) return false;
    if (!savedDraft) return true;

    return !isEqual(activeDraft, savedDraft);
  },
);

/**
 * Selector to get specific field from active draft process
 * @param field - The field to select from the draft process
 */
export const createActiveDraftFieldSelector = <
  T extends keyof UserDefinedProcess,
>(
  field: T,
) =>
  createSelector(
    [activeDraftProcessSelector],
    (draftProcess) => draftProcess?.[field] ?? null,
  );
