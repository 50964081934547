import { Action } from 'protos/pb/v1alpha1/orbot_action';
import { UiState } from 'protos/pb/v1alpha1/orbot_assets';
import {
  CreateProcessRequest,
  CreateProcessResponse,
  GenerateDocumentationRequest,
  GenerateDocumentationResponse,
  GetProcessDefinitionRequest,
  GetProcessDefinitionResponse,
  GetProcessRequest,
  GetProcessResponse,
  GetTraceRequest,
  GetTraceResponse,
  GetTraceUsersRequest,
  GetTraceUsersResponse,
  ListProcessesRequest,
  ListProcessesResponse,
  ListTracesRequest,
  ListTracesResponse,
  ProcessDiscoveryClientImpl,
  UpdateProcessRequest,
  UpdateProcessResponse,
  UpdateTraceRequest,
  UpdateTraceResponse,
} from 'protos/pb/v1alpha2/process_discovery_service';
import { getMetaData, rpcWithErrorHandling } from '../utils/RpcUtills';
import { storageService } from './StorageService';

const getFakeTrace = () => {
  return GetTraceResponse.create({
    actions: [
      Action.create({
        id: 'action_1',
        description: 'Action 1Action 1',
        beforeState: UiState.create({
          viewportScreenshot: {
            id: '1',
            url: 'https://fakeimg.pl/1200x800',
          },
        }),
      }),
      Action.create({
        id: 'action_2',
        description: 'Action 2',
        beforeState: UiState.create({
          viewportScreenshot: {
            id: '2',
            url: 'https://fakeimg.pl/600x400',
          },
        }),
      }),
      Action.create({
        id: 'action_3',
        description: 'Action 3',
        beforeState: UiState.create({}),
      }),
    ],
  });
};

export class ProcessDiscoveryService {
  private static _instance = new ProcessDiscoveryService();
  private _client: ProcessDiscoveryClientImpl;

  private constructor() {
    this._client = new ProcessDiscoveryClientImpl(rpcWithErrorHandling);
  }

  static getInstance(): ProcessDiscoveryService {
    if (!this._instance) {
      this._instance = new ProcessDiscoveryService();
    }
    return this._instance;
  }

  listProcesses = async (
    req: ListProcessesRequest,
  ): Promise<{ response?: ListProcessesResponse; error?: Error }> => {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response = await this._client.ListProcesses(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  };

  getProcess = async (
    req: GetProcessRequest,
  ): Promise<{ response?: GetProcessResponse; error?: Error }> => {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response = await this._client.GetProcess(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  };

  listTraces = async (
    req: ListTracesRequest,
  ): Promise<{ response?: ListTracesResponse; error?: Error }> => {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response = await this._client.ListTraces(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  };

  getTrace = async (
    req: GetTraceRequest,
  ): Promise<{ response?: GetTraceResponse; error?: Error }> => {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response = await this._client.GetTrace(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      // TODO: Remove this once API is working
      if (error) {
        return { response: getFakeTrace() };
      }
      return { response: getFakeTrace() };
      //return { error: error as Error };
    }
  };

  getTraceUsers = async (
    req: GetTraceUsersRequest,
  ): Promise<{ response?: GetTraceUsersResponse; error?: Error }> => {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response = await this._client.GetTraceUsers(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  };

  updateTrace = async (
    req: UpdateTraceRequest,
  ): Promise<{ response?: UpdateTraceResponse; error?: Error }> => {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response = await this._client.UpdateTrace(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  };

  createProcess = async (
    req: CreateProcessRequest,
  ): Promise<{ response?: CreateProcessResponse; error?: Error }> => {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response = await this._client.CreateProcess(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  };

  getProcessDefinition = async (
    req: GetProcessDefinitionRequest,
  ): Promise<{ response?: GetProcessDefinitionResponse; error?: Error }> => {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response = await this._client.GetProcessDefinition(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  };

  updateProcess = async (
    req: UpdateProcessRequest,
  ): Promise<{ response?: UpdateProcessResponse; error?: Error }> => {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response = await this._client.UpdateProcess(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  };

  generateDocumentation = async (
    req: GenerateDocumentationRequest,
  ): Promise<{ response?: GenerateDocumentationResponse; error?: Error }> => {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response = await this._client.GenerateDocumentation(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  };
}

export const processDiscoveryService = ProcessDiscoveryService.getInstance();
