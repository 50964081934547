import React, { FC, memo } from 'react';
import { Box, SxProps } from '@mui/material';
import OrbyColorPalette from '../colors/ColorPalette';
import OrbyTypography from '../typography/OrbyTypography';
import { TypographySize } from '../typography/typography-utils';

export interface OrbyTileProps {
  value: string;
  label: string;
  valueColor?: string;
  labelColor?: string;
  valueSize?: TypographySize;
  labelSize?: TypographySize;
  containerSx?: SxProps;
}

const DEFAULT_STYLES = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '12px',
  bgcolor: OrbyColorPalette['purple-25'],
  padding: '32px 24px',
  borderRadius: '16px',
  minWidth: '200px',
  height: '138px',
} as const;

const OrbyTile: FC<OrbyTileProps> = ({
  value,
  label,
  valueColor = OrbyColorPalette['grey-900'],
  labelColor = OrbyColorPalette['grey-900'],
  valueSize = 'display-xs',
  labelSize = 'sm',
  containerSx = {},
}) => {
  return (
    <Box sx={{ ...DEFAULT_STYLES, ...containerSx }}>
      <OrbyTypography weight='bold' size={valueSize} color={valueColor}>
        {value || '-'}
      </OrbyTypography>
      <OrbyTypography
        weight='semibold'
        size={labelSize}
        color={labelColor}
        sx={{ textAlign: 'center' }}
      >
        {label}
      </OrbyTypography>
    </Box>
  );
};

export default memo(OrbyTile);
