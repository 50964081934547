import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrbyHeaderLabel, OrbyTable } from 'orby-ui/src';

import {
  selectedOrgIdSelector,
  selectedOrgInfoSelector,
} from '../../../../redux/selectors/user.selectors';
import { useNavigate } from 'react-router-dom';
import {
  DEFAULT_FIRST_PAGE,
  DEFAULT_ROWS_PER_PAGE,
} from 'orby-ui/src/components/table/table-utils';
import {
  ORBOT_WORKFLOW_ACTION_MENU_ITEM,
  buildRequest,
  getWorkflowActionItem,
  getWorkflowActionMenuCell,
  getWorkflowCreatorCell,
  getWorkflowModeCell,
  getWorkflowNameCell,
  getWorkflowNameColumnWidth,
  getWorkflowRunScheduleCell,
  getWorkflowTimeCell,
  getWorkflowTimeWidth,
  handleWorkflowPageChange,
  handleWorkflowsRowSelectionChange,
} from './workflow-ui-automation-helper';
import {
  showErrorToast,
  showSuccessToast,
} from 'orby-ui/src/components/toast/OrbyToast';
import {
  listOrbotWorkflowErrorSelector,
  orbotWorkflowListSelector,
  orbotWorkflowsLoadedSelector,
  orbotWorkflowsLoadingSelector,
  orbotWorkflowsTotalSizeSelector,
  processOrbotWorkflowErrorSelector,
  updatedOrbotWorkflowSelector,
} from '../../../../redux/selectors/orbot.selectors';
import { Workflow } from 'protos/pb/v1alpha1/orbot_workflow';
import {
  deleteWorkflowAction,
  listWorkflowAction,
  listWorkflowErrorAction,
} from '../../../../redux/actions/orbot.action';
import {
  DateRange,
  DEFAULT_REFRESH_TRUE,
  FEATURE_FLAGS,
} from '../../../../utils/constants';
import { DeleteWorkflowRequest } from 'protos/pb/v1alpha1/orbot_service';
import UiAutomationDeleteModal from './UiAutomationDeleteModal';
import { APP_ROUTES } from '../../../ProtectedRoutes/Routes';
import { getPageNumber } from '../../../Tasks/TaskHelpers';
import useIsFeatureEnabled from '../../../../hooks/useIsFeatureEnabled';
import { setWorkflowSuccess } from '../../../../redux/actions/workflow_details.constants';
import { useGeneratePath } from '../../../../hooks/useGeneratePath';

export interface Props {
  displayNamePrefix: string;
  selectedUsers: Array<string>;
  tableWidth: number;
  selectedApps: string[];
  selectedModes: string[];
  selectedTime: string;
  customRange: DateRange;
}

const WorkflowUiAutomationTab: React.FC<Props> = ({
  displayNamePrefix,
  tableWidth,
  selectedUsers,
  selectedModes,
  selectedApps,
  selectedTime,
  customRange,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const generateOrgPath = useGeneratePath();
  const urlSearchParams = new URLSearchParams(location.search);

  const workflows: Workflow[] = useSelector(orbotWorkflowListSelector);
  const listWorkflowError = useSelector(listOrbotWorkflowErrorSelector);
  const updatedWorkflow = useSelector(updatedOrbotWorkflowSelector);
  const workflowError = useSelector(processOrbotWorkflowErrorSelector);
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const orgId = useSelector(selectedOrgIdSelector);
  const totalSize = useSelector(orbotWorkflowsTotalSizeSelector) ?? 0;
  const loading = useSelector(orbotWorkflowsLoadingSelector);
  const loaded = useSelector(orbotWorkflowsLoadedSelector);

  const isSelfServiceEnabled = useIsFeatureEnabled(
    FEATURE_FLAGS.SELF_SERVE_WORKFLOW,
  );

  const [selectedWorkflow, setSelectedWorkflow] = useState<Workflow | null>(
    null,
  );
  const [updationTriggered, setUpdationTriggered] = useState(false);

  const [page, setPage] = useState(getPageNumber(urlSearchParams));
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);

  const [actionMenuAnchorEl, setActionMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const isBlockWorkflowCreationAndModification = useIsFeatureEnabled(
    FEATURE_FLAGS.BLOCK_WORKFLOW_CREATION_AND_MODIFICATION,
  );

  /**
   * ACTION MENU
   */
  const actionMenuOpen = Boolean(actionMenuAnchorEl);
  const handleActionMenuClick = (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
    workflow: Workflow,
  ) => {
    setSelectedWorkflow(workflow);
    setActionMenuAnchorEl(event.currentTarget);
  };

  /**
   * HANDLE ACTION MENU CLOSE
   */
  const handleActionMenuClose = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    type?: string,
  ) => {
    event.stopPropagation();
    if (type && selectedWorkflow) {
      if (type === ORBOT_WORKFLOW_ACTION_MENU_ITEM.DELETE) {
        setIsDeletionModalOpen(true);
      }
    }
    setActionMenuAnchorEl(null);
  };

  const handleDelete = () => {
    const req = DeleteWorkflowRequest.create({
      workflowId: selectedWorkflow?.id,
      orgId: orgId,
    });
    dispatch(deleteWorkflowAction(req));
    setUpdationTriggered(true);
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setSelectedWorkflow(null);
    setIsDeletionModalOpen(false);
  };

  /**
   * FETCH WORKFLOWS
   * @param pageNumber
   * @param pageSize
   * @param refresh
   */
  const listWorkflows = (pageNumber: number, pageSize: number) => {
    const req = buildRequest({
      navigate,
      displayNamePrefix,
      orgResourceName: selectedOrgInfo!.orgResourceName as string,
      selectedTime,
      customRange,
      selectedUsers,
      selectedModes,
      pageNumber,
    });
    req.pageNumber = pageNumber;
    req.pageSize = pageSize;
    dispatch(listWorkflowAction(req, DEFAULT_REFRESH_TRUE));
  };

  /**
   * REFRESH PAGE
   */
  const refreshPage = () => {
    setPage(DEFAULT_FIRST_PAGE);
    listWorkflows(DEFAULT_FIRST_PAGE, rowsPerPage);
  };

  /**
   * USE EFFECTS
   */
  useEffect(() => {
    listWorkflows(page, rowsPerPage);

    return () => {
      // Clearing the workflow data when unmounted
      dispatch(setWorkflowSuccess());
    };
  }, []);

  useEffect(() => {
    if (loaded) {
      refreshPage();
    }
  }, [
    displayNamePrefix,
    selectedTime,
    customRange,
    selectedUsers,
    selectedModes,
    selectedApps,
  ]);

  useEffect(() => {
    if (listWorkflowError) {
      showErrorToast(listWorkflowError.message);
      dispatch(listWorkflowErrorAction());
    }
  }, [listWorkflowError]);

  useEffect(() => {
    if (updatedWorkflow && updationTriggered) {
      showSuccessToast(
        'This workflow is being deleted and may take a few moments to update in the list. Feel free to focus on other tasks in the meantime.',
        {
          autoClose: 2000,
        },
      );
      refreshPage();
    }
  }, [updatedWorkflow]);

  useEffect(() => {
    if (workflowError && updationTriggered) {
      // revert back the name since its not saved successfully
      showErrorToast(workflowError.message);
    }
  }, [workflowError]);

  return (
    <>
      <OrbyTable
        cursor='pointer'
        tableContainerSx={{
          width: 'auto',
          overflowX: 'auto',
        }}
        onTableRowClick={(data) => {
          const workflow = data as Workflow;
          if (workflow.id) {
            if (isSelfServiceEnabled) {
              navigate(
                generateOrgPath(APP_ROUTES.WORKFLOW_BUILDER, {
                  workflow_id: workflow.id,
                }),
              );
            } else {
              navigate(
                generateOrgPath(APP_ROUTES.WORKFLOW_DEFINITION, {
                  workflow_id: workflow.id,
                }),
              );
            }
          }
        }}
        isDataLoading={loading}
        noDataMessage={
          displayNamePrefix
            ? `No results for "${displayNamePrefix}"`
            : 'There are no workflows available'
        }
        colSx={[
          {
            width: `${getWorkflowNameColumnWidth(tableWidth, 150)}px`,
            maxWidth: `${getWorkflowNameColumnWidth(tableWidth, 150)}px`,
            minWidth: `${getWorkflowNameColumnWidth(tableWidth, 150)}px`,
          },
          {
            width: '350px',
            maxWidth: '350px',
            minWidth: '350px',
          },
          {
            width: '142px',
            maxWidth: '142px',
            minWidth: '142px',
          },
          {
            width: '217px',
            minWidth: '217px',
            maxWidth: '217px',
          },
          {
            width: `${getWorkflowTimeWidth(tableWidth)}px`,
            maxWidth: `${getWorkflowTimeWidth(tableWidth)}px`,
            minWidth: `${getWorkflowTimeWidth(tableWidth)}px`,
          },
          {
            width: '69px',
            maxWidth: '69px',
            minWidth: '69px',
          },
        ]}
        tableId='workflow-ui-automation-table'
        showCheckBox={false}
        isCheckBoxDisabled={true}
        /**
         * PAGINATION
         */
        pagination={{
          rowsPerPage,
          currentPage: page,
          totalRows: totalSize,
          setCurrentPage: (pageNumber: number) =>
            handleWorkflowPageChange({
              pageNumber,
              rowsPerPage,
              setPage,
              listWorkflows,
            }),
          setRowsPerPage: (rows: number) =>
            handleWorkflowsRowSelectionChange({
              rowsNumber: rows,
              setPage,
              setRowsPerPage,
              listWorkflows,
            }),
        }}
        /**
         * TABLE HEADER ROWS
         */
        headerRows={[
          <OrbyHeaderLabel
            key={'workflow-header-workflow-name'}
            label='Workflow Name'
          />,
          <OrbyHeaderLabel
            key={'workflow-header-run-schedule'}
            label='Run Schedule'
          />,
          <OrbyHeaderLabel key={'workflow-header-mode'} label='Mode' />,
          <OrbyHeaderLabel key={'workflow-header-creator'} label='Creator' />,
          <OrbyHeaderLabel
            key={'workflow-header-time-modified'}
            label='Time Modified'
          />,
          <OrbyHeaderLabel key={'workflow-header-actions'} label='' />,
        ]}
        /**
         * TABLE ROWS
         */
        dataRows={workflows.map((workflow) => {
          return {
            title: workflow.displayName!,
            id: workflow.id!,
            row: workflow,
            cells: [
              getWorkflowNameCell(workflow, displayNamePrefix),
              getWorkflowRunScheduleCell(workflow),
              getWorkflowModeCell(workflow),
              getWorkflowCreatorCell(workflow),
              getWorkflowTimeCell(workflow),
              getWorkflowActionMenuCell(
                Workflow.create(workflow),
                handleActionMenuClick,
                isBlockWorkflowCreationAndModification,
              ),
            ],
          };
        })}
      />

      {/* WORKFLOW ACTION MENU */}
      {getWorkflowActionItem(
        actionMenuAnchorEl,
        actionMenuOpen,
        handleActionMenuClose,
        selectedWorkflow,
      )}

      {/* WORKFLOW DELETION MODAL */}
      <UiAutomationDeleteModal
        open={isDeletionModalOpen}
        handleClose={handleCloseModal}
        onSubmit={handleDelete}
      />
    </>
  );
};

export default React.memo(WorkflowUiAutomationTab);
