import { Box, SxProps, Theme } from '@mui/material';
import {
  OrbyColorPalette,
  OrbyMenuItem,
  OrbySelect,
  OrbyTypography,
} from 'orby-ui/src';
import { DateOptionValues, dateOptions } from 'orby-ui/src/utils/constants';
import React, { FC, memo, useMemo, useState } from 'react';
import { DateRange, ELLIPSIS_STYLE, Filter } from '../../utils/constants';
import { getDateRangeForDisplay } from '../../utils/helpers';
import CustomDateRangePicker from './CustomDateRangePicker';

const ALL_TIME = {
  value: 'all-time',
  label: 'All time',
};

interface TimeFilterProps {
  value: string;
  setValue: (x: string) => void;
  width?: string;
  menuWidth?: string;
  customRange: DateRange;
  setCustomRange:
    | React.Dispatch<React.SetStateAction<DateRange>>
    | ((x: DateRange) => void);
  isShowAllTime?: boolean;
  allTimeFilter?: Filter;
  customDateOptions?: Filter[];
  inputPrefix?: string;
  containerSx?: SxProps<Theme>;
}

const TimeFilter: FC<TimeFilterProps> = ({
  value,
  setValue,
  width = '160px',
  menuWidth = '320px',
  customRange,
  setCustomRange,
  isShowAllTime = false,
  allTimeFilter = ALL_TIME,
  customDateOptions = dateOptions,
  inputPrefix,
  containerSx,
}) => {
  const [showRangePicker, setShowRangePicker] = useState(false);

  const handleRangeChange = (range: DateRange) => {
    setCustomRange(range);
    setValue(DateOptionValues.CUSTOM_RANGE);
    setShowRangePicker(false);
  };

  const dateFilterLabel = useMemo(() => {
    const dateOption = customDateOptions.find((o) => o.value === value);
    const isShowRange =
      dateOption?.value === DateOptionValues.CUSTOM_RANGE && !showRangePicker;
    const startDate = customRange.startDate.toString();
    const endDate = customRange.endDate.toString();
    return isShowRange
      ? getDateRangeForDisplay(startDate, endDate)
      : dateOption?.label || '';
  }, [value, customRange]);

  return (
    <Box position={'relative'}>
      <OrbySelect
        containerSx={containerSx}
        renderValue={(selected) => (
          <OrbyTypography
            size='sm'
            color={OrbyColorPalette['grey-700']}
            weight={'medium'}
            sx={{
              ...ELLIPSIS_STYLE,
            }}
          >
            {inputPrefix && `${inputPrefix} `}
            {selected ? dateFilterLabel : allTimeFilter.label}
          </OrbyTypography>
        )}
        width={width}
        onChange={(e: any) => {
          if (e.target.value !== DateOptionValues.CUSTOM_RANGE) {
            if (e.target.value !== allTimeFilter.value) {
              setValue(e.target.value);
            } else {
              setValue('');
            }
          }
        }}
        value={value}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {isShowAllTime && (
          <OrbyMenuItem
            width={menuWidth}
            key={allTimeFilter.value}
            title={allTimeFilter.label}
            value={allTimeFilter.value}
            isSelected={!value}
            onClick={() => setValue(allTimeFilter.value)}
          />
        )}
        {customDateOptions.map((option) => (
          <OrbyMenuItem
            width={menuWidth}
            key={option.value}
            title={option.label}
            value={option.value}
            isSelected={value === option.value}
            onClick={() =>
              setShowRangePicker(
                option.value === DateOptionValues.CUSTOM_RANGE ? true : false,
              )
            }
          />
        ))}
      </OrbySelect>

      {showRangePicker && (
        <CustomDateRangePicker
          customRange={customRange}
          onClose={() => setShowRangePicker(false)}
          onRangeChange={handleRangeChange}
        />
      )}
    </Box>
  );
};

export default memo(TimeFilter);
