export const VALUE = 'value';
export const EMAIL = 'email';
export const ID = 'id';
export const MAX_TRUNCATE_LENGTH = 25;

export const ALL_VALUE = {
  label: 'All users',
  value: 'All tasks',
};

export const UNASSIGNED_VALUE = {
  label: 'Unassigned only',
  value: 'OrbyUnassigned',
};
