import {
  DocumentEntity,
  DocumentEntityNormalizedValue,
  DocumentPageAnchor,
  DocumentPageAnchorPageRef,
  DocumentProvenance,
  DocumentProvenanceOperationType,
  DocumentTextAnchor,
  DocumentTextAnchorTextSegment,
} from 'protos/google/cloud/documentai/v1/document';
import {
  EntityInfo,
  MatchState,
  TextSegmentInfo,
} from '../redux/reducers/review_task.reducer';
import { boxPositionValuesUtilV2 } from './BoxPositionValuesUtilV2';
import {
  IdleSession,
  ReviewSession,
  ReviewType,
  Task,
  TaskSTATUS,
} from 'protos/pb/v1alpha2/tasks_service';
import {
  BoundingPoly,
  NormalizedVertex,
  Vertex,
} from 'protos/google/cloud/documentai/v1/geometry';
import { TEXT_ANCHOR_SEPARATOR, checkIfNotesEntityType } from './entities';
import { boxPositionValuesUtil } from './BoxPositionValuesUtil';
import {
  EntityDataType,
  EntityDetails,
  EntityTypeSchema,
} from 'protos/pb/v1alpha2/workflow_steps_params';
import {
  moneyToText,
  processCurrency,
  processDate,
  processNumber,
  processText,
} from './normalization';
import {
  CONFIDENCE_SCORE_ROW_HEIGHT,
  DEFAULT_CONFIDENCE_SCORE,
  DEFAULT_REVIEW_PAGE_WIDTH,
  DEFAULT_REVIEW_PAGE_ZOOM,
  ENTITY_TYPE_CHOICE_SEPARATOR,
  EntityFilter,
  FINAL_VALUE_ROW_HEIGHT,
  NOT_FOUND_ENTITY_TEXT,
  PADDING_BW_PDF_PAGES,
  PDF_PANEL_WRAPPER_ID,
  RAW_VALUE_ROW_HEIGHT,
  REVIEW_PAGE_MAX_ZOOM,
  REVIEW_PAGE_MIN_ZOOM,
  REVIEW_PAGE_TOP_MARGIN,
  REVIEW_PAGE_ZOOM_IN_VALUE,
  TABLE_MODAL_ID,
  TimeRange,
} from './constants';
import {
  setSelectedEntityIdAction,
  setSelectedParentEntityTypeAction,
  updateSelectedReviewFilterSection,
  updateTaskEntityInfoAction,
} from '../redux/actions/review_task.action';
import store from '../redux/store';
import { getSelectedTaskDocument } from './helpers';
import { WorkflowMode } from 'protos/pb/v1alpha2/workflows_service';
import { resetFloatingModalStyle } from './UnsavedChangesUtils';
import { clamp, isEmpty, round, uniqueId } from 'lodash';
import { sentryService } from '../services/SentryService';
import {
  FieldGroupMatch,
  FieldGroupMatchMatchedFieldGroup,
  FieldGroupMatchUnmatchedFieldGroup,
  ReconcileItemsResult,
  SmartActionResult,
} from 'protos/pb/v1alpha1/actionprocessing';
import { ExecutionStep } from 'protos/pb/v1alpha2/execution_steps';
import { tokenValuesUtil } from './TokenValuesUtil';
import { OrbyColorPalette } from 'orby-ui/src';

/**
 * Parses an entity type string that may contain options separated by ENTITY_TYPE_CHOICE_SEPARATOR
 * @param type - The entity type string to parse (e.g. "invoice_number_-_-option1_-_-option2")
 * @returns Object containing the base entity type and array of options
 */
const parseEntityType = (
  type: string,
): { entityType: string; options: string[] } => {
  if (!type.includes(ENTITY_TYPE_CHOICE_SEPARATOR)) {
    return {
      entityType: type,
      options: [],
    };
  }

  const [entityType, ...options] = type.split(ENTITY_TYPE_CHOICE_SEPARATOR);
  return {
    entityType,
    options,
  };
};

// FUNCTION TO ADD INFO OF ENTITY
export const getEntityInfoForEntity = ({
  entity,
  documentText,
  isNestedEntity,
  parentEntity,
  task,
  entityDetails,
  isInferenceEnabled,
}: {
  entity: DocumentEntity;
  documentText: string;
  isNestedEntity: boolean;
  parentEntity?: DocumentEntity;
  entityDetails: EntityDetails[];
  task: Task;
  isInferenceEnabled: boolean;
}): EntityInfo => {
  // Nested entities with prefix "*" are not treated as Notes entities rather they are normal entities.
  const isNotesEntity = checkIfNotesEntityType(entity.type as string);
  const { entityType, options } = parseEntityType(entity.type ?? '');
  const normalizedEntityType = getNormalizedEntityTypeValue(
    entityType,
    entityDetails,
    parentEntity?.type,
  );
  const textSegments = getTextSegmentInfoForEntity(
    entity,
    documentText,
    isInferenceEnabled,
  );
  const isDocumentModified =
    !!getSelectedTaskDocument(task)?.originalDocumentUris?.length;
  return {
    id: entity.id as string,
    isReviewed:
      entity.provenance?.type ===
        DocumentProvenanceOperationType.EVAL_APPROVED ||
      (task.status === TaskSTATUS.COMPLETED && !task?.tags?.includes('auto')),
    isModified: false,
    textSegments,
    // mentionText is used only if the document is modified,
    // as it indicates that mentionText was set by the frontend.
    // TODO: Eliminate the use of mentionText once we disable
    //       raw value editing, since mentionText is currently
    //       used to store intermediate values directly entered by the user in the table cells.
    entityText: isDocumentModified
      ? entity.mentionText ?? getTextFromTextSegments(textSegments)
      : getTextFromTextSegments(textSegments),
    normalizedValue:
      getNormalizedValueForEntity(entity, normalizedEntityType) ??
      ({} as DocumentEntityNormalizedValue),
    type: entityType,
    options,
    isNestedEntity,
    parentEntityId: parentEntity?.id,
    parentEntityType: parentEntity?.type,
    confidenceScore:
      task.status === TaskSTATUS.COMPLETED ? 1 : entity.confidence,
    isInDoc: isEntityInDocument(textSegments),
    normalizedEntityType: normalizedEntityType,
    normalizedInputValue: getNormalizedInputValue(entity, normalizedEntityType),
    isExtra: isNotesEntity,
    extraEntityText: isNotesEntity ? entity.mentionText : '',
    // Adding this confidence score to check if this entity needs attention of reviewer
    // If the entity's confidence is less than this, then we show it in Need Attention Tab on review page
    needAttentionConfidenceScore:
      task.workflowModeWhenCreated === WorkflowMode.DEFAULT
        ? (task.needAttentionThresholdDefaultMode as number)
        : DEFAULT_CONFIDENCE_SCORE,
  };
};

/**
 *
 * @param entityType - Entity type for which normalization type is to be found
 * @param entityDetails - Array of all the entities with their normalization type info
 * @param parentEntityType - Name of the parent entity (if entity is nested)
 * @returns
 */
export const getNormalizedEntityTypeValue = (
  entityType: string,
  entityDetails: EntityDetails[],
  parentEntityType?: string,
) => {
  let normalizedEntityType = EntityDataType.ENTITY_TYPE_TEXT;
  entityDetails.forEach((entityDetail) => {
    if (!parentEntityType && entityDetail.entityType === entityType) {
      // This means that the entity is not a nested entity
      normalizedEntityType = entityDetail.normalizationType as EntityDataType;
      return;
    } else if (
      entityDetail?.properties?.length &&
      parentEntityType === entityDetail.entityType &&
      entityDetail?.properties?.length > 0
    ) {
      // This means that the entity is a nested entity
      entityDetail.properties.forEach((property) => {
        if (property.entityType === entityType) {
          normalizedEntityType = property.normalizationType as EntityDataType;
          return;
        }
      });
    }
  });
  return normalizedEntityType;
};

export const getDefaultTextSegment = (entityId: string, defaultText = '') => {
  const textSegment: { [id: string]: TextSegmentInfo } = {};
  textSegment[0] = {
    id: `${0}`,
    vertices: [],
    pageCorrespondingVertices: [],
    normalizedVertices: [],
    page: 0,
    modifiedPage: 0,
    text: defaultText,
    textFromTokens: defaultText,
    entityId: entityId as string,
    startIndex: 0,
    endIndex: 0,
  };
  return textSegment;
};

export const getNormalizedInputValue = (
  entity: DocumentEntity,
  normalizedEntityType: EntityDataType,
) => {
  const finalValue = entity?.normalizedValue?.text;
  switch (normalizedEntityType) {
    case EntityDataType.ENTITY_TYPE_MONEY: {
      const processedMoneyValue = processCurrency(finalValue ?? '');
      return processedMoneyValue
        ? moneyToText(
            processedMoneyValue?.moneyValue?.units ?? 0,
            processedMoneyValue?.moneyValue?.nanos ?? 0,
          )
        : entity?.normalizedValue?.text ?? '';
    }
    case EntityDataType.ENTITY_TYPE_FLOAT:
      return (
        entity.normalizedValue?.text ??
        entity.normalizedValue?.floatValue?.toString() ??
        ''
      );
    case EntityDataType.ENTITY_TYPE_INTEGER:
      return (
        entity.normalizedValue?.text ??
        entity.normalizedValue?.integerValue?.toString() ??
        ''
      );
    default:
      return entity.normalizedValue?.text;
  }
};

export const getNormalizedValueForEntity = (
  entity: DocumentEntity,
  normalizedEntityType: EntityDataType,
) => {
  if (normalizedEntityType) {
    // Check if the normalized value is already present
    // If not then create a new normalized value
    if (!entity.normalizedValue) {
      return getBlankNormalizedValue(normalizedEntityType);
    }
    return entity.normalizedValue;
  }
  return undefined;
};

export const getBlankNormalizedValue = (normalizationType?: EntityDataType) => {
  const normalizedValue: DocumentEntityNormalizedValue = { text: '' };
  if (normalizationType === EntityDataType.ENTITY_TYPE_DATE) {
    normalizedValue.dateValue = {};
  } else if (normalizationType === EntityDataType.ENTITY_TYPE_MONEY) {
    normalizedValue.moneyValue = {};
  } else if (normalizationType === EntityDataType.ENTITY_TYPE_INTEGER) {
    normalizedValue.integerValue = 0;
  } else if (normalizationType === EntityDataType.ENTITY_TYPE_FLOAT) {
    normalizedValue.floatValue = 0;
  }
  return normalizedValue;
};

// FUNCTION TO ADD INFO OF TEXT SEGMENTS IN AN ENTITY
export const getTextSegmentInfoForEntity = (
  entity: DocumentEntity,
  documentText: string,
  isInferenceEnabled: boolean,
) => {
  let textSegmentsInfo: { [id: string]: TextSegmentInfo } = {};
  const textSegments = entity?.textAnchor?.textSegments ?? [];
  textSegments.forEach((textSegment, index) => {
    const text = getTextValue(documentText, entity, index);
    const pageRef = entity.pageAnchor?.pageRefs?.[index];
    const page = pageRef?.page && pageRef?.page >= 0 ? pageRef.page : 0;
    textSegmentsInfo[index] = {
      id: `${index}`,
      vertices: pageRef?.boundingPoly?.vertices ?? [],
      pageCorrespondingVertices: pageRef?.boundingPoly?.vertices ?? [],
      normalizedVertices: pageRef?.boundingPoly?.normalizedVertices ?? [],
      page,
      modifiedPage: page,
      text,
      textFromTokens: text,
      entityId: entity.id ?? '',
      startIndex: textSegment.startIndex ?? 0,
      endIndex: textSegment.endIndex ?? 0,
    };
  });
  if (Object.keys(textSegmentsInfo).length === 0) {
    textSegmentsInfo = getDefaultTextSegment(
      entity.id as string,
      getDefaultTextForEntity(entity, isInferenceEnabled),
    );
  }
  return textSegmentsInfo;
};

const getTextValue = (
  documentText: string,
  entity: DocumentEntity,
  index: number,
) => {
  const parsedTextContent = parseTextAnchorContent(
    entity?.textAnchor?.content as string,
  );
  const textFromContent = parsedTextContent?.[index]?.trim();
  const textFromTextSegment = tokenValuesUtil.getSubStringFromTextSegment(
    entity?.textAnchor?.textSegments?.[index] as DocumentTextAnchorTextSegment,
    documentText,
  );
  // If the text from content is not present, then return the text from text segment
  // This is for backward compatibility
  return textFromContent || textFromTextSegment;
};

const parseTextAnchorContent = (textAnchorContent: string) => {
  return textAnchorContent?.split(TEXT_ANCHOR_SEPARATOR);
};

// THIS APPENDS ALL THE TEXT PRESENT IN TEXT SEGMENT MAP OF ANY ENTITY
// The text segments will be appended in the order of text index
export const getTextFromTextSegments = (textSegments: {
  [id: string]: TextSegmentInfo;
}) => {
  const textSegmentArray = Object.values(textSegments || {});
  // Join the sorted text segments
  const text = textSegmentArray
    .map((segment) => segment.text)
    .join(' ')
    .trim();
  return text;
};

// This will return all normal entities along with the first child entity of a parent entity that is used to
// display parent ui in the list. E.g. If there are 4 normal entities and 7 child(nested) entities (4 of parent 1
// and 3 of parent 2), then the list will return 4 normal entities, 1 child entity of parent 1 and 1 child entity
// of parent 2, 6 in total
// TODO: Optimise this
export const getEntitiesList = (
  entityInfoObject: {
    [id: string]: EntityInfo;
  },
  originalEntityInfoObject: {
    [id: string]: EntityInfo;
  },
) => {
  const entitiesList: EntityInfo[] = [];
  // The reason we are using originalEntityInfoObject for looping,
  // is to preserve the original entity display order
  for (const entityId in originalEntityInfoObject) {
    const entity = originalEntityInfoObject[entityId];
    if (entity.isNestedEntity) {
      if (
        !entitiesList.find(
          (e) => e.parentEntityType === entity.parentEntityType,
        )
      ) {
        // While preserving the the original order we want to make sure to always
        // display updated entity which is present in entityInfoObject
        const updatedEntity = Object.values(entityInfoObject).find(
          (e) => e.parentEntityType === entity?.parentEntityType,
        );
        if (updatedEntity) {
          entitiesList.push(updatedEntity);
        }
      }
    } else {
      const updatedEntity = entityInfoObject[entity.id];
      if (updatedEntity) {
        entitiesList.push(updatedEntity);
      }
    }
  }
  return entitiesList;
};

// THIS BASICALLY DO THE SAME THING AS ABOVE. THE ONLY DIFFERENCE IS IT RETURNS IT AS TYPE - DocumentEntity
// THIS RETURNS THE UPDATED VALUES FROM ENTITY INFO MAP FOR EACH ENTITY
export const getDocumentEntitiesFromMap = (
  entityInfoObject: { [id: string]: EntityInfo },
  increaseConfidence?: boolean,
) => {
  const entitiesList: DocumentEntity[] = [];
  for (const entityId in entityInfoObject) {
    if (entityInfoObject[entityId].isNestedEntity) {
      const nestedEntityIndex = entitiesList.findIndex(
        (entity) => entity.id === entityInfoObject[entityId].parentEntityId,
      );
      if (nestedEntityIndex !== -1) {
        const entity = createDocumentEntityFromEntityInfo(
          entityInfoObject[entityId],
          increaseConfidence,
        );
        entitiesList[nestedEntityIndex]?.properties?.push(entity);
      } else {
        const parentEntity = createParentEntityFromChildEntityInfo(
          entityInfoObject[entityId],
        );
        const childEntity = createDocumentEntityFromEntityInfo(
          entityInfoObject[entityId],
          increaseConfidence,
        );
        parentEntity.properties?.push(childEntity);
        entitiesList.push(parentEntity);
      }
    } else {
      const entity = createDocumentEntityFromEntityInfo(
        entityInfoObject[entityId],
        increaseConfidence,
      );
      entitiesList.push(entity);
    }
  }
  return entitiesList;
};

// This function handles choice type entities by concatenating their options with the type.
// For example, if type is "color" and options are ["red", "blue"],
// the result will be "color_-_-red_-_-blue" with ENTITY_TYPE_CHOICE_SEPARATOR as "_-_-"
const createDocumentEntityType = (entityInfo: EntityInfo) => {
  const { type, options, normalizedEntityType } = entityInfo;
  if (
    normalizedEntityType === EntityDataType.ENTITY_TYPE_CHOICE &&
    options?.length
  ) {
    const optionsString = options.join(ENTITY_TYPE_CHOICE_SEPARATOR);
    return `${type}${ENTITY_TYPE_CHOICE_SEPARATOR}${optionsString}`;
  }
  // If the entity is not a choice type entity, then return the type as is
  return type;
};

// THIS RETURNS DocumentEntity FROM EntityInfo MAP
export const createDocumentEntityFromEntityInfo = (
  entityInfo: EntityInfo,
  increaseConfidence?: boolean,
): DocumentEntity => {
  const entity: DocumentEntity = DocumentEntity.create({});
  entity.id = entityInfo.id;
  entity.type = createDocumentEntityType(entityInfo);
  entity.confidence = increaseConfidence ? 1 : entityInfo.confidenceScore;
  entity.provenance = entityInfo.isReviewed
    ? DocumentProvenance.create({
        type: DocumentProvenanceOperationType.EVAL_APPROVED,
      })
    : undefined;
  entity.mentionText = entityInfo.isExtra
    ? entityInfo.extraEntityText
    : entityInfo.entityText;
  entity.normalizedValue = DocumentEntityNormalizedValue.create(
    entityInfo.normalizedValue,
  );
  entity.pageAnchor = getPageAnchor(Object.values(entityInfo.textSegments));
  entity.textAnchor = getTextAnchor(entityInfo);
  return entity;
};

export const createParentEntityFromChildEntityInfo = (
  entityInfo: EntityInfo,
): DocumentEntity => {
  const entity = DocumentEntity.create({});
  entity.id = entityInfo.parentEntityId ?? '';
  entity.type = entityInfo.parentEntityType ?? '';
  entity.properties = [];
  return entity;
};

// THIS RETURNS THE TEXT SEGMENT WHOSE Y COORD IS THE MOST OUT OF ALL TEXT SEGMENTS
export const getBottomTextSegment = (
  textSegments: TextSegmentInfo[],
): TextSegmentInfo | undefined => {
  let textSegmentInfo: TextSegmentInfo | undefined = undefined;
  for (const textSegment of textSegments) {
    if (textSegment && textSegment?.vertices?.length) {
      if (!textSegmentInfo) {
        textSegmentInfo = textSegment;
      }
      const bottomRightVertex =
        boxPositionValuesUtilV2.getBottomRightCornerVertices(
          textSegment.vertices,
        );
      const prevBottomRightVertex =
        boxPositionValuesUtilV2.getBottomRightCornerVertices(
          textSegmentInfo.vertices,
        );
      if (
        (bottomRightVertex.y as number) > (prevBottomRightVertex.y as number)
      ) {
        textSegmentInfo = textSegment;
      }
    } else {
      if (!textSegmentInfo) {
        textSegmentInfo = textSegment;
      }
    }
  }
  return textSegmentInfo;
};

/**
 * This function returns the entity keys based on the selected review filter
 * @param {EntityInfo} entityInfo
 * @param {EntityFilter} selectedReviewFilterSection
 * @returns
 */
const isNextKeyBasedOnReviewFilter = (
  entityInfo: EntityInfo,
  selectedReviewFilterSection: EntityFilter,
) => {
  switch (selectedReviewFilterSection) {
    case EntityFilter.NEED_ATTENTION:
      return isEntityNeedAttention(entityInfo);
    case EntityFilter.REVIEWED:
      return isEntityReviewed(entityInfo);
    case EntityFilter.PREDICTED:
      return isEntityPredicted(entityInfo);
    default:
      return true;
  }
};

/**
 * This function check if the entity is the last key in the entities list based on the selected review filter
 * @param {{ [id: string]: EntityInfo; }} entitiesInfoMap
 * @param {EntityInfo} entityInfo
 * @param {EntityFilter} selectedReviewFilterSection
 * @returns
 */
const isEntityLastInSelectedFilter = (
  entitiesInfoMap: { [id: string]: EntityInfo },
  entityInfo: EntityInfo,
  selectedReviewFilterSection?: EntityFilter,
) => {
  switch (selectedReviewFilterSection) {
    case EntityFilter.NEED_ATTENTION: {
      const lastNeedAttentionEntityId =
        getLastNeedAttentionEntityId(entitiesInfoMap);
      return lastNeedAttentionEntityId === entityInfo.id;
    }
    case EntityFilter.PREDICTED: {
      const lastPredictedEntityId = getLastPredictedEntityId(entitiesInfoMap);
      return lastPredictedEntityId === entityInfo.id;
    }
    case EntityFilter.REVIEWED: {
      const lastReviewedEntityId = getLastReviewedEntityId(entitiesInfoMap);
      return lastReviewedEntityId === entityInfo.id;
    }
    default: {
      const entities = Object.keys(entitiesInfoMap);
      return entities[entities.length - 1] === entityInfo?.id;
    }
  }
};

/**
 * This function gives the next available entity id
 */
export const findNextEntityKey = (
  selectedEntityId: string,
  entitiesInfoMap: { [id: string]: EntityInfo },
  sortedEntities: EntityInfo[],
  selectedReviewFilterSection?: EntityFilter,
) => {
  // Find the index of the selected entity in the sorted entities array
  const selectedEntityIndex = sortedEntities.findIndex(
    (entity) => entity.id === selectedEntityId,
  );
  /**
   * If this key is the last key in the selected review filter
   * The below block is used to handle the transitions between the review filter sections
   * REF - https://orby-ai.atlassian.net/browse/OA-2132
   */
  const isEntityTheLastKeyInSelectedFilter = isEntityLastInSelectedFilter(
    entitiesInfoMap,
    entitiesInfoMap[selectedEntityId],
    selectedReviewFilterSection,
  );
  // If the selected entity is the last key in the entities list based on the selected filter
  if (isEntityTheLastKeyInSelectedFilter) {
    switch (selectedReviewFilterSection) {
      case EntityFilter.NEED_ATTENTION: {
        // Check if there are more than one entities that need attention
        // The reason for using 1 is because the selected entity could be the last key in the entities list
        if (getNeedAttentionEntitiesCount(entitiesInfoMap) > 1) {
          // If yes, set the review filter section to 'NEED_ATTENTION'
          store.dispatch(
            updateSelectedReviewFilterSection(EntityFilter.NEED_ATTENTION),
          );
          return getFirstNeedAttentionEntityId(entitiesInfoMap); // Return the first need attention entity id
        } else if (getPredictedEntitiesCount(entitiesInfoMap) > 0) {
          // If yes, set the review filter section to 'PREDICTED'
          store.dispatch(
            updateSelectedReviewFilterSection(EntityFilter.PREDICTED),
          );
          return getFirstPredictedEntityId(entitiesInfoMap); // Return the ID of the first predicted entity
        } else {
          // If no entities need attention and no predicted entities exist, set the review filter section to 'REVIEWED'
          store.dispatch(
            updateSelectedReviewFilterSection(EntityFilter.REVIEWED),
          );
          return null; // Return null when tab changes to REVIEWED
        }
      }
      case EntityFilter.PREDICTED: {
        if (getNeedAttentionEntitiesCount(entitiesInfoMap) > 0) {
          // If yes, set the review filter section to 'NEED_ATTENTION'
          store.dispatch(
            updateSelectedReviewFilterSection(EntityFilter.NEED_ATTENTION),
          );
          return getFirstNeedAttentionEntityId(entitiesInfoMap); // Return the first need attention entity id
        } else {
          // If no entities need attention, set the review filter section to 'REVIEWED'
          store.dispatch(
            updateSelectedReviewFilterSection(EntityFilter.REVIEWED),
          );
          return null; // Return null when tab changes to REVIEWED
        }
      }
      case EntityFilter.REVIEWED: {
        if (getNeedAttentionEntitiesCount(entitiesInfoMap) > 0) {
          // If yes, set the review filter section to 'NEED_ATTENTION'
          store.dispatch(
            updateSelectedReviewFilterSection(EntityFilter.NEED_ATTENTION),
          );
          return getFirstNeedAttentionEntityId(entitiesInfoMap); // Return the first need attention entity id
        } else if (getPredictedEntitiesCount(entitiesInfoMap) > 0) {
          // If yes, set the review filter section to 'PREDICTED'
          store.dispatch(
            updateSelectedReviewFilterSection(EntityFilter.PREDICTED),
          );
          return getFirstPredictedEntityId(entitiesInfoMap); // Return the ID of the first predicted entity
        }
        return null; // Return null since there are no entities to review
      }
      default:
        return null; // Return null since there are no entities to review
    }
  } else {
    // If the selected entity is not the last key in the entities list based on the selected filter
    // Iterate through the entity keys, starting from the key after the selected entity
    for (let i = 1; i < sortedEntities.length; i++) {
      // Calculate the index in a circular manner to loop back to the start of the array
      const index = (selectedEntityIndex + i) % sortedEntities.length;
      const key = sortedEntities[index].id;
      // Skip the currently selected key
      if (key === selectedEntityId) {
        continue;
      }

      // Check if the current key is the next un-reviewed key based on the selected review filter section
      if (
        isNextKeyBasedOnReviewFilter(
          entitiesInfoMap[key],
          selectedReviewFilterSection as EntityFilter,
        )
      ) {
        return key; // Found the next un reviewed key
      }
    }
    // If no key is found, return null
    return null;
  }
};

// THIS RETURNS DocumentPageAnchor INFO FOR ANY TEXT SEGMENT
export const getPageAnchor = (
  textSegments: TextSegmentInfo[],
): DocumentPageAnchor => {
  const pageAnchor = DocumentPageAnchor.create({});
  if (textSegments.length) {
    for (const textSegment of textSegments) {
      if (textSegment?.pageCorrespondingVertices) {
        // This is to handle the case when there is a not in doc entity so it won't have page corresponding vertices if no modifications are made
        pageAnchor?.pageRefs?.push(
          DocumentPageAnchorPageRef.create({
            page: textSegment.modifiedPage,
            boundingPoly: BoundingPoly.create({
              vertices: textSegment.pageCorrespondingVertices.map((v) => {
                return {
                  x: Math.round(v.x as number),
                  y: Math.round(v.y as number),
                };
              }),
              normalizedVertices: textSegment.normalizedVertices,
            }),
          }),
        );
      }
    }
  }
  return pageAnchor;
};

// THIS RETURNS DocumentTextAnchor INFO FOR ANY TEXT SEGMENT
export const getTextAnchor = (entityInfo: EntityInfo): DocumentTextAnchor => {
  const textAnchor = DocumentTextAnchor.create({
    content: entityInfo.isExtra
      ? entityInfo.extraEntityText
      : Object.values(entityInfo.textSegments)
          .map((t) => t.text.trim())
          .join(TEXT_ANCHOR_SEPARATOR),
  });

  const textSegments: TextSegmentInfo[] = Object.values(
    entityInfo.textSegments,
  );
  if (textSegments.length) {
    for (const textSegment of textSegments) {
      textAnchor?.textSegments?.push(
        DocumentTextAnchorTextSegment.create({
          startIndex: textSegment.startIndex,
          endIndex: textSegment.endIndex,
        }),
      );
    }
  } else {
    textAnchor?.textSegments?.push(DocumentTextAnchorTextSegment.create({}));
  }
  return textAnchor;
};

/**
 * The purpose of this function is to normalize the coordinates of these vertices relative to the
 * dimensions of the PDF box, and it returns an array of NormalizedVertex objects.
 */
export const getNormalizedVertices = (
  pdfBoxHeight: number,
  pdfBoxWidth: number,
  vertices: Vertex[],
): NormalizedVertex[] => {
  const { top, left, width, height } =
    boxPositionValuesUtil.getBoxPositionValues(
      pdfBoxWidth,
      pdfBoxHeight,
      vertices,
    );
  const right = left + width;
  const bottom = top + height;

  const normalisedVertex: NormalizedVertex[] = [
    { x: left / 100, y: top / 100 },
    { x: right / 100, y: top / 100 },
    { x: right / 100, y: bottom / 100 },
    { x: left / 100, y: bottom / 100 },
  ];
  return normalisedVertex;
};

export const isElementInViewport = (element: HTMLElement): boolean => {
  const rect = element.getBoundingClientRect();
  const documentView = document.getElementById(PDF_PANEL_WRAPPER_ID);
  if (!documentView) return false;

  const documentRect = documentView.getBoundingClientRect();
  const table = document.getElementById(TABLE_MODAL_ID);

  const inDocumentView =
    rect.top >= documentRect.top &&
    rect.left >= documentRect.left &&
    rect.bottom <= documentView.clientHeight &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth);

  if (!table) {
    return inDocumentView;
  }

  const tableRect = table.getBoundingClientRect();

  // Check if the element is obscured by the table
  const isObscuredByTable =
    rect.bottom > tableRect.top &&
    rect.top < tableRect.bottom &&
    rect.right > tableRect.left &&
    rect.left < tableRect.right;

  return inDocumentView && !isObscuredByTable;
};

export const goToVisibleElement = (id: string): void => {
  const element = document.getElementById(id);
  // do not scroll if element is not found or if element is already in viewport
  if (!element || isElementInViewport(element)) return;

  const documentView = document.getElementById(PDF_PANEL_WRAPPER_ID);
  if (!documentView) return;

  const scrollDocument = (top: number, left: number) => {
    // WARN: DO NOT USE scrollIntoView() as it
    // is not working as expected as cause screen layout shifts
    documentView.scrollTo({
      top,
      left,
      behavior: 'smooth',
    });
  };

  const table = document.getElementById(TABLE_MODAL_ID);
  const elementRect = element.getBoundingClientRect();
  const documentRect = documentView?.getBoundingClientRect() as DOMRect;
  const tableRect = table?.getBoundingClientRect() as DOMRect;
  const scrollTopMargin = 20;
  const scrollBottomMargin = 40;

  let targetY: number =
    documentView.scrollTop +
    elementRect.top -
    documentRect.top -
    scrollTopMargin;

  const targetX: number =
    documentView.scrollLeft +
    elementRect.left -
    documentRect.left -
    scrollTopMargin;

  if (!table) {
    scrollDocument(targetY, targetX);
    return;
  }

  const spaceAvailableOnBottom = documentRect.bottom - tableRect.bottom;

  if (spaceAvailableOnBottom >= 40) {
    // Scroll to bottom with margin
    targetY =
      documentView.scrollTop +
      elementRect.bottom -
      documentRect.bottom +
      scrollBottomMargin;
  }

  scrollDocument(targetY, targetX);
};

/**
 * This function is used to identify if the normalized value should be shown or not
 * @param entityType
 */
export const isShowNormalizedValue = (entityType: EntityDataType) => {
  return [
    EntityDataType.ENTITY_TYPE_DATE,
    EntityDataType.ENTITY_TYPE_FLOAT,
    EntityDataType.ENTITY_TYPE_INTEGER,
    EntityDataType.ENTITY_TYPE_MONEY,
    EntityDataType.ENTITY_TYPE_TEXT,
    EntityDataType.ENTITY_TYPE_CHOICE,
  ].includes(entityType);
};

/**
 * Confirms the review of an entity, updates its state, and shifts to the next entity if applicable.
 *
 * @param {EntityInfo} entity - The entity being reviewed.
 * @param {{ [id: string]: EntityInfo }} entitiesInfoMap - Map of entity IDs to their information.
 * @param {EntityFilter} selectedReviewFilterSection - The selected review filter section.
 * @param {boolean} [selectNextEntityIfAvailable=true] - Flag to determine if the next entity should be selected if available.
 */
export const confirmReview = (
  entity: EntityInfo,
  entitiesInfoMap: { [id: string]: EntityInfo },
  selectedReviewFilterSection: EntityFilter,
  sortedEntities: EntityInfo[],
  selectNextEntityIfAvailable: boolean = true,
) => {
  if (!entity.error) {
    resetFloatingModalStyle(); // this will remove warning state from modal
    let textSegments = { ...entity.textSegments };
    const textSegmentsList = Object.values(textSegments);
    for (const segment of textSegmentsList) {
      if (isTextSegmentEmpty(segment)) {
        delete textSegments[segment.id];
      }
    }
    if (Object.values(textSegments).length === 0) {
      textSegments = getDefaultTextSegment(entity.id);
    }
    const newEntityInfo = { ...entity, textSegments };
    newEntityInfo.isNormalizationFailed = false;
    newEntityInfo.isReviewed = true;
    newEntityInfo.isInDoc = !(
      textSegmentsList.length === 0 || checkIfNotesEntityType(entity.type)
    );
    newEntityInfo.confidenceScore = 1;
    store.dispatch(updateTaskEntityInfoAction(entity.id, newEntityInfo));
    shiftToNextEntity(
      entity,
      entitiesInfoMap,
      selectedReviewFilterSection,
      sortedEntities,
      selectNextEntityIfAvailable,
    );
  }
};

/**
 * Closes the floating modal
 *
 */
export const closeFloatingModal = () => {
  resetFloatingModalStyle();
  store.dispatch(setSelectedEntityIdAction(null));
};

export const getLastEntityForParent = (
  entitiesInfoMap: { [id: string]: EntityInfo },
  parentType: string,
) => {
  const currentTableEntities = Object.values(entitiesInfoMap).filter(
    (e) => e.parentEntityType === parentType,
  );
  return currentTableEntities.pop();
};

export const getFirstEntityForParent = (
  sortedEntities: EntityInfo[],
  parentType: string,
) => {
  return sortedEntities.find((e) => e.parentEntityType === parentType);
};

/**
 * Shifts the selection to the next entity based on the current entity and specified conditions.
 *
 * @param {EntityInfo} entity - The current entity information.
 * @param {{ [id: string]: EntityInfo }} entitiesInfoMap - Map of entity IDs to their information.
 * @param {EntityFilter} selectedReviewFilterSection - The selected review filter section.
 * @param {boolean} [selectNextEntityIfAvailable=true] - Flag to determine if the next entity should be selected if available.
 * @returns {string | undefined} - The key of the next entity, or undefined if not applicable.
 */
export const shiftToNextEntity = (
  entity: EntityInfo,
  entitiesInfoMap: { [id: string]: EntityInfo },
  selectedReviewFilterSection: EntityFilter,
  sortedEntities: EntityInfo[],
  selectNextEntityIfAvailable = true,
) => {
  const nextEntityKey = selectNextEntityIfAvailable
    ? findNextEntityKey(
        entity.id,
        entitiesInfoMap,
        sortedEntities,
        selectedReviewFilterSection,
      )
    : undefined;
  const parentId =
    nextEntityKey && entitiesInfoMap[nextEntityKey]?.parentEntityId;
  const parentType =
    nextEntityKey && entitiesInfoMap[nextEntityKey]?.parentEntityType;

  // if the next entity happens to be a child entity then
  // select the parent entity to which that child belongs (opens table modal)
  if (parentId && parentType) {
    store.dispatch(
      setSelectedParentEntityTypeAction({ id: parentId, type: parentType }),
    );
    store.dispatch(setSelectedEntityIdAction(null));
  } else {
    store.dispatch(setSelectedEntityIdAction(nextEntityKey));
  }
  // We do not use the return value of this function in actual FE code (We can use if need be, but not currently)
  // we are only retuning the value to facilitate proper testing of this function
  return nextEntityKey;
};

/**
 * Function to process the Date Entity Type
 */
export const processDateEntity = ({
  entityInfo,
  originalNormalizedValue,
  text,
  originalText,
}: {
  entityInfo: EntityInfo;
  originalNormalizedValue?: DocumentEntityNormalizedValue;
  text: string;
  originalText?: string;
}) => {
  // Check if the normalized value is already present
  // If not then create a new normalized value
  if (!entityInfo.normalizedValue) {
    entityInfo.normalizedValue = {};
    entityInfo.normalizedValue.dateValue = {};
  }
  // If the text is same as original text then use the original normalized value
  if (text === originalText && entityInfo.normalizedValue?.dateValue) {
    entityInfo.normalizedValue.text = originalNormalizedValue?.text;
    entityInfo.normalizedValue.dateValue.year =
      originalNormalizedValue?.dateValue?.year;
    entityInfo.normalizedValue.dateValue.month =
      originalNormalizedValue?.dateValue?.month;
    entityInfo.normalizedValue.dateValue.day =
      originalNormalizedValue?.dateValue?.day;
  } else {
    // Else process the date and get the normalized date value
    const normalizedDateValue = processDate(text);
    if (normalizedDateValue && entityInfo.normalizedValue?.dateValue) {
      entityInfo.normalizedValue.text = normalizedDateValue.text;
      entityInfo.normalizedValue.dateValue.year =
        normalizedDateValue.dateValue.year;
      entityInfo.normalizedValue.dateValue.month =
        normalizedDateValue.dateValue.month;
      entityInfo.normalizedValue.dateValue.day =
        normalizedDateValue.dateValue.day;
      entityInfo.error = null;
    } else {
      entityInfo.normalizedValue = {};
      entityInfo.normalizedValue.dateValue = {};
      // upon normalization failure, we need to populate the text with normalized text
      // this ensure normalizedValue.text is always populated
      entityInfo.normalizedValue.text = text ? processText(text) : '';
      entityInfo.isNormalizationFailed = !!text;
    }
  }
  entityInfo.normalizedInputValue = entityInfo.normalizedValue.text;
};

/**
 * Function to process the Money Entity Type
 */
export const processMoneyEntity = ({
  entityInfo,
  originalNormalizedValue,
  text,
  originalText,
}: {
  entityInfo: EntityInfo;
  originalNormalizedValue?: DocumentEntityNormalizedValue;
  text: string;
  originalText?: string;
}) => {
  // Check if the normalized value is already present
  // If not then create a new normalized value
  if (!entityInfo.normalizedValue) {
    entityInfo.normalizedValue = getBlankNormalizedValue(
      EntityDataType.ENTITY_TYPE_MONEY,
    );
  }
  // If the text is same as original text then use the original normalized value
  if (text === originalText && entityInfo.normalizedValue.moneyValue) {
    entityInfo.normalizedValue.text = originalNormalizedValue?.text;
    entityInfo.normalizedValue.moneyValue.units =
      originalNormalizedValue?.moneyValue?.units;
    entityInfo.normalizedValue.moneyValue.nanos =
      originalNormalizedValue?.moneyValue?.nanos;
    entityInfo.normalizedValue.moneyValue.currencyCode =
      originalNormalizedValue?.moneyValue?.currencyCode;
  } else {
    // Else process the money and get the normalized money value
    // Also here we are replacing any . at the end of the text (REF - OA-2043)
    const normalizedMoneyValue = processCurrency(
      text.replace(/\.*$/, '').trim(),
    );
    if (normalizedMoneyValue && entityInfo.normalizedValue.moneyValue) {
      entityInfo.normalizedValue.text = normalizedMoneyValue.text;
      entityInfo.normalizedValue.moneyValue.units =
        normalizedMoneyValue.moneyValue.units;
      entityInfo.normalizedValue.moneyValue.nanos =
        normalizedMoneyValue.moneyValue.nanos;
      entityInfo.normalizedValue.moneyValue.currencyCode =
        normalizedMoneyValue.moneyValue.currencyCode;
      entityInfo.error = null;
    } else {
      entityInfo.normalizedValue = getBlankNormalizedValue(
        EntityDataType.ENTITY_TYPE_MONEY,
      );
      // upon normalization failure, we need to populate the text with normalized text
      // this ensure normalizedValue.text is always populated
      entityInfo.normalizedValue.text = text ? processText(text) : '';
      entityInfo.isNormalizationFailed = !!text;
    }
  }
  entityInfo.normalizedInputValue = entityInfo.isNormalizationFailed
    ? entityInfo.normalizedValue.text
    : moneyToText(
        entityInfo.normalizedValue.moneyValue?.units ?? 0,
        entityInfo.normalizedValue.moneyValue?.nanos ?? 0,
      );
};

/**
 * Function to process the Integer Entity Type
 */
export const processIntegerEntity = ({
  entityInfo,
  originalNormalizedValue,
  text,
  originalText,
}: {
  entityInfo: EntityInfo;
  originalNormalizedValue?: DocumentEntityNormalizedValue;
  text: string;
  originalText?: string;
}) => {
  // Check if the normalized value is already present
  // If not then create a new normalized value
  if (!entityInfo.normalizedValue) {
    entityInfo.normalizedValue = {
      integerValue: 0,
    };
  }
  // If the text is same as original text then use the original normalized value
  if (text === originalText) {
    entityInfo.normalizedValue.integerValue =
      originalNormalizedValue?.integerValue;
    entityInfo.normalizedValue.text =
      originalNormalizedValue?.integerValue?.toString();
  } else {
    // Else process the number and get the normalized integer value
    const normalizedIntegerValue = processNumber(text) as {
      integerValue: number;
    };
    if (normalizedIntegerValue && normalizedIntegerValue.integerValue) {
      entityInfo.normalizedValue.integerValue =
        normalizedIntegerValue?.integerValue;
      entityInfo.normalizedValue.text =
        normalizedIntegerValue?.integerValue?.toString();
      entityInfo.error = null;
    } else {
      entityInfo.normalizedValue = {
        integerValue: 0,
      };
      // upon normalization failure, we need to populate the text with normalized text
      // this ensure normalizedValue.text is always populated
      entityInfo.normalizedValue.text = text ? processText(text) : '';
      entityInfo.isNormalizationFailed = !!text;
    }
  }
  entityInfo.normalizedInputValue = entityInfo.normalizedValue.text;
};

/**
 * Function to process the Float Entity Type
 */
export const processFloatEntity = ({
  entityInfo,
  originalNormalizedValue,
  text,
  originalText,
}: {
  entityInfo: EntityInfo;
  originalNormalizedValue?: DocumentEntityNormalizedValue;
  text: string;
  originalText?: string;
}) => {
  // Check if the normalized value is already present
  // If not then create a new normalized value
  if (!entityInfo.normalizedValue) {
    entityInfo.normalizedValue = {
      floatValue: 0,
    };
  }
  // If the text is same as original text then use the original normalized value
  if (text === originalText) {
    entityInfo.normalizedValue.floatValue = originalNormalizedValue?.floatValue;
    entityInfo.normalizedValue.text =
      originalNormalizedValue?.floatValue?.toString() ?? '';
  } else {
    // Else process the number and get the normalized float value
    const normalizedFloatValue = processNumber(text) as { floatValue: number };
    if (normalizedFloatValue && normalizedFloatValue.floatValue) {
      entityInfo.normalizedValue.floatValue = normalizedFloatValue.floatValue;
      entityInfo.normalizedValue.text =
        normalizedFloatValue.floatValue.toString();
      entityInfo.error = null;
    } else {
      entityInfo.normalizedValue = {
        floatValue: 0,
      };
      // upon normalization failure, we need to populate the text with normalized text
      // this ensure normalizedValue.text is always populated
      entityInfo.normalizedValue.text = text ? processText(text) : '';
      entityInfo.isNormalizationFailed = !!text;
    }
  }
  entityInfo.normalizedInputValue = entityInfo.normalizedValue.text;
};

/**
 * Function to process the Text Entity Type
 */
export const processTextEntity = ({
  entityInfo,
  originalNormalizedValue,
  text,
  originalText,
}: {
  entityInfo: EntityInfo;
  originalNormalizedValue?: DocumentEntityNormalizedValue;
  text: string;
  originalText?: string;
}) => {
  // Check if the normalized value is already present
  // If not then create a new normalized value
  if (!entityInfo.normalizedValue) {
    entityInfo.normalizedValue = {} as DocumentEntityNormalizedValue;
  }
  // If the text is same as original text then use the original normalized value
  if (text === originalText) {
    entityInfo.normalizedValue.text = originalNormalizedValue?.text;
  } else {
    // Else process the number and get the normalized text value
    const normalizedTextValue = processText(text) ?? '';
    entityInfo.normalizedValue.text = normalizedTextValue;
  }
  entityInfo.normalizedInputValue = entityInfo.normalizedValue.text;
};

/**
 * The below function is used to get the next available text segment id
 * when we add a new text segment using the floating modal
 * @param {{ [id: string]: TextSegmentInfo }} textSegments
 * @returns
 */
export const getNewTextSegmentId = () => {
  return `${uniqueId()}`;
};

/**
 * Inserts a copied entity below the selected entity in the left sidebar ordering.
 *
 * @param {{ [x: string]: EntityInfo }} entityInfoMap - The ordering object on the left sidebar.
 * @param {string} selectedEntityId - The identifier of the selected entity.
 * @param {string} entityCopyId - The identifier of the copied entity.
 * @param {EntityInfo} copiedEntity - The copied entity object to insert.
 * @returns {{ [x: string]: EntityInfo }} - A new ordering object with the copied entity inserted below the selected entity.
 */
export const insertEntityBelowSelectedEntity = (
  entityInfoMap: { [x: string]: EntityInfo },
  selectedEntityId: string,
  entityCopyId: string,
  copiedEntity: EntityInfo,
) => {
  const entityInfoObject: { [x: string]: EntityInfo } = {};
  for (const key in entityInfoMap) {
    entityInfoObject[key] = entityInfoMap[key];
    if (key === selectedEntityId) {
      entityInfoObject[entityCopyId] = copiedEntity;
    }
  }
  return entityInfoObject;
};

/**
 * This function returns true if the entity has a high confidence score
 * @param {EntityInfo} entity
 * @returns
 */
export const isHighConfidenceEntity = (entity: EntityInfo) => {
  return (entity.confidenceScore ?? 0) >= entity.needAttentionConfidenceScore;
};

/**
 * This functions returns the entities that needs attention by the reviewer
 * The entities which have not been reviewed and ( are not in the document or have a low confidence score )
 * will come under this category
 * @param {EntityInfo} entity
 * @returns
 */
export const isEntityNeedAttention = (entity: EntityInfo) => {
  return (
    !entity.isReviewed &&
    (!entity.isInDoc || (entity.isInDoc && !isHighConfidenceEntity(entity)))
  );
};

/**
 * This functions returns the entities that have been reviewed by the reviewer
 * @param {EntityInfo} entity
 * @returns
 */
export const isEntityReviewed = (entity: EntityInfo) => {
  return entity.isReviewed;
};

/**
 * This functions returns the entities that have been predicted by the ML
 * The entities which have not been reviewed and are in doc and have confidence score greater than or equal to 85%
 * @param {EntityInfo} entity
 * @returns
 */
export const isEntityPredicted = (entity: EntityInfo) => {
  return !entity.isReviewed && entity.isInDoc && isHighConfidenceEntity(entity);
};

/**
 * Determines if the "Not found" label should be shown for an entity
 * @param {EntityInfo} entity - The entity to check
 * @returns {boolean} true if the entity is not in document, false otherwise
 */
export const isShowNotFoundLabel = (entity: EntityInfo): boolean =>
  !entity.isInDoc;

/**
 * This functions returns the id of the first entity in the need attention entities list
 */
export const getFirstNeedAttentionEntityId = (entityInfo: {
  [id: string]: EntityInfo;
}) => {
  const needAttentionEntities = Object.values(entityInfo).filter(
    (entityDetail) => {
      return isEntityNeedAttention(entityDetail);
    },
  );
  return needAttentionEntities.length ? needAttentionEntities[0].id : null;
};

/**
 * This functions returns the id of the first entity in the predicted entities list
 */
export const getFirstPredictedEntityId = (entityInfo: {
  [id: string]: EntityInfo;
}) => {
  const predictedEntities = Object.values(entityInfo).filter((entityDetail) => {
    return isEntityPredicted(entityDetail);
  });
  return predictedEntities.length ? predictedEntities[0].id : null;
};

/**
 * This functions returns the id of the last entity in the need attention entities list
 */
export const getLastNeedAttentionEntityId = (entityInfo: {
  [id: string]: EntityInfo;
}) => {
  const needAttentionEntities = Object.values(entityInfo).filter(
    (entityDetail) => {
      return isEntityNeedAttention(entityDetail);
    },
  );
  return needAttentionEntities.length
    ? needAttentionEntities[needAttentionEntities.length - 1].id
    : null;
};

/**
 * This functions returns the id of the last entity in the predicted entities list
 */
export const getLastPredictedEntityId = (entityInfo: {
  [id: string]: EntityInfo;
}) => {
  const predictedEntities = Object.values(entityInfo).filter((entityDetail) => {
    return isEntityPredicted(entityDetail);
  });
  return predictedEntities.length
    ? predictedEntities[predictedEntities.length - 1].id
    : null;
};

/**
 * This functions returns the id of the last entity in the predicted entities list
 */
export const getLastReviewedEntityId = (entityInfo: {
  [id: string]: EntityInfo;
}) => {
  const reviewedEntities = Object.values(entityInfo).filter((entityDetail) => {
    return isEntityReviewed(entityDetail);
  });
  return reviewedEntities.length
    ? reviewedEntities[reviewedEntities.length - 1].id
    : null;
};

/**
 * This function returns the count for entities which needs attention
 */
export const getNeedAttentionEntitiesCount = (entityInfo: {
  [id: string]: EntityInfo;
}) => {
  return Object.values(entityInfo).filter((entityDetail) => {
    return isEntityNeedAttention(entityDetail);
  }).length;
};

/**
 * This function returns the count for entities which are predicted
 */
export const getPredictedEntitiesCount = (entityInfo: {
  [id: string]: EntityInfo;
}) => {
  return Object.values(entityInfo).filter((entityDetail) => {
    return isEntityPredicted(entityDetail);
  }).length;
};

/**
 * This function returns the count for entities which are reviewed
 */
export const getReviewedEntitiesCount = (entityInfo: {
  [id: string]: EntityInfo;
}) => {
  return Object.values(entityInfo).filter((entityDetail) => {
    return isEntityReviewed(entityDetail);
  }).length;
};

export const getDefaultPDFPage = (
  scale: number,
  task: Task,
  reviewPageTopBarHeight: number,
) => {
  // Complete component that contains all the pages
  const elementWrapper = document.getElementById(
    PDF_PANEL_WRAPPER_ID,
  ) as HTMLElement;
  // To show exactly in center we need half of screen height
  const halfViewportHeight =
    window.innerHeight / scale / 2 -
    (reviewPageTopBarHeight + REVIEW_PAGE_TOP_MARGIN);
  // How much the user has scrolled PDF
  const elemScrollTop = elementWrapper.scrollTop / scale;
  // Top position of the bounding box
  const boxTop = elemScrollTop + halfViewportHeight;

  const selectedTaskDocument = getSelectedTaskDocument(task)?.documents?.[0];
  const pdfBoxHeight = selectedTaskDocument?.pages?.[0].image?.height;
  // Total padding will be CURRENT PADDING / scale
  const padding = PADDING_BW_PDF_PAGES / scale;
  return Math.floor(boxTop / ((pdfBoxHeight as number) + padding));
};

// Function to check whether any segment text contains empty text
export const isTextSegmentEmpty = (textSegment?: TextSegmentInfo) => {
  if (!textSegment || !textSegment.text || textSegment.text.trim() === '') {
    return true;
  }
  return false;
};

/**
 * TODO: Can we removed once the migration has been done on the backend
 * This function is used to convert entityTypeSchemaMapping to entityDetails
 * This is done to provide a fallback till the migration on the backend is not done
 * So the old tasks can still work with the new changes
 * @param {{ [id: string]: EntityTypeSchema }} entityTypeSchemaMapping
 * @returns
 */
export const getEntityDetailsFromEntityTypeSchemaMapping = ({
  entities,
  entityTypeSchemaMapping,
}: {
  entities: string[];
  entityTypeSchemaMapping: Map<string, EntityTypeSchema>;
}) => {
  // Array to store the resulting entityDetails
  const entityDetails: EntityDetails[] = [];
  // Iterating through each entity in the input array
  entities.forEach((entity) => {
    // Destructuring the result of splitting the entity string into parent and child
    const [parent, child] = entity.split('/');
    // Finding an existing parent entity in the entityDetails array
    const parentEntity = entityDetails.find((e) => e.entityType === parent);
    // Checking if there is no existing parent entity
    if (!parentEntity) {
      // Creating a new entity object for the parent or entity without a parent
      // if parent, setting normalizationType to UNSPECIFIED
      const newEntity: EntityDetails = {
        entityType: parent || entity,
        normalizationType: parent
          ? EntityDataType.ENTITY_TYPE_NESTED
          : entityTypeSchemaMapping?.get(entity)?.normalizationType,
        properties: [],
      };
      // Checking if there is a child entity
      if (child) {
        // Adding a child entity to the properties array of the new entity
        newEntity?.properties?.push({
          entityType: child,
          normalizationType:
            entityTypeSchemaMapping?.get(entity)?.normalizationType,
          properties: [],
        });
      }
      // Adding the new entity to the entityDetails array
      entityDetails.push(newEntity);
    } else if (child) {
      // If there is an existing parent entity, adding the child entity to its properties array
      parentEntity?.properties?.push({
        entityType: child,
        normalizationType:
          entityTypeSchemaMapping?.get(entity)?.normalizationType,
        properties: [],
      });
    }
  });
  // Returning the resulting entityDetails array
  return entityDetails;
};

export const processNormalizedValue = (
  entityInfo: EntityInfo,
  text: string,
  originalNormalizedValue?: DocumentEntityNormalizedValue,
  originalText?: string,
) => {
  const data = {
    entityInfo: entityInfo,
    originalNormalizedValue,
    text,
    originalText,
  };

  switch (entityInfo.normalizedEntityType) {
    case EntityDataType.ENTITY_TYPE_MONEY:
      processMoneyEntity(data);
      break;
    case EntityDataType.ENTITY_TYPE_DATE:
      processDateEntity(data);
      break;
    case EntityDataType.ENTITY_TYPE_INTEGER:
      processIntegerEntity(data);
      break;
    case EntityDataType.ENTITY_TYPE_FLOAT:
      processFloatEntity(data);
      break;
    case EntityDataType.ENTITY_TYPE_TEXT:
      processTextEntity(data);
      break;
  }
};

export const getRowOrderInfoForTableEntities = (
  selectedTableEntitiesInfo: EntityInfo[],
  selectedParentEntityInfoType: string | undefined,
) => {
  const parentIds: Set<string> = new Set();
  selectedTableEntitiesInfo?.forEach((e) => {
    if (
      e.parentEntityType === selectedParentEntityInfoType &&
      !parentIds.has(e.parentEntityId || '')
    ) {
      parentIds.add(e.parentEntityId || '');
    }
  });
  return [...parentIds];
};

export const checkIfMultipleCellsSelected = (
  selectedEntityIdsForAnnotation: string[],
  selectedTableEntitiesInfo: Record<string, EntityInfo>,
) => {
  const isNotesEntitySelected = selectedEntityIdsForAnnotation.some((id) => {
    const entity = selectedTableEntitiesInfo[id];
    return entity && entity.isExtra;
  });
  return selectedEntityIdsForAnnotation.length > 1 && !isNotesEntitySelected;
};

const formatIdleSessionsForBackend = (
  idleSessions: TimeRange[],
): IdleSession[] => {
  const validSessions: IdleSession[] = [];

  idleSessions.forEach(({ start, end }) => {
    // Log an error to Sentry if either the start or end time is missing.
    // This check is expected to rarely trigger, but if it does, it will handle the error
    // gracefully and log it to Sentry for developers to address.
    if (!start || !end) {
      sentryService.error('Start or End Time not found', { idleSessions });
    } else {
      const startTime = new Date(start);
      const duration = end - start; // duration in ms
      validSessions.push({
        startTime: startTime,
        durationMsec: duration,
      });
    }
  });

  return validSessions;
};

export const getReviewDetailsForTask = (
  email: string | undefined,
  idleSessions: TimeRange[],
  reviewStartTime: number | undefined,
  reviewType = ReviewType.NORMAL_REVIEW,
  existingSession: ReviewSession[],
) => {
  // Add the person who reviewed the task
  return {
    user: email,
    reviewType,
    sessions: [
      ...existingSession,
      {
        reviewer: { username: email },
        idleSessions: formatIdleSessionsForBackend(idleSessions),
        startTime: reviewStartTime ? new Date(reviewStartTime) : undefined,
        endTime: new Date(),
      },
    ],
  };
};

/**
 * Constrains a zoom value to be within the allowed minimum and maximum zoom levels.
 *
 * @param zoom - The zoom value to constrain
 * @returns The constrained zoom value rounded to 2 decimal places
 */
export const getConstrainedZoom = (zoom: number) => {
  return round(clamp(zoom, REVIEW_PAGE_MIN_ZOOM, REVIEW_PAGE_MAX_ZOOM), 2);
};

/**
 * Calculates the initial zoom level for the review page based on the page width.
 * @param pageWidth - The width of the page to be displayed (optional)
 * @returns The calculated initial zoom level, rounded to 2 decimal places
 */
export const getReviewPageInitialZoom = (pageWidth?: number): number => {
  if (
    !pageWidth ||
    pageWidth * DEFAULT_REVIEW_PAGE_ZOOM > DEFAULT_REVIEW_PAGE_WIDTH
  ) {
    return DEFAULT_REVIEW_PAGE_ZOOM;
  }
  // Calculate zoom based on ratio of default width to page width
  const calculatedZoom = DEFAULT_REVIEW_PAGE_WIDTH / pageWidth;
  return getConstrainedZoom(calculatedZoom);
};

export const incrementZoom = (zoom: number) =>
  getConstrainedZoom(zoom + REVIEW_PAGE_ZOOM_IN_VALUE);

export const decrementZoom = (zoom: number) =>
  getConstrainedZoom(zoom - REVIEW_PAGE_ZOOM_IN_VALUE);

export const handleMatchedFields = (
  modifiedStates: Record<number, MatchState>,
  index: number,
  newMatch: FieldGroupMatchMatchedFieldGroup,
  originalMatch?: FieldGroupMatchMatchedFieldGroup,
): Record<number, MatchState> => {
  const newModifiedStates = { ...modifiedStates };

  const isDifferent =
    newMatch.sourceIndex !== originalMatch?.sourceIndex ||
    newMatch.targetIndex !== originalMatch?.targetIndex;

  if (isDifferent) {
    newModifiedStates[index] = {
      sourceIndex: originalMatch?.sourceIndex,
      targetIndex: originalMatch?.targetIndex,
    };
  } else {
    delete newModifiedStates[index];
  }

  return newModifiedStates;
};

export const handleUnmatchedTarget = (
  modifiedStates: Record<number, MatchState>,
  index: number,
  newTarget?: FieldGroupMatchUnmatchedFieldGroup,
  originalTarget?: FieldGroupMatchUnmatchedFieldGroup,
): Record<number, MatchState> => {
  const newModifiedStates = { ...modifiedStates };

  const isDifferent = newTarget?.index !== originalTarget?.index;

  if (isDifferent) {
    newModifiedStates[index] = { unmatchedIndex: originalTarget?.index };
  } else {
    delete newModifiedStates[index];
  }

  return newModifiedStates;
};

export const updateExecutionStep = (
  executionStep: ExecutionStep,
  matches: FieldGroupMatch[],
) => {
  if (!executionStep?.result?.smartActionResult) {
    return executionStep;
  }

  const sourceExtractedFields = !isEmpty(
    executionStep.result.smartActionResult.correctedSmartActionResult,
  )
    ? executionStep.result.smartActionResult.correctedSmartActionResult
        ?.reconcileLineItemsResult?.sourceExtractedFields
    : executionStep.result.smartActionResult.smartActionResult
        ?.reconcileLineItemsResult?.sourceExtractedFields;

  const targetExtractedFields = !isEmpty(
    executionStep.result.smartActionResult.correctedSmartActionResult,
  )
    ? executionStep.result.smartActionResult.correctedSmartActionResult
        ?.reconcileLineItemsResult?.targetExtractedFields
    : executionStep.result.smartActionResult.smartActionResult
        ?.reconcileLineItemsResult?.targetExtractedFields;

  return {
    ...executionStep,
    result: {
      ...executionStep.result,
      smartActionResult: {
        ...executionStep.result.smartActionResult,
        correctedSmartActionResult: SmartActionResult.create({
          reconcileLineItemsResult: ReconcileItemsResult.create({
            fieldGroupMatches: matches,
            sourceExtractedFields,
            targetExtractedFields,
          }),
        }),
      },
    },
  };
};

export const isEntityInDocument = (
  textSegments: Record<string, TextSegmentInfo>,
) => Object.values(textSegments).some((segment) => segment.vertices.length > 0);

export const getDefaultTextForEntity = (
  entity: DocumentEntity,
  isInferenceEnabled: boolean,
) => {
  if (
    entity.provenance?.type === DocumentProvenanceOperationType.EVAL_APPROVED
  ) {
    return entity.mentionText;
  }
  return isInferenceEnabled
    ? parseTextAnchorContent(entity.textAnchor?.content ?? '').join(' ')
    : entity.mentionText;
};

/**
 * Calculates the height of a table row based on the visibility of raw values and confidence score.
 *
 * @param showRawValues - Whether raw values are visible
 * @param showConfidenceScore - Whether confidence score is visible
 * @returns The height of the table row
 */
export const getTableRowHeight = (
  showRawValues: boolean,
  showConfidenceScore: boolean,
) => {
  let rowHeight = FINAL_VALUE_ROW_HEIGHT;
  // Add the height of the raw value if it is visible
  if (showRawValues) {
    rowHeight += RAW_VALUE_ROW_HEIGHT;
  }
  // Add the height of the confidence score if it is visible
  if (showConfidenceScore) {
    rowHeight += CONFIDENCE_SCORE_ROW_HEIGHT;
  }
  // Return the estimated row height
  return rowHeight;
};

/**
 * Calculates the background color for an entity based on its status and selection.
 *
 * @param entity - The entity to calculate the background color for
 * @param isSelected - Optional boolean indicating if the entity is selected, used to determine highlight color
 * @param defaultColor - The fallback background color to use if no other conditions are met (defaults to grey-25)
 * @returns The background color for the entity
 */
export const getBackgroundColorForEntity = (
  entity: EntityInfo,
  isSelected?: boolean,
  defaultColor: string = OrbyColorPalette['grey-25'],
) => {
  const { isInDoc, isReviewed } = entity;

  if (isReviewed) {
    return OrbyColorPalette[isSelected ? 'green-100' : 'green-50'];
  }
  if (isEntityNeedAttention(entity)) {
    if (isInDoc) {
      return OrbyColorPalette[isSelected ? 'warning-50' : 'warning-25'];
    }
    return OrbyColorPalette[isSelected ? 'error-100' : 'error-50'];
  }
  return isSelected ? OrbyColorPalette['indigo-50'] : defaultColor;
};

/**
 * Gets the display text for an entity.
 *
 * @param entity - The entity to get text for
 * @param showRawValue - Whether to show the raw value instead of normalized value
 * @returns The text to display for the entity
 */
export const getTextForEntity = (entity: EntityInfo, showRawValue: boolean) => {
  // Early return extraEntityText for notes entity
  if (entity.isExtra) return entity.extraEntityText;
  // Set default text based on showRawValue flag
  const defaultText = showRawValue
    ? entity.entityText
    : entity.normalizedValue?.text;

  // Check if entity is not reviewed and is not in document
  if (!entity.isReviewed && !entity.isInDoc) {
    // Return defaultText if present, otherwise return NOT_FOUND_ENTITY_TEXT
    return defaultText || NOT_FOUND_ENTITY_TEXT;
  }

  return defaultText;
};
