import { TableCell, SxProps, Theme } from '@mui/material';
import React, { FC, memo } from 'react';
import OrbyColorPalette from '../../../colors/ColorPalette';

interface OrbyTableCellProps {
  /**
   * The content of the component
   */
  children?: React.ReactElement | JSX.Element | JSX.Element[];
  colSpan?: number;
  sx?: SxProps<Theme>;
}

const OrbyTableCell: FC<OrbyTableCellProps> = ({
  children,
  sx = {},
  colSpan,
}) => {
  return (
    <TableCell
      sx={{
        borderBottom: `1px solid ${OrbyColorPalette['grey-200']}`,
        padding: '15.75px 16px',
        boxSizing: 'border-box',
        ...sx,
      }}
      colSpan={colSpan}
    >
      {children}
    </TableCell>
  );
};

export default memo(OrbyTableCell);
