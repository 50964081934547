import { HandleProps, Position } from '@xyflow/react';
import { OrbyColorPalette } from 'orby-ui/src';
import { VariableDataType } from 'workflow-utils/src/variable';

export const FLOW_CHART_HANDLES: HandleProps[] = [
  {
    type: 'source',
    position: Position.Bottom,
    style: {
      left: '50%',
      visibility: 'hidden',
    },
  },
  {
    type: 'source',
    position: Position.Left,
    style: {
      top: '50%',
      visibility: 'hidden',
    },
  },
  {
    type: 'source',
    position: Position.Right,
    style: {
      top: '50%',
      visibility: 'hidden',
    },
  },
  {
    type: 'target',
    position: Position.Top,
    style: {
      left: '50%',
      visibility: 'hidden',
    },
  },
];

/** AddActionButton constants */
export const ADD_ACTION_BUTTON_POPUP_WIDTH = 432;
export const ADD_ACTION_BUTTON_POPUP_HEIGHT = 262;

export const ADD_ACTION_BUTTON_POPUP_HEIGHT_WITH_ONLY_RECORD_ACTION = 80;

export const ADD_ACTION_BUTTON_POPUP_GRADIENT_BACKGROUND = `linear-gradient(152deg, ${OrbyColorPalette['blue-700']}, ${OrbyColorPalette['blue-400']})`;

export const ASSUMED_DELETE_BUTTON_WIDTH = 30;

export const START_NODE_ACTUAL_WIDTH = 60;
export const START_NODE_ACTUAL_HEIGHT = 28;

export const IF_CONDITION_BRANCH = {
  LABEL: 'Is true',
  SUFFIX: '-THEN',
};

export const ELSE_CONDITION_BRANCH = {
  LABEL: 'Otherwise',
  SUFFIX: '-ELSE',
};

export interface FieldDetails {
  uniqueId: string;
  name: string;
  type: VariableDataType;
  children: Record<string, FieldDetails>;
}
