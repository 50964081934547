import { Check } from '@mui/icons-material';
import { Box, MenuItem, SxProps, Theme } from '@mui/material';
import React, { FC, memo } from 'react';
import '../../styles/global.css';
import OrbyColorPalette from '../colors/ColorPalette';
import OrbyMenuItemContent from './components/OrbyMenuItemContent';
import { TruncateType } from './types';
export interface OrbyMenuItemProps {
  /**
   * Primary label to be displayed on menu item
   */
  title: string;
  /**
   * Should be unique similar to React.key
   */
  key: string | number;
  /**
   * Actual value of the item
   */
  value: string | number;
  /**
   * Secondary label to be displayed on menu item
   */
  description?: string;
  /**
   * Icon/Image to be displayed as prefix on menu item
   */
  icon?: JSX.Element;
  /**
   * Determines whether to show check icon
   */
  isSelected?: boolean;
  /**
   * disables all interactions with the MenuItem
   * @default false
   */
  disabled?: boolean;
  /**
   * Truncates the title or description of the menu item
   * @default 'TITLE'
   */
  truncate?: TruncateType;
  /**
   * The onClick handler for the checkbox.
   */
  onClick?: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  /**
   * Width of the component
   */
  width: string;
  /**
   * Style Props
   */
  sx?: SxProps<Theme>;
  /**
   * Override the title label
   */
  titleLabelOverride?: string;
  /**
   * Title sx
   */
  titleSx?: SxProps<Theme>;
  /**
   * End icon sx
   */
  endIconSx?: SxProps<Theme>;

  menuItemSx?: SxProps<Theme>;
  descriptionSx?: SxProps<Theme>;
}

const OrbyMenuItem: FC<OrbyMenuItemProps> = ({
  title,
  description,
  icon,
  isSelected,
  width,
  sx,
  truncate = 'TITLE',
  titleLabelOverride = title,
  titleSx,
  endIconSx,
  menuItemSx,
  descriptionSx,
  ...props
}) => {
  return (
    // Note: Props need to be passed directly using the spread operator, and all props should have the same names as defined for MenuItem.
    // This is because MUI's Select component requires MenuItem to be a direct child.
    // Therefore, we cannot wrap MenuItem in a custom component and use it directly.
    // To bypass this requirement, we must pass {...props} directly.
    <MenuItem
      {...props}
      // Custom attribute to store the menu item value
      data-my-value={props.value}
      selected={isSelected}
      sx={{
        width: width,
        '&.Mui-selected': {
          backgroundColor: `${OrbyColorPalette['grey-50']}`,
        },
        '&.Mui-focused': {
          backgroundColor: `${OrbyColorPalette['grey-400']} !important`,
        },
        ...sx,
      }}
    >
      <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
        <OrbyMenuItemContent
          title={title}
          description={description}
          icon={icon}
          isSelected={isSelected}
          truncate={truncate}
          titleLabelOverride={titleLabelOverride}
          titleSx={titleSx}
          menuItemSx={menuItemSx}
          descriptionSx={descriptionSx}
        />
        {!props.disabled && isSelected ? (
          <Check sx={{ color: OrbyColorPalette['purple-700'], ...endIconSx }} />
        ) : (
          <Box width={'24px'} />
        )}
      </Box>
    </MenuItem>
  );
};

export default memo(OrbyMenuItem);
