import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Variable } from 'protos/pb/v1alpha1/variables';
import { getDisplayVariable } from 'workflow-utils/src/variable';
import WorkflowVariableTable from './WorkflowVariableTable';
import WorkflowVariableRecord from './WorkflowVariableRecord';
import OrbyColorPalette from '../colors/ColorPalette';
import OrbyTypography from '../typography/OrbyTypography';
import OrbyBadge from '../badge/OrbyBadge';
import OrbyButton from '../button/OrbyButton';
import { getFontSize } from '../typography/typography-utils';
import CheckIcon from '@mui/icons-material/Check';
import WorkflowVariableValue from './WorkflowVariableValue';

const WorkflowVariable = (props: {
  variable: Variable;
  selectToCopyContext?: {
    copyFn?: (
      variableValue: string,
      referenceValueKey?: string,
    ) => Promise<void>;
    isSelected?: (refKey?: string) => boolean;
    onSelect?: (refKey?: string) => void;
  };
  inSidePanel?: boolean;
}) => {
  const { variable, selectToCopyContext, inSidePanel } = props;
  const [isHovered, setIsHovered] = useState(false);
  const displayVariable = getDisplayVariable(variable);

  if (!displayVariable) {
    return null;
  }

  if (displayVariable?.type === 'Table') {
    return <WorkflowVariableTable variable={variable} />;
  }

  if (displayVariable?.type === 'Record') {
    return (
      <WorkflowVariableRecord
        variable={variable}
        selectToCopyContext={selectToCopyContext}
        inSidePanel={inSidePanel}
      />
    );
  }

  const copyFn = selectToCopyContext?.copyFn;
  const isSelected = selectToCopyContext?.isSelected;
  const onSelect = selectToCopyContext?.onSelect;

  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        padding: '8px 12px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '2px',
        '&:hover': {
          backgroundColor: OrbyColorPalette['grey-100'],
        },
        alignItems: 'center',
      }}
    >
      <Box sx={{ flexDirection: 'row' }}>
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
          }}
        >
          <OrbyTypography weight='medium' color={OrbyColorPalette['grey-900']}>
            {variable.name}
          </OrbyTypography>

          <OrbyBadge
            size='small'
            textColor={OrbyColorPalette['grey-700']}
            backgroundColor={OrbyColorPalette['grey-200']}
            badgeName={displayVariable.type}
          />
        </Box>

        <WorkflowVariableValue value={displayVariable.value} />
      </Box>
      {inSidePanel && isHovered && !isSelected?.() && (
        <OrbyButton
          label='Select'
          variant='monochrome-text'
          onClick={() => {
            copyFn?.(displayVariable.value);
            onSelect?.();
          }}
          sx={{ textTransform: 'none', fontSize: getFontSize('xs') }}
        />
      )}
      {inSidePanel && isSelected?.() && (
        <OrbyButton
          label='Selected'
          endIcon={<CheckIcon />}
          variant='primary-contained'
          onClick={() => onSelect?.()}
          sx={{ textTransform: 'none', fontSize: getFontSize('xs') }}
        />
      )}
    </Box>
  );
};

export default WorkflowVariable;
