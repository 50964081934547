export enum DateOptionValues {
  TODAY = '1d',
  YESTERDAY = '2d',
  LAST_WEEK = '7d',
  LAST_MONTH = '30d',
  CUSTOM_RANGE = 'cr',
}

export const dateOptions = [
  { label: 'Today', value: DateOptionValues.TODAY },
  { label: 'Yesterday', value: DateOptionValues.YESTERDAY },
  { label: 'Last 7 days', value: DateOptionValues.LAST_WEEK },
  { label: 'Last 30 days', value: DateOptionValues.LAST_MONTH },
  { label: 'Custom range', value: DateOptionValues.CUSTOM_RANGE },
];
