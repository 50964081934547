import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { organizationsService } from '../../services/OrganizationsService';
import {
  listApiKeys,
  listApiKeysSuccessAction,
  listApiKeysFailureAction,
  createApiKey,
  createApiKeySuccessAction,
  createApiKeyFailureAction,
  deleteApiKeySuccessAction,
  deleteApiKeyFailureAction,
  updateApiKeySuccessAction,
  updateApiKeyFailureAction,
  updateApiKey,
  deleteApiKey,
  rotateApiKeySuccessAction,
  rotateApiKeyFailureAction,
  rotateApiKey,
} from '../slices/api_keys.slice';
import {
  CreateApiKeyRequest,
  DeleteApiKeyRequest,
  ListApiKeysRequest,
  UpdateApiKeyRequest,
  RotateApiKeyRequest,
} from 'protos/pb/v1alpha1/organization_service';

function* handleListApiKeys(action: PayloadAction<ListApiKeysRequest>) {
  try {
    const { response, error } = yield call(
      organizationsService.listApiKeys,
      action.payload,
    );
    if (response) {
      yield put(
        listApiKeysSuccessAction({
          apiKeys: response.apiKeys,
          totalSize: response.totalSize,
        }),
      );
    } else {
      const errorMessage = error?.message || 'Failed to fetch API keys';
      yield put(listApiKeysFailureAction(errorMessage));
    }
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : 'Failed to fetch API keys';
    yield put(listApiKeysFailureAction(errorMessage));
  }
}

function* handleCreateApiKey(
  action: PayloadAction<
    CreateApiKeyRequest & { onSuccess?: (apiKey: string) => void }
  >,
) {
  try {
    const { response, error } = yield call(
      organizationsService.createApiKey,
      action.payload,
    );
    if (response && response.apiKey) {
      yield put(
        createApiKeySuccessAction({
          apiKeyId: response.apiKey.id,
          encryptedKey: response.apiKey.encryptedKey || '',
        }),
      );
      if (action.payload.onSuccess) {
        yield call(
          action.payload.onSuccess,
          response.apiKey.encryptedKey || '',
        );
      }
    } else {
      const errorMessage = error?.message || 'Failed to create API key';
      yield put(createApiKeyFailureAction(errorMessage));
    }
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : 'Failed to create API key';
    yield put(createApiKeyFailureAction(errorMessage));
  }
}

function* handleDeleteApiKey(action: PayloadAction<DeleteApiKeyRequest>) {
  try {
    const { response, error } = yield call(
      organizationsService.deleteApiKey,
      action.payload,
    );
    if (response) {
      yield put(deleteApiKeySuccessAction());
    } else {
      const errorMessage = error?.message || 'Failed to delete API key';
      yield put(deleteApiKeyFailureAction(errorMessage));
    }
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : 'Failed to delete API key';
    yield put(deleteApiKeyFailureAction(errorMessage));
  }
}

function* handleUpdateApiKey(action: PayloadAction<UpdateApiKeyRequest>) {
  try {
    const { response, error } = yield call(
      organizationsService.updateApiKey,
      action.payload,
    );
    if (response) {
      yield put(updateApiKeySuccessAction());
    } else {
      const errorMessage = error?.message || 'Failed to update API key';
      yield put(updateApiKeyFailureAction(errorMessage));
    }
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : 'Failed to update API key';
    yield put(updateApiKeyFailureAction(errorMessage));
  }
}

function* handleRotateApiKey(action: PayloadAction<RotateApiKeyRequest>) {
  try {
    const { response, error } = yield call(
      organizationsService.rotateApiKey,
      action.payload,
    );
    if (response && response.apiKey) {
      yield put(rotateApiKeySuccessAction(response.apiKey.encryptedKey || ''));
    } else {
      const errorMessage = error?.message || 'Failed to rotate API key';
      yield put(rotateApiKeyFailureAction(errorMessage));
    }
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : 'Failed to rotate API key';
    yield put(rotateApiKeyFailureAction(errorMessage));
  }
}

export function* apiKeysSaga() {
  yield takeLatest(listApiKeys.type, handleListApiKeys);
  yield takeLatest(createApiKey.type, handleCreateApiKey);
  yield takeLatest(deleteApiKey.type, handleDeleteApiKey);
  yield takeLatest(updateApiKey.type, handleUpdateApiKey);
  yield takeLatest(rotateApiKey.type, handleRotateApiKey);
}
