import { TruncateType } from 'orby-ui/src/components/menuItem/types';
import { MAX_TRUNCATE_LENGTH } from './constant';

/**
 * Determines how to truncate user information based on string length
 * @param email - User's email address
 * @param fullName - Optional user's full name
 * @returns 'BOTH' if the evaluated string exceeds max length, otherwise 'DESCRIPTION'
 */
export const getTruncate = (email: string, fullName?: string): TruncateType => {
  const stringToEvaluate = fullName || email;
  if (stringToEvaluate.length > MAX_TRUNCATE_LENGTH) {
    return 'BOTH';
  }
  return 'DESCRIPTION';
};
