import React, { memo } from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import { ELLIPSIS_STYLE } from '../utils/constants';
import { OrbyBadge, OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import UserIcon from './UserIcon';
import { TypographySize } from 'orby-ui/src/components/typography/typography-utils';

interface Props {
  email: string;
  imageUrl?: string;
  fullName?: string;
  displayEmailExplicitly?: boolean;
  additionalLabelText?: string;
  badgeText?: string;
  avatarTextSize?: TypographySize;
  warningText?: string;
  opacity?: number;
  sx?: SxProps<Theme>;
}

const UserCard: React.FC<Props> = ({
  email,
  imageUrl,
  fullName,
  displayEmailExplicitly = false,
  additionalLabelText = '',
  badgeText = '',
  avatarTextSize = 'md',
  warningText = '',
  opacity = 1,
  sx,
}) => {
  const displayName = fullName?.trim() || email;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      <UserIcon
        imageUrl={imageUrl}
        fullName={fullName}
        email={email}
        textSize={avatarTextSize}
        sx={{
          width: 32,
          height: 32,
          fontSize: 20,
          opacity: opacity,
          ...sx,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: '0px',
          gap: '4px',
        }}
      >
        <Box
          display='flex'
          flexDirection={additionalLabelText ? 'row' : 'column'}
          gap={1}
        >
          <Box title={email ?? displayName} sx={{ minWidth: '0px' }}>
            <OrbyTypography
              size='sm'
              weight='regular'
              color={OrbyColorPalette['grey-900']}
              sx={{
                opacity: opacity,
                ...ELLIPSIS_STYLE,
              }}
            >
              {displayName}
            </OrbyTypography>
            {warningText && (
              <OrbyTypography
                size='sm'
                weight='regular'
                color={OrbyColorPalette['warning-700']}
                sx={{ ...ELLIPSIS_STYLE }}
              >
                {warningText}
              </OrbyTypography>
            )}
          </Box>
          {additionalLabelText && (
            <OrbyBadge
              size='small'
              textColor={OrbyColorPalette['grey-700']}
              backgroundColor={OrbyColorPalette['grey-100']}
              badgeName={additionalLabelText}
            />
          )}
        </Box>
        {displayEmailExplicitly && fullName?.trim() && (
          <Box title={email} flexDirection='column' sx={{ minWidth: '0px' }}>
            <OrbyTypography
              size='sm'
              weight='regular'
              color={OrbyColorPalette['grey-500']}
              sx={{ ...ELLIPSIS_STYLE }}
            >
              {email}
            </OrbyTypography>
          </Box>
        )}
        {badgeText && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <OrbyBadge
              size='small'
              textColor={OrbyColorPalette['blue-900']}
              backgroundColor={OrbyColorPalette['purple-100']}
              badgeName={badgeText}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default memo(UserCard);
