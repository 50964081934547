import { AppState } from '../reducers';

export const authSelector = (state: AppState) => state.auth;
export const userSelector = (state: AppState) => state.user;
export const taskV2Selector = (state: AppState) => state.taskV2;
export const automationMiningSelector = (state: AppState) =>
  state.automationMining;
export const workflowSelector = (state: AppState) => state.workflow;
export const workflowTaskSelector = (state: AppState) => state.workflowTask;
export const workflowDetailsSelector = (state: AppState) =>
  state.workflowDetails;
export const dashboardSelector = (state: AppState) => state.dashboard;
export const utilitySelector = (state: AppState) => state.utility;
export const connectorSelector = (state: AppState) => state.connector;
export const reviewTaskSelector = (state: AppState) => state.reviewTask.present;
export const filterOptionsSelector = (state: AppState) => state.filterOptions;
export const archivedResourcesSelector = (state: AppState) =>
  state.archivedResources;
export const featureFlagsSelector = (state: AppState) => state.featureFlags;
export const organizationSelector = (state: AppState) => state.organization;
export const orbotSelector = (state: AppState) => state.orbot;
export const workflowSecretSelector = (state: AppState) => state.workflowSecret;
export const processDiscoverySelector = (state: AppState) =>
  state.processDiscovery;
