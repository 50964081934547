// Helper function to create both new and legacy route patterns
const createRoutePatterns = (legacyPath: string) => {
  return {
    new: `/organizations/:organization_id${legacyPath}`,
    legacy: legacyPath,
  };
};

export const ROUTE_PATTERNS = {
  DASHBOARD: createRoutePatterns('/dashboard'),
  TASKS: createRoutePatterns('/tasks'),
  WORKFLOW: createRoutePatterns('/workflow'),
  WORKFLOW_DEFINITION: createRoutePatterns('/workflow/:workflow_id/definition'),
  WORKFLOW_BUILDER: createRoutePatterns('/workflow/:workflow_id/builder'),
  WORKFLOW_UPDATE: createRoutePatterns('/workflow/:workflow_id/update'),
  WORKFLOW_CREATE: createRoutePatterns('/workflow/create'),
  WORKFLOW_SETTINGS: createRoutePatterns('/workflow/:workflow_id/settings'),
  WORKFLOW_ADMINS: createRoutePatterns('/workflow/:workflow_id/admins'),
  WORKFLOW_SCHEDULE: createRoutePatterns('/workflow/:workflow_id/schedule'),
  TASK_REVIEW: createRoutePatterns(
    '/workflows/:workflow_id/tasks/:task_id/automation-review',
  ),
  EXECUTIONS: createRoutePatterns('/executions'),
  EXECUTION_DETAILS: createRoutePatterns('/executions/:workflow_task_id'),
  HITL_DETAILS: createRoutePatterns(
    '/executions/:workflow_id/tasks/:task_id/hitl-details',
  ),
  DELETED_ITEMS: createRoutePatterns('/deleted-items'),
  TEAMS: createRoutePatterns('/teams'),
  SETTINGS: createRoutePatterns('/settings'),
  PROCESS_DISCOVERY: createRoutePatterns('/process-discovery'),
  PROCESS_DISCOVERY_DIAGRAM: createRoutePatterns(
    '/process-discovery/:process_id/diagram',
  ),
  PROCESS_DISCOVERY_DOCUMENTATION: createRoutePatterns(
    '/process-discovery/:process_id/documentation',
  ),
  PROCESS_DISCOVERY_SETTINGS: createRoutePatterns(
    '/process-discovery/:process_id/settings',
  ),
  PROCESS_DISCOVERY_SESSIONS: createRoutePatterns(
    '/process-discovery/:process_id/sessions',
  ),
  CREATE_PROCESS_DISCOVERY: createRoutePatterns('/process-discovery/create'),
  UPDATE_DRAFT_PROCESS: createRoutePatterns(
    '/process-discovery/create/:process_id',
  ),
  ALL_EXECUTIONS: createRoutePatterns('/all-executions'),
  WORKFLOW_TEMPLATE: createRoutePatterns('/workflow/template'),
  WORKFLOW_ADVANCED_EDITOR: createRoutePatterns(
    '/workflow/:workflow_id/advanced-editor',
  ),
  FIELDS: createRoutePatterns('/fields'),
  MACHINES: createRoutePatterns('/settings/machines'),
  WEBHOOKS: createRoutePatterns('/settings/webhooks'),
  API_KEYS_MANAGEMENT: createRoutePatterns('/settings/api-keys'),
  COMPANY_NON_WORKING_DAYS: createRoutePatterns(
    '/settings/company-non-working-days',
  ),
  CREATE_WORKFLOW_TEMPLATE: createRoutePatterns('/workflow/template/create'),
  TEMPLATE_DEFINITION: createRoutePatterns('/template/:template_id/definition'),
  CONNECTOR_CREATE: createRoutePatterns('/workflow/connector/create'),
  CONNECTOR_UPDATE: createRoutePatterns(
    '/workflow/connector/:connector_id/update',
  ),
  // ... add other routes that need organization context
};

export const APP_ROUTES = {
  ...Object.entries(ROUTE_PATTERNS).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value.new,
    }),
    {} as Record<keyof typeof ROUTE_PATTERNS, string>,
  ),
  // Add routes without legacy counterparts
  MACHINE_REGISTRATION_REDIRECT: '/auth' as string,
  TASKS_EXPORT: '/tasks/export/:notification_id' as string,
  DEV_TESTING: '/dev-testing',
  DEV_TESTING_DOCUMENT: '/dev-testing/document/:file_name',
  INTEGRATION_TEST: '/integration-test',
  CONFIGURE_SSO: '/settings/configure-sso/:organization_id',
  SCHEDULED_TASKS: '/scheduled-tasks',
  FEATURE_FLAGS: '/feature-flags',
  FEATURE_FLAGS_CREATE: '/feature-flags/create',
  FEATURE_FLAGS_UPDATE: '/feature-flags/:feature_flag_id/update',
} as const;

//TODO: Remove this once we have a new route pattern setup for all the customers
// Legacy routes enum for reference
export const LEGACY_ROUTES = Object.entries(ROUTE_PATTERNS).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [key]: value.legacy as string,
  }),
  {} as Record<keyof typeof ROUTE_PATTERNS, string>,
);
