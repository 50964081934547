import React, {
  FC,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  matchPath,
  generatePath,
} from 'react-router-dom';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import useIsFeatureEnabled from '../../hooks/useIsFeatureEnabled';
import WebDrawerWrapper from '../../components/web-drawer/WebDrawerWrapper';
import { setSelectedOrgInfo } from '../../redux/actions/user.action';
import {
  loggedInUserSelector,
  selectedOrgInfoCompletedSelector,
  selectedOrgInfoSelector,
  userPermissionsSelector,
} from '../../redux/selectors/user.selectors';
import { storageService, Token } from '../../services/StorageService';
import {
  FEATURE_FLAGS,
  SESSION_BROADCAST_ACTIONS,
  DRAWER_WIDTH_COLLAPSED,
  DRAWER_WIDTH_EXPANDED,
  IS_PROD,
} from '../../utils/constants';
import {
  getOrgIdFromResourceName,
  isAdmin,
  isOrbyAIUser,
} from '../../utils/helpers';
import AnnouncementPopup from '../Announcement/AnnouncementPopup';
import IdleSessionManager from '../IdleSessionManager/IdleSessionManager';
import SessionTimeoutManager from '../SessionTimeout/SessionTimeoutManager';
import SuspenseLoader from './components/SuspenseLoader';
import { APP_ROUTES } from './Routes';
import { shouldCollapseSideDrawer } from './RouteBuilder.helpers';
import { amplitudeService } from '../../services/AmplitudeService';
import { setRouterPath } from '../../redux/slices/router.slice';
import { sendOrgInfoToExtension } from '../../utils/extension';
import { showErrorToast } from 'orby-ui/src/components/toast/OrbyToast';
import { getProtectedRoutes } from './ProtectedRoutesList';
import { useGeneratePath } from '../../hooks/useGeneratePath';
import { cookieConsentService } from '../../services/CookieConsentService';

interface Props {
  orbotWorkflowLength: number;
}

const TrackedRoute: FC<{ children: ReactNode; routerPath: string }> = ({
  children,
  routerPath,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (routerPath !== '*') {
      amplitudeService.trackPageView({
        path: location.pathname,
        location: window.location.href,
        router_path: routerPath,
        window_width: window.innerWidth,
        window_height: window.innerHeight,
        screen_width: window.screen.width,
        screen_height: window.screen.height,
      });
      dispatch(setRouterPath(routerPath));
    }
  }, [routerPath]);
  return <>{children}</>;
};

const ProtectedRoutes: FC<Props> = ({ orbotWorkflowLength }) => {
  // HELPER FUNCTIONS
  const handlePostLoginToken = async (isLoginCookieEnabled: boolean) => {
    const token: Token | undefined = await storageService.getStoredToken();

    if (token?.refreshTokenExpiresAt) {
      // Note: due to extended_refresh_token feature flag we get refreshTokenExpiresAt for 90 days
      // But actual session only last for 24 hrs
      storageService.setSessionExpirationTime(token.refreshTokenExpiresAt);
    }

    if (isLoginCookieEnabled && token) {
      // Set the sessionId in the session storage
      await storageService.setSessionId(token.sessionId as string);

      // We need to remove the tokens from the local storage
      localStorage.removeItem('token');
      localStorage.removeItem('google-token');
    } else if (!token && !isLoginCookieEnabled) {
      // TODO: Implement login cookie logic - Need to fetch the token from BE and set it in localStorage
      // https://orby-ai.atlassian.net/browse/OA-3861
    }

    // If this page was opened for re-authentication, close the window
    // This is typically used when the user needs to log in again in a new window

    // check if this window was opened by a script using the window.open()
    if (window.opener && window.name === 'reauthenticate') {
      const broadcastChannel = new BroadcastChannel('SessionLogoutChannel');
      broadcastChannel.postMessage({
        type: SESSION_BROADCAST_ACTIONS.SYNC_SESSION_STATE,
        visibility: false,
        isReAuthenticated: true,
      });
      // This method can only be called on windows that were opened
      // by a script using the Window.open()
      // ref: https://developer.mozilla.org/en-US/docs/Web/API/Window/close
      window.close();
    }
  };

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const generateOrgPath = useGeneratePath();

  // STATES & SELECTORS

  // SIDE DRAWER COLLAPSE STATE
  const [collapse, setCollapse] = useState(
    shouldCollapseSideDrawer(location.pathname),
  );

  const user = useSelector(loggedInUserSelector);
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const userPermissions = useSelector(userPermissionsSelector);
  const selectedOrgInfoCompleted = useSelector(
    selectedOrgInfoCompletedSelector,
  );

  // send the selectedOrgInfo to the extension whenever there are any changes in selected org.
  useEffect(() => {
    if (selectedOrgInfo && selectedOrgInfoCompleted) {
      sendOrgInfoToExtension(selectedOrgInfo);
    }
  }, [selectedOrgInfoCompleted]);
  // FEATURE FLAGS

  const isLoginCookieEnabled = useIsFeatureEnabled(FEATURE_FLAGS.COOKIE);
  const isRbacEnabled = useIsFeatureEnabled(FEATURE_FLAGS.RBAC);

  const isAdvancedEditorEnabled = useIsFeatureEnabled(
    FEATURE_FLAGS.ADVANCED_WORKFLOW_EDITOR,
  );
  const isIdleSessionManagerEnabled = useIsFeatureEnabled(
    FEATURE_FLAGS.ENABLE_IDLE_SESSION_TIMEOUT,
  );
  const overrideSessionTimeoutTo1Min = useIsFeatureEnabled(
    FEATURE_FLAGS.OVERRIDE_IDLE_SESSION_TIMEOUT_1MIN,
  );
  const isWebhooksEnabled = useIsFeatureEnabled(FEATURE_FLAGS.WEBHOOK_ENABLED);
  const isApiKeysManagementEnabled = useIsFeatureEnabled(
    FEATURE_FLAGS.API_KEYS_MANAGEMENT,
  );
  const isBlockWorkflowCreationAndModification = useIsFeatureEnabled(
    FEATURE_FLAGS.BLOCK_WORKFLOW_CREATION_AND_MODIFICATION,
  );

  // PERMISSIONS & CHECKS

  let isTestUser = false;
  if (user?.email === process.env.REACT_APP_TEST_USER) {
    isTestUser = true;
  }
  const isAdminUser = isAdmin(selectedOrgInfo?.role);
  const isOrbyAdmin =
    selectedOrgInfo?.orgDisplayName === 'Orby AI' && isAdminUser;
  const canCreateWorkflow =
    isAdminUser || (isRbacEnabled && userPermissions.createWorkflow);
  const canListArchivedResources =
    isAdminUser || (isRbacEnabled && userPermissions.listArchivedResources);
  const canAccessConnectors =
    isAdminUser || (isRbacEnabled && userPermissions.listConnectors);
  const canAccessWorkflowPage =
    canCreateWorkflow ||
    orbotWorkflowLength > 0 ||
    (isRbacEnabled &&
      (userPermissions.listWorkflows || userPermissions.listConnectors));
  const canAccessExecutionPage =
    isAdminUser ||
    orbotWorkflowLength > 0 ||
    (isRbacEnabled && userPermissions.listWorkflows);
  const canAccessTeamPage =
    isAdminUser || (isRbacEnabled && userPermissions.updateUser);
  const canAccessWebhooksPage = isAdminUser && isWebhooksEnabled;
  const canAccessApiKeysManagementPage =
    isAdminUser && isApiKeysManagementEnabled;

  // ROUTES CONFIGURATION
  const PROTECTED_ROUTES = getProtectedRoutes({
    permissions: {
      canAccessWorkflowPage,
      canAccessExecutionPage,
      canAccessConnectors,
      canListArchivedResources,
      canCreateWorkflow,
      canAccessTeamPage,
      canAccessWebhooksPage,
      canAccessApiKeysManagementPage,
      isOrbyAdmin,
      isAdminUser,
      isRbacEnabled,
      isBlockWorkflowCreationAndModification,
      isTestUser,
      isAdvancedEditorEnabled,
      isOrbyAIUser: isOrbyAIUser(user),
    },
    collapse,
    isProd: IS_PROD,
  });

  /**
   * USE EFFECTS
   */

  useEffect(() => {
    // if the current path is a collapsed path, set the collapse state to true
    // otherwise we keep the previous state
    if (shouldCollapseSideDrawer(location.pathname)) {
      setCollapse(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isLoginCookieEnabled !== undefined) {
      handlePostLoginToken(isLoginCookieEnabled);
    }
  }, [isLoginCookieEnabled]);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (
        event.key === 'org-resource-name' &&
        event.newValue &&
        event.oldValue
      ) {
        const orgInfo = user?.orgInfos?.find(
          (org) => org.orgResourceName === event.newValue,
        );
        if (orgInfo) {
          dispatch(setSelectedOrgInfo(orgInfo));
          navigate(
            {
              pathname: generatePath(APP_ROUTES.DASHBOARD, {
                organization_id: getOrgIdFromResourceName(
                  orgInfo.orgResourceName,
                ),
              }),
            },
            // prevent HITL unsaved changes warning
            { state: { preventBlocking: true } },
          ); // navigate to dashboard
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    // Cleanup listener when component unmounts
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []); // Empty dependency array since we want this to run once on mount

  const handleOrganizationSwitch = useCallback(() => {
    const match = matchPath(
      '/organizations/:organization_id/*',
      location.pathname,
    );
    const organizationId = match?.params?.organization_id;

    // Early returns for invalid cases
    if (
      !organizationId ||
      organizationId === ':organization_id' ||
      !user?.orgInfos
    ) {
      return;
    }

    const targetOrgResourceName = `organizations/${organizationId}`;

    // Skip if already on correct org
    if (selectedOrgInfo?.orgResourceName === targetOrgResourceName) {
      return;
    }

    const targetOrganization = user.orgInfos.find(
      (org) => org.orgResourceName === targetOrgResourceName,
    );

    if (targetOrganization) {
      dispatch(setSelectedOrgInfo(targetOrganization));
    } else {
      showErrorToast("You don't have permissions to view this organization", {
        position: 'top-right',
        autoClose: 1000,
      });
      const fallbackPath = generateOrgPath(APP_ROUTES.DASHBOARD);
      navigate(fallbackPath, { replace: true });
    }
  }, [
    location.pathname,
    selectedOrgInfo?.orgResourceName,
    user?.orgInfos,
    dispatch,
    navigate,
  ]);

  useEffect(() => {
    handleOrganizationSwitch();
  }, [handleOrganizationSwitch]);

  useEffect(() => {
    cookieConsentService.init();
    return () => {
      cookieConsentService.destroyCookieBanner();
    };
  }, []);

  return (
    <Box height={'100%'}>
      <title>`&quot;Login | Orby-UI&quot;</title>
      {isIdleSessionManagerEnabled && (
        <IdleSessionManager
          overrideSessionTimeoutTo1Min={overrideSessionTimeoutTo1Min}
        />
      )}
      <Box height={'100%'} display={'flex'}>
        <WebDrawerWrapper
          collapse={collapse}
          setCollapse={setCollapse}
          orbotWorkflowLength={orbotWorkflowLength}
        />
        <SessionTimeoutManager />
        <AnnouncementPopup announcements={user?.announcements ?? []} />
        <Box
          id={'main-content'}
          sx={{
            width: `calc(100% - ${collapse ? `${DRAWER_WIDTH_COLLAPSED}px` : `${DRAWER_WIDTH_EXPANDED}px`})`,
            marginLeft: collapse
              ? `${DRAWER_WIDTH_COLLAPSED}px`
              : `${DRAWER_WIDTH_EXPANDED}px`,
            transition: 'width 0.3s ease, margin-left 0.3s ease',
          }}
        >
          <Routes>
            {PROTECTED_ROUTES.map(
              (route) =>
                route.enabled && (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <SuspenseLoader>
                        <TrackedRoute routerPath={route.path}>
                          {route.element}
                        </TrackedRoute>
                      </SuspenseLoader>
                    }
                  />
                ),
            )}
          </Routes>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(ProtectedRoutes);
