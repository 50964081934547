/* eslint-disable */
// @ts-nocheck
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import { UserProfileInfo } from "../../common/user_profile";
import { Empty } from "../../google/protobuf/empty";
import { Timestamp } from "../../google/protobuf/timestamp";
import { messageTypeRegistry } from "../../typeRegistry";

export const protobufPackage = "pb.v1alpha1";

/**
 * A SecretBlock is a collection of secrets.
 * It can only be created by the organization admin.
 * Other users can only update their own secrets in a block.
 */
export interface SecretBlock {
  $type?: "pb.v1alpha1.SecretBlock";
  id?: string | undefined;
  orgId?: string | undefined;
  createdTime?: Date | undefined;
  lastUpdatedTime?: Date | undefined;
  creatorId?:
    | string
    | undefined;
  /** This below fields are used to track the last modified information in the changelogs. */
  lastModifiedBy?:
    | string
    | undefined;
  /**
   * A human readable name to describe the secret block. Optional.
   * Previously (before 2025 Feb 11), block name was unique and used to identify.
   */
  blockName?:
    | string
    | undefined;
  /** Explicitly store the origin URL that the secret block should be for */
  originUrl?:
    | string
    | undefined;
  /**
   * Public secret blocks are viewable by all users in an organization.
   * Private secret blocks are only viewable by the user who created them.
   * Private secret blocks can only be used by a user manually running a workflow.
   */
  isPublic?:
    | boolean
    | undefined;
  /** A list of secrets that are in this block */
  secrets?:
    | Secret[]
    | undefined;
  /**
   * deprecated: use secrets field instead.
   *
   * @deprecated
   */
  secretInfos?: SecretInfo[] | undefined;
  creator?: UserProfileInfo | undefined;
}

export interface SecretInfo {
  $type?: "pb.v1alpha1.SecretInfo";
  secret?:
    | Secret
    | undefined;
  /**
   * Whether or not this secret has a private override for the user.
   * If the secret is private, use this field to determine if the user is authorized
   * to access the secrets.
   */
  hasOverride?: boolean | undefined;
}

export interface Secret {
  $type?: "pb.v1alpha1.Secret";
  /**
   * secret Id generated by the server.
   * It should not be set when creating a secret.
   */
  id?:
    | string
    | undefined;
  /**
   * We can remove this field in the future, since we have secrets wrapped in a block. However keeping it for now for backward compatibility.
   *
   * @deprecated
   */
  orgId?:
    | string
    | undefined;
  /** @deprecated */
  createdTime?:
    | Date
    | undefined;
  /**
   * a human readable name to describe what the secret is about, it has to be unique in a secret block.
   * this will also be used as a key in the secret block to identify which secret
   * it is.
   */
  displayName?:
    | string
    | undefined;
  /** the secret value. */
  value?:
    | string
    | undefined;
  /**
   * Cannot be changed once set.
   * Assumption: Secrets inside a block needs to be of the same SCOPE.
   * deprecated: use scope in the secret block instead.
   *
   * @deprecated
   */
  scope?: SecretScope | undefined;
  lastUpdater?: UserProfileInfo | undefined;
}

export enum SecretScope {
  UNSPECIFIED = 0,
  ORG = 1,
  PRIVATE = 2,
  UNRECOGNIZED = -1,
}

export function secretScopeFromJSON(object: any): SecretScope {
  switch (object) {
    case 0:
    case "SCOPE_UNSPECIFIED":
      return SecretScope.UNSPECIFIED;
    case 1:
    case "SCOPE_ORG":
      return SecretScope.ORG;
    case 2:
    case "SCOPE_PRIVATE":
      return SecretScope.PRIVATE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SecretScope.UNRECOGNIZED;
  }
}

export function secretScopeToJSON(object: SecretScope): string {
  switch (object) {
    case SecretScope.UNSPECIFIED:
      return "SCOPE_UNSPECIFIED";
    case SecretScope.ORG:
      return "SCOPE_ORG";
    case SecretScope.PRIVATE:
      return "SCOPE_PRIVATE";
    case SecretScope.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Refers to a secret value stored in a SecretBlock.
 * When there is no secret being associated (such as in a template), block_id
 * would be empty.
 */
export interface SecretValue {
  $type?: "pb.v1alpha1.SecretValue";
  blockId?:
    | string
    | undefined;
  /** name need to match the secret display_name provided by the user. */
  name?: string | undefined;
}

export interface CreateSecretRequest {
  $type?: "pb.v1alpha1.CreateSecretRequest";
  secret?: Secret | undefined;
}

export interface CreateSecretResponse {
  $type?: "pb.v1alpha1.CreateSecretResponse";
  /** a secret Id generated by the server. */
  id?: string | undefined;
}

export interface CreateSecretBlockRequest {
  $type?: "pb.v1alpha1.CreateSecretBlockRequest";
  secretBlock?: SecretBlock | undefined;
}

export interface CreateSecretBlockResponse {
  $type?: "pb.v1alpha1.CreateSecretBlockResponse";
  /** secrets inside, will only have id and display_name fields populated. */
  secretBlock?: SecretBlock | undefined;
}

export interface GetSecretBlockRequest {
  $type?: "pb.v1alpha1.GetSecretBlockRequest";
  orgId?:
    | string
    | undefined;
  /**
   * TODO: Remove for backwards comaptibility. Since after Fegb 2025, secret block names
   * are no longer unique, don't allow querying for it.
   * Since block_name is unique within an organization, we can use it to identify the particular block.
   *
   * @deprecated
   */
  blockName?: string | undefined;
  id?: string | undefined;
}

/**
 * Returns the secret block regardless if the user has secrets set in the block or not.
 * This is so that FE can get info on this secret block (what fields are present), so
 * it can prompt the user accordingly.
 * If the secret is private scoped, use has_override field on
 * SecretInfo to determine if the user has secrets set (is authorized).
 */
export interface GetSecretBlockResponse {
  $type?: "pb.v1alpha1.GetSecretBlockResponse";
  /**
   * Secrets inside the block will only have id, display_name, SCOPE fields populated.
   * Clients must call GetSecret individually with the secret ids to get the secret value.
   */
  secretBlock?: SecretBlock | undefined;
}

export interface UpdateSecretBlockRequest {
  $type?: "pb.v1alpha1.UpdateSecretBlockRequest";
  orgId?:
    | string
    | undefined;
  /**
   * TODO: Remove for backwards comaptibility. Since after Fegb 2025, secret block names
   * are no longer unique, don't allow querying for it.
   *
   * @deprecated
   */
  blockName?: string | undefined;
  id?:
    | string
    | undefined;
  /**
   * secrets is the list of secrets to be updated.
   * Secret id or the displayName should be present in the list, whose value needs to be updated.
   * displayName can also be used since displayName of a secret inside a block is unique.
   * Note: Only the value field can be overwritten.
   */
  secrets?:
    | Secret[]
    | undefined;
  /**
   * Normal users can only update their override.
   * However admins can update their override with the public ones.
   * If this field is set to true and the secret scope was public, the secret value passed will update the public secret value.
   * deprecated: secret blocks no longer have overrides
   *
   * @deprecated
   */
  updateDefaultValue?: boolean | undefined;
}

export interface UpdateSecretBlockResponse {
  $type?: "pb.v1alpha1.UpdateSecretBlockResponse";
  /** secrets inside, will only have id and display_name fields populated. */
  secretBlock?: SecretBlock | undefined;
}

export interface DeleteSecretBlockRequest {
  $type?: "pb.v1alpha1.DeleteSecretBlockRequest";
  orgId?:
    | string
    | undefined;
  /**
   * TODO: Remove for backwards comaptibility. Since after Fegb 2025, secret block names
   * are no longer unique, don't allow querying for it.
   *
   * @deprecated
   */
  blockName?: string | undefined;
  id?: string | undefined;
}

export interface DeleteSecretBlockResponse {
  $type?: "pb.v1alpha1.DeleteSecretBlockResponse";
}

export interface ListSecretBlocksRequest {
  $type?: "pb.v1alpha1.ListSecretBlocksRequest";
  orgId?: string | undefined;
}

export interface ListSecretBlocksResponse {
  $type?: "pb.v1alpha1.ListSecretBlocksResponse";
  /** Only the secretId, displayName, Scope in the secret block will be populated. */
  secretBlocks?: SecretBlock[] | undefined;
}

export interface GetSecretRequest {
  $type?: "pb.v1alpha1.GetSecretRequest";
  orgId?:
    | string
    | undefined;
  /**
   * use the secret_id field instead.
   * TODO: remove this after the BE/FE migration is completed.
   *
   * @deprecated
   */
  id?:
    | string
    | undefined;
  /** fetching a secret value by its id */
  secretId?:
    | string
    | undefined;
  /** fetch a secret value by the block id and secret name. */
  secretValue?:
    | SecretValue
    | undefined;
  /**
   * This field is only valid if the user has an override for the public secret.
   * If true, the response will include the public secret value and not the specific user's override value.
   * It can only be retrieved by admins.
   * deprecated: secret blocks no longer have overrides
   *
   * @deprecated
   */
  returnPublicValue?: boolean | undefined;
}

export interface GetSecretResponse {
  $type?: "pb.v1alpha1.GetSecretResponse";
  secret?: Secret | undefined;
}

/** Allow users to update a secret value. */
export interface UpdateSecretRequest {
  $type?: "pb.v1alpha1.UpdateSecretRequest";
  orgId?: string | undefined;
  id?:
    | string
    | undefined;
  /** Ovewrite the secret name. */
  value?: string | undefined;
}

export interface UpdateSecretResponse {
  $type?: "pb.v1alpha1.UpdateSecretResponse";
  /** secret Id generated by the server. */
  id?: string | undefined;
}

export interface DeleteSecretRequest {
  $type?: "pb.v1alpha1.DeleteSecretRequest";
  orgId?:
    | string
    | undefined;
  /** secret Id generated by the server. */
  id?: string | undefined;
}

function createBaseSecretBlock(): SecretBlock {
  return {
    $type: "pb.v1alpha1.SecretBlock",
    id: "",
    orgId: "",
    createdTime: undefined,
    lastUpdatedTime: undefined,
    creatorId: "",
    lastModifiedBy: "",
    blockName: "",
    originUrl: "",
    isPublic: false,
    secrets: [],
    secretInfos: [],
    creator: undefined,
  };
}

export const SecretBlock = {
  $type: "pb.v1alpha1.SecretBlock" as const,

  encode(message: SecretBlock, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.orgId !== undefined && message.orgId !== "") {
      writer.uint32(18).string(message.orgId);
    }
    if (message.createdTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createdTime), writer.uint32(26).fork()).ldelim();
    }
    if (message.lastUpdatedTime !== undefined) {
      Timestamp.encode(toTimestamp(message.lastUpdatedTime), writer.uint32(34).fork()).ldelim();
    }
    if (message.creatorId !== undefined && message.creatorId !== "") {
      writer.uint32(42).string(message.creatorId);
    }
    if (message.lastModifiedBy !== undefined && message.lastModifiedBy !== "") {
      writer.uint32(50).string(message.lastModifiedBy);
    }
    if (message.blockName !== undefined && message.blockName !== "") {
      writer.uint32(58).string(message.blockName);
    }
    if (message.originUrl !== undefined && message.originUrl !== "") {
      writer.uint32(82).string(message.originUrl);
    }
    if (message.isPublic !== undefined && message.isPublic !== false) {
      writer.uint32(88).bool(message.isPublic);
    }
    if (message.secrets !== undefined && message.secrets.length !== 0) {
      for (const v of message.secrets) {
        Secret.encode(v!, writer.uint32(98).fork()).ldelim();
      }
    }
    if (message.secretInfos !== undefined && message.secretInfos.length !== 0) {
      for (const v of message.secretInfos) {
        SecretInfo.encode(v!, writer.uint32(66).fork()).ldelim();
      }
    }
    if (message.creator !== undefined) {
      UserProfileInfo.encode(message.creator, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecretBlock {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecretBlock();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.createdTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lastUpdatedTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.creatorId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.lastModifiedBy = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.blockName = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.originUrl = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.isPublic = reader.bool();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.secrets!.push(Secret.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.secretInfos!.push(SecretInfo.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.creator = UserProfileInfo.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SecretBlock {
    return {
      $type: SecretBlock.$type,
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      createdTime: isSet(object.createdTime) ? fromJsonTimestamp(object.createdTime) : undefined,
      lastUpdatedTime: isSet(object.lastUpdatedTime) ? fromJsonTimestamp(object.lastUpdatedTime) : undefined,
      creatorId: isSet(object.creatorId) ? globalThis.String(object.creatorId) : "",
      lastModifiedBy: isSet(object.lastModifiedBy) ? globalThis.String(object.lastModifiedBy) : "",
      blockName: isSet(object.blockName) ? globalThis.String(object.blockName) : "",
      originUrl: isSet(object.originUrl) ? globalThis.String(object.originUrl) : "",
      isPublic: isSet(object.isPublic) ? globalThis.Boolean(object.isPublic) : false,
      secrets: globalThis.Array.isArray(object?.secrets) ? object.secrets.map((e: any) => Secret.fromJSON(e)) : [],
      secretInfos: globalThis.Array.isArray(object?.secretInfos)
        ? object.secretInfos.map((e: any) => SecretInfo.fromJSON(e))
        : [],
      creator: isSet(object.creator) ? UserProfileInfo.fromJSON(object.creator) : undefined,
    };
  },

  toJSON(message: SecretBlock): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    if (message.orgId !== undefined && message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.createdTime !== undefined) {
      obj.createdTime = message.createdTime.toISOString();
    }
    if (message.lastUpdatedTime !== undefined) {
      obj.lastUpdatedTime = message.lastUpdatedTime.toISOString();
    }
    if (message.creatorId !== undefined && message.creatorId !== "") {
      obj.creatorId = message.creatorId;
    }
    if (message.lastModifiedBy !== undefined && message.lastModifiedBy !== "") {
      obj.lastModifiedBy = message.lastModifiedBy;
    }
    if (message.blockName !== undefined && message.blockName !== "") {
      obj.blockName = message.blockName;
    }
    if (message.originUrl !== undefined && message.originUrl !== "") {
      obj.originUrl = message.originUrl;
    }
    if (message.isPublic !== undefined && message.isPublic !== false) {
      obj.isPublic = message.isPublic;
    }
    if (message.secrets?.length) {
      obj.secrets = message.secrets.map((e) => Secret.toJSON(e));
    }
    if (message.secretInfos?.length) {
      obj.secretInfos = message.secretInfos.map((e) => SecretInfo.toJSON(e));
    }
    if (message.creator !== undefined) {
      obj.creator = UserProfileInfo.toJSON(message.creator);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SecretBlock>, I>>(base?: I): SecretBlock {
    return SecretBlock.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SecretBlock>, I>>(object: I): SecretBlock {
    const message = createBaseSecretBlock();
    message.id = object.id ?? "";
    message.orgId = object.orgId ?? "";
    message.createdTime = object.createdTime ?? undefined;
    message.lastUpdatedTime = object.lastUpdatedTime ?? undefined;
    message.creatorId = object.creatorId ?? "";
    message.lastModifiedBy = object.lastModifiedBy ?? "";
    message.blockName = object.blockName ?? "";
    message.originUrl = object.originUrl ?? "";
    message.isPublic = object.isPublic ?? false;
    message.secrets = object.secrets?.map((e) => Secret.fromPartial(e)) || [];
    message.secretInfos = object.secretInfos?.map((e) => SecretInfo.fromPartial(e)) || [];
    message.creator = (object.creator !== undefined && object.creator !== null)
      ? UserProfileInfo.fromPartial(object.creator)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(SecretBlock.$type, SecretBlock);

function createBaseSecretInfo(): SecretInfo {
  return { $type: "pb.v1alpha1.SecretInfo", secret: undefined, hasOverride: false };
}

export const SecretInfo = {
  $type: "pb.v1alpha1.SecretInfo" as const,

  encode(message: SecretInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.secret !== undefined) {
      Secret.encode(message.secret, writer.uint32(10).fork()).ldelim();
    }
    if (message.hasOverride !== undefined && message.hasOverride !== false) {
      writer.uint32(16).bool(message.hasOverride);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecretInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecretInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.secret = Secret.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.hasOverride = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SecretInfo {
    return {
      $type: SecretInfo.$type,
      secret: isSet(object.secret) ? Secret.fromJSON(object.secret) : undefined,
      hasOverride: isSet(object.hasOverride) ? globalThis.Boolean(object.hasOverride) : false,
    };
  },

  toJSON(message: SecretInfo): unknown {
    const obj: any = {};
    if (message.secret !== undefined) {
      obj.secret = Secret.toJSON(message.secret);
    }
    if (message.hasOverride !== undefined && message.hasOverride !== false) {
      obj.hasOverride = message.hasOverride;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SecretInfo>, I>>(base?: I): SecretInfo {
    return SecretInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SecretInfo>, I>>(object: I): SecretInfo {
    const message = createBaseSecretInfo();
    message.secret = (object.secret !== undefined && object.secret !== null)
      ? Secret.fromPartial(object.secret)
      : undefined;
    message.hasOverride = object.hasOverride ?? false;
    return message;
  },
};

messageTypeRegistry.set(SecretInfo.$type, SecretInfo);

function createBaseSecret(): Secret {
  return {
    $type: "pb.v1alpha1.Secret",
    id: "",
    orgId: "",
    createdTime: undefined,
    displayName: "",
    value: "",
    scope: 0,
    lastUpdater: undefined,
  };
}

export const Secret = {
  $type: "pb.v1alpha1.Secret" as const,

  encode(message: Secret, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.orgId !== undefined && message.orgId !== "") {
      writer.uint32(18).string(message.orgId);
    }
    if (message.createdTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createdTime), writer.uint32(26).fork()).ldelim();
    }
    if (message.displayName !== undefined && message.displayName !== "") {
      writer.uint32(34).string(message.displayName);
    }
    if (message.value !== undefined && message.value !== "") {
      writer.uint32(42).string(message.value);
    }
    if (message.scope !== undefined && message.scope !== 0) {
      writer.uint32(48).int32(message.scope);
    }
    if (message.lastUpdater !== undefined) {
      UserProfileInfo.encode(message.lastUpdater, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Secret {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecret();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.createdTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.displayName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.value = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.scope = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.lastUpdater = UserProfileInfo.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Secret {
    return {
      $type: Secret.$type,
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      createdTime: isSet(object.createdTime) ? fromJsonTimestamp(object.createdTime) : undefined,
      displayName: isSet(object.displayName) ? globalThis.String(object.displayName) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
      scope: isSet(object.scope) ? secretScopeFromJSON(object.scope) : 0,
      lastUpdater: isSet(object.lastUpdater) ? UserProfileInfo.fromJSON(object.lastUpdater) : undefined,
    };
  },

  toJSON(message: Secret): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    if (message.orgId !== undefined && message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.createdTime !== undefined) {
      obj.createdTime = message.createdTime.toISOString();
    }
    if (message.displayName !== undefined && message.displayName !== "") {
      obj.displayName = message.displayName;
    }
    if (message.value !== undefined && message.value !== "") {
      obj.value = message.value;
    }
    if (message.scope !== undefined && message.scope !== 0) {
      obj.scope = secretScopeToJSON(message.scope);
    }
    if (message.lastUpdater !== undefined) {
      obj.lastUpdater = UserProfileInfo.toJSON(message.lastUpdater);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Secret>, I>>(base?: I): Secret {
    return Secret.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Secret>, I>>(object: I): Secret {
    const message = createBaseSecret();
    message.id = object.id ?? "";
    message.orgId = object.orgId ?? "";
    message.createdTime = object.createdTime ?? undefined;
    message.displayName = object.displayName ?? "";
    message.value = object.value ?? "";
    message.scope = object.scope ?? 0;
    message.lastUpdater = (object.lastUpdater !== undefined && object.lastUpdater !== null)
      ? UserProfileInfo.fromPartial(object.lastUpdater)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(Secret.$type, Secret);

function createBaseSecretValue(): SecretValue {
  return { $type: "pb.v1alpha1.SecretValue", blockId: "", name: "" };
}

export const SecretValue = {
  $type: "pb.v1alpha1.SecretValue" as const,

  encode(message: SecretValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.blockId !== undefined && message.blockId !== "") {
      writer.uint32(10).string(message.blockId);
    }
    if (message.name !== undefined && message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecretValue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecretValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.blockId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SecretValue {
    return {
      $type: SecretValue.$type,
      blockId: isSet(object.blockId) ? globalThis.String(object.blockId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: SecretValue): unknown {
    const obj: any = {};
    if (message.blockId !== undefined && message.blockId !== "") {
      obj.blockId = message.blockId;
    }
    if (message.name !== undefined && message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SecretValue>, I>>(base?: I): SecretValue {
    return SecretValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SecretValue>, I>>(object: I): SecretValue {
    const message = createBaseSecretValue();
    message.blockId = object.blockId ?? "";
    message.name = object.name ?? "";
    return message;
  },
};

messageTypeRegistry.set(SecretValue.$type, SecretValue);

function createBaseCreateSecretRequest(): CreateSecretRequest {
  return { $type: "pb.v1alpha1.CreateSecretRequest", secret: undefined };
}

export const CreateSecretRequest = {
  $type: "pb.v1alpha1.CreateSecretRequest" as const,

  encode(message: CreateSecretRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.secret !== undefined) {
      Secret.encode(message.secret, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateSecretRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateSecretRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.secret = Secret.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateSecretRequest {
    return {
      $type: CreateSecretRequest.$type,
      secret: isSet(object.secret) ? Secret.fromJSON(object.secret) : undefined,
    };
  },

  toJSON(message: CreateSecretRequest): unknown {
    const obj: any = {};
    if (message.secret !== undefined) {
      obj.secret = Secret.toJSON(message.secret);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateSecretRequest>, I>>(base?: I): CreateSecretRequest {
    return CreateSecretRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateSecretRequest>, I>>(object: I): CreateSecretRequest {
    const message = createBaseCreateSecretRequest();
    message.secret = (object.secret !== undefined && object.secret !== null)
      ? Secret.fromPartial(object.secret)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(CreateSecretRequest.$type, CreateSecretRequest);

function createBaseCreateSecretResponse(): CreateSecretResponse {
  return { $type: "pb.v1alpha1.CreateSecretResponse", id: "" };
}

export const CreateSecretResponse = {
  $type: "pb.v1alpha1.CreateSecretResponse" as const,

  encode(message: CreateSecretResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateSecretResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateSecretResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateSecretResponse {
    return { $type: CreateSecretResponse.$type, id: isSet(object.id) ? globalThis.String(object.id) : "" };
  },

  toJSON(message: CreateSecretResponse): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateSecretResponse>, I>>(base?: I): CreateSecretResponse {
    return CreateSecretResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateSecretResponse>, I>>(object: I): CreateSecretResponse {
    const message = createBaseCreateSecretResponse();
    message.id = object.id ?? "";
    return message;
  },
};

messageTypeRegistry.set(CreateSecretResponse.$type, CreateSecretResponse);

function createBaseCreateSecretBlockRequest(): CreateSecretBlockRequest {
  return { $type: "pb.v1alpha1.CreateSecretBlockRequest", secretBlock: undefined };
}

export const CreateSecretBlockRequest = {
  $type: "pb.v1alpha1.CreateSecretBlockRequest" as const,

  encode(message: CreateSecretBlockRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.secretBlock !== undefined) {
      SecretBlock.encode(message.secretBlock, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateSecretBlockRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateSecretBlockRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.secretBlock = SecretBlock.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateSecretBlockRequest {
    return {
      $type: CreateSecretBlockRequest.$type,
      secretBlock: isSet(object.secretBlock) ? SecretBlock.fromJSON(object.secretBlock) : undefined,
    };
  },

  toJSON(message: CreateSecretBlockRequest): unknown {
    const obj: any = {};
    if (message.secretBlock !== undefined) {
      obj.secretBlock = SecretBlock.toJSON(message.secretBlock);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateSecretBlockRequest>, I>>(base?: I): CreateSecretBlockRequest {
    return CreateSecretBlockRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateSecretBlockRequest>, I>>(object: I): CreateSecretBlockRequest {
    const message = createBaseCreateSecretBlockRequest();
    message.secretBlock = (object.secretBlock !== undefined && object.secretBlock !== null)
      ? SecretBlock.fromPartial(object.secretBlock)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(CreateSecretBlockRequest.$type, CreateSecretBlockRequest);

function createBaseCreateSecretBlockResponse(): CreateSecretBlockResponse {
  return { $type: "pb.v1alpha1.CreateSecretBlockResponse", secretBlock: undefined };
}

export const CreateSecretBlockResponse = {
  $type: "pb.v1alpha1.CreateSecretBlockResponse" as const,

  encode(message: CreateSecretBlockResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.secretBlock !== undefined) {
      SecretBlock.encode(message.secretBlock, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateSecretBlockResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateSecretBlockResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.secretBlock = SecretBlock.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateSecretBlockResponse {
    return {
      $type: CreateSecretBlockResponse.$type,
      secretBlock: isSet(object.secretBlock) ? SecretBlock.fromJSON(object.secretBlock) : undefined,
    };
  },

  toJSON(message: CreateSecretBlockResponse): unknown {
    const obj: any = {};
    if (message.secretBlock !== undefined) {
      obj.secretBlock = SecretBlock.toJSON(message.secretBlock);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateSecretBlockResponse>, I>>(base?: I): CreateSecretBlockResponse {
    return CreateSecretBlockResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateSecretBlockResponse>, I>>(object: I): CreateSecretBlockResponse {
    const message = createBaseCreateSecretBlockResponse();
    message.secretBlock = (object.secretBlock !== undefined && object.secretBlock !== null)
      ? SecretBlock.fromPartial(object.secretBlock)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(CreateSecretBlockResponse.$type, CreateSecretBlockResponse);

function createBaseGetSecretBlockRequest(): GetSecretBlockRequest {
  return { $type: "pb.v1alpha1.GetSecretBlockRequest", orgId: "", blockName: undefined, id: undefined };
}

export const GetSecretBlockRequest = {
  $type: "pb.v1alpha1.GetSecretBlockRequest" as const,

  encode(message: GetSecretBlockRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orgId !== undefined && message.orgId !== "") {
      writer.uint32(10).string(message.orgId);
    }
    if (message.blockName !== undefined) {
      writer.uint32(18).string(message.blockName);
    }
    if (message.id !== undefined) {
      writer.uint32(26).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSecretBlockRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSecretBlockRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.blockName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSecretBlockRequest {
    return {
      $type: GetSecretBlockRequest.$type,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      blockName: isSet(object.blockName) ? globalThis.String(object.blockName) : undefined,
      id: isSet(object.id) ? globalThis.String(object.id) : undefined,
    };
  },

  toJSON(message: GetSecretBlockRequest): unknown {
    const obj: any = {};
    if (message.orgId !== undefined && message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.blockName !== undefined) {
      obj.blockName = message.blockName;
    }
    if (message.id !== undefined) {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSecretBlockRequest>, I>>(base?: I): GetSecretBlockRequest {
    return GetSecretBlockRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSecretBlockRequest>, I>>(object: I): GetSecretBlockRequest {
    const message = createBaseGetSecretBlockRequest();
    message.orgId = object.orgId ?? "";
    message.blockName = object.blockName ?? undefined;
    message.id = object.id ?? undefined;
    return message;
  },
};

messageTypeRegistry.set(GetSecretBlockRequest.$type, GetSecretBlockRequest);

function createBaseGetSecretBlockResponse(): GetSecretBlockResponse {
  return { $type: "pb.v1alpha1.GetSecretBlockResponse", secretBlock: undefined };
}

export const GetSecretBlockResponse = {
  $type: "pb.v1alpha1.GetSecretBlockResponse" as const,

  encode(message: GetSecretBlockResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.secretBlock !== undefined) {
      SecretBlock.encode(message.secretBlock, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSecretBlockResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSecretBlockResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.secretBlock = SecretBlock.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSecretBlockResponse {
    return {
      $type: GetSecretBlockResponse.$type,
      secretBlock: isSet(object.secretBlock) ? SecretBlock.fromJSON(object.secretBlock) : undefined,
    };
  },

  toJSON(message: GetSecretBlockResponse): unknown {
    const obj: any = {};
    if (message.secretBlock !== undefined) {
      obj.secretBlock = SecretBlock.toJSON(message.secretBlock);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSecretBlockResponse>, I>>(base?: I): GetSecretBlockResponse {
    return GetSecretBlockResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSecretBlockResponse>, I>>(object: I): GetSecretBlockResponse {
    const message = createBaseGetSecretBlockResponse();
    message.secretBlock = (object.secretBlock !== undefined && object.secretBlock !== null)
      ? SecretBlock.fromPartial(object.secretBlock)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(GetSecretBlockResponse.$type, GetSecretBlockResponse);

function createBaseUpdateSecretBlockRequest(): UpdateSecretBlockRequest {
  return {
    $type: "pb.v1alpha1.UpdateSecretBlockRequest",
    orgId: "",
    blockName: undefined,
    id: undefined,
    secrets: [],
    updateDefaultValue: false,
  };
}

export const UpdateSecretBlockRequest = {
  $type: "pb.v1alpha1.UpdateSecretBlockRequest" as const,

  encode(message: UpdateSecretBlockRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orgId !== undefined && message.orgId !== "") {
      writer.uint32(10).string(message.orgId);
    }
    if (message.blockName !== undefined) {
      writer.uint32(18).string(message.blockName);
    }
    if (message.id !== undefined) {
      writer.uint32(26).string(message.id);
    }
    if (message.secrets !== undefined && message.secrets.length !== 0) {
      for (const v of message.secrets) {
        Secret.encode(v!, writer.uint32(34).fork()).ldelim();
      }
    }
    if (message.updateDefaultValue !== undefined && message.updateDefaultValue !== false) {
      writer.uint32(40).bool(message.updateDefaultValue);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateSecretBlockRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateSecretBlockRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.blockName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.id = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.secrets!.push(Secret.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.updateDefaultValue = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateSecretBlockRequest {
    return {
      $type: UpdateSecretBlockRequest.$type,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      blockName: isSet(object.blockName) ? globalThis.String(object.blockName) : undefined,
      id: isSet(object.id) ? globalThis.String(object.id) : undefined,
      secrets: globalThis.Array.isArray(object?.secrets) ? object.secrets.map((e: any) => Secret.fromJSON(e)) : [],
      updateDefaultValue: isSet(object.updateDefaultValue) ? globalThis.Boolean(object.updateDefaultValue) : false,
    };
  },

  toJSON(message: UpdateSecretBlockRequest): unknown {
    const obj: any = {};
    if (message.orgId !== undefined && message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.blockName !== undefined) {
      obj.blockName = message.blockName;
    }
    if (message.id !== undefined) {
      obj.id = message.id;
    }
    if (message.secrets?.length) {
      obj.secrets = message.secrets.map((e) => Secret.toJSON(e));
    }
    if (message.updateDefaultValue !== undefined && message.updateDefaultValue !== false) {
      obj.updateDefaultValue = message.updateDefaultValue;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateSecretBlockRequest>, I>>(base?: I): UpdateSecretBlockRequest {
    return UpdateSecretBlockRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateSecretBlockRequest>, I>>(object: I): UpdateSecretBlockRequest {
    const message = createBaseUpdateSecretBlockRequest();
    message.orgId = object.orgId ?? "";
    message.blockName = object.blockName ?? undefined;
    message.id = object.id ?? undefined;
    message.secrets = object.secrets?.map((e) => Secret.fromPartial(e)) || [];
    message.updateDefaultValue = object.updateDefaultValue ?? false;
    return message;
  },
};

messageTypeRegistry.set(UpdateSecretBlockRequest.$type, UpdateSecretBlockRequest);

function createBaseUpdateSecretBlockResponse(): UpdateSecretBlockResponse {
  return { $type: "pb.v1alpha1.UpdateSecretBlockResponse", secretBlock: undefined };
}

export const UpdateSecretBlockResponse = {
  $type: "pb.v1alpha1.UpdateSecretBlockResponse" as const,

  encode(message: UpdateSecretBlockResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.secretBlock !== undefined) {
      SecretBlock.encode(message.secretBlock, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateSecretBlockResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateSecretBlockResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.secretBlock = SecretBlock.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateSecretBlockResponse {
    return {
      $type: UpdateSecretBlockResponse.$type,
      secretBlock: isSet(object.secretBlock) ? SecretBlock.fromJSON(object.secretBlock) : undefined,
    };
  },

  toJSON(message: UpdateSecretBlockResponse): unknown {
    const obj: any = {};
    if (message.secretBlock !== undefined) {
      obj.secretBlock = SecretBlock.toJSON(message.secretBlock);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateSecretBlockResponse>, I>>(base?: I): UpdateSecretBlockResponse {
    return UpdateSecretBlockResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateSecretBlockResponse>, I>>(object: I): UpdateSecretBlockResponse {
    const message = createBaseUpdateSecretBlockResponse();
    message.secretBlock = (object.secretBlock !== undefined && object.secretBlock !== null)
      ? SecretBlock.fromPartial(object.secretBlock)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(UpdateSecretBlockResponse.$type, UpdateSecretBlockResponse);

function createBaseDeleteSecretBlockRequest(): DeleteSecretBlockRequest {
  return { $type: "pb.v1alpha1.DeleteSecretBlockRequest", orgId: "", blockName: undefined, id: undefined };
}

export const DeleteSecretBlockRequest = {
  $type: "pb.v1alpha1.DeleteSecretBlockRequest" as const,

  encode(message: DeleteSecretBlockRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orgId !== undefined && message.orgId !== "") {
      writer.uint32(10).string(message.orgId);
    }
    if (message.blockName !== undefined) {
      writer.uint32(18).string(message.blockName);
    }
    if (message.id !== undefined) {
      writer.uint32(26).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteSecretBlockRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteSecretBlockRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.blockName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteSecretBlockRequest {
    return {
      $type: DeleteSecretBlockRequest.$type,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      blockName: isSet(object.blockName) ? globalThis.String(object.blockName) : undefined,
      id: isSet(object.id) ? globalThis.String(object.id) : undefined,
    };
  },

  toJSON(message: DeleteSecretBlockRequest): unknown {
    const obj: any = {};
    if (message.orgId !== undefined && message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.blockName !== undefined) {
      obj.blockName = message.blockName;
    }
    if (message.id !== undefined) {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteSecretBlockRequest>, I>>(base?: I): DeleteSecretBlockRequest {
    return DeleteSecretBlockRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteSecretBlockRequest>, I>>(object: I): DeleteSecretBlockRequest {
    const message = createBaseDeleteSecretBlockRequest();
    message.orgId = object.orgId ?? "";
    message.blockName = object.blockName ?? undefined;
    message.id = object.id ?? undefined;
    return message;
  },
};

messageTypeRegistry.set(DeleteSecretBlockRequest.$type, DeleteSecretBlockRequest);

function createBaseDeleteSecretBlockResponse(): DeleteSecretBlockResponse {
  return { $type: "pb.v1alpha1.DeleteSecretBlockResponse" };
}

export const DeleteSecretBlockResponse = {
  $type: "pb.v1alpha1.DeleteSecretBlockResponse" as const,

  encode(_: DeleteSecretBlockResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteSecretBlockResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteSecretBlockResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): DeleteSecretBlockResponse {
    return { $type: DeleteSecretBlockResponse.$type };
  },

  toJSON(_: DeleteSecretBlockResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteSecretBlockResponse>, I>>(base?: I): DeleteSecretBlockResponse {
    return DeleteSecretBlockResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteSecretBlockResponse>, I>>(_: I): DeleteSecretBlockResponse {
    const message = createBaseDeleteSecretBlockResponse();
    return message;
  },
};

messageTypeRegistry.set(DeleteSecretBlockResponse.$type, DeleteSecretBlockResponse);

function createBaseListSecretBlocksRequest(): ListSecretBlocksRequest {
  return { $type: "pb.v1alpha1.ListSecretBlocksRequest", orgId: "" };
}

export const ListSecretBlocksRequest = {
  $type: "pb.v1alpha1.ListSecretBlocksRequest" as const,

  encode(message: ListSecretBlocksRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orgId !== undefined && message.orgId !== "") {
      writer.uint32(10).string(message.orgId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListSecretBlocksRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListSecretBlocksRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orgId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListSecretBlocksRequest {
    return { $type: ListSecretBlocksRequest.$type, orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "" };
  },

  toJSON(message: ListSecretBlocksRequest): unknown {
    const obj: any = {};
    if (message.orgId !== undefined && message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListSecretBlocksRequest>, I>>(base?: I): ListSecretBlocksRequest {
    return ListSecretBlocksRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListSecretBlocksRequest>, I>>(object: I): ListSecretBlocksRequest {
    const message = createBaseListSecretBlocksRequest();
    message.orgId = object.orgId ?? "";
    return message;
  },
};

messageTypeRegistry.set(ListSecretBlocksRequest.$type, ListSecretBlocksRequest);

function createBaseListSecretBlocksResponse(): ListSecretBlocksResponse {
  return { $type: "pb.v1alpha1.ListSecretBlocksResponse", secretBlocks: [] };
}

export const ListSecretBlocksResponse = {
  $type: "pb.v1alpha1.ListSecretBlocksResponse" as const,

  encode(message: ListSecretBlocksResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.secretBlocks !== undefined && message.secretBlocks.length !== 0) {
      for (const v of message.secretBlocks) {
        SecretBlock.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListSecretBlocksResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListSecretBlocksResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.secretBlocks!.push(SecretBlock.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListSecretBlocksResponse {
    return {
      $type: ListSecretBlocksResponse.$type,
      secretBlocks: globalThis.Array.isArray(object?.secretBlocks)
        ? object.secretBlocks.map((e: any) => SecretBlock.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListSecretBlocksResponse): unknown {
    const obj: any = {};
    if (message.secretBlocks?.length) {
      obj.secretBlocks = message.secretBlocks.map((e) => SecretBlock.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListSecretBlocksResponse>, I>>(base?: I): ListSecretBlocksResponse {
    return ListSecretBlocksResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListSecretBlocksResponse>, I>>(object: I): ListSecretBlocksResponse {
    const message = createBaseListSecretBlocksResponse();
    message.secretBlocks = object.secretBlocks?.map((e) => SecretBlock.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(ListSecretBlocksResponse.$type, ListSecretBlocksResponse);

function createBaseGetSecretRequest(): GetSecretRequest {
  return {
    $type: "pb.v1alpha1.GetSecretRequest",
    orgId: "",
    id: "",
    secretId: undefined,
    secretValue: undefined,
    returnPublicValue: false,
  };
}

export const GetSecretRequest = {
  $type: "pb.v1alpha1.GetSecretRequest" as const,

  encode(message: GetSecretRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orgId !== undefined && message.orgId !== "") {
      writer.uint32(10).string(message.orgId);
    }
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(18).string(message.id);
    }
    if (message.secretId !== undefined) {
      writer.uint32(34).string(message.secretId);
    }
    if (message.secretValue !== undefined) {
      SecretValue.encode(message.secretValue, writer.uint32(42).fork()).ldelim();
    }
    if (message.returnPublicValue !== undefined && message.returnPublicValue !== false) {
      writer.uint32(24).bool(message.returnPublicValue);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSecretRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSecretRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.secretId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.secretValue = SecretValue.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.returnPublicValue = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSecretRequest {
    return {
      $type: GetSecretRequest.$type,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      secretId: isSet(object.secretId) ? globalThis.String(object.secretId) : undefined,
      secretValue: isSet(object.secretValue) ? SecretValue.fromJSON(object.secretValue) : undefined,
      returnPublicValue: isSet(object.returnPublicValue) ? globalThis.Boolean(object.returnPublicValue) : false,
    };
  },

  toJSON(message: GetSecretRequest): unknown {
    const obj: any = {};
    if (message.orgId !== undefined && message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    if (message.secretId !== undefined) {
      obj.secretId = message.secretId;
    }
    if (message.secretValue !== undefined) {
      obj.secretValue = SecretValue.toJSON(message.secretValue);
    }
    if (message.returnPublicValue !== undefined && message.returnPublicValue !== false) {
      obj.returnPublicValue = message.returnPublicValue;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSecretRequest>, I>>(base?: I): GetSecretRequest {
    return GetSecretRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSecretRequest>, I>>(object: I): GetSecretRequest {
    const message = createBaseGetSecretRequest();
    message.orgId = object.orgId ?? "";
    message.id = object.id ?? "";
    message.secretId = object.secretId ?? undefined;
    message.secretValue = (object.secretValue !== undefined && object.secretValue !== null)
      ? SecretValue.fromPartial(object.secretValue)
      : undefined;
    message.returnPublicValue = object.returnPublicValue ?? false;
    return message;
  },
};

messageTypeRegistry.set(GetSecretRequest.$type, GetSecretRequest);

function createBaseGetSecretResponse(): GetSecretResponse {
  return { $type: "pb.v1alpha1.GetSecretResponse", secret: undefined };
}

export const GetSecretResponse = {
  $type: "pb.v1alpha1.GetSecretResponse" as const,

  encode(message: GetSecretResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.secret !== undefined) {
      Secret.encode(message.secret, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSecretResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSecretResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.secret = Secret.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSecretResponse {
    return {
      $type: GetSecretResponse.$type,
      secret: isSet(object.secret) ? Secret.fromJSON(object.secret) : undefined,
    };
  },

  toJSON(message: GetSecretResponse): unknown {
    const obj: any = {};
    if (message.secret !== undefined) {
      obj.secret = Secret.toJSON(message.secret);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSecretResponse>, I>>(base?: I): GetSecretResponse {
    return GetSecretResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSecretResponse>, I>>(object: I): GetSecretResponse {
    const message = createBaseGetSecretResponse();
    message.secret = (object.secret !== undefined && object.secret !== null)
      ? Secret.fromPartial(object.secret)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(GetSecretResponse.$type, GetSecretResponse);

function createBaseUpdateSecretRequest(): UpdateSecretRequest {
  return { $type: "pb.v1alpha1.UpdateSecretRequest", orgId: "", id: "", value: "" };
}

export const UpdateSecretRequest = {
  $type: "pb.v1alpha1.UpdateSecretRequest" as const,

  encode(message: UpdateSecretRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orgId !== undefined && message.orgId !== "") {
      writer.uint32(10).string(message.orgId);
    }
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(18).string(message.id);
    }
    if (message.value !== undefined && message.value !== "") {
      writer.uint32(26).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateSecretRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateSecretRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateSecretRequest {
    return {
      $type: UpdateSecretRequest.$type,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: UpdateSecretRequest): unknown {
    const obj: any = {};
    if (message.orgId !== undefined && message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    if (message.value !== undefined && message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateSecretRequest>, I>>(base?: I): UpdateSecretRequest {
    return UpdateSecretRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateSecretRequest>, I>>(object: I): UpdateSecretRequest {
    const message = createBaseUpdateSecretRequest();
    message.orgId = object.orgId ?? "";
    message.id = object.id ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

messageTypeRegistry.set(UpdateSecretRequest.$type, UpdateSecretRequest);

function createBaseUpdateSecretResponse(): UpdateSecretResponse {
  return { $type: "pb.v1alpha1.UpdateSecretResponse", id: "" };
}

export const UpdateSecretResponse = {
  $type: "pb.v1alpha1.UpdateSecretResponse" as const,

  encode(message: UpdateSecretResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateSecretResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateSecretResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateSecretResponse {
    return { $type: UpdateSecretResponse.$type, id: isSet(object.id) ? globalThis.String(object.id) : "" };
  },

  toJSON(message: UpdateSecretResponse): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateSecretResponse>, I>>(base?: I): UpdateSecretResponse {
    return UpdateSecretResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateSecretResponse>, I>>(object: I): UpdateSecretResponse {
    const message = createBaseUpdateSecretResponse();
    message.id = object.id ?? "";
    return message;
  },
};

messageTypeRegistry.set(UpdateSecretResponse.$type, UpdateSecretResponse);

function createBaseDeleteSecretRequest(): DeleteSecretRequest {
  return { $type: "pb.v1alpha1.DeleteSecretRequest", orgId: "", id: "" };
}

export const DeleteSecretRequest = {
  $type: "pb.v1alpha1.DeleteSecretRequest" as const,

  encode(message: DeleteSecretRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orgId !== undefined && message.orgId !== "") {
      writer.uint32(10).string(message.orgId);
    }
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(18).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteSecretRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteSecretRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteSecretRequest {
    return {
      $type: DeleteSecretRequest.$type,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      id: isSet(object.id) ? globalThis.String(object.id) : "",
    };
  },

  toJSON(message: DeleteSecretRequest): unknown {
    const obj: any = {};
    if (message.orgId !== undefined && message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteSecretRequest>, I>>(base?: I): DeleteSecretRequest {
    return DeleteSecretRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteSecretRequest>, I>>(object: I): DeleteSecretRequest {
    const message = createBaseDeleteSecretRequest();
    message.orgId = object.orgId ?? "";
    message.id = object.id ?? "";
    return message;
  },
};

messageTypeRegistry.set(DeleteSecretRequest.$type, DeleteSecretRequest);

/** Provides endpoints to manage customer-defined secrets. See go/secret-manager. */
export interface SecretManager {
  CreateSecret(request: DeepPartial<CreateSecretRequest>, metadata?: grpc.Metadata): Promise<CreateSecretResponse>;
  GetSecret(request: DeepPartial<GetSecretRequest>, metadata?: grpc.Metadata): Promise<GetSecretResponse>;
  UpdateSecret(request: DeepPartial<UpdateSecretRequest>, metadata?: grpc.Metadata): Promise<UpdateSecretResponse>;
  DeleteSecret(request: DeepPartial<DeleteSecretRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /** Secret Block APIs */
  CreateSecretBlock(
    request: DeepPartial<CreateSecretBlockRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateSecretBlockResponse>;
  GetSecretBlock(
    request: DeepPartial<GetSecretBlockRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetSecretBlockResponse>;
  UpdateSecretBlock(
    request: DeepPartial<UpdateSecretBlockRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateSecretBlockResponse>;
  DeleteSecretBlock(request: DeepPartial<DeleteSecretBlockRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  ListSecretBlocks(
    request: DeepPartial<ListSecretBlocksRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListSecretBlocksResponse>;
}

export class SecretManagerClientImpl implements SecretManager {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.CreateSecret = this.CreateSecret.bind(this);
    this.GetSecret = this.GetSecret.bind(this);
    this.UpdateSecret = this.UpdateSecret.bind(this);
    this.DeleteSecret = this.DeleteSecret.bind(this);
    this.CreateSecretBlock = this.CreateSecretBlock.bind(this);
    this.GetSecretBlock = this.GetSecretBlock.bind(this);
    this.UpdateSecretBlock = this.UpdateSecretBlock.bind(this);
    this.DeleteSecretBlock = this.DeleteSecretBlock.bind(this);
    this.ListSecretBlocks = this.ListSecretBlocks.bind(this);
  }

  CreateSecret(request: DeepPartial<CreateSecretRequest>, metadata?: grpc.Metadata): Promise<CreateSecretResponse> {
    return this.rpc.unary(SecretManagerCreateSecretDesc, CreateSecretRequest.fromPartial(request), metadata);
  }

  GetSecret(request: DeepPartial<GetSecretRequest>, metadata?: grpc.Metadata): Promise<GetSecretResponse> {
    return this.rpc.unary(SecretManagerGetSecretDesc, GetSecretRequest.fromPartial(request), metadata);
  }

  UpdateSecret(request: DeepPartial<UpdateSecretRequest>, metadata?: grpc.Metadata): Promise<UpdateSecretResponse> {
    return this.rpc.unary(SecretManagerUpdateSecretDesc, UpdateSecretRequest.fromPartial(request), metadata);
  }

  DeleteSecret(request: DeepPartial<DeleteSecretRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(SecretManagerDeleteSecretDesc, DeleteSecretRequest.fromPartial(request), metadata);
  }

  CreateSecretBlock(
    request: DeepPartial<CreateSecretBlockRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateSecretBlockResponse> {
    return this.rpc.unary(SecretManagerCreateSecretBlockDesc, CreateSecretBlockRequest.fromPartial(request), metadata);
  }

  GetSecretBlock(
    request: DeepPartial<GetSecretBlockRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetSecretBlockResponse> {
    return this.rpc.unary(SecretManagerGetSecretBlockDesc, GetSecretBlockRequest.fromPartial(request), metadata);
  }

  UpdateSecretBlock(
    request: DeepPartial<UpdateSecretBlockRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateSecretBlockResponse> {
    return this.rpc.unary(SecretManagerUpdateSecretBlockDesc, UpdateSecretBlockRequest.fromPartial(request), metadata);
  }

  DeleteSecretBlock(request: DeepPartial<DeleteSecretBlockRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(SecretManagerDeleteSecretBlockDesc, DeleteSecretBlockRequest.fromPartial(request), metadata);
  }

  ListSecretBlocks(
    request: DeepPartial<ListSecretBlocksRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListSecretBlocksResponse> {
    return this.rpc.unary(SecretManagerListSecretBlocksDesc, ListSecretBlocksRequest.fromPartial(request), metadata);
  }
}

export const SecretManagerDesc = { serviceName: "pb.v1alpha1.SecretManager" };

export const SecretManagerCreateSecretDesc: UnaryMethodDefinitionish = {
  methodName: "CreateSecret",
  service: SecretManagerDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateSecretRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CreateSecretResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SecretManagerGetSecretDesc: UnaryMethodDefinitionish = {
  methodName: "GetSecret",
  service: SecretManagerDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetSecretRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetSecretResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SecretManagerUpdateSecretDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateSecret",
  service: SecretManagerDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateSecretRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateSecretResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SecretManagerDeleteSecretDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteSecret",
  service: SecretManagerDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteSecretRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SecretManagerCreateSecretBlockDesc: UnaryMethodDefinitionish = {
  methodName: "CreateSecretBlock",
  service: SecretManagerDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateSecretBlockRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CreateSecretBlockResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SecretManagerGetSecretBlockDesc: UnaryMethodDefinitionish = {
  methodName: "GetSecretBlock",
  service: SecretManagerDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetSecretBlockRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetSecretBlockResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SecretManagerUpdateSecretBlockDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateSecretBlock",
  service: SecretManagerDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateSecretBlockRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateSecretBlockResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SecretManagerDeleteSecretBlockDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteSecretBlock",
  service: SecretManagerDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteSecretBlockRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SecretManagerListSecretBlocksDesc: UnaryMethodDefinitionish = {
  methodName: "ListSecretBlocks",
  service: SecretManagerDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListSecretBlocksRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListSecretBlocksResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P> | "$type">]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { $type: "google.protobuf.Timestamp", seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
