import { call, put, takeLatest } from 'redux-saga/effects';
import {
  processSmartActions,
  setProcessSmartActionsError,
  setProcessSmartActionsLoading,
  setProcessSmartActionsResponse,
} from '../slices/smart_actions.slice';
import { orbotService } from '../../services/OrbotService';
import { ProcessSmartActionsRequest } from 'protos/pb/v1alpha1/actionprocessing';
import { DataLoadingStatus } from '../../utils/constants';

export function* processSmartActionsSaga(data: {
  type: typeof processSmartActions.type;
  payload: ProcessSmartActionsRequest;
}): any {
  try {
    yield put(setProcessSmartActionsLoading(DataLoadingStatus.LOADING));

    const { response, error } = yield call(
      orbotService.processSmartActions,
      data.payload,
    );

    if (response) {
      yield put(setProcessSmartActionsResponse(response));
      yield put(setProcessSmartActionsLoading(DataLoadingStatus.LOADED));
    } else {
      yield put(setProcessSmartActionsError(error?.message || String(error)));
      yield put(setProcessSmartActionsLoading(DataLoadingStatus.ERROR));
    }
  } catch (error) {
    yield put(setProcessSmartActionsError(error?.message || String(error)));
    yield put(setProcessSmartActionsLoading(DataLoadingStatus.ERROR));
  }
}

export function* smartActionsSaga() {
  yield takeLatest(processSmartActions.type, processSmartActionsSaga);
}

export default smartActionsSaga;
