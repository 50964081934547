import { Workflow, WorkflowProcess } from 'protos/pb/v1alpha1/orbot_workflow';
import {
  Action,
  ActionParamValue,
  WorkflowVariable,
} from 'protos/pb/v1alpha1/orbot_action';
import { containBlockAction } from './action-classifier';
import { ResourceType, Variable } from 'protos/pb/v1alpha1/variables';

export const getWorkflowId = (workflow: Workflow) => {
  return workflow?.id || '';
};

export function getActions(
  workflow?: Workflow | null,
  processId?: string,
): Action[] | undefined {
  if (workflow?.processes && workflow.processes.length > 0) {
    // Default to first process if no process id is provided
    let process: WorkflowProcess | undefined = workflow.processes[0];
    if (processId) {
      process = workflow.processes.find((process) => process.id === processId);
    }
    return process?.actions;
  } else {
    throw new Error('No process found in workflow');
  }
}

export function getErrorHandlingProcessIdIfExists(
  workflow?: Workflow | null,
  processId?: string,
): string | undefined {
  if (workflow?.processes && workflow.processes.length > 0) {
    // Default to first process if no process id is provided
    let process: WorkflowProcess | undefined = workflow.processes[0];
    if (processId) {
      process = workflow.processes.find((process) => process.id === processId);
    }
    return process?.errorHandlingProcessId;
  } else {
    throw new Error('No process found in workflow');
  }
}

export const allowAddingElseBlock = (action: Action): boolean => {
  // if there is any conditionAction that has thenActions but no elseActions, we will allow the user to add else block
  if (
    (action?.condition?.thenActions ?? []).length > 0 &&
    (action?.condition?.elseActions ?? []).length === 0
  ) {
    return true;
  }
  return false;
};

export function getActionById(
  id: string,
  actions?: Action[],
): Action | undefined {
  if (!actions || actions.length === 0) {
    return undefined;
  }

  let targetAction: Action | undefined;
  traverseActionsRecursively(actions, (action) => {
    if (action.id === id) {
      targetAction = action;
    }
  });

  return targetAction;
}

export type ActionOutputVariable = {
  actionId: string;
  variable: Variable;
};

export type EnvVariable = Variable;

type FilterType = 'Boolean' | 'Array' | 'Document' | 'String';

export function getWorkflowOutputVariables(
  workflow: Workflow | null,
  beforeActionId?: string,
): ActionOutputVariable[] {
  if (!workflow) {
    return [];
  }

  let shouldStop = false;
  const outputVariables: ActionOutputVariable[] = [];
  workflow.processes?.forEach((process) => {
    if (shouldStop) {
      return;
    }

    const actions = getActions(workflow, process.id);

    traverseActionsRecursively(actions, (action) => {
      // TODO: beforeActionId need to handle actions with conditional branches.
      // if the action is in the false branch, we'll traversing the true branch first and return all variables there.
      if (beforeActionId && action.id === beforeActionId) {
        shouldStop = true;
        return;
      }

      if (shouldStop) {
        return;
      }

      let outputVariable: Variable | undefined = action.outputVariable;

      if (beforeActionId && action.foreach?.items) {
        outputVariable = getLoopVariable(action, beforeActionId, actions ?? []);
      }

      if (outputVariable) {
        outputVariables.push({
          actionId: action.id!,
          variable: outputVariable,
        });
      }
    });
  });

  return outputVariables;
}

export function getWorkflowEnvVariables(workflow: Workflow | null): Variable[] {
  if (!workflow) {
    return [];
  }

  return workflow.environmentVariables ?? [];
}

/**
 * Retrieves the loop variable for a child action that's inside a loop action.
 *
 * @param loopAction - A foreach action
 * @param childActionId - The ID of the child action to check
 * @param actions - Array of all available actions
 * @returns A Variable object representing the loop iteration variable, or undefined if the child is not part of the loop
 */
function getLoopVariable(
  loopAction: Action,
  childActionId: string,
  actions: Action[],
): Variable | undefined {
  const loopDecedentActions = getAllDecedentActions([loopAction]);
  if (loopDecedentActions.some((action) => action.id === childActionId)) {
    const loopVariable = getLoopVariableFromParamValue(
      loopAction.foreach!.items!,
      actions ?? [],
    );
    return {
      ...loopVariable,
      name: `Iteration of ${loopAction.description}`,
    };
  }
  return undefined;
}

function getLoopVariableFromParamValue(
  paramValue: ActionParamValue,
  actions: Action[],
) {
  let loopVariable: Variable | undefined;
  if (paramValue.referenceValue) {
    const loopInputAction = getActionById(paramValue.referenceValue, actions);
    loopVariable = loopInputAction?.outputVariable;
  }
  if (paramValue.partialReferenceValue?.referenceValue) {
    const loopInputAction = getActionById(
      paramValue.partialReferenceValue.referenceValue,
      actions,
    );
    loopVariable = {
      name: `${loopInputAction?.outputVariable?.name}[${paramValue.partialReferenceValue.referenceValueKey}]`,
      type: loopInputAction?.outputVariable?.type?.record?.fields?.[
        paramValue.partialReferenceValue.referenceValueKey!
      ],
      value:
        loopInputAction?.outputVariable?.value?.record?.fields?.[
          paramValue.partialReferenceValue.referenceValueKey!
        ],
    };
  }

  if (loopVariable && loopVariable.type?.array) {
    return {
      name: loopVariable.name,
      type: loopVariable.type?.array?.entryType,
      value: loopVariable.value?.array?.entries?.[0],
    };
  }

  return undefined;
}

export function getWorkflowVariables(
  workflow: Workflow | null,
  processId?: string,
): WorkflowVariable[] {
  const workflowVariables: WorkflowVariable[] = [];
  if (!workflow) {
    return workflowVariables;
  }

  traverseActionsRecursively(
    getActions(workflow, processId) || [],
    (action) => {
      getParams(action).forEach((param) => {
        if (param?.envValue) {
          workflowVariables.push({ key: param.envValue, value: undefined });
        }
      });
    },
  );
  return workflowVariables;
}

export function traverseActionsRecursively(
  actions: ReadonlyArray<Action> | undefined,
  callback: (action: Action) => void,
): void {
  if (!actions || !actions.length) return;
  actions.forEach((action) => {
    if (action.prerequisites) {
      traverseActionsRecursively(
        action.prerequisites.map((prerequisite) => prerequisite.action!),
        callback,
      );
    }
    callback(action);
    if (action.block) {
      traverseActionsRecursively(action.block.actions, callback);
    } else if (action.condition) {
      traverseActionsRecursively(action.condition.thenActions, callback);
      traverseActionsRecursively(action.condition?.elseActions, callback);
    } else if (action.foreach) {
      traverseActionsRecursively(action.foreach.loopActions, callback);
    }
  });
}

function getParams(action: Action): ActionParamValue[] {
  if (action.click?.locator) {
    return [action.click.locator];
  }
  if (action.getElement?.elementLocator) {
    return [action.getElement.elementLocator];
  }
  if (action.hover?.locator) {
    return [action.hover.locator];
  }
  if (action.condition?.condition) {
    return [action.condition.condition];
  }
  if (action.foreach?.items) {
    return [action.foreach.items];
  }
  if (action.createTask?.workflowVariables) {
    return action.createTask.workflowVariables;
  }
  if (action.customSmartAction?.inputs) {
    return Object.values(action.customSmartAction.inputs);
  }
  if (action.detectDuplicateLineItems?.source) {
    return [action.detectDuplicateLineItems.source];
  }
  if (action.extractFields?.document) {
    return [action.extractFields.document];
  }
  if (action.flagKeywords?.source) {
    return [action.flagKeywords.source];
  }
  if (action.generateText?.inputs) {
    return action.generateText.inputs;
  }
  if (action.goto?.url) {
    return [action.goto.url];
  }
  if (action.jsFunction?.params) {
    return action.jsFunction.params;
  }
  if (action.setValue?.fieldLocator) {
    return [action.setValue.fieldLocator!, action.setValue.fieldValue!];
  }
  if (action.validate?.source) {
    return [action.validate.source!, action.validate.target!];
  }
  if (action.macro?.generic?.instructionVariables) {
    return action.macro.generic.instructionVariables;
  }

  return [];
}

export function getCreateTaskActionUUIDs(
  workflow: Workflow,
  processId?: string,
): string[] {
  const uuids: string[] = [];
  traverseActionsRecursively(getActions(workflow, processId), (action) => {
    if (action.createTask) {
      if (action.id) {
        uuids.push(action.id);
      }
    }
  });
  return uuids;
}

export function addActionAfter(
  newAction: Action,
  id: string,
  actions?: Action[],
): boolean {
  if (!actions || actions.length === 0) {
    return false;
  }
  for (const [index, action] of actions.entries()) {
    if (action.id === id) {
      actions.splice(index + 1, 0, newAction);
      return true;
    }
    if (action.condition) {
      if (
        addActionAfter(newAction, id, action.condition.thenActions ?? []) ||
        addActionAfter(newAction, id, action.condition.elseActions ?? [])
      ) {
        return true;
      }
    }
    if (action.foreach) {
      if (addActionAfter(newAction, id, action.foreach.loopActions ?? [])) {
        return true;
      }
    }
  }
  return false;
}

export function removeAction(
  uuid: string,
  actions: Action[],
): Action | undefined {
  if (!actions || actions.length === 0) {
    return undefined;
  }

  for (const [index, action] of actions.entries()) {
    if (action.id === uuid) {
      actions.splice(index, 1);
      return action;
    }
    if (action.condition) {
      const removedActionGroup =
        removeAction(uuid, action.condition.thenActions ?? []) ??
        removeAction(uuid, action.condition.elseActions ?? []);
      if (removedActionGroup) {
        return removedActionGroup;
      }
    }
    if (action.foreach) {
      const removedAction = removeAction(
        uuid,
        action.foreach.loopActions ?? [],
      );
      if (removedAction) {
        return removedAction;
      }
    }
    if (containBlockAction(action)) {
      const removedAction = removeAction(uuid, action.block?.actions ?? []);
      if (removedAction) {
        return removedAction;
      }
    }
  }
}

export function getAllDecedentActions(actionItems: Action[] | undefined) {
  const actions: Action[] = [];
  traverseActionsRecursively(actionItems, (action: Action) => {
    actions.push(action as Action);
  });
  return actions;
}

export interface WorkflowVariableOption extends Variable {
  variableCategory: string;
  actionId?: string;
  // Set if the variable is a record field
  recordFieldParent?: WorkflowVariableOption;
}

export const variableCategory = {
  WORKFLOW_VARIABLE: 'Workflow variable',
  CONSTANT: 'Constants',
};

export function listVariableOptions(
  workflow: Workflow | null,
  filterType?: FilterType,
  beforeActionId?: string,
): WorkflowVariableOption[] {
  const outputVariables = getWorkflowOutputVariables(workflow, beforeActionId);

  const envVariables = getWorkflowEnvVariables(workflow);

  return prepareWorkflowVariablesToDisplay(
    outputVariables,
    envVariables,
    filterType,
  );
}

function prepareWorkflowVariablesToDisplay(
  workflowVariables: ActionOutputVariable[],
  workflowEnvVariables: Variable[],
  filterType?: FilterType,
): WorkflowVariableOption[] {
  const variables = workflowVariables.map((workflowVariable) => {
    return {
      variableCategory: variableCategory.WORKFLOW_VARIABLE,
      ...workflowVariable.variable,
      actionId: workflowVariable.actionId,
    };
  });

  const constants = workflowEnvVariables.map((variable) => ({
    variableCategory: variableCategory.CONSTANT,
    ...variable,
  }));

  const candidates = [...variables, ...constants];
  return candidates.flatMap((candidate) =>
    expandAndFilterWorkflowVariableOption(candidate, filterType),
  );
}

/**
 * Processes a workflow variable option to display in the dropdown.
 * For simple variable and array variables, we apply the filter if provided.
 * For record variables, we expand the record fields and apply the filter to each field.
 */
function expandAndFilterWorkflowVariableOption(
  option: WorkflowVariableOption,
  filterType?: FilterType,
): WorkflowVariableOption[] {
  if (!filterType || !option.type) {
    return [option];
  }

  const variableType = option.type;

  if (variableType.record?.fields) {
    return Object.entries(variableType.record.fields).flatMap(
      ([key, variableType]) => {
        const variableValue = option.value?.record?.fields?.[key];
        return expandAndFilterWorkflowVariableOption(
          {
            name: key,
            type: variableType,
            value: variableValue,
            variableCategory: option.variableCategory,
            actionId: option.actionId,
            recordFieldParent: option,
          },
          filterType,
        );
      },
    );
  }

  const isArray = filterType === 'Array' && variableType.array;
  const isBool = filterType === 'Boolean' && variableType.boolean;
  const isDocument =
    filterType === 'Document' &&
    variableType.resource === ResourceType.DOCUMENT;
  const isString = filterType === 'String' && variableType.text;
  const isMatchingType = isArray || isBool || isDocument || isString;

  return isMatchingType ? [option] : [];
}

export function getInputVariablesForAction(
  action: Action,
  allVariables: WorkflowVariableOption[],
): WorkflowVariableOption[] {
  let params: ActionParamValue[] = [];

  if (action?.foreach?.items) {
    params = [action.foreach.items];
  } else if (action.extractFields?.document) {
    params = [action.extractFields.document];
  } else if (action?.jsFunction?.params) {
    params = action.jsFunction.params;
  } else if (action?.condition?.condition) {
    params = [action.condition.condition];
  } else if (action?.goto?.url) {
    params = [action.goto.url];
  } else if (action?.setValue?.fieldValue) {
    params = [action.setValue.fieldValue];
  }

  return params
    .map((param) => getVariableOptionForParam(param, allVariables))
    .filter((val): val is WorkflowVariableOption => val !== undefined);
}

function getVariableOptionForParam(
  param: ActionParamValue,
  variableOption: WorkflowVariableOption[],
): WorkflowVariableOption | undefined {
  if (param.referenceValue) {
    return variableOption.find(
      (val) =>
        val.variableCategory === variableCategory.WORKFLOW_VARIABLE &&
        val.actionId === param.referenceValue,
    );
  }

  if (param.partialReferenceValue) {
    const parentVariable = variableOption.find(
      (val) =>
        val.variableCategory === variableCategory.WORKFLOW_VARIABLE &&
        val.actionId === param.partialReferenceValue?.referenceValue,
    );
    if (parentVariable) {
      return {
        ...parentVariable,
        name: param.partialReferenceValue.referenceValueKey,
        type: parentVariable.type?.record?.fields?.[
          param.partialReferenceValue.referenceValueKey!
        ],
        value:
          parentVariable.value?.record?.fields?.[
            param.partialReferenceValue.referenceValueKey!
          ],
        recordFieldParent: parentVariable,
      };
    }
  }

  if (param.envValue) {
    return variableOption.find(
      (val) =>
        val.variableCategory === variableCategory.CONSTANT &&
        val.name === param.envValue,
    );
  }
  return undefined;
}

export function getActionParamValueFromVariableOption(
  option?: WorkflowVariableOption,
): ActionParamValue | undefined {
  if (!option) {
    return undefined;
  }

  if (option.variableCategory === variableCategory.WORKFLOW_VARIABLE) {
    if (option.recordFieldParent) {
      return {
        partialReferenceValue: {
          referenceValue: option.recordFieldParent.actionId,
          referenceValueKey: option.name,
        },
      };
    }
    return {
      referenceValue: option.actionId,
    };
  }

  if (option.variableCategory === variableCategory.CONSTANT) {
    return {
      envValue: option.name,
    };
  }

  return undefined;
}
