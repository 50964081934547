import ObjectId from 'bson-objectid';
import { get, isEqual } from 'lodash';
import { SortField } from 'protos/common/data_query_params';
import { ObservationSession } from 'protos/pb/process_discovery/observation_session';
import { Action } from 'protos/pb/v1alpha1/orbot_action';
import {
  CreateProcessRequest,
  GenerateDocumentationRequest,
  GetProcessDefinitionRequest,
  GetProcessRequest,
  GetTraceRequest,
  GetTraceUsersRequest,
  ListProcessesRequest,
  ListTracesRequest,
  UpdateProcessRequest,
  UpdateTraceRequest,
  UserDefinedProcessStatus,
} from 'protos/pb/v1alpha2/process_discovery_service';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { orbotService } from '../../services/OrbotService';
import { processDiscoveryService } from '../../services/ProcessDiscoveryService';
import { DataLoadingStatus } from '../../utils/constants';
import { savedDraftProcessSelector } from '../selectors/draftProcessSelectors';
import {
  changeSelectedTraceIndexAction,
  createAutomatedWorkflowAction,
  createProcessAction,
  editSessionNameAction,
  generateDocumentationAction,
  getProcessAction,
  getProcessDefinitionAction,
  getTraceAction,
  getTraceUsers,
  listProcessesAction,
  listTracesAction,
  selectedTraceSelector,
  setCreateAutomatedWorkflowError,
  setCreateAutomatedWorkflowLoading,
  setCreateProcessError,
  setCreateProcessLoading,
  setDraftProcesses,
  setEditSessionNameError,
  setEditSessionNameLoading,
  setGenerateDocumentationError,
  setGenerateDocumentationLoading,
  setGetProcess,
  setGetProcessDefinitionError,
  setGetProcessDefinitionLoading,
  setGetProcessError,
  setGetProcessLoading,
  setGetTraceError,
  setGetTraceLoading,
  setListProcesses,
  setListProcessesError,
  setListProcessesLoading,
  setListTracesError,
  setListTracesLoading,
  setSelectedTraceIndex,
  setTotalTraces,
  setTotalUsers,
  setTraceActions,
  setTraceUsers,
  setTracesData,
  setUpdateProcessError,
  setUpdateProcessLoading,
  updateProcessAction,
} from '../slices/process-discovery.slice';

export function* listProcessesSaga(data: {
  type: typeof listProcessesAction.type;
  payload: ListProcessesRequest;
}): any {
  try {
    yield put(setListProcessesLoading(DataLoadingStatus.LOADING));

    const { response, error } = yield call(
      processDiscoveryService.listProcesses,
      data.payload,
    );
    if (response) {
      yield put(setListProcesses(response));
      yield put(setListProcessesLoading(DataLoadingStatus.LOADED));
    } else {
      yield put(setListProcessesError(error?.message || error));
      yield put(setListProcessesLoading(DataLoadingStatus.ERROR));
    }
  } catch (error) {
    yield put(setListProcessesError(error?.message || error));
    yield put(setListProcessesLoading(DataLoadingStatus.ERROR));
  }
}

export function* getProcessSaga(data: {
  type: typeof getProcessAction.type;
  payload: GetProcessRequest;
}): any {
  try {
    yield put(setGetProcessLoading(DataLoadingStatus.LOADING));

    const { response, error } = yield call(
      processDiscoveryService.getProcess,
      data.payload,
    );
    if (response) {
      yield put(setGetProcess(response));
      yield put(setGetProcessLoading(DataLoadingStatus.LOADED));
    } else {
      yield put(setGetProcessError(error?.message || error));
      yield put(setGetProcessLoading(DataLoadingStatus.ERROR));
    }
  } catch (error) {
    yield put(setGetProcessError(error?.message || error));
    yield put(setGetProcessLoading(DataLoadingStatus.ERROR));
  }
}

export function* listTracesSaga(data: {
  type: typeof listTracesAction.type;
  payload: ListTracesRequest;
}): any {
  try {
    yield put(setListTracesLoading(DataLoadingStatus.LOADING));

    const { response, error } = yield call(processDiscoveryService.listTraces, {
      ...data.payload,
      sortFields: [SortField.create({ field: 'end_time', descending: true })],
    });
    if (response) {
      const tracesData = response.traces.map((trace: ObservationSession) => ({
        observationSession: trace,
        actions: [],
      }));

      yield put(setTracesData(tracesData));
      yield put(setTotalTraces(response.totalCount || 0));
      yield put(setTotalUsers(response.totalUsers || 0));
      yield put(setListTracesLoading(DataLoadingStatus.LOADED));
    } else {
      yield put(setListTracesError(error?.message || error));
      yield put(setListTracesLoading(DataLoadingStatus.ERROR));
    }
  } catch (error) {
    yield put(setListTracesError(error?.message || error));
    yield put(setListTracesLoading(DataLoadingStatus.ERROR));
  }
}

export function* getTraceSaga(data: {
  type: typeof getTraceAction.type;
  payload: GetTraceRequest;
}): any {
  try {
    yield put(setGetTraceLoading(DataLoadingStatus.LOADING));

    const { response, error } = yield call(
      processDiscoveryService.getTrace,
      data.payload,
    );
    if (response) {
      yield put(
        setTraceActions({
          traceId: data.payload.traceId!,
          actions: response.actions,
        }),
      );
      yield put(setGetTraceLoading(DataLoadingStatus.LOADED));
    } else {
      yield put(setGetTraceError(error?.message || error));
      yield put(setGetTraceLoading(DataLoadingStatus.ERROR));
    }
  } catch (error) {
    yield put(setGetTraceError(error?.message || error));
    yield put(setGetTraceLoading(DataLoadingStatus.ERROR));
  }
}

export function* changeSelectedTraceSaga(data: {
  type: typeof changeSelectedTraceIndexAction.type;
  payload: number;
}): any {
  yield put(setSelectedTraceIndex(data.payload));
  const selectedTrace = yield select(selectedTraceSelector);
  // Check if totalActions is greater than 0 and the trace doesn't have any actions
  // If so, fetch the actions for the trace
  if (
    selectedTrace?.observationSession?.totalActions > 0 &&
    !selectedTrace?.actions.length
  ) {
    yield put(
      getTraceAction({ traceId: selectedTrace.observationSession?.id }),
    );
  }
}

/**
 * Helper function to filter out Focus and Scroll actions from the tree of actions.
 */
export function filterActionsRecursively(
  actions: Action[] | undefined,
): Action[] | undefined {
  if (!actions || actions.length === 0) return actions;

  return actions
    .filter((action) => !action.focus && !action.scrollAction)
    .map((action) => {
      const filteredAction = { ...action };

      if (filteredAction.prerequisites) {
        filteredAction.prerequisites = filteredAction.prerequisites
          .map((prereq) => {
            if (prereq.action) {
              const filteredActions = filterActionsRecursively([prereq.action]);
              return filteredActions && filteredActions.length > 0
                ? { ...prereq, action: filteredActions[0] }
                : null;
            }
            return null;
          })
          .filter((prereq) => prereq !== null) as any;
      }
      if (filteredAction.block) {
        filteredAction.block = {
          ...filteredAction.block,
          actions: filterActionsRecursively(filteredAction.block.actions),
        };
      }
      if (filteredAction.condition) {
        filteredAction.condition = {
          ...filteredAction.condition,
          thenActions: filterActionsRecursively(
            filteredAction.condition.thenActions,
          ),
          elseActions: filterActionsRecursively(
            filteredAction.condition.elseActions,
          ),
        };
      }
      if (filteredAction.foreach) {
        filteredAction.foreach = {
          ...filteredAction.foreach,
          loopActions: filterActionsRecursively(
            filteredAction.foreach.loopActions,
          ),
        };
      }
      return filteredAction;
    });
}

export function* createAutomateWorkflowSaga(data: {
  type: typeof createAutomatedWorkflowAction.type;
  payload: { actions: Action[]; navigateFn: (URL: string) => void };
}): any {
  try {
    const { actions, navigateFn } = data.payload;
    yield put(setCreateAutomatedWorkflowLoading(DataLoadingStatus.LOADING));

    const workflowId = new ObjectId(
      Math.floor(Date.now() / 1000),
    ).toHexString();

    const filteredActions = filterActionsRecursively(actions);

    const newWorkflow = {
      id: workflowId,
      processes: [
        {
          actions: filteredActions,
        },
      ],
    };

    const response = yield call(orbotService.createWorkflow, {
      workflow: newWorkflow,
    });
    if (response) {
      yield put(setCreateAutomatedWorkflowLoading(DataLoadingStatus.LOADED));
      navigateFn(`/workflow/${response.id}/definition`);
    } else {
      yield put(setCreateAutomatedWorkflowLoading(DataLoadingStatus.ERROR));
    }
  } catch (error) {
    yield put(setCreateAutomatedWorkflowError(error?.message || error));
    yield put(setCreateAutomatedWorkflowLoading(DataLoadingStatus.ERROR));
  }
}

export function* editSessionNameSaga(data: {
  type: typeof editSessionNameAction.type;
  payload: UpdateTraceRequest;
}): any {
  try {
    yield put(setEditSessionNameLoading(DataLoadingStatus.LOADING));

    const response = yield call(
      processDiscoveryService.updateTrace,
      data.payload,
    );
    if (response) {
      yield put(setEditSessionNameLoading(DataLoadingStatus.LOADED));
    } else {
      yield put(setEditSessionNameLoading(DataLoadingStatus.ERROR));
    }
  } catch (error) {
    yield put(setEditSessionNameError(error?.message || error));
    yield put(setEditSessionNameLoading(DataLoadingStatus.ERROR));
  }
}

export function* getTraceUsersList(data: {
  type: typeof getTraceUsers.type;
  payload: GetTraceUsersRequest;
}): any {
  try {
    yield put(setGetTraceLoading(DataLoadingStatus.LOADING));

    const { response, error } = yield call(
      processDiscoveryService.getTraceUsers,
      data.payload,
    );
    if (response) {
      yield put(setTraceUsers(response));
      yield put(setGetTraceLoading(DataLoadingStatus.LOADED));
    } else {
      yield put(setGetTraceError(error?.message || error));
      yield put(setGetTraceLoading(DataLoadingStatus.ERROR));
    }
  } catch (error) {
    yield put(setGetTraceError(error?.message || error));
    yield put(setGetTraceLoading(DataLoadingStatus.ERROR));
  }
}

export function* createProcessSaga(data: {
  type: typeof createProcessAction.type;
  payload: CreateProcessRequest;
}): any {
  try {
    yield put(setCreateProcessLoading(DataLoadingStatus.LOADING));

    const { response, error } = yield call(
      processDiscoveryService.createProcess,
      data.payload,
    );
    if (response) {
      yield put(
        setDraftProcesses({ savedDraftProcess: response.userDefinedProcess }),
      );
      yield put(setCreateProcessLoading(DataLoadingStatus.LOADED));
    } else {
      yield put(setCreateProcessError(error?.message || error));
      yield put(setCreateProcessLoading(DataLoadingStatus.ERROR));
    }
  } catch (error) {
    yield put(setCreateProcessError(error?.message || error));
    yield put(setCreateProcessLoading(DataLoadingStatus.ERROR));
  }
}

export function* getProcessDefinitionSaga(data: {
  type: typeof getProcessDefinitionAction.type;
  payload: GetProcessDefinitionRequest;
}): any {
  try {
    yield put(setGetProcessDefinitionLoading(DataLoadingStatus.LOADING));

    const { response, error } = yield call(
      processDiscoveryService.getProcessDefinition,
      data.payload,
    );
    if (response) {
      yield put(setDraftProcesses({ savedDraftProcess: response.process }));
      yield put(setGetProcessDefinitionLoading(DataLoadingStatus.LOADED));
    } else {
      yield put(setGetProcessDefinitionError(error?.message || error));
      yield put(setGetProcessDefinitionLoading(DataLoadingStatus.ERROR));
    }
  } catch (error) {
    yield put(setGetProcessDefinitionError(error?.message || error));
    yield put(setGetProcessDefinitionLoading(DataLoadingStatus.ERROR));
  }
}

export function* updateProcessSaga(data: {
  type: typeof updateProcessAction.type;
  payload: UpdateProcessRequest;
}): any {
  try {
    yield put(setUpdateProcessLoading(DataLoadingStatus.LOADING));

    // Get the saved draft process to compare with active draft
    const savedDraftProcess = yield select(savedDraftProcessSelector);

    // If no update_mask is provided in the payload, generate it by comparing with saved draft
    if (!data.payload.updateMask || data.payload.updateMask.length === 0) {
      const updatedFields = Object.keys(
        data.payload.userDefinedProcess || {},
      ).filter((key) => {
        return (
          savedDraftProcess &&
          !isEqual(
            get(data.payload.userDefinedProcess, key),
            get(savedDraftProcess, key),
          )
        );
      });
      data.payload.updateMask = updatedFields;
    }

    const { response, error } = yield call(
      processDiscoveryService.updateProcess,
      data.payload,
    );
    if (response) {
      yield put(
        setDraftProcesses({ savedDraftProcess: response.userDefinedProcess }),
      );
      yield put(setUpdateProcessLoading(DataLoadingStatus.LOADED));
    } else {
      yield put(setUpdateProcessError(error?.message || error));
      yield put(setUpdateProcessLoading(DataLoadingStatus.ERROR));
    }
  } catch (error) {
    yield put(setUpdateProcessError(error?.message || error));
    yield put(setUpdateProcessLoading(DataLoadingStatus.ERROR));
  }
}

export function* generateDocumentationSaga(data: {
  type: typeof generateDocumentationAction.type;
  payload: GenerateDocumentationRequest;
}): any {
  try {
    yield put(setGenerateDocumentationLoading(DataLoadingStatus.LOADING));
    // Get the saved draft process to compare with active draft
    const savedDraftProcess = yield select(savedDraftProcessSelector);

    yield put(
      setDraftProcesses({
        savedDraftProcess: {
          ...savedDraftProcess,
          status: UserDefinedProcessStatus.GENERATING_DOCUMENTATION,
        },
      }),
    );

    const { response, error } = yield call(
      processDiscoveryService.generateDocumentation,
      data.payload,
    );
    if (response) {
      yield put(
        setDraftProcesses({ savedDraftProcess: response.processConfig }),
      );
      yield put(setGenerateDocumentationLoading(DataLoadingStatus.LOADED));
    } else {
      yield put(setGenerateDocumentationError(error?.message || error));
      yield put(setGenerateDocumentationLoading(DataLoadingStatus.ERROR));
    }
  } catch (error) {
    yield put(setGenerateDocumentationError(error?.message || error));
    yield put(setGenerateDocumentationLoading(DataLoadingStatus.ERROR));
  }
}

export function* processDiscoverySaga() {
  yield takeLatest(listProcessesAction.type, listProcessesSaga);
  yield takeLatest(getProcessAction.type, getProcessSaga);
  yield takeLatest(listTracesAction.type, listTracesSaga);
  yield takeLatest(getTraceAction.type, getTraceSaga);
  yield takeLatest(
    changeSelectedTraceIndexAction.type,
    changeSelectedTraceSaga,
  );
  yield takeLatest(
    createAutomatedWorkflowAction.type,
    createAutomateWorkflowSaga,
  );
  yield takeLatest(editSessionNameAction.type, editSessionNameSaga);
  yield takeLatest(getTraceUsers.type, getTraceUsersList);
  yield takeLatest(createProcessAction.type, createProcessSaga);
  yield takeLatest(getProcessDefinitionAction.type, getProcessDefinitionSaga);
  yield takeLatest(updateProcessAction.type, updateProcessSaga);
  yield takeLatest(generateDocumentationAction.type, generateDocumentationSaga);
}
