import { createSlice, createAction } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  ProcessSmartActionsRequest,
  ProcessSmartActionsResponse,
} from 'protos/pb/v1alpha1/actionprocessing';
import { DataLoadingStatus } from '../../utils/constants';

export interface SmartActionsState {
  processSmartActionsResponse: ProcessSmartActionsResponse | null;
  processSmartActionsLoadingStatus: DataLoadingStatus;
  processSmartActionsError: string | null;
}

const initialState: SmartActionsState = {
  processSmartActionsResponse: null,
  processSmartActionsLoadingStatus: DataLoadingStatus.INITIAL,
  processSmartActionsError: null,
};

/**
 * The actions are created here to be used in the smart_actions.saga.ts file.
 */

export const processSmartActions = createAction<ProcessSmartActionsRequest>(
  'smartActions/processSmartActions',
);

export const smartActionsSlice = createSlice({
  name: 'smartActions',
  initialState,

  reducers: {
    setProcessSmartActionsResponse: (
      state,
      action: PayloadAction<ProcessSmartActionsResponse>,
    ) => {
      state.processSmartActionsResponse = action.payload;
    },
    setProcessSmartActionsLoading: (
      state,
      action: PayloadAction<DataLoadingStatus>,
    ) => {
      state.processSmartActionsLoadingStatus = action.payload;
    },
    setProcessSmartActionsError: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.processSmartActionsError = action.payload;
    },
  },
  selectors: {
    processSmartActionsResponseSelector: (state) =>
      state.processSmartActionsResponse,
    isProcessSmartActionsLoadingSelector: (state) =>
      state.processSmartActionsLoadingStatus === DataLoadingStatus.LOADING,
    processSmartActionsErrorSelector: (state) =>
      state.processSmartActionsLoadingStatus === DataLoadingStatus.ERROR
        ? state.processSmartActionsError
        : null,
  },
});

export const {
  setProcessSmartActionsResponse,
  setProcessSmartActionsLoading,
  setProcessSmartActionsError,
} = smartActionsSlice.actions;

export const {
  processSmartActionsResponseSelector,
  isProcessSmartActionsLoadingSelector,
  processSmartActionsErrorSelector,
} = smartActionsSlice.selectors;

export default smartActionsSlice.reducer;
