import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ActionPosition } from 'protos/pb/v1alpha1/orbot_service';

export const enum ACTION_TYPE {
  CONDITIONAL_ACTION = 'CONDITIONAL_ACTION',
  LOOP_ACTION = 'LOOP_ACTION',
  DOCUMENT_EXTRACTION_ACTION = 'DOCUMENT_EXTRACTION_ACTION',
  CODE_BLOCK_ACTION = 'CODE_BLOCK_ACTION',
  RECORD_ACTIONS = 'RECORD_ACTIONS',
  GOTO_ACTION = 'GOTO_ACTION',
  SET_VALUE_ACTION = 'SET_VALUE_ACTION',
  CLICK_ACTION = 'CLICK_ACTION',
}

export interface RecordingContext {
  workflowId: string;
  processId?: string;
  actionPosition: ActionPosition;
}

export interface MyWorkflowsState {
  isFlowChartSideBarVisible: boolean;
  newActionType?: ACTION_TYPE;
  recordingContext?: RecordingContext;
  expandedActions: string[];
  hoveredEdgeId?: string;
  hoveredNodeId?: string;
}

const initialState: MyWorkflowsState = {
  isFlowChartSideBarVisible: false,
  expandedActions: [],
};

export const myWorkflowsSlice = createSlice({
  name: 'myWorkflows',
  initialState,
  reducers: {
    setIsSideBarVisible: (state, action: PayloadAction<boolean>) => {
      state.isFlowChartSideBarVisible = action.payload;
    },
    setNewActionType: (
      state,
      action: PayloadAction<ACTION_TYPE | undefined>,
    ) => {
      state.newActionType = action.payload;
      state.isFlowChartSideBarVisible = !!action.payload;
    },
    setRecordingContext: (
      state,
      action: PayloadAction<RecordingContext | undefined>,
    ) => {
      state.recordingContext = action.payload;
    },
    setExpandedActions: (state, action: PayloadAction<string>) => {
      if (state.expandedActions.includes(action.payload)) {
        state.expandedActions = state.expandedActions.filter(
          (id) => id !== action.payload,
        );
      } else {
        state.expandedActions.push(action.payload);
      }
    },
    clearExpandedActions: (state) => {
      state.expandedActions = [];
    },
    addHoveredEdgeId: (state, action: PayloadAction<string>) => {
      state.hoveredEdgeId = action.payload;
    },
    removeHoveredEdgeId: (state) => {
      state.hoveredEdgeId = undefined;
    },
    setHoveredNodeId: (state, action: PayloadAction<string | undefined>) => {
      state.hoveredNodeId = action.payload;
    },
  },
  selectors: {
    isSideBarVisibleSelector: (state) => state.isFlowChartSideBarVisible,
    newActionTypeSelector: (state) => state.newActionType,
    recordingContextSelector: (state) => state.recordingContext,
    expandedActionsSelector: (state) => state.expandedActions,
    hoveredEdgeIdSelector: (state) => state.hoveredEdgeId,
    hoveredNodeIdSelector: (state) => state.hoveredNodeId,
  },
});

export const {
  setIsSideBarVisible,
  setNewActionType,
  setExpandedActions,
  clearExpandedActions,
  addHoveredEdgeId,
  removeHoveredEdgeId,
  setRecordingContext,
  setHoveredNodeId,
} = myWorkflowsSlice.actions;

export const {
  isSideBarVisibleSelector,
  newActionTypeSelector,
  recordingContextSelector,
  expandedActionsSelector,
  hoveredEdgeIdSelector,
  hoveredNodeIdSelector,
} = myWorkflowsSlice.selectors;

export default myWorkflowsSlice.reducer;
