import { Box, Checkbox, SxProps, Theme, Tooltip } from '@mui/material';
import React, { FC, memo, useState } from 'react';

import OrbyTypography from '../typography/OrbyTypography';

// Default State
import CheckBoxChecked from './icons/checkbox-checked.svg';
import CheckBoxIndetermined from './icons/checkbox-indetermined.svg';
import CheckBoxUnChecked from './icons/checkbox-unchecked.svg';

// Hover State
import CheckBoxCheckedHover from './icons/checkbox-checked-hovered.svg';
import CheckBoxIndeterminedHover from './icons/checkbox-indetermined-hovered.svg';
import CheckBoxUnCheckedHover from './icons/checkbox-unchecked-hovered.svg';

// Focus State
import CheckBoxUnCheckedFocus from './icons/checkbox-unchecked-focused.svg';

// Disabled State
import CheckBoxCheckedDisable from './icons/checkbox-checked-disabled.svg';
import CheckBoxIndeterminedDisable from './icons/checkbox-indetermined-disabled.svg';
import CheckBoxUnCheckedDisable from './icons/checkbox-unchecked-disabled.svg';

import '../../styles/global.css';
import OrbyColorPalette from '../colors/ColorPalette';
import { getCheckboxSize } from './checkbox-utils';

export interface OrbyCheckboxProps {
  /**
   * The size of the checkbox
   * @default 'sm'
   */
  size: 'sm' | 'md';
  /**
   * If true, the component is checked.
   * @default -
   */
  checked?: boolean;
  /**
   * The onClick handler for the checkbox.
   */
  onClick?: React.MouseEventHandler<HTMLInputElement | HTMLButtonElement>;
  /**
   * The onKeyDown handler for the checkbox.
   */
  onKeyDown?: React.KeyboardEventHandler<HTMLButtonElement>;
  /**
   * The label for the checkbox.
   */
  label?: string;
  /**
   * The description for the checkbox.
   */
  description?: string;
  /**
   * If true, the checkbox is disabled.
   * @default -
   */
  disabled?: boolean;
  /**
   * Title for accessibility
   */
  title: string;
  /**
   * Title for tooltip
   */
  tooltipTitle?: string;

  /**
   * The style for the checkbox.
   */
  checkboxContainerStyle?: SxProps<Theme>;

  /**
   * The style for the checkbox label.
   */
  labelStyle?: SxProps<Theme>;

  /**
   * The style for the checkbox description.
   */
  descriptionStyle?: SxProps<Theme>;
}

const FocusedStyle = {
  boxShadow: '0px 0px 0px 4px #f4ebff',
  borderRadius: '4px',
};

const OrbyCheckbox: FC<OrbyCheckboxProps> = (props) => {
  const {
    checked,
    onClick,
    disabled,
    size,
    label,
    description,
    title,
    onKeyDown,
    tooltipTitle,
    checkboxContainerStyle,
    labelStyle,
    descriptionStyle,
  } = props;
  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);

  let icon: JSX.Element;
  let checkedIcon: JSX.Element;
  let indeterminateIcon: JSX.Element;

  if (disabled) {
    icon = (
      <img
        width={getCheckboxSize(size)}
        height={getCheckboxSize(size)}
        src={CheckBoxUnCheckedDisable}
        alt='Unchecked Box'
      />
    );
    checkedIcon = (
      <img
        width={getCheckboxSize(size)}
        height={getCheckboxSize(size)}
        src={CheckBoxCheckedDisable}
        alt='Checked Box'
      />
    );
    indeterminateIcon = (
      <img
        width={getCheckboxSize(size)}
        height={getCheckboxSize(size)}
        src={CheckBoxIndeterminedDisable}
        alt='Indeterminate Box'
      />
    );
  } else if (focus) {
    icon = (
      <img
        style={FocusedStyle}
        width={getCheckboxSize(size)}
        height={getCheckboxSize(size)}
        src={CheckBoxUnCheckedFocus}
        alt='Unchecked Box'
      />
    );
    checkedIcon = (
      <img
        style={FocusedStyle}
        width={getCheckboxSize(size)}
        height={getCheckboxSize(size)}
        src={CheckBoxChecked}
        alt='Checked Box'
      />
    );
    indeterminateIcon = (
      <img
        style={FocusedStyle}
        width={getCheckboxSize(size)}
        height={getCheckboxSize(size)}
        src={CheckBoxIndetermined}
        alt='Indeterminate Box'
      />
    );
  } else if (hover) {
    icon = (
      <img
        width={getCheckboxSize(size)}
        height={getCheckboxSize(size)}
        src={CheckBoxUnCheckedHover}
        alt='Unchecked Box'
      />
    );
    checkedIcon = (
      <img
        width={getCheckboxSize(size)}
        height={getCheckboxSize(size)}
        src={CheckBoxCheckedHover}
        alt='Checked Box'
      />
    );
    indeterminateIcon = (
      <img
        width={getCheckboxSize(size)}
        height={getCheckboxSize(size)}
        src={CheckBoxIndeterminedHover}
        alt='Indeterminate Box'
      />
    );
  } else {
    icon = (
      <img
        width={getCheckboxSize(size)}
        height={getCheckboxSize(size)}
        src={CheckBoxUnChecked}
        alt='Unchecked Box'
      />
    );
    checkedIcon = (
      <img
        width={getCheckboxSize(size)}
        height={getCheckboxSize(size)}
        src={CheckBoxChecked}
        alt='Checked Box'
      />
    );
    indeterminateIcon = (
      <img
        width={getCheckboxSize(size)}
        height={getCheckboxSize(size)}
        src={CheckBoxIndeterminedHover}
        alt='Indeterminate Box'
      />
    );
  }

  return (
    <Tooltip
      open={hover && disabled && Boolean(tooltipTitle)}
      arrow
      PopperProps={{
        sx: {
          '& .MuiTooltip-arrow': { color: '#000' },
          '& .MuiTooltip-tooltip': {
            backgroundColor: '#000',
            borderRadius: '8px',
          },
        },
      }}
      title={tooltipTitle}
    >
      <Box
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        display={'inline-flex'}
        flexDirection={'row'}
        gap={'8px'}
        alignItems={'flex-start'}
        sx={checkboxContainerStyle}
      >
        <Checkbox
          title={title}
          indeterminate={typeof checked !== 'boolean'}
          disableRipple={true}
          disabled={disabled}
          sx={{ p: 0, marginTop: '2px' }}
          checked={checked}
          onClick={onClick}
          onKeyDown={onKeyDown}
          indeterminateIcon={indeterminateIcon}
          icon={icon}
          checkedIcon={checkedIcon}
          inputProps={{ 'aria-label': `${title}` }}
        />
        {label && (
          <Box>
            <OrbyTypography
              size={size}
              weight='medium'
              color={
                disabled
                  ? OrbyColorPalette['grey-300']
                  : OrbyColorPalette['grey-900']
              }
              sx={labelStyle}
            >
              {label}
            </OrbyTypography>
            <OrbyTypography
              size={size}
              weight='regular'
              color={
                disabled
                  ? OrbyColorPalette['grey-300']
                  : OrbyColorPalette['grey-500']
              }
              sx={descriptionStyle}
            >
              {description}
            </OrbyTypography>
          </Box>
        )}
      </Box>
    </Tooltip>
  );
};

export default memo(OrbyCheckbox);
