import { createSelector } from 'reselect';
import { userSelector } from './app.selectors';
import { DataLoadingStatus } from '../../utils/constants';
import { getOrgIdFromResourceName } from '../../utils/helpers';

export const loggedInUserSelector = createSelector(
  [userSelector],
  (userState) => userState.loggedInUser,
);

export const fetchingLoggedInUserSelector = createSelector(
  [userSelector],
  (userState) =>
    userState.loggedInUserLoadingStatus === DataLoadingStatus.LOADING,
);

export const updatingPasswordSelector = createSelector(
  [userSelector],
  (userState) => userState.updatingPassword,
);

export const usersListSelector = createSelector(
  [userSelector],
  (userState) => userState.users,
);

export const loadingUsersSelector = createSelector(
  [userSelector],
  (userState) => userState.loadingUsersStatus === DataLoadingStatus.LOADING,
);

export const loadedUsersSelector = createSelector(
  [userSelector],
  (userState) => userState.loadingUsersStatus === DataLoadingStatus.LOADED,
);

export const usersNextPageTokenSelector = createSelector(
  [userSelector],
  (userState) => userState.nextPageToken,
);

export const usersTotalSizeSelector = createSelector(
  [userSelector],
  (userState) => userState.totalSize,
);

export const listUsersErrorSelector = createSelector(
  [userSelector],
  (userState) => userState.listUsersError,
);

export const addUserErrorSelector = createSelector(
  [userSelector],
  (userState) => userState.addUserError,
);

export const addedUserSelector = createSelector(
  [userSelector],
  (userState) => userState.addedUser,
);

export const updateUserErrorSelector = createSelector(
  [userSelector],
  (userState) =>
    userState.updateUserStatus === DataLoadingStatus.ERROR
      ? userState.updateUserError
      : undefined,
);

export const updatedUserSelector = createSelector(
  [userSelector],
  (userState) => userState.updatedUser,
);

export const updateUserLoadingSelector = createSelector(
  [userSelector],
  (userState) => userState.updateUserStatus === DataLoadingStatus.LOADING,
);

export const createUserLoadingSelector = createSelector(
  [userSelector],
  (userState) => userState.createUserStatus === DataLoadingStatus.LOADING,
);

export const selectedOrgInfoSelector = createSelector(
  [userSelector],
  (userState) => userState.selectedOrgInfo,
);

export const selectedOrgIdSelector = createSelector(
  [selectedOrgInfoSelector],
  (selectedOrgInfo) =>
    getOrgIdFromResourceName(selectedOrgInfo?.orgResourceName),
);

export const updateGoogleTokenLoadingSelector = createSelector(
  [userSelector],
  (userState) =>
    userState.updateGoogleTokenLoadingStatus === DataLoadingStatus.LOADING,
);
export const selectedOrgInfoCompletedSelector = createSelector(
  [userSelector],
  (userState) => userState.selectedOrgInfoCompleted,
);

export const updateGoogleTokenCompletedSelector = createSelector(
  [userSelector],
  (userState) =>
    userState.updateGoogleTokenLoadingStatus === DataLoadingStatus.LOADED,
);

export const updateGoogleTokenErrorSelector = createSelector(
  [userSelector],
  (userState) =>
    userState.updateGoogleTokenLoadingStatus === DataLoadingStatus.ERROR
      ? userState.updateGoogleTokenError
      : undefined,
);

export const updateMicrosoftTokenLoadingSelector = createSelector(
  [userSelector],
  (userState) =>
    userState.updateMicrosoftTokenLoadingStatus === DataLoadingStatus.LOADING,
);

export const updateMicrosoftTokenCompletedSelector = createSelector(
  [userSelector],
  (userState) =>
    userState.updateMicrosoftTokenLoadingStatus === DataLoadingStatus.LOADED,
);

export const updateMicrosoftTokenErrorSelector = createSelector(
  [userSelector],
  (userState) =>
    userState.updateMicrosoftTokenLoadingStatus === DataLoadingStatus.ERROR
      ? userState.updateMicrosoftTokenError
      : undefined,
);

export const userPermissionsSelector = createSelector(
  [userSelector],
  (userState) => userState.userPermissions,
);
