/**
 * This component is used to copy tasks from one workflow to another.
 * It has two options:
 * 1. All completed tasks
 * 2. Specific tasks
 * On selecting "All completed tasks", all the completed tasks will be copied from the copy from workflow.
 * On selecting the "Specific tasks", the user can select the specific tasks (completed only) to copy.
 * When the user selects the "Specific tasks", the completed tasks will be fetched from the backend.
 * The user can select the target workflow to copy the tasks to.
 * The user can click on the cancel button to close the modal.
 * The user can click on the okay button to copy the tasks.
 * The user can click on the pagination component to change the page in case of "Specific tasks".
 * The user can click on the target workflow select field to select the target workflow.
 * The targeted workflow should be of the same type as the copy from workflow.
 */
import {
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Box,
} from '@mui/material';

import { Form, FormikProvider, useFormik } from 'formik';
import React, { FC, memo, useState } from 'react';
import * as Yup from 'yup';
import CustomTypography, {
  TypographyType,
} from '../../../components/CustomTypography';
import {
  CopyTasksRequest,
  ListTasksRequest,
  ListTasksResponse,
  Task,
} from 'protos/pb/v1alpha2/tasks_service';
import { Workflow } from 'protos/pb/v1alpha2/workflows_service';
import { useSelector } from 'react-redux';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
} from '../../../redux/selectors/user.selectors';
import { formatDate, isAdmin } from '../../../utils/helpers';
import {
  ELLIPSIS_STYLE,
  FEATURE_FLAGS,
  ROWS_PER_PAGE,
} from '../../../utils/constants';
import CustomPagination from '../../../components/Pagination/CustomPagination';
import CustomSelectField from './components/CustomSelectField';
import { tasksV2Service } from '../../../services/TasksV2Service';
import CustomTextField from '../../../components/CustomTextField';
import CustomModal from '../../CustomModal';
import { showErrorToast } from 'orby-ui/src/components/toast/OrbyToast';
import { buildRequest } from '../../../pages/Tasks/TaskHelpers';
import { useNavigate } from 'react-router-dom';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import useIsFeatureEnabled from '../../../hooks/useIsFeatureEnabled';
import dayjs from 'dayjs';
interface TaskCopyModalProps {
  open: boolean;
  setOpen: (x: boolean) => void;
  workflow: Workflow;
  setShowTaskCopyConfirmModal: (showTaskCopyConfirmModal: boolean) => void;
}

enum SelectedTaskOption {
  COMPLETED,
  SPECIFIC,
}

const DEFAULT_TASKS_LIST_RESPONSE: ListTasksResponse = {
  tasks: [],
  totalSize: 0,
};

const initialValues = {
  selectedTaskOption: SelectedTaskOption.COMPLETED,
  selectedTasksList: [] as Task[],
  selectedToWorkflow: {} as Workflow,
};

const TaskCopyModal: FC<TaskCopyModalProps> = ({
  open,
  setOpen,
  workflow,
  setShowTaskCopyConfirmModal,
}) => {
  const navigate = useNavigate();
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);

  const isCompletedTaskCopyDisabled = useIsFeatureEnabled(
    FEATURE_FLAGS.TASK_COPY_MODAL_COMPLETED_TASK_DISABLED,
  );

  const [taskListObject, setTaskListObject] = useState<ListTasksResponse>(
    DEFAULT_TASKS_LIST_RESPONSE,
  );
  const user = useSelector(loggedInUserSelector);
  const [taskPage, setTaskPage] = useState(0);
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);
  const [isTasksLoading, setIsTasksLoading] = useState(false);
  const [isCompletedTasksLoading, setIsCompletedTasksLoading] = useState(false);
  const [hasCompletedTasks, setHasCompletedTasks] = useState(false);
  const rowsPerPage = 10;
  const organizations = user?.orgInfos?.map((org) => {
    return {
      label: org.orgDisplayName as string,
      value: org.orgResourceName as string,
    };
  });
  /**
   * FETCH TASKS FOR THE SELECTED WORKFLOW
   */
  const composeFilters = (): string => {
    return `status=COMPLETED,workflow_resource_names=${workflow.name}`;
  };

  const fetchTasks = async (pageNumber = 1) => {
    if (
      ((taskListObject.tasks &&
        taskListObject.totalSize &&
        taskListObject.tasks.length < taskListObject.totalSize) ||
        !taskListObject.totalSize) &&
      selectedOrgInfo
    ) {
      setIsTasksLoading(true);
      const req: ListTasksRequest = {};
      req.pageSize = rowsPerPage;
      req.parent = selectedOrgInfo.orgResourceName;
      req.pageNumber = pageNumber;
      req.filter = composeFilters();
      const response = await tasksV2Service.getTasksList(req);
      if (response.error) {
        setIsTasksLoading(false);
        return;
      }
      const taskListResponse = response.response;
      setTaskListObject({
        tasks: [
          ...(taskListObject.tasks ?? []),
          ...(taskListResponse?.tasks || []),
        ],
        totalSize: taskListResponse?.totalSize,
      } as ListTasksResponse);
      setIsTasksLoading(false);
    }
  };

  /**
   * This function is used to copy tasks from one workflow to another.
   */
  const handleSubmitCopyTasks = async (values: typeof initialValues) => {
    if (isCompletedTaskCopyDisabled && hasCompletedTasks) {
      showErrorToast(
        "This workflow already contains completed tasks. You can't add a copied task to this workflow.",
      );
      return;
    }
    setIsSubmitButtonLoading(true);
    const req: CopyTasksRequest = {};
    req.sourceWorkflowResourceName = workflow.name;
    req.destinationOrgResourceName =
      values.selectedToWorkflow.organizationResourceName;
    req.destinationWorkflowResourceName = values.selectedToWorkflow.name;
    req.sendEmailNotification = true;
    if (values.selectedTaskOption === SelectedTaskOption.SPECIFIC) {
      req.filter = `task_resource_names=${values.selectedTasksList.join('-')}`;
    }
    //TODO: refactor this (saga and store)
    const response = await tasksV2Service.copyTask(req);
    if (response.error) {
      showErrorToast(response.error.message);
      setIsSubmitButtonLoading(false);
      return;
    } else {
      setOpen(false);
      setShowTaskCopyConfirmModal(true);
      setIsSubmitButtonLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      selectedToWorkflow: Yup.object().test(
        'check-required',
        'Please select a target workflow before proceeding',
        (val) => {
          if (!val?.name) {
            return false;
          }
          return true;
        },
      ),
      selectedTasksList: Yup.array().test(
        'check-required',
        'Please select at least one task before proceeding',
        (selectedTasksList) => {
          if (
            values.selectedTaskOption === SelectedTaskOption.SPECIFIC &&
            selectedTasksList?.length === 0
          ) {
            return false;
          }
          return true;
        },
      ),
    }),
    onSubmit: (values) => {
      if (!isSubmitButtonLoading) {
        handleSubmitCopyTasks(values);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    values,
    resetForm,
    setFieldValue,
    getFieldProps,
  } = formik;

  const getSubmitButtonLabel = () => {
    if (values.selectedTaskOption === SelectedTaskOption.COMPLETED) {
      return 'Copy Tasks';
    } else {
      if (values.selectedTasksList.length === 0) {
        return 'Copy Tasks';
      }
      return `Copy ${values.selectedTasksList.length} tasks`;
    }
  };

  const handleWorkflowChange = async (workflow: Workflow) => {
    /**
     * CALL THE TASKS API TO CHECK IF THERE ARE ANY COMPLETED TASKS FOR THE SELECTED WORKFLOW
     * IF YES, THEN SHOW WARNING MESSAGE TO THE USER
     */
    setIsCompletedTasksLoading(true);
    setHasCompletedTasks(false);
    const req = buildRequest({
      filter: 'status=COMPLETED',
      user: user!,
      isAdminView: isAdmin(selectedOrgInfo?.role),
      navigate,
      selectedWorkflows: [workflow.name as string],
      displayNamePrefix: '',
      selectedUsers: [],
      orgResourceName: selectedOrgInfo!.orgResourceName as string,
      pageNumber: 1,
      pageSize: 1,
      selectedTime: '',
      customRange: {
        startDate: dayjs(),
        endDate: dayjs(),
      },
    });
    const response = await tasksV2Service.getTasksList(req);
    if (response.error) {
      showErrorToast(response.error.message);
      setIsCompletedTasksLoading(false);
      return;
    }
    if (response.response?.totalSize && response.response?.totalSize > 0) {
      setHasCompletedTasks(true);
    } else {
      setHasCompletedTasks(false);
    }
    setIsCompletedTasksLoading(false);
    setFieldValue('selectedToWorkflow', workflow);
  };

  return (
    <CustomModal
      open={open}
      containerSx={{
        px: '40px !important',
      }}
      heading={'Copy tasks'}
      titleSx={{ fontSize: '20px' }}
      headerSx={{ p: '40px', pb: '28px' }}
      actionSx={{ px: '40px' }}
      closable={false}
      handleClose={() => {
        setOpen(false);
        resetForm();
      }}
      primaryLabel={getSubmitButtonLabel()}
      isPrimaryLoading={isSubmitButtonLoading}
      secondaryLabel={'Cancel'}
      onPrimaryClick={handleSubmit}
      primaryDisabled={
        values.selectedTaskOption === SelectedTaskOption.SPECIFIC &&
        values.selectedTasksList.length === 0
      }
      content={
        <FormikProvider value={formik}>
          {isCompletedTasksLoading && (
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                background: '#0000000f',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress
                sx={{
                  margin: '0 auto',
                  color: OrbyColorPalette['blue-700'],
                }}
              />
            </Box>
          )}
          <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
            <CustomTypography
              sx={{
                marginBottom: '28px',
                lineHeight: '21px',
                fontSize: '14px',
              }}
              typographyType={TypographyType.MediumPara}
            >
              Please select the target workflows to copy to, the copied tasks
              will be leveraged in the target workflow&apos;s future task
              processing. We&apos;ll send you an email once it&apos;s done.
            </CustomTypography>
            <Grid>
              {/* 
              This is the copy from field. 
              It will show the workflow name from which the tasks are copied.
            */}
              <CustomTextField
                labelStyle={{
                  color: '#344054',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
                label='Copy from'
                value={workflow.displayName as string}
                disabled
                size='full'
              />
            </Grid>

            <Grid marginTop={'10px'}>
              {/* 
              This is a radio button group.
              It has two options:
              1. All completed tasks
              2. Specific tasks
              On selecting "All completed tasks", all the completed tasks will be copied from the copy from workflow.
              On selecting the "Specific tasks", the user can select the specific tasks (completed only) to copy.
              When the user selects the "Specific tasks", the completed tasks will be fetched from the backend.
            */}
              <RadioGroup
                value={values.selectedTaskOption}
                aria-labelledby='mode-label'
                name='radio-buttons-group'
                sx={{ alignItems: 'start' }}
              >
                <Grid paddingX={'10px'}>
                  <FormControlLabel
                    sx={{
                      paddingY: '5px',
                    }}
                    control={
                      <Radio
                        value={SelectedTaskOption.COMPLETED}
                        onChange={() => {
                          setFieldValue(
                            'selectedTaskOption',
                            SelectedTaskOption.COMPLETED,
                          );
                          setFieldValue('selectedTasksList', []);
                        }}
                        sx={{
                          marginLeft: 0,
                          color: '#7F56D9',
                          '&.Mui-checked': {
                            color: '#7F56D9',
                          },
                        }}
                      />
                    }
                    label={
                      <CustomTypography
                        sx={{
                          fontSize: '12px',
                          fontWeight: 500,
                          lineHeight: '18px',
                          color: '#667085',
                        }}
                      >
                        All completed tasks
                      </CustomTypography>
                    }
                    labelPlacement='end'
                  />
                </Grid>
                <Divider sx={{ width: '100%' }} />
                <Grid display={'flex'} paddingLeft={'10px'} width={'100%'}>
                  <Grid flex={1} display={'flex'} width={'100%'}>
                    <FormControlLabel
                      sx={{
                        paddingY: '5px',
                      }}
                      control={
                        <Radio
                          onChange={() => {
                            setFieldValue(
                              'selectedTaskOption',
                              SelectedTaskOption.SPECIFIC,
                            );
                            setFieldValue('selectedTasksList', []);
                            // Only fetch data if current page data is not already fetched
                            if (
                              taskListObject.tasks &&
                              taskListObject.tasks.length <=
                                taskPage * rowsPerPage
                            ) {
                              fetchTasks();
                            }
                          }}
                          value={SelectedTaskOption.SPECIFIC}
                          sx={{
                            marginLeft: 0,
                            color: '#7F56D9',
                            '&.Mui-checked': {
                              color: '#7F56D9',
                            },
                          }}
                        />
                      }
                      label={
                        <CustomTypography
                          sx={{
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '18px',
                            color: '#667085',
                          }}
                        >
                          Specific tasks
                        </CustomTypography>
                      }
                      labelPlacement='end'
                    />
                  </Grid>
                </Grid>
                <Divider sx={{ width: '100%' }} />
              </RadioGroup>
              {
                <>
                  {
                    /**
                     * This is the list of tasks.
                     * It is shown only when the user selects the "Specific tasks" option.
                     * It shows the list of completed tasks.
                     * The list is paginated. The user can change the page using the pagination component.
                     */
                    formik.values.selectedTaskOption ===
                      SelectedTaskOption.SPECIFIC && (
                      <>
                        {
                          /**
                           * This is the loader.
                           * It is shown when the tasks are being fetched from the backend.
                           */
                          isTasksLoading ? (
                            <Grid
                              display={'flex'}
                              justifyContent={'center'}
                              paddingY={'20px'}
                            >
                              <CircularProgress
                                sx={{
                                  '&.MuiCircularProgress-root': {
                                    color: '#7F56D9',
                                  },
                                }}
                              />
                            </Grid>
                          ) : (
                            <>
                              {taskListObject.tasks &&
                              taskListObject.tasks.length > 0 ? (
                                <>
                                  <Grid
                                    paddingTop={'10px'}
                                    maxHeight={'150px'}
                                    overflow={'auto'}
                                  >
                                    {/**
                                     * This is the list of tasks, which is shown in a paginated manner.
                                     */
                                    taskListObject.tasks
                                      ?.slice(
                                        taskPage * rowsPerPage,
                                        (taskPage + 1) * rowsPerPage,
                                      )
                                      ?.map((task) => {
                                        return (
                                          <Grid
                                            id='specific-tasks-list-table'
                                            key={task.name}
                                            width={'100%'}
                                          >
                                            <Grid
                                              paddingX={'30px'}
                                              display={'flex'}
                                              justifyContent={'space-between'}
                                              alignItems={'center'}
                                            >
                                              <FormControlLabel
                                                sx={{
                                                  flex: 2,
                                                }}
                                                control={
                                                  <Checkbox
                                                    checked={
                                                      !!values.selectedTasksList.find(
                                                        (t) => t === task.name,
                                                      )
                                                    }
                                                    value={task.name}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        setFieldValue(
                                                          'selectedTasksList',
                                                          [
                                                            ...values.selectedTasksList,
                                                            task.name,
                                                          ],
                                                        );
                                                      } else {
                                                        setFieldValue(
                                                          'selectedTasksList',
                                                          values.selectedTasksList.filter(
                                                            (t) =>
                                                              t !== task.name,
                                                          ),
                                                        );
                                                      }
                                                    }}
                                                    sx={{
                                                      color: '#7F56D9',
                                                      '&.Mui-checked': {
                                                        color: '#7F56D9',
                                                      },
                                                      '& .MuiSvgIcon-root': {
                                                        fontSize: 20,
                                                      },
                                                    }}
                                                  />
                                                }
                                                label={
                                                  <Box title={task.displayName}>
                                                    <CustomTypography
                                                      sx={{
                                                        fontSize: '12px',
                                                        fontWeight: 500,
                                                        color: '#667085',
                                                        ...ELLIPSIS_STYLE,
                                                        // Set maxWidth to '230px' to ensure proper alignment between createTime and its adjacent property,
                                                        // allowing for some space between them.
                                                        maxWidth: '230px',
                                                      }}
                                                    >
                                                      {task.displayName}
                                                    </CustomTypography>
                                                  </Box>
                                                }
                                              />
                                              <CustomTypography
                                                sx={{
                                                  fontSize: '12px',
                                                  fontWeight: 500,
                                                  color: '#667085',
                                                  flex: 1,
                                                }}
                                              >
                                                {formatDate(task.createTime!)}
                                              </CustomTypography>
                                            </Grid>
                                          </Grid>
                                        );
                                      })}
                                  </Grid>
                                  {
                                    /**
                                     * This is the pagination component.
                                     * It is shown only when the total number of tasks is greater than the number of tasks shown in the current page.
                                     */
                                    taskListObject.tasks &&
                                      taskListObject.totalSize &&
                                      taskListObject.tasks.length <=
                                        taskListObject.totalSize && (
                                        <Grid
                                          display={'flex'}
                                          justifyContent={'center'}
                                          paddingY={'10px'}
                                        >
                                          <CustomPagination
                                            ariaLabel={
                                              'Please select tasks rows per page.'
                                            }
                                            rowsPerPage={rowsPerPage}
                                            totalSize={
                                              taskListObject?.totalSize
                                            }
                                            page={taskPage}
                                            rows={ROWS_PER_PAGE}
                                            isShowSelectFilter={false}
                                            justifyContent={'end'}
                                            onPageChange={(p) => {
                                              setTaskPage(p);
                                              if (
                                                p >= taskPage &&
                                                taskListObject.tasks &&
                                                taskListObject.tasks.length <=
                                                  p * rowsPerPage
                                              ) {
                                                // Fetch tasks for new page
                                                fetchTasks(p + 1);
                                                // Scroll to top of the page when we change the page
                                                const element =
                                                  document.getElementById(
                                                    'specific-tasks-list-table',
                                                  );
                                                if (element) {
                                                  element.scrollTop = 0;
                                                }
                                              }
                                            }}
                                          />
                                        </Grid>
                                      )
                                  }
                                  <>
                                    {
                                      /**
                                       * This is the error message.
                                       * It is shown when the user tries to submit the form without selecting any task.
                                       * It is shown only when the user selects the "Specific tasks" option.
                                       */
                                      Boolean(
                                        touched.selectedTasksList &&
                                          errors.selectedTasksList,
                                      ) && (
                                        <CustomTypography
                                          component={'span'}
                                          sx={{
                                            color: '#EB0000',
                                            fontSize: '12px',
                                            display: 'block',
                                          }}
                                        >
                                          {
                                            (touched.selectedTasksList &&
                                              errors.selectedTasksList) as string
                                          }
                                        </CustomTypography>
                                      )
                                    }
                                  </>
                                  <Divider sx={{ width: '100%' }} />
                                </>
                              ) : (
                                /**
                                 * This is the message shown when there are no completed tasks for the selected workflow.
                                 */
                                <Grid
                                  display={'flex'}
                                  justifyContent={'center'}
                                  paddingY={'20px'}
                                >
                                  <CustomTypography
                                    sx={{
                                      fontSize: '12px',
                                      fontWeight: 500,
                                      color: '#667085',
                                    }}
                                  >
                                    There are no completed tasks for this
                                    workflow
                                  </CustomTypography>
                                </Grid>
                              )}
                            </>
                          )
                        }
                      </>
                    )
                  }
                </>
              }
            </Grid>

            <Grid paddingTop={'10px'}>
              {/* 
              This is a custom select field which is used to select the target workflow.
            */}
              <CustomSelectField
                copyFromWorkFlow={workflow}
                selectedToWorkflow={formik.values.selectedToWorkflow}
                handleSelectToWorkflow={(workflow: Workflow) => {
                  handleWorkflowChange(workflow);
                }}
                style={{
                  border:
                    touched.selectedToWorkflow && errors.selectedToWorkflow
                      ? '0.75px solid #EB0000'
                      : '1px solid #D0D5DD',
                }}
                ariaLabel='Task filter'
                dropdownWidth='100%'
                bottom='45px'
                height='40px'
                fieldProps={getFieldProps('confidenceScore')}
                organizationList={organizations || []}
              />
              {hasCompletedTasks && (
                <OrbyTypography
                  size='xs'
                  color={OrbyColorPalette['warning-800']}
                  sx={{
                    paddingTop: '10px',
                  }}
                >
                  {isCompletedTaskCopyDisabled
                    ? "This workflow already contains completed tasks. You can't add a copied task to this workflow."
                    : 'This workflow already contains completed tasks. Adding copied tasks may impact the workflow quality positively or negatively.'}
                </OrbyTypography>
              )}
              {
                /**
                 * This is the error message.
                 * It is shown when the user tries to submit the form without selecting any workflow.
                 */
                Boolean(
                  touched.selectedToWorkflow && errors.selectedToWorkflow,
                ) && (
                  <CustomTypography
                    component={'span'}
                    sx={{
                      color: '#EB0000',
                      fontSize: '12px',
                      display: 'block',
                    }}
                  >
                    {
                      (touched.selectedToWorkflow &&
                        errors.selectedToWorkflow) as string
                    }
                  </CustomTypography>
                )
              }
            </Grid>
          </Form>
        </FormikProvider>
      }
    />
  );
};

export default memo(TaskCopyModal);
