import { TextFieldType } from 'protos/pb/v1alpha1/field';
import {
  ResourceType,
  Variable,
  VariableType,
  VariableValue,
} from 'protos/pb/v1alpha1/variables';

export enum VariableDataType {
  UNKNOWN = 'Unknown',
  STRING = 'String',
  INTEGER = 'Integer',
  FLOAT = 'Float',
  BOOLEAN = 'Boolean',
  DATE = 'Date',
  MONEY = 'Money',
  SELECT = 'Select',
  TABLE = 'Table',
  ARRAY = 'Array',
  DOCUMENT = 'Document',
  RECORD = 'Record',
}

export type DisplayVariable =
  | DisplayVariableTable
  | DisplayVariableArray
  | DisplayVariableRecord
  | DisplayVariableDocument
  | DisplayVariableMoney
  | DisplayVariableSelect
  | DisplayVariableDate
  | DisplayVariablePrimitive;

export type DisplayVariableTable = {
  type: VariableDataType.TABLE;
  value: Record<string, (string | number)[]>;
};

export type DisplayVariableArray = {
  type: VariableDataType.ARRAY;
  value: string;
};

export type DisplayVariableDocument = {
  type: VariableDataType.DOCUMENT;
  value: string;
};

export type DisplayVariableMoney = {
  type: VariableDataType.MONEY;
  value: string;
};

export type DisplayVariableSelect = {
  type: VariableDataType.SELECT;
  value: string;
};

export type DisplayVariableDate = {
  type: VariableDataType.DATE;
  value: string;
};

export type DisplayVariableRecord = {
  type: VariableDataType.RECORD;
  value: Record<string, string>;
};

export type DisplayVariablePrimitive = {
  type:
    | VariableDataType.STRING
    | VariableDataType.BOOLEAN
    | VariableDataType.INTEGER
    | VariableDataType.FLOAT
    | VariableDataType.UNKNOWN;
  value: string;
};

export function getDisplayVariable(
  variable: Variable,
): DisplayVariable | undefined {
  // NOTE: We encountered a variable without type, so the variable type isn't always guaranteed
  // It could be corrupted data, we return a string value to prevent the app from breaking
  if (!variable.type) {
    return {
      type: VariableDataType.STRING,
      value: variable.value ? getDisplayVariableValue(variable.value) : '',
    };
  }

  const type = getVariableType(variable.type);

  // Table is a special type it's an array of records that can be expanded in the UI.
  if (type === VariableDataType.TABLE) {
    const result: Record<string, DisplayVariablePrimitive['value'][]> = {};
    if (variable.type?.array?.entryType?.record?.fields) {
      const fields = variable.type.array.entryType.record.fields;
      Object.keys(fields).forEach((field) => {
        if (!result[field]) {
          result[field] = [];
        }

        variable.value?.array?.entries?.forEach((entry) => {
          const value = entry.record?.fields?.[field];
          result[field].push(getDisplayVariableValue(value));
        });
      });
    }

    return {
      type,
      value: result,
    };
  } else if (type === VariableDataType.RECORD) {
    // Record can also be expanded in the UI.
    const result: Record<string, string> = {};
    if (variable.type?.record?.fields) {
      const fields = variable.type.record.fields;
      Object.keys(fields).forEach((field) => {
        result[field] = getVariableType(fields[field]);
      });
    }

    return {
      type,
      value: result,
    };
  } else {
    return {
      type,
      value: getDisplayVariableValue(variable.value),
    };
  }
}

export function getVariableType(type: VariableType) {
  if (type.text !== undefined) {
    return VariableDataType.STRING;
  }

  if (type.boolean !== undefined) {
    return VariableDataType.BOOLEAN;
  }

  if (type.float !== undefined) {
    return VariableDataType.FLOAT;
  }

  if (type.integer !== undefined) {
    return VariableDataType.INTEGER;
  }

  if (type.array !== undefined) {
    if (type.array.entryType?.record?.fields) {
      return VariableDataType.TABLE;
    }
    return VariableDataType.ARRAY;
  }

  if (type.record !== undefined) {
    return VariableDataType.RECORD;
  }

  if (type.date !== undefined) {
    return VariableDataType.DATE;
  }

  if (type?.resource === ResourceType.DOCUMENT) {
    return VariableDataType.DOCUMENT;
  }

  if (type.money !== undefined) {
    return VariableDataType.MONEY;
  }

  if (type.select !== undefined) {
    return VariableDataType.SELECT;
  }

  // NOTE: all variable types should be handled
  // the unknown type should not appear in UI
  return VariableDataType.UNKNOWN;
}

export function isVariablePrimitiveType(type: VariableDataType) {
  const primitiveTypes = [
    VariableDataType.STRING,
    VariableDataType.BOOLEAN,
    VariableDataType.FLOAT,
    VariableDataType.INTEGER,
  ];

  return primitiveTypes.includes(type);
}

export function isVariableNumberType(type: VariableDataType) {
  const numberTypes = [VariableDataType.FLOAT, VariableDataType.INTEGER];

  return numberTypes.includes(type);
}

export const getEmptyVariableType = (type: VariableDataType): VariableType => {
  switch (type) {
    case VariableDataType.STRING:
      return { text: {} };
    case VariableDataType.ARRAY:
      return { array: {} };
    case VariableDataType.TABLE:
      return { array: { entryType: { record: { fields: {} } } } };
    case VariableDataType.RECORD:
      return { record: {} };
    case VariableDataType.INTEGER:
      return { integer: {} };
    case VariableDataType.MONEY:
      return { money: {} };
    case VariableDataType.BOOLEAN:
      return { boolean: {} };
    case VariableDataType.DATE:
      return { date: {} };
    case VariableDataType.FLOAT:
      return { float: {} };
    case VariableDataType.SELECT:
      return { select: {} };
    case VariableDataType.DOCUMENT:
      return { resource: ResourceType.DOCUMENT };
    default:
      return {};
  }
};

export const getEmptyVariableValues = (
  type: VariableDataType,
): VariableValue => {
  switch (type) {
    case VariableDataType.STRING:
      return { text: {} };
    case VariableDataType.ARRAY:
      return { array: {} };
    case VariableDataType.TABLE:
      return { array: { entries: [{ record: { fields: {} } }] } };
    case VariableDataType.RECORD:
      return { record: {} };
    case VariableDataType.INTEGER:
      return { integer: {} };
    case VariableDataType.MONEY:
      return { money: {} };
    case VariableDataType.BOOLEAN:
      return { boolean: {} };
    case VariableDataType.DATE:
      return { date: {} };
    case VariableDataType.FLOAT:
      return { float: {} };
    case VariableDataType.SELECT:
      return { select: {} };
    case VariableDataType.DOCUMENT:
      return { document: {} };
    default:
      return {};
  }
};

export function getDisplayVariableValue(variableValue?: VariableValue): string {
  if (!variableValue) {
    return '';
  }

  if (variableValue.text !== undefined) {
    return variableValue?.text?.value ?? '';
  }

  if (variableValue.boolean !== undefined) {
    return variableValue.boolean.value ? 'True' : 'False';
  }

  if (variableValue.integer !== undefined) {
    return `${variableValue.integer.value}`;
  }

  if (variableValue.float !== undefined) {
    return `${variableValue.float.value}`;
  }

  if (variableValue.array !== undefined) {
    const array = variableValue.array.entries ?? [];
    return array.map(getDisplayVariableValue).join(', ');
  }

  if (variableValue.record !== undefined) {
    const fields = variableValue.record.fields ?? {};
    return Object.keys(fields).join(', ');
  }

  if (variableValue.date !== undefined) {
    const date = variableValue.date;
    if (date.year && date.month && date.day) {
      const month = date.month.toString().padStart(2, '0'); // padStart to ensure month and day are two digits
      const day = date.day.toString().padStart(2, '0');
      const year = date.year;

      return `${month}/${day}/${year}`;
    }
    return '';
  }

  if (variableValue.document !== undefined) {
    return variableValue.document.filename ?? '';
  }

  // TODO: Fix this right now this does not handle the nano's
  // use moneyToText from normalization utils in orby-web-app repo
  if (variableValue.money !== undefined) {
    const money = variableValue.money;
    return `${money.currencyCode} ${money.units}`;
  }

  if (variableValue.select !== undefined) {
    const select = variableValue.select.value;
    return select ?? '';
  }

  return '';
}

export type NewVariable = {
  name: string;
  type: VariableDataType;
  value?: string;
};

// TODO: reuse the createVariable from TestCase Input
export function createVariable(newVariable: {
  type: VariableDataType;
  name?: string;
  value?: string;
}) {
  const variable = Variable.create({ name: newVariable.name });

  if (newVariable.type === VariableDataType.STRING) {
    variable.type = {
      text: {
        type: TextFieldType.TEXT,
      },
    };
    variable.value = { text: { value: newVariable.value } };
  }

  if (newVariable.type === VariableDataType.FLOAT) {
    const value = Number(newVariable.value);
    variable.type = {
      float: {},
    };
    variable.value = { float: { value } };
  }

  if (newVariable.type === VariableDataType.INTEGER) {
    const value = Number(newVariable.value);
    variable.type = {
      integer: {},
    };
    variable.value = { integer: { value } };
  }

  if (newVariable.type === VariableDataType.BOOLEAN) {
    const value = newVariable.value === 'True';
    variable.type = {
      boolean: {},
    };
    variable.value = { boolean: { value } };
  }

  if (newVariable.type === VariableDataType.RECORD) {
    const inputValue = JSON.parse(newVariable.value ?? '');
    const fieldsType: { [key: string]: VariableType } = {};
    const fieldsValue: { [key: string]: VariableValue } = {};
    Object.keys(inputValue).forEach((key) => {
      const value = inputValue[key];
      let type = VariableDataType.STRING;
      // TODO: need to differenciate integer/float types
      if (typeof value === 'number') {
        type = VariableDataType.INTEGER;
      }
      const variable = createVariable({
        type,
        value,
      });
      if (variable.type) {
        fieldsType[key] = variable.type;
      }
      if (variable.value) {
        fieldsValue[key] = variable.value;
      }
    });
    variable.type = {
      record: {
        fields: fieldsType,
      },
    };
    variable.value = {
      record: {
        fields: fieldsValue,
      },
    };
  }

  // TODO: support Table variable creation
  if (newVariable.type === VariableDataType.TABLE) {
    variable.type = {
      array: {
        entryType: {
          record: {
            fields: {},
          },
        },
      },
    };
    variable.value = {
      array: {
        entries: [],
      },
    };
  }

  return variable;
}

// TODO: reuse the placeholder from TestCase Input
export function getVariablePlaceholder(type: VariableDataType) {
  if (type === VariableDataType.RECORD) {
    const result = {
      '<key 1>': '<value 1>',
      '<key 2>': '<value 2>',
    };

    return JSON.stringify(result, null, 2);
  }

  if (type === VariableDataType.TABLE) {
    const result = {
      '<key 1>': ['<value 1>', '<value 2>'],
      '<key 2>': ['<value 3>', '<value 3>'],
    };
    return JSON.stringify(result, null, 2);
  }
}
export const OUTPUT_VARIABLE_OPTIONS = [
  { label: 'String', value: VariableDataType.STRING },
  { label: 'Table', value: VariableDataType.TABLE },
  { label: 'Integer', value: VariableDataType.INTEGER },
  { label: 'Array', value: VariableDataType.ARRAY },
  { label: 'Float', value: VariableDataType.FLOAT },
  { label: 'Boolean', value: VariableDataType.BOOLEAN },
  { label: 'Date', value: VariableDataType.DATE },
  { label: 'Money', value: VariableDataType.MONEY },
  { label: 'Select', value: VariableDataType.SELECT },
  { label: 'Record', value: VariableDataType.RECORD },
  { label: 'Document', value: VariableDataType.DOCUMENT },
];

/**
 * Valid parent variable data types that can have child variables
 * with specific type restrictions
 */
export type ParentVariableDataType =
  | VariableDataType.TABLE
  | VariableDataType.RECORD
  | VariableDataType.ARRAY;

/**
 * Maps parent variable types to the variable types that should be excluded
 * from their child variable options
 */
export const parentTypeExclusionMap: Record<
  ParentVariableDataType,
  VariableDataType[]
> = {
  [VariableDataType.TABLE]: [
    VariableDataType.TABLE,
    VariableDataType.RECORD,
    VariableDataType.ARRAY,
  ],
  [VariableDataType.RECORD]: [VariableDataType.RECORD],
  [VariableDataType.ARRAY]: [
    VariableDataType.ARRAY,
    VariableDataType.TABLE,
    VariableDataType.RECORD,
  ],
};

/**
 * Filters the available variable data types based on the parent type
 * Each parent type has specific restrictions on what child types it can contain
 *
 * @param parentType - The parent data type (TABLE, RECORD, or ARRAY)
 * @param isDocExtraction - Whether this is being used in document extraction context
 * @returns Filtered list of variable type options valid for the given parent
 */
export const getOutputVariableOptions = (
  parentType: ParentVariableDataType,
  isDocExtraction: boolean = false,
) => {
  let variableOptions = [...OUTPUT_VARIABLE_OPTIONS];

  if (isDocExtraction) {
    // Only allow TABLE and STRING for doc extraction
    variableOptions = variableOptions.filter((option) =>
      [VariableDataType.TABLE, VariableDataType.STRING].includes(option.value),
    );
  }

  return variableOptions.filter(
    (option) => !parentTypeExclusionMap[parentType].includes(option.value),
  );
};
