import React from 'react';

import { KeyOutlined, KitchenTwoTone } from '@mui/icons-material';

import { DrawerTabType } from './DrawerTab';

import { ReactComponent as DashBoardHoverIcon } from '../../static/icons/drawer/home/full.svg';
import { ReactComponent as DashBoardIcon } from '../../static/icons/drawer/home/line.svg';

import { ReactComponent as WorkflowHoverIcon } from '../../static/icons/drawer/workflow/full.svg';
import { ReactComponent as WorkflowIcon } from '../../static/icons/drawer/workflow/line.svg';

import { ReactComponent as TaskHoverIcon } from '../../static/icons/drawer/task/full.svg';
import { ReactComponent as TaskIcon } from '../../static/icons/drawer/task/line.svg';

import { ReactComponent as ExecutionHoverIcon } from '../../static/icons/drawer/execution/full.svg';
import { ReactComponent as ExecutionIcon } from '../../static/icons/drawer/execution/line.svg';

import { ReactComponent as TeamsHoverIcon } from '../../static/icons/drawer/team/full.svg';
import { ReactComponent as TeamsIcon } from '../../static/icons/drawer/team/line.svg';

import { ReactComponent as SettingHoverIcon } from '../../static/icons/drawer/setting/full.svg';
import { ReactComponent as SettingIcon } from '../../static/icons/drawer/setting/line.svg';

import { ReactComponent as TrashHoverIcon } from '../../static/icons/drawer/trash/full.svg';
import { ReactComponent as TrashIcon } from '../../static/icons/drawer/trash/line.svg';

import { ReactComponent as ProcessDiscoveryIcon } from '../../static/icons/drawer/process-discovery/line.svg';

import { APP_ROUTES } from '../../pages/ProtectedRoutes/Routes';
import _ from 'lodash';

const buildRoute = (path: string, orgId?: string) => {
  // If we have an orgId, try to use the new route pattern
  if (orgId) {
    return path.replace(':organization_id', orgId);
  }
  // Fallback to legacy route (without organization)
  return path.replace('/organizations/:organization_id', '');
};

export const DRAWER_TABS: DrawerTabType[] = [
  {
    title: 'Homepage',
    route: _.partial(buildRoute, APP_ROUTES.DASHBOARD),
    icon: <DashBoardIcon />,
    hoverIcon: <DashBoardHoverIcon />,
  },
  {
    title: 'Workflows',
    route: _.partial(buildRoute, APP_ROUTES.WORKFLOW),
    icon: <WorkflowIcon />,
    hoverIcon: <WorkflowHoverIcon />,
  },
  {
    title: 'Process Discovery',
    route: _.partial(buildRoute, APP_ROUTES.PROCESS_DISCOVERY),
    icon: <ProcessDiscoveryIcon />,
    hoverIcon: <ProcessDiscoveryIcon />,
  },
  {
    title: 'Tasks',
    route: _.partial(buildRoute, APP_ROUTES.TASKS),
    icon: <TaskIcon />,
    hoverIcon: <TaskHoverIcon />,
  },
  {
    title: 'Executions',
    route: _.partial(buildRoute, APP_ROUTES.EXECUTIONS),
    icon: <ExecutionIcon />,
    hoverIcon: <ExecutionHoverIcon />,
  },
  {
    title: 'Trash Can',
    route: _.partial(buildRoute, APP_ROUTES.DELETED_ITEMS),
    icon: <TrashIcon />,
    hoverIcon: <TrashHoverIcon />,
  },
  {
    title: 'Teams',
    route: _.partial(buildRoute, APP_ROUTES.TEAMS),
    icon: <TeamsIcon />,
    hoverIcon: <TeamsHoverIcon />,
  },
  {
    title: 'Settings',
    route: _.partial(buildRoute, APP_ROUTES.SETTINGS),
    icon: <SettingIcon />,
    hoverIcon: <SettingHoverIcon />,
    subTabs: [
      {
        title: 'General',
        route: _.partial(buildRoute, APP_ROUTES.SETTINGS),
      },
      {
        title: 'Company non-working days',
        route: _.partial(buildRoute, APP_ROUTES.COMPANY_NON_WORKING_DAYS),
      },
      {
        title: 'Machines',
        route: _.partial(buildRoute, APP_ROUTES.MACHINES),
      },
      {
        title: 'Webhooks',
        route: _.partial(buildRoute, APP_ROUTES.WEBHOOKS),
      },
      {
        title: 'API Keys',
        route: _.partial(buildRoute, APP_ROUTES.API_KEYS_MANAGEMENT),
      },
    ],
  },
  {
    title: 'ML Testing',
    route: _.constant(APP_ROUTES.DEV_TESTING),
    description: 'INTERNAL',
    icon: <KeyOutlined />,
    hoverIcon: <KeyOutlined />,
  },
  {
    title: 'Integration Test',
    route: _.constant(APP_ROUTES.INTEGRATION_TEST),
    icon: <KitchenTwoTone />,
    hoverIcon: <KitchenTwoTone />,
  },
  {
    title: 'Feature Flags',
    route: _.constant(APP_ROUTES.FEATURE_FLAGS),
    description: 'INTERNAL',
    icon: <KeyOutlined />,
    hoverIcon: <KeyOutlined />,
  },
];
