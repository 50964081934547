import * as Sentry from '@sentry/react';
import { Extras, CaptureContext } from '@sentry/types';
import { IS_DEV, IS_LOCAL } from '../utils/constants';

class SentryService {
  private static _instance: SentryService;

  static getInstance(): SentryService {
    if (!this._instance) {
      this._instance = new SentryService();
    }
    return this._instance;
  }

  init(): void {
    Sentry.init({
      // Disable Sentry in local development to avoid spamming the dashboard
      enabled: !IS_LOCAL,
      attachStacktrace: true, // attaches stacktrace to custom messages
      dsn: process.env.REACT_APP_SENTRY_DSN,
      release: process.env.REACT_APP_SENTRY_RELEASE, // This env is dynamically populated to latest commit hash, during the build process in GitHub Workflows
      environment: process.env.REACT_APP_ENV,
      sendDefaultPii: false,
      integrations: [new Sentry.BrowserTracing({}), new Sentry.Replay()],
      // Performance Monitoring
      // TODO: add lower sampling rate for non prod envs
      tracesSampleRate: 1.0, // Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }

  // This method is used for logging custom messages
  // Note: Only events with severity level as `error` will be notified on slack channel (For more info see alert rules on sentry dashboard)
  private sendMessage(
    message: string,
    captureContext?: CaptureContext,
  ): string {
    return Sentry.captureMessage(message, captureContext);
  }

  /**
   * @param {string} message Captures a message event (level=debug) and sends it to Sentry
   * @param {Extras} extras Plain Javascript object for logging additional info
   */
  // Only used in local and development env
  debug(message: string, extras?: Extras) {
    if (IS_LOCAL || IS_DEV) {
      this.sendMessage(message, {
        extra: extras,
        level: 'debug',
      });
    }
  }

  /**
   * @param {string} message Captures a message event (level=info) and sends it to Sentry
   * @param {Extras} extras Plain Javascript object for logging additional info
   */
  info(message: string, extras?: Extras) {
    this.sendMessage(message, {
      extra: extras,
      level: 'info',
    });
  }

  /**
   * @param {string} message Captures a message event (level=warning) and sends it to Sentry
   * @param {Extras} extras Plain Javascript object for logging additional info
   */
  warn(message: string, extras?: Extras) {
    this.sendMessage(message, {
      extra: extras,
      level: 'warning',
    });
  }

  /**
   * @param {string} message Captures a message event (level=error) and sends it to Sentry
   * @param {Extras} extras Plain Javascript object for logging additional info
   */
  error(message: string, extras?: Extras) {
    Sentry.captureException(message, {
      extra: extras,
      level: 'error',
    });
  }
}

export const sentryService = SentryService.getInstance();
