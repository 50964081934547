import React from 'react';
import { Box, SxProps } from '@mui/material';
import OrbyTypography from '../typography/OrbyTypography';
import OrbyColorPalette from '../colors/ColorPalette';

// Variable icon with code braces symbol
const VariableIcon = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect x='2' y='2' width='16' height='16' rx='8' fill='#884DFF' />
    <path
      d='M7 6V6C6.44772 6 5.96877 6.44662 5.93024 6.99756L5.7703 9.28487C5.76105 9.41716 5.6995 9.54402 5.59919 9.63756L5.37226 9.84918C5.28871 9.92709 5.27987 10.0534 5.35253 10.1313L5.54986 10.3429C5.63709 10.4365 5.6809 10.5634 5.67165 10.6956L5.5117 12.9829C5.47318 13.5339 5.88966 13.9805 6.44195 13.9805V13.9805'
      stroke='white'
      stroke-linecap='round'
    />
    <path
      d='M13.55 6V6C14.1023 6 14.5188 6.44662 14.4802 6.99756L14.3203 9.28487C14.311 9.41716 14.3548 9.54402 14.4421 9.63756L14.6394 9.84918C14.7121 9.92709 14.7032 10.0534 14.6197 10.1313L14.3927 10.3429C14.2924 10.4365 14.2309 10.5634 14.2216 10.6956L14.0617 12.9829C14.0232 13.5339 13.5442 13.9805 12.9919 13.9805V13.9805'
      stroke='white'
      stroke-linecap='round'
    />
    <path
      d='M11.8453 7.54248L8.06103 12.4454'
      stroke='white'
      stroke-linecap='round'
    />
    <path
      d='M8.40002 7.54248L11.4986 12.4454'
      stroke='white'
      stroke-linecap='round'
    />
  </svg>
);

interface VariableBadgeProps {
  label?: string;
  badgeSx?: SxProps;
  iconSx?: SxProps;
  textSx?: SxProps;
}

const VariableBadge: React.FC<VariableBadgeProps> = ({
  label = 'Variable Name',
  badgeSx,
  iconSx,
  textSx,
}) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      gap='4px'
      sx={{
        backgroundColor: OrbyColorPalette['purple-100'],
        borderRadius: '16px',
        padding: '2px 8px 2px 4px',
        height: '24px',
        ...badgeSx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: '-2px', // Adjust for the new icon's viewBox
          ...iconSx,
        }}
      >
        <VariableIcon />
      </Box>
      <OrbyTypography
        color={OrbyColorPalette['purple-700']}
        size='xs'
        weight='medium'
        sx={{
          whiteSpace: 'nowrap',
          lineHeight: 1,
          display: 'flex',
          alignItems: 'center',
          ...textSx,
        }}
      >
        {label}
      </OrbyTypography>
    </Box>
  );
};

export default VariableBadge;
