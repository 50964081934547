import React, { FC, memo } from 'react';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { SxProps, Theme } from '@mui/material';
import OrbyColorPalette from '../colors/ColorPalette';

export interface OrbyGaugeChartProps {
  value: number;
  startAngle?: number;
  endAngle?: number;
  innerRadius?: string;
  outerRadius?: string;
  cornerRadius?: string;
  fillColor?: string;
  sx?: SxProps<Theme>;
}

const OrbyGaugeChart: FC<OrbyGaugeChartProps> = ({
  value,
  startAngle = -100,
  endAngle = 100,
  innerRadius = '88%',
  outerRadius = '100%',
  cornerRadius = '50%',
  fillColor = OrbyColorPalette['purple-700'],
  sx = {},
}) => {
  return (
    <Gauge
      width={250}
      height={125}
      value={value}
      startAngle={startAngle}
      endAngle={endAngle}
      innerRadius={innerRadius}
      outerRadius={outerRadius}
      text={({ value }) => (value ? `${value} %` : '-')}
      cornerRadius={cornerRadius}
      sx={() => ({
        [`& .${gaugeClasses.valueText}`]: {
          //TODO: Add font size to typography utils
          fontSize: '28px',
          fontWeight: '600',
          lineHeight: '32.9px',
          transform: 'translate(0px, -25px)',
          color: OrbyColorPalette['grey-900'],
          textAnchor: 'middle',
          dominantBaseline: 'middle',
          fontFamily: 'var(--font-family-inter-variable)',
          ...sx,
        },
        [`& .${gaugeClasses.valueArc}`]: {
          fill: fillColor,
        },
        [`& .${gaugeClasses.referenceArc}`]: {
          fill: OrbyColorPalette['grey-200'],
        },
      })}
    />
  );
};

export default memo(OrbyGaugeChart);
