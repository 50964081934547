import React from 'react';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { Box, IconButton } from '@mui/material';
import { Variable, VariableType } from 'protos/pb/v1alpha1/variables';
import { useState } from 'react';
import {
  DisplayVariableRecord,
  getDisplayVariable,
  getVariableType,
} from 'workflow-utils/src/variable';
import OrbyTypography from '../typography/OrbyTypography';
import OrbyColorPalette from '../colors/ColorPalette';
import OrbyBadge from '../badge/OrbyBadge';
import OrbyButton from '../button/OrbyButton';
import { getFontSize } from '../typography/typography-utils';
import WorkflowVariableValue from './WorkflowVariableValue';
import CheckIcon from '@mui/icons-material/Check';

const WorkflowVariableRecord = (props: {
  variable: Variable;
  selectToCopyContext?: {
    copyFn?: (
      variableValue: string,
      referenceValueKey?: string,
    ) => Promise<void>;
    isSelected?: (refKey?: string) => boolean;
    onSelect?: (refKey?: string) => void;
  };
  inSidePanel?: boolean;
}) => {
  const { variable, inSidePanel, selectToCopyContext } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const displayVariable = getDisplayVariable(variable);

  if (!displayVariable) return null;

  return (
    <Box
      sx={{
        padding: '8px 12px',
        display: 'flex',
        flexDirection: 'column',
        gap: '2px',
        '&:hover': {
          backgroundColor: OrbyColorPalette['grey-100'],
        },
      }}
    >
      <Box
        sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
          }}
        >
          <OrbyTypography weight='medium' color={OrbyColorPalette['grey-900']}>
            {variable.name}
          </OrbyTypography>

          <OrbyBadge
            size='small'
            textColor={OrbyColorPalette['grey-700']}
            backgroundColor={OrbyColorPalette['grey-200']}
            badgeName='Record'
          />
        </Box>

        <IconButton
          title='Expand'
          aria-label='Expand'
          key={'Expand'}
          onClick={(e) => {
            e.stopPropagation();
            setIsExpanded(!isExpanded);
          }}
          sx={{
            height: '24px',
            width: '24px',
            transform: isExpanded ? 'rotate(270deg)' : 'rotate(90deg)',
            cursor: 'pointer',
          }}
        >
          <ChevronRightRoundedIcon
            sx={{
              color: OrbyColorPalette['grey-900'],
              height: '20x',
              width: '20px',
            }}
          />
        </IconButton>
      </Box>

      {!isExpanded ? (
        <OrbyTypography
          color={OrbyColorPalette['grey-700']}
          sx={{ wordBreak: 'break-all' }}
        >
          {Object.keys(displayVariable.value).join(', ')}
        </OrbyTypography>
      ) : (
        <WorkflowVariableFields
          variable={variable}
          selectToCopyContext={selectToCopyContext}
          inSidePanel={inSidePanel}
        />
      )}
    </Box>
  );
};

const WorkflowVariableFields = (props: {
  variable: Variable;
  selectToCopyContext?: {
    copyFn?: (
      variableValue: string,
      referenceValueKey?: string,
    ) => Promise<void>;
    isSelected?: (refKey?: string) => boolean;
    onSelect?: (refKey?: string) => void;
  };
  inSidePanel?: boolean;
}) => {
  const { variable, inSidePanel, selectToCopyContext } = props;
  const fields = Object.keys(variable.type?.record?.fields ?? {});

  const displayVariable = getDisplayVariable(variable) as DisplayVariableRecord;

  return (
    <Box
      sx={{
        marginTop: '8px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        borderLeft: '1px solid',
        borderColor: OrbyColorPalette['grey-300'],
      }}
    >
      {fields.map((field) => {
        const type = variable.type?.record?.fields![field];
        return (
          <WorkFlowVariableField
            variable={variable}
            field={field}
            type={type!}
            displayVariable={displayVariable}
            selectToCopyContext={selectToCopyContext}
            inSidePanel={inSidePanel}
          />
        );
      })}
    </Box>
  );
};

const WorkFlowVariableField = (props: {
  variable: Variable;
  field: string;
  type: VariableType;
  displayVariable: DisplayVariableRecord;
  selectToCopyContext?: {
    copyFn?: (
      variableValue: string,
      referenceValueKey?: string,
    ) => Promise<void>;
    isSelected?: (refKey?: string) => boolean;
    onSelect?: (refKey?: string) => void;
  };
  inSidePanel?: boolean;
}) => {
  const {
    variable,
    field,
    type,
    displayVariable,
    selectToCopyContext,
    inSidePanel,
  } = props;
  const copyFn = selectToCopyContext?.copyFn;
  const isSelected = selectToCopyContext?.isSelected;
  const onSelect = selectToCopyContext?.onSelect;
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Box
      key={field}
      sx={{
        padding: '4px 12px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '2px',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box sx={{ flexDirection: 'column' }}>
        <Box
          key={variable.name}
          sx={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
          }}
        >
          <OrbyTypography weight='medium' color={OrbyColorPalette['grey-900']}>
            {field}
          </OrbyTypography>

          <OrbyBadge
            size='small'
            textColor={OrbyColorPalette['grey-700']}
            backgroundColor={OrbyColorPalette['grey-200']}
            badgeName={getVariableType(type!)}
          />
        </Box>

        <WorkflowVariableValue
          value={displayVariable.value[field]}
          enableTooltip
        />
      </Box>
      {inSidePanel && isHovered && !isSelected?.(field) && (
        <OrbyButton
          label='Select'
          variant='monochrome-text'
          onClick={() => {
            copyFn?.(field, field);
            onSelect?.(field);
          }}
          sx={{ textTransform: 'none', fontSize: getFontSize('xs') }}
        />
      )}
      {inSidePanel && isSelected?.(field) && (
        <OrbyButton
          label='Selected'
          endIcon={<CheckIcon />}
          variant='primary-contained'
          onClick={() => onSelect?.(field)}
          sx={{ textTransform: 'none', fontSize: getFontSize('xs') }}
        />
      )}
    </Box>
  );
};

export default WorkflowVariableRecord;
