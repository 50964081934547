import { Empty } from 'protos/google/protobuf/empty';
import {
  CreateFeatureFlagRequest,
  DeleteFeatureFlagRequest,
  FeatureFlag,
  FeatureFlagsClientImpl,
  ListFeatureFlagsRequest,
  ListFeatureFlagsResponse,
  UpdateFeatureFlagRequest,
  GetFeatureFlagRequest,
  GetFeatureFlagsForOrgAndUserRequest,
  GetFeatureFlagsForOrgAndUserResponse,
  IsFeatureFlagEnabledRequest,
  IsFeatureFlagEnabledResponse,
} from 'protos/pb/v1alpha2/feature_flag_service';
import { storageService } from './StorageService';
import { rpcWithErrorHandling } from '../utils/RpcUtills';

export class FeatureFlagService {
  private static instance: FeatureFlagService;

  private static client = new FeatureFlagsClientImpl(rpcWithErrorHandling);

  public static getInstance(): FeatureFlagService {
    if (!this.instance) {
      this.instance = new FeatureFlagService();
    }
    return this.instance;
  }

  async getFeatureFlags(
    req: ListFeatureFlagsRequest,
  ): Promise<{ response?: ListFeatureFlagsResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await FeatureFlagService.client.ListFeatureFlags(req, {
        authorization,
      } as any);
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async createFeatureFlag(
    req: CreateFeatureFlagRequest,
  ): Promise<{ response?: FeatureFlag; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await FeatureFlagService.client.CreateFeatureFlag(req, {
        authorization,
      } as any);
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async updateFeatureFlag(
    req: UpdateFeatureFlagRequest,
  ): Promise<{ response?: FeatureFlag; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await FeatureFlagService.client.UpdateFeatureFlag(req, {
        authorization,
      } as any);
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async deleteFeatureFlag(req: DeleteFeatureFlagRequest): Promise<Empty> {
    const authorization = await storageService.getAuthorizationHeader();
    return await FeatureFlagService.client.DeleteFeatureFlag(req, {
      authorization,
    } as any);
  }

  async getFeatureFlag(
    req: GetFeatureFlagRequest,
  ): Promise<{ response?: FeatureFlag; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await FeatureFlagService.client.GetFeatureFlag(req, {
        authorization,
      } as any);
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async getFeatureFlagsForOrgAndUser(
    req: GetFeatureFlagsForOrgAndUserRequest,
  ): Promise<{
    response?: GetFeatureFlagsForOrgAndUserResponse;
    error?: Error;
  }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response =
        await FeatureFlagService.client.GetFeatureFlagsForOrgAndUser(req, {
          authorization,
        } as any);
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async isFeatureFlagEnabled(
    req: IsFeatureFlagEnabledRequest,
  ): Promise<{ response?: IsFeatureFlagEnabledResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await FeatureFlagService.client.IsFeatureFlagEnabled(
        req,
        {
          authorization,
        } as any,
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }
}

export const featureFlagService = FeatureFlagService.getInstance();
