import {
  DeleteHolidayListRequest,
  GetHolidayListRequest,
  GetOrganizationRequest,
  HolidayList,
  ListHolidayListsRequest,
  UpdateOrganizationRequest,
  UpsertHolidayListRequest,
  ListMicrosoftApplicationUsersRequest,
} from 'protos/pb/v1alpha1/organization_service';
import { OrganizationActionType } from './actions.constants';
import { Organization } from 'protos/pb/v1alpha1/organization';
import { User } from 'protos/pb/v1alpha1/user';

export const getOrganizationAction = (req: GetOrganizationRequest) => ({
  type: OrganizationActionType.GET_ORGANIZATION,
  payload: req,
});

export const getOrganizationCompletedAction = (org: Organization) => ({
  type: OrganizationActionType.GET_ORGANIZATION_COMPLETED,
  payload: org,
});

export const getOrganizationErrorAction = (error?: Error) => ({
  type: OrganizationActionType.GET_ORGANIZATION_ERROR,
  payload: error,
});

export const updateOrganizationAction = (req: UpdateOrganizationRequest) => ({
  type: OrganizationActionType.UPDATE_ORGANIZATION,
  payload: req,
});

export const updateOrganizationCompletedAction = (payload: Organization) => ({
  type: OrganizationActionType.UPDATE_ORGANIZATION_COMPLETED,
  payload,
});

export const updateOrganizationErrorAction = (error?: Error) => ({
  type: OrganizationActionType.UPDATE_ORGANIZATION_ERROR,
  payload: error,
});

export const clearOrganizationUpdateStatusAction = () => ({
  type: OrganizationActionType.CLEAR_UPDATE_STATUS,
});

export const upsertHolidayListAction = (req: UpsertHolidayListRequest) => ({
  type: OrganizationActionType.UPSERT_HOLIDAY_LIST,
  payload: req,
});

export const upsertHolidayListCompletedAction = (success: boolean) => ({
  type: OrganizationActionType.UPSERT_HOLIDAY_LIST_COMPLETED,
  payload: success,
});

export const upsertHolidayListErrorAction = (error?: Error) => ({
  type: OrganizationActionType.UPSERT_HOLIDAY_LIST_ERROR,
  payload: error,
});

export const listHolidayListsAction = (req: ListHolidayListsRequest) => ({
  type: OrganizationActionType.LIST_HOLIDAY_LISTS,
  payload: req,
});

export const listHolidayListsCompletedAction = (
  holidayLists: HolidayList[],
) => ({
  type: OrganizationActionType.LIST_HOLIDAY_LISTS_COMPLETED,
  payload: holidayLists,
});

export const listHolidayListsErrorAction = (error?: Error) => ({
  type: OrganizationActionType.LIST_HOLIDAY_LISTS_ERROR,
  payload: error,
});

export const listHolidayListsIsLoadingAction = (isLoading: boolean) => ({
  type: OrganizationActionType.LIST_HOLIDAY_LISTS_IS_LOADING,
  payload: isLoading,
});

export const getSingleHolidayListAction = (req: GetHolidayListRequest) => ({
  type: OrganizationActionType.GET_SINGLE_HOLIDAY,
  payload: req,
});

export const getSingleHolidayListCompletedAction = (
  holidayList: HolidayList,
) => ({
  type: OrganizationActionType.GET_SINGLE_HOLIDAY_COMPLETED,
  payload: holidayList,
});

export const getSingleHolidayListErrorAction = (error?: Error) => ({
  type: OrganizationActionType.GET_SINGLE_HOLIDAY_ERROR,
  payload: error,
});

export const getSingleHolidayListIsLoadingAction = (isLoading: boolean) => ({
  type: OrganizationActionType.GET_SINGLE_HOLIDAY_IS_LOADING,
  payload: isLoading,
});

export const deleteHolidayListAction = (req: DeleteHolidayListRequest) => ({
  type: OrganizationActionType.DELETE_HOLIDAY_LIST,
  payload: req,
});

export const deleteHolidayListCompletedAction = (success: boolean) => ({
  type: OrganizationActionType.DELETE_HOLIDAY_LIST_COMPLETED,
  payload: success,
});

export const deleteHolidayListLoadingAction = (loading: boolean) => ({
  type: OrganizationActionType.DELETE_HOLIDAY_LIST_LOADING,
  payload: loading,
});

export const deleteHolidayListErrorAction = (error?: Error) => ({
  type: OrganizationActionType.DELETE_HOLIDAY_LIST_ERROR,
  payload: error,
});

export const upsertHolidayListLoadingAction = (loading: boolean) => ({
  type: OrganizationActionType.UPSERT_HOLIDAY_LIST_LOADING,
  payload: loading,
});

export const listMicrosoftApplicationUsersAction = () => ({
  type: OrganizationActionType.LIST_MICROSOFT_APPLICATION_USERS,
  payload: ListMicrosoftApplicationUsersRequest.create({
    microsoftApplicationConfigId:
      process.env.REACT_APP_MICROSOFT_APPLICATION_CONFIG_ID,
  }),
});

export const listMicrosoftApplicationUsersCompletedAction = (
  users: User[] | undefined,
) => ({
  type: OrganizationActionType.LIST_MICROSOFT_APPLICATION_USERS_COMPLETED,
  payload: users,
});

export const listMicrosoftApplicationUsersErrorAction = (error?: Error) => ({
  type: OrganizationActionType.LIST_MICROSOFT_APPLICATION_USERS_ERROR,
  payload: error,
});

export const listMicrosoftApplicationUsersLoadingAction = (
  loading: boolean,
) => ({
  type: OrganizationActionType.LIST_MICROSOFT_APPLICATION_USERS_LOADING,
  payload: loading,
});

export const getMicrosoftUserAction = (
  microsoftApplicationConfigId: string,
  microsoftUserId: string,
) => ({
  type: OrganizationActionType.GET_MICROSOFT_USER,
  payload: { microsoftApplicationConfigId, microsoftUserId },
});

export const getMicrosoftUserSuccessAction = (
  microsoftUser: User | undefined,
) => ({
  type: OrganizationActionType.GET_MICROSOFT_USER_COMPLETED,
  payload: microsoftUser,
});

export const getMicrosoftUserFailureAction = (error: any) => ({
  type: OrganizationActionType.GET_MICROSOFT_USER_ERROR,
  payload: error,
});

export const getMicrosoftUserLoadingAction = (loading: boolean) => ({
  type: OrganizationActionType.GET_MICROSOFT_USER_LOADING,
  payload: loading,
});
