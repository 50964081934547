import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ObservationSession } from 'protos/pb/process_discovery/observation_session';
import { Action } from 'protos/pb/v1alpha1/orbot_action';
import {
  CreateProcessRequest,
  GenerateDocumentationRequest,
  GetProcessDefinitionRequest,
  GetProcessRequest,
  GetProcessResponse,
  GetTraceRequest,
  GetTraceUsersRequest,
  GetTraceUsersResponse,
  ListProcessesRequest,
  ListProcessesResponse,
  ListTracesRequest,
  UpdateProcessRequest,
  UpdateTraceRequest,
  UserDefinedProcess,
} from 'protos/pb/v1alpha2/process_discovery_service';
import { CustomNodeType } from '../../pages/ProcessDiscovery/components/CustomNode';
import { DataLoadingStatus } from '../../utils/constants';

export enum NodeToolbarOpenedBy {
  CLICK = 'CLICK',
  HOVER = 'HOVER',
}

export interface NodeToolbar {
  node?: CustomNodeType;
  openedBy?: NodeToolbarOpenedBy;
}

export interface EdgeCounts {
  [key: string]: number;
}

export interface TraceInfo {
  observationSession?: ObservationSession;
  actions?: Action[];
}

export interface ProcessDiscoveryState {
  //Process Data
  listProcesses: ListProcessesResponse | null;
  listProcessesLoadingStatus: DataLoadingStatus;
  listProcessesError: string | null;
  getProcess: GetProcessResponse | null;
  getProcessLoadingStatus: DataLoadingStatus;
  getProcessError: string | null;

  //Traces Data
  tracesData: TraceInfo[];
  totalTraces: number;
  totalUsers: number;
  selectedTraceIndex: number;
  listTracesLoadingStatus: DataLoadingStatus;
  listTracesError: string | null;
  getTraceLoadingStatus: DataLoadingStatus;
  getTraceError: string | null;
  createAutomatedWorkflowLoadingStatus: DataLoadingStatus;
  createAutomatedWorkflowError: string | null;

  editSessionNameLoadingStatus: DataLoadingStatus;
  editSessionNameError: string | null;

  //UI
  showSummarySection: boolean;
  hoveredNode: CustomNodeType | undefined;
  selectedNodes: CustomNodeType[];
  excludedNodeIds: string[];
  excludedInstanceIds: string[];
  selectedPath: number | undefined;
  nodeToolbar?: NodeToolbar;

  isFrequencyFilterOpen: boolean;
  frequencyRange: {
    min: number;
    max: number;
  };
  frequencyFilterSelectedValue: {
    min: number;
    max: number;
  };

  edgeCounts: EdgeCounts;

  traceUsers: GetTraceUsersResponse | null;
  traceUsersLoadingStatus: DataLoadingStatus;
  traceUsersError: string | null;
  activeDraftProcess: UserDefinedProcess | null;
  savedDraftProcess: UserDefinedProcess | null;

  createProcessLoadingStatus: DataLoadingStatus;
  createProcessError: string | null;
  getProcessDefinitionLoadingStatus: DataLoadingStatus;
  getProcessDefinitionError: string | null;
  updateProcessLoadingStatus: DataLoadingStatus;
  updateProcessError: string | null;
  generateDocumentationLoadingStatus: DataLoadingStatus;
  generateDocumentationError: string | null;
}

const initialState: ProcessDiscoveryState = {
  listProcesses: null,
  listProcessesLoadingStatus: DataLoadingStatus.INITIAL,
  listProcessesError: null,
  getProcess: null,
  getProcessLoadingStatus: DataLoadingStatus.INITIAL,
  getProcessError: null,
  tracesData: [],
  totalTraces: 0,
  totalUsers: 0,
  selectedTraceIndex: -1,
  listTracesLoadingStatus: DataLoadingStatus.INITIAL,
  listTracesError: null,
  getTraceLoadingStatus: DataLoadingStatus.INITIAL,
  getTraceError: null,
  createAutomatedWorkflowLoadingStatus: DataLoadingStatus.INITIAL,
  createAutomatedWorkflowError: null,

  editSessionNameLoadingStatus: DataLoadingStatus.INITIAL,
  editSessionNameError: null,

  showSummarySection: true,
  hoveredNode: undefined,
  selectedNodes: [],
  excludedNodeIds: [],
  excludedInstanceIds: [],
  selectedPath: undefined,
  nodeToolbar: undefined,

  isFrequencyFilterOpen: false,
  frequencyRange: {
    min: 0,
    max: 0,
  },
  frequencyFilterSelectedValue: {
    min: 0,
    max: 0,
  },

  edgeCounts: {},

  traceUsers: null,
  traceUsersLoadingStatus: DataLoadingStatus.INITIAL,
  traceUsersError: null,
  activeDraftProcess: null,
  savedDraftProcess: null,

  createProcessLoadingStatus: DataLoadingStatus.INITIAL,
  createProcessError: null,
  getProcessDefinitionLoadingStatus: DataLoadingStatus.INITIAL,
  getProcessDefinitionError: null,
  updateProcessLoadingStatus: DataLoadingStatus.INITIAL,
  updateProcessError: null,
  generateDocumentationLoadingStatus: DataLoadingStatus.INITIAL,
  generateDocumentationError: null,
};

// Actions for calling the process discovery services
export const listProcessesAction = createAction<ListProcessesRequest>(
  'processDiscovery/fetchProcesses',
);

export const getProcessAction = createAction<GetProcessRequest>(
  'processDiscovery/getProcess',
);

export const listTracesAction = createAction<ListTracesRequest>(
  'processDiscovery/fetchTraces',
);

export const getTraceAction = createAction<GetTraceRequest>(
  'processDiscovery/getTrace',
);

export const changeSelectedTraceIndexAction = createAction<number>(
  'processDiscovery/changeSelectedTraceIndex',
);

export const createAutomatedWorkflowAction = createAction<{
  actions: Action[];
  navigateFn: (URL: string) => void;
}>('processDiscovery/createAutomatedWorkflow');

export const editSessionNameAction = createAction<UpdateTraceRequest>(
  'processDiscovery/editSessionName',
);

export const getTraceUsers = createAction<GetTraceUsersRequest>(
  'processDiscovery/getTraceUsers',
);

export const createProcessAction = createAction<CreateProcessRequest>(
  'processDiscovery/createProcess',
);

export const getProcessDefinitionAction =
  createAction<GetProcessDefinitionRequest>(
    'processDiscovery/getProcessDefinition',
  );

export const updateProcessAction = createAction<UpdateProcessRequest>(
  'processDiscovery/updateProcess',
);

export const generateDocumentationAction =
  createAction<GenerateDocumentationRequest>(
    'processDiscovery/generateDocumentation',
  );

interface SetDraftProcessesPayload {
  savedDraftProcess?: UserDefinedProcess;
}

interface UpdateActiveDraftProcessPayload {
  updates: Partial<UserDefinedProcess>;
}

export const processDiscoverySlice = createSlice({
  name: 'processDiscovery',
  initialState,
  reducers: {
    setShowSummarySection: (state, action: PayloadAction<boolean>) => {
      state.showSummarySection = action.payload;
    },
    setHoveredNode: (
      state,
      action: PayloadAction<CustomNodeType | undefined>,
    ) => {
      state.hoveredNode = action.payload;
    },
    setSelectedNode: (state, action: PayloadAction<CustomNodeType>) => {
      const node = action.payload;
      if (state.selectedNodes.find((n) => n.id === node.id)) {
        state.selectedNodes = state.selectedNodes.filter(
          (n) => n.id !== node.id,
        );
        // If the node was clicked again, we can remove the node toolbar as well
        state.nodeToolbar = undefined;
      } else {
        state.selectedNodes = [...state.selectedNodes, node];
        // When a node is selected, we can show the node toolbar
        state.nodeToolbar = {
          node,
          openedBy: NodeToolbarOpenedBy.CLICK,
        };
      }

      // Apply both node selection and frequency filters
      applyFilters(state);
    },
    clearSelectedNodes: (state) => {
      state.selectedNodes = [];
      state.excludedInstanceIds = [];
      state.excludedNodeIds = [];
    },
    setSelectedPath: (state, action: PayloadAction<number | undefined>) => {
      state.selectedPath = action.payload;
    },
    setListProcesses: (state, action: PayloadAction<ListProcessesResponse>) => {
      state.listProcesses = action.payload;
    },
    setGetProcess: (state, action: PayloadAction<GetProcessResponse>) => {
      state.getProcess = action.payload;
      // Initialize edge counts
      const edgeCounts = new Map<string, number>();
      const processInstances = state.getProcess?.process?.processInstances;
      const stepNodes = state.getProcess?.process?.stepNodes;
      if (!processInstances) return;
      for (const instance of processInstances) {
        let steps = instance.stepNodeInstances || [];
        if (steps.length === 0) continue;
        // Temporary hack to add START and END nodes to the steps
        // These should be included in the process instance
        const firstNodeType = stepNodes?.find(
          (node) => node.id === steps[0].stepNodeId,
        )?.stepTypeId;
        const lastNodeType = stepNodes?.find(
          (node) => node.id === steps[steps.length - 1].stepNodeId,
        )?.stepTypeId;
        if (
          firstNodeType !== 'START' &&
          stepNodes?.find((node) => node.id === 'START')
        ) {
          steps = [{ stepNodeId: 'START' }, ...steps];
        }
        if (
          lastNodeType !== 'END' &&
          stepNodes?.find((node) => node.id === 'END')
        ) {
          steps = [...steps, { stepNodeId: 'END' }];
        }
        for (let i = 0; i < steps.length - 1; i++) {
          const currentStepId = steps[i].stepNodeId;
          const nextStepId = steps[i + 1].stepNodeId;
          if (!currentStepId || !nextStepId) continue;

          const edgeKey = `${currentStepId}-${nextStepId}`;
          edgeCounts.set(edgeKey, (edgeCounts.get(edgeKey) || 0) + 1);
        }
      }
      state.edgeCounts = Object.fromEntries(edgeCounts);
      // Calculate frequency range
      const minCount = Math.min(...Array.from(edgeCounts.values()));
      const maxCount = Math.max(...Array.from(edgeCounts.values()));
      state.frequencyRange = {
        min: minCount,
        max: maxCount,
      };
    },
    setListProcessesLoading: (
      state,
      action: PayloadAction<DataLoadingStatus>,
    ) => {
      state.listProcessesLoadingStatus = action.payload;
    },
    setGetProcessLoading: (state, action: PayloadAction<DataLoadingStatus>) => {
      state.getProcessLoadingStatus = action.payload;
    },
    setListProcessesError: (state, action: PayloadAction<string | null>) => {
      state.listProcessesError = action.payload;
    },
    setGetProcessError: (state, action: PayloadAction<string | null>) => {
      state.getProcessError = action.payload;
    },
    setNodeToolbar: (state, action: PayloadAction<NodeToolbar | undefined>) => {
      state.nodeToolbar = action.payload;
    },

    setIsFrequencyFilterOpen: (state, action: PayloadAction<boolean>) => {
      state.isFrequencyFilterOpen = action.payload;
    },

    setFrequencyFilterSelectedValue: (
      state,
      action: PayloadAction<{ min: number; max: number }>,
    ) => {
      if (action.payload.min >= 0 && action.payload.max >= 0) {
        state.frequencyFilterSelectedValue = action.payload;

        // Apply both node selection and frequency filters
        applyFilters(state);
      }
    },
    setTracesData: (state, action: PayloadAction<TraceInfo[]>) => {
      state.tracesData = action.payload;
    },
    setTotalTraces: (state, action: PayloadAction<number>) => {
      state.totalTraces = action.payload;
    },
    setTotalUsers: (state, action: PayloadAction<number>) => {
      state.totalUsers = action.payload;
    },
    setSelectedTraceIndex: (state, action: PayloadAction<number>) => {
      state.selectedTraceIndex = action.payload;
    },
    setListTracesLoading: (state, action: PayloadAction<DataLoadingStatus>) => {
      state.listTracesLoadingStatus = action.payload;
    },
    setListTracesError: (state, action: PayloadAction<string | null>) => {
      state.listTracesError = action.payload;
    },
    setGetTraceLoading: (state, action: PayloadAction<DataLoadingStatus>) => {
      state.getTraceLoadingStatus = action.payload;
    },
    setGetTraceError: (state, action: PayloadAction<string | null>) => {
      state.getTraceError = action.payload;
    },
    setCreateAutomatedWorkflowLoading: (
      state,
      action: PayloadAction<DataLoadingStatus>,
    ) => {
      state.createAutomatedWorkflowLoadingStatus = action.payload;
    },
    setCreateAutomatedWorkflowError: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.createAutomatedWorkflowError = action.payload;
    },
    setTraceActions: (
      state,
      action: PayloadAction<{ traceId: string; actions: Action[] }>,
    ) => {
      const index = state.tracesData.findIndex(
        (trace) => trace.observationSession?.id === action.payload.traceId,
      );
      if (index !== -1) {
        state.tracesData[index].actions = action.payload.actions;
      }
    },
    setTraceUsers: (
      state,
      action?: PayloadAction<GetTraceUsersResponse | null>,
    ) => {
      state.traceUsers = action?.payload || null;
    },
    setTraceUsersLoadingStatus: (
      state,
      action: PayloadAction<DataLoadingStatus>,
    ) => {
      state.traceUsersLoadingStatus = action.payload;
    },
    setTraceUsersError: (state, action: PayloadAction<string | null>) => {
      state.traceUsersError = action.payload;
    },

    setEditSessionNameLoading: (
      state,
      action: PayloadAction<DataLoadingStatus>,
    ) => {
      state.editSessionNameLoadingStatus = action.payload;
    },
    setEditSessionNameError: (state, action: PayloadAction<string | null>) => {
      state.editSessionNameError = action.payload;
    },

    clearProcess: (state) => {
      state.getProcess = null;
      state.getProcessLoadingStatus = DataLoadingStatus.INITIAL;
      state.getProcessError = null;
      state.nodeToolbar = undefined;
      state.selectedNodes = [];
      state.excludedNodeIds = [];
      state.excludedInstanceIds = [];
      state.selectedPath = undefined;
      state.isFrequencyFilterOpen = false;
      state.showSummarySection = true;
      state.frequencyRange = {
        min: 0,
        max: 0,
      };
      state.frequencyFilterSelectedValue = {
        min: 0,
        max: 0,
      };
      state.edgeCounts = {};
      state.tracesData = [];
      state.totalTraces = 0;
      state.totalUsers = 0;
      state.selectedTraceIndex = -1;
      state.listTracesLoadingStatus = DataLoadingStatus.INITIAL;
      state.listTracesError = null;
      state.getTraceLoadingStatus = DataLoadingStatus.INITIAL;
      state.getTraceError = null;
      state.createAutomatedWorkflowLoadingStatus = DataLoadingStatus.INITIAL;
      state.createAutomatedWorkflowError = null;
      state.editSessionNameLoadingStatus = DataLoadingStatus.INITIAL;
      state.editSessionNameError = null;
      state.traceUsers = null;
      state.traceUsersError = null;
      state.traceUsersLoadingStatus = DataLoadingStatus.INITIAL;
      state.savedDraftProcess = null;
      state.activeDraftProcess = null;
    },

    // Draft Process Reducers
    setDraftProcesses: (
      state,
      action: PayloadAction<SetDraftProcessesPayload>,
    ) => {
      const { savedDraftProcess } = action.payload;

      if (savedDraftProcess) {
        // If savedDraftProcess is provided, set both states
        state.savedDraftProcess = savedDraftProcess;
        state.activeDraftProcess = savedDraftProcess;
      } else {
        // If no savedDraftProcess, initialize empty active draft
        state.activeDraftProcess = {};
      }
    },

    updateActiveDraftProcess: (
      state,
      action: PayloadAction<UpdateActiveDraftProcessPayload>,
    ) => {
      if (state.activeDraftProcess) {
        state.activeDraftProcess = {
          ...state.activeDraftProcess,
          ...action.payload.updates,
        };
      }
    },

    setCreateProcessLoading: (
      state,
      action: PayloadAction<DataLoadingStatus>,
    ) => {
      state.createProcessLoadingStatus = action.payload;
    },
    setCreateProcessError: (state, action: PayloadAction<string | null>) => {
      state.createProcessError = action.payload;
    },
    setGetProcessDefinitionLoading: (
      state,
      action: PayloadAction<DataLoadingStatus>,
    ) => {
      state.getProcessDefinitionLoadingStatus = action.payload;
    },
    setGetProcessDefinitionError: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.getProcessDefinitionError = action.payload;
    },
    setUpdateProcessLoading: (
      state,
      action: PayloadAction<DataLoadingStatus>,
    ) => {
      state.updateProcessLoadingStatus = action.payload;
    },
    setUpdateProcessError: (state, action: PayloadAction<string | null>) => {
      state.updateProcessError = action.payload;
    },
    setGenerateDocumentationLoading: (
      state,
      action: PayloadAction<DataLoadingStatus>,
    ) => {
      state.generateDocumentationLoadingStatus = action.payload;
    },
    setGenerateDocumentationError: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.generateDocumentationError = action.payload;
    },
    resetProcessCreation: (state) => {
      state.activeDraftProcess = null;
      state.savedDraftProcess = null;
      state.createProcessLoadingStatus = DataLoadingStatus.INITIAL;
      state.createProcessError = null;
      state.updateProcessLoadingStatus = DataLoadingStatus.INITIAL;
      state.updateProcessError = null;
      state.generateDocumentationError = null;
      state.generateDocumentationLoadingStatus = DataLoadingStatus.INITIAL;
      state.getProcessDefinitionLoadingStatus = DataLoadingStatus.INITIAL;
      state.getProcessDefinitionError = null;
    },
  },
  selectors: {
    showSummarySectionSelector: (state) => state.showSummarySection,
    hoveredNodeSelector: (state) => state.hoveredNode,
    selectedNodesSelector: (state) => state.selectedNodes,
    excludedNodeIdsSelector: (state) => state.excludedNodeIds,
    excludedInstanceIdsSelector: (state) => state.excludedInstanceIds,
    selectedPathSelector: (state) => state.selectedPath,
    listProcessesSelector: (state) => state.listProcesses,
    getProcessSelector: (state) => state.getProcess,
    listProcessesLoadingStatusSelector: (state) =>
      state.listProcessesLoadingStatus,
    getProcessLoadingStatusSelector: (state) => state.getProcessLoadingStatus,
    listProcessesErrorSelector: (state) => state.listProcessesError,
    getProcessErrorSelector: (state) => state.getProcessError,
    processDiscoveryListStateSelector: (state) => ({
      processesData: state.listProcesses,
      isLoading: state.listProcessesLoadingStatus === DataLoadingStatus.LOADING,
      error: state.listProcessesError,
    }),
    nodeToolbarSelector: (state) => state.nodeToolbar,

    isFrequencyFilterOpenSelector: (state) => state.isFrequencyFilterOpen,
    frequencyRangeSelector: (state) => state.frequencyRange,
    frequencyFilterSelectedValueSelector: (state) =>
      state.frequencyFilterSelectedValue,
    edgeCountsSelector: (state) => state.edgeCounts,
    tracesDataSelector: (state) => state.tracesData,
    totalTracesSelector: (state) => state.totalTraces,
    totalUsersSelector: (state) => state.totalUsers,
    selectedTraceIndexSelector: (state) => state.selectedTraceIndex,
    listTracesLoadingStatusSelector: (state) => state.listTracesLoadingStatus,
    listTracesErrorSelector: (state) => state.listTracesError,
    getTraceLoadingStatusSelector: (state) => state.getTraceLoadingStatus,
    getTraceErrorSelector: (state) => state.getTraceError,
    createAutomatedWorkflowLoadingStatusSelector: (state) =>
      state.createAutomatedWorkflowLoadingStatus,
    createAutomatedWorkflowErrorSelector: (state) =>
      state.createAutomatedWorkflowError,
    selectedTraceSelector: (state) =>
      state.selectedTraceIndex >= 0
        ? state.tracesData[state.selectedTraceIndex]
        : null,
    traceUsersSelector: (state) =>
      // Creating this custom user filter list for the trace users
      // TODO: need to correct this when we have the correct type for the trace users from BE
      state.traceUsers?.users?.map((user: any) => ({
        email: user.email || '',
        fullName: user.fullName || '',
        id: user.id || '',
        profilePicture: user.profileImageUrl || '',
        value: `users/${user.id}`,
      })) || [],
    traceUsersLoadingStatusSelector: (state) => state.traceUsersLoadingStatus,
    traceUsersErrorSelector: (state) => state.traceUsersError,
    editSessionNameLoadingStatusSelector: (state) =>
      state.editSessionNameLoadingStatus,
    editSessionNameErrorSelector: (state) => state.editSessionNameError,
    getProcessDefinitionLoadingStatusSelector: (state) =>
      state.getProcessDefinitionLoadingStatus,
    getProcessDefinitionErrorSelector: (state) =>
      state.getProcessDefinitionError,
    createProcessLoadingStatusSelector: (state) =>
      state.createProcessLoadingStatus === DataLoadingStatus.LOADING,
    createProcessErrorSelector: (state) => state.createProcessError,
    updateProcessLoadingSelector: (state) =>
      state.updateProcessLoadingStatus === DataLoadingStatus.LOADING,
    updateProcessErrorSelector: (state) => state.updateProcessError,
    generateDocumentationErrorSelector: (state) =>
      state.generateDocumentationError,
    generateDocumentationLoadingSelector: (state) =>
      state.generateDocumentationLoadingStatus === DataLoadingStatus.LOADING,
  },
});

export const {
  setShowSummarySection,
  setHoveredNode,
  setSelectedNode,
  clearSelectedNodes,
  setSelectedPath,
  setListProcesses,
  setGetProcess,
  setListProcessesLoading,
  setGetProcessLoading,
  setListProcessesError,
  setGetProcessError,
  setIsFrequencyFilterOpen,
  setNodeToolbar,
  clearProcess,
  setFrequencyFilterSelectedValue,
  setTracesData,
  setTotalTraces,
  setTotalUsers,
  setSelectedTraceIndex,
  setListTracesLoading,
  setListTracesError,
  setGetTraceLoading,
  setGetTraceError,
  setTraceActions,
  setCreateAutomatedWorkflowLoading,
  setCreateAutomatedWorkflowError,
  setTraceUsers,
  setTraceUsersLoadingStatus,
  setTraceUsersError,
  setEditSessionNameLoading,
  setEditSessionNameError,
  setDraftProcesses,
  updateActiveDraftProcess,
  setCreateProcessLoading,
  setCreateProcessError,
  setGetProcessDefinitionLoading,
  setGetProcessDefinitionError,
  setUpdateProcessLoading,
  setUpdateProcessError,
  setGenerateDocumentationLoading,
  setGenerateDocumentationError,
  resetProcessCreation,
} = processDiscoverySlice.actions;

export const {
  showSummarySectionSelector,
  hoveredNodeSelector,
  selectedNodesSelector,
  excludedNodeIdsSelector,
  excludedInstanceIdsSelector,
  selectedPathSelector,
  listProcessesSelector,
  getProcessSelector,
  listProcessesLoadingStatusSelector,
  getProcessLoadingStatusSelector,
  listProcessesErrorSelector,
  getProcessErrorSelector,
  processDiscoveryListStateSelector,
  isFrequencyFilterOpenSelector,
  nodeToolbarSelector,
  frequencyRangeSelector,
  frequencyFilterSelectedValueSelector,
  edgeCountsSelector,
  tracesDataSelector,
  totalTracesSelector,
  totalUsersSelector,
  selectedTraceIndexSelector,
  listTracesLoadingStatusSelector,
  listTracesErrorSelector,
  getTraceLoadingStatusSelector,
  getTraceErrorSelector,
  selectedTraceSelector,
  createAutomatedWorkflowLoadingStatusSelector,
  createAutomatedWorkflowErrorSelector,

  traceUsersSelector,
  traceUsersLoadingStatusSelector,
  traceUsersErrorSelector,
  editSessionNameLoadingStatusSelector,
  editSessionNameErrorSelector,
  getProcessDefinitionLoadingStatusSelector,
  getProcessDefinitionErrorSelector,
  createProcessLoadingStatusSelector,
  createProcessErrorSelector,
  updateProcessLoadingSelector,
  updateProcessErrorSelector,
  generateDocumentationErrorSelector,
  generateDocumentationLoadingSelector,
} = processDiscoverySlice.selectors;

export default processDiscoverySlice.reducer;

const applyFilters = (state: ProcessDiscoveryState) => {
  const selectedNodeIds = new Set<string>();
  const excludedInstanceIds = new Set<string>();
  const { min, max } = state.frequencyFilterSelectedValue;

  state.getProcess?.process?.processInstances?.forEach((instance) => {
    if (!instance.id) return;

    // Check node selection criteria
    const hasAllSelectedNodes =
      state.selectedNodes.length === 0 ||
      state.selectedNodes.every((selectedNode) =>
        instance.stepNodeInstances?.some(
          (step) => step.stepNodeId === selectedNode.id,
        ),
      );

    // Check frequency criteria for all edges in this instance
    let meetsFrequencyFilter = true;
    const steps = instance.stepNodeInstances || [];
    for (let i = 0; i < steps.length - 1 && meetsFrequencyFilter; i++) {
      const currentStepId = steps[i].stepNodeId;
      const nextStepId = steps[i + 1].stepNodeId;
      if (!currentStepId || !nextStepId) continue;

      const edgeCount = state.edgeCounts[`${currentStepId}-${nextStepId}`];
      if (edgeCount === undefined || edgeCount < min || edgeCount > max) {
        meetsFrequencyFilter = false;
        break;
      }
    }

    // Instance is valid only if it meets both criteria
    if (hasAllSelectedNodes && meetsFrequencyFilter) {
      // Collect nodes from valid instances
      instance.stepNodeInstances?.forEach(
        (step) => step.stepNodeId && selectedNodeIds.add(step.stepNodeId),
      );
    } else {
      excludedInstanceIds.add(instance.id);
    }
  });

  // Calculate excluded nodes
  const excludedNodeIds = new Set<string>();
  state.getProcess?.process?.stepNodes?.forEach((step) => {
    if (
      step.id &&
      step.stepTypeId !== 'START' &&
      step.stepTypeId !== 'END' &&
      !selectedNodeIds.has(step.id)
    ) {
      excludedNodeIds.add(step.id);
    }
  });

  // If no nodes are selected and frequency filter is at default range, clear all filters
  if (
    state.selectedNodes.length === 0 &&
    min === state.frequencyRange.min &&
    max === state.frequencyRange.max
  ) {
    state.excludedInstanceIds = [];
    state.excludedNodeIds = [];
    state.nodeToolbar = undefined;
  } else {
    state.excludedInstanceIds = Array.from(excludedInstanceIds);
    state.excludedNodeIds = Array.from(excludedNodeIds);
  }
};
