import { generatePath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectedOrgIdSelector } from '../redux/selectors/user.selectors';

// This hook is used to generate paths for the app by adding the organization_id to the path

export const useGeneratePath = () => {
  const orgId = useSelector(selectedOrgIdSelector);

  return (path: string, extraParams?: Record<string, string | number>) => {
    return generatePath(path, {
      organization_id: orgId,
      ...extraParams,
    });
  };
};
