import { all, fork } from 'redux-saga/effects';
import authSaga from './auth.saga';
import invoiceSaga from './invoice.saga';
import userSaga from './user.saga';
import automationMiningSaga from './automation_mining.saga';
import workflowSaga from './workflow.saga';
import taskV2Saga from './taskV2.saga';
import dashboardSaga from './dashboard.saga';
import utilitySaga from './utility.saga';
import connectorSaga from './connector.saga';
import reviewTaskSaga from './review_task.saga';
import filterOptionsSaga from './filter_options.saga';
import archivedResourcesSaga from './archived_resources.saga';
import featureFlagsSaga from './feature_flags.saga';
import organizationSaga from './organization.saga';
import orbotSaga from './orbot.saga';
import workflowTaskSaga from './workflow_task.saga';
import workflowDetailsSaga from './workflow_details.saga';
import workflowSecretSaga from './workflow_secrets.saga';
import { machinesSaga } from './machines.saga';
import { processDiscoverySaga } from './process_discovery.saga';
import webhookSaga from './webhook.saga';
import { apiKeysSaga } from './api_keys.saga';
import smartActionsSaga from './smart_actions.saga';

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(userSaga),
    fork(invoiceSaga),
    fork(taskV2Saga),
    fork(automationMiningSaga),
    fork(workflowSaga),
    fork(dashboardSaga),
    fork(utilitySaga),
    fork(connectorSaga),
    fork(reviewTaskSaga),
    fork(filterOptionsSaga),
    fork(archivedResourcesSaga),
    fork(featureFlagsSaga),
    fork(organizationSaga),
    fork(orbotSaga),
    fork(workflowTaskSaga),
    fork(workflowDetailsSaga),
    fork(workflowSecretSaga),
    fork(machinesSaga),
    fork(processDiscoverySaga),
    fork(webhookSaga),
    fork(apiKeysSaga),
    fork(smartActionsSaga),
  ]);
}
