import {
  all,
  call,
  debounce,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { userService } from '../../services/UserService';
import { UserActionType } from '../actions/actions.constants';
import {
  addUserToOrganizationCompletedAction,
  addUserToOrganizationErrorAction,
  fetchLoggedInUserCompletedAction,
  fetchLoggedInUserErrorAction,
  listUsersCompletedAction,
  listUsersErrorAction,
  setSelectedOrgInfo,
  updateGoogleTokenAction,
  updateGoogleTokenCompletedAction,
  updateGoogleTokenErrorAction,
  updatePasswordCompletedAction,
  updatePasswordErrorAction,
  updateUserCompletedAction,
  updateUserErrorAction,
  getUserPermissionsCompletedAction,
  getUserPermissionsErrorAction,
  updateMicrosoftTokenAction,
  updateMicrosoftTokenErrorAction,
  updateMicrosoftTokenCompletedAction,
  setSelectedOrgInfoCompletedAction,
} from '../actions/user.action';
import {
  AddUserToOrganizationRequest,
  GetResponse,
  ListUsersRequest,
  UpdateGoogleTokenRequest,
  UpdatePasswordRequest,
  UpdateRequest,
  GetUserPermissionsRequest,
  UpdateMicrosoftTokenRequest,
  UpdateActiveOrgIdRequest,
} from 'protos/pb/v1alpha1/users_service';
import { CookiePreference, OrgInfo, User } from 'protos/pb/v1alpha1/user';
import { storageService } from '../../services/StorageService';
import {
  getGoogleAuthCode,
  getMicrosoftAuthCode,
  getOrgIdFromResourceName,
  isOrbyAIUser,
} from '../../utils/helpers';
import { unauthenticatedErrorAction } from '../actions/auth.action';
import store from '../store';
import { isFeatureFlagEnabled } from '../../pages/FeatureFlags/FeatureFlagUtils';
import { AsyncReturn, FEATURE_FLAGS } from '../../utils/constants';
import { amplitudeService } from '../../services/AmplitudeService';
import { authService } from '../../services/AuthService';
import { loggedInUserSelector } from '../selectors/user.selectors';
import {
  CookiePreferences,
  cookieConsentService,
} from '../../services/CookieConsentService';
import { isEqual } from 'lodash';

export function* getLoggedInUserSaga(): any {
  try {
    const resp: GetResponse = yield call(userService.getLoggedInUser);
    const orgResourceName: string | undefined = yield call(
      storageService.getStoredOrgResourceName,
    );

    if (resp.user?.microsoftUserInfo) {
      yield call(
        storageService.setStoredMicrosoftInfo,
        resp.user.microsoftUserInfo,
      );
    }

    if (resp.user?.googleToken) {
      yield call(storageService.setStoredGoogleToken, {
        accessToken: resp.user.googleToken.accessToken as string,
        accessTokenExpiresAt: resp.user.googleToken.expiry!,
      });
    }

    const orgInfos = resp.user?.orgInfos;
    if (orgInfos?.length) {
      if (!orgResourceName) {
        yield call(
          storageService.setStoredOrgResourceName,
          orgInfos[0].orgResourceName as string,
        );
        yield put(setSelectedOrgInfo(orgInfos[0]));
        amplitudeService.setOrgResourceName(
          orgInfos[0].orgResourceName as string,
        );
      } else {
        const orgInfo: OrgInfo = orgInfos.find(
          (o) => o.orgResourceName === orgResourceName,
        ) as OrgInfo;
        if (orgInfo) {
          yield put(setSelectedOrgInfo(orgInfo));
          amplitudeService.setOrgResourceName(
            orgInfo.orgResourceName as string,
          );
        } else {
          yield call(
            storageService.setStoredOrgResourceName,
            orgInfos[0].orgResourceName as string,
          );
          yield put(setSelectedOrgInfo(orgInfos[0]));
          amplitudeService.setOrgResourceName(
            orgInfos[0].orgResourceName as string,
          );
        }
      }
    }
    amplitudeService.setUserId(resp.user?.id || '');
    yield put(fetchLoggedInUserCompletedAction(resp.user as User));
  } catch (e: any) {
    yield put(
      fetchLoggedInUserErrorAction(
        (e?.errors && e.errors[0]?.message) || e?.message,
      ),
    );

    yield put(
      unauthenticatedErrorAction(
        (e?.errors && e.errors[0]?.message) || e?.message,
      ),
    );
  }
}

export function* updateUserSaga(data: {
  type: UserActionType;
  payload: UpdateRequest;
}): any {
  try {
    const { response, error } = yield call(
      userService.updateUser,
      data.payload,
    );
    if (response) {
      yield put(updateUserCompletedAction(response.user));
      amplitudeService.setUserId(response.user.id);
    } else {
      yield put(updateUserErrorAction(error));
    }
  } catch (error) {
    yield put(updateUserErrorAction(error as Error));
  }
}

export function* updatePasswordSaga(data: {
  type: UserActionType;
  payload: UpdatePasswordRequest;
}): any {
  try {
    const { response, error } = yield call(
      userService.updatePassword,
      data.payload,
    );
    if (response) {
      yield put(updatePasswordCompletedAction(response.message));
    } else {
      yield put(updatePasswordErrorAction(error));
    }
  } catch (error) {
    yield put(updatePasswordErrorAction(error as string));
  }
}

export function* listUsersSaga(data: {
  type: UserActionType;
  payload: ListUsersRequest;
  refresh: boolean;
}): any {
  try {
    const { response, error } = yield call(userService.listUsers, data.payload);
    if (response) {
      yield put(
        listUsersCompletedAction(
          response.users,
          response.nextPageToken,
          response.totalSize,
          data.refresh,
        ),
      );
    } else {
      yield put(listUsersErrorAction(error));
    }
  } catch (error) {
    yield put(listUsersErrorAction(error as Error));
  }
}

export function* addUserToOrganizationSaga(data: {
  type: UserActionType;
  payload: AddUserToOrganizationRequest;
}): any {
  try {
    const { response, error } = yield call(
      userService.addUserToOrganization,
      data.payload,
    );
    if (response) {
      yield put(addUserToOrganizationCompletedAction(response));
    } else {
      yield put(addUserToOrganizationErrorAction(error));
    }
  } catch (error) {
    yield put(addUserToOrganizationErrorAction(error as Error));
  }
}

export function* updateGoogleToken(
  data: ReturnType<typeof updateGoogleTokenAction>,
): any {
  try {
    // get the google auth code
    const googleResponse: AsyncReturn<typeof getGoogleAuthCode> = yield call(
      getGoogleAuthCode,
      data.payload.scope,
    );

    const {
      response,
      error,
    }: AsyncReturn<typeof userService.updateGoogleToken> = yield call(
      userService.updateGoogleToken,
      UpdateGoogleTokenRequest.create({
        googleAuthorizationCode: googleResponse.code,
      }),
    );
    const featureFlags =
      store.getState().featureFlags.featureFlagsForOrgAndUser;
    const isCookieEnabled = isFeatureFlagEnabled(
      FEATURE_FLAGS.COOKIE,
      featureFlags,
    );
    // Set the google scope in the local storage
    if (googleResponse.scope) {
      yield call(storageService.setStoredGoogleScope, googleResponse.scope);
    }
    // set the google token in the local storage
    if (response?.googleToken?.accessToken) {
      if (!isCookieEnabled) {
        yield call(storageService.setStoredGoogleToken, {
          accessToken: response.googleToken.accessToken,
          accessTokenExpiresAt: response.googleToken.expiry!,
        });
      }

      const resp: AsyncReturn<typeof userService.getLoggedInUser> = yield call(
        userService.getLoggedInUser,
      );

      if (!resp?.user) {
        throw new Error('Failed to fetch updated google user information');
      }

      yield put(fetchLoggedInUserCompletedAction(resp.user as User, true));
      yield put(updateGoogleTokenCompletedAction());
    } else {
      yield put(updateGoogleTokenErrorAction(error));
    }
  } catch (e: any) {
    yield put(
      updateGoogleTokenErrorAction(
        new Error((e?.errors && e.errors[0]?.message) || e?.message),
      ),
    );
  }
}

export function* updateMicrosoftToken(
  data: ReturnType<typeof updateMicrosoftTokenAction>,
): any {
  try {
    // Step 1: Get Microsoft login URL
    const msAuthURL: AsyncReturn<typeof authService.getMicrosoftLoginUrl> =
      yield call(authService.getMicrosoftLoginUrl, data.payload.scope);

    if (!msAuthURL?.authCodeUrl) {
      throw new Error('Microsoft authentication URL not received');
    }

    // Step 2: Get auth code from Microsoft
    const authCode: AsyncReturn<typeof getMicrosoftAuthCode> = yield call(
      getMicrosoftAuthCode,
      msAuthURL.authCodeUrl,
    );
    if (!authCode) {
      throw new Error('Microsoft authentication code not received');
    }

    // Step 3: Update Microsoft token
    const {
      response: tokenUpdateResponse,
      error: tokenUpdateError,
    }: AsyncReturn<typeof userService.updateMicrosoftToken> = yield call(
      userService.updateMicrosoftToken,
      UpdateMicrosoftTokenRequest.create({
        microsoftAuthorizationCode: authCode,
      }),
    );

    if (!tokenUpdateResponse || tokenUpdateError) {
      throw new Error(
        tokenUpdateError?.message || 'Failed to update Microsoft token',
      );
    }

    // Step 4: Get and store updated microsoft user info
    const response: AsyncReturn<typeof userService.getLoggedInUser> =
      yield call(userService.getLoggedInUser);
    if (!response?.user?.microsoftUserInfo) {
      throw new Error('Failed to fetch updated microsoft user information');
    }

    yield call(
      storageService.setStoredMicrosoftInfo,
      response?.user?.microsoftUserInfo,
    );

    yield put(fetchLoggedInUserCompletedAction(response.user as User, true));
    yield put(updateMicrosoftTokenCompletedAction());
  } catch (error: any) {
    const errorMessage =
      error?.errors?.[0]?.message ||
      error?.message ||
      'Microsoft authentication failed';
    yield put(updateMicrosoftTokenErrorAction(new Error(errorMessage)));
  }
}

export function* getUserPermissionsSaga(): any {
  try {
    const { response, error } = yield call(
      userService.getUserPermissions,
      GetUserPermissionsRequest.create({}),
    );

    if (response) {
      yield put(getUserPermissionsCompletedAction(response.permittedActions));
    } else {
      yield put(getUserPermissionsErrorAction(error));
    }
  } catch (e: any) {
    yield put(
      getUserPermissionsErrorAction(
        (e?.errors && e.errors[0]?.message) || e?.message,
      ),
    );
  }
}

export function* updateActiveOrgIdSaga(data: {
  type: UserActionType;
  payload: OrgInfo;
}): any {
  const orgId = getOrgIdFromResourceName(data.payload.orgResourceName);
  const req: UpdateActiveOrgIdRequest = {
    orgId: orgId,
  };
  const { response } = yield call(userService.updateActiveOrgId, req);
  yield call(getUserPermissionsSaga);
  if (response) {
    yield put(setSelectedOrgInfoCompletedAction());
  }
}

function* updateCookiePreferencesSaga(): any {
  // Get the logged in user from Redux store
  const loggedInUser: User = yield select(loggedInUserSelector);
  const updatedCookiePreferences: CookiePreferences =
    cookieConsentService.getCookiePreferences();
  const lastUpdatedCookiePreferences =
    cookieConsentService.lastUpdatedCookiePreferences;

  const shouldUpdate = !isEqual(
    lastUpdatedCookiePreferences,
    updatedCookiePreferences,
  );

  // handle analytics cookie
  if (updatedCookiePreferences.analytics) {
    if (amplitudeService.isInitialized) {
      amplitudeService.setOptOut(false);
    } else if (loggedInUser && !isOrbyAIUser(loggedInUser)) {
      amplitudeService.init();
    }
  } else {
    if (amplitudeService.isInitialized) {
      amplitudeService.setOptOut(true);
    }
  }
  // check if the cookie preferences have changed only then update the cookie preferences on server
  if (shouldUpdate) {
    cookieConsentService.updateCookiePreferences(updatedCookiePreferences);
    const user = User.create(loggedInUser);
    user.cookiePreferences = Object.entries(updatedCookiePreferences).map(
      ([key, value]) => {
        return CookiePreference.create({
          key,
          value: value.toString(),
        });
      },
    );
    yield call(updateUserSaga, {
      type: UserActionType.UPDATE_USER,
      payload: UpdateRequest.create({
        user,
        fieldMask: ['cookie_preferences'],
      }),
    });
  }
}

function* userSaga() {
  yield all([
    takeLatest(UserActionType.FETCH_LOGGED_IN_USER, getLoggedInUserSaga),
    takeLatest(UserActionType.UPDATE_USER, updateUserSaga),
    takeLatest(UserActionType.UPDATE_PASSWORD, updatePasswordSaga),
    takeLatest(UserActionType.LIST_USERS, listUsersSaga),
    takeLatest(
      UserActionType.ADD_USER_TO_ORGANIZATION,
      addUserToOrganizationSaga,
    ),
    takeLatest(UserActionType.UPDATE_GOOGLE_TOKEN, updateGoogleToken),
    takeLatest(UserActionType.UPDATE_MICROSOFT_TOKEN, updateMicrosoftToken),
    // update the active org id
    takeLatest(UserActionType.SET_SELECTED_ORG_INFO, updateActiveOrgIdSaga),

    debounce(
      300,
      UserActionType.UPDATE_COOKIE_PREFERENCES,
      updateCookiePreferencesSaga,
    ),
  ]);
}

export default userSaga;
