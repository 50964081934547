import { useEffect, useState } from 'react';
import { ListWorkflowsRequest } from 'protos/pb/v1alpha1/orbot_service';
import { orbotService } from '../services/OrbotService';

export const useFetchOrbotWorkflows = (selectedOrgInfoCompleted: boolean) => {
  const [orbotWorkflowLength, setOrbotWorkflowLength] = useState(0);
  const [orbotWorkflowsLoading, setOrbotWorkflowsLoading] = useState(false);

  const fetchWorkflows = async () => {
    setOrbotWorkflowsLoading(true);
    const req: ListWorkflowsRequest = ListWorkflowsRequest.create({
      pageNumber: 0,
      pageSize: 1,
    });
    const { response } = await orbotService.listWorkflows(req);
    if (response) {
      setOrbotWorkflowLength(response.totalSize ?? 0);
      setOrbotWorkflowsLoading(false);
    } else {
      setOrbotWorkflowsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedOrgInfoCompleted) {
      fetchWorkflows();
    }
  }, [selectedOrgInfoCompleted]);

  return {
    orbotWorkflowLength,
    orbotWorkflowsLoading,
  };
};
