import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateApiKeyRequest,
  ListApiKeysRequest,
  UpdateApiKeyRequest,
  DeleteApiKeyRequest,
  RotateApiKeyRequest,
} from 'protos/pb/v1alpha1/organization_service';
import { ApiKey } from 'protos/pb/v1alpha1/api_key';
import { DataLoadingStatus } from '../../utils/constants';
import {
  DEFAULT_FIRST_PAGE,
  DEFAULT_ROWS_PER_PAGE,
} from 'orby-ui/src/components/table/table-utils';

export interface ApiKeysState {
  list: ApiKey[];
  listStatus: DataLoadingStatus;
  createStatus: DataLoadingStatus;
  listError: string | null;
  createError: string | null;
  createdKey: string | null;
  encryptedKey: string | null;
  createdApiKeyId: string | null;
  pagination: {
    pageSize: number;
    pageNumber: number;
    totalSize: number;
  };
  deleteStatus: DataLoadingStatus;
  deleteError: string | null;
  updateStatus: DataLoadingStatus;
  updateError: string | null;
  rotateStatus: DataLoadingStatus;
  rotateError: string | null;
}

const initialState: ApiKeysState = {
  list: [],
  listStatus: DataLoadingStatus.INITIAL,
  createStatus: DataLoadingStatus.INITIAL,
  listError: null,
  createError: null,
  createdKey: null,
  encryptedKey: null,
  createdApiKeyId: null,
  pagination: {
    pageSize: DEFAULT_ROWS_PER_PAGE,
    pageNumber: DEFAULT_FIRST_PAGE,
    totalSize: 0,
  },
  deleteStatus: DataLoadingStatus.INITIAL,
  deleteError: null,
  updateStatus: DataLoadingStatus.INITIAL,
  updateError: null,
  rotateStatus: DataLoadingStatus.INITIAL,
  rotateError: null,
};

// Replace the manual action creation with proper createAction
export const createApiKey = createAction<CreateApiKeyRequest>(
  'apiKeys/createApiKey',
);
export const listApiKeys = createAction<ListApiKeysRequest>(
  'apiKeys/listApiKeys',
);
export const deleteApiKey = createAction<DeleteApiKeyRequest>(
  'apiKeys/deleteApiKey',
);
export const updateApiKey = createAction<UpdateApiKeyRequest>(
  'apiKeys/updateApiKey',
);
export const rotateApiKey = createAction<RotateApiKeyRequest>(
  'apiKeys/rotateApiKey',
);

const apiKeysSlice = createSlice({
  name: 'apiKeys',
  initialState,
  reducers: {
    listApiKeysSuccessAction: (
      state,
      action: PayloadAction<{ apiKeys: ApiKey[]; totalSize: number }>,
    ) => {
      state.listStatus = DataLoadingStatus.LOADED;
      state.list = action.payload.apiKeys;
      state.listError = null;
      state.pagination.totalSize = action.payload.totalSize;
    },
    listApiKeysFailureAction: (state, action: PayloadAction<string>) => {
      state.listStatus = DataLoadingStatus.ERROR;
      state.listError = action.payload;
    },
    createApiKeySuccessAction: (
      state,
      action: PayloadAction<{ apiKeyId: string; encryptedKey: string }>,
    ) => {
      state.createStatus = DataLoadingStatus.LOADED;
      state.encryptedKey = action.payload.encryptedKey;
      state.createdApiKeyId = action.payload.apiKeyId;
      state.createError = null;
    },
    createApiKeyFailureAction: (state, action: PayloadAction<string>) => {
      state.createStatus = DataLoadingStatus.ERROR;
      state.createError = action.payload;
    },
    resetCreateApiKeyStatus: (state) => {
      state.createStatus = DataLoadingStatus.INITIAL;
      state.createError = null;
      state.createdKey = null;
      state.encryptedKey = null;
    },
    setPagination: (
      state,
      action: PayloadAction<{ pageNumber: number; pageSize: number }>,
    ) => {
      state.pagination.pageNumber = action.payload.pageNumber;
      state.pagination.pageSize = action.payload.pageSize;
    },
    setDecryptedKey: (state, action: PayloadAction<string>) => {
      state.createdKey = action.payload;
    },
    clearDecryptedKey: (state) => {
      state.createdKey = null;
    },
    deleteApiKeySuccessAction: (state) => {
      state.deleteStatus = DataLoadingStatus.LOADED;
      state.deleteError = null;
    },
    deleteApiKeyFailureAction: (state, action: PayloadAction<string>) => {
      state.deleteStatus = DataLoadingStatus.ERROR;
      state.deleteError = action.payload;
    },
    updateApiKeySuccessAction: (state) => {
      state.updateStatus = DataLoadingStatus.LOADED;
      state.updateError = null;
    },
    updateApiKeyFailureAction: (state, action: PayloadAction<string>) => {
      state.updateStatus = DataLoadingStatus.ERROR;
      state.updateError = action.payload;
    },
    resetUpdateApiKeyStatus: (state) => {
      state.updateStatus = DataLoadingStatus.INITIAL;
      state.updateError = null;
    },
    resetDeleteApiKeyStatus: (state) => {
      state.deleteStatus = DataLoadingStatus.INITIAL;
      state.deleteError = null;
    },
    resetRotateApiKeyStatus: (state) => {
      state.rotateStatus = DataLoadingStatus.INITIAL;
      state.rotateError = null;
    },
    rotateApiKeySuccessAction: (state, action: PayloadAction<string>) => {
      state.rotateStatus = DataLoadingStatus.LOADED;
      state.encryptedKey = action.payload;
      state.rotateError = null;
    },
    rotateApiKeyFailureAction: (state, action: PayloadAction<string>) => {
      state.rotateStatus = DataLoadingStatus.ERROR;
      state.rotateError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listApiKeys, (state) => {
        state.listStatus = DataLoadingStatus.LOADING;
        state.listError = null;
      })
      .addCase(createApiKey, (state) => {
        state.createStatus = DataLoadingStatus.LOADING;
        state.createError = null;
      })
      .addCase(deleteApiKey, (state) => {
        state.deleteStatus = DataLoadingStatus.LOADING;
        state.deleteError = null;
      })
      .addCase(updateApiKey, (state) => {
        state.updateStatus = DataLoadingStatus.LOADING;
        state.updateError = null;
      })
      .addCase(rotateApiKey, (state) => {
        state.rotateStatus = DataLoadingStatus.LOADING;
        state.rotateError = null;
      });
  },
});

export const {
  listApiKeysSuccessAction,
  listApiKeysFailureAction,
  createApiKeySuccessAction,
  createApiKeyFailureAction,
  resetCreateApiKeyStatus,
  setPagination,
  setDecryptedKey,
  clearDecryptedKey,
  deleteApiKeySuccessAction,
  deleteApiKeyFailureAction,
  updateApiKeySuccessAction,
  updateApiKeyFailureAction,
  resetUpdateApiKeyStatus,
  resetDeleteApiKeyStatus,
  rotateApiKeySuccessAction,
  rotateApiKeyFailureAction,
  resetRotateApiKeyStatus,
} = apiKeysSlice.actions;

// Export selectors
export const apiKeysListStatusSelector = (state: { apiKeys: ApiKeysState }) =>
  state.apiKeys.listStatus;
export const apiKeysCreateStatusSelector = (state: { apiKeys: ApiKeysState }) =>
  state.apiKeys.createStatus;
export const apiKeysCreatedKeySelector = (state: { apiKeys: ApiKeysState }) =>
  state.apiKeys.createdKey;
export const apiKeysListSelector = (state: { apiKeys: ApiKeysState }) =>
  state.apiKeys.list;
export const apiKeysListErrorSelector = (state: { apiKeys: ApiKeysState }) =>
  state.apiKeys.listError;
export const apiKeysCreateErrorSelector = (state: { apiKeys: ApiKeysState }) =>
  state.apiKeys.createError;
export const apiKeysPaginationSelector = (state: { apiKeys: ApiKeysState }) =>
  state.apiKeys.pagination;
export const apiKeysEncryptedKeySelector = (state: { apiKeys: ApiKeysState }) =>
  state.apiKeys.encryptedKey;
export const apiKeysDeleteStatusSelector = (state: { apiKeys: ApiKeysState }) =>
  state.apiKeys.deleteStatus;
export const apiKeysDeleteErrorSelector = (state: { apiKeys: ApiKeysState }) =>
  state.apiKeys.deleteError;
export const apiKeysUpdateStatusSelector = (state: { apiKeys: ApiKeysState }) =>
  state.apiKeys.updateStatus;
export const apiKeysUpdateErrorSelector = (state: { apiKeys: ApiKeysState }) =>
  state.apiKeys.updateError;
export const apiKeysRotateStatusSelector = (state: { apiKeys: ApiKeysState }) =>
  state.apiKeys.rotateStatus;
export const apiKeysRotateErrorSelector = (state: { apiKeys: ApiKeysState }) =>
  state.apiKeys.rotateError;
export const apiKeysCreatedApiKeyIdSelector = (state: {
  apiKeys: ApiKeysState;
}) => state.apiKeys.createdApiKeyId;
export default apiKeysSlice.reducer;
