import {
  Box,
  CircularProgress,
  ListSubheader,
  SelectChangeEvent,
  SxProps,
  Theme,
} from '@mui/material';
import {
  OrbyColorPalette,
  OrbyMenuItem,
  OrbySelect,
  OrbyTextField,
  OrbyTypography,
} from 'orby-ui/src';
import React, { useEffect, useMemo, useState } from 'react';

import { GridSearchIcon } from '@mui/x-data-grid';
import { debounce } from 'lodash';
import { showErrorToast } from 'orby-ui/src/components/toast/OrbyToast';
import { User } from 'protos/pb/v1alpha1/user';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserFiltersAction,
  getUserFiltersErrorAction,
} from '../../redux/actions/filter_options.action';
import {
  userFiltersErrorSelector,
  userFiltersLoadingSelector,
  userFiltersSelector,
} from '../../redux/selectors/filter_options.selectors';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
} from '../../redux/selectors/user.selectors';
import { ELLIPSIS_STYLE, UserFilter } from '../../utils/constants';
import UserIcon from '../UserIcon';
import { ALL_VALUE, EMAIL, ID, UNASSIGNED_VALUE, VALUE } from './constant';
import { getTruncate } from './helper';

export interface Props {
  selectedUsers: Array<string>;
  setSelectedUsers: (data: Array<string>) => void;
  user: User;
  isAdminView: boolean;
  forceShowLoggedInUserOption?: boolean;
  isShowUnassignedOnly?: boolean;
  allValue?: Record<string, string>;
  userFilterMappedValue?: 'value' | 'email' | 'id';
  width?: string;
  menuWidth?: string;
  // If we need to pass custom user filters - basically custom users list
  customUserFilters?: UserFilter[];
  customUserFiltersLoading?: boolean;
  customUserFiltersError?: Error | null;
  containerSx?: SxProps<Theme>;
}

const UserFilter: React.FC<Props> = ({
  selectedUsers,
  setSelectedUsers,
  user,
  isAdminView,
  isShowUnassignedOnly = true,
  width = '160px',
  menuWidth = '350px',
  allValue = ALL_VALUE,
  userFilterMappedValue = EMAIL,
  customUserFilters,
  customUserFiltersLoading,
  customUserFiltersError,
  forceShowLoggedInUserOption = false,
  containerSx,
}) => {
  // User options
  const [userOptions, setUserOptions] = useState<UserFilter[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<UserFilter[]>([]);
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const userFilters = customUserFilters ?? useSelector(userFiltersSelector);
  const userFiltersError =
    customUserFiltersError ?? useSelector(userFiltersErrorSelector);
  const userFiltersLoading =
    customUserFiltersLoading ?? useSelector(userFiltersLoadingSelector);
  const loggedInUser = useSelector(loggedInUserSelector) ?? {};
  const dispatch = useDispatch();

  useEffect(() => {
    if (userFilters) {
      setFilterOptions();
    }
  }, [userFilters]);

  /**
   * Fetch users
   */
  useEffect(() => {
    if (userFiltersError) {
      showErrorToast(userFiltersError.message);
      dispatch(getUserFiltersErrorAction());
    }
  }, [userFiltersError]);

  useEffect(() => {
    if ((isAdminView || forceShowLoggedInUserOption) && selectedOrgInfo) {
      dispatch(
        getUserFiltersAction(selectedOrgInfo?.orgResourceName as string),
      );
    }
  }, [selectedOrgInfo]);

  /**
   * Handle user filter search
   */
  const handleUserSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newOptions = userOptions.filter(
      (opt) =>
        opt.email?.toLowerCase().includes(e.target.value?.toLowerCase()) ||
        opt.fullName?.toLowerCase().includes(e.target.value?.toLowerCase()), // Search should be case insensitive
    );
    setFilteredOptions(newOptions);
  };

  /**
   * Get render value if only one element is selected
   */
  const getRenderValueIfSingle = (value: string) => {
    if (value.toLowerCase() === UNASSIGNED_VALUE.value.toLowerCase()) {
      return UNASSIGNED_VALUE.label;
    } else if (value.toLowerCase() === user.email!.toLowerCase()) {
      return user.email;
    }
    return value;
  };

  const getFilterKey = () => {
    let filterKey: keyof UserFilter;
    switch (userFilterMappedValue) {
      case VALUE:
        filterKey = VALUE;
        break;
      case EMAIL:
        filterKey = EMAIL;
        break;
      case ID:
        filterKey = ID;
        break;
    }
    return filterKey;
  };

  /**
   * Set filter options
   */
  const setFilterOptions = () => {
    const userOptions = (userFilters ?? []).map((filter: UserFilter) => ({
      ...filter,
      value: filter[getFilterKey()],
    }));
    setUserOptions(userOptions);
    setFilteredOptions(userOptions);
  };

  const handleSelectionChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as Array<string>;
    if (value) {
      if (value[value.length - 1] === allValue.value || value.length === 0) {
        setSelectedUsers([]);
      } else {
        // Remove the default value
        let filteredSelectedUsers: Array<string> = [];
        if (isAdminView) {
          // In case of admin if any other value is selected remove 'all' from the options
          filteredSelectedUsers = value.filter((o) => o !== allValue.value);
        } else {
          // In case of users only one value can be selected from the options
          filteredSelectedUsers = [value[value.length - 1]];
        }

        setSelectedUsers(filteredSelectedUsers);
      }
    }
  };

  /**
   * Handle render value
   */
  const handleRenderValue = () => {
    const noOfUsers = selectedUsers.length;
    if (noOfUsers === 0) {
      return (
        <Box overflow={'hidden'} title={allValue.label}>
          {renderLabel(allValue.label)}
        </Box>
      );
    } else {
      if (selectedUsers.length === 1) {
        const selectedUser = userFilters.find(
          (f) => f[getFilterKey()] === selectedUsers[0],
        );
        let label;
        if (selectedUser) {
          label = selectedUser.fullName || selectedUser.email;
        } else {
          label = selectedUsers[0];
        }
        return (
          <Box
            overflow={'hidden'}
            display={'flex'}
            gap={'4px'}
            alignItems={'center'}
            title={getRenderValueIfSingle(label)}
          >
            <OrbyTypography
              size='sm'
              color={OrbyColorPalette['grey-900']}
              sx={{
                paddingRight: '10px',
                ...ELLIPSIS_STYLE,
              }}
            >
              {getRenderValueIfSingle(label)}
            </OrbyTypography>
          </Box>
        );
      } else {
        const value = (
          <Box
            overflow={'hidden'}
            display={'flex'}
            gap={'4px'}
            alignItems={'center'}
          >
            <OrbyTypography
              size='sm'
              weight='semibold'
              color={OrbyColorPalette['blue-700']}
            >
              {noOfUsers}
            </OrbyTypography>
            <OrbyTypography
              size='sm'
              weight='medium'
              color={OrbyColorPalette['grey-700']}
              sx={{
                paddingRight: '10px',
                ...ELLIPSIS_STYLE,
              }}
            >
              selected users
            </OrbyTypography>
          </Box>
        );
        return (
          <Box overflow={'hidden'} title={`${noOfUsers} users selected`}>
            {value}
          </Box>
        );
      }
    }
  };

  const renderLabel = (label: string, isMenu = false) => {
    return (
      <OrbyTypography
        size='sm'
        color={OrbyColorPalette['grey-900']}
        weight={'regular'}
        sx={{
          paddingRight: isMenu ? '0px' : '10px',
          ...ELLIPSIS_STYLE,
        }}
      >
        {label}
      </OrbyTypography>
    );
  };

  const renderLoggedInUserMenuItem = useMemo(() => {
    if ((isAdminView || forceShowLoggedInUserOption) && !userFiltersLoading) {
      const loggedInUserOption = (filteredOptions ?? []).find(
        (option) => option?.id === loggedInUser?.id,
      );
      if (loggedInUserOption) {
        return (
          <OrbyMenuItem
            width={menuWidth}
            key={loggedInUserOption.value}
            title={`(myself) ${loggedInUserOption.fullName || loggedInUserOption.email}`}
            description={
              loggedInUserOption.fullName ? loggedInUserOption.email : ''
            }
            value={loggedInUserOption.value}
            truncate={getTruncate(
              loggedInUserOption.email,
              loggedInUserOption.fullName,
            )}
            titleLabelOverride={
              loggedInUserOption.fullName
                ? `${loggedInUserOption.fullName} (${loggedInUserOption.email})`
                : loggedInUserOption.email
            }
            icon={
              <UserIcon
                imageUrl={loggedInUserOption.profilePicture}
                fullName={loggedInUserOption.fullName}
                email={loggedInUserOption.email}
                sx={{ width: 24, height: 24, fontSize: 20 }}
              />
            }
            isSelected={selectedUsers.includes(loggedInUserOption.value)}
          />
        );
      }
      return null;
    }
    return null;
  }, [
    loggedInUser,
    filteredOptions,
    userFiltersLoading,
    isAdminView,
    selectedUsers,
    userFilterMappedValue,
    forceShowLoggedInUserOption,
  ]);

  const renderUserFilterOptionsExceptLoggedInUser = useMemo(() => {
    if (userFiltersLoading) {
      return (
        <Box
          display={'flex'}
          padding={'10px 0px'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <CircularProgress
            sx={{ color: OrbyColorPalette['purple-900'] }}
            size={'30px'}
          />
        </Box>
      );
    }
    if (isAdminView) {
      if (!(filteredOptions ?? []).length) {
        return (
          <Box
            display={'flex'}
            padding={'10px 0px'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <OrbyTypography
              size='sm'
              weight='medium'
              color={OrbyColorPalette['grey-700']}
            >
              No users found
            </OrbyTypography>
          </Box>
        );
      } else {
        const options = (filteredOptions ?? []).filter(
          (option) => option?.id !== loggedInUser?.id,
        );
        return options.map((option) => {
          return (
            <OrbyMenuItem
              width={menuWidth}
              key={option.value}
              title={option.fullName || option.email}
              description={option.fullName ? option.email : ''}
              value={option.value}
              truncate={getTruncate(option.email, option.fullName)}
              titleLabelOverride={
                option.fullName
                  ? `${option.fullName} (${option.email})`
                  : option.email
              }
              icon={
                <UserIcon
                  imageUrl={option.profilePicture}
                  fullName={option.fullName}
                  email={option.email}
                  sx={{ width: 24, height: 24, fontSize: 20 }}
                />
              }
              isSelected={selectedUsers.includes(option.value)}
            />
          );
        });
      }
    }
    return null;
  }, [
    filteredOptions,
    loggedInUser,
    isAdminView,
    userFiltersLoading,
    selectedUsers,
  ]);

  return (
    <>
      <OrbySelect
        onClose={setFilterOptions}
        renderValue={handleRenderValue}
        multiple={true}
        value={
          selectedUsers.length === 0
            ? [allValue.value]
            : [...selectedUsers.values()]
        }
        width={selectedUsers.length >= 2 ? '200px' : width}
        onChange={handleSelectionChange}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        containerSx={containerSx}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {isAdminView && (
          <ListSubheader
            sx={{
              padding: 0,
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <OrbyTextField
              variant='flat'
              onKeyDown={(e: React.KeyboardEvent<HTMLElement>) =>
                e.stopPropagation()
              }
              width={'100%'}
              tabIndex={0}
              name={'search-email'}
              disabled={false}
              placeholder='Search for name or email'
              startAdornment={
                <GridSearchIcon
                  sx={{ color: OrbyColorPalette['grey-900'] }}
                  fontSize='medium'
                />
              }
              autoFocus={true}
              onChange={debounce((event) => handleUserSearch(event), 300)}
            />
          </ListSubheader>
        )}

        <OrbyMenuItem
          width={menuWidth}
          key={allValue.value}
          title={allValue.label}
          value={allValue.value}
          isSelected={selectedUsers.length === 0}
        />

        {isShowUnassignedOnly && (
          <OrbyMenuItem
            width={menuWidth}
            key={UNASSIGNED_VALUE.value}
            title={UNASSIGNED_VALUE.label}
            value={UNASSIGNED_VALUE.value}
            isSelected={selectedUsers.includes(UNASSIGNED_VALUE.value)}
          />
        )}

        {renderLoggedInUserMenuItem}
        {renderUserFilterOptionsExceptLoggedInUser}
      </OrbySelect>
    </>
  );
};

export default React.memo(UserFilter);
