import React from 'react';
import { Navigate } from 'react-router-dom';
import {
  LazyApiKeysManagement,
  LazyCompanyNonWorkingDays,
  LazyConfigureSSO,
  LazyCreateConnector,
  LazyCreateProcessDiscovery,
  LazyCreateWorkflow,
  LazyCreateWorkflowTemplate,
  LazyDashboard,
  LazyDebuggerReview,
  LazyExecutionHistory,
  LazyExecutions,
  LazyFeatureFlagCreationPage,
  LazyFeatureFlagUpdatePage,
  LazyFeatureFlags,
  LazyFieldsPage,
  LazyIntegrationTest,
  LazyMachines,
  LazyMyWorkflows,
  LazyProcessDiscovery,
  LazyProcessDiscoveryDetails,
  LazyScheduledTasks,
  LazySettings,
  LazyTaskReviewDetails,
  LazyTasks,
  LazyTasksExport,
  LazyTeamsPage,
  LazyTemplate,
  LazyTrashCan,
  LazyUiAutomationsDetail,
  LazyUpdateConnector,
  LazyUpdateWorkflow,
  LazyWebhooks,
  LazyWorkflowDetails,
} from '.';
import { useGeneratePath } from '../../hooks/useGeneratePath';
import { LegacyRouteRedirect } from './LegacyRouteRedirect';
import { APP_ROUTES, LEGACY_ROUTES } from './Routes';

interface BaseRouteConfig {
  path: string;
  element: React.ReactNode;
  enabled: boolean;
}

interface RouteDefinition {
  newPath: string;
  legacyPath?: string;
  element: (props: { sideDrawerCollapse?: boolean }) => React.ReactNode;
  enabled: boolean | ((permissions: any) => boolean);
}

interface Permissions {
  [key: string]: boolean;
}

interface CreateProtectedRoutesProps {
  routeDefinitions: Record<string, RouteDefinition>;
  collapse: boolean;
  permissions: Permissions;
}

export const createProtectedRoutes = ({
  routeDefinitions,
  collapse,
  permissions,
}: CreateProtectedRoutesProps): BaseRouteConfig[] => {
  return Object.entries(routeDefinitions).flatMap(([, definition]) => {
    const enabled =
      typeof definition.enabled === 'function'
        ? definition.enabled(permissions)
        : definition.enabled;

    const routes: BaseRouteConfig[] = [
      {
        path: definition.newPath,
        element: definition.element({ sideDrawerCollapse: collapse }),
        enabled,
      },
    ];

    // Add legacy route redirect if legacyPath exists
    if (definition.legacyPath) {
      routes.push({
        path: definition.legacyPath,
        element: <LegacyRouteRedirect toPath={definition.newPath} />,
        enabled,
      });
    }

    return routes;
  });
};

export const getProtectedRoutes = ({
  permissions,
  collapse,
  isProd,
}: {
  permissions: Permissions;
  collapse: boolean;
  isProd: boolean;
}) => {
  const generateOrgPath = useGeneratePath();
  const routeDefinitions = {
    dashboard: {
      newPath: APP_ROUTES.DASHBOARD,
      legacyPath: LEGACY_ROUTES.DASHBOARD,
      element: () => <LazyDashboard />,
      enabled: true,
    },
    tasks: {
      newPath: APP_ROUTES.TASKS,
      legacyPath: LEGACY_ROUTES.TASKS,
      element: (props: { sideDrawerCollapse?: boolean }) => (
        <LazyTasks sideDrawerCollapse={props.sideDrawerCollapse} />
      ),
      enabled: true,
    },
    processDiscovery: {
      newPath: APP_ROUTES.PROCESS_DISCOVERY,
      legacyPath: LEGACY_ROUTES.PROCESS_DISCOVERY,
      element: (props: { sideDrawerCollapse?: boolean }) => (
        <LazyProcessDiscovery sideDrawerCollapse={props.sideDrawerCollapse} />
      ),
      enabled: true,
    },
    processDiscoveryDiagram: {
      newPath: APP_ROUTES.PROCESS_DISCOVERY_DIAGRAM,
      legacyPath: LEGACY_ROUTES.PROCESS_DISCOVERY_DIAGRAM,
      element: (props: { sideDrawerCollapse?: boolean }) => (
        <LazyProcessDiscoveryDetails
          sideDrawerCollapse={props.sideDrawerCollapse}
        />
      ),
      enabled: true,
    },
    processDiscoveryDocumentation: {
      newPath: APP_ROUTES.PROCESS_DISCOVERY_DOCUMENTATION,
      legacyPath: LEGACY_ROUTES.PROCESS_DISCOVERY_DOCUMENTATION,
      element: (props: { sideDrawerCollapse?: boolean }) => (
        <LazyProcessDiscoveryDetails
          sideDrawerCollapse={props.sideDrawerCollapse}
        />
      ),
      enabled: true,
    },
    processDiscoverySessions: {
      newPath: APP_ROUTES.PROCESS_DISCOVERY_SESSIONS,
      legacyPath: LEGACY_ROUTES.PROCESS_DISCOVERY_SESSIONS,
      element: (props: { sideDrawerCollapse?: boolean }) => (
        <LazyProcessDiscoveryDetails
          sideDrawerCollapse={props.sideDrawerCollapse}
        />
      ),
      enabled: true,
    },
    createProcessDiscovery: {
      newPath: APP_ROUTES.CREATE_PROCESS_DISCOVERY,
      legacyPath: LEGACY_ROUTES.CREATE_PROCESS_DISCOVERY,
      element: (props: { sideDrawerCollapse?: boolean }) => (
        <LazyCreateProcessDiscovery
          sideDrawerCollapse={props.sideDrawerCollapse}
        />
      ),
      enabled: (permissions: Permissions) =>
        !permissions.isBlockWorkflowCreationAndModification,
    },
    updateDraftProcess: {
      newPath: APP_ROUTES.UPDATE_DRAFT_PROCESS,
      legacyPath: LEGACY_ROUTES.UPDATE_DRAFT_PROCESS,
      element: (props: { sideDrawerCollapse?: boolean }) => (
        <LazyCreateProcessDiscovery
          sideDrawerCollapse={props.sideDrawerCollapse}
        />
      ),
      enabled: (permissions: Permissions) =>
        !permissions.isBlockWorkflowCreationAndModification,
    },
    tasksExport: {
      newPath: APP_ROUTES.TASKS_EXPORT,
      element: () => <LazyTasksExport />,
      enabled: true,
    },
    allExecutions: {
      newPath: APP_ROUTES.ALL_EXECUTIONS,
      element: (props: { sideDrawerCollapse?: boolean }) => (
        <LazyExecutions sideDrawerCollapse={props.sideDrawerCollapse} />
      ),
      enabled: true,
    },
    executions: {
      newPath: APP_ROUTES.EXECUTIONS,
      legacyPath: LEGACY_ROUTES.EXECUTIONS,
      element: (props: { sideDrawerCollapse?: boolean }) => (
        <LazyExecutionHistory sideDrawerCollapse={props.sideDrawerCollapse} />
      ),
      enabled: (permissions: Permissions) => permissions.canAccessExecutionPage,
    },
    executionDetails: {
      newPath: APP_ROUTES.EXECUTION_DETAILS,
      legacyPath: LEGACY_ROUTES.EXECUTION_DETAILS,
      element: () => <LazyUiAutomationsDetail />,
      enabled: (permissions: Permissions) => permissions.canAccessExecutionPage,
    },
    workflowTemplate: {
      newPath: APP_ROUTES.WORKFLOW_TEMPLATE,
      legacyPath: LEGACY_ROUTES.WORKFLOW_TEMPLATE,
      element: () => <LazyCreateWorkflowTemplate />,
      enabled: (permissions: Permissions) => permissions.canCreateWorkflow,
    },
    taskReview: {
      newPath: APP_ROUTES.TASK_REVIEW,
      legacyPath: LEGACY_ROUTES.TASK_REVIEW,
      element: (props: { sideDrawerCollapse?: boolean }) => (
        <LazyTaskReviewDetails sideDrawerCollapse={props.sideDrawerCollapse} />
      ),
      enabled: true,
    },
    hitlDetails: {
      newPath: APP_ROUTES.HITL_DETAILS,
      legacyPath: LEGACY_ROUTES.HITL_DETAILS,
      element: (props: { sideDrawerCollapse?: boolean }) => (
        <LazyTaskReviewDetails
          sideDrawerCollapse={props.sideDrawerCollapse}
          isOrbot={true}
        />
      ),
      enabled: true,
    },
    devTesting: {
      newPath: APP_ROUTES.DEV_TESTING,
      element: () => <LazyDebuggerReview />,
      enabled: (permissions: Permissions) =>
        !permissions.isBlockWorkflowCreationAndModification &&
        permissions.isOrbyAIUser,
    },
    devTestingDocument: {
      newPath: APP_ROUTES.DEV_TESTING_DOCUMENT,
      element: (props: { sideDrawerCollapse?: boolean }) => (
        <LazyTaskReviewDetails
          debug={true}
          sideDrawerCollapse={props.sideDrawerCollapse}
        />
      ),
      enabled: (permissions: Permissions) =>
        !permissions.isBlockWorkflowCreationAndModification &&
        permissions.isOrbyAIUser,
    },
    integrationTest: {
      newPath: APP_ROUTES.INTEGRATION_TEST,
      element: () => <LazyIntegrationTest />,
      enabled: (permissions: Permissions) => permissions.isTestUser,
    },
    workflow: {
      newPath: APP_ROUTES.WORKFLOW,
      legacyPath: LEGACY_ROUTES.WORKFLOW,
      element: (props: { sideDrawerCollapse?: boolean }) => (
        <LazyMyWorkflows sideDrawerCollapse={props.sideDrawerCollapse} />
      ),
      enabled: (permissions: Permissions) => permissions.canAccessWorkflowPage,
    },
    workflowBuilder: {
      newPath: APP_ROUTES.WORKFLOW_BUILDER,
      legacyPath: LEGACY_ROUTES.WORKFLOW_BUILDER,
      element: (props: { sideDrawerCollapse?: boolean }) => (
        <LazyWorkflowDetails sideDrawerCollapse={props.sideDrawerCollapse} />
      ),
      enabled: (permissions: Permissions) => permissions.canAccessWorkflowPage,
    },
    workflowDefinition: {
      newPath: APP_ROUTES.WORKFLOW_DEFINITION,
      legacyPath: LEGACY_ROUTES.WORKFLOW_DEFINITION,
      element: (props: { sideDrawerCollapse?: boolean }) => (
        <LazyWorkflowDetails sideDrawerCollapse={props.sideDrawerCollapse} />
      ),
      enabled: (permissions: Permissions) => permissions.canAccessWorkflowPage,
    },
    workflowUpdate: {
      newPath: APP_ROUTES.WORKFLOW_UPDATE,
      legacyPath: LEGACY_ROUTES.WORKFLOW_UPDATE,
      element: () => <LazyUpdateWorkflow />,
      enabled: (permissions: Permissions) => permissions.canAccessWorkflowPage,
    },
    workflowCreate: {
      newPath: APP_ROUTES.WORKFLOW_CREATE,
      legacyPath: LEGACY_ROUTES.WORKFLOW_CREATE,
      element: () => <LazyCreateWorkflow />,
      enabled: (permissions: Permissions) =>
        permissions.canCreateWorkflow &&
        !permissions.isBlockWorkflowCreationAndModification,
    },
    workflowSettings: {
      newPath: APP_ROUTES.WORKFLOW_SETTINGS,
      legacyPath: LEGACY_ROUTES.WORKFLOW_SETTINGS,
      element: (props: { sideDrawerCollapse?: boolean }) => (
        <LazyWorkflowDetails sideDrawerCollapse={props.sideDrawerCollapse} />
      ),
      enabled: (permissions: Permissions) => permissions.canAccessWorkflowPage,
    },
    workflowAdmins: {
      newPath: APP_ROUTES.WORKFLOW_ADMINS,
      legacyPath: LEGACY_ROUTES.WORKFLOW_ADMINS,
      element: (props: { sideDrawerCollapse?: boolean }) => (
        <LazyWorkflowDetails sideDrawerCollapse={props.sideDrawerCollapse} />
      ),
      enabled: (permissions: Permissions) => permissions.canAccessWorkflowPage,
    },
    workflowSchedule: {
      newPath: APP_ROUTES.WORKFLOW_SCHEDULE,
      legacyPath: LEGACY_ROUTES.WORKFLOW_SCHEDULE,
      element: (props: { sideDrawerCollapse?: boolean }) => (
        <LazyWorkflowDetails sideDrawerCollapse={props.sideDrawerCollapse} />
      ),
      enabled: (permissions: Permissions) => permissions.canAccessWorkflowPage,
    },
    workflowAdvancedEditor: {
      newPath: APP_ROUTES.WORKFLOW_ADVANCED_EDITOR,
      legacyPath: LEGACY_ROUTES.WORKFLOW_ADVANCED_EDITOR,
      element: (props: { sideDrawerCollapse?: boolean }) => (
        <LazyWorkflowDetails sideDrawerCollapse={props.sideDrawerCollapse} />
      ),
      enabled: (permissions: Permissions) =>
        permissions.canAccessWorkflowPage &&
        permissions.isAdvancedEditorEnabled,
    },
    connectorCreate: {
      newPath: APP_ROUTES.CONNECTOR_CREATE,
      legacyPath: LEGACY_ROUTES.CONNECTOR_CREATE,
      element: () => <LazyCreateConnector />,
      enabled: (permissions: Permissions) =>
        permissions.canAccessConnectors &&
        !permissions.isBlockWorkflowCreationAndModification,
    },
    connectorUpdate: {
      newPath: APP_ROUTES.CONNECTOR_UPDATE,
      legacyPath: LEGACY_ROUTES.CONNECTOR_UPDATE,
      element: () => <LazyUpdateConnector />,
      enabled: (permissions: Permissions) => permissions.canAccessConnectors,
    },
    deletedItems: {
      newPath: APP_ROUTES.DELETED_ITEMS,
      legacyPath: LEGACY_ROUTES.DELETED_ITEMS,
      element: (props: { sideDrawerCollapse?: boolean }) => (
        <LazyTrashCan sideDrawerCollapse={props.sideDrawerCollapse} />
      ),
      enabled: (permissions: Permissions) =>
        permissions.canListArchivedResources,
    },
    fields: {
      newPath: APP_ROUTES.FIELDS,
      legacyPath: LEGACY_ROUTES.FIELDS,
      element: (props: { sideDrawerCollapse?: boolean }) => (
        <LazyFieldsPage sideDrawerCollapse={props.sideDrawerCollapse} />
      ),
      enabled: true,
    },
    templateDefinition: {
      newPath: APP_ROUTES.TEMPLATE_DEFINITION,
      legacyPath: LEGACY_ROUTES.TEMPLATE_DEFINITION,
      element: () => <LazyTemplate />,
      enabled: (permissions: Permissions) => permissions.canCreateWorkflow,
    },
    settings: {
      newPath: APP_ROUTES.SETTINGS,
      legacyPath: LEGACY_ROUTES.SETTINGS,
      element: () => <LazySettings />,
      enabled: true,
    },
    configureSSO: {
      newPath: APP_ROUTES.CONFIGURE_SSO,
      element: () => <LazyConfigureSSO />,
      enabled: (permissions: Permissions) => permissions.isAdminUser,
    },
    companyNonWorkingDays: {
      newPath: APP_ROUTES.COMPANY_NON_WORKING_DAYS,
      legacyPath: LEGACY_ROUTES.COMPANY_NON_WORKING_DAYS,
      element: () => <LazyCompanyNonWorkingDays />,
      enabled: (permissions: Permissions) => permissions.isAdminUser,
    },
    machines: {
      newPath: APP_ROUTES.MACHINES,
      legacyPath: LEGACY_ROUTES.MACHINES,
      element: () => <LazyMachines />,
      enabled: (permissions: Permissions) => permissions.isAdminUser,
    },
    webhooks: {
      newPath: APP_ROUTES.WEBHOOKS,
      legacyPath: LEGACY_ROUTES.WEBHOOKS,
      element: () => <LazyWebhooks />,
      enabled: (permissions: Permissions) => permissions.canAccessWebhooksPage,
    },
    apiKeysManagement: {
      newPath: APP_ROUTES.API_KEYS_MANAGEMENT,
      legacyPath: LEGACY_ROUTES.API_KEYS_MANAGEMENT,
      element: () => <LazyApiKeysManagement />,
      enabled: (permissions: Permissions) =>
        permissions.canAccessApiKeysManagementPage,
    },
    scheduledTasks: {
      newPath: APP_ROUTES.SCHEDULED_TASKS,
      element: () => <LazyScheduledTasks />,
      enabled: (permissions: Permissions) => permissions.isAdminUser && !isProd,
    },
    teams: {
      newPath: APP_ROUTES.TEAMS,
      legacyPath: LEGACY_ROUTES.TEAMS,
      element: (props: { sideDrawerCollapse?: boolean }) => (
        <LazyTeamsPage sideDrawerCollapse={props.sideDrawerCollapse} />
      ),
      enabled: (permissions: Permissions) => permissions.canAccessTeamPage,
    },
    featureFlags: {
      newPath: APP_ROUTES.FEATURE_FLAGS,
      element: () => <LazyFeatureFlags />,
      enabled: (permissions: Permissions) => permissions.isOrbyAdmin,
    },
    featureFlagsCreate: {
      newPath: APP_ROUTES.FEATURE_FLAGS_CREATE,
      element: () => <LazyFeatureFlagCreationPage />,
      enabled: (permissions: Permissions) => permissions.isOrbyAdmin,
    },
    featureFlagsUpdate: {
      newPath: APP_ROUTES.FEATURE_FLAGS_UPDATE,
      element: () => <LazyFeatureFlagUpdatePage />,
      enabled: (permissions: Permissions) => permissions.isOrbyAdmin,
    },
    fallback: {
      newPath: '*',
      element: () => <Navigate to={generateOrgPath(APP_ROUTES.DASHBOARD)} />,
      enabled: true,
    },
  };

  return createProtectedRoutes({
    routeDefinitions,
    collapse,
    permissions,
  });
};
