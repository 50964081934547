import { workflowDetailsSelector } from './app.selectors';
import { WorkflowDetailsState } from '../reducers/workflow_detail.reducer';
import { createSelector } from '@reduxjs/toolkit';
import { AssignmentMode, DataLoadingStatus } from '../../utils/constants';
import {
  getReviewersListEmails,
  isManualAssignment,
} from '../../utils/WorkflowDetailUtils';
import {
  getActionById,
  getActions,
  getWorkflowEnvVariables,
  getWorkflowOutputVariables,
} from 'workflow-utils/src/workflow';

export const loadingSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) =>
    workflowDetails.loadingStatus === DataLoadingStatus.LOADING,
);

export const workflowAllDetailsSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) => workflowDetails.workflow,
);

export const workflowVariablesSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) =>
    getWorkflowOutputVariables(workflowDetails.workflow),
);

export const workflowEnvVariablesSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) =>
    getWorkflowEnvVariables(workflowDetails.workflow),
);

export const workflowHasUnsavedChangesSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) => workflowDetails.hasUnsavedChanges,
);

export const getWorkflowSchedulesSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) => workflowDetails.schedules,
);

export const createWorkflowScheduleLoadingSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) =>
    workflowDetails.createWorkflowScheduleLoadingStatus ===
    DataLoadingStatus.LOADING,
);

export const createWorkflowScheduleErrorSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) =>
    workflowDetails.createWorkflowScheduleError,
);

export const workflowSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) => workflowDetails.workflow,
);

export const deleteWorkflowScheduleErrorSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) =>
    workflowDetails.deleteWorkflowScheduleError,
);

export const getWorkflowScheduleSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) => workflowDetails.schedule,
);

export const getSchedulesLoadingSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) =>
    workflowDetails.schedulesLoadingStatus === DataLoadingStatus.LOADING,
);

export const getSchedulesErrorSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) => workflowDetails.schedulesError,
);

export const getInvalidChangesSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) => workflowDetails.invalidateChanges,
);

export const getRoundOneReviewerListSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) => {
    return workflowDetails.workflow
      ? getReviewersListEmails(workflowDetails.workflow, 1)
      : [];
  },
);

export const getRoundTwoReviewerListSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) => {
    return workflowDetails.workflow
      ? getReviewersListEmails(workflowDetails.workflow, 2)
      : [];
  },
);

export const getAssignmentModeSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) => {
    if (
      workflowDetails.workflow &&
      isManualAssignment(workflowDetails.workflow, 1)
    ) {
      return AssignmentMode.UNASSIGNED;
    }
    return AssignmentMode.ASSIGNED;
  },
);

export const getSamplePercentageSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) => {
    const reviewerList = workflowDetails.workflow?.reviewerLists?.find(
      (r) => r?.roundNumber === 2,
    );
    return (
      reviewerList?.triggerCondition?.conditionOptions?.percentOfRandomSample?.toString() ??
      '0'
    );
  },
);

export const getSaveWorkflowSuccessToastSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) =>
    workflowDetails.showSaveWorkflowSuccessToast,
);

export const getSaveWorkflowErrorToastSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) =>
    workflowDetails.showSaveWorkflowErrorToast,
);

export const getSaveWorkflowErrorSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) => workflowDetails.saveWorkflowError,
);

export const getSendInviteToAdminsCompletedSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) =>
    workflowDetails.showSendInviteToAdminsCompletedToast,
);

export const getWorkflowProcessesErrorSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) =>
    workflowDetails.workflowProcessesError,
);

export const getNewlyAddedActionIdSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) => workflowDetails.newlyAddedActionId,
);

export const selectedActionIdSelector = createSelector(
  [workflowDetailsSelector],
  (workflowDetails: WorkflowDetailsState) => workflowDetails.selectedActionId,
);

export const selectedActionSelector = createSelector(
  [workflowSelector, selectedActionIdSelector],
  (workflow, selectedActionId) => {
    if (!workflow) {
      return undefined;
    }
    const actions = getActions(workflow, undefined);
    return getActionById(selectedActionId!, actions!)!;
  },
);
