export const ANCHOR_ID = 'orby-root';
export const POINTER_ID = 'orby-pointer';
export const CONTEXT_CONTAINER_ID = 'orby-context-container';
export const CLICK_ANIMATION_ID = 'orby-click-animation';

export const WEB_SOCKET_SERVER_PREFIX = 'ws-server/v1alpha1';
export const EXTENSION_ID = 'hijnmoemdldebjokiebfcalfjkkdnejb';
export const EXTENSION_URL =
  'https://chrome.google.com/webstore/detail/' + EXTENSION_ID;

export const RECORD_PAGE_ID = 'orby-record-page';

export const LOW_CONFIDENCE_THRESHOLD = 20;
export const HIGH_CONFIDENCE_THRESHOLD = 80;

// default max tabs per machine
export const DEFAULT_MAX_TABS = 30;

// Dev only settings
export const EXECUTE_TASK_ON_CURRENT_TAB = false;
export const ALLOW_RECORDING_CHILD_WORKFLOW = false;
export const TOKEN_COOKIE_KEY_PLACEHOLDER = 'USE_COOKIE';

// Chrome Devtools Protocol debugger version
export const CDP_VERSION = '1.3';

// Default timeout for webpage overlays
export const WEB_OVERLAY_TIMEOUT = 2000;

// Extract Variable Smart Action
export const EXTRACT_VARIABLE_SMART_ACTION = 'variable-extraction-smart-action';

export enum Endpoint {
  LOCAL = 'local',
  US_DEV = 'us_dev',
  US_STAGING = 'us_staging',
  US_PREPROD = 'us_preprod',
  US = 'us',
  EU_PREPROD = 'eu_preprod',
  EU = 'eu',
}

interface EndpointConfig {
  ENV: string; // for Sentry
  BASE_URL: string;
  GRPC_URL: string;
  SENTRY_DSN: string;
}

const SENTRY_DSN = {
  US: 'https://e94ef890073cea40379501d023987932@o4506347956535296.ingest.sentry.io/4506736901881856',
  EU: 'https://96c5e03cfd169d6dede76b58a7644c04@o4508850032607232.ingest.de.sentry.io/4508850086412368',
};

export const ENDPOINT_CONFIG: Record<Endpoint, EndpointConfig> = {
  [Endpoint.LOCAL]: {
    ENV: 'local',
    GRPC_URL: 'https://0.0.0.0:8099',
    BASE_URL: 'http://localhost:3000',
    SENTRY_DSN: SENTRY_DSN.US,
  },
  [Endpoint.US_DEV]: {
    ENV: 'dev',
    GRPC_URL: 'https://dev2-grpc.orby.ai',
    BASE_URL: 'https://dev-web-app.orby.ai',
    SENTRY_DSN: SENTRY_DSN.US,
  },
  [Endpoint.US_STAGING]: {
    ENV: 'staging',
    GRPC_URL: 'https://staging2-grpc.orby.ai',
    BASE_URL: 'https://staging-web-app.orby.ai',
    SENTRY_DSN: SENTRY_DSN.US,
  },
  [Endpoint.US_PREPROD]: {
    ENV: 'preprod',
    GRPC_URL: 'https://preprod-grpc.orby.ai',
    BASE_URL: 'https://preprod-web-app.orby.ai',
    SENTRY_DSN: SENTRY_DSN.US,
  },
  [Endpoint.US]: {
    ENV: 'prod',
    GRPC_URL: 'https://grpc.orby.ai',
    BASE_URL: 'https://web-app.orby.ai',
    SENTRY_DSN: SENTRY_DSN.US,
  },
  [Endpoint.EU_PREPROD]: {
    ENV: 'preprod',
    GRPC_URL: 'https://preprod-grpc-eu.orby.ai',
    BASE_URL: 'https://preprod-web-eu.orby.ai',
    SENTRY_DSN: SENTRY_DSN.EU,
  },
  [Endpoint.EU]: {
    ENV: 'prod',
    GRPC_URL: 'https://grpc-eu.orby.ai',
    BASE_URL: 'https://web-eu.orby.ai',
    SENTRY_DSN: SENTRY_DSN.EU,
  },
};

export const DEFAULT_ENDPOINT =
  process.env.DEFAULT_ENDPOINT &&
  process.env.DEFAULT_ENDPOINT in ENDPOINT_CONFIG
    ? (process.env.DEFAULT_ENDPOINT as Endpoint)
    : Endpoint.US;
export const ENDPOINT_STORAGE_KEY = 'selected_endpoint';
