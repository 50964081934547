import React from 'react';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { Box, IconButton } from '@mui/material';
import { Variable } from 'protos/pb/v1alpha1/variables';
import { useState } from 'react';
import {
  DisplayVariableTable,
  getDisplayVariable,
  getVariableType,
} from 'workflow-utils/src/variable';
import OrbyTypography from '../typography/OrbyTypography';
import OrbyColorPalette from '../colors/ColorPalette';
import OrbyBadge from '../badge/OrbyBadge';
import WorkflowVariableValue from './WorkflowVariableValue';

const WorkflowVariableTable = (props: { variable: Variable }) => {
  const { variable } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const displayVariable = getDisplayVariable(variable);

  if (!displayVariable) return;

  return (
    <Box
      sx={{
        padding: '8px 12px',
        display: 'flex',
        flexDirection: 'column',
        gap: '2px',
        '&:hover': {
          backgroundColor: OrbyColorPalette['grey-100'],
        },
      }}
    >
      <Box
        sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
          }}
        >
          <OrbyTypography weight='medium' color={OrbyColorPalette['grey-900']}>
            {variable.name}
          </OrbyTypography>

          <OrbyBadge
            size='small'
            textColor={OrbyColorPalette['grey-700']}
            backgroundColor={OrbyColorPalette['grey-200']}
            badgeName='Table'
          />
        </Box>

        <IconButton
          title='Expand'
          aria-label='Expand'
          key={'Expand'}
          onClick={(e) => {
            e.stopPropagation();
            setIsExpanded(!isExpanded);
          }}
          sx={{
            height: '24px',
            width: '24px',
            transform: isExpanded ? 'rotate(270deg)' : 'rotate(90deg)',
            cursor: 'pointer',
          }}
        >
          <ChevronRightRoundedIcon
            sx={{
              color: OrbyColorPalette['grey-900'],
              height: '20x',
              width: '20px',
            }}
          />
        </IconButton>
      </Box>

      {!isExpanded ? (
        <OrbyTypography
          color={OrbyColorPalette['grey-700']}
          sx={{ wordBreak: 'break-all' }}
        >
          {Object.keys(displayVariable.value).join(', ')}
        </OrbyTypography>
      ) : (
        <WorkflowVariableFields variable={variable} />
      )}
    </Box>
  );
};

const WorkflowVariableFields = (props: { variable: Variable }) => {
  const { variable } = props;
  const fields = Object.keys(
    variable.type?.array?.entryType?.record?.fields ?? {},
  );

  const displayVariable = getDisplayVariable(variable) as DisplayVariableTable;

  return (
    <Box
      sx={{
        marginTop: '8px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        borderLeft: '1px solid',
        borderColor: OrbyColorPalette['grey-300'],
      }}
    >
      {fields.map((field) => {
        const type = variable.type?.array?.entryType?.record?.fields![field];
        return (
          <Box
            key={field}
            sx={{
              padding: '4px 12px',
              display: 'flex',
              flexDirection: 'column',
              gap: '2px',
            }}
          >
            <Box
              key={variable.name}
              sx={{
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <OrbyTypography
                weight='medium'
                color={OrbyColorPalette['grey-900']}
              >
                {field}
              </OrbyTypography>

              <OrbyBadge
                size='small'
                textColor={OrbyColorPalette['grey-700']}
                backgroundColor={OrbyColorPalette['grey-200']}
                badgeName={getVariableType(type!)}
              />
            </Box>

            <WorkflowVariableValue
              value={displayVariable.value[field].join(', ')}
              enableTooltip
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default WorkflowVariableTable;
