import React, { FC, useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectedOrgIdSelector } from '../../redux/selectors/user.selectors';

interface Props {
  toPath: string;
}

export const LegacyRouteRedirect: FC<Props> = ({ toPath }) => {
  const params = useParams();
  const orgId = useSelector(selectedOrgIdSelector);

  if (!orgId) {
    return null;
  }

  // Replace all route parameters in the new path
  const newPath = useMemo(() => {
    let path = toPath.replace(':organization_id', orgId);
    Object.entries(params).forEach(([key, value]) => {
      path = path.replace(`:${key}`, value as string);
    });
    return path;
  }, [toPath, orgId, params]);

  return <Navigate to={newPath} replace />;
};
