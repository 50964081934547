import {
  DeleteHolidayListRequest,
  GetHolidayListRequest,
  GetOrganizationRequest,
  CreateApiKeyRequest,
  ListApiKeysRequest,
  ListHolidayListsRequest,
  OrganizationsClientImpl,
  UpdateOrganizationRequest,
  UpsertHolidayListRequest,
  DeleteApiKeyRequest,
  UpdateApiKeyRequest,
  RotateApiKeyRequest,
  ListMicrosoftApplicationUsersRequest,
  GetMicrosoftApplicationUserRequest,
  ListMicrosoftApplicationUsersResponse,
  GetMicrosoftApplicationUserResponse,
} from 'protos/pb/v1alpha1/organization_service';
import { getMetaData, rpcWithErrorHandling } from '../utils/RpcUtills';
import { storageService } from './StorageService';

export class OrganizationsService {
  private static instance: OrganizationsService;
  private static client = new OrganizationsClientImpl(rpcWithErrorHandling);

  public static getInstance(): OrganizationsService {
    if (!this.instance) {
      this.instance = new OrganizationsService();
    }
    return this.instance;
  }

  async updateOrganization(req: UpdateOrganizationRequest) {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await OrganizationsService.client.UpdateOrganization(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async getOrganization(req: GetOrganizationRequest) {
    const authorization = await storageService.getAuthorizationHeader();

    try {
      const response = await OrganizationsService.client.GetOrganization(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async upsertHolidayList(req: UpsertHolidayListRequest) {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await OrganizationsService.client.UpsertHolidayList(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async listHolidayLists(req: ListHolidayListsRequest) {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await OrganizationsService.client.ListHolidayLists(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async getHolidayList(req: GetHolidayListRequest) {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await OrganizationsService.client.GetHolidayList(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async deleteHolidayList(req: DeleteHolidayListRequest) {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await OrganizationsService.client.DeleteHolidayList(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async listApiKeys(req: ListApiKeysRequest) {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await OrganizationsService.client.ListApiKeys(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async createApiKey(req: CreateApiKeyRequest) {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await OrganizationsService.client.CreateApiKey(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  public async deleteApiKey(request: DeleteApiKeyRequest) {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await OrganizationsService.client.DeleteApiKey(
        request,
        getMetaData({ authorization }),
      );
      return { response, error: null };
    } catch (error) {
      return { error: error as Error };
    }
  }

  public async updateApiKey(request: UpdateApiKeyRequest) {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await OrganizationsService.client.UpdateApiKey(
        request,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  public async rotateApiKey(request: RotateApiKeyRequest) {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await OrganizationsService.client.RotateApiKey(
        request,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async listMicrosoftApplicationUsers(
    req: ListMicrosoftApplicationUsersRequest,
  ): Promise<{
    response?: ListMicrosoftApplicationUsersResponse;
    error?: Error;
  }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response =
        await OrganizationsService.client.ListMicrosoftApplicationUsers(
          req,
          getMetaData({ authorization }),
        );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async getMicrosoftUser(req: GetMicrosoftApplicationUserRequest): Promise<{
    response?: GetMicrosoftApplicationUserResponse;
    error?: Error;
  }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await OrganizationsService.client.GetMicrosoftUser(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }
}

export const organizationsService = OrganizationsService.getInstance();
