import { createSelector } from 'reselect';
import { featureFlagsSelector } from './app.selectors';
import { DataLoadingStatus } from '../../utils/constants';

export const featureFlagsListSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) => featureFlagsState.featureFlags,
);

export const featureFlagsLoadingSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) =>
    featureFlagsState.loadingFeatureFlagsListStatus ===
    DataLoadingStatus.LOADING,
);

export const featureFlagsLoadedSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) =>
    featureFlagsState.loadingFeatureFlagsListStatus ===
    DataLoadingStatus.LOADED,
);

export const featureFlagsErrorSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) =>
    featureFlagsState.loadingFeatureFlagsListStatus === DataLoadingStatus.ERROR
      ? featureFlagsState.listFeatureFlagsError
      : undefined,
);

export const featureFlagsTotalSizeSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) => featureFlagsState.totalSize,
);

export const createdFeatureFlagSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) => featureFlagsState.createdFeatureFlag,
);

export const updatedFeatureFlagSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) => featureFlagsState.updatedFeatureFlag,
);

export const processFeatureFlagErrorSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) =>
    featureFlagsState.processingFeatureFlagStatus === DataLoadingStatus.ERROR
      ? featureFlagsState.processFeatureFlagError
      : undefined,
);

export const processingFeatureFlagSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) =>
    featureFlagsState.processingFeatureFlagStatus === DataLoadingStatus.LOADING,
);

export const selectedFeatureFlagSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) => featureFlagsState.selectedFeatureFlag,
);

export const selectedFeatureFlagLoadingSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) =>
    featureFlagsState.selectedFeatureFlagLoadingStatus ===
    DataLoadingStatus.LOADING,
);

export const selectedFeatureFlagErrorSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) =>
    featureFlagsState.selectedFeatureFlagLoadingStatus ===
    DataLoadingStatus.ERROR
      ? featureFlagsState.selectedFeatureFlagError
      : undefined,
);

export const getFeatureFlagsForOrgAndUserSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) => featureFlagsState.featureFlagsForOrgAndUser,
);

export const getFeatureFlagsForOrgAndUserLoadingSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) =>
    featureFlagsState.featureFlagsForOrgAndUserLoadingStatus ===
    DataLoadingStatus.LOADING,
);

export const isFeatureFlagEnabledSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) => featureFlagsState.isFeatureFlagEnabled,
);

export const isFeatureFlagEnabledLoadingSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) =>
    featureFlagsState.isFeatureFlagEnabledLoadingStatus ===
    DataLoadingStatus.LOADING,
);

export const isFeatureFlagEnabledErrorSelector = createSelector(
  [featureFlagsSelector],
  (featureFlagsState) =>
    featureFlagsState.isFeatureFlagEnabledLoadingStatus ===
    DataLoadingStatus.ERROR
      ? featureFlagsState.isFeatureFlagEnabledError
      : undefined,
);
