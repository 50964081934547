import { Document } from 'protos/google/cloud/documentai/v1/document';
import {
  GetTaskRequest,
  Task,
  UpdateReviewTaskRequest,
  UpdateTaskRequest,
} from 'protos/pb/v1alpha2/tasks_service';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ReviewTaskActionType } from '../actions/actions.constants';
import {
  getTaskForReviewCompletedAction,
  getTaskForReviewErrorAction,
  saveTaskErrorAction,
  saveTaskCompletedAction,
  setAutomationCompleted,
  setAutomationCompletedError,
  updateTaskCompletedAction,
  updateTaskErrorAction,
} from '../actions/review_task.action';
import { gcsService } from '../../services/GcsService';
import { tasksV2Service } from '../../services/TasksV2Service';
import {
  getDocumentProtoURI,
  getSelectedTaskDocument,
  getWorkflowIdFromTaskResourceName,
} from '../../utils/helpers';
import { getFeatureFlagsForOrgAndUserSelector } from '../selectors/feature_flags.selectors';
import { FEATURE_FLAGS } from '../../utils/constants';
import { isFeatureFlagEnabled } from '../../pages/FeatureFlags/FeatureFlagUtils';
import { featureFlagService } from '../../services/FeatureFlagService';
import { IsFeatureFlagEnabledRequest } from 'protos/pb/v1alpha2/feature_flag_service';

export function* getTaskSaga(data: {
  type: ReviewTaskActionType;
  payload: GetTaskRequest;
  task?: Task;
  isOrbot?: boolean;
}) {
  try {
    let responseTask, err;
    if (data.task) {
      responseTask = Task.fromPartial(data.task);
    } else {
      const { response, error }: { response?: Task; error?: Error } =
        yield call(
          data.isOrbot
            ? tasksV2Service.getTaskSmartAction
            : tasksV2Service.getTask,
          data.payload,
        );
      responseTask = response;
      err = error;
    }
    if (responseTask) {
      const task = responseTask;
      const documentStep = getSelectedTaskDocument(task);
      const docProtoURI = getDocumentProtoURI(documentStep);
      if (docProtoURI) {
        const { response, error } = yield call(
          gcsService.getUriContent,
          docProtoURI,
        );
        if (error) {
          yield put(
            getTaskForReviewErrorAction(
              `Failed to fetch content for task: ${task.name}`,
            ),
          );
          return;
        } else {
          const textDoc = Document.decode(response);
          textDoc.uri = docProtoURI;
          documentStep!.documents![0] = textDoc;
        }
      }
      const featureFlags: Record<string, boolean> | undefined = yield select(
        getFeatureFlagsForOrgAndUserSelector,
      );
      let isInferenceEnabled = isFeatureFlagEnabled(
        FEATURE_FLAGS.INFERENCE_ENABLED,
        featureFlags,
      );

      const workflowId = getWorkflowIdFromTaskResourceName(task.name ?? '');
      // If the feature flag is not enabled for the org and user, we need to check if it is enabled for the workflow
      if (workflowId && !isInferenceEnabled) {
        const { response } = yield call(
          featureFlagService.isFeatureFlagEnabled,
          IsFeatureFlagEnabledRequest.create({
            name: FEATURE_FLAGS.INFERENCE_ENABLED,
            workflowId,
          }),
        );
        if (response) {
          isInferenceEnabled = response.enabled;
        }
      }

      yield put(
        getTaskForReviewCompletedAction(
          task,
          data.task !== undefined,
          isInferenceEnabled,
        ),
      );
    } else {
      yield put(getTaskForReviewErrorAction(err));
    }
  } catch (error) {
    yield put(getTaskForReviewErrorAction(error as string));
  }
}

export function* updateTaskSaga(data: {
  type: ReviewTaskActionType;
  payload: UpdateReviewTaskRequest | UpdateTaskRequest;
  isOrbot?: boolean;
}) {
  try {
    const { response, error } = yield call(
      data.isOrbot
        ? tasksV2Service.updateReviewTask
        : tasksV2Service.updateTask,
      data.payload as any,
    );
    if (response) {
      yield put(updateTaskCompletedAction(response));
      yield put(setAutomationCompleted(true));
    } else {
      yield put(updateTaskErrorAction(error));
      yield put(setAutomationCompletedError(error));
    }
  } catch (error) {
    yield put(updateTaskErrorAction(error));
    yield put(setAutomationCompletedError(error));
  }
}

function* saveTaskSaga(data: {
  type: ReviewTaskActionType;
  payload: UpdateTaskRequest | UpdateReviewTaskRequest;
  isOrbot?: boolean;
}) {
  try {
    const { response, error } = yield call(
      data.isOrbot
        ? tasksV2Service.updateReviewTask
        : tasksV2Service.updateTask,
      data.payload as any,
    );
    if (response) {
      yield put(saveTaskCompletedAction(response));
    } else {
      yield put(saveTaskErrorAction(error));
    }
  } catch (error) {
    yield put(saveTaskErrorAction(error));
  }
}

function* reviewTaskSaga() {
  yield all([
    takeLatest(ReviewTaskActionType.GET_TASK_FOR_REVIEW, getTaskSaga),
    takeLatest(ReviewTaskActionType.UPDATE_TASK, updateTaskSaga),
    takeLatest(ReviewTaskActionType.SAVE_TASK, saveTaskSaga),
  ]);
}

export default reviewTaskSaga;
