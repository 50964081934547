/* eslint-disable */
// @ts-nocheck
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import { SortField } from "../../common/data_query_params";
import { UserProfileInfo } from "../../common/user_profile";
import { Empty } from "../../google/protobuf/empty";
import { FieldMask } from "../../google/protobuf/field_mask";
import { Timestamp } from "../../google/protobuf/timestamp";
import { messageTypeRegistry } from "../../typeRegistry";
import { Attribute, ProcessPath, SopSource, StepType } from "../process_discovery/dataset";
import { ObservationSession } from "../process_discovery/observation_session";
import { Process, ProcessInstance } from "../process_discovery/process";
import { Action, UserEvent } from "../v1alpha1/orbot_action";
import { User } from "../v1alpha1/user";

export const protobufPackage = "pb.v1alpha2";

/**
 * This is how the user defines for the process and will be stored in the
 * database.
 * The DB object will also store the some additional fields
 * such as the generated processMaps etc.
 */
export interface UserDefinedProcess {
  $type?: "pb.v1alpha2.UserDefinedProcess";
  id?:
    | string
    | undefined;
  /** The name of the process */
  name?:
    | string
    | undefined;
  /** The description of the process */
  description?:
    | string
    | undefined;
  /**
   * The base step definitions for the process.
   * Only the display name, description and application are
   * inputted by the user.
   */
  baseStepDefinition?:
    | StepType[]
    | undefined;
  /** The variant step definitions for the process */
  variantStepDefinition?:
    | StepType[]
    | undefined;
  /**
   * User defines the attributes for the application and ML will
   * associate the attributes with the step definitions.
   */
  applicationAttributes?:
    | ApplicationAttributes[]
    | undefined;
  /** The users that are associated with the process */
  userIds?: string[] | undefined;
  createTime?: Date | undefined;
  lastUpdateTime?:
    | Date
    | undefined;
  /** User who created the process */
  creator?:
    | UserProfileInfo
    | undefined;
  /**
   * The total number of process instances for the process
   * This number will be added by the server when listing processes.
   */
  totalInstances?:
    | number
    | undefined;
  /** The process paths for the process */
  processPaths?:
    | ProcessPath[]
    | undefined;
  /** The GCS URI of the where the Process SOP is stored. */
  sopSource?:
    | SopSource
    | undefined;
  /** The attributes for the process */
  processAttributes?: Attribute[] | undefined;
  status?:
    | UserDefinedProcessStatus
    | undefined;
  /** The applications allowed to be used in the process */
  allowedApplications?: Application[] | undefined;
}

export enum UserDefinedProcessStatus {
  UNSPECIFIED = 0,
  DRAFT = 1,
  PUBLISHED = 2,
  GENERATING_DOCUMENTATION = 3,
  UNRECOGNIZED = -1,
}

export function userDefinedProcessStatusFromJSON(object: any): UserDefinedProcessStatus {
  switch (object) {
    case 0:
    case "STATUS_UNSPECIFIED":
      return UserDefinedProcessStatus.UNSPECIFIED;
    case 1:
    case "STATUS_DRAFT":
      return UserDefinedProcessStatus.DRAFT;
    case 2:
    case "STATUS_PUBLISHED":
      return UserDefinedProcessStatus.PUBLISHED;
    case 3:
    case "STATUS_GENERATING_DOCUMENTATION":
      return UserDefinedProcessStatus.GENERATING_DOCUMENTATION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UserDefinedProcessStatus.UNRECOGNIZED;
  }
}

export function userDefinedProcessStatusToJSON(object: UserDefinedProcessStatus): string {
  switch (object) {
    case UserDefinedProcessStatus.UNSPECIFIED:
      return "STATUS_UNSPECIFIED";
    case UserDefinedProcessStatus.DRAFT:
      return "STATUS_DRAFT";
    case UserDefinedProcessStatus.PUBLISHED:
      return "STATUS_PUBLISHED";
    case UserDefinedProcessStatus.GENERATING_DOCUMENTATION:
      return "STATUS_GENERATING_DOCUMENTATION";
    case UserDefinedProcessStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Application {
  $type?: "pb.v1alpha2.Application";
  id?:
    | string
    | undefined;
  /** The name of the application */
  name?:
    | string
    | undefined;
  /** The description of the application */
  description?:
    | string
    | undefined;
  /** The logo of the application */
  applicationLogoUrl?:
    | string
    | undefined;
  /** The url of the application which we need to add to allowlist of the process */
  applicationUrl?: string | undefined;
}

export interface ApplicationAttributes {
  $type?: "pb.v1alpha2.ApplicationAttributes";
  application?:
    | string
    | undefined;
  /** The attributes associated with the application */
  attributes?: Attribute[] | undefined;
}

export interface CreateProcessRequest {
  $type?: "pb.v1alpha2.CreateProcessRequest";
  /**
   * The id, creator, create_time, last_update_time inside
   * the user_defined_process are added by BE.
   */
  userDefinedProcess?: UserDefinedProcess | undefined;
}

export interface CreateProcessResponse {
  $type?: "pb.v1alpha2.CreateProcessResponse";
  userDefinedProcess?: UserDefinedProcess | undefined;
}

export interface GetProcessDefinitionRequest {
  $type?: "pb.v1alpha2.GetProcessDefinitionRequest";
  processId?: string | undefined;
}

export interface GetProcessDefinitionResponse {
  $type?: "pb.v1alpha2.GetProcessDefinitionResponse";
  process?: UserDefinedProcess | undefined;
}

export interface GetProcessRequest {
  $type?: "pb.v1alpha2.GetProcessRequest";
  processId?: string | undefined;
}

/**
 * GetProcessResponse takes in the id of the user_defined_process and
 * returns the corresponding processMap.
 */
export interface GetProcessResponse {
  $type?: "pb.v1alpha2.GetProcessResponse";
  process?: Process | undefined;
}

/** ListProcessesRequest is the request for listing all the processes. */
export interface ListProcessesRequest {
  $type?: "pb.v1alpha2.ListProcessesRequest";
  /** Maximum number of results to return */
  pageSize?:
    | number
    | undefined;
  /** Page number for the current request */
  pageNumber?:
    | number
    | undefined;
  /**
   * Supported filter: "name_prefix={SEARCH_KEY},
   * applications={semicolon separated applications},
   * creators={semicolon separated creator ids},
   * start_time={UNIX_TIME_SEC},end_time={UNIX_TIME_SEC}"
   * start_time and end_time in the filter filters the sessions with
   * session end time between start_time and end_time. Both start_time and
   * end_time are required to filter based on time range.
   * name_prefix is used to search for a process with a given prefix
   * applications is used to filter the processes for a given application
   * creators is used to filter the processes for a given creator
   */
  filter?:
    | string
    | undefined;
  /** optional: Currently support sort on name, time */
  sortFields?: SortField[] | undefined;
}

export interface ListProcessesResponse {
  $type?: "pb.v1alpha2.ListProcessesResponse";
  /**
   * Deprecated: Use user_defined_processes instead
   *
   * @deprecated
   */
  processes?: Process[] | undefined;
  totalCount?: number | undefined;
  userDefinedProcesses?: UserDefinedProcess[] | undefined;
}

export interface UpdateProcessRequest {
  $type?: "pb.v1alpha2.UpdateProcessRequest";
  userDefinedProcess?:
    | UserDefinedProcess
    | undefined;
  /**
   * Supported fields: base_step_definitions,
   * variant_step_definitions, application_attributes,
   * user_ids, sop_source, process_attributes,
   * status, allowed_applications
   */
  updateMask?: string[] | undefined;
}

export interface UpdateProcessResponse {
  $type?: "pb.v1alpha2.UpdateProcessResponse";
  userDefinedProcess?: UserDefinedProcess | undefined;
}

export interface DeleteProcessRequest {
  $type?: "pb.v1alpha2.DeleteProcessRequest";
  processId?: string | undefined;
}

export interface GetTraceUsersFilter {
  $type?: "pb.v1alpha2.GetTraceUsersFilter";
  /** Filter by user name or email, only support prefix-based name queries. */
  nameEmailPrefix?: string | undefined;
}

export interface GetTraceUsersRequest {
  $type?: "pb.v1alpha2.GetTraceUsersRequest";
  /** @deprecated */
  processId?: string | undefined;
  filter?: GetTraceUsersFilter | undefined;
}

export interface GetTraceUsersResponse {
  $type?: "pb.v1alpha2.GetTraceUsersResponse";
  users?: User[] | undefined;
}

export interface ListTracesRequest {
  $type?: "pb.v1alpha2.ListTracesRequest";
  /**
   * Traces are not associated with a process anymore.
   *
   * @deprecated
   */
  processId?:
    | string
    | undefined;
  /** Maximum number of results to return */
  pageSize?:
    | number
    | undefined;
  /** Page number for the current request (starts from 1) */
  pageNumber?:
    | number
    | undefined;
  /** optional: Currently support sort on session name and end time */
  sortFields?:
    | SortField[]
    | undefined;
  /**
   * Supported filter: "trace_name_prefix={SEARCH_KEY},user_ids={semicolon
   * separated user_ids},start_time={UNIX_TIME_SEC},end_time={UNIX_TIME_SEC}"
   * start_time and end_time in the filter filters the sessions with
   * session end time between start_time and end_time. Both start_time and
   * end_time are required to filter based on time range.
   * trace_name_prefix is used to search for a session with a given prefix
   * user_ids is used to filter the sessions for a given user
   */
  filter?: string | undefined;
}

export interface ListTracesResponse {
  $type?: "pb.v1alpha2.ListTracesResponse";
  traces?: ObservationSession[] | undefined;
  totalCount?:
    | number
    | undefined;
  /** The total number of unique users in the traces for the process */
  totalUsers?: number | undefined;
}

export interface GetTraceRequest {
  $type?: "pb.v1alpha2.GetTraceRequest";
  traceId?:
    | string
    | undefined;
  /**
   * The field mask to specify the fields to return in the actions
   * TODO: Define the supported fields in the field mask
   */
  fieldMask?: string[] | undefined;
}

export interface GetTraceResponse {
  $type?: "pb.v1alpha2.GetTraceResponse";
  /** The actions in the trace */
  actions?: Action[] | undefined;
}

export interface DeleteTraceRequest {
  $type?: "pb.v1alpha2.DeleteTraceRequest";
  traceId?: string | undefined;
}

export interface UpdateTraceRequest {
  $type?: "pb.v1alpha2.UpdateTraceRequest";
  traceId?:
    | string
    | undefined;
  /** The trace to update */
  trace?:
    | ObservationSession
    | undefined;
  /**
   * The field mask to specify the fields to update in the trace
   * Supported fields: name
   */
  updateMask?: string[] | undefined;
}

export interface UpdateTraceResponse {
  $type?: "pb.v1alpha2.UpdateTraceResponse";
  updatedTrace?: ObservationSession | undefined;
}

export interface GetProcessMapRequest {
  $type?: "pb.v1alpha2.GetProcessMapRequest";
  /** The process id for which the process map is generated. */
  processId?:
    | string
    | undefined;
  /**
   * The path ids for which the process map is generated.
   * Optional: If not provided, the process map will be
   * generated for all the paths.
   */
  pathIds?:
    | string[]
    | undefined;
  /**
   * Whether regenerate the process map or just return the existing one
   * if available.
   */
  refresh?: boolean | undefined;
}

export interface GetProcessMapResponse {
  $type?: "pb.v1alpha2.GetProcessMapResponse";
  /** The process map if it is already generated. */
  processMap?:
    | Process
    | undefined;
  /** Process map generation status */
  status?:
    | GetProcessMapResponseProcessMapGenerationStatus
    | undefined;
  /** The error message if the process map generation fails. */
  errorMessage?: string | undefined;
}

export enum GetProcessMapResponseProcessMapGenerationStatus {
  UNSPECIFIED = 0,
  GENERATED = 1,
  GENERATING = 2,
  FAILED = 3,
  NO_DATA = 4,
  UNRECOGNIZED = -1,
}

export function getProcessMapResponseProcessMapGenerationStatusFromJSON(
  object: any,
): GetProcessMapResponseProcessMapGenerationStatus {
  switch (object) {
    case 0:
    case "PROCESS_MAP_GENERATION_STATUS_UNSPECIFIED":
      return GetProcessMapResponseProcessMapGenerationStatus.UNSPECIFIED;
    case 1:
    case "PROCESS_MAP_GENERATION_STATUS_GENERATED":
      return GetProcessMapResponseProcessMapGenerationStatus.GENERATED;
    case 2:
    case "PROCESS_MAP_GENERATION_STATUS_GENERATING":
      return GetProcessMapResponseProcessMapGenerationStatus.GENERATING;
    case 3:
    case "PROCESS_MAP_GENERATION_STATUS_FAILED":
      return GetProcessMapResponseProcessMapGenerationStatus.FAILED;
    case 4:
    case "PROCESS_MAP_GENERATION_STATUS_NO_DATA":
      return GetProcessMapResponseProcessMapGenerationStatus.NO_DATA;
    case -1:
    case "UNRECOGNIZED":
    default:
      return GetProcessMapResponseProcessMapGenerationStatus.UNRECOGNIZED;
  }
}

export function getProcessMapResponseProcessMapGenerationStatusToJSON(
  object: GetProcessMapResponseProcessMapGenerationStatus,
): string {
  switch (object) {
    case GetProcessMapResponseProcessMapGenerationStatus.UNSPECIFIED:
      return "PROCESS_MAP_GENERATION_STATUS_UNSPECIFIED";
    case GetProcessMapResponseProcessMapGenerationStatus.GENERATED:
      return "PROCESS_MAP_GENERATION_STATUS_GENERATED";
    case GetProcessMapResponseProcessMapGenerationStatus.GENERATING:
      return "PROCESS_MAP_GENERATION_STATUS_GENERATING";
    case GetProcessMapResponseProcessMapGenerationStatus.FAILED:
      return "PROCESS_MAP_GENERATION_STATUS_FAILED";
    case GetProcessMapResponseProcessMapGenerationStatus.NO_DATA:
      return "PROCESS_MAP_GENERATION_STATUS_NO_DATA";
    case GetProcessMapResponseProcessMapGenerationStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface GenerateDocumentationRequest {
  $type?: "pb.v1alpha2.GenerateDocumentationRequest";
  configGenerationRequest?: GenerateDocumentationRequestConfigGenerationRequest | undefined;
  sopAndConfigGenerationRequest?: GenerateDocumentationRequestSopAndConfigGenerationRequest | undefined;
  sopAndConfigUpdateRequest?:
    | GenerateDocumentationRequestSopAndConfigUpdateRequest
    | undefined;
  /** The process id for which the documentation is generated. */
  processId?: string | undefined;
}

export interface GenerateDocumentationRequestConfigGenerationRequest {
  $type?: "pb.v1alpha2.GenerateDocumentationRequest.ConfigGenerationRequest";
  /** The source of the SOP document where the SOP will be stored. */
  sopSource?: SopSource | undefined;
}

export interface GenerateDocumentationRequestSopAndConfigGenerationRequest {
  $type?: "pb.v1alpha2.GenerateDocumentationRequest.SopAndConfigGenerationRequest";
  /**
   * The golden traces to generate a SOP document and process
   * configuration from.
   */
  traceIds?: string[] | undefined;
}

export interface GenerateDocumentationRequestSopAndConfigUpdateRequest {
  $type?: "pb.v1alpha2.GenerateDocumentationRequest.SopAndConfigUpdateRequest";
  /** The SOP should already exist. */
  sopSource?:
    | SopSource
    | undefined;
  /** The user feedback for the updated SOP in natural language. */
  userFeedback?: string | undefined;
}

export interface GenerateDocumentationResponse {
  $type?: "pb.v1alpha2.GenerateDocumentationResponse";
  /**
   * The generated process config which also contains the SOP document
   * in it.
   */
  processConfig?: UserDefinedProcess | undefined;
}

export interface ListProcessInstancesRequest {
  $type?: "pb.v1alpha2.ListProcessInstancesRequest";
  processId?:
    | string
    | undefined;
  /**
   * The filter for the process instances.
   * Supported filters: "step_type_ids={semicolon separated step_type_ids},
   * paths={semicolon separated path_ids}"
   * step_type_ids is used to filter the process instances
   * for a given step type id
   * paths is used to filter the process instances for a given path id
   */
  filter?:
    | string
    | undefined;
  /** The maximum number of results to return */
  pageSize?:
    | number
    | undefined;
  /** The page number for the current request (starts from 1) */
  pageNumber?: number | undefined;
}

export interface ListProcessInstancesResponse {
  $type?: "pb.v1alpha2.ListProcessInstancesResponse";
  processInstances?:
    | ProcessInstance[]
    | undefined;
  /** The total number of process instances for the process */
  totalCount?: number | undefined;
}

export interface GetProcessInstanceRequest {
  $type?: "pb.v1alpha2.GetProcessInstanceRequest";
  processInstanceId?: string | undefined;
}

export interface GetProcessInstanceResponse {
  $type?: "pb.v1alpha2.GetProcessInstanceResponse";
  /**
   * This will contain the process instance with all the screenshot
   * data for each action as well
   */
  processInstance?: ProcessInstance | undefined;
}

export interface UpdateProcessInstanceRequest {
  $type?: "pb.v1alpha2.UpdateProcessInstanceRequest";
  processInstance?:
    | ProcessInstance
    | undefined;
  /**
   * supported fields: display_name, path_id, attributes, status, reviews
   * annotation
   */
  updateMask?: string[] | undefined;
}

export interface UpdateProcessInstanceResponse {
  $type?: "pb.v1alpha2.UpdateProcessInstanceResponse";
  updatedProcessInstance?: ProcessInstance | undefined;
}

export interface GetVariantPathsRequest {
  $type?: "pb.v1alpha2.GetVariantPathsRequest";
  processId?: string | undefined;
}

export interface GetVariantPathsResponse {
  $type?: "pb.v1alpha2.GetVariantPathsResponse";
  /**
   * The list of variant paths for the process is not sorted by default and
   * will be done by the client.
   * This will not be a paginated response.
   */
  variantPaths?: GetVariantPathsResponseVariantPath[] | undefined;
}

export interface GetVariantPathsResponseVariantPath {
  $type?: "pb.v1alpha2.GetVariantPathsResponse.VariantPath";
  /** The id of the variant path */
  pathId?:
    | string
    | undefined;
  /** The process map for the variant path */
  process?:
    | Process
    | undefined;
  /** The frequency of the process instances for the variant path */
  processInstanceFrequency?:
    | number
    | undefined;
  /**
   * The average time spent for the process instances for the variant path
   * in seconds
   */
  averageTimeSpent?:
    | number
    | undefined;
  /** The longest process instance for the variant path in seconds */
  longestTimeSpent?:
    | number
    | undefined;
  /** The shortest process instance for the variant path in seconds */
  shortestTimeSpent?: number | undefined;
}

/** Request to generate a process configuration from an existing SOP. */
export interface ConfigGenerationRequest {
  $type?: "pb.v1alpha2.ConfigGenerationRequest";
  /**
   * The source of the SOP document where the SOP will be stored.
   * If the source is non-existent, then an error will be returned.
   */
  sopSource?: SopSource | undefined;
}

/**
 * Request to generate a SOP document and process
 * configuration from golden traces.
 */
export interface SopAndConfigGenerationRequest {
  $type?: "pb.v1alpha2.SopAndConfigGenerationRequest";
  /**
   * The golden traces to generate a SOP document and process
   * configuration from.
   */
  traceEvents?:
    | SopAndConfigGenerationRequestTraceEvents[]
    | undefined;
  /**
   * The source of the SOP document where the SOP will be stored.
   * This will not be used to generate the process configuration.
   */
  sopSource?: SopSource | undefined;
}

export interface SopAndConfigGenerationRequestTraceEvents {
  $type?: "pb.v1alpha2.SopAndConfigGenerationRequest.TraceEvents";
  traceId?:
    | string
    | undefined;
  /** The user events for the trace. */
  userEvents?: UserEvent[] | undefined;
}

/**
 * Request to update an existing SOP and process configuration
 * based on user feedback.
 */
export interface SopAndConfigUpdateRequest {
  $type?: "pb.v1alpha2.SopAndConfigUpdateRequest";
  /**
   * The SOP should already exist and the updated SOP will be sent
   * in the response.
   */
  sopSource?:
    | SopSource
    | undefined;
  /** The user feedback for the updated SOP in natural language. */
  userFeedback?: string | undefined;
}

/**
 * This is the request format for the GenerateDocumentation
 * ML pipeline.
 */
export interface MLGenerateDocumentationRequest {
  $type?: "pb.v1alpha2.MLGenerateDocumentationRequest";
  configGenerationRequest?: ConfigGenerationRequest | undefined;
  sopAndConfigGenerationRequest?: SopAndConfigGenerationRequest | undefined;
  sopAndConfigUpdateRequest?:
    | SopAndConfigUpdateRequest
    | undefined;
  /**
   * The metadata of the process like the process id, name, description,
   * allowed applications
   *
   * This will be used to keep the same metadata for the process config and
   * SOP document.
   */
  processMetadata?:
    | UserDefinedProcess
    | undefined;
  /**
   * The GCS URI where the process discovery ML debugging
   * information can be stored. This is passed to save PII data in
   * proper buckets for tenant.
   * The GCS URI should be in the following format:
   * gs://<bucket_name>/<directory_to_process_discovery_debugging>
   */
  outputProcessDiscoveryDebuggingGcsPath?: string | undefined;
}

/**
 * This is the response format for the GenerateDocumentation
 * ML pipeline.
 */
export interface MLGenerateDocumentationResponse {
  $type?: "pb.v1alpha2.MLGenerateDocumentationResponse";
  /** The generated process config. */
  processConfig?: UserDefinedProcess | undefined;
}

/**
 * This is the request format for the ProcessDiscoveryAnnotation
 * ML pipeline.
 */
export interface MLProcessDiscoveryAnnotationRequest {
  $type?: "pb.v1alpha2.MLProcessDiscoveryAnnotationRequest";
  /** The list of user events for one session. */
  userEvents?:
    | UserEvent[]
    | undefined;
  /** The process config for which the user events are annotated. */
  processConfig?:
    | UserDefinedProcess
    | undefined;
  /**
   * The GCS URI where the process discovery ML debugging
   * information can be stored. This is passed to save PII data in
   * proper buckets for tenant.
   * The GCS URI should be in the following format:
   * gs://<bucket_name>/<directory_to_process_discovery_debugging>
   */
  outputProcessDiscoveryDebuggingGcsPath?:
    | string
    | undefined;
  /**
   * The GCS URI prefix where the process instances will be stored.
   * The process instances will be stored in the following format:
   * <output_process_instances_gcs_prefix>/<process_instance_id>.proto
   * One trace given as input to the ML pipeline may result in multiple
   * process instances.
   */
  outputProcessInstancesGcsPrefix?:
    | string
    | undefined;
  /** The gcs uris of the annotated process instances for few shot. */
  annotatedProcessInstanceGcsUris?: string[] | undefined;
}

/**
 * This is the response format for the ProcessDiscoveryAnnotation
 * ML pipeline.
 */
export interface MLProcessDiscoveryAnnotationResponse {
  $type?: "pb.v1alpha2.MLProcessDiscoveryAnnotationResponse";
  processInstances?: MLProcessDiscoveryAnnotationResponseProcessInstanceInfo[] | undefined;
}

export interface MLProcessDiscoveryAnnotationResponseProcessInstanceInfo {
  $type?: "pb.v1alpha2.MLProcessDiscoveryAnnotationResponse.ProcessInstanceInfo";
  /** The id of the process instance. */
  processInstanceId?:
    | string
    | undefined;
  /** The id of the path which the process instance belongs to. */
  pathId?:
    | string
    | undefined;
  /**
   * The GCS URI where the process instance will be stored.
   * The GCS URI should be in the following format:
   * gs://<bucket_name>/<path_to_process_instance>
   */
  processInstanceGcsUri?: string | undefined;
}

/**
 * This is the request format for the ProcessMapGeneration
 * ML pipeline.
 */
export interface MLProcessMapGenerationRequest {
  $type?: "pb.v1alpha2.MLProcessMapGenerationRequest";
  /** The process id for which the process map is generated. */
  processId?:
    | string
    | undefined;
  /**
   * The path ids for which the process map is generated.
   * Optional: If not provided, the process map will be
   * generated for all the paths.
   */
  pathIds?:
    | string[]
    | undefined;
  /** The GCS URI where the process map will be stored. */
  outputProcessMapGcsUri?:
    | string
    | undefined;
  /** The annotated process instances for the process map generation. */
  annotatedProcessInstanceGcsUris?:
    | string[]
    | undefined;
  /**
   * The GCS URI where the process discovery ML debugging
   * information can be stored. This is passed to save PII data in
   * proper buckets for tenant.
   */
  outputProcessDiscoveryDebuggingGcsPath?: string | undefined;
}

/**
 * This is the response format for the ProcessMapGeneration
 * ML pipeline.
 */
export interface MLProcessMapGenerationResponse {
  $type?: "pb.v1alpha2.MLProcessMapGenerationResponse";
  /**
   * The GCS URI where the process map will be stored.
   * The GCS URI should be in the following format:
   * gs://<bucket_name>/<path_to_process_map>
   * This is only set if the process map generation succeeds.
   */
  processMapGcsUri?:
    | string
    | undefined;
  /**
   * The error message if the process map generation fails.
   * This is only set if the process map generation fails.
   */
  errorMessage?: string | undefined;
}

/**
 * This notification is sent to server from ML pipeline when the process map
 * generation is completed.
 */
export interface ProcessMapGenerationCompletionNotification {
  $type?: "pb.v1alpha2.ProcessMapGenerationCompletionNotification";
  /** The process id for which the process map is generated. */
  processId?:
    | string
    | undefined;
  /**
   * The path ids for which the process map is generated.
   * Optional: If not provided, the process map will be
   * generated for all the paths.
   */
  pathIds?:
    | string[]
    | undefined;
  /**
   * The GCS URI where the process map will be stored.
   * The GCS URI should be in the following format:
   * gs://<bucket_name>/<path_to_process_map>
   * This is only set if the process map generation succeeds.
   */
  processMapGcsUri?:
    | string
    | undefined;
  /**
   * The error message if the process map generation fails.
   * This is only set if the process map generation fails.
   */
  errorMessage?: string | undefined;
}

function createBaseUserDefinedProcess(): UserDefinedProcess {
  return {
    $type: "pb.v1alpha2.UserDefinedProcess",
    id: "",
    name: "",
    description: "",
    baseStepDefinition: [],
    variantStepDefinition: [],
    applicationAttributes: [],
    userIds: [],
    createTime: undefined,
    lastUpdateTime: undefined,
    creator: undefined,
    totalInstances: 0,
    processPaths: [],
    sopSource: undefined,
    processAttributes: [],
    status: 0,
    allowedApplications: [],
  };
}

export const UserDefinedProcess = {
  $type: "pb.v1alpha2.UserDefinedProcess" as const,

  encode(message: UserDefinedProcess, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined && message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== undefined && message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.baseStepDefinition !== undefined && message.baseStepDefinition.length !== 0) {
      for (const v of message.baseStepDefinition) {
        StepType.encode(v!, writer.uint32(34).fork()).ldelim();
      }
    }
    if (message.variantStepDefinition !== undefined && message.variantStepDefinition.length !== 0) {
      for (const v of message.variantStepDefinition) {
        StepType.encode(v!, writer.uint32(42).fork()).ldelim();
      }
    }
    if (message.applicationAttributes !== undefined && message.applicationAttributes.length !== 0) {
      for (const v of message.applicationAttributes) {
        ApplicationAttributes.encode(v!, writer.uint32(50).fork()).ldelim();
      }
    }
    if (message.userIds !== undefined && message.userIds.length !== 0) {
      for (const v of message.userIds) {
        writer.uint32(58).string(v!);
      }
    }
    if (message.createTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createTime), writer.uint32(66).fork()).ldelim();
    }
    if (message.lastUpdateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.lastUpdateTime), writer.uint32(74).fork()).ldelim();
    }
    if (message.creator !== undefined) {
      UserProfileInfo.encode(message.creator, writer.uint32(82).fork()).ldelim();
    }
    if (message.totalInstances !== undefined && message.totalInstances !== 0) {
      writer.uint32(88).int32(message.totalInstances);
    }
    if (message.processPaths !== undefined && message.processPaths.length !== 0) {
      for (const v of message.processPaths) {
        ProcessPath.encode(v!, writer.uint32(98).fork()).ldelim();
      }
    }
    if (message.sopSource !== undefined) {
      SopSource.encode(message.sopSource, writer.uint32(106).fork()).ldelim();
    }
    if (message.processAttributes !== undefined && message.processAttributes.length !== 0) {
      for (const v of message.processAttributes) {
        Attribute.encode(v!, writer.uint32(114).fork()).ldelim();
      }
    }
    if (message.status !== undefined && message.status !== 0) {
      writer.uint32(120).int32(message.status);
    }
    if (message.allowedApplications !== undefined && message.allowedApplications.length !== 0) {
      for (const v of message.allowedApplications) {
        Application.encode(v!, writer.uint32(130).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserDefinedProcess {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserDefinedProcess();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.baseStepDefinition!.push(StepType.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.variantStepDefinition!.push(StepType.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.applicationAttributes!.push(ApplicationAttributes.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.userIds!.push(reader.string());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.lastUpdateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.creator = UserProfileInfo.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.totalInstances = reader.int32();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.processPaths!.push(ProcessPath.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.sopSource = SopSource.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.processAttributes!.push(Attribute.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.allowedApplications!.push(Application.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserDefinedProcess {
    return {
      $type: UserDefinedProcess.$type,
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      baseStepDefinition: globalThis.Array.isArray(object?.baseStepDefinition)
        ? object.baseStepDefinition.map((e: any) => StepType.fromJSON(e))
        : [],
      variantStepDefinition: globalThis.Array.isArray(object?.variantStepDefinition)
        ? object.variantStepDefinition.map((e: any) => StepType.fromJSON(e))
        : [],
      applicationAttributes: globalThis.Array.isArray(object?.applicationAttributes)
        ? object.applicationAttributes.map((e: any) => ApplicationAttributes.fromJSON(e))
        : [],
      userIds: globalThis.Array.isArray(object?.userIds) ? object.userIds.map((e: any) => globalThis.String(e)) : [],
      createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined,
      lastUpdateTime: isSet(object.lastUpdateTime) ? fromJsonTimestamp(object.lastUpdateTime) : undefined,
      creator: isSet(object.creator) ? UserProfileInfo.fromJSON(object.creator) : undefined,
      totalInstances: isSet(object.totalInstances) ? globalThis.Number(object.totalInstances) : 0,
      processPaths: globalThis.Array.isArray(object?.processPaths)
        ? object.processPaths.map((e: any) => ProcessPath.fromJSON(e))
        : [],
      sopSource: isSet(object.sopSource) ? SopSource.fromJSON(object.sopSource) : undefined,
      processAttributes: globalThis.Array.isArray(object?.processAttributes)
        ? object.processAttributes.map((e: any) => Attribute.fromJSON(e))
        : [],
      status: isSet(object.status) ? userDefinedProcessStatusFromJSON(object.status) : 0,
      allowedApplications: globalThis.Array.isArray(object?.allowedApplications)
        ? object.allowedApplications.map((e: any) => Application.fromJSON(e))
        : [],
    };
  },

  toJSON(message: UserDefinedProcess): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== undefined && message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== undefined && message.description !== "") {
      obj.description = message.description;
    }
    if (message.baseStepDefinition?.length) {
      obj.baseStepDefinition = message.baseStepDefinition.map((e) => StepType.toJSON(e));
    }
    if (message.variantStepDefinition?.length) {
      obj.variantStepDefinition = message.variantStepDefinition.map((e) => StepType.toJSON(e));
    }
    if (message.applicationAttributes?.length) {
      obj.applicationAttributes = message.applicationAttributes.map((e) => ApplicationAttributes.toJSON(e));
    }
    if (message.userIds?.length) {
      obj.userIds = message.userIds;
    }
    if (message.createTime !== undefined) {
      obj.createTime = message.createTime.toISOString();
    }
    if (message.lastUpdateTime !== undefined) {
      obj.lastUpdateTime = message.lastUpdateTime.toISOString();
    }
    if (message.creator !== undefined) {
      obj.creator = UserProfileInfo.toJSON(message.creator);
    }
    if (message.totalInstances !== undefined && message.totalInstances !== 0) {
      obj.totalInstances = Math.round(message.totalInstances);
    }
    if (message.processPaths?.length) {
      obj.processPaths = message.processPaths.map((e) => ProcessPath.toJSON(e));
    }
    if (message.sopSource !== undefined) {
      obj.sopSource = SopSource.toJSON(message.sopSource);
    }
    if (message.processAttributes?.length) {
      obj.processAttributes = message.processAttributes.map((e) => Attribute.toJSON(e));
    }
    if (message.status !== undefined && message.status !== 0) {
      obj.status = userDefinedProcessStatusToJSON(message.status);
    }
    if (message.allowedApplications?.length) {
      obj.allowedApplications = message.allowedApplications.map((e) => Application.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserDefinedProcess>, I>>(base?: I): UserDefinedProcess {
    return UserDefinedProcess.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserDefinedProcess>, I>>(object: I): UserDefinedProcess {
    const message = createBaseUserDefinedProcess();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.baseStepDefinition = object.baseStepDefinition?.map((e) => StepType.fromPartial(e)) || [];
    message.variantStepDefinition = object.variantStepDefinition?.map((e) => StepType.fromPartial(e)) || [];
    message.applicationAttributes = object.applicationAttributes?.map((e) => ApplicationAttributes.fromPartial(e)) ||
      [];
    message.userIds = object.userIds?.map((e) => e) || [];
    message.createTime = object.createTime ?? undefined;
    message.lastUpdateTime = object.lastUpdateTime ?? undefined;
    message.creator = (object.creator !== undefined && object.creator !== null)
      ? UserProfileInfo.fromPartial(object.creator)
      : undefined;
    message.totalInstances = object.totalInstances ?? 0;
    message.processPaths = object.processPaths?.map((e) => ProcessPath.fromPartial(e)) || [];
    message.sopSource = (object.sopSource !== undefined && object.sopSource !== null)
      ? SopSource.fromPartial(object.sopSource)
      : undefined;
    message.processAttributes = object.processAttributes?.map((e) => Attribute.fromPartial(e)) || [];
    message.status = object.status ?? 0;
    message.allowedApplications = object.allowedApplications?.map((e) => Application.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(UserDefinedProcess.$type, UserDefinedProcess);

function createBaseApplication(): Application {
  return {
    $type: "pb.v1alpha2.Application",
    id: "",
    name: "",
    description: undefined,
    applicationLogoUrl: undefined,
    applicationUrl: "",
  };
}

export const Application = {
  $type: "pb.v1alpha2.Application" as const,

  encode(message: Application, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined && message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== undefined) {
      writer.uint32(26).string(message.description);
    }
    if (message.applicationLogoUrl !== undefined) {
      writer.uint32(34).string(message.applicationLogoUrl);
    }
    if (message.applicationUrl !== undefined && message.applicationUrl !== "") {
      writer.uint32(42).string(message.applicationUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Application {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApplication();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.applicationLogoUrl = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.applicationUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Application {
    return {
      $type: Application.$type,
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : undefined,
      applicationLogoUrl: isSet(object.applicationLogoUrl) ? globalThis.String(object.applicationLogoUrl) : undefined,
      applicationUrl: isSet(object.applicationUrl) ? globalThis.String(object.applicationUrl) : "",
    };
  },

  toJSON(message: Application): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== undefined && message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== undefined) {
      obj.description = message.description;
    }
    if (message.applicationLogoUrl !== undefined) {
      obj.applicationLogoUrl = message.applicationLogoUrl;
    }
    if (message.applicationUrl !== undefined && message.applicationUrl !== "") {
      obj.applicationUrl = message.applicationUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Application>, I>>(base?: I): Application {
    return Application.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Application>, I>>(object: I): Application {
    const message = createBaseApplication();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? undefined;
    message.applicationLogoUrl = object.applicationLogoUrl ?? undefined;
    message.applicationUrl = object.applicationUrl ?? "";
    return message;
  },
};

messageTypeRegistry.set(Application.$type, Application);

function createBaseApplicationAttributes(): ApplicationAttributes {
  return { $type: "pb.v1alpha2.ApplicationAttributes", application: "", attributes: [] };
}

export const ApplicationAttributes = {
  $type: "pb.v1alpha2.ApplicationAttributes" as const,

  encode(message: ApplicationAttributes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.application !== undefined && message.application !== "") {
      writer.uint32(10).string(message.application);
    }
    if (message.attributes !== undefined && message.attributes.length !== 0) {
      for (const v of message.attributes) {
        Attribute.encode(v!, writer.uint32(18).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ApplicationAttributes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApplicationAttributes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.application = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.attributes!.push(Attribute.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ApplicationAttributes {
    return {
      $type: ApplicationAttributes.$type,
      application: isSet(object.application) ? globalThis.String(object.application) : "",
      attributes: globalThis.Array.isArray(object?.attributes)
        ? object.attributes.map((e: any) => Attribute.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ApplicationAttributes): unknown {
    const obj: any = {};
    if (message.application !== undefined && message.application !== "") {
      obj.application = message.application;
    }
    if (message.attributes?.length) {
      obj.attributes = message.attributes.map((e) => Attribute.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ApplicationAttributes>, I>>(base?: I): ApplicationAttributes {
    return ApplicationAttributes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ApplicationAttributes>, I>>(object: I): ApplicationAttributes {
    const message = createBaseApplicationAttributes();
    message.application = object.application ?? "";
    message.attributes = object.attributes?.map((e) => Attribute.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(ApplicationAttributes.$type, ApplicationAttributes);

function createBaseCreateProcessRequest(): CreateProcessRequest {
  return { $type: "pb.v1alpha2.CreateProcessRequest", userDefinedProcess: undefined };
}

export const CreateProcessRequest = {
  $type: "pb.v1alpha2.CreateProcessRequest" as const,

  encode(message: CreateProcessRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userDefinedProcess !== undefined) {
      UserDefinedProcess.encode(message.userDefinedProcess, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateProcessRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateProcessRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userDefinedProcess = UserDefinedProcess.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateProcessRequest {
    return {
      $type: CreateProcessRequest.$type,
      userDefinedProcess: isSet(object.userDefinedProcess)
        ? UserDefinedProcess.fromJSON(object.userDefinedProcess)
        : undefined,
    };
  },

  toJSON(message: CreateProcessRequest): unknown {
    const obj: any = {};
    if (message.userDefinedProcess !== undefined) {
      obj.userDefinedProcess = UserDefinedProcess.toJSON(message.userDefinedProcess);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateProcessRequest>, I>>(base?: I): CreateProcessRequest {
    return CreateProcessRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateProcessRequest>, I>>(object: I): CreateProcessRequest {
    const message = createBaseCreateProcessRequest();
    message.userDefinedProcess = (object.userDefinedProcess !== undefined && object.userDefinedProcess !== null)
      ? UserDefinedProcess.fromPartial(object.userDefinedProcess)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(CreateProcessRequest.$type, CreateProcessRequest);

function createBaseCreateProcessResponse(): CreateProcessResponse {
  return { $type: "pb.v1alpha2.CreateProcessResponse", userDefinedProcess: undefined };
}

export const CreateProcessResponse = {
  $type: "pb.v1alpha2.CreateProcessResponse" as const,

  encode(message: CreateProcessResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userDefinedProcess !== undefined) {
      UserDefinedProcess.encode(message.userDefinedProcess, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateProcessResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateProcessResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userDefinedProcess = UserDefinedProcess.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateProcessResponse {
    return {
      $type: CreateProcessResponse.$type,
      userDefinedProcess: isSet(object.userDefinedProcess)
        ? UserDefinedProcess.fromJSON(object.userDefinedProcess)
        : undefined,
    };
  },

  toJSON(message: CreateProcessResponse): unknown {
    const obj: any = {};
    if (message.userDefinedProcess !== undefined) {
      obj.userDefinedProcess = UserDefinedProcess.toJSON(message.userDefinedProcess);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateProcessResponse>, I>>(base?: I): CreateProcessResponse {
    return CreateProcessResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateProcessResponse>, I>>(object: I): CreateProcessResponse {
    const message = createBaseCreateProcessResponse();
    message.userDefinedProcess = (object.userDefinedProcess !== undefined && object.userDefinedProcess !== null)
      ? UserDefinedProcess.fromPartial(object.userDefinedProcess)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(CreateProcessResponse.$type, CreateProcessResponse);

function createBaseGetProcessDefinitionRequest(): GetProcessDefinitionRequest {
  return { $type: "pb.v1alpha2.GetProcessDefinitionRequest", processId: "" };
}

export const GetProcessDefinitionRequest = {
  $type: "pb.v1alpha2.GetProcessDefinitionRequest" as const,

  encode(message: GetProcessDefinitionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.processId !== undefined && message.processId !== "") {
      writer.uint32(10).string(message.processId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetProcessDefinitionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetProcessDefinitionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.processId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetProcessDefinitionRequest {
    return {
      $type: GetProcessDefinitionRequest.$type,
      processId: isSet(object.processId) ? globalThis.String(object.processId) : "",
    };
  },

  toJSON(message: GetProcessDefinitionRequest): unknown {
    const obj: any = {};
    if (message.processId !== undefined && message.processId !== "") {
      obj.processId = message.processId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetProcessDefinitionRequest>, I>>(base?: I): GetProcessDefinitionRequest {
    return GetProcessDefinitionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetProcessDefinitionRequest>, I>>(object: I): GetProcessDefinitionRequest {
    const message = createBaseGetProcessDefinitionRequest();
    message.processId = object.processId ?? "";
    return message;
  },
};

messageTypeRegistry.set(GetProcessDefinitionRequest.$type, GetProcessDefinitionRequest);

function createBaseGetProcessDefinitionResponse(): GetProcessDefinitionResponse {
  return { $type: "pb.v1alpha2.GetProcessDefinitionResponse", process: undefined };
}

export const GetProcessDefinitionResponse = {
  $type: "pb.v1alpha2.GetProcessDefinitionResponse" as const,

  encode(message: GetProcessDefinitionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.process !== undefined) {
      UserDefinedProcess.encode(message.process, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetProcessDefinitionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetProcessDefinitionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.process = UserDefinedProcess.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetProcessDefinitionResponse {
    return {
      $type: GetProcessDefinitionResponse.$type,
      process: isSet(object.process) ? UserDefinedProcess.fromJSON(object.process) : undefined,
    };
  },

  toJSON(message: GetProcessDefinitionResponse): unknown {
    const obj: any = {};
    if (message.process !== undefined) {
      obj.process = UserDefinedProcess.toJSON(message.process);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetProcessDefinitionResponse>, I>>(base?: I): GetProcessDefinitionResponse {
    return GetProcessDefinitionResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetProcessDefinitionResponse>, I>>(object: I): GetProcessDefinitionResponse {
    const message = createBaseGetProcessDefinitionResponse();
    message.process = (object.process !== undefined && object.process !== null)
      ? UserDefinedProcess.fromPartial(object.process)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(GetProcessDefinitionResponse.$type, GetProcessDefinitionResponse);

function createBaseGetProcessRequest(): GetProcessRequest {
  return { $type: "pb.v1alpha2.GetProcessRequest", processId: "" };
}

export const GetProcessRequest = {
  $type: "pb.v1alpha2.GetProcessRequest" as const,

  encode(message: GetProcessRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.processId !== undefined && message.processId !== "") {
      writer.uint32(10).string(message.processId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetProcessRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetProcessRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.processId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetProcessRequest {
    return {
      $type: GetProcessRequest.$type,
      processId: isSet(object.processId) ? globalThis.String(object.processId) : "",
    };
  },

  toJSON(message: GetProcessRequest): unknown {
    const obj: any = {};
    if (message.processId !== undefined && message.processId !== "") {
      obj.processId = message.processId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetProcessRequest>, I>>(base?: I): GetProcessRequest {
    return GetProcessRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetProcessRequest>, I>>(object: I): GetProcessRequest {
    const message = createBaseGetProcessRequest();
    message.processId = object.processId ?? "";
    return message;
  },
};

messageTypeRegistry.set(GetProcessRequest.$type, GetProcessRequest);

function createBaseGetProcessResponse(): GetProcessResponse {
  return { $type: "pb.v1alpha2.GetProcessResponse", process: undefined };
}

export const GetProcessResponse = {
  $type: "pb.v1alpha2.GetProcessResponse" as const,

  encode(message: GetProcessResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.process !== undefined) {
      Process.encode(message.process, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetProcessResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetProcessResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.process = Process.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetProcessResponse {
    return {
      $type: GetProcessResponse.$type,
      process: isSet(object.process) ? Process.fromJSON(object.process) : undefined,
    };
  },

  toJSON(message: GetProcessResponse): unknown {
    const obj: any = {};
    if (message.process !== undefined) {
      obj.process = Process.toJSON(message.process);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetProcessResponse>, I>>(base?: I): GetProcessResponse {
    return GetProcessResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetProcessResponse>, I>>(object: I): GetProcessResponse {
    const message = createBaseGetProcessResponse();
    message.process = (object.process !== undefined && object.process !== null)
      ? Process.fromPartial(object.process)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(GetProcessResponse.$type, GetProcessResponse);

function createBaseListProcessesRequest(): ListProcessesRequest {
  return { $type: "pb.v1alpha2.ListProcessesRequest", pageSize: 0, pageNumber: 0, filter: "", sortFields: [] };
}

export const ListProcessesRequest = {
  $type: "pb.v1alpha2.ListProcessesRequest" as const,

  encode(message: ListProcessesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pageSize !== undefined && message.pageSize !== 0) {
      writer.uint32(32).int32(message.pageSize);
    }
    if (message.pageNumber !== undefined && message.pageNumber !== 0) {
      writer.uint32(40).int32(message.pageNumber);
    }
    if (message.filter !== undefined && message.filter !== "") {
      writer.uint32(50).string(message.filter);
    }
    if (message.sortFields !== undefined && message.sortFields.length !== 0) {
      for (const v of message.sortFields) {
        SortField.encode(v!, writer.uint32(58).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListProcessesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListProcessesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 4:
          if (tag !== 32) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.pageNumber = reader.int32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.filter = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.sortFields!.push(SortField.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListProcessesRequest {
    return {
      $type: ListProcessesRequest.$type,
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageNumber: isSet(object.pageNumber) ? globalThis.Number(object.pageNumber) : 0,
      filter: isSet(object.filter) ? globalThis.String(object.filter) : "",
      sortFields: globalThis.Array.isArray(object?.sortFields)
        ? object.sortFields.map((e: any) => SortField.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListProcessesRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== undefined && message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageNumber !== undefined && message.pageNumber !== 0) {
      obj.pageNumber = Math.round(message.pageNumber);
    }
    if (message.filter !== undefined && message.filter !== "") {
      obj.filter = message.filter;
    }
    if (message.sortFields?.length) {
      obj.sortFields = message.sortFields.map((e) => SortField.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListProcessesRequest>, I>>(base?: I): ListProcessesRequest {
    return ListProcessesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListProcessesRequest>, I>>(object: I): ListProcessesRequest {
    const message = createBaseListProcessesRequest();
    message.pageSize = object.pageSize ?? 0;
    message.pageNumber = object.pageNumber ?? 0;
    message.filter = object.filter ?? "";
    message.sortFields = object.sortFields?.map((e) => SortField.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(ListProcessesRequest.$type, ListProcessesRequest);

function createBaseListProcessesResponse(): ListProcessesResponse {
  return { $type: "pb.v1alpha2.ListProcessesResponse", processes: [], totalCount: 0, userDefinedProcesses: [] };
}

export const ListProcessesResponse = {
  $type: "pb.v1alpha2.ListProcessesResponse" as const,

  encode(message: ListProcessesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.processes !== undefined && message.processes.length !== 0) {
      for (const v of message.processes) {
        Process.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    if (message.totalCount !== undefined && message.totalCount !== 0) {
      writer.uint32(16).int32(message.totalCount);
    }
    if (message.userDefinedProcesses !== undefined && message.userDefinedProcesses.length !== 0) {
      for (const v of message.userDefinedProcesses) {
        UserDefinedProcess.encode(v!, writer.uint32(26).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListProcessesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListProcessesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.processes!.push(Process.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.totalCount = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.userDefinedProcesses!.push(UserDefinedProcess.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListProcessesResponse {
    return {
      $type: ListProcessesResponse.$type,
      processes: globalThis.Array.isArray(object?.processes)
        ? object.processes.map((e: any) => Process.fromJSON(e))
        : [],
      totalCount: isSet(object.totalCount) ? globalThis.Number(object.totalCount) : 0,
      userDefinedProcesses: globalThis.Array.isArray(object?.userDefinedProcesses)
        ? object.userDefinedProcesses.map((e: any) => UserDefinedProcess.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListProcessesResponse): unknown {
    const obj: any = {};
    if (message.processes?.length) {
      obj.processes = message.processes.map((e) => Process.toJSON(e));
    }
    if (message.totalCount !== undefined && message.totalCount !== 0) {
      obj.totalCount = Math.round(message.totalCount);
    }
    if (message.userDefinedProcesses?.length) {
      obj.userDefinedProcesses = message.userDefinedProcesses.map((e) => UserDefinedProcess.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListProcessesResponse>, I>>(base?: I): ListProcessesResponse {
    return ListProcessesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListProcessesResponse>, I>>(object: I): ListProcessesResponse {
    const message = createBaseListProcessesResponse();
    message.processes = object.processes?.map((e) => Process.fromPartial(e)) || [];
    message.totalCount = object.totalCount ?? 0;
    message.userDefinedProcesses = object.userDefinedProcesses?.map((e) => UserDefinedProcess.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(ListProcessesResponse.$type, ListProcessesResponse);

function createBaseUpdateProcessRequest(): UpdateProcessRequest {
  return { $type: "pb.v1alpha2.UpdateProcessRequest", userDefinedProcess: undefined, updateMask: undefined };
}

export const UpdateProcessRequest = {
  $type: "pb.v1alpha2.UpdateProcessRequest" as const,

  encode(message: UpdateProcessRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userDefinedProcess !== undefined) {
      UserDefinedProcess.encode(message.userDefinedProcess, writer.uint32(10).fork()).ldelim();
    }
    if (message.updateMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.updateMask), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateProcessRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateProcessRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userDefinedProcess = UserDefinedProcess.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.updateMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateProcessRequest {
    return {
      $type: UpdateProcessRequest.$type,
      userDefinedProcess: isSet(object.userDefinedProcess)
        ? UserDefinedProcess.fromJSON(object.userDefinedProcess)
        : undefined,
      updateMask: isSet(object.updateMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.updateMask)) : undefined,
    };
  },

  toJSON(message: UpdateProcessRequest): unknown {
    const obj: any = {};
    if (message.userDefinedProcess !== undefined) {
      obj.userDefinedProcess = UserDefinedProcess.toJSON(message.userDefinedProcess);
    }
    if (message.updateMask !== undefined) {
      obj.updateMask = FieldMask.toJSON(FieldMask.wrap(message.updateMask));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateProcessRequest>, I>>(base?: I): UpdateProcessRequest {
    return UpdateProcessRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateProcessRequest>, I>>(object: I): UpdateProcessRequest {
    const message = createBaseUpdateProcessRequest();
    message.userDefinedProcess = (object.userDefinedProcess !== undefined && object.userDefinedProcess !== null)
      ? UserDefinedProcess.fromPartial(object.userDefinedProcess)
      : undefined;
    message.updateMask = object.updateMask ?? undefined;
    return message;
  },
};

messageTypeRegistry.set(UpdateProcessRequest.$type, UpdateProcessRequest);

function createBaseUpdateProcessResponse(): UpdateProcessResponse {
  return { $type: "pb.v1alpha2.UpdateProcessResponse", userDefinedProcess: undefined };
}

export const UpdateProcessResponse = {
  $type: "pb.v1alpha2.UpdateProcessResponse" as const,

  encode(message: UpdateProcessResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userDefinedProcess !== undefined) {
      UserDefinedProcess.encode(message.userDefinedProcess, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateProcessResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateProcessResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userDefinedProcess = UserDefinedProcess.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateProcessResponse {
    return {
      $type: UpdateProcessResponse.$type,
      userDefinedProcess: isSet(object.userDefinedProcess)
        ? UserDefinedProcess.fromJSON(object.userDefinedProcess)
        : undefined,
    };
  },

  toJSON(message: UpdateProcessResponse): unknown {
    const obj: any = {};
    if (message.userDefinedProcess !== undefined) {
      obj.userDefinedProcess = UserDefinedProcess.toJSON(message.userDefinedProcess);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateProcessResponse>, I>>(base?: I): UpdateProcessResponse {
    return UpdateProcessResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateProcessResponse>, I>>(object: I): UpdateProcessResponse {
    const message = createBaseUpdateProcessResponse();
    message.userDefinedProcess = (object.userDefinedProcess !== undefined && object.userDefinedProcess !== null)
      ? UserDefinedProcess.fromPartial(object.userDefinedProcess)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(UpdateProcessResponse.$type, UpdateProcessResponse);

function createBaseDeleteProcessRequest(): DeleteProcessRequest {
  return { $type: "pb.v1alpha2.DeleteProcessRequest", processId: "" };
}

export const DeleteProcessRequest = {
  $type: "pb.v1alpha2.DeleteProcessRequest" as const,

  encode(message: DeleteProcessRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.processId !== undefined && message.processId !== "") {
      writer.uint32(10).string(message.processId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteProcessRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteProcessRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.processId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteProcessRequest {
    return {
      $type: DeleteProcessRequest.$type,
      processId: isSet(object.processId) ? globalThis.String(object.processId) : "",
    };
  },

  toJSON(message: DeleteProcessRequest): unknown {
    const obj: any = {};
    if (message.processId !== undefined && message.processId !== "") {
      obj.processId = message.processId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteProcessRequest>, I>>(base?: I): DeleteProcessRequest {
    return DeleteProcessRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteProcessRequest>, I>>(object: I): DeleteProcessRequest {
    const message = createBaseDeleteProcessRequest();
    message.processId = object.processId ?? "";
    return message;
  },
};

messageTypeRegistry.set(DeleteProcessRequest.$type, DeleteProcessRequest);

function createBaseGetTraceUsersFilter(): GetTraceUsersFilter {
  return { $type: "pb.v1alpha2.GetTraceUsersFilter", nameEmailPrefix: "" };
}

export const GetTraceUsersFilter = {
  $type: "pb.v1alpha2.GetTraceUsersFilter" as const,

  encode(message: GetTraceUsersFilter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nameEmailPrefix !== undefined && message.nameEmailPrefix !== "") {
      writer.uint32(10).string(message.nameEmailPrefix);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTraceUsersFilter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTraceUsersFilter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nameEmailPrefix = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTraceUsersFilter {
    return {
      $type: GetTraceUsersFilter.$type,
      nameEmailPrefix: isSet(object.nameEmailPrefix) ? globalThis.String(object.nameEmailPrefix) : "",
    };
  },

  toJSON(message: GetTraceUsersFilter): unknown {
    const obj: any = {};
    if (message.nameEmailPrefix !== undefined && message.nameEmailPrefix !== "") {
      obj.nameEmailPrefix = message.nameEmailPrefix;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTraceUsersFilter>, I>>(base?: I): GetTraceUsersFilter {
    return GetTraceUsersFilter.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTraceUsersFilter>, I>>(object: I): GetTraceUsersFilter {
    const message = createBaseGetTraceUsersFilter();
    message.nameEmailPrefix = object.nameEmailPrefix ?? "";
    return message;
  },
};

messageTypeRegistry.set(GetTraceUsersFilter.$type, GetTraceUsersFilter);

function createBaseGetTraceUsersRequest(): GetTraceUsersRequest {
  return { $type: "pb.v1alpha2.GetTraceUsersRequest", processId: "", filter: undefined };
}

export const GetTraceUsersRequest = {
  $type: "pb.v1alpha2.GetTraceUsersRequest" as const,

  encode(message: GetTraceUsersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.processId !== undefined && message.processId !== "") {
      writer.uint32(10).string(message.processId);
    }
    if (message.filter !== undefined) {
      GetTraceUsersFilter.encode(message.filter, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTraceUsersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTraceUsersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.processId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.filter = GetTraceUsersFilter.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTraceUsersRequest {
    return {
      $type: GetTraceUsersRequest.$type,
      processId: isSet(object.processId) ? globalThis.String(object.processId) : "",
      filter: isSet(object.filter) ? GetTraceUsersFilter.fromJSON(object.filter) : undefined,
    };
  },

  toJSON(message: GetTraceUsersRequest): unknown {
    const obj: any = {};
    if (message.processId !== undefined && message.processId !== "") {
      obj.processId = message.processId;
    }
    if (message.filter !== undefined) {
      obj.filter = GetTraceUsersFilter.toJSON(message.filter);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTraceUsersRequest>, I>>(base?: I): GetTraceUsersRequest {
    return GetTraceUsersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTraceUsersRequest>, I>>(object: I): GetTraceUsersRequest {
    const message = createBaseGetTraceUsersRequest();
    message.processId = object.processId ?? "";
    message.filter = (object.filter !== undefined && object.filter !== null)
      ? GetTraceUsersFilter.fromPartial(object.filter)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(GetTraceUsersRequest.$type, GetTraceUsersRequest);

function createBaseGetTraceUsersResponse(): GetTraceUsersResponse {
  return { $type: "pb.v1alpha2.GetTraceUsersResponse", users: [] };
}

export const GetTraceUsersResponse = {
  $type: "pb.v1alpha2.GetTraceUsersResponse" as const,

  encode(message: GetTraceUsersResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.users !== undefined && message.users.length !== 0) {
      for (const v of message.users) {
        User.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTraceUsersResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTraceUsersResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.users!.push(User.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTraceUsersResponse {
    return {
      $type: GetTraceUsersResponse.$type,
      users: globalThis.Array.isArray(object?.users) ? object.users.map((e: any) => User.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetTraceUsersResponse): unknown {
    const obj: any = {};
    if (message.users?.length) {
      obj.users = message.users.map((e) => User.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTraceUsersResponse>, I>>(base?: I): GetTraceUsersResponse {
    return GetTraceUsersResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTraceUsersResponse>, I>>(object: I): GetTraceUsersResponse {
    const message = createBaseGetTraceUsersResponse();
    message.users = object.users?.map((e) => User.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(GetTraceUsersResponse.$type, GetTraceUsersResponse);

function createBaseListTracesRequest(): ListTracesRequest {
  return {
    $type: "pb.v1alpha2.ListTracesRequest",
    processId: "",
    pageSize: 0,
    pageNumber: 0,
    sortFields: [],
    filter: undefined,
  };
}

export const ListTracesRequest = {
  $type: "pb.v1alpha2.ListTracesRequest" as const,

  encode(message: ListTracesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.processId !== undefined && message.processId !== "") {
      writer.uint32(10).string(message.processId);
    }
    if (message.pageSize !== undefined && message.pageSize !== 0) {
      writer.uint32(16).int32(message.pageSize);
    }
    if (message.pageNumber !== undefined && message.pageNumber !== 0) {
      writer.uint32(24).int32(message.pageNumber);
    }
    if (message.sortFields !== undefined && message.sortFields.length !== 0) {
      for (const v of message.sortFields) {
        SortField.encode(v!, writer.uint32(34).fork()).ldelim();
      }
    }
    if (message.filter !== undefined) {
      writer.uint32(42).string(message.filter);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListTracesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTracesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.processId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.pageNumber = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.sortFields!.push(SortField.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.filter = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListTracesRequest {
    return {
      $type: ListTracesRequest.$type,
      processId: isSet(object.processId) ? globalThis.String(object.processId) : "",
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageNumber: isSet(object.pageNumber) ? globalThis.Number(object.pageNumber) : 0,
      sortFields: globalThis.Array.isArray(object?.sortFields)
        ? object.sortFields.map((e: any) => SortField.fromJSON(e))
        : [],
      filter: isSet(object.filter) ? globalThis.String(object.filter) : undefined,
    };
  },

  toJSON(message: ListTracesRequest): unknown {
    const obj: any = {};
    if (message.processId !== undefined && message.processId !== "") {
      obj.processId = message.processId;
    }
    if (message.pageSize !== undefined && message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageNumber !== undefined && message.pageNumber !== 0) {
      obj.pageNumber = Math.round(message.pageNumber);
    }
    if (message.sortFields?.length) {
      obj.sortFields = message.sortFields.map((e) => SortField.toJSON(e));
    }
    if (message.filter !== undefined) {
      obj.filter = message.filter;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListTracesRequest>, I>>(base?: I): ListTracesRequest {
    return ListTracesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListTracesRequest>, I>>(object: I): ListTracesRequest {
    const message = createBaseListTracesRequest();
    message.processId = object.processId ?? "";
    message.pageSize = object.pageSize ?? 0;
    message.pageNumber = object.pageNumber ?? 0;
    message.sortFields = object.sortFields?.map((e) => SortField.fromPartial(e)) || [];
    message.filter = object.filter ?? undefined;
    return message;
  },
};

messageTypeRegistry.set(ListTracesRequest.$type, ListTracesRequest);

function createBaseListTracesResponse(): ListTracesResponse {
  return { $type: "pb.v1alpha2.ListTracesResponse", traces: [], totalCount: 0, totalUsers: 0 };
}

export const ListTracesResponse = {
  $type: "pb.v1alpha2.ListTracesResponse" as const,

  encode(message: ListTracesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.traces !== undefined && message.traces.length !== 0) {
      for (const v of message.traces) {
        ObservationSession.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    if (message.totalCount !== undefined && message.totalCount !== 0) {
      writer.uint32(16).int32(message.totalCount);
    }
    if (message.totalUsers !== undefined && message.totalUsers !== 0) {
      writer.uint32(24).int32(message.totalUsers);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListTracesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTracesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.traces!.push(ObservationSession.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.totalCount = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.totalUsers = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListTracesResponse {
    return {
      $type: ListTracesResponse.$type,
      traces: globalThis.Array.isArray(object?.traces)
        ? object.traces.map((e: any) => ObservationSession.fromJSON(e))
        : [],
      totalCount: isSet(object.totalCount) ? globalThis.Number(object.totalCount) : 0,
      totalUsers: isSet(object.totalUsers) ? globalThis.Number(object.totalUsers) : 0,
    };
  },

  toJSON(message: ListTracesResponse): unknown {
    const obj: any = {};
    if (message.traces?.length) {
      obj.traces = message.traces.map((e) => ObservationSession.toJSON(e));
    }
    if (message.totalCount !== undefined && message.totalCount !== 0) {
      obj.totalCount = Math.round(message.totalCount);
    }
    if (message.totalUsers !== undefined && message.totalUsers !== 0) {
      obj.totalUsers = Math.round(message.totalUsers);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListTracesResponse>, I>>(base?: I): ListTracesResponse {
    return ListTracesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListTracesResponse>, I>>(object: I): ListTracesResponse {
    const message = createBaseListTracesResponse();
    message.traces = object.traces?.map((e) => ObservationSession.fromPartial(e)) || [];
    message.totalCount = object.totalCount ?? 0;
    message.totalUsers = object.totalUsers ?? 0;
    return message;
  },
};

messageTypeRegistry.set(ListTracesResponse.$type, ListTracesResponse);

function createBaseGetTraceRequest(): GetTraceRequest {
  return { $type: "pb.v1alpha2.GetTraceRequest", traceId: "", fieldMask: undefined };
}

export const GetTraceRequest = {
  $type: "pb.v1alpha2.GetTraceRequest" as const,

  encode(message: GetTraceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.traceId !== undefined && message.traceId !== "") {
      writer.uint32(10).string(message.traceId);
    }
    if (message.fieldMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.fieldMask), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTraceRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTraceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.traceId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fieldMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTraceRequest {
    return {
      $type: GetTraceRequest.$type,
      traceId: isSet(object.traceId) ? globalThis.String(object.traceId) : "",
      fieldMask: isSet(object.fieldMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.fieldMask)) : undefined,
    };
  },

  toJSON(message: GetTraceRequest): unknown {
    const obj: any = {};
    if (message.traceId !== undefined && message.traceId !== "") {
      obj.traceId = message.traceId;
    }
    if (message.fieldMask !== undefined) {
      obj.fieldMask = FieldMask.toJSON(FieldMask.wrap(message.fieldMask));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTraceRequest>, I>>(base?: I): GetTraceRequest {
    return GetTraceRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTraceRequest>, I>>(object: I): GetTraceRequest {
    const message = createBaseGetTraceRequest();
    message.traceId = object.traceId ?? "";
    message.fieldMask = object.fieldMask ?? undefined;
    return message;
  },
};

messageTypeRegistry.set(GetTraceRequest.$type, GetTraceRequest);

function createBaseGetTraceResponse(): GetTraceResponse {
  return { $type: "pb.v1alpha2.GetTraceResponse", actions: [] };
}

export const GetTraceResponse = {
  $type: "pb.v1alpha2.GetTraceResponse" as const,

  encode(message: GetTraceResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.actions !== undefined && message.actions.length !== 0) {
      for (const v of message.actions) {
        Action.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTraceResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTraceResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.actions!.push(Action.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTraceResponse {
    return {
      $type: GetTraceResponse.$type,
      actions: globalThis.Array.isArray(object?.actions) ? object.actions.map((e: any) => Action.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetTraceResponse): unknown {
    const obj: any = {};
    if (message.actions?.length) {
      obj.actions = message.actions.map((e) => Action.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTraceResponse>, I>>(base?: I): GetTraceResponse {
    return GetTraceResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTraceResponse>, I>>(object: I): GetTraceResponse {
    const message = createBaseGetTraceResponse();
    message.actions = object.actions?.map((e) => Action.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(GetTraceResponse.$type, GetTraceResponse);

function createBaseDeleteTraceRequest(): DeleteTraceRequest {
  return { $type: "pb.v1alpha2.DeleteTraceRequest", traceId: "" };
}

export const DeleteTraceRequest = {
  $type: "pb.v1alpha2.DeleteTraceRequest" as const,

  encode(message: DeleteTraceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.traceId !== undefined && message.traceId !== "") {
      writer.uint32(10).string(message.traceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteTraceRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteTraceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.traceId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteTraceRequest {
    return { $type: DeleteTraceRequest.$type, traceId: isSet(object.traceId) ? globalThis.String(object.traceId) : "" };
  },

  toJSON(message: DeleteTraceRequest): unknown {
    const obj: any = {};
    if (message.traceId !== undefined && message.traceId !== "") {
      obj.traceId = message.traceId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteTraceRequest>, I>>(base?: I): DeleteTraceRequest {
    return DeleteTraceRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteTraceRequest>, I>>(object: I): DeleteTraceRequest {
    const message = createBaseDeleteTraceRequest();
    message.traceId = object.traceId ?? "";
    return message;
  },
};

messageTypeRegistry.set(DeleteTraceRequest.$type, DeleteTraceRequest);

function createBaseUpdateTraceRequest(): UpdateTraceRequest {
  return { $type: "pb.v1alpha2.UpdateTraceRequest", traceId: "", trace: undefined, updateMask: undefined };
}

export const UpdateTraceRequest = {
  $type: "pb.v1alpha2.UpdateTraceRequest" as const,

  encode(message: UpdateTraceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.traceId !== undefined && message.traceId !== "") {
      writer.uint32(10).string(message.traceId);
    }
    if (message.trace !== undefined) {
      ObservationSession.encode(message.trace, writer.uint32(18).fork()).ldelim();
    }
    if (message.updateMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.updateMask), writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTraceRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTraceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.traceId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.trace = ObservationSession.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.updateMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateTraceRequest {
    return {
      $type: UpdateTraceRequest.$type,
      traceId: isSet(object.traceId) ? globalThis.String(object.traceId) : "",
      trace: isSet(object.trace) ? ObservationSession.fromJSON(object.trace) : undefined,
      updateMask: isSet(object.updateMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.updateMask)) : undefined,
    };
  },

  toJSON(message: UpdateTraceRequest): unknown {
    const obj: any = {};
    if (message.traceId !== undefined && message.traceId !== "") {
      obj.traceId = message.traceId;
    }
    if (message.trace !== undefined) {
      obj.trace = ObservationSession.toJSON(message.trace);
    }
    if (message.updateMask !== undefined) {
      obj.updateMask = FieldMask.toJSON(FieldMask.wrap(message.updateMask));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateTraceRequest>, I>>(base?: I): UpdateTraceRequest {
    return UpdateTraceRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateTraceRequest>, I>>(object: I): UpdateTraceRequest {
    const message = createBaseUpdateTraceRequest();
    message.traceId = object.traceId ?? "";
    message.trace = (object.trace !== undefined && object.trace !== null)
      ? ObservationSession.fromPartial(object.trace)
      : undefined;
    message.updateMask = object.updateMask ?? undefined;
    return message;
  },
};

messageTypeRegistry.set(UpdateTraceRequest.$type, UpdateTraceRequest);

function createBaseUpdateTraceResponse(): UpdateTraceResponse {
  return { $type: "pb.v1alpha2.UpdateTraceResponse", updatedTrace: undefined };
}

export const UpdateTraceResponse = {
  $type: "pb.v1alpha2.UpdateTraceResponse" as const,

  encode(message: UpdateTraceResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.updatedTrace !== undefined) {
      ObservationSession.encode(message.updatedTrace, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTraceResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTraceResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.updatedTrace = ObservationSession.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateTraceResponse {
    return {
      $type: UpdateTraceResponse.$type,
      updatedTrace: isSet(object.updatedTrace) ? ObservationSession.fromJSON(object.updatedTrace) : undefined,
    };
  },

  toJSON(message: UpdateTraceResponse): unknown {
    const obj: any = {};
    if (message.updatedTrace !== undefined) {
      obj.updatedTrace = ObservationSession.toJSON(message.updatedTrace);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateTraceResponse>, I>>(base?: I): UpdateTraceResponse {
    return UpdateTraceResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateTraceResponse>, I>>(object: I): UpdateTraceResponse {
    const message = createBaseUpdateTraceResponse();
    message.updatedTrace = (object.updatedTrace !== undefined && object.updatedTrace !== null)
      ? ObservationSession.fromPartial(object.updatedTrace)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(UpdateTraceResponse.$type, UpdateTraceResponse);

function createBaseGetProcessMapRequest(): GetProcessMapRequest {
  return { $type: "pb.v1alpha2.GetProcessMapRequest", processId: "", pathIds: [], refresh: false };
}

export const GetProcessMapRequest = {
  $type: "pb.v1alpha2.GetProcessMapRequest" as const,

  encode(message: GetProcessMapRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.processId !== undefined && message.processId !== "") {
      writer.uint32(10).string(message.processId);
    }
    if (message.pathIds !== undefined && message.pathIds.length !== 0) {
      for (const v of message.pathIds) {
        writer.uint32(18).string(v!);
      }
    }
    if (message.refresh !== undefined && message.refresh !== false) {
      writer.uint32(24).bool(message.refresh);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetProcessMapRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetProcessMapRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.processId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pathIds!.push(reader.string());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.refresh = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetProcessMapRequest {
    return {
      $type: GetProcessMapRequest.$type,
      processId: isSet(object.processId) ? globalThis.String(object.processId) : "",
      pathIds: globalThis.Array.isArray(object?.pathIds) ? object.pathIds.map((e: any) => globalThis.String(e)) : [],
      refresh: isSet(object.refresh) ? globalThis.Boolean(object.refresh) : false,
    };
  },

  toJSON(message: GetProcessMapRequest): unknown {
    const obj: any = {};
    if (message.processId !== undefined && message.processId !== "") {
      obj.processId = message.processId;
    }
    if (message.pathIds?.length) {
      obj.pathIds = message.pathIds;
    }
    if (message.refresh !== undefined && message.refresh !== false) {
      obj.refresh = message.refresh;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetProcessMapRequest>, I>>(base?: I): GetProcessMapRequest {
    return GetProcessMapRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetProcessMapRequest>, I>>(object: I): GetProcessMapRequest {
    const message = createBaseGetProcessMapRequest();
    message.processId = object.processId ?? "";
    message.pathIds = object.pathIds?.map((e) => e) || [];
    message.refresh = object.refresh ?? false;
    return message;
  },
};

messageTypeRegistry.set(GetProcessMapRequest.$type, GetProcessMapRequest);

function createBaseGetProcessMapResponse(): GetProcessMapResponse {
  return { $type: "pb.v1alpha2.GetProcessMapResponse", processMap: undefined, status: 0, errorMessage: undefined };
}

export const GetProcessMapResponse = {
  $type: "pb.v1alpha2.GetProcessMapResponse" as const,

  encode(message: GetProcessMapResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.processMap !== undefined) {
      Process.encode(message.processMap, writer.uint32(10).fork()).ldelim();
    }
    if (message.status !== undefined && message.status !== 0) {
      writer.uint32(16).int32(message.status);
    }
    if (message.errorMessage !== undefined) {
      writer.uint32(26).string(message.errorMessage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetProcessMapResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetProcessMapResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.processMap = Process.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.errorMessage = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetProcessMapResponse {
    return {
      $type: GetProcessMapResponse.$type,
      processMap: isSet(object.processMap) ? Process.fromJSON(object.processMap) : undefined,
      status: isSet(object.status) ? getProcessMapResponseProcessMapGenerationStatusFromJSON(object.status) : 0,
      errorMessage: isSet(object.errorMessage) ? globalThis.String(object.errorMessage) : undefined,
    };
  },

  toJSON(message: GetProcessMapResponse): unknown {
    const obj: any = {};
    if (message.processMap !== undefined) {
      obj.processMap = Process.toJSON(message.processMap);
    }
    if (message.status !== undefined && message.status !== 0) {
      obj.status = getProcessMapResponseProcessMapGenerationStatusToJSON(message.status);
    }
    if (message.errorMessage !== undefined) {
      obj.errorMessage = message.errorMessage;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetProcessMapResponse>, I>>(base?: I): GetProcessMapResponse {
    return GetProcessMapResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetProcessMapResponse>, I>>(object: I): GetProcessMapResponse {
    const message = createBaseGetProcessMapResponse();
    message.processMap = (object.processMap !== undefined && object.processMap !== null)
      ? Process.fromPartial(object.processMap)
      : undefined;
    message.status = object.status ?? 0;
    message.errorMessage = object.errorMessage ?? undefined;
    return message;
  },
};

messageTypeRegistry.set(GetProcessMapResponse.$type, GetProcessMapResponse);

function createBaseGenerateDocumentationRequest(): GenerateDocumentationRequest {
  return {
    $type: "pb.v1alpha2.GenerateDocumentationRequest",
    configGenerationRequest: undefined,
    sopAndConfigGenerationRequest: undefined,
    sopAndConfigUpdateRequest: undefined,
    processId: "",
  };
}

export const GenerateDocumentationRequest = {
  $type: "pb.v1alpha2.GenerateDocumentationRequest" as const,

  encode(message: GenerateDocumentationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.configGenerationRequest !== undefined) {
      GenerateDocumentationRequestConfigGenerationRequest.encode(
        message.configGenerationRequest,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.sopAndConfigGenerationRequest !== undefined) {
      GenerateDocumentationRequestSopAndConfigGenerationRequest.encode(
        message.sopAndConfigGenerationRequest,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.sopAndConfigUpdateRequest !== undefined) {
      GenerateDocumentationRequestSopAndConfigUpdateRequest.encode(
        message.sopAndConfigUpdateRequest,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.processId !== undefined && message.processId !== "") {
      writer.uint32(34).string(message.processId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GenerateDocumentationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateDocumentationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.configGenerationRequest = GenerateDocumentationRequestConfigGenerationRequest.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sopAndConfigGenerationRequest = GenerateDocumentationRequestSopAndConfigGenerationRequest.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.sopAndConfigUpdateRequest = GenerateDocumentationRequestSopAndConfigUpdateRequest.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.processId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GenerateDocumentationRequest {
    return {
      $type: GenerateDocumentationRequest.$type,
      configGenerationRequest: isSet(object.configGenerationRequest)
        ? GenerateDocumentationRequestConfigGenerationRequest.fromJSON(object.configGenerationRequest)
        : undefined,
      sopAndConfigGenerationRequest: isSet(object.sopAndConfigGenerationRequest)
        ? GenerateDocumentationRequestSopAndConfigGenerationRequest.fromJSON(object.sopAndConfigGenerationRequest)
        : undefined,
      sopAndConfigUpdateRequest: isSet(object.sopAndConfigUpdateRequest)
        ? GenerateDocumentationRequestSopAndConfigUpdateRequest.fromJSON(object.sopAndConfigUpdateRequest)
        : undefined,
      processId: isSet(object.processId) ? globalThis.String(object.processId) : "",
    };
  },

  toJSON(message: GenerateDocumentationRequest): unknown {
    const obj: any = {};
    if (message.configGenerationRequest !== undefined) {
      obj.configGenerationRequest = GenerateDocumentationRequestConfigGenerationRequest.toJSON(
        message.configGenerationRequest,
      );
    }
    if (message.sopAndConfigGenerationRequest !== undefined) {
      obj.sopAndConfigGenerationRequest = GenerateDocumentationRequestSopAndConfigGenerationRequest.toJSON(
        message.sopAndConfigGenerationRequest,
      );
    }
    if (message.sopAndConfigUpdateRequest !== undefined) {
      obj.sopAndConfigUpdateRequest = GenerateDocumentationRequestSopAndConfigUpdateRequest.toJSON(
        message.sopAndConfigUpdateRequest,
      );
    }
    if (message.processId !== undefined && message.processId !== "") {
      obj.processId = message.processId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GenerateDocumentationRequest>, I>>(base?: I): GenerateDocumentationRequest {
    return GenerateDocumentationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GenerateDocumentationRequest>, I>>(object: I): GenerateDocumentationRequest {
    const message = createBaseGenerateDocumentationRequest();
    message.configGenerationRequest =
      (object.configGenerationRequest !== undefined && object.configGenerationRequest !== null)
        ? GenerateDocumentationRequestConfigGenerationRequest.fromPartial(object.configGenerationRequest)
        : undefined;
    message.sopAndConfigGenerationRequest =
      (object.sopAndConfigGenerationRequest !== undefined && object.sopAndConfigGenerationRequest !== null)
        ? GenerateDocumentationRequestSopAndConfigGenerationRequest.fromPartial(object.sopAndConfigGenerationRequest)
        : undefined;
    message.sopAndConfigUpdateRequest =
      (object.sopAndConfigUpdateRequest !== undefined && object.sopAndConfigUpdateRequest !== null)
        ? GenerateDocumentationRequestSopAndConfigUpdateRequest.fromPartial(object.sopAndConfigUpdateRequest)
        : undefined;
    message.processId = object.processId ?? "";
    return message;
  },
};

messageTypeRegistry.set(GenerateDocumentationRequest.$type, GenerateDocumentationRequest);

function createBaseGenerateDocumentationRequestConfigGenerationRequest(): GenerateDocumentationRequestConfigGenerationRequest {
  return { $type: "pb.v1alpha2.GenerateDocumentationRequest.ConfigGenerationRequest", sopSource: undefined };
}

export const GenerateDocumentationRequestConfigGenerationRequest = {
  $type: "pb.v1alpha2.GenerateDocumentationRequest.ConfigGenerationRequest" as const,

  encode(
    message: GenerateDocumentationRequestConfigGenerationRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.sopSource !== undefined) {
      SopSource.encode(message.sopSource, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GenerateDocumentationRequestConfigGenerationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateDocumentationRequestConfigGenerationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sopSource = SopSource.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GenerateDocumentationRequestConfigGenerationRequest {
    return {
      $type: GenerateDocumentationRequestConfigGenerationRequest.$type,
      sopSource: isSet(object.sopSource) ? SopSource.fromJSON(object.sopSource) : undefined,
    };
  },

  toJSON(message: GenerateDocumentationRequestConfigGenerationRequest): unknown {
    const obj: any = {};
    if (message.sopSource !== undefined) {
      obj.sopSource = SopSource.toJSON(message.sopSource);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GenerateDocumentationRequestConfigGenerationRequest>, I>>(
    base?: I,
  ): GenerateDocumentationRequestConfigGenerationRequest {
    return GenerateDocumentationRequestConfigGenerationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GenerateDocumentationRequestConfigGenerationRequest>, I>>(
    object: I,
  ): GenerateDocumentationRequestConfigGenerationRequest {
    const message = createBaseGenerateDocumentationRequestConfigGenerationRequest();
    message.sopSource = (object.sopSource !== undefined && object.sopSource !== null)
      ? SopSource.fromPartial(object.sopSource)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(
  GenerateDocumentationRequestConfigGenerationRequest.$type,
  GenerateDocumentationRequestConfigGenerationRequest,
);

function createBaseGenerateDocumentationRequestSopAndConfigGenerationRequest(): GenerateDocumentationRequestSopAndConfigGenerationRequest {
  return { $type: "pb.v1alpha2.GenerateDocumentationRequest.SopAndConfigGenerationRequest", traceIds: [] };
}

export const GenerateDocumentationRequestSopAndConfigGenerationRequest = {
  $type: "pb.v1alpha2.GenerateDocumentationRequest.SopAndConfigGenerationRequest" as const,

  encode(
    message: GenerateDocumentationRequestSopAndConfigGenerationRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.traceIds !== undefined && message.traceIds.length !== 0) {
      for (const v of message.traceIds) {
        writer.uint32(10).string(v!);
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GenerateDocumentationRequestSopAndConfigGenerationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateDocumentationRequestSopAndConfigGenerationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.traceIds!.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GenerateDocumentationRequestSopAndConfigGenerationRequest {
    return {
      $type: GenerateDocumentationRequestSopAndConfigGenerationRequest.$type,
      traceIds: globalThis.Array.isArray(object?.traceIds) ? object.traceIds.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: GenerateDocumentationRequestSopAndConfigGenerationRequest): unknown {
    const obj: any = {};
    if (message.traceIds?.length) {
      obj.traceIds = message.traceIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GenerateDocumentationRequestSopAndConfigGenerationRequest>, I>>(
    base?: I,
  ): GenerateDocumentationRequestSopAndConfigGenerationRequest {
    return GenerateDocumentationRequestSopAndConfigGenerationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GenerateDocumentationRequestSopAndConfigGenerationRequest>, I>>(
    object: I,
  ): GenerateDocumentationRequestSopAndConfigGenerationRequest {
    const message = createBaseGenerateDocumentationRequestSopAndConfigGenerationRequest();
    message.traceIds = object.traceIds?.map((e) => e) || [];
    return message;
  },
};

messageTypeRegistry.set(
  GenerateDocumentationRequestSopAndConfigGenerationRequest.$type,
  GenerateDocumentationRequestSopAndConfigGenerationRequest,
);

function createBaseGenerateDocumentationRequestSopAndConfigUpdateRequest(): GenerateDocumentationRequestSopAndConfigUpdateRequest {
  return {
    $type: "pb.v1alpha2.GenerateDocumentationRequest.SopAndConfigUpdateRequest",
    sopSource: undefined,
    userFeedback: "",
  };
}

export const GenerateDocumentationRequestSopAndConfigUpdateRequest = {
  $type: "pb.v1alpha2.GenerateDocumentationRequest.SopAndConfigUpdateRequest" as const,

  encode(
    message: GenerateDocumentationRequestSopAndConfigUpdateRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.sopSource !== undefined) {
      SopSource.encode(message.sopSource, writer.uint32(10).fork()).ldelim();
    }
    if (message.userFeedback !== undefined && message.userFeedback !== "") {
      writer.uint32(18).string(message.userFeedback);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GenerateDocumentationRequestSopAndConfigUpdateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateDocumentationRequestSopAndConfigUpdateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sopSource = SopSource.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userFeedback = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GenerateDocumentationRequestSopAndConfigUpdateRequest {
    return {
      $type: GenerateDocumentationRequestSopAndConfigUpdateRequest.$type,
      sopSource: isSet(object.sopSource) ? SopSource.fromJSON(object.sopSource) : undefined,
      userFeedback: isSet(object.userFeedback) ? globalThis.String(object.userFeedback) : "",
    };
  },

  toJSON(message: GenerateDocumentationRequestSopAndConfigUpdateRequest): unknown {
    const obj: any = {};
    if (message.sopSource !== undefined) {
      obj.sopSource = SopSource.toJSON(message.sopSource);
    }
    if (message.userFeedback !== undefined && message.userFeedback !== "") {
      obj.userFeedback = message.userFeedback;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GenerateDocumentationRequestSopAndConfigUpdateRequest>, I>>(
    base?: I,
  ): GenerateDocumentationRequestSopAndConfigUpdateRequest {
    return GenerateDocumentationRequestSopAndConfigUpdateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GenerateDocumentationRequestSopAndConfigUpdateRequest>, I>>(
    object: I,
  ): GenerateDocumentationRequestSopAndConfigUpdateRequest {
    const message = createBaseGenerateDocumentationRequestSopAndConfigUpdateRequest();
    message.sopSource = (object.sopSource !== undefined && object.sopSource !== null)
      ? SopSource.fromPartial(object.sopSource)
      : undefined;
    message.userFeedback = object.userFeedback ?? "";
    return message;
  },
};

messageTypeRegistry.set(
  GenerateDocumentationRequestSopAndConfigUpdateRequest.$type,
  GenerateDocumentationRequestSopAndConfigUpdateRequest,
);

function createBaseGenerateDocumentationResponse(): GenerateDocumentationResponse {
  return { $type: "pb.v1alpha2.GenerateDocumentationResponse", processConfig: undefined };
}

export const GenerateDocumentationResponse = {
  $type: "pb.v1alpha2.GenerateDocumentationResponse" as const,

  encode(message: GenerateDocumentationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.processConfig !== undefined) {
      UserDefinedProcess.encode(message.processConfig, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GenerateDocumentationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateDocumentationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.processConfig = UserDefinedProcess.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GenerateDocumentationResponse {
    return {
      $type: GenerateDocumentationResponse.$type,
      processConfig: isSet(object.processConfig) ? UserDefinedProcess.fromJSON(object.processConfig) : undefined,
    };
  },

  toJSON(message: GenerateDocumentationResponse): unknown {
    const obj: any = {};
    if (message.processConfig !== undefined) {
      obj.processConfig = UserDefinedProcess.toJSON(message.processConfig);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GenerateDocumentationResponse>, I>>(base?: I): GenerateDocumentationResponse {
    return GenerateDocumentationResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GenerateDocumentationResponse>, I>>(
    object: I,
  ): GenerateDocumentationResponse {
    const message = createBaseGenerateDocumentationResponse();
    message.processConfig = (object.processConfig !== undefined && object.processConfig !== null)
      ? UserDefinedProcess.fromPartial(object.processConfig)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(GenerateDocumentationResponse.$type, GenerateDocumentationResponse);

function createBaseListProcessInstancesRequest(): ListProcessInstancesRequest {
  return {
    $type: "pb.v1alpha2.ListProcessInstancesRequest",
    processId: "",
    filter: undefined,
    pageSize: 0,
    pageNumber: 0,
  };
}

export const ListProcessInstancesRequest = {
  $type: "pb.v1alpha2.ListProcessInstancesRequest" as const,

  encode(message: ListProcessInstancesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.processId !== undefined && message.processId !== "") {
      writer.uint32(10).string(message.processId);
    }
    if (message.filter !== undefined) {
      writer.uint32(18).string(message.filter);
    }
    if (message.pageSize !== undefined && message.pageSize !== 0) {
      writer.uint32(24).int32(message.pageSize);
    }
    if (message.pageNumber !== undefined && message.pageNumber !== 0) {
      writer.uint32(32).int32(message.pageNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListProcessInstancesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListProcessInstancesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.processId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.filter = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.pageNumber = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListProcessInstancesRequest {
    return {
      $type: ListProcessInstancesRequest.$type,
      processId: isSet(object.processId) ? globalThis.String(object.processId) : "",
      filter: isSet(object.filter) ? globalThis.String(object.filter) : undefined,
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageNumber: isSet(object.pageNumber) ? globalThis.Number(object.pageNumber) : 0,
    };
  },

  toJSON(message: ListProcessInstancesRequest): unknown {
    const obj: any = {};
    if (message.processId !== undefined && message.processId !== "") {
      obj.processId = message.processId;
    }
    if (message.filter !== undefined) {
      obj.filter = message.filter;
    }
    if (message.pageSize !== undefined && message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageNumber !== undefined && message.pageNumber !== 0) {
      obj.pageNumber = Math.round(message.pageNumber);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListProcessInstancesRequest>, I>>(base?: I): ListProcessInstancesRequest {
    return ListProcessInstancesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListProcessInstancesRequest>, I>>(object: I): ListProcessInstancesRequest {
    const message = createBaseListProcessInstancesRequest();
    message.processId = object.processId ?? "";
    message.filter = object.filter ?? undefined;
    message.pageSize = object.pageSize ?? 0;
    message.pageNumber = object.pageNumber ?? 0;
    return message;
  },
};

messageTypeRegistry.set(ListProcessInstancesRequest.$type, ListProcessInstancesRequest);

function createBaseListProcessInstancesResponse(): ListProcessInstancesResponse {
  return { $type: "pb.v1alpha2.ListProcessInstancesResponse", processInstances: [], totalCount: 0 };
}

export const ListProcessInstancesResponse = {
  $type: "pb.v1alpha2.ListProcessInstancesResponse" as const,

  encode(message: ListProcessInstancesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.processInstances !== undefined && message.processInstances.length !== 0) {
      for (const v of message.processInstances) {
        ProcessInstance.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    if (message.totalCount !== undefined && message.totalCount !== 0) {
      writer.uint32(16).int32(message.totalCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListProcessInstancesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListProcessInstancesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.processInstances!.push(ProcessInstance.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.totalCount = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListProcessInstancesResponse {
    return {
      $type: ListProcessInstancesResponse.$type,
      processInstances: globalThis.Array.isArray(object?.processInstances)
        ? object.processInstances.map((e: any) => ProcessInstance.fromJSON(e))
        : [],
      totalCount: isSet(object.totalCount) ? globalThis.Number(object.totalCount) : 0,
    };
  },

  toJSON(message: ListProcessInstancesResponse): unknown {
    const obj: any = {};
    if (message.processInstances?.length) {
      obj.processInstances = message.processInstances.map((e) => ProcessInstance.toJSON(e));
    }
    if (message.totalCount !== undefined && message.totalCount !== 0) {
      obj.totalCount = Math.round(message.totalCount);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListProcessInstancesResponse>, I>>(base?: I): ListProcessInstancesResponse {
    return ListProcessInstancesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListProcessInstancesResponse>, I>>(object: I): ListProcessInstancesResponse {
    const message = createBaseListProcessInstancesResponse();
    message.processInstances = object.processInstances?.map((e) => ProcessInstance.fromPartial(e)) || [];
    message.totalCount = object.totalCount ?? 0;
    return message;
  },
};

messageTypeRegistry.set(ListProcessInstancesResponse.$type, ListProcessInstancesResponse);

function createBaseGetProcessInstanceRequest(): GetProcessInstanceRequest {
  return { $type: "pb.v1alpha2.GetProcessInstanceRequest", processInstanceId: "" };
}

export const GetProcessInstanceRequest = {
  $type: "pb.v1alpha2.GetProcessInstanceRequest" as const,

  encode(message: GetProcessInstanceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.processInstanceId !== undefined && message.processInstanceId !== "") {
      writer.uint32(10).string(message.processInstanceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetProcessInstanceRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetProcessInstanceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.processInstanceId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetProcessInstanceRequest {
    return {
      $type: GetProcessInstanceRequest.$type,
      processInstanceId: isSet(object.processInstanceId) ? globalThis.String(object.processInstanceId) : "",
    };
  },

  toJSON(message: GetProcessInstanceRequest): unknown {
    const obj: any = {};
    if (message.processInstanceId !== undefined && message.processInstanceId !== "") {
      obj.processInstanceId = message.processInstanceId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetProcessInstanceRequest>, I>>(base?: I): GetProcessInstanceRequest {
    return GetProcessInstanceRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetProcessInstanceRequest>, I>>(object: I): GetProcessInstanceRequest {
    const message = createBaseGetProcessInstanceRequest();
    message.processInstanceId = object.processInstanceId ?? "";
    return message;
  },
};

messageTypeRegistry.set(GetProcessInstanceRequest.$type, GetProcessInstanceRequest);

function createBaseGetProcessInstanceResponse(): GetProcessInstanceResponse {
  return { $type: "pb.v1alpha2.GetProcessInstanceResponse", processInstance: undefined };
}

export const GetProcessInstanceResponse = {
  $type: "pb.v1alpha2.GetProcessInstanceResponse" as const,

  encode(message: GetProcessInstanceResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.processInstance !== undefined) {
      ProcessInstance.encode(message.processInstance, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetProcessInstanceResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetProcessInstanceResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.processInstance = ProcessInstance.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetProcessInstanceResponse {
    return {
      $type: GetProcessInstanceResponse.$type,
      processInstance: isSet(object.processInstance) ? ProcessInstance.fromJSON(object.processInstance) : undefined,
    };
  },

  toJSON(message: GetProcessInstanceResponse): unknown {
    const obj: any = {};
    if (message.processInstance !== undefined) {
      obj.processInstance = ProcessInstance.toJSON(message.processInstance);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetProcessInstanceResponse>, I>>(base?: I): GetProcessInstanceResponse {
    return GetProcessInstanceResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetProcessInstanceResponse>, I>>(object: I): GetProcessInstanceResponse {
    const message = createBaseGetProcessInstanceResponse();
    message.processInstance = (object.processInstance !== undefined && object.processInstance !== null)
      ? ProcessInstance.fromPartial(object.processInstance)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(GetProcessInstanceResponse.$type, GetProcessInstanceResponse);

function createBaseUpdateProcessInstanceRequest(): UpdateProcessInstanceRequest {
  return { $type: "pb.v1alpha2.UpdateProcessInstanceRequest", processInstance: undefined, updateMask: undefined };
}

export const UpdateProcessInstanceRequest = {
  $type: "pb.v1alpha2.UpdateProcessInstanceRequest" as const,

  encode(message: UpdateProcessInstanceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.processInstance !== undefined) {
      ProcessInstance.encode(message.processInstance, writer.uint32(10).fork()).ldelim();
    }
    if (message.updateMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.updateMask), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateProcessInstanceRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateProcessInstanceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.processInstance = ProcessInstance.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.updateMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateProcessInstanceRequest {
    return {
      $type: UpdateProcessInstanceRequest.$type,
      processInstance: isSet(object.processInstance) ? ProcessInstance.fromJSON(object.processInstance) : undefined,
      updateMask: isSet(object.updateMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.updateMask)) : undefined,
    };
  },

  toJSON(message: UpdateProcessInstanceRequest): unknown {
    const obj: any = {};
    if (message.processInstance !== undefined) {
      obj.processInstance = ProcessInstance.toJSON(message.processInstance);
    }
    if (message.updateMask !== undefined) {
      obj.updateMask = FieldMask.toJSON(FieldMask.wrap(message.updateMask));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateProcessInstanceRequest>, I>>(base?: I): UpdateProcessInstanceRequest {
    return UpdateProcessInstanceRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateProcessInstanceRequest>, I>>(object: I): UpdateProcessInstanceRequest {
    const message = createBaseUpdateProcessInstanceRequest();
    message.processInstance = (object.processInstance !== undefined && object.processInstance !== null)
      ? ProcessInstance.fromPartial(object.processInstance)
      : undefined;
    message.updateMask = object.updateMask ?? undefined;
    return message;
  },
};

messageTypeRegistry.set(UpdateProcessInstanceRequest.$type, UpdateProcessInstanceRequest);

function createBaseUpdateProcessInstanceResponse(): UpdateProcessInstanceResponse {
  return { $type: "pb.v1alpha2.UpdateProcessInstanceResponse", updatedProcessInstance: undefined };
}

export const UpdateProcessInstanceResponse = {
  $type: "pb.v1alpha2.UpdateProcessInstanceResponse" as const,

  encode(message: UpdateProcessInstanceResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.updatedProcessInstance !== undefined) {
      ProcessInstance.encode(message.updatedProcessInstance, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateProcessInstanceResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateProcessInstanceResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.updatedProcessInstance = ProcessInstance.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateProcessInstanceResponse {
    return {
      $type: UpdateProcessInstanceResponse.$type,
      updatedProcessInstance: isSet(object.updatedProcessInstance)
        ? ProcessInstance.fromJSON(object.updatedProcessInstance)
        : undefined,
    };
  },

  toJSON(message: UpdateProcessInstanceResponse): unknown {
    const obj: any = {};
    if (message.updatedProcessInstance !== undefined) {
      obj.updatedProcessInstance = ProcessInstance.toJSON(message.updatedProcessInstance);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateProcessInstanceResponse>, I>>(base?: I): UpdateProcessInstanceResponse {
    return UpdateProcessInstanceResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateProcessInstanceResponse>, I>>(
    object: I,
  ): UpdateProcessInstanceResponse {
    const message = createBaseUpdateProcessInstanceResponse();
    message.updatedProcessInstance =
      (object.updatedProcessInstance !== undefined && object.updatedProcessInstance !== null)
        ? ProcessInstance.fromPartial(object.updatedProcessInstance)
        : undefined;
    return message;
  },
};

messageTypeRegistry.set(UpdateProcessInstanceResponse.$type, UpdateProcessInstanceResponse);

function createBaseGetVariantPathsRequest(): GetVariantPathsRequest {
  return { $type: "pb.v1alpha2.GetVariantPathsRequest", processId: "" };
}

export const GetVariantPathsRequest = {
  $type: "pb.v1alpha2.GetVariantPathsRequest" as const,

  encode(message: GetVariantPathsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.processId !== undefined && message.processId !== "") {
      writer.uint32(10).string(message.processId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetVariantPathsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetVariantPathsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.processId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetVariantPathsRequest {
    return {
      $type: GetVariantPathsRequest.$type,
      processId: isSet(object.processId) ? globalThis.String(object.processId) : "",
    };
  },

  toJSON(message: GetVariantPathsRequest): unknown {
    const obj: any = {};
    if (message.processId !== undefined && message.processId !== "") {
      obj.processId = message.processId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetVariantPathsRequest>, I>>(base?: I): GetVariantPathsRequest {
    return GetVariantPathsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetVariantPathsRequest>, I>>(object: I): GetVariantPathsRequest {
    const message = createBaseGetVariantPathsRequest();
    message.processId = object.processId ?? "";
    return message;
  },
};

messageTypeRegistry.set(GetVariantPathsRequest.$type, GetVariantPathsRequest);

function createBaseGetVariantPathsResponse(): GetVariantPathsResponse {
  return { $type: "pb.v1alpha2.GetVariantPathsResponse", variantPaths: [] };
}

export const GetVariantPathsResponse = {
  $type: "pb.v1alpha2.GetVariantPathsResponse" as const,

  encode(message: GetVariantPathsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.variantPaths !== undefined && message.variantPaths.length !== 0) {
      for (const v of message.variantPaths) {
        GetVariantPathsResponseVariantPath.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetVariantPathsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetVariantPathsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.variantPaths!.push(GetVariantPathsResponseVariantPath.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetVariantPathsResponse {
    return {
      $type: GetVariantPathsResponse.$type,
      variantPaths: globalThis.Array.isArray(object?.variantPaths)
        ? object.variantPaths.map((e: any) => GetVariantPathsResponseVariantPath.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetVariantPathsResponse): unknown {
    const obj: any = {};
    if (message.variantPaths?.length) {
      obj.variantPaths = message.variantPaths.map((e) => GetVariantPathsResponseVariantPath.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetVariantPathsResponse>, I>>(base?: I): GetVariantPathsResponse {
    return GetVariantPathsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetVariantPathsResponse>, I>>(object: I): GetVariantPathsResponse {
    const message = createBaseGetVariantPathsResponse();
    message.variantPaths = object.variantPaths?.map((e) => GetVariantPathsResponseVariantPath.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(GetVariantPathsResponse.$type, GetVariantPathsResponse);

function createBaseGetVariantPathsResponseVariantPath(): GetVariantPathsResponseVariantPath {
  return {
    $type: "pb.v1alpha2.GetVariantPathsResponse.VariantPath",
    pathId: "",
    process: undefined,
    processInstanceFrequency: 0,
    averageTimeSpent: 0,
    longestTimeSpent: 0,
    shortestTimeSpent: 0,
  };
}

export const GetVariantPathsResponseVariantPath = {
  $type: "pb.v1alpha2.GetVariantPathsResponse.VariantPath" as const,

  encode(message: GetVariantPathsResponseVariantPath, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pathId !== undefined && message.pathId !== "") {
      writer.uint32(10).string(message.pathId);
    }
    if (message.process !== undefined) {
      Process.encode(message.process, writer.uint32(18).fork()).ldelim();
    }
    if (message.processInstanceFrequency !== undefined && message.processInstanceFrequency !== 0) {
      writer.uint32(24).int32(message.processInstanceFrequency);
    }
    if (message.averageTimeSpent !== undefined && message.averageTimeSpent !== 0) {
      writer.uint32(32).int32(message.averageTimeSpent);
    }
    if (message.longestTimeSpent !== undefined && message.longestTimeSpent !== 0) {
      writer.uint32(40).int32(message.longestTimeSpent);
    }
    if (message.shortestTimeSpent !== undefined && message.shortestTimeSpent !== 0) {
      writer.uint32(48).int32(message.shortestTimeSpent);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetVariantPathsResponseVariantPath {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetVariantPathsResponseVariantPath();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pathId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.process = Process.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.processInstanceFrequency = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.averageTimeSpent = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.longestTimeSpent = reader.int32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.shortestTimeSpent = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetVariantPathsResponseVariantPath {
    return {
      $type: GetVariantPathsResponseVariantPath.$type,
      pathId: isSet(object.pathId) ? globalThis.String(object.pathId) : "",
      process: isSet(object.process) ? Process.fromJSON(object.process) : undefined,
      processInstanceFrequency: isSet(object.processInstanceFrequency)
        ? globalThis.Number(object.processInstanceFrequency)
        : 0,
      averageTimeSpent: isSet(object.averageTimeSpent) ? globalThis.Number(object.averageTimeSpent) : 0,
      longestTimeSpent: isSet(object.longestTimeSpent) ? globalThis.Number(object.longestTimeSpent) : 0,
      shortestTimeSpent: isSet(object.shortestTimeSpent) ? globalThis.Number(object.shortestTimeSpent) : 0,
    };
  },

  toJSON(message: GetVariantPathsResponseVariantPath): unknown {
    const obj: any = {};
    if (message.pathId !== undefined && message.pathId !== "") {
      obj.pathId = message.pathId;
    }
    if (message.process !== undefined) {
      obj.process = Process.toJSON(message.process);
    }
    if (message.processInstanceFrequency !== undefined && message.processInstanceFrequency !== 0) {
      obj.processInstanceFrequency = Math.round(message.processInstanceFrequency);
    }
    if (message.averageTimeSpent !== undefined && message.averageTimeSpent !== 0) {
      obj.averageTimeSpent = Math.round(message.averageTimeSpent);
    }
    if (message.longestTimeSpent !== undefined && message.longestTimeSpent !== 0) {
      obj.longestTimeSpent = Math.round(message.longestTimeSpent);
    }
    if (message.shortestTimeSpent !== undefined && message.shortestTimeSpent !== 0) {
      obj.shortestTimeSpent = Math.round(message.shortestTimeSpent);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetVariantPathsResponseVariantPath>, I>>(
    base?: I,
  ): GetVariantPathsResponseVariantPath {
    return GetVariantPathsResponseVariantPath.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetVariantPathsResponseVariantPath>, I>>(
    object: I,
  ): GetVariantPathsResponseVariantPath {
    const message = createBaseGetVariantPathsResponseVariantPath();
    message.pathId = object.pathId ?? "";
    message.process = (object.process !== undefined && object.process !== null)
      ? Process.fromPartial(object.process)
      : undefined;
    message.processInstanceFrequency = object.processInstanceFrequency ?? 0;
    message.averageTimeSpent = object.averageTimeSpent ?? 0;
    message.longestTimeSpent = object.longestTimeSpent ?? 0;
    message.shortestTimeSpent = object.shortestTimeSpent ?? 0;
    return message;
  },
};

messageTypeRegistry.set(GetVariantPathsResponseVariantPath.$type, GetVariantPathsResponseVariantPath);

function createBaseConfigGenerationRequest(): ConfigGenerationRequest {
  return { $type: "pb.v1alpha2.ConfigGenerationRequest", sopSource: undefined };
}

export const ConfigGenerationRequest = {
  $type: "pb.v1alpha2.ConfigGenerationRequest" as const,

  encode(message: ConfigGenerationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sopSource !== undefined) {
      SopSource.encode(message.sopSource, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConfigGenerationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConfigGenerationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sopSource = SopSource.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConfigGenerationRequest {
    return {
      $type: ConfigGenerationRequest.$type,
      sopSource: isSet(object.sopSource) ? SopSource.fromJSON(object.sopSource) : undefined,
    };
  },

  toJSON(message: ConfigGenerationRequest): unknown {
    const obj: any = {};
    if (message.sopSource !== undefined) {
      obj.sopSource = SopSource.toJSON(message.sopSource);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ConfigGenerationRequest>, I>>(base?: I): ConfigGenerationRequest {
    return ConfigGenerationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ConfigGenerationRequest>, I>>(object: I): ConfigGenerationRequest {
    const message = createBaseConfigGenerationRequest();
    message.sopSource = (object.sopSource !== undefined && object.sopSource !== null)
      ? SopSource.fromPartial(object.sopSource)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(ConfigGenerationRequest.$type, ConfigGenerationRequest);

function createBaseSopAndConfigGenerationRequest(): SopAndConfigGenerationRequest {
  return { $type: "pb.v1alpha2.SopAndConfigGenerationRequest", traceEvents: [], sopSource: undefined };
}

export const SopAndConfigGenerationRequest = {
  $type: "pb.v1alpha2.SopAndConfigGenerationRequest" as const,

  encode(message: SopAndConfigGenerationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.traceEvents !== undefined && message.traceEvents.length !== 0) {
      for (const v of message.traceEvents) {
        SopAndConfigGenerationRequestTraceEvents.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    if (message.sopSource !== undefined) {
      SopSource.encode(message.sopSource, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SopAndConfigGenerationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSopAndConfigGenerationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.traceEvents!.push(SopAndConfigGenerationRequestTraceEvents.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sopSource = SopSource.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SopAndConfigGenerationRequest {
    return {
      $type: SopAndConfigGenerationRequest.$type,
      traceEvents: globalThis.Array.isArray(object?.traceEvents)
        ? object.traceEvents.map((e: any) => SopAndConfigGenerationRequestTraceEvents.fromJSON(e))
        : [],
      sopSource: isSet(object.sopSource) ? SopSource.fromJSON(object.sopSource) : undefined,
    };
  },

  toJSON(message: SopAndConfigGenerationRequest): unknown {
    const obj: any = {};
    if (message.traceEvents?.length) {
      obj.traceEvents = message.traceEvents.map((e) => SopAndConfigGenerationRequestTraceEvents.toJSON(e));
    }
    if (message.sopSource !== undefined) {
      obj.sopSource = SopSource.toJSON(message.sopSource);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SopAndConfigGenerationRequest>, I>>(base?: I): SopAndConfigGenerationRequest {
    return SopAndConfigGenerationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SopAndConfigGenerationRequest>, I>>(
    object: I,
  ): SopAndConfigGenerationRequest {
    const message = createBaseSopAndConfigGenerationRequest();
    message.traceEvents = object.traceEvents?.map((e) => SopAndConfigGenerationRequestTraceEvents.fromPartial(e)) || [];
    message.sopSource = (object.sopSource !== undefined && object.sopSource !== null)
      ? SopSource.fromPartial(object.sopSource)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(SopAndConfigGenerationRequest.$type, SopAndConfigGenerationRequest);

function createBaseSopAndConfigGenerationRequestTraceEvents(): SopAndConfigGenerationRequestTraceEvents {
  return { $type: "pb.v1alpha2.SopAndConfigGenerationRequest.TraceEvents", traceId: "", userEvents: [] };
}

export const SopAndConfigGenerationRequestTraceEvents = {
  $type: "pb.v1alpha2.SopAndConfigGenerationRequest.TraceEvents" as const,

  encode(message: SopAndConfigGenerationRequestTraceEvents, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.traceId !== undefined && message.traceId !== "") {
      writer.uint32(10).string(message.traceId);
    }
    if (message.userEvents !== undefined && message.userEvents.length !== 0) {
      for (const v of message.userEvents) {
        UserEvent.encode(v!, writer.uint32(18).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SopAndConfigGenerationRequestTraceEvents {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSopAndConfigGenerationRequestTraceEvents();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.traceId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userEvents!.push(UserEvent.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SopAndConfigGenerationRequestTraceEvents {
    return {
      $type: SopAndConfigGenerationRequestTraceEvents.$type,
      traceId: isSet(object.traceId) ? globalThis.String(object.traceId) : "",
      userEvents: globalThis.Array.isArray(object?.userEvents)
        ? object.userEvents.map((e: any) => UserEvent.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SopAndConfigGenerationRequestTraceEvents): unknown {
    const obj: any = {};
    if (message.traceId !== undefined && message.traceId !== "") {
      obj.traceId = message.traceId;
    }
    if (message.userEvents?.length) {
      obj.userEvents = message.userEvents.map((e) => UserEvent.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SopAndConfigGenerationRequestTraceEvents>, I>>(
    base?: I,
  ): SopAndConfigGenerationRequestTraceEvents {
    return SopAndConfigGenerationRequestTraceEvents.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SopAndConfigGenerationRequestTraceEvents>, I>>(
    object: I,
  ): SopAndConfigGenerationRequestTraceEvents {
    const message = createBaseSopAndConfigGenerationRequestTraceEvents();
    message.traceId = object.traceId ?? "";
    message.userEvents = object.userEvents?.map((e) => UserEvent.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(SopAndConfigGenerationRequestTraceEvents.$type, SopAndConfigGenerationRequestTraceEvents);

function createBaseSopAndConfigUpdateRequest(): SopAndConfigUpdateRequest {
  return { $type: "pb.v1alpha2.SopAndConfigUpdateRequest", sopSource: undefined, userFeedback: "" };
}

export const SopAndConfigUpdateRequest = {
  $type: "pb.v1alpha2.SopAndConfigUpdateRequest" as const,

  encode(message: SopAndConfigUpdateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sopSource !== undefined) {
      SopSource.encode(message.sopSource, writer.uint32(10).fork()).ldelim();
    }
    if (message.userFeedback !== undefined && message.userFeedback !== "") {
      writer.uint32(18).string(message.userFeedback);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SopAndConfigUpdateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSopAndConfigUpdateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sopSource = SopSource.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userFeedback = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SopAndConfigUpdateRequest {
    return {
      $type: SopAndConfigUpdateRequest.$type,
      sopSource: isSet(object.sopSource) ? SopSource.fromJSON(object.sopSource) : undefined,
      userFeedback: isSet(object.userFeedback) ? globalThis.String(object.userFeedback) : "",
    };
  },

  toJSON(message: SopAndConfigUpdateRequest): unknown {
    const obj: any = {};
    if (message.sopSource !== undefined) {
      obj.sopSource = SopSource.toJSON(message.sopSource);
    }
    if (message.userFeedback !== undefined && message.userFeedback !== "") {
      obj.userFeedback = message.userFeedback;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SopAndConfigUpdateRequest>, I>>(base?: I): SopAndConfigUpdateRequest {
    return SopAndConfigUpdateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SopAndConfigUpdateRequest>, I>>(object: I): SopAndConfigUpdateRequest {
    const message = createBaseSopAndConfigUpdateRequest();
    message.sopSource = (object.sopSource !== undefined && object.sopSource !== null)
      ? SopSource.fromPartial(object.sopSource)
      : undefined;
    message.userFeedback = object.userFeedback ?? "";
    return message;
  },
};

messageTypeRegistry.set(SopAndConfigUpdateRequest.$type, SopAndConfigUpdateRequest);

function createBaseMLGenerateDocumentationRequest(): MLGenerateDocumentationRequest {
  return {
    $type: "pb.v1alpha2.MLGenerateDocumentationRequest",
    configGenerationRequest: undefined,
    sopAndConfigGenerationRequest: undefined,
    sopAndConfigUpdateRequest: undefined,
    processMetadata: undefined,
    outputProcessDiscoveryDebuggingGcsPath: "",
  };
}

export const MLGenerateDocumentationRequest = {
  $type: "pb.v1alpha2.MLGenerateDocumentationRequest" as const,

  encode(message: MLGenerateDocumentationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.configGenerationRequest !== undefined) {
      ConfigGenerationRequest.encode(message.configGenerationRequest, writer.uint32(10).fork()).ldelim();
    }
    if (message.sopAndConfigGenerationRequest !== undefined) {
      SopAndConfigGenerationRequest.encode(message.sopAndConfigGenerationRequest, writer.uint32(18).fork()).ldelim();
    }
    if (message.sopAndConfigUpdateRequest !== undefined) {
      SopAndConfigUpdateRequest.encode(message.sopAndConfigUpdateRequest, writer.uint32(26).fork()).ldelim();
    }
    if (message.processMetadata !== undefined) {
      UserDefinedProcess.encode(message.processMetadata, writer.uint32(34).fork()).ldelim();
    }
    if (
      message.outputProcessDiscoveryDebuggingGcsPath !== undefined &&
      message.outputProcessDiscoveryDebuggingGcsPath !== ""
    ) {
      writer.uint32(42).string(message.outputProcessDiscoveryDebuggingGcsPath);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MLGenerateDocumentationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMLGenerateDocumentationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.configGenerationRequest = ConfigGenerationRequest.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sopAndConfigGenerationRequest = SopAndConfigGenerationRequest.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.sopAndConfigUpdateRequest = SopAndConfigUpdateRequest.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.processMetadata = UserDefinedProcess.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.outputProcessDiscoveryDebuggingGcsPath = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MLGenerateDocumentationRequest {
    return {
      $type: MLGenerateDocumentationRequest.$type,
      configGenerationRequest: isSet(object.configGenerationRequest)
        ? ConfigGenerationRequest.fromJSON(object.configGenerationRequest)
        : undefined,
      sopAndConfigGenerationRequest: isSet(object.sopAndConfigGenerationRequest)
        ? SopAndConfigGenerationRequest.fromJSON(object.sopAndConfigGenerationRequest)
        : undefined,
      sopAndConfigUpdateRequest: isSet(object.sopAndConfigUpdateRequest)
        ? SopAndConfigUpdateRequest.fromJSON(object.sopAndConfigUpdateRequest)
        : undefined,
      processMetadata: isSet(object.processMetadata) ? UserDefinedProcess.fromJSON(object.processMetadata) : undefined,
      outputProcessDiscoveryDebuggingGcsPath: isSet(object.outputProcessDiscoveryDebuggingGcsPath)
        ? globalThis.String(object.outputProcessDiscoveryDebuggingGcsPath)
        : "",
    };
  },

  toJSON(message: MLGenerateDocumentationRequest): unknown {
    const obj: any = {};
    if (message.configGenerationRequest !== undefined) {
      obj.configGenerationRequest = ConfigGenerationRequest.toJSON(message.configGenerationRequest);
    }
    if (message.sopAndConfigGenerationRequest !== undefined) {
      obj.sopAndConfigGenerationRequest = SopAndConfigGenerationRequest.toJSON(message.sopAndConfigGenerationRequest);
    }
    if (message.sopAndConfigUpdateRequest !== undefined) {
      obj.sopAndConfigUpdateRequest = SopAndConfigUpdateRequest.toJSON(message.sopAndConfigUpdateRequest);
    }
    if (message.processMetadata !== undefined) {
      obj.processMetadata = UserDefinedProcess.toJSON(message.processMetadata);
    }
    if (
      message.outputProcessDiscoveryDebuggingGcsPath !== undefined &&
      message.outputProcessDiscoveryDebuggingGcsPath !== ""
    ) {
      obj.outputProcessDiscoveryDebuggingGcsPath = message.outputProcessDiscoveryDebuggingGcsPath;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MLGenerateDocumentationRequest>, I>>(base?: I): MLGenerateDocumentationRequest {
    return MLGenerateDocumentationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MLGenerateDocumentationRequest>, I>>(
    object: I,
  ): MLGenerateDocumentationRequest {
    const message = createBaseMLGenerateDocumentationRequest();
    message.configGenerationRequest =
      (object.configGenerationRequest !== undefined && object.configGenerationRequest !== null)
        ? ConfigGenerationRequest.fromPartial(object.configGenerationRequest)
        : undefined;
    message.sopAndConfigGenerationRequest =
      (object.sopAndConfigGenerationRequest !== undefined && object.sopAndConfigGenerationRequest !== null)
        ? SopAndConfigGenerationRequest.fromPartial(object.sopAndConfigGenerationRequest)
        : undefined;
    message.sopAndConfigUpdateRequest =
      (object.sopAndConfigUpdateRequest !== undefined && object.sopAndConfigUpdateRequest !== null)
        ? SopAndConfigUpdateRequest.fromPartial(object.sopAndConfigUpdateRequest)
        : undefined;
    message.processMetadata = (object.processMetadata !== undefined && object.processMetadata !== null)
      ? UserDefinedProcess.fromPartial(object.processMetadata)
      : undefined;
    message.outputProcessDiscoveryDebuggingGcsPath = object.outputProcessDiscoveryDebuggingGcsPath ?? "";
    return message;
  },
};

messageTypeRegistry.set(MLGenerateDocumentationRequest.$type, MLGenerateDocumentationRequest);

function createBaseMLGenerateDocumentationResponse(): MLGenerateDocumentationResponse {
  return { $type: "pb.v1alpha2.MLGenerateDocumentationResponse", processConfig: undefined };
}

export const MLGenerateDocumentationResponse = {
  $type: "pb.v1alpha2.MLGenerateDocumentationResponse" as const,

  encode(message: MLGenerateDocumentationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.processConfig !== undefined) {
      UserDefinedProcess.encode(message.processConfig, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MLGenerateDocumentationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMLGenerateDocumentationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.processConfig = UserDefinedProcess.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MLGenerateDocumentationResponse {
    return {
      $type: MLGenerateDocumentationResponse.$type,
      processConfig: isSet(object.processConfig) ? UserDefinedProcess.fromJSON(object.processConfig) : undefined,
    };
  },

  toJSON(message: MLGenerateDocumentationResponse): unknown {
    const obj: any = {};
    if (message.processConfig !== undefined) {
      obj.processConfig = UserDefinedProcess.toJSON(message.processConfig);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MLGenerateDocumentationResponse>, I>>(base?: I): MLGenerateDocumentationResponse {
    return MLGenerateDocumentationResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MLGenerateDocumentationResponse>, I>>(
    object: I,
  ): MLGenerateDocumentationResponse {
    const message = createBaseMLGenerateDocumentationResponse();
    message.processConfig = (object.processConfig !== undefined && object.processConfig !== null)
      ? UserDefinedProcess.fromPartial(object.processConfig)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(MLGenerateDocumentationResponse.$type, MLGenerateDocumentationResponse);

function createBaseMLProcessDiscoveryAnnotationRequest(): MLProcessDiscoveryAnnotationRequest {
  return {
    $type: "pb.v1alpha2.MLProcessDiscoveryAnnotationRequest",
    userEvents: [],
    processConfig: undefined,
    outputProcessDiscoveryDebuggingGcsPath: "",
    outputProcessInstancesGcsPrefix: "",
    annotatedProcessInstanceGcsUris: [],
  };
}

export const MLProcessDiscoveryAnnotationRequest = {
  $type: "pb.v1alpha2.MLProcessDiscoveryAnnotationRequest" as const,

  encode(message: MLProcessDiscoveryAnnotationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userEvents !== undefined && message.userEvents.length !== 0) {
      for (const v of message.userEvents) {
        UserEvent.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    if (message.processConfig !== undefined) {
      UserDefinedProcess.encode(message.processConfig, writer.uint32(18).fork()).ldelim();
    }
    if (
      message.outputProcessDiscoveryDebuggingGcsPath !== undefined &&
      message.outputProcessDiscoveryDebuggingGcsPath !== ""
    ) {
      writer.uint32(26).string(message.outputProcessDiscoveryDebuggingGcsPath);
    }
    if (message.outputProcessInstancesGcsPrefix !== undefined && message.outputProcessInstancesGcsPrefix !== "") {
      writer.uint32(34).string(message.outputProcessInstancesGcsPrefix);
    }
    if (message.annotatedProcessInstanceGcsUris !== undefined && message.annotatedProcessInstanceGcsUris.length !== 0) {
      for (const v of message.annotatedProcessInstanceGcsUris) {
        writer.uint32(42).string(v!);
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MLProcessDiscoveryAnnotationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMLProcessDiscoveryAnnotationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userEvents!.push(UserEvent.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.processConfig = UserDefinedProcess.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.outputProcessDiscoveryDebuggingGcsPath = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.outputProcessInstancesGcsPrefix = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.annotatedProcessInstanceGcsUris!.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MLProcessDiscoveryAnnotationRequest {
    return {
      $type: MLProcessDiscoveryAnnotationRequest.$type,
      userEvents: globalThis.Array.isArray(object?.userEvents)
        ? object.userEvents.map((e: any) => UserEvent.fromJSON(e))
        : [],
      processConfig: isSet(object.processConfig) ? UserDefinedProcess.fromJSON(object.processConfig) : undefined,
      outputProcessDiscoveryDebuggingGcsPath: isSet(object.outputProcessDiscoveryDebuggingGcsPath)
        ? globalThis.String(object.outputProcessDiscoveryDebuggingGcsPath)
        : "",
      outputProcessInstancesGcsPrefix: isSet(object.outputProcessInstancesGcsPrefix)
        ? globalThis.String(object.outputProcessInstancesGcsPrefix)
        : "",
      annotatedProcessInstanceGcsUris: globalThis.Array.isArray(object?.annotatedProcessInstanceGcsUris)
        ? object.annotatedProcessInstanceGcsUris.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: MLProcessDiscoveryAnnotationRequest): unknown {
    const obj: any = {};
    if (message.userEvents?.length) {
      obj.userEvents = message.userEvents.map((e) => UserEvent.toJSON(e));
    }
    if (message.processConfig !== undefined) {
      obj.processConfig = UserDefinedProcess.toJSON(message.processConfig);
    }
    if (
      message.outputProcessDiscoveryDebuggingGcsPath !== undefined &&
      message.outputProcessDiscoveryDebuggingGcsPath !== ""
    ) {
      obj.outputProcessDiscoveryDebuggingGcsPath = message.outputProcessDiscoveryDebuggingGcsPath;
    }
    if (message.outputProcessInstancesGcsPrefix !== undefined && message.outputProcessInstancesGcsPrefix !== "") {
      obj.outputProcessInstancesGcsPrefix = message.outputProcessInstancesGcsPrefix;
    }
    if (message.annotatedProcessInstanceGcsUris?.length) {
      obj.annotatedProcessInstanceGcsUris = message.annotatedProcessInstanceGcsUris;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MLProcessDiscoveryAnnotationRequest>, I>>(
    base?: I,
  ): MLProcessDiscoveryAnnotationRequest {
    return MLProcessDiscoveryAnnotationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MLProcessDiscoveryAnnotationRequest>, I>>(
    object: I,
  ): MLProcessDiscoveryAnnotationRequest {
    const message = createBaseMLProcessDiscoveryAnnotationRequest();
    message.userEvents = object.userEvents?.map((e) => UserEvent.fromPartial(e)) || [];
    message.processConfig = (object.processConfig !== undefined && object.processConfig !== null)
      ? UserDefinedProcess.fromPartial(object.processConfig)
      : undefined;
    message.outputProcessDiscoveryDebuggingGcsPath = object.outputProcessDiscoveryDebuggingGcsPath ?? "";
    message.outputProcessInstancesGcsPrefix = object.outputProcessInstancesGcsPrefix ?? "";
    message.annotatedProcessInstanceGcsUris = object.annotatedProcessInstanceGcsUris?.map((e) => e) || [];
    return message;
  },
};

messageTypeRegistry.set(MLProcessDiscoveryAnnotationRequest.$type, MLProcessDiscoveryAnnotationRequest);

function createBaseMLProcessDiscoveryAnnotationResponse(): MLProcessDiscoveryAnnotationResponse {
  return { $type: "pb.v1alpha2.MLProcessDiscoveryAnnotationResponse", processInstances: [] };
}

export const MLProcessDiscoveryAnnotationResponse = {
  $type: "pb.v1alpha2.MLProcessDiscoveryAnnotationResponse" as const,

  encode(message: MLProcessDiscoveryAnnotationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.processInstances !== undefined && message.processInstances.length !== 0) {
      for (const v of message.processInstances) {
        MLProcessDiscoveryAnnotationResponseProcessInstanceInfo.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MLProcessDiscoveryAnnotationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMLProcessDiscoveryAnnotationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.processInstances!.push(
            MLProcessDiscoveryAnnotationResponseProcessInstanceInfo.decode(reader, reader.uint32()),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MLProcessDiscoveryAnnotationResponse {
    return {
      $type: MLProcessDiscoveryAnnotationResponse.$type,
      processInstances: globalThis.Array.isArray(object?.processInstances)
        ? object.processInstances.map((e: any) => MLProcessDiscoveryAnnotationResponseProcessInstanceInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MLProcessDiscoveryAnnotationResponse): unknown {
    const obj: any = {};
    if (message.processInstances?.length) {
      obj.processInstances = message.processInstances.map((e) =>
        MLProcessDiscoveryAnnotationResponseProcessInstanceInfo.toJSON(e)
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MLProcessDiscoveryAnnotationResponse>, I>>(
    base?: I,
  ): MLProcessDiscoveryAnnotationResponse {
    return MLProcessDiscoveryAnnotationResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MLProcessDiscoveryAnnotationResponse>, I>>(
    object: I,
  ): MLProcessDiscoveryAnnotationResponse {
    const message = createBaseMLProcessDiscoveryAnnotationResponse();
    message.processInstances =
      object.processInstances?.map((e) => MLProcessDiscoveryAnnotationResponseProcessInstanceInfo.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(MLProcessDiscoveryAnnotationResponse.$type, MLProcessDiscoveryAnnotationResponse);

function createBaseMLProcessDiscoveryAnnotationResponseProcessInstanceInfo(): MLProcessDiscoveryAnnotationResponseProcessInstanceInfo {
  return {
    $type: "pb.v1alpha2.MLProcessDiscoveryAnnotationResponse.ProcessInstanceInfo",
    processInstanceId: "",
    pathId: "",
    processInstanceGcsUri: "",
  };
}

export const MLProcessDiscoveryAnnotationResponseProcessInstanceInfo = {
  $type: "pb.v1alpha2.MLProcessDiscoveryAnnotationResponse.ProcessInstanceInfo" as const,

  encode(
    message: MLProcessDiscoveryAnnotationResponseProcessInstanceInfo,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.processInstanceId !== undefined && message.processInstanceId !== "") {
      writer.uint32(10).string(message.processInstanceId);
    }
    if (message.pathId !== undefined && message.pathId !== "") {
      writer.uint32(18).string(message.pathId);
    }
    if (message.processInstanceGcsUri !== undefined && message.processInstanceGcsUri !== "") {
      writer.uint32(26).string(message.processInstanceGcsUri);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MLProcessDiscoveryAnnotationResponseProcessInstanceInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMLProcessDiscoveryAnnotationResponseProcessInstanceInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.processInstanceId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pathId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.processInstanceGcsUri = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MLProcessDiscoveryAnnotationResponseProcessInstanceInfo {
    return {
      $type: MLProcessDiscoveryAnnotationResponseProcessInstanceInfo.$type,
      processInstanceId: isSet(object.processInstanceId) ? globalThis.String(object.processInstanceId) : "",
      pathId: isSet(object.pathId) ? globalThis.String(object.pathId) : "",
      processInstanceGcsUri: isSet(object.processInstanceGcsUri) ? globalThis.String(object.processInstanceGcsUri) : "",
    };
  },

  toJSON(message: MLProcessDiscoveryAnnotationResponseProcessInstanceInfo): unknown {
    const obj: any = {};
    if (message.processInstanceId !== undefined && message.processInstanceId !== "") {
      obj.processInstanceId = message.processInstanceId;
    }
    if (message.pathId !== undefined && message.pathId !== "") {
      obj.pathId = message.pathId;
    }
    if (message.processInstanceGcsUri !== undefined && message.processInstanceGcsUri !== "") {
      obj.processInstanceGcsUri = message.processInstanceGcsUri;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MLProcessDiscoveryAnnotationResponseProcessInstanceInfo>, I>>(
    base?: I,
  ): MLProcessDiscoveryAnnotationResponseProcessInstanceInfo {
    return MLProcessDiscoveryAnnotationResponseProcessInstanceInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MLProcessDiscoveryAnnotationResponseProcessInstanceInfo>, I>>(
    object: I,
  ): MLProcessDiscoveryAnnotationResponseProcessInstanceInfo {
    const message = createBaseMLProcessDiscoveryAnnotationResponseProcessInstanceInfo();
    message.processInstanceId = object.processInstanceId ?? "";
    message.pathId = object.pathId ?? "";
    message.processInstanceGcsUri = object.processInstanceGcsUri ?? "";
    return message;
  },
};

messageTypeRegistry.set(
  MLProcessDiscoveryAnnotationResponseProcessInstanceInfo.$type,
  MLProcessDiscoveryAnnotationResponseProcessInstanceInfo,
);

function createBaseMLProcessMapGenerationRequest(): MLProcessMapGenerationRequest {
  return {
    $type: "pb.v1alpha2.MLProcessMapGenerationRequest",
    processId: "",
    pathIds: [],
    outputProcessMapGcsUri: "",
    annotatedProcessInstanceGcsUris: [],
    outputProcessDiscoveryDebuggingGcsPath: "",
  };
}

export const MLProcessMapGenerationRequest = {
  $type: "pb.v1alpha2.MLProcessMapGenerationRequest" as const,

  encode(message: MLProcessMapGenerationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.processId !== undefined && message.processId !== "") {
      writer.uint32(10).string(message.processId);
    }
    if (message.pathIds !== undefined && message.pathIds.length !== 0) {
      for (const v of message.pathIds) {
        writer.uint32(18).string(v!);
      }
    }
    if (message.outputProcessMapGcsUri !== undefined && message.outputProcessMapGcsUri !== "") {
      writer.uint32(26).string(message.outputProcessMapGcsUri);
    }
    if (message.annotatedProcessInstanceGcsUris !== undefined && message.annotatedProcessInstanceGcsUris.length !== 0) {
      for (const v of message.annotatedProcessInstanceGcsUris) {
        writer.uint32(34).string(v!);
      }
    }
    if (
      message.outputProcessDiscoveryDebuggingGcsPath !== undefined &&
      message.outputProcessDiscoveryDebuggingGcsPath !== ""
    ) {
      writer.uint32(42).string(message.outputProcessDiscoveryDebuggingGcsPath);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MLProcessMapGenerationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMLProcessMapGenerationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.processId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pathIds!.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.outputProcessMapGcsUri = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.annotatedProcessInstanceGcsUris!.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.outputProcessDiscoveryDebuggingGcsPath = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MLProcessMapGenerationRequest {
    return {
      $type: MLProcessMapGenerationRequest.$type,
      processId: isSet(object.processId) ? globalThis.String(object.processId) : "",
      pathIds: globalThis.Array.isArray(object?.pathIds) ? object.pathIds.map((e: any) => globalThis.String(e)) : [],
      outputProcessMapGcsUri: isSet(object.outputProcessMapGcsUri)
        ? globalThis.String(object.outputProcessMapGcsUri)
        : "",
      annotatedProcessInstanceGcsUris: globalThis.Array.isArray(object?.annotatedProcessInstanceGcsUris)
        ? object.annotatedProcessInstanceGcsUris.map((e: any) => globalThis.String(e))
        : [],
      outputProcessDiscoveryDebuggingGcsPath: isSet(object.outputProcessDiscoveryDebuggingGcsPath)
        ? globalThis.String(object.outputProcessDiscoveryDebuggingGcsPath)
        : "",
    };
  },

  toJSON(message: MLProcessMapGenerationRequest): unknown {
    const obj: any = {};
    if (message.processId !== undefined && message.processId !== "") {
      obj.processId = message.processId;
    }
    if (message.pathIds?.length) {
      obj.pathIds = message.pathIds;
    }
    if (message.outputProcessMapGcsUri !== undefined && message.outputProcessMapGcsUri !== "") {
      obj.outputProcessMapGcsUri = message.outputProcessMapGcsUri;
    }
    if (message.annotatedProcessInstanceGcsUris?.length) {
      obj.annotatedProcessInstanceGcsUris = message.annotatedProcessInstanceGcsUris;
    }
    if (
      message.outputProcessDiscoveryDebuggingGcsPath !== undefined &&
      message.outputProcessDiscoveryDebuggingGcsPath !== ""
    ) {
      obj.outputProcessDiscoveryDebuggingGcsPath = message.outputProcessDiscoveryDebuggingGcsPath;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MLProcessMapGenerationRequest>, I>>(base?: I): MLProcessMapGenerationRequest {
    return MLProcessMapGenerationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MLProcessMapGenerationRequest>, I>>(
    object: I,
  ): MLProcessMapGenerationRequest {
    const message = createBaseMLProcessMapGenerationRequest();
    message.processId = object.processId ?? "";
    message.pathIds = object.pathIds?.map((e) => e) || [];
    message.outputProcessMapGcsUri = object.outputProcessMapGcsUri ?? "";
    message.annotatedProcessInstanceGcsUris = object.annotatedProcessInstanceGcsUris?.map((e) => e) || [];
    message.outputProcessDiscoveryDebuggingGcsPath = object.outputProcessDiscoveryDebuggingGcsPath ?? "";
    return message;
  },
};

messageTypeRegistry.set(MLProcessMapGenerationRequest.$type, MLProcessMapGenerationRequest);

function createBaseMLProcessMapGenerationResponse(): MLProcessMapGenerationResponse {
  return { $type: "pb.v1alpha2.MLProcessMapGenerationResponse", processMapGcsUri: undefined, errorMessage: undefined };
}

export const MLProcessMapGenerationResponse = {
  $type: "pb.v1alpha2.MLProcessMapGenerationResponse" as const,

  encode(message: MLProcessMapGenerationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.processMapGcsUri !== undefined) {
      writer.uint32(26).string(message.processMapGcsUri);
    }
    if (message.errorMessage !== undefined) {
      writer.uint32(34).string(message.errorMessage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MLProcessMapGenerationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMLProcessMapGenerationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.processMapGcsUri = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.errorMessage = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MLProcessMapGenerationResponse {
    return {
      $type: MLProcessMapGenerationResponse.$type,
      processMapGcsUri: isSet(object.processMapGcsUri) ? globalThis.String(object.processMapGcsUri) : undefined,
      errorMessage: isSet(object.errorMessage) ? globalThis.String(object.errorMessage) : undefined,
    };
  },

  toJSON(message: MLProcessMapGenerationResponse): unknown {
    const obj: any = {};
    if (message.processMapGcsUri !== undefined) {
      obj.processMapGcsUri = message.processMapGcsUri;
    }
    if (message.errorMessage !== undefined) {
      obj.errorMessage = message.errorMessage;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MLProcessMapGenerationResponse>, I>>(base?: I): MLProcessMapGenerationResponse {
    return MLProcessMapGenerationResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MLProcessMapGenerationResponse>, I>>(
    object: I,
  ): MLProcessMapGenerationResponse {
    const message = createBaseMLProcessMapGenerationResponse();
    message.processMapGcsUri = object.processMapGcsUri ?? undefined;
    message.errorMessage = object.errorMessage ?? undefined;
    return message;
  },
};

messageTypeRegistry.set(MLProcessMapGenerationResponse.$type, MLProcessMapGenerationResponse);

function createBaseProcessMapGenerationCompletionNotification(): ProcessMapGenerationCompletionNotification {
  return {
    $type: "pb.v1alpha2.ProcessMapGenerationCompletionNotification",
    processId: "",
    pathIds: [],
    processMapGcsUri: undefined,
    errorMessage: undefined,
  };
}

export const ProcessMapGenerationCompletionNotification = {
  $type: "pb.v1alpha2.ProcessMapGenerationCompletionNotification" as const,

  encode(message: ProcessMapGenerationCompletionNotification, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.processId !== undefined && message.processId !== "") {
      writer.uint32(10).string(message.processId);
    }
    if (message.pathIds !== undefined && message.pathIds.length !== 0) {
      for (const v of message.pathIds) {
        writer.uint32(18).string(v!);
      }
    }
    if (message.processMapGcsUri !== undefined) {
      writer.uint32(26).string(message.processMapGcsUri);
    }
    if (message.errorMessage !== undefined) {
      writer.uint32(34).string(message.errorMessage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProcessMapGenerationCompletionNotification {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProcessMapGenerationCompletionNotification();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.processId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pathIds!.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.processMapGcsUri = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.errorMessage = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProcessMapGenerationCompletionNotification {
    return {
      $type: ProcessMapGenerationCompletionNotification.$type,
      processId: isSet(object.processId) ? globalThis.String(object.processId) : "",
      pathIds: globalThis.Array.isArray(object?.pathIds) ? object.pathIds.map((e: any) => globalThis.String(e)) : [],
      processMapGcsUri: isSet(object.processMapGcsUri) ? globalThis.String(object.processMapGcsUri) : undefined,
      errorMessage: isSet(object.errorMessage) ? globalThis.String(object.errorMessage) : undefined,
    };
  },

  toJSON(message: ProcessMapGenerationCompletionNotification): unknown {
    const obj: any = {};
    if (message.processId !== undefined && message.processId !== "") {
      obj.processId = message.processId;
    }
    if (message.pathIds?.length) {
      obj.pathIds = message.pathIds;
    }
    if (message.processMapGcsUri !== undefined) {
      obj.processMapGcsUri = message.processMapGcsUri;
    }
    if (message.errorMessage !== undefined) {
      obj.errorMessage = message.errorMessage;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProcessMapGenerationCompletionNotification>, I>>(
    base?: I,
  ): ProcessMapGenerationCompletionNotification {
    return ProcessMapGenerationCompletionNotification.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProcessMapGenerationCompletionNotification>, I>>(
    object: I,
  ): ProcessMapGenerationCompletionNotification {
    const message = createBaseProcessMapGenerationCompletionNotification();
    message.processId = object.processId ?? "";
    message.pathIds = object.pathIds?.map((e) => e) || [];
    message.processMapGcsUri = object.processMapGcsUri ?? undefined;
    message.errorMessage = object.errorMessage ?? undefined;
    return message;
  },
};

messageTypeRegistry.set(ProcessMapGenerationCompletionNotification.$type, ProcessMapGenerationCompletionNotification);

/** ============== Process Intake APIs ============== */
export interface ProcessDiscovery {
  /**
   * This endpoint is used to generate a process configuration and optionally
   * a SOP document.
   */
  GenerateDocumentation(
    request: DeepPartial<GenerateDocumentationRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GenerateDocumentationResponse>;
  /**
   * This endpoint is used to create a process object in database using the
   * user defined process in the request.
   */
  CreateProcess(request: DeepPartial<CreateProcessRequest>, metadata?: grpc.Metadata): Promise<CreateProcessResponse>;
  /** This endpoint is used to update a process object in the database. */
  UpdateProcess(request: DeepPartial<UpdateProcessRequest>, metadata?: grpc.Metadata): Promise<UpdateProcessResponse>;
  /** This endpoint is used to get the process definition for a process. */
  GetProcessDefinition(
    request: DeepPartial<GetProcessDefinitionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetProcessDefinitionResponse>;
  /** This endpoint is used to list all the processes in the database. */
  ListProcesses(request: DeepPartial<ListProcessesRequest>, metadata?: grpc.Metadata): Promise<ListProcessesResponse>;
  /** This endpoint is used to delete a process object in the database. */
  DeleteProcess(request: DeepPartial<DeleteProcessRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /**
   * This endpoint is used to get the process map for a process.
   * If the process map is not generated, then it will be generated
   * and returned after ML pipeline is completed.
   */
  GetProcessMap(request: DeepPartial<GetProcessMapRequest>, metadata?: grpc.Metadata): Promise<GetProcessMapResponse>;
  /** This endpoint is used to list all the process instances for a process. */
  ListProcessInstances(
    request: DeepPartial<ListProcessInstancesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListProcessInstancesResponse>;
  /**
   * This endpoint is used to get a process instance for a process along with
   * all the screenshots for each action.
   */
  GetProcessInstance(
    request: DeepPartial<GetProcessInstanceRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetProcessInstanceResponse>;
  /**
   * This endpoint is used to update a process instance in the database during
   * the process discovery HITL.
   */
  UpdateProcessInstance(
    request: DeepPartial<UpdateProcessInstanceRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateProcessInstanceResponse>;
  /** This endpoint is used to get the variant paths for a process. */
  GetVariantPaths(
    request: DeepPartial<GetVariantPathsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetVariantPathsResponse>;
  /** List all the traces for a process. This returns a paginated list of traces. */
  ListTraces(request: DeepPartial<ListTracesRequest>, metadata?: grpc.Metadata): Promise<ListTracesResponse>;
  /**
   * Get a single trace for a process. This returns the
   * list of actions performed in the trace.
   */
  GetTrace(request: DeepPartial<GetTraceRequest>, metadata?: grpc.Metadata): Promise<GetTraceResponse>;
  /**
   * Delete a trace for a process. This will make sure the trace
   * is unlearned by ML.
   */
  DeleteTrace(request: DeepPartial<DeleteTraceRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /** Update a trace for a process. */
  UpdateTrace(request: DeepPartial<UpdateTraceRequest>, metadata?: grpc.Metadata): Promise<UpdateTraceResponse>;
  /** List the users who create the traces under the process. */
  GetTraceUsers(request: DeepPartial<GetTraceUsersRequest>, metadata?: grpc.Metadata): Promise<GetTraceUsersResponse>;
  /**
   * Deprecated: Use GetProcessMap, ListProcessInstances,
   * GetProcessInstance instead.
   */
  GetProcess(request: DeepPartial<GetProcessRequest>, metadata?: grpc.Metadata): Promise<GetProcessResponse>;
}

export class ProcessDiscoveryClientImpl implements ProcessDiscovery {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GenerateDocumentation = this.GenerateDocumentation.bind(this);
    this.CreateProcess = this.CreateProcess.bind(this);
    this.UpdateProcess = this.UpdateProcess.bind(this);
    this.GetProcessDefinition = this.GetProcessDefinition.bind(this);
    this.ListProcesses = this.ListProcesses.bind(this);
    this.DeleteProcess = this.DeleteProcess.bind(this);
    this.GetProcessMap = this.GetProcessMap.bind(this);
    this.ListProcessInstances = this.ListProcessInstances.bind(this);
    this.GetProcessInstance = this.GetProcessInstance.bind(this);
    this.UpdateProcessInstance = this.UpdateProcessInstance.bind(this);
    this.GetVariantPaths = this.GetVariantPaths.bind(this);
    this.ListTraces = this.ListTraces.bind(this);
    this.GetTrace = this.GetTrace.bind(this);
    this.DeleteTrace = this.DeleteTrace.bind(this);
    this.UpdateTrace = this.UpdateTrace.bind(this);
    this.GetTraceUsers = this.GetTraceUsers.bind(this);
    this.GetProcess = this.GetProcess.bind(this);
  }

  GenerateDocumentation(
    request: DeepPartial<GenerateDocumentationRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GenerateDocumentationResponse> {
    return this.rpc.unary(
      ProcessDiscoveryGenerateDocumentationDesc,
      GenerateDocumentationRequest.fromPartial(request),
      metadata,
    );
  }

  CreateProcess(request: DeepPartial<CreateProcessRequest>, metadata?: grpc.Metadata): Promise<CreateProcessResponse> {
    return this.rpc.unary(ProcessDiscoveryCreateProcessDesc, CreateProcessRequest.fromPartial(request), metadata);
  }

  UpdateProcess(request: DeepPartial<UpdateProcessRequest>, metadata?: grpc.Metadata): Promise<UpdateProcessResponse> {
    return this.rpc.unary(ProcessDiscoveryUpdateProcessDesc, UpdateProcessRequest.fromPartial(request), metadata);
  }

  GetProcessDefinition(
    request: DeepPartial<GetProcessDefinitionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetProcessDefinitionResponse> {
    return this.rpc.unary(
      ProcessDiscoveryGetProcessDefinitionDesc,
      GetProcessDefinitionRequest.fromPartial(request),
      metadata,
    );
  }

  ListProcesses(request: DeepPartial<ListProcessesRequest>, metadata?: grpc.Metadata): Promise<ListProcessesResponse> {
    return this.rpc.unary(ProcessDiscoveryListProcessesDesc, ListProcessesRequest.fromPartial(request), metadata);
  }

  DeleteProcess(request: DeepPartial<DeleteProcessRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(ProcessDiscoveryDeleteProcessDesc, DeleteProcessRequest.fromPartial(request), metadata);
  }

  GetProcessMap(request: DeepPartial<GetProcessMapRequest>, metadata?: grpc.Metadata): Promise<GetProcessMapResponse> {
    return this.rpc.unary(ProcessDiscoveryGetProcessMapDesc, GetProcessMapRequest.fromPartial(request), metadata);
  }

  ListProcessInstances(
    request: DeepPartial<ListProcessInstancesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListProcessInstancesResponse> {
    return this.rpc.unary(
      ProcessDiscoveryListProcessInstancesDesc,
      ListProcessInstancesRequest.fromPartial(request),
      metadata,
    );
  }

  GetProcessInstance(
    request: DeepPartial<GetProcessInstanceRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetProcessInstanceResponse> {
    return this.rpc.unary(
      ProcessDiscoveryGetProcessInstanceDesc,
      GetProcessInstanceRequest.fromPartial(request),
      metadata,
    );
  }

  UpdateProcessInstance(
    request: DeepPartial<UpdateProcessInstanceRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateProcessInstanceResponse> {
    return this.rpc.unary(
      ProcessDiscoveryUpdateProcessInstanceDesc,
      UpdateProcessInstanceRequest.fromPartial(request),
      metadata,
    );
  }

  GetVariantPaths(
    request: DeepPartial<GetVariantPathsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetVariantPathsResponse> {
    return this.rpc.unary(ProcessDiscoveryGetVariantPathsDesc, GetVariantPathsRequest.fromPartial(request), metadata);
  }

  ListTraces(request: DeepPartial<ListTracesRequest>, metadata?: grpc.Metadata): Promise<ListTracesResponse> {
    return this.rpc.unary(ProcessDiscoveryListTracesDesc, ListTracesRequest.fromPartial(request), metadata);
  }

  GetTrace(request: DeepPartial<GetTraceRequest>, metadata?: grpc.Metadata): Promise<GetTraceResponse> {
    return this.rpc.unary(ProcessDiscoveryGetTraceDesc, GetTraceRequest.fromPartial(request), metadata);
  }

  DeleteTrace(request: DeepPartial<DeleteTraceRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(ProcessDiscoveryDeleteTraceDesc, DeleteTraceRequest.fromPartial(request), metadata);
  }

  UpdateTrace(request: DeepPartial<UpdateTraceRequest>, metadata?: grpc.Metadata): Promise<UpdateTraceResponse> {
    return this.rpc.unary(ProcessDiscoveryUpdateTraceDesc, UpdateTraceRequest.fromPartial(request), metadata);
  }

  GetTraceUsers(request: DeepPartial<GetTraceUsersRequest>, metadata?: grpc.Metadata): Promise<GetTraceUsersResponse> {
    return this.rpc.unary(ProcessDiscoveryGetTraceUsersDesc, GetTraceUsersRequest.fromPartial(request), metadata);
  }

  GetProcess(request: DeepPartial<GetProcessRequest>, metadata?: grpc.Metadata): Promise<GetProcessResponse> {
    return this.rpc.unary(ProcessDiscoveryGetProcessDesc, GetProcessRequest.fromPartial(request), metadata);
  }
}

export const ProcessDiscoveryDesc = { serviceName: "pb.v1alpha2.ProcessDiscovery" };

export const ProcessDiscoveryGenerateDocumentationDesc: UnaryMethodDefinitionish = {
  methodName: "GenerateDocumentation",
  service: ProcessDiscoveryDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GenerateDocumentationRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GenerateDocumentationResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ProcessDiscoveryCreateProcessDesc: UnaryMethodDefinitionish = {
  methodName: "CreateProcess",
  service: ProcessDiscoveryDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateProcessRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CreateProcessResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ProcessDiscoveryUpdateProcessDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateProcess",
  service: ProcessDiscoveryDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateProcessRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateProcessResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ProcessDiscoveryGetProcessDefinitionDesc: UnaryMethodDefinitionish = {
  methodName: "GetProcessDefinition",
  service: ProcessDiscoveryDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetProcessDefinitionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetProcessDefinitionResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ProcessDiscoveryListProcessesDesc: UnaryMethodDefinitionish = {
  methodName: "ListProcesses",
  service: ProcessDiscoveryDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListProcessesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListProcessesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ProcessDiscoveryDeleteProcessDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteProcess",
  service: ProcessDiscoveryDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteProcessRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ProcessDiscoveryGetProcessMapDesc: UnaryMethodDefinitionish = {
  methodName: "GetProcessMap",
  service: ProcessDiscoveryDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetProcessMapRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetProcessMapResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ProcessDiscoveryListProcessInstancesDesc: UnaryMethodDefinitionish = {
  methodName: "ListProcessInstances",
  service: ProcessDiscoveryDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListProcessInstancesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListProcessInstancesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ProcessDiscoveryGetProcessInstanceDesc: UnaryMethodDefinitionish = {
  methodName: "GetProcessInstance",
  service: ProcessDiscoveryDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetProcessInstanceRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetProcessInstanceResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ProcessDiscoveryUpdateProcessInstanceDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateProcessInstance",
  service: ProcessDiscoveryDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateProcessInstanceRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateProcessInstanceResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ProcessDiscoveryGetVariantPathsDesc: UnaryMethodDefinitionish = {
  methodName: "GetVariantPaths",
  service: ProcessDiscoveryDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetVariantPathsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetVariantPathsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ProcessDiscoveryListTracesDesc: UnaryMethodDefinitionish = {
  methodName: "ListTraces",
  service: ProcessDiscoveryDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListTracesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListTracesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ProcessDiscoveryGetTraceDesc: UnaryMethodDefinitionish = {
  methodName: "GetTrace",
  service: ProcessDiscoveryDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetTraceRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetTraceResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ProcessDiscoveryDeleteTraceDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteTrace",
  service: ProcessDiscoveryDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteTraceRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ProcessDiscoveryUpdateTraceDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateTrace",
  service: ProcessDiscoveryDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateTraceRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateTraceResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ProcessDiscoveryGetTraceUsersDesc: UnaryMethodDefinitionish = {
  methodName: "GetTraceUsers",
  service: ProcessDiscoveryDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetTraceUsersRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetTraceUsersResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ProcessDiscoveryGetProcessDesc: UnaryMethodDefinitionish = {
  methodName: "GetProcess",
  service: ProcessDiscoveryDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetProcessRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetProcessResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P> | "$type">]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { $type: "google.protobuf.Timestamp", seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
