/* eslint-disable */
// @ts-nocheck
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import { messageTypeRegistry } from "../../typeRegistry";

export const protobufPackage = "pb.v1alpha1";

/** File generated during action recording */
export interface RecordedFile {
  $type?: "pb.v1alpha1.RecordedFile";
  id?: string | undefined;
  url?: string | undefined;
}

/**
 * A snapshot of the UI.
 * Next ID: 6
 */
export interface UiState {
  $type?: "pb.v1alpha1.UiState";
  /** Screenshot image of the viewport. */
  viewportScreenshot?:
    | RecordedFile
    | undefined;
  /**
   * Pixel width of the viewport. Both viewport_width and viewport_height
   * should correspond to the bounding box in element. However, they
   * might not be consistent with the screenshot due to device pixel ratio.
   */
  viewportWidth?:
    | number
    | undefined;
  /** Pixel height of the viewport. See comment above for more info. */
  viewportHeight?:
    | number
    | undefined;
  /**
   * Binary proto of the root element of the UI.
   *
   * @deprecated
   */
  rootElement?:
    | RecordedFile
    | undefined;
  /**
   * Binary proto of the root element wrapper of the UI.
   * The file should be of the ElementWrapper proto
   */
  rootElementWrapper?:
    | RecordedFile
    | undefined;
  /** URL of the page. */
  url?:
    | string
    | undefined;
  /** Time when the snapshot was captured. */
  capturedAt?: Date | undefined;
}

function createBaseRecordedFile(): RecordedFile {
  return { $type: "pb.v1alpha1.RecordedFile", id: "", url: "" };
}

export const RecordedFile = {
  $type: "pb.v1alpha1.RecordedFile" as const,

  encode(message: RecordedFile, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.url !== undefined && message.url !== "") {
      writer.uint32(18).string(message.url);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RecordedFile {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRecordedFile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.url = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RecordedFile {
    return {
      $type: RecordedFile.$type,
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      url: isSet(object.url) ? globalThis.String(object.url) : "",
    };
  },

  toJSON(message: RecordedFile): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    if (message.url !== undefined && message.url !== "") {
      obj.url = message.url;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RecordedFile>, I>>(base?: I): RecordedFile {
    return RecordedFile.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RecordedFile>, I>>(object: I): RecordedFile {
    const message = createBaseRecordedFile();
    message.id = object.id ?? "";
    message.url = object.url ?? "";
    return message;
  },
};

messageTypeRegistry.set(RecordedFile.$type, RecordedFile);

function createBaseUiState(): UiState {
  return {
    $type: "pb.v1alpha1.UiState",
    viewportScreenshot: undefined,
    viewportWidth: 0,
    viewportHeight: 0,
    rootElement: undefined,
    rootElementWrapper: undefined,
    url: "",
    capturedAt: undefined,
  };
}

export const UiState = {
  $type: "pb.v1alpha1.UiState" as const,

  encode(message: UiState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.viewportScreenshot !== undefined) {
      RecordedFile.encode(message.viewportScreenshot, writer.uint32(10).fork()).ldelim();
    }
    if (message.viewportWidth !== undefined && message.viewportWidth !== 0) {
      writer.uint32(16).int32(message.viewportWidth);
    }
    if (message.viewportHeight !== undefined && message.viewportHeight !== 0) {
      writer.uint32(24).int32(message.viewportHeight);
    }
    if (message.rootElement !== undefined) {
      RecordedFile.encode(message.rootElement, writer.uint32(34).fork()).ldelim();
    }
    if (message.rootElementWrapper !== undefined) {
      RecordedFile.encode(message.rootElementWrapper, writer.uint32(50).fork()).ldelim();
    }
    if (message.url !== undefined && message.url !== "") {
      writer.uint32(42).string(message.url);
    }
    if (message.capturedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.capturedAt), writer.uint32(378).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UiState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUiState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.viewportScreenshot = RecordedFile.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.viewportWidth = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.viewportHeight = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.rootElement = RecordedFile.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.rootElementWrapper = RecordedFile.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.url = reader.string();
          continue;
        case 47:
          if (tag !== 378) {
            break;
          }

          message.capturedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UiState {
    return {
      $type: UiState.$type,
      viewportScreenshot: isSet(object.viewportScreenshot)
        ? RecordedFile.fromJSON(object.viewportScreenshot)
        : undefined,
      viewportWidth: isSet(object.viewportWidth) ? globalThis.Number(object.viewportWidth) : 0,
      viewportHeight: isSet(object.viewportHeight) ? globalThis.Number(object.viewportHeight) : 0,
      rootElement: isSet(object.rootElement) ? RecordedFile.fromJSON(object.rootElement) : undefined,
      rootElementWrapper: isSet(object.rootElementWrapper)
        ? RecordedFile.fromJSON(object.rootElementWrapper)
        : undefined,
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      capturedAt: isSet(object.capturedAt) ? fromJsonTimestamp(object.capturedAt) : undefined,
    };
  },

  toJSON(message: UiState): unknown {
    const obj: any = {};
    if (message.viewportScreenshot !== undefined) {
      obj.viewportScreenshot = RecordedFile.toJSON(message.viewportScreenshot);
    }
    if (message.viewportWidth !== undefined && message.viewportWidth !== 0) {
      obj.viewportWidth = Math.round(message.viewportWidth);
    }
    if (message.viewportHeight !== undefined && message.viewportHeight !== 0) {
      obj.viewportHeight = Math.round(message.viewportHeight);
    }
    if (message.rootElement !== undefined) {
      obj.rootElement = RecordedFile.toJSON(message.rootElement);
    }
    if (message.rootElementWrapper !== undefined) {
      obj.rootElementWrapper = RecordedFile.toJSON(message.rootElementWrapper);
    }
    if (message.url !== undefined && message.url !== "") {
      obj.url = message.url;
    }
    if (message.capturedAt !== undefined) {
      obj.capturedAt = message.capturedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UiState>, I>>(base?: I): UiState {
    return UiState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UiState>, I>>(object: I): UiState {
    const message = createBaseUiState();
    message.viewportScreenshot = (object.viewportScreenshot !== undefined && object.viewportScreenshot !== null)
      ? RecordedFile.fromPartial(object.viewportScreenshot)
      : undefined;
    message.viewportWidth = object.viewportWidth ?? 0;
    message.viewportHeight = object.viewportHeight ?? 0;
    message.rootElement = (object.rootElement !== undefined && object.rootElement !== null)
      ? RecordedFile.fromPartial(object.rootElement)
      : undefined;
    message.rootElementWrapper = (object.rootElementWrapper !== undefined && object.rootElementWrapper !== null)
      ? RecordedFile.fromPartial(object.rootElementWrapper)
      : undefined;
    message.url = object.url ?? "";
    message.capturedAt = object.capturedAt ?? undefined;
    return message;
  },
};

messageTypeRegistry.set(UiState.$type, UiState);

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P> | "$type">]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { $type: "google.protobuf.Timestamp", seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
