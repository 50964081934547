import produce from 'immer';
import { Activity } from 'protos/automation_mining/automation_mining';
import {
  DocumentEntity,
  DocumentEntityNormalizedValue,
  DocumentPage,
  DocumentPageToken,
} from 'protos/google/cloud/documentai/v1/document';
import {
  NormalizedVertex,
  Vertex,
} from 'protos/google/cloud/documentai/v1/geometry';
import { PredictionResult } from 'protos/pb/v1alpha2/execution_steps';
import { Task, TaskSTATUS } from 'protos/pb/v1alpha2/tasks_service';
import {
  EntityDataType,
  EntityDetails,
  EntityTypeSchema,
} from 'protos/pb/v1alpha2/workflow_steps_params';
import { Reducer } from 'redux';
import { ReviewTaskActionType } from '../actions/actions.constants';
import {
  getStartIndicesOfSearchedText,
  searchTokensWithBinarySearch,
} from '../../utils/DocumentSearchUtil';
import {
  getBlankNormalizedValue,
  getBottomTextSegment,
  getEntityDetailsFromEntityTypeSchemaMapping,
  getEntityInfoForEntity,
  isShowNormalizedValue,
  processNormalizedValue,
  insertEntityBelowSelectedEntity,
  getDefaultTextSegment,
  getTextFromTextSegments,
  handleMatchedFields,
  handleUnmatchedTarget,
  updateExecutionStep,
  isEntityInDocument,
} from '../../utils/ReviewTaskUtils';
import {
  AutomationProgressStatus,
  DataLoadingStatus,
  EntityFilter,
  HITL_ENTITY_SORT_MODES,
  TimeRange,
} from '../../utils/constants';
import {
  getSelectedTaskDocument,
  removeUnwantedVerticalBars,
} from '../../utils/helpers';
import { v4 as uuidv4 } from 'uuid';
import { deepCloneEntityInfoObject } from '../../utils/cloneObjectUtils';
import {
  removeRowFromRowOrderInfo,
  removeRowsNotPresentInEntityInfoFromRowOrder,
  setRowAndColumnOrderForTask,
} from '../../utils/NestedTableUtils';
import { getEntitiesInSequence } from '../../utils/EntitySortUtils';
import { isNestedEntity } from '../../utils/entities';
import {
  getCollidingCell,
  getCollidingCellTokens,
  getCollidingTokenListToHighlight,
  isHighlightedInsideGivenCoords,
} from '../../utils/TokensHighlightUtils';
import { cloneDeep, isEmpty } from 'lodash';
import { FieldGroupMatch } from 'protos/pb/v1alpha1/actionprocessing';

/**
 * This interface is used to store the match state for each field group match
 * sourceIndex - for matched fields
 * targetIndex - for matched fields
 * unmatchedIndex - for unmatched targets
 */
export interface MatchState {
  sourceIndex?: number; // Index of modified source field
  targetIndex?: number; // Index of modified target field
  unmatchedIndex?: number; // Index of modified unmatched target
}

/**
 * This interface is used for nested entity table layout to store the information of selected parent
 * entity. We have multiple parent entities that have the same type. To show information of all these
 * entities' child in a table format, the details of parent entity is stored using this interface
 */
export interface SelectedParentEntity {
  id: string;
  type: string;
}

/**
 * The purpose of TextSegmentInfo is to store the information about the text segment
 * which is used to render the text in the floating modal.
 */
export interface TextSegmentInfo {
  id: string;
  vertices: Vertex[]; // This is used to show the bounding box on the document (This is based on the complete document) - For Reference BoundingBox.tsx
  pageCorrespondingVertices: Vertex[]; // This is the one which we send to BE according to every page (It will take reference from every page) - For Reference BoundingBox.tsx
  normalizedVertices: NormalizedVertex[]; // This is the vertices in percent - Reference getNormalizedVertices in ReviewTaskUtils.ts
  page: number; // This is the original page number in which the text segment was present
  modifiedPage: number; // This is the modified page number in which the text segment is present after the modification
  text: string; // This is the text in the text segment which can be manually modified by user
  textFromTokens: string; // This is the text in the text segment captured with bounding box and does not change manually
  entityId: string; // This is the entity id to which the text segment belongs
  startIndex: number; // This is the start index of the first token out of all the tokens captured bounding box
  endIndex: number; // This is the end index of the last token out of all the tokens captured bounding box
  lastAdjustedPadding?: number; // This stores the padding calculated as per scale at the time of moving the bounding box
}

/**
 * The purpose of EntityInfo is to store the information about the entity
 * which is used to render the entity in the floating modal and also to
 * update the entity in the task.
 */
export interface EntityInfo {
  id: string;
  isReviewed: boolean; // This is updated when user clicks on confirm or decline in the floating modal
  isModified: boolean; // This is updated when user modifies the entity either by moving the bounding box or manually entering the text in the floating modal
  textSegments: { [id: string]: TextSegmentInfo };
  normalizedValue: DocumentEntityNormalizedValue | undefined; // This is the normalized value of the entity which is sent by ML
  isNestedEntity: boolean; // This is used to keep track if the entity is a nested entity or not
  parentEntityType?: string; // This is used to keep track of the parent entity type if the entity is a nested entity
  confidenceScore?: number; // This is used to keep track of the confidence score of the entity min value 0 and max value 1
  parentEntityId?: string; // This is used to keep track of the parent entity id if the entity is a nested entity
  entities?: EntityInfo[]; // This is used to keep track of entities inside parent entity
  isInDoc: boolean; // This is used to keep track if the entity is present in the document or not
  type: string;
  normalizedEntityType?: EntityDataType; // This is used to keep track of the normalized entity type if the entity is a normalized entity
  extraEntityText?: string;
  isExtra: boolean;
  needAttentionConfidenceScore: number;
  isInsideTable?: boolean;
  error?: string | null; // This is used for checking validation errors
  isNormalizationFailed?: boolean | null; // This is used for checking whether the value in text Segments is successfully normalized or not

  // This will keep track of temp updates to normalized value while user is editing the normalized value
  // We can use useState in component itself to tract temp normalized value updates (whether valid or invalid )
  // but the reason we are keeping this field in
  // the store is to support undo/redo feature which is integrated with store
  normalizedInputValue?: string;

  // this field will contain the overall text value for
  // the entire entity this is same as mentionText
  entityText: string;
  options?: string[]; // This will contain the options for the ENTITY_TYPE_CHOICE entity type.
}

export interface ReviewTaskState {
  task?: Task;
  taskLoadingStatus: DataLoadingStatus;
  taskLoadingError?: string;
  documentActivityType?: Activity;
  selectedTaskEntityInfo: { [id: string]: EntityInfo }; // This is used to keep track of all the entity information to keep track of changes done by user during review
  originalTaskEntityInfo: { [id: string]: EntityInfo }; // This is used to keep track of all the original entity information before any modification
  selectedEntityId?: string; // This hold the entity id of the current selected entity
  selectedEntityInfo?: EntityInfo; // This hold the entity info of the current selected entity
  selectedTextSegmentId?: string; // This hold the text segment id of the current selected text segment
  tokensInDocument: { [page: string]: DocumentPageToken[] }; // This is used to keep track of all the tokens in the document respect to every page
  predictionResult?: PredictionResult; // This is used for classification workflow to store the predicted result
  classificationLabelsList?: string[]; // This is used for classification workflow to store the list of classification labels
  automationProgressStatus: AutomationProgressStatus; // 0 - No automation in progress, 1 - Automation in progress (Accepted case), 2 - Automation in progress (Declined case)
  isAutomationCompleted: boolean;
  automationError?: Error;
  selectedReviewFilterSection?: EntityFilter;
  searchText: string;
  searchedTokensInDocument: { [page: string]: DocumentPageToken[] }; // This is used to keep track of all the searched tokens in the document respect to every page
  searchedTokensStartIndices: number[];
  selectedTokenIndex?: number;
  tokenForHighlight?: DocumentPageToken;
  selectedParentEntityInfo?: SelectedParentEntity; // When any parent entity is selected, this key is used to show table layout
  selectedTableEntitiesInfo: EntityInfo[];
  selectedEntityIdsForAnnotation: string[];
  addLocation: boolean; // This flag is used to append text segments in an existing entity rather than overriding all textSegments
  lastLocatedEntityType?: string;
  columnOrderInfoForTableEntities: { [parentType: string]: EntityInfo[] };
  hiddenEntityTypesForTableAnnotation: { [parentType: string]: string[] };
  rowOrderInfoForTableEntities: { [parentType: string]: string[] };
  isEntitySidePanelCollapsed?: boolean;
  tokenListToHighlight?: DocumentPageToken[];
  tokenListPageToHighlight?: number;
  openAddRowModal: boolean;
  suggestedTableEntitiesInfo: EntityInfo[]; // This is to store entity info for extra annotated cells
  suggestionData?: { cells: Vertex[]; page: number; entityType: string }; // This is to store data for suggestedTableEntitiesInfo
  parentEntityIdsForSuggestionRows: string[]; // This is to keep parent entity ids of each row of suggestedTableEntitiesInfo
  allowEditingTask?: boolean;
  entitySortMode: HITL_ENTITY_SORT_MODES;
  // Activity Monitoring
  // We are using activity monitoring states (reviewStartTime and idleSessions) for both ORBOT and ORBY HITL.
  reviewStartTime?: number; // Timestamp when the user started reviewing the task (in milliseconds)
  idleSessions: TimeRange[];

  // For Hitl
  hitlDataUpdatingStatus: DataLoadingStatus;
  hitlDataUpdatingError?: string;
  // Stores original field matches before any changes for comparison
  originalFieldMatches?: FieldGroupMatch[];
  isModified: boolean;
  // Stores modified states for field group matches, indexed by match number
  modifiedMatchStates: { [matchIndex: number]: MatchState };

  //save task
  saveTaskStatus: DataLoadingStatus;
  saveTaskError?: string;
}

const initialState: ReviewTaskState = {
  taskLoadingStatus: DataLoadingStatus.INITIAL,
  selectedTaskEntityInfo: {},
  originalTaskEntityInfo: {},
  tokensInDocument: {},
  automationProgressStatus: AutomationProgressStatus.DEFAULT,
  isAutomationCompleted: false,
  searchText: '',
  searchedTokensInDocument: {},
  searchedTokensStartIndices: [],
  selectedEntityIdsForAnnotation: [],
  addLocation: false,
  columnOrderInfoForTableEntities: {},
  hiddenEntityTypesForTableAnnotation: {},
  selectedTableEntitiesInfo: [],
  suggestedTableEntitiesInfo: [],
  rowOrderInfoForTableEntities: {},
  isEntitySidePanelCollapsed: false,
  openAddRowModal: false,
  parentEntityIdsForSuggestionRows: [],
  allowEditingTask: false,
  idleSessions: [],
  isModified: false,
  entitySortMode: HITL_ENTITY_SORT_MODES.LOCATION_ORDER,

  hitlDataUpdatingStatus: DataLoadingStatus.INITIAL,
  saveTaskStatus: DataLoadingStatus.INITIAL,
  // This is used to store the original match state for each field group match
  modifiedMatchStates: {},
};

export const reviewTaskReducer: Reducer<ReviewTaskState> = (
  state: ReviewTaskState = initialState,
  action,
) =>
  produce(state, (draft: ReviewTaskState) => {
    switch (action.type) {
      /**
       * GET_TASK_FOR_REVIEW
       */
      case ReviewTaskActionType.GET_TASK_FOR_REVIEW: {
        draft.taskLoadingStatus = DataLoadingStatus.LOADING;
        break;
      }
      /**
       * GET_TASK_FOR_REVIEW_COMPLETED
       */
      case ReviewTaskActionType.GET_TASK_FOR_REVIEW_COMPLETED: {
        const debugLayout = action.debugLayout;
        const task: Task = action.payload;
        const isInferenceEnabled: boolean = action.isInferenceEnabled;
        draft.task = { ...task };

        /**
         * Set originalFieldMatches from either correctedSmartActionResult or smartActionResult
         * First check if correctedSmartActionResult exists (for corrected/reviewed tasks)
         * Otherwise fallback to smartActionResult (for new tasks)
         *
         * This is important because:
         * 1. For reviewed tasks, we want to compare against the last corrected state
         * 2. For new tasks, we compare against the initial ML predictions
         * These comparisons help track what changes the user makes during the review process
         */
        const executionStep = task.executionSteps?.[0];
        const smartActionResult =
          executionStep?.result?.smartActionResult
            ?.correctedSmartActionResult ||
          executionStep?.result?.smartActionResult?.smartActionResult;
        if (smartActionResult) {
          draft.originalFieldMatches =
            smartActionResult.reconcileLineItemsResult?.fieldGroupMatches;
        }

        draft.allowEditingTask = task.status === TaskSTATUS.READY;
        // HERE WE WILL ADD ALL THE ENTITY INFO FROM TASK
        const documentStep = getSelectedTaskDocument(task);
        const documentText = documentStep?.documents?.[0]?.text;
        // Setting tokens in document for each page here
        documentStep?.documents?.[0]?.pages?.map(
          (page: DocumentPage, index: number) => {
            draft.tokensInDocument[index] = page.tokens as DocumentPageToken[];
          },
        );

        if (documentStep?.activity === Activity.CLASSIFY_DOCUMENT) {
          draft.predictionResult = { ...documentStep.predictionResult };
          if (
            documentStep?.options?.[0]?.classification?.classificationLabels
          ) {
            draft.classificationLabelsList = [
              ...documentStep.options[0].classification.classificationLabels,
            ];
          }
        } else {
          // Select the entity type schema mapping in case of extraction based task
          let entityDetails: EntityDetails[] = [];
          if (
            documentStep?.options &&
            documentStep?.options[0]?.entityExtraction?.entitiesDetails &&
            documentStep?.options[0]?.entityExtraction?.entitiesDetails
              ?.length > 0
          ) {
            // If entities details are present then we will use them
            entityDetails =
              documentStep?.options[0]?.entityExtraction?.entitiesDetails ?? [];
          } else if (
            documentStep?.options &&
            documentStep?.options[0]?.entityExtraction
              ?.entityTypeSchemaMapping &&
            Object.keys(
              documentStep?.options[0]?.entityExtraction
                ?.entityTypeSchemaMapping,
            ).length > 0
          ) {
            // Else fallback for the old task to get info from entity type schema mapping
            entityDetails = getEntityDetailsFromEntityTypeSchemaMapping({
              entities: documentStep?.options[0]?.entityExtraction
                ?.entities as string[],
              entityTypeSchemaMapping: documentStep?.options[0]
                ?.entityExtraction?.entityTypeSchemaMapping as unknown as Map<
                string,
                EntityTypeSchema
              >,
            });
          }
          // Here we sort the entities in the sequence of their appearance in the document
          if (documentStep?.documents?.[0]?.entities) {
            const sortedEntities = debugLayout
              ? getEntitiesInSequence([...documentStep.documents[0].entities])
              : documentStep.documents[0].entities;
            // ADDING INFO FOR EACH ENTITY IN THE DOCUMENT
            for (const entity of sortedEntities) {
              if (isNestedEntity(entity)) {
                // Sort nested entities in the same sequence as normal entities
                const sortedNestedEntities = debugLayout
                  ? getEntitiesInSequence([
                      ...(entity.properties as DocumentEntity[]),
                    ])
                  : (entity.properties as DocumentEntity[]);
                // ADDING NESTED ENTITIES IN AN ENTITY TO THE INFO MAP
                for (const nestedEntity of sortedNestedEntities) {
                  // Here if ID is not present in the entity then we will create a random id for the entity
                  if (!nestedEntity.id) {
                    nestedEntity.id = `not_in_doc_id_${uuidv4()}`;
                  }
                  if (entity.id == '') {
                    entity.id = `parent_${uuidv4()}`;
                  }
                  draft.selectedTaskEntityInfo[nestedEntity.id] =
                    getEntityInfoForEntity({
                      entity: nestedEntity,
                      documentText: documentText as string,
                      isNestedEntity: true,
                      parentEntity: entity,
                      task,
                      entityDetails,
                      isInferenceEnabled,
                    });
                  // make a separate copy for originalTaskEntityInfo to avoid mutation
                  draft.originalTaskEntityInfo[nestedEntity.id] =
                    getEntityInfoForEntity({
                      entity: nestedEntity,
                      documentText: documentText as string,
                      isNestedEntity: true,
                      parentEntity: entity,
                      task,
                      entityDetails,
                      isInferenceEnabled,
                    });
                }
              } else {
                // Here if ID is not present in the entity then we will create a random id for the entity
                if (!entity.id) {
                  entity.id = `not_in_doc_id_${uuidv4()}`;
                }
                draft.selectedTaskEntityInfo[entity.id] =
                  getEntityInfoForEntity({
                    entity,
                    documentText: documentText as string,
                    isNestedEntity: false,
                    task,
                    entityDetails,
                    isInferenceEnabled,
                  });
                // make a separate copy for originalTaskEntityInfo to avoid mutation
                draft.originalTaskEntityInfo[entity.id] =
                  getEntityInfoForEntity({
                    entity,
                    documentText: documentText as string,
                    isNestedEntity: false,
                    task,
                    entityDetails,
                    isInferenceEnabled,
                  });
              }
            }
          }
          const textSegmentInfo = getBottomTextSegment(
            Object.values(draft.selectedEntityInfo?.textSegments || {}),
          );
          if (textSegmentInfo) {
            draft.selectedTextSegmentId = textSegmentInfo.id;
          } else {
            draft.selectedTextSegmentId = undefined;
          }
        }
        draft.taskLoadingStatus = DataLoadingStatus.LOADED;
        draft.documentActivityType = documentStep?.activity;
        setRowAndColumnOrderForTask(
          draft.selectedTaskEntityInfo,
          draft.columnOrderInfoForTableEntities,
          draft.rowOrderInfoForTableEntities,
        );
        break;
      }
      /**
       * GET_TASK_FOR_REVIEW_ERROR
       */
      case ReviewTaskActionType.GET_TASK_FOR_REVIEW_ERROR: {
        draft.taskLoadingError = action.payload;
        draft.taskLoadingStatus = DataLoadingStatus.ERROR;
        break;
      }

      case ReviewTaskActionType.SAVE_TASK: {
        draft.saveTaskStatus = DataLoadingStatus.LOADING;
        break;
      }

      case ReviewTaskActionType.SAVE_TASK_COMPLETED: {
        const task: Task = action.payload;
        draft.originalTaskEntityInfo = cloneDeep(draft.selectedTaskEntityInfo); //no change on the entity info as user may change after saving

        const previousExecutionStep = getSelectedTaskDocument(draft.task);
        const executionStep = getSelectedTaskDocument(task);
        if (
          previousExecutionStep?.originalDocuments &&
          executionStep?.originalDocuments
        ) {
          previousExecutionStep.originalDocuments =
            executionStep?.originalDocuments;
        }
        if (
          previousExecutionStep?.documents &&
          previousExecutionStep.documents.length > 0 &&
          executionStep?.documents &&
          executionStep.documents.length > 0
        ) {
          const lastPrevDoc = previousExecutionStep.documents.at(0);
          const lastNewDoc = executionStep.documents.at(0);
          if (lastPrevDoc && lastNewDoc) {
            lastPrevDoc.uri = lastNewDoc.uri;
          }
        }
        draft.task = { ...draft.task, reviews: task.reviews };
        draft.saveTaskStatus = DataLoadingStatus.LOADED;
        delete draft.originalFieldMatches;
        draft.modifiedMatchStates = {};
        draft.isModified = false;
        break;
      }

      case ReviewTaskActionType.SAVE_TASK_ERROR: {
        draft.saveTaskStatus = DataLoadingStatus.ERROR;
        draft.saveTaskError = action.payload;
        break;
      }
      /**
       * SET_SELECTED_ENTITY_ID
       */
      case ReviewTaskActionType.SET_SELECTED_ENTITY_ID: {
        const selectedEntityId: string = action.payload;
        const entityInfo: EntityInfo | undefined =
          draft.selectedTaskEntityInfo[selectedEntityId];

        draft.selectedEntityId = selectedEntityId;
        draft.selectedEntityInfo = entityInfo;
        if (entityInfo) {
          draft.selectedEntityInfo = deepCloneEntityInfoObject(entityInfo);
          const textSegmentInfo = Object.values(entityInfo?.textSegments);
          if (textSegmentInfo) {
            draft.selectedTextSegmentId =
              textSegmentInfo[textSegmentInfo.length - 1]?.id; // Select the last text segment
          } else {
            draft.selectedTextSegmentId = undefined;
          }
          // Clear Nested Table Data
          draft.selectedParentEntityInfo = undefined;
          draft.selectedEntityIdsForAnnotation = [];
          draft.selectedTableEntitiesInfo = [];
        }
        break;
      }
      /**
       * SET_SELECTED_ENTITY_INFO
       */
      case ReviewTaskActionType.SET_SELECTED_ENTITY_INFO: {
        const entityInfo: EntityInfo = action.payload;
        if (entityInfo) {
          draft.selectedEntityId = entityInfo.id;
          draft.selectedEntityInfo = deepCloneEntityInfoObject(entityInfo);
          const textSegmentInfo = Object.values(entityInfo?.textSegments);
          if (textSegmentInfo) {
            draft.selectedTextSegmentId =
              textSegmentInfo[textSegmentInfo.length - 1]?.id; // Select the last text segment
          } else {
            draft.selectedTextSegmentId = undefined;
          }
        }
        break;
      }

      /**
       * COPY_SELECTED_ENTITY
       */
      case ReviewTaskActionType.COPY_SELECTED_ENTITY: {
        const selectedParentEntityId = action.payload;
        const isSuggestion = action.isSuggestion;
        const parentCopyId = selectedParentEntityId + '_copy_' + uuidv4();
        /**
         * Filter the nested entities for the selected parent entity id
         */
        const nestedEntities =
          draft.selectedTableEntitiesInfo?.filter((entity) => {
            return entity?.parentEntityId === selectedParentEntityId;
          }) || [];
        /**
         * Loop through all the nested entities and create a copy of them
         * and add them to the entitiesToCopy object
         * Also update the selectedTaskEntityInfo object with the new entity
         */
        for (const entity of nestedEntities) {
          const selectedEntity = { ...entity };
          if (selectedEntity?.parentEntityId === selectedParentEntityId) {
            const entityCopyId = selectedEntity.id + '_copy_' + uuidv4();
            const copyEntity = { ...selectedEntity };
            copyEntity.id = entityCopyId;
            copyEntity.parentEntityId = parentCopyId;
            copyEntity.isReviewed = false;
            copyEntity.isModified = false;
            copyEntity.isInDoc = false;
            copyEntity.extraEntityText = '';
            copyEntity.normalizedValue = DocumentEntityNormalizedValue.create(
              {},
            );
            copyEntity.confidenceScore = 0;
            copyEntity.textSegments = getDefaultTextSegment(copyEntity.id);
            copyEntity.entityText = '';
            copyEntity.normalizedInputValue = '';
            copyEntity.error = undefined;

            if (
              isShowNormalizedValue(
                selectedEntity.normalizedEntityType ||
                  EntityDataType.ENTITY_TYPE_UNSPECIFIED,
              )
            ) {
              copyEntity.normalizedEntityType =
                selectedEntity.normalizedEntityType;
              copyEntity.normalizedValue = getBlankNormalizedValue(
                selectedEntity.normalizedEntityType,
              );
            }
            if (isSuggestion) {
              // If isSuggestion is true we push copyEntity for extra annotated cells in suggestedTableEntitiesInfo key
              draft.suggestedTableEntitiesInfo?.push(copyEntity);
            } else {
              draft.selectedTableEntitiesInfo?.push(copyEntity);
            }
          }
        }
        if (isSuggestion) {
          // If isSuggestion is true we push parentCopyId for extra annotated cells in parentEntityIdsForSuggestionRows key
          draft.parentEntityIdsForSuggestionRows.push(parentCopyId);
        } else {
          const indexToAddRow = action.indexToAddRow;
          if (indexToAddRow >= 0) {
            // Insert the row at the specified index (indexToAddRow)
            draft.rowOrderInfoForTableEntities[
              draft.selectedParentEntityInfo?.type || ''
            ].splice(indexToAddRow, 0, parentCopyId);
          } else {
            // Add row in row order at the end
            draft.rowOrderInfoForTableEntities[
              draft.selectedParentEntityInfo?.type || ''
            ].push(parentCopyId);
          }
        }
        break;
      }
      /**
       * DELETE_SELECTED_ENTITY
       */
      case ReviewTaskActionType.DELETE_SELECTED_ENTITY: {
        const selectedParentEntityId = action.payload;
        let parentEntityType = '';
        for (const entityId in draft.selectedTaskEntityInfo) {
          const selectedEntity = draft.selectedTaskEntityInfo[entityId];
          if (selectedEntity?.parentEntityId === selectedParentEntityId) {
            // delete this entity only when there is another nested group entity present with other id.
            parentEntityType = selectedEntity.parentEntityType as string;
            for (const eId in draft.selectedTaskEntityInfo) {
              if (
                draft.selectedTaskEntityInfo[eId]?.parentEntityType ==
                  parentEntityType &&
                draft.selectedTaskEntityInfo[eId]?.parentEntityId !=
                  selectedParentEntityId
              ) {
                delete draft.selectedTaskEntityInfo[entityId];
              }
            }
          }
        }
        draft.selectedTableEntitiesInfo =
          draft.selectedTableEntitiesInfo?.filter(
            (e) => e.parentEntityId !== selectedParentEntityId,
          );

        // If a row is deleted, it should be removed from row order of table
        removeRowFromRowOrderInfo(
          draft.rowOrderInfoForTableEntities,
          selectedParentEntityId,
          draft.selectedParentEntityInfo,
        );
        if (draft.selectedParentEntityInfo?.type) {
          const isSelectedParentEntityInfoIdExists =
            draft.rowOrderInfoForTableEntities[
              draft.selectedParentEntityInfo?.type
            ]?.includes(draft.selectedParentEntityInfo?.id);
          if (!isSelectedParentEntityInfoIdExists) {
            draft.selectedParentEntityInfo = {
              id: draft.rowOrderInfoForTableEntities[
                draft.selectedParentEntityInfo.type
              ][0],
              type: draft.selectedParentEntityInfo?.type,
            };
          }
        }
        break;
      }
      /**
       * UPDATE SELECTED ENTITY INFO
       */
      case ReviewTaskActionType.UPDATE_ENTITY_INFO: {
        const entityInfo: EntityInfo = JSON.parse(
          JSON.stringify(action.payload),
        );
        // Update isInDoc flag based on text segments
        entityInfo.isInDoc = isEntityInDocument(entityInfo.textSegments);
        /**
         * Get text from text segments
         */
        entityInfo.entityText =
          getTextFromTextSegments(entityInfo.textSegments) ?? '';
        const text = entityInfo.entityText.trim().replace(/\n/g, '');
        /**
         * Process Normalization value update
         */
        if (
          isShowNormalizedValue(
            entityInfo?.normalizedEntityType as EntityDataType,
          )
        ) {
          const originalNormalizedValue =
            draft.originalTaskEntityInfo[entityInfo.id]?.normalizedValue;
          const originalText = draft.originalTaskEntityInfo[
            entityInfo.id
          ]?.entityText
            ?.trim()
            .replace(/\n/g, '');
          // set default value as false
          entityInfo.isNormalizationFailed = false;
          processNormalizedValue(
            entityInfo,
            text,
            originalNormalizedValue as DocumentEntityNormalizedValue,
            originalText,
          );
        }
        draft.selectedEntityInfo = entityInfo;
        break;
      }
      /**
       * UPDATE SELECTED ENTITY INFO NORMALIZED VALUE
       */
      case ReviewTaskActionType.UPDATE_ENTITY_INFO_NORMALIZED_VALUE: {
        const entityInfo: EntityInfo = action.payload;
        draft.selectedEntityInfo = entityInfo;
        break;
      }
      /**
       * SET SELECTED TEXT SEGMENT ID
       */
      case ReviewTaskActionType.SET_SELECTED_TEXT_SEGMENT_ID: {
        const textSegmentId: string = action.payload;
        draft.selectedTextSegmentId = textSegmentId;
        break;
      }
      /**
       * UPDATE TASK ENTITY INFO
       */
      case ReviewTaskActionType.UPDATE_TASK_ENTITY_INFO: {
        const { id, info } = action.payload;
        draft.selectedEntityInfo = info;
        // This is to prevent adding new entity if it is not present before in the map
        // Use case: On adding row in table modal and if any entity of that row is edited using
        // edit details, if user press this is correct, it updates here in table entity info
        // but should not get added in task entity info until user presses This is correct from table modal
        if (draft.selectedTaskEntityInfo[id]) {
          draft.selectedTaskEntityInfo[id] = info;
        }
        const tableInfoIndex = draft.selectedTableEntitiesInfo.findIndex(
          (e) => e.id === id,
        );
        // Means this entity is also present in table info and needs to be updated there as well
        if (tableInfoIndex >= 0) {
          draft.selectedTableEntitiesInfo?.splice(tableInfoIndex, 1, info);
        }
        break;
      }
      /**
       * CLEAR DATA FOR TASK ANNOTATION
       */
      case ReviewTaskActionType.CLEAR_TASK_ANNOTATION_INFO: {
        draft.selectedTaskEntityInfo = {};
        draft.selectedEntityId = undefined;
        draft.selectedEntityInfo = undefined;
        draft.selectedParentEntityInfo = undefined;
        draft.selectedEntityIdsForAnnotation = [];
        draft.selectedTableEntitiesInfo = [];
        draft.columnOrderInfoForTableEntities = {};
        draft.rowOrderInfoForTableEntities = {};
        draft.hiddenEntityTypesForTableAnnotation = {};
        draft.selectedTextSegmentId = undefined;
        draft.saveTaskStatus = DataLoadingStatus.INITIAL;
        draft.saveTaskError = undefined;
        break;
      }
      /**
       * SET AUTOMATION IN PROGRESS
       */
      case ReviewTaskActionType.SET_AUTOMATION_IN_PRORGRESS: {
        draft.automationProgressStatus = action.payload;
        draft.isAutomationCompleted = false;
        break;
      }
      /**
       * SET AUTOMATION COMPLETED
       */
      case ReviewTaskActionType.SET_AUTOMATION_COMPLETED: {
        draft.isAutomationCompleted = true;
        break;
      }
      /**
       * SET AUTOMATION ERROR
       */
      case ReviewTaskActionType.SET_AUTOMATION_COMPLETED_ERROR: {
        draft.automationProgressStatus = AutomationProgressStatus.DEFAULT;
        draft.isAutomationCompleted = false;
        draft.automationError = action.payload;
        break;
      }
      /**
       * UPDATE TASK CLASSIFICATION LABEL
       */
      case ReviewTaskActionType.UPDATE_CLASSIFICATION_LABEL: {
        const label = action.payload;
        if (draft.predictionResult) {
          draft.predictionResult.classificationLabel = label;
        }
        break;
      }
      /**
       * UPDATE SELECTED REVIEW FILTER SECTION
       */
      case ReviewTaskActionType.UPDATE_SELECTED_REVIEW_FILTER_SECTION: {
        const filter = action.payload;
        draft.selectedReviewFilterSection = filter;
        break;
      }
      /**
       * SET SEARCH STATE & SEARCHED TOKENS IN DOCUMENT
       */
      case ReviewTaskActionType.SET_SEARCH_TEXT: {
        const searchText = action.payload;
        draft.searchText = searchText;
        const document = getSelectedTaskDocument(draft.task as Task)
          ?.documents?.[0];
        {
          /* We first check if the search text has at least 2 letters to begin searching
            This is to reduce the rendering of the tokens, since only the tokens that matches
            this search text will be rendered over the pdf.
        */
        }
        if (document?.text && searchText.length > 1) {
          // Gets the start index array of matched search result
          // Here we are replacing /n with space to enable search across multi lines
          const indices: number[] = getStartIndicesOfSearchedText(
            document.text.toLowerCase().replaceAll('\n', ' '),
            searchText,
          );
          draft.searchedTokensStartIndices = indices;

          Object.keys(draft.tokensInDocument).forEach((key) => {
            // These are tokens for a particular page (key is page no. here starting with 0)
            const pageTokens: DocumentPageToken[] = draft.tokensInDocument[key];

            // skip the iteration if pageTokens is empty
            if (!pageTokens?.length) {
              return;
            }

            // We store previous' pages search result initially
            const searchedTokens: { [key: string]: DocumentPageToken[] } = {
              ...draft.searchedTokensInDocument,
            };

            // Iterate over matched indexes calculated above
            for (const index of indices) {
              const endIndex =
                pageTokens?.[pageTokens.length - 1]?.layout?.textAnchor
                  ?.textSegments?.[0]?.endIndex;
              const startIndex =
                pageTokens?.[0]?.layout?.textAnchor?.textSegments?.[0]
                  .startIndex;
              if (endIndex && index >= endIndex) {
                continue;
              }
              if (startIndex && index + searchText.length <= startIndex) {
                continue;
              }
              // Created a key with the format `${pageNumber}-${matchedStartIndex}`
              // Helpful in rendering the resulted tokens over the pdf in review page
              const searchedTokenKey = `${key}-${index}`;
              const searchedTokensForKey = searchTokensWithBinarySearch(
                pageTokens,
                index,
                searchText,
              );

              // If no tokens are matched and the result is empty, we delete this key from map
              if (searchedTokensForKey.length === 0) {
                delete searchedTokens[searchedTokenKey];
              } else {
                // Otherwise adds them in the corresponding key created above
                searchedTokens[searchedTokenKey] = searchedTokensForKey;
              }
            }
            draft.searchedTokensInDocument = searchedTokens;
          });
        } else {
          draft.searchedTokensInDocument = {};
          draft.searchedTokensStartIndices = [];
        }
        break;
      }
      case ReviewTaskActionType.TOGGLE_SEARCH_RESULT: {
        draft.selectedTokenIndex = action.payload;
        break;
      }
      case ReviewTaskActionType.SET_TOKEN_FOR_HIGHLIGHT: {
        draft.tokenForHighlight = action.payload;
        break;
      }
      // TODO: Add test cases for this action
      case ReviewTaskActionType.SET_SELECTED_PARENT_ENTITY_INFO: {
        const selectedParentEntityInfo: SelectedParentEntity | undefined =
          action.payload;
        const prevSelectedParentEntityInfo = draft.selectedParentEntityInfo;
        draft.selectedParentEntityInfo = selectedParentEntityInfo;
        draft.selectedTableEntitiesInfo = [];

        if (selectedParentEntityInfo) {
          const selectedTableEntitiesInfo = Object.values(
            draft.selectedTaskEntityInfo,
          ).filter((e) => e.parentEntityType === selectedParentEntityInfo.type);

          selectedTableEntitiesInfo.forEach((e: EntityInfo) => {
            draft.selectedTableEntitiesInfo?.push(deepCloneEntityInfoObject(e));
          });

          removeRowsNotPresentInEntityInfoFromRowOrder(
            draft.rowOrderInfoForTableEntities,
            draft.selectedTaskEntityInfo,
            draft.selectedTableEntitiesInfo,
            selectedParentEntityInfo,
          );
        }

        if (prevSelectedParentEntityInfo) {
          const selectedTableEntitiesInfo = Object.values(
            draft.selectedTaskEntityInfo,
          ).filter(
            (e) => e.parentEntityType === prevSelectedParentEntityInfo.type,
          );

          removeRowsNotPresentInEntityInfoFromRowOrder(
            draft.rowOrderInfoForTableEntities,
            draft.selectedTaskEntityInfo,
            selectedTableEntitiesInfo,
            prevSelectedParentEntityInfo,
          );
        }
        draft.selectedEntityId = undefined;
        draft.selectedEntityInfo = undefined;
        draft.selectedEntityIdsForAnnotation = [];
        break;
      }
      case ReviewTaskActionType.SET_SELECTED_ENTITY_IDS_FOR_ANNOTATION: {
        draft.selectedEntityIdsForAnnotation = action.payload;
        break;
      }
      case ReviewTaskActionType.DELETE_TEXT_SEGMENTS_FROM_ENTITIES: {
        const entityIdsToUpdate: string[] = action.payload;
        entityIdsToUpdate.forEach((entityId: string) => {
          const index: number = draft.selectedTableEntitiesInfo.findIndex(
            (e) => e.id === entityId,
          );
          if (index >= 0) {
            const entityInfo = draft.selectedTableEntitiesInfo[index];
            draft.selectedTableEntitiesInfo[index] = {
              ...entityInfo,
              textSegments: getDefaultTextSegment(entityId),
              isInDoc: false,
              entityText: '',
              normalizedValue: getBlankNormalizedValue(
                entityInfo.normalizedEntityType,
              ),
              normalizedInputValue: '',
              extraEntityText: '',
            };
          }
        });
        break;
      }
      case ReviewTaskActionType.UPDATE_ENTITY_INFO_FOR_TABLE_ANNOTATION: {
        const isSuggestion = action.isSuggestion;
        const entityInfo: EntityInfo = JSON.parse(
          JSON.stringify(action.payload),
        );

        // Update isInDoc flag based on text segments
        entityInfo.isInDoc = isEntityInDocument(entityInfo.textSegments);

        const computeNormalizedValue = action.computeNormalizedValue;
        // If isSuggestion is true (extra rows annotated) we get index from suggestedTableEntitiesInfo key
        const index: number = (
          isSuggestion
            ? draft.suggestedTableEntitiesInfo
            : draft.selectedTableEntitiesInfo
        ).findIndex((e) => e.id === entityInfo.id);
        /**
         * Process Normalization value update
         */
        if (action.ignoreVerticalLines) {
          entityInfo.entityText = removeUnwantedVerticalBars(
            entityInfo.entityText,
          );
          entityInfo.textSegments = Object.fromEntries(
            Object.entries(entityInfo.textSegments).map(
              ([key, textSegment]) => [
                key,
                {
                  ...textSegment,
                  text: removeUnwantedVerticalBars(textSegment.text),
                  textFromTokens: removeUnwantedVerticalBars(
                    textSegment.textFromTokens,
                  ),
                },
              ],
            ),
          );
        }
        if (
          isShowNormalizedValue(entityInfo.normalizedEntityType!) &&
          computeNormalizedValue
        ) {
          const text = entityInfo.entityText.trim().replace(/\n/g, '');
          const originalNormalizedValue =
            draft.originalTaskEntityInfo[entityInfo.id]?.normalizedValue;
          const originalText = draft.originalTaskEntityInfo[
            entityInfo.id
          ]?.entityText
            ?.trim()
            .replace(/\n/g, '');
          // set default value as false
          entityInfo.isNormalizationFailed = false;
          processNormalizedValue(
            entityInfo,
            text,
            originalNormalizedValue,
            originalText,
          );
        }

        if (index >= 0) {
          if (isSuggestion) {
            // If isSuggestion is true (extra rows annotated) we update entityInfo in suggestedTableEntitiesInfo key
            draft.suggestedTableEntitiesInfo?.splice(index, 1, entityInfo);
          } else {
            draft.selectedTableEntitiesInfo?.splice(index, 1, entityInfo);
          }
        }
        break;
      }
      case ReviewTaskActionType.CONFIRM_TABLE_ENTITIES_INFO: {
        const selectedParentType = draft.selectedParentEntityInfo?.type ?? '';
        const rowOrderInfoForTableEntities =
          draft.rowOrderInfoForTableEntities[selectedParentType];

        // Set to store the IDs of table rows that should be added
        const tableRowsToAdd: Set<string> = new Set();

        // Maps row IDs (parentEntityId) to a boolean indicating whether the row contains non-empty content
        const rowHasContent: Record<string, boolean> = {};

        // Object to store selected table entities, indexed by their IDs for quick lookup
        const selectedTableEntitiesInfoById: Record<string, EntityInfo> = {};

        // Populate selectedTableEntitiesInfoById and rowHasContent
        draft.selectedTableEntitiesInfo.forEach((e) => {
          // Store each entity in the lookup object
          selectedTableEntitiesInfoById[e.id] = e;
          // Mark the row as having content if the entity text is non-empty
          if (
            (e.entityText?.trim().length ||
              (e.isExtra && e.extraEntityText?.trim())) &&
            e.parentEntityId
          ) {
            rowHasContent[e.parentEntityId] = true;
          }
        });

        // Determine which rows should be added based on their content and order
        rowOrderInfoForTableEntities.forEach((id, index) => {
          // Keep rows that have non-empty content
          if (rowHasContent[id]) {
            tableRowsToAdd.add(id);
          }

          // Edge case: If no rows have been added and this is the last iteration,
          // keep the first row to ensure at least one row remains
          if (
            tableRowsToAdd.size === 0 &&
            index === rowOrderInfoForTableEntities.length - 1
          ) {
            tableRowsToAdd.add(rowOrderInfoForTableEntities[0]);
          }
        });

        const selectedTableEntitiesRemove = Object.values(
          draft.selectedTaskEntityInfo,
        ).filter((entity) => entity.parentEntityType === selectedParentType);

        let lastNestedEntityId =
          selectedTableEntitiesRemove[selectedTableEntitiesRemove.length - 1]
            .id;
        for (const entityInfo of draft.selectedTableEntitiesInfo || []) {
          // Check if tableRowsToAdd includes entityInfo's parentEntityId
          if (tableRowsToAdd.has(entityInfo.parentEntityId || '')) {
            entityInfo.isReviewed = true;
            entityInfo.confidenceScore = 1;
            // if entity already exist no need to insert just below last entity belonging to the same parent
            // because already existing entities have correct order
            if (draft.selectedTaskEntityInfo[entityInfo.id]) {
              draft.selectedTaskEntityInfo[entityInfo.id] = entityInfo;
            } else {
              draft.selectedTaskEntityInfo = insertEntityBelowSelectedEntity(
                { ...draft.selectedTaskEntityInfo },
                lastNestedEntityId,
                entityInfo.id,
                entityInfo,
              );
              lastNestedEntityId = entityInfo.id;
            }
          }
        }

        selectedTableEntitiesRemove.forEach((entity) => {
          if (
            !selectedTableEntitiesInfoById[entity.id] ||
            !tableRowsToAdd.has(entity.parentEntityId ?? '')
          ) {
            // remove all the entity which are not part of
            // table entities anymore (they were deleted) or they belong to rows which are blank
            delete draft.selectedTaskEntityInfo[entity.id];
          }
        });
        draft.rowOrderInfoForTableEntities[selectedParentType] = [
          ...tableRowsToAdd,
        ];
        draft.selectedParentEntityInfo = undefined;
        draft.selectedEntityIdsForAnnotation = [];
        draft.selectedTableEntitiesInfo = [];
        break;
      }
      case ReviewTaskActionType.CLEAR_ALL_TABLE_ENTITIES_INFO: {
        draft.selectedTableEntitiesInfo = draft.selectedTableEntitiesInfo.map(
          (entityInfo) => ({
            ...entityInfo,
            textSegments: getDefaultTextSegment(entityInfo.id),
            extraEntityText: '',
            entityText: '',
            normalizedInputValue: '',
            normalizedValue: getBlankNormalizedValue(
              entityInfo.normalizedEntityType,
            ),
          }),
        );
        break;
      }

      case ReviewTaskActionType.ADD_LOCATION: {
        draft.addLocation = action.payload;
        break;
      }
      case ReviewTaskActionType.LAST_LOCATED_ENTITY_TYPE: {
        draft.lastLocatedEntityType = action.payload;
        break;
      }
      case ReviewTaskActionType.UPDATE_ORDER_OF_TABLE_ENTITIES: {
        const entitiesList: EntityInfo[] = action.payload;
        draft.columnOrderInfoForTableEntities[
          draft.selectedParentEntityInfo?.type || ''
        ] = entitiesList;
        break;
      }
      case ReviewTaskActionType.HIDE_COLUMN_FROM_TABLE_ANNOTATION: {
        const entityId: string = action.payload;
        if (
          draft.hiddenEntityTypesForTableAnnotation[
            draft.selectedParentEntityInfo?.type || ''
          ]
        ) {
          draft.hiddenEntityTypesForTableAnnotation[
            draft.selectedParentEntityInfo?.type || ''
          ].push(entityId);
        } else {
          draft.hiddenEntityTypesForTableAnnotation[
            draft.selectedParentEntityInfo?.type || ''
          ] = [entityId];
        }
        break;
      }
      case ReviewTaskActionType.SHOW_COLUMNS_FOR_TABLE_ANNOTATION: {
        delete draft.hiddenEntityTypesForTableAnnotation[
          draft.selectedParentEntityInfo?.type || ''
        ];
        break;
      }
      case ReviewTaskActionType.UPDATE_ORDER_OF_TABLE_ROWS: {
        draft.rowOrderInfoForTableEntities[
          draft.selectedParentEntityInfo?.type || ''
        ] = action.payload;
        break;
      }
      case ReviewTaskActionType.COLLAPSE_ENTITY_SIDE_PANEL: {
        draft.isEntitySidePanelCollapsed = action.payload;
        break;
      }
      case ReviewTaskActionType.SET_TOKEN_LIST_TO_HIGHLIGHT: {
        // If any simple notes entity or any child notes entity is selected, we do not want to show
        // click and drag highlights to create bounding boxes
        const isNotesEntitySelected =
          draft.selectedEntityInfo?.isExtra ||
          (draft.selectedEntityIdsForAnnotation.length === 1 &&
            draft.selectedTableEntitiesInfo.find(
              (e) => e.id === draft.selectedEntityIdsForAnnotation[0],
            )?.isExtra);
        if (!action.payload || isNotesEntitySelected) {
          draft.tokenListToHighlight = undefined;
          draft.tokenListPageToHighlight = undefined;
        } else if (
          draft.selectedEntityId ||
          draft.selectedEntityIdsForAnnotation.length === 1
        ) {
          const document = getSelectedTaskDocument(draft?.task)?.documents?.[0];
          const tables = document?.pages?.[action.page]?.tables;

          const collidingTable = tables?.find((table) => {
            return isHighlightedInsideGivenCoords(
              action.payload,
              table?.layout?.boundingPoly?.vertices as Vertex[],
            );
          });

          const collidingCell = getCollidingCell(
            collidingTable!,
            action.payload,
          );
          const tokens: DocumentPageToken[] = getCollidingTokenListToHighlight(
            action.payload,
            collidingCell
              ? getCollidingCellTokens(
                  collidingCell?.layout?.boundingPoly?.vertices as Vertex[],
                  draft.tokensInDocument[action.page],
                )
              : draft.tokensInDocument[action.page],
          );
          draft.tokenListToHighlight = tokens;
          draft.tokenListPageToHighlight = action.page;
        }
        break;
      }
      case ReviewTaskActionType.OPEN_ADD_ROW_MODAL: {
        // If modal close Reset states
        if (action.payload === false) {
          draft.suggestedTableEntitiesInfo = [];
          draft.suggestionData = undefined;
          draft.parentEntityIdsForSuggestionRows = [];
          // Get the selected row
          const selectedRow = draft.selectedTableEntitiesInfo?.find(
            (e) => e.id === draft.selectedEntityIdsForAnnotation[0],
          );
          // Set lastLocatedEntityType with selectedRow?.type and set selectedEntityIdsForAnnotation empty
          draft.lastLocatedEntityType = selectedRow?.type;
          draft.selectedEntityIdsForAnnotation = [];
        }
        draft.openAddRowModal = action.payload;
        break;
      }

      case ReviewTaskActionType.SET_SUGGESTION_DATA: {
        draft.suggestionData = action.payload;
        break;
      }

      case ReviewTaskActionType.ADD_SUGGESTED_ROWS: {
        // Add suggestedTableEntitiesInfo to selectedTableEntitiesInfo
        draft.selectedTableEntitiesInfo.push(
          ...draft.suggestedTableEntitiesInfo,
        );
        // Add parentEntityIdsForSuggestionRows to rowOrderInfoForTableEntities
        draft.rowOrderInfoForTableEntities[
          draft.selectedParentEntityInfo?.type || ''
        ].push(...draft.parentEntityIdsForSuggestionRows);
        break;
      }
      case ReviewTaskActionType.ALLOW_EDITING_TASK: {
        draft.allowEditingTask = action.payload;
        break;
      }

      // For activity monitoring
      case ReviewTaskActionType.RECORD_IDLE_SESSION: {
        draft.idleSessions.push(action.payload);
        break;
      }
      case ReviewTaskActionType.REVIEW_START_TIME: {
        draft.reviewStartTime = Date.now();
        break;
      }

      // For ORBOT HITL
      case ReviewTaskActionType.UPDATE_TASK:
        draft.hitlDataUpdatingStatus = DataLoadingStatus.LOADING;
        break;
      case ReviewTaskActionType.UPDATE_TASK_COMPLETED:
        draft.hitlDataUpdatingStatus = DataLoadingStatus.LOADED;
        break;
      case ReviewTaskActionType.UPDATE_TASK_ERROR:
        draft.hitlDataUpdatingStatus = DataLoadingStatus.ERROR;
        draft.hitlDataUpdatingError = action.payload;
        break;
      case ReviewTaskActionType.UPDATE_MATCHES: {
        const { matches, stepIndex } = action.payload;
        const originalMatches = draft.originalFieldMatches;
        // Skip processing if no original matches to compare against
        if (!originalMatches?.length) {
          break;
        }

        // Compare and track changes
        matches?.forEach((newMatch: FieldGroupMatch, index: number) => {
          // Get the original match state for comparison
          const originalMatch = originalMatches[index];

          // Handle matched fields case - when both new and original matches have 'match' property
          if (newMatch.match) {
            // Compare source and target fields between new and original matches
            // Updates modifiedMatchStates if changes are detected
            // This tracks when matched fields are modified or reordered
            draft.modifiedMatchStates = handleMatchedFields(
              draft.modifiedMatchStates,
              index,
              newMatch.match,
              originalMatch.match,
            );
          }
          // Handle unmatched target/source cases
          else if (newMatch.unmatchedTarget || newMatch.unmatchedSource) {
            // Compare unmatched targets/sources between new and original matches
            // Updates modifiedMatchStates if changes are detected
            draft.modifiedMatchStates = handleUnmatchedTarget(
              draft.modifiedMatchStates,
              index,
              newMatch.unmatchedTarget || newMatch.unmatchedSource,
              originalMatch?.unmatchedTarget || originalMatch?.unmatchedSource,
            );
          }
        });

        // Update modification status and execution step
        draft.isModified = !isEmpty(draft.modifiedMatchStates);
        const executionSteps = draft.task?.executionSteps;
        if (executionSteps) {
          executionSteps[stepIndex] = updateExecutionStep(
            executionSteps[stepIndex],
            matches,
          );
        }

        break;
      }
      case ReviewTaskActionType.SET_ENTITY_SORT_MODE: {
        draft.entitySortMode = action.payload;
        break;
      }

      /**
       * CLEAR STATE
       */
      case ReviewTaskActionType.CLEAR: {
        draft.task = undefined;
        draft.isEntitySidePanelCollapsed = false;
        draft.allowEditingTask = false;
        draft.taskLoadingStatus = DataLoadingStatus.INITIAL;
        draft.taskLoadingError = undefined;
        draft.documentActivityType = undefined;
        draft.selectedTaskEntityInfo = {};
        draft.originalTaskEntityInfo = {};
        draft.selectedEntityId = undefined;
        draft.selectedEntityInfo = undefined;
        draft.selectedTextSegmentId = undefined;
        draft.tokensInDocument = {};
        draft.searchedTokensStartIndices = [];
        draft.searchedTokensInDocument = {};
        draft.predictionResult = undefined;
        draft.classificationLabelsList = undefined;
        draft.reviewStartTime = undefined;
        draft.idleSessions = [];
        draft.automationProgressStatus = AutomationProgressStatus.DEFAULT;
        draft.isAutomationCompleted = false;
        draft.automationError = undefined;
        draft.saveTaskStatus = DataLoadingStatus.INITIAL;
        draft.saveTaskError = undefined;
        draft.addLocation = false;
        draft.columnOrderInfoForTableEntities = {};
        draft.hiddenEntityTypesForTableAnnotation = {};
        draft.selectedEntityIdsForAnnotation = [];
        draft.selectedTableEntitiesInfo = [];
        draft.selectedReviewFilterSection = undefined;
        draft.searchText = '';
        draft.searchedTokensInDocument = {};
        // ORBOT HITL states
        draft.hitlDataUpdatingStatus = DataLoadingStatus.INITIAL;
        draft.isModified = false;
        delete draft.originalFieldMatches;
        draft.modifiedMatchStates = {};
        break;
      }
      default:
        break;
    }
  });
