/* eslint-disable */
// @ts-nocheck
import _m0 from "protobufjs/minimal";
import { messageTypeRegistry } from "../../typeRegistry";

export const protobufPackage = "pb.v1alpha1";

/**
 * Data model for a function that can passed around for generation and execution.
 * Next ID: 4
 */
export interface Function {
  $type?: "pb.v1alpha1.Function";
  /** NL description that produced the function */
  description?:
    | string
    | undefined;
  /**
   * list of parameter names in function signature; order matters
   * This is not the same as the name of the variable input
   */
  paramNames?:
    | string[]
    | undefined;
  /** TypeScript function body */
  body?: string | undefined;
}

function createBaseFunction(): Function {
  return { $type: "pb.v1alpha1.Function", description: "", paramNames: [], body: "" };
}

export const Function = {
  $type: "pb.v1alpha1.Function" as const,

  encode(message: Function, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.description !== undefined && message.description !== "") {
      writer.uint32(10).string(message.description);
    }
    if (message.paramNames !== undefined && message.paramNames.length !== 0) {
      for (const v of message.paramNames) {
        writer.uint32(18).string(v!);
      }
    }
    if (message.body !== undefined && message.body !== "") {
      writer.uint32(26).string(message.body);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Function {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFunction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.description = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.paramNames!.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.body = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Function {
    return {
      $type: Function.$type,
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      paramNames: globalThis.Array.isArray(object?.paramNames)
        ? object.paramNames.map((e: any) => globalThis.String(e))
        : [],
      body: isSet(object.body) ? globalThis.String(object.body) : "",
    };
  },

  toJSON(message: Function): unknown {
    const obj: any = {};
    if (message.description !== undefined && message.description !== "") {
      obj.description = message.description;
    }
    if (message.paramNames?.length) {
      obj.paramNames = message.paramNames;
    }
    if (message.body !== undefined && message.body !== "") {
      obj.body = message.body;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Function>, I>>(base?: I): Function {
    return Function.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Function>, I>>(object: I): Function {
    const message = createBaseFunction();
    message.description = object.description ?? "";
    message.paramNames = object.paramNames?.map((e) => e) || [];
    message.body = object.body ?? "";
    return message;
  },
};

messageTypeRegistry.set(Function.$type, Function);

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P> | "$type">]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
