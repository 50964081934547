/* eslint-disable */
// @ts-nocheck
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal";
import { EmailLabel, GmailLabel } from "../../automation_mining/ontology/data_models";
import { Timestamp } from "../../google/protobuf/timestamp";
import { messageTypeRegistry } from "../../typeRegistry";

export const protobufPackage = "pb.v1alpha2";

/** Do not use: For backward compatibility, will be removed once all logic is migrated to GetEmailLabelsRequest. */
export interface GetGmailLabelsRequest {
  $type?: "pb.v1alpha2.GetGmailLabelsRequest";
}

export interface GetEmailLabelsRequest {
  $type?: "pb.v1alpha2.GetEmailLabelsRequest";
  mailType?:
    | GetEmailLabelsRequestMailType
    | undefined;
  /**
   * Only used for outlook mail type
   * This is the user id of the outlook account which is used only
   * when its application credentials and not a SSO login
   */
  microsoftUserId?:
    | string
    | undefined;
  /**
   * Only used for outlook mail type
   * This is the application config id of the outlook account which is used only
   * when its application credentials and not a SSO login
   */
  microsoftApplicationConfigId?: string | undefined;
}

export enum GetEmailLabelsRequestMailType {
  /** UNSPECIFIED - Default value if none is specified. */
  UNSPECIFIED = 0,
  GMAIL = 1,
  OUTLOOK = 2,
  UNRECOGNIZED = -1,
}

export function getEmailLabelsRequestMailTypeFromJSON(object: any): GetEmailLabelsRequestMailType {
  switch (object) {
    case 0:
    case "MAIL_TYPE_UNSPECIFIED":
      return GetEmailLabelsRequestMailType.UNSPECIFIED;
    case 1:
    case "MAIL_TYPE_GMAIL":
      return GetEmailLabelsRequestMailType.GMAIL;
    case 2:
    case "MAIL_TYPE_OUTLOOK":
      return GetEmailLabelsRequestMailType.OUTLOOK;
    case -1:
    case "UNRECOGNIZED":
    default:
      return GetEmailLabelsRequestMailType.UNRECOGNIZED;
  }
}

export function getEmailLabelsRequestMailTypeToJSON(object: GetEmailLabelsRequestMailType): string {
  switch (object) {
    case GetEmailLabelsRequestMailType.UNSPECIFIED:
      return "MAIL_TYPE_UNSPECIFIED";
    case GetEmailLabelsRequestMailType.GMAIL:
      return "MAIL_TYPE_GMAIL";
    case GetEmailLabelsRequestMailType.OUTLOOK:
      return "MAIL_TYPE_OUTLOOK";
    case GetEmailLabelsRequestMailType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Do not use: For backward compatibility, will be removed once all logic is migrated to GetEmailLabelsResponse. */
export interface GetGmailLabelsResponse {
  $type?: "pb.v1alpha2.GetGmailLabelsResponse";
  labels?: GmailLabel[] | undefined;
}

export interface GetEmailLabelsResponse {
  $type?: "pb.v1alpha2.GetEmailLabelsResponse";
  labels?: EmailLabel[] | undefined;
}

export interface GetScheduleTasksRequest {
  $type?: "pb.v1alpha2.GetScheduleTasksRequest";
  orgResourceName?: string | undefined;
  pageNo?: number | undefined;
}

export interface GetScheduleTasksResponse {
  $type?: "pb.v1alpha2.GetScheduleTasksResponse";
  tasks?: ScheduleTask[] | undefined;
}

export interface ScheduleTask {
  $type?: "pb.v1alpha2.ScheduleTask";
  name?: string | undefined;
  uploadTime?: Date | undefined;
  orbyScheduledTime?: Date | undefined;
  workflowName?: string | undefined;
  priority?: number | undefined;
  status?: string | undefined;
}

export interface GetSignedGCSUriRequest {
  $type?: "pb.v1alpha2.GetSignedGCSUriRequest";
  uri?: string | undefined;
}

export interface GetSignedGCSUriResponse {
  $type?: "pb.v1alpha2.GetSignedGCSUriResponse";
  signedUri?: string | undefined;
}

export interface GetOnedriveFilesRequest {
  $type?: "pb.v1alpha2.GetOnedriveFilesRequest";
  /** If not provided, the root folder will be used */
  parentId?:
    | string
    | undefined;
  /** The microsoft user id of the user */
  microsoftUserId?: string | undefined;
  microsoftApplicationConfigId?:
    | string
    | undefined;
  /**
   * The next link to get the next page of files
   * If this is not provided, there are no more files to get
   * If this is provided, the client should make a new request
   * with this next link
   */
  nextLink?: string | undefined;
}

export interface GetOnedriveFilesResponse {
  $type?: "pb.v1alpha2.GetOnedriveFilesResponse";
  files?:
    | OnedriveFile[]
    | undefined;
  /**
   * The next link to get the next page of files
   * If this is not provided, there are no more files to get
   * If this is provided, the client should make a new request
   * with this next link
   */
  nextLink?: string | undefined;
}

export interface OnedriveFile {
  $type?: "pb.v1alpha2.OnedriveFile";
  name?: string | undefined;
  id?: string | undefined;
  parentId?: string | undefined;
  webUrl?:
    | string
    | undefined;
  /**
   * The mime type of the file
   * This will be empty for folders
   */
  mimeType?: string | undefined;
  isFolder?:
    | boolean
    | undefined;
  /**
   * The path of the file
   * This will be / if the file or folder is in the root folder
   * For child folders, it will be the path from the root folder
   * For example, for a file in the folder "Parent Folder", the
   * path will be "/Parent Folder/File Name"
   */
  path?: string | undefined;
}

function createBaseGetGmailLabelsRequest(): GetGmailLabelsRequest {
  return { $type: "pb.v1alpha2.GetGmailLabelsRequest" };
}

export const GetGmailLabelsRequest = {
  $type: "pb.v1alpha2.GetGmailLabelsRequest" as const,

  encode(_: GetGmailLabelsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetGmailLabelsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetGmailLabelsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetGmailLabelsRequest {
    return { $type: GetGmailLabelsRequest.$type };
  },

  toJSON(_: GetGmailLabelsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetGmailLabelsRequest>, I>>(base?: I): GetGmailLabelsRequest {
    return GetGmailLabelsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetGmailLabelsRequest>, I>>(_: I): GetGmailLabelsRequest {
    const message = createBaseGetGmailLabelsRequest();
    return message;
  },
};

messageTypeRegistry.set(GetGmailLabelsRequest.$type, GetGmailLabelsRequest);

function createBaseGetEmailLabelsRequest(): GetEmailLabelsRequest {
  return {
    $type: "pb.v1alpha2.GetEmailLabelsRequest",
    mailType: 0,
    microsoftUserId: undefined,
    microsoftApplicationConfigId: undefined,
  };
}

export const GetEmailLabelsRequest = {
  $type: "pb.v1alpha2.GetEmailLabelsRequest" as const,

  encode(message: GetEmailLabelsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.mailType !== undefined && message.mailType !== 0) {
      writer.uint32(8).int32(message.mailType);
    }
    if (message.microsoftUserId !== undefined) {
      writer.uint32(18).string(message.microsoftUserId);
    }
    if (message.microsoftApplicationConfigId !== undefined) {
      writer.uint32(26).string(message.microsoftApplicationConfigId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetEmailLabelsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetEmailLabelsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.mailType = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.microsoftUserId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.microsoftApplicationConfigId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetEmailLabelsRequest {
    return {
      $type: GetEmailLabelsRequest.$type,
      mailType: isSet(object.mailType) ? getEmailLabelsRequestMailTypeFromJSON(object.mailType) : 0,
      microsoftUserId: isSet(object.microsoftUserId) ? globalThis.String(object.microsoftUserId) : undefined,
      microsoftApplicationConfigId: isSet(object.microsoftApplicationConfigId)
        ? globalThis.String(object.microsoftApplicationConfigId)
        : undefined,
    };
  },

  toJSON(message: GetEmailLabelsRequest): unknown {
    const obj: any = {};
    if (message.mailType !== undefined && message.mailType !== 0) {
      obj.mailType = getEmailLabelsRequestMailTypeToJSON(message.mailType);
    }
    if (message.microsoftUserId !== undefined) {
      obj.microsoftUserId = message.microsoftUserId;
    }
    if (message.microsoftApplicationConfigId !== undefined) {
      obj.microsoftApplicationConfigId = message.microsoftApplicationConfigId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetEmailLabelsRequest>, I>>(base?: I): GetEmailLabelsRequest {
    return GetEmailLabelsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetEmailLabelsRequest>, I>>(object: I): GetEmailLabelsRequest {
    const message = createBaseGetEmailLabelsRequest();
    message.mailType = object.mailType ?? 0;
    message.microsoftUserId = object.microsoftUserId ?? undefined;
    message.microsoftApplicationConfigId = object.microsoftApplicationConfigId ?? undefined;
    return message;
  },
};

messageTypeRegistry.set(GetEmailLabelsRequest.$type, GetEmailLabelsRequest);

function createBaseGetGmailLabelsResponse(): GetGmailLabelsResponse {
  return { $type: "pb.v1alpha2.GetGmailLabelsResponse", labels: [] };
}

export const GetGmailLabelsResponse = {
  $type: "pb.v1alpha2.GetGmailLabelsResponse" as const,

  encode(message: GetGmailLabelsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.labels !== undefined && message.labels.length !== 0) {
      for (const v of message.labels) {
        GmailLabel.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetGmailLabelsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetGmailLabelsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.labels!.push(GmailLabel.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetGmailLabelsResponse {
    return {
      $type: GetGmailLabelsResponse.$type,
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => GmailLabel.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetGmailLabelsResponse): unknown {
    const obj: any = {};
    if (message.labels?.length) {
      obj.labels = message.labels.map((e) => GmailLabel.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetGmailLabelsResponse>, I>>(base?: I): GetGmailLabelsResponse {
    return GetGmailLabelsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetGmailLabelsResponse>, I>>(object: I): GetGmailLabelsResponse {
    const message = createBaseGetGmailLabelsResponse();
    message.labels = object.labels?.map((e) => GmailLabel.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(GetGmailLabelsResponse.$type, GetGmailLabelsResponse);

function createBaseGetEmailLabelsResponse(): GetEmailLabelsResponse {
  return { $type: "pb.v1alpha2.GetEmailLabelsResponse", labels: [] };
}

export const GetEmailLabelsResponse = {
  $type: "pb.v1alpha2.GetEmailLabelsResponse" as const,

  encode(message: GetEmailLabelsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.labels !== undefined && message.labels.length !== 0) {
      for (const v of message.labels) {
        EmailLabel.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetEmailLabelsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetEmailLabelsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.labels!.push(EmailLabel.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetEmailLabelsResponse {
    return {
      $type: GetEmailLabelsResponse.$type,
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => EmailLabel.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetEmailLabelsResponse): unknown {
    const obj: any = {};
    if (message.labels?.length) {
      obj.labels = message.labels.map((e) => EmailLabel.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetEmailLabelsResponse>, I>>(base?: I): GetEmailLabelsResponse {
    return GetEmailLabelsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetEmailLabelsResponse>, I>>(object: I): GetEmailLabelsResponse {
    const message = createBaseGetEmailLabelsResponse();
    message.labels = object.labels?.map((e) => EmailLabel.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(GetEmailLabelsResponse.$type, GetEmailLabelsResponse);

function createBaseGetScheduleTasksRequest(): GetScheduleTasksRequest {
  return { $type: "pb.v1alpha2.GetScheduleTasksRequest", orgResourceName: "", pageNo: 0 };
}

export const GetScheduleTasksRequest = {
  $type: "pb.v1alpha2.GetScheduleTasksRequest" as const,

  encode(message: GetScheduleTasksRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orgResourceName !== undefined && message.orgResourceName !== "") {
      writer.uint32(10).string(message.orgResourceName);
    }
    if (message.pageNo !== undefined && message.pageNo !== 0) {
      writer.uint32(16).int32(message.pageNo);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetScheduleTasksRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetScheduleTasksRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orgResourceName = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.pageNo = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetScheduleTasksRequest {
    return {
      $type: GetScheduleTasksRequest.$type,
      orgResourceName: isSet(object.orgResourceName) ? globalThis.String(object.orgResourceName) : "",
      pageNo: isSet(object.pageNo) ? globalThis.Number(object.pageNo) : 0,
    };
  },

  toJSON(message: GetScheduleTasksRequest): unknown {
    const obj: any = {};
    if (message.orgResourceName !== undefined && message.orgResourceName !== "") {
      obj.orgResourceName = message.orgResourceName;
    }
    if (message.pageNo !== undefined && message.pageNo !== 0) {
      obj.pageNo = Math.round(message.pageNo);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetScheduleTasksRequest>, I>>(base?: I): GetScheduleTasksRequest {
    return GetScheduleTasksRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetScheduleTasksRequest>, I>>(object: I): GetScheduleTasksRequest {
    const message = createBaseGetScheduleTasksRequest();
    message.orgResourceName = object.orgResourceName ?? "";
    message.pageNo = object.pageNo ?? 0;
    return message;
  },
};

messageTypeRegistry.set(GetScheduleTasksRequest.$type, GetScheduleTasksRequest);

function createBaseGetScheduleTasksResponse(): GetScheduleTasksResponse {
  return { $type: "pb.v1alpha2.GetScheduleTasksResponse", tasks: [] };
}

export const GetScheduleTasksResponse = {
  $type: "pb.v1alpha2.GetScheduleTasksResponse" as const,

  encode(message: GetScheduleTasksResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tasks !== undefined && message.tasks.length !== 0) {
      for (const v of message.tasks) {
        ScheduleTask.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetScheduleTasksResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetScheduleTasksResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tasks!.push(ScheduleTask.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetScheduleTasksResponse {
    return {
      $type: GetScheduleTasksResponse.$type,
      tasks: globalThis.Array.isArray(object?.tasks) ? object.tasks.map((e: any) => ScheduleTask.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetScheduleTasksResponse): unknown {
    const obj: any = {};
    if (message.tasks?.length) {
      obj.tasks = message.tasks.map((e) => ScheduleTask.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetScheduleTasksResponse>, I>>(base?: I): GetScheduleTasksResponse {
    return GetScheduleTasksResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetScheduleTasksResponse>, I>>(object: I): GetScheduleTasksResponse {
    const message = createBaseGetScheduleTasksResponse();
    message.tasks = object.tasks?.map((e) => ScheduleTask.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(GetScheduleTasksResponse.$type, GetScheduleTasksResponse);

function createBaseScheduleTask(): ScheduleTask {
  return {
    $type: "pb.v1alpha2.ScheduleTask",
    name: "",
    uploadTime: undefined,
    orbyScheduledTime: undefined,
    workflowName: "",
    priority: 0,
    status: "",
  };
}

export const ScheduleTask = {
  $type: "pb.v1alpha2.ScheduleTask" as const,

  encode(message: ScheduleTask, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== undefined && message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.uploadTime !== undefined) {
      Timestamp.encode(toTimestamp(message.uploadTime), writer.uint32(18).fork()).ldelim();
    }
    if (message.orbyScheduledTime !== undefined) {
      Timestamp.encode(toTimestamp(message.orbyScheduledTime), writer.uint32(26).fork()).ldelim();
    }
    if (message.workflowName !== undefined && message.workflowName !== "") {
      writer.uint32(34).string(message.workflowName);
    }
    if (message.priority !== undefined && message.priority !== 0) {
      writer.uint32(40).int64(message.priority);
    }
    if (message.status !== undefined && message.status !== "") {
      writer.uint32(50).string(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScheduleTask {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScheduleTask();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.uploadTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.orbyScheduledTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.workflowName = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.priority = longToNumber(reader.int64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.status = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScheduleTask {
    return {
      $type: ScheduleTask.$type,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      uploadTime: isSet(object.uploadTime) ? fromJsonTimestamp(object.uploadTime) : undefined,
      orbyScheduledTime: isSet(object.orbyScheduledTime) ? fromJsonTimestamp(object.orbyScheduledTime) : undefined,
      workflowName: isSet(object.workflowName) ? globalThis.String(object.workflowName) : "",
      priority: isSet(object.priority) ? globalThis.Number(object.priority) : 0,
      status: isSet(object.status) ? globalThis.String(object.status) : "",
    };
  },

  toJSON(message: ScheduleTask): unknown {
    const obj: any = {};
    if (message.name !== undefined && message.name !== "") {
      obj.name = message.name;
    }
    if (message.uploadTime !== undefined) {
      obj.uploadTime = message.uploadTime.toISOString();
    }
    if (message.orbyScheduledTime !== undefined) {
      obj.orbyScheduledTime = message.orbyScheduledTime.toISOString();
    }
    if (message.workflowName !== undefined && message.workflowName !== "") {
      obj.workflowName = message.workflowName;
    }
    if (message.priority !== undefined && message.priority !== 0) {
      obj.priority = Math.round(message.priority);
    }
    if (message.status !== undefined && message.status !== "") {
      obj.status = message.status;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScheduleTask>, I>>(base?: I): ScheduleTask {
    return ScheduleTask.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ScheduleTask>, I>>(object: I): ScheduleTask {
    const message = createBaseScheduleTask();
    message.name = object.name ?? "";
    message.uploadTime = object.uploadTime ?? undefined;
    message.orbyScheduledTime = object.orbyScheduledTime ?? undefined;
    message.workflowName = object.workflowName ?? "";
    message.priority = object.priority ?? 0;
    message.status = object.status ?? "";
    return message;
  },
};

messageTypeRegistry.set(ScheduleTask.$type, ScheduleTask);

function createBaseGetSignedGCSUriRequest(): GetSignedGCSUriRequest {
  return { $type: "pb.v1alpha2.GetSignedGCSUriRequest", uri: "" };
}

export const GetSignedGCSUriRequest = {
  $type: "pb.v1alpha2.GetSignedGCSUriRequest" as const,

  encode(message: GetSignedGCSUriRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uri !== undefined && message.uri !== "") {
      writer.uint32(10).string(message.uri);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSignedGCSUriRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSignedGCSUriRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uri = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSignedGCSUriRequest {
    return { $type: GetSignedGCSUriRequest.$type, uri: isSet(object.uri) ? globalThis.String(object.uri) : "" };
  },

  toJSON(message: GetSignedGCSUriRequest): unknown {
    const obj: any = {};
    if (message.uri !== undefined && message.uri !== "") {
      obj.uri = message.uri;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSignedGCSUriRequest>, I>>(base?: I): GetSignedGCSUriRequest {
    return GetSignedGCSUriRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSignedGCSUriRequest>, I>>(object: I): GetSignedGCSUriRequest {
    const message = createBaseGetSignedGCSUriRequest();
    message.uri = object.uri ?? "";
    return message;
  },
};

messageTypeRegistry.set(GetSignedGCSUriRequest.$type, GetSignedGCSUriRequest);

function createBaseGetSignedGCSUriResponse(): GetSignedGCSUriResponse {
  return { $type: "pb.v1alpha2.GetSignedGCSUriResponse", signedUri: "" };
}

export const GetSignedGCSUriResponse = {
  $type: "pb.v1alpha2.GetSignedGCSUriResponse" as const,

  encode(message: GetSignedGCSUriResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.signedUri !== undefined && message.signedUri !== "") {
      writer.uint32(10).string(message.signedUri);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSignedGCSUriResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSignedGCSUriResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.signedUri = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSignedGCSUriResponse {
    return {
      $type: GetSignedGCSUriResponse.$type,
      signedUri: isSet(object.signedUri) ? globalThis.String(object.signedUri) : "",
    };
  },

  toJSON(message: GetSignedGCSUriResponse): unknown {
    const obj: any = {};
    if (message.signedUri !== undefined && message.signedUri !== "") {
      obj.signedUri = message.signedUri;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSignedGCSUriResponse>, I>>(base?: I): GetSignedGCSUriResponse {
    return GetSignedGCSUriResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSignedGCSUriResponse>, I>>(object: I): GetSignedGCSUriResponse {
    const message = createBaseGetSignedGCSUriResponse();
    message.signedUri = object.signedUri ?? "";
    return message;
  },
};

messageTypeRegistry.set(GetSignedGCSUriResponse.$type, GetSignedGCSUriResponse);

function createBaseGetOnedriveFilesRequest(): GetOnedriveFilesRequest {
  return {
    $type: "pb.v1alpha2.GetOnedriveFilesRequest",
    parentId: undefined,
    microsoftUserId: "",
    microsoftApplicationConfigId: "",
    nextLink: undefined,
  };
}

export const GetOnedriveFilesRequest = {
  $type: "pb.v1alpha2.GetOnedriveFilesRequest" as const,

  encode(message: GetOnedriveFilesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.parentId !== undefined) {
      writer.uint32(10).string(message.parentId);
    }
    if (message.microsoftUserId !== undefined && message.microsoftUserId !== "") {
      writer.uint32(18).string(message.microsoftUserId);
    }
    if (message.microsoftApplicationConfigId !== undefined && message.microsoftApplicationConfigId !== "") {
      writer.uint32(26).string(message.microsoftApplicationConfigId);
    }
    if (message.nextLink !== undefined) {
      writer.uint32(34).string(message.nextLink);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOnedriveFilesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOnedriveFilesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.parentId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.microsoftUserId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.microsoftApplicationConfigId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.nextLink = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetOnedriveFilesRequest {
    return {
      $type: GetOnedriveFilesRequest.$type,
      parentId: isSet(object.parentId) ? globalThis.String(object.parentId) : undefined,
      microsoftUserId: isSet(object.microsoftUserId) ? globalThis.String(object.microsoftUserId) : "",
      microsoftApplicationConfigId: isSet(object.microsoftApplicationConfigId)
        ? globalThis.String(object.microsoftApplicationConfigId)
        : "",
      nextLink: isSet(object.nextLink) ? globalThis.String(object.nextLink) : undefined,
    };
  },

  toJSON(message: GetOnedriveFilesRequest): unknown {
    const obj: any = {};
    if (message.parentId !== undefined) {
      obj.parentId = message.parentId;
    }
    if (message.microsoftUserId !== undefined && message.microsoftUserId !== "") {
      obj.microsoftUserId = message.microsoftUserId;
    }
    if (message.microsoftApplicationConfigId !== undefined && message.microsoftApplicationConfigId !== "") {
      obj.microsoftApplicationConfigId = message.microsoftApplicationConfigId;
    }
    if (message.nextLink !== undefined) {
      obj.nextLink = message.nextLink;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetOnedriveFilesRequest>, I>>(base?: I): GetOnedriveFilesRequest {
    return GetOnedriveFilesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetOnedriveFilesRequest>, I>>(object: I): GetOnedriveFilesRequest {
    const message = createBaseGetOnedriveFilesRequest();
    message.parentId = object.parentId ?? undefined;
    message.microsoftUserId = object.microsoftUserId ?? "";
    message.microsoftApplicationConfigId = object.microsoftApplicationConfigId ?? "";
    message.nextLink = object.nextLink ?? undefined;
    return message;
  },
};

messageTypeRegistry.set(GetOnedriveFilesRequest.$type, GetOnedriveFilesRequest);

function createBaseGetOnedriveFilesResponse(): GetOnedriveFilesResponse {
  return { $type: "pb.v1alpha2.GetOnedriveFilesResponse", files: [], nextLink: undefined };
}

export const GetOnedriveFilesResponse = {
  $type: "pb.v1alpha2.GetOnedriveFilesResponse" as const,

  encode(message: GetOnedriveFilesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.files !== undefined && message.files.length !== 0) {
      for (const v of message.files) {
        OnedriveFile.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    if (message.nextLink !== undefined) {
      writer.uint32(18).string(message.nextLink);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOnedriveFilesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOnedriveFilesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.files!.push(OnedriveFile.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nextLink = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetOnedriveFilesResponse {
    return {
      $type: GetOnedriveFilesResponse.$type,
      files: globalThis.Array.isArray(object?.files) ? object.files.map((e: any) => OnedriveFile.fromJSON(e)) : [],
      nextLink: isSet(object.nextLink) ? globalThis.String(object.nextLink) : undefined,
    };
  },

  toJSON(message: GetOnedriveFilesResponse): unknown {
    const obj: any = {};
    if (message.files?.length) {
      obj.files = message.files.map((e) => OnedriveFile.toJSON(e));
    }
    if (message.nextLink !== undefined) {
      obj.nextLink = message.nextLink;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetOnedriveFilesResponse>, I>>(base?: I): GetOnedriveFilesResponse {
    return GetOnedriveFilesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetOnedriveFilesResponse>, I>>(object: I): GetOnedriveFilesResponse {
    const message = createBaseGetOnedriveFilesResponse();
    message.files = object.files?.map((e) => OnedriveFile.fromPartial(e)) || [];
    message.nextLink = object.nextLink ?? undefined;
    return message;
  },
};

messageTypeRegistry.set(GetOnedriveFilesResponse.$type, GetOnedriveFilesResponse);

function createBaseOnedriveFile(): OnedriveFile {
  return {
    $type: "pb.v1alpha2.OnedriveFile",
    name: "",
    id: "",
    parentId: "",
    webUrl: "",
    mimeType: "",
    isFolder: false,
    path: "",
  };
}

export const OnedriveFile = {
  $type: "pb.v1alpha2.OnedriveFile" as const,

  encode(message: OnedriveFile, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== undefined && message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(18).string(message.id);
    }
    if (message.parentId !== undefined && message.parentId !== "") {
      writer.uint32(26).string(message.parentId);
    }
    if (message.webUrl !== undefined && message.webUrl !== "") {
      writer.uint32(34).string(message.webUrl);
    }
    if (message.mimeType !== undefined && message.mimeType !== "") {
      writer.uint32(42).string(message.mimeType);
    }
    if (message.isFolder !== undefined && message.isFolder !== false) {
      writer.uint32(48).bool(message.isFolder);
    }
    if (message.path !== undefined && message.path !== "") {
      writer.uint32(58).string(message.path);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OnedriveFile {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOnedriveFile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.parentId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.webUrl = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.mimeType = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.isFolder = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.path = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OnedriveFile {
    return {
      $type: OnedriveFile.$type,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      parentId: isSet(object.parentId) ? globalThis.String(object.parentId) : "",
      webUrl: isSet(object.webUrl) ? globalThis.String(object.webUrl) : "",
      mimeType: isSet(object.mimeType) ? globalThis.String(object.mimeType) : "",
      isFolder: isSet(object.isFolder) ? globalThis.Boolean(object.isFolder) : false,
      path: isSet(object.path) ? globalThis.String(object.path) : "",
    };
  },

  toJSON(message: OnedriveFile): unknown {
    const obj: any = {};
    if (message.name !== undefined && message.name !== "") {
      obj.name = message.name;
    }
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    if (message.parentId !== undefined && message.parentId !== "") {
      obj.parentId = message.parentId;
    }
    if (message.webUrl !== undefined && message.webUrl !== "") {
      obj.webUrl = message.webUrl;
    }
    if (message.mimeType !== undefined && message.mimeType !== "") {
      obj.mimeType = message.mimeType;
    }
    if (message.isFolder !== undefined && message.isFolder !== false) {
      obj.isFolder = message.isFolder;
    }
    if (message.path !== undefined && message.path !== "") {
      obj.path = message.path;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OnedriveFile>, I>>(base?: I): OnedriveFile {
    return OnedriveFile.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OnedriveFile>, I>>(object: I): OnedriveFile {
    const message = createBaseOnedriveFile();
    message.name = object.name ?? "";
    message.id = object.id ?? "";
    message.parentId = object.parentId ?? "";
    message.webUrl = object.webUrl ?? "";
    message.mimeType = object.mimeType ?? "";
    message.isFolder = object.isFolder ?? false;
    message.path = object.path ?? "";
    return message;
  },
};

messageTypeRegistry.set(OnedriveFile.$type, OnedriveFile);

export interface Utility {
  /** Do not use will be deprecated later once all logic is migrated to GetEmailLabels */
  GetGmailLabels(
    request: DeepPartial<GetGmailLabelsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetGmailLabelsResponse>;
  GetEmailLabels(
    request: DeepPartial<GetEmailLabelsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetEmailLabelsResponse>;
  /** Only for internal use */
  GetScheduleTasks(
    request: DeepPartial<GetScheduleTasksRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetScheduleTasksResponse>;
  /** Only for internal use for debugging and by orby.ai users */
  GetSignedGCSUri(
    request: DeepPartial<GetSignedGCSUriRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetSignedGCSUriResponse>;
  /**
   * This will only be used to get onedrive files for
   * MSFT users who have logged in using application credentials
   *
   * Use the existing Onedrive file picker for users who have logged
   * in using SSO
   */
  GetOnedriveFiles(
    request: DeepPartial<GetOnedriveFilesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetOnedriveFilesResponse>;
}

export class UtilityClientImpl implements Utility {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetGmailLabels = this.GetGmailLabels.bind(this);
    this.GetEmailLabels = this.GetEmailLabels.bind(this);
    this.GetScheduleTasks = this.GetScheduleTasks.bind(this);
    this.GetSignedGCSUri = this.GetSignedGCSUri.bind(this);
    this.GetOnedriveFiles = this.GetOnedriveFiles.bind(this);
  }

  GetGmailLabels(
    request: DeepPartial<GetGmailLabelsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetGmailLabelsResponse> {
    return this.rpc.unary(UtilityGetGmailLabelsDesc, GetGmailLabelsRequest.fromPartial(request), metadata);
  }

  GetEmailLabels(
    request: DeepPartial<GetEmailLabelsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetEmailLabelsResponse> {
    return this.rpc.unary(UtilityGetEmailLabelsDesc, GetEmailLabelsRequest.fromPartial(request), metadata);
  }

  GetScheduleTasks(
    request: DeepPartial<GetScheduleTasksRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetScheduleTasksResponse> {
    return this.rpc.unary(UtilityGetScheduleTasksDesc, GetScheduleTasksRequest.fromPartial(request), metadata);
  }

  GetSignedGCSUri(
    request: DeepPartial<GetSignedGCSUriRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetSignedGCSUriResponse> {
    return this.rpc.unary(UtilityGetSignedGCSUriDesc, GetSignedGCSUriRequest.fromPartial(request), metadata);
  }

  GetOnedriveFiles(
    request: DeepPartial<GetOnedriveFilesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetOnedriveFilesResponse> {
    return this.rpc.unary(UtilityGetOnedriveFilesDesc, GetOnedriveFilesRequest.fromPartial(request), metadata);
  }
}

export const UtilityDesc = { serviceName: "pb.v1alpha2.Utility" };

export const UtilityGetGmailLabelsDesc: UnaryMethodDefinitionish = {
  methodName: "GetGmailLabels",
  service: UtilityDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetGmailLabelsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetGmailLabelsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UtilityGetEmailLabelsDesc: UnaryMethodDefinitionish = {
  methodName: "GetEmailLabels",
  service: UtilityDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetEmailLabelsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetEmailLabelsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UtilityGetScheduleTasksDesc: UnaryMethodDefinitionish = {
  methodName: "GetScheduleTasks",
  service: UtilityDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetScheduleTasksRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetScheduleTasksResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UtilityGetSignedGCSUriDesc: UnaryMethodDefinitionish = {
  methodName: "GetSignedGCSUri",
  service: UtilityDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetSignedGCSUriRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetSignedGCSUriResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UtilityGetOnedriveFilesDesc: UnaryMethodDefinitionish = {
  methodName: "GetOnedriveFiles",
  service: UtilityDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetOnedriveFilesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetOnedriveFilesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P> | "$type">]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { $type: "google.protobuf.Timestamp", seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
