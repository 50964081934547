/* eslint-disable */
// @ts-nocheck
import _m0 from "protobufjs/minimal";
import { messageTypeRegistry } from "../../typeRegistry";
import { Document } from "./document";
import { Element, ElementLocator } from "./element";
import {
  BoolField,
  BoolFieldValue,
  DateField,
  DateFieldValue,
  FloatField,
  FloatFieldValue,
  IntegerField,
  IntegerFieldValue,
  MoneyField,
  MoneyFieldValue,
  SelectField,
  SelectFieldValue,
  TextField,
  TextFieldValue,
} from "./field";

export const protobufPackage = "pb.v1alpha1";

/**
 * Types of external resources that can be referenced in workflows.
 * They could represent different components of web pages/desktop UI that actions
 * can interact with or extract data from during automation.
 */
export enum ResourceType {
  UNSPECIFIED = 0,
  ELEMENT = 1,
  ELEMENT_LOCATOR = 2,
  DOCUMENT = 3,
  UNRECOGNIZED = -1,
}

export function resourceTypeFromJSON(object: any): ResourceType {
  switch (object) {
    case 0:
    case "RESOURCE_TYPE_UNSPECIFIED":
      return ResourceType.UNSPECIFIED;
    case 1:
    case "ELEMENT":
      return ResourceType.ELEMENT;
    case 2:
    case "ELEMENT_LOCATOR":
      return ResourceType.ELEMENT_LOCATOR;
    case 3:
    case "DOCUMENT":
      return ResourceType.DOCUMENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ResourceType.UNRECOGNIZED;
  }
}

export function resourceTypeToJSON(object: ResourceType): string {
  switch (object) {
    case ResourceType.UNSPECIFIED:
      return "RESOURCE_TYPE_UNSPECIFIED";
    case ResourceType.ELEMENT:
      return "ELEMENT";
    case ResourceType.ELEMENT_LOCATOR:
      return "ELEMENT_LOCATOR";
    case ResourceType.DOCUMENT:
      return "DOCUMENT";
    case ResourceType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Data model for variable types and values for workflows.
 *
 * There are two categories of variables we expose in the UI:
 * 1. Workflow action output variables
 * For most actions, there is either no output or the type can be inferred from the action,
 * so we don't need to explicitly store the variable type.
 * The exceptions are:
 * a. Extraction (either from web page or document)
 * b. JSFunction action for custom logic
 * c. Other smart actions
 * In these cases, we need to explicitly define the variable type and store in the Action.
 * Smart actions currently use FieldGroup to define output schema, the type definition should be
 * expressive enough to map to FieldGroup and vice versa.
 *
 * 2. Workflow environment variables
 * User can provide override values for these variables to use in an execution,
 * or it can be passed from one process to another.
 *
 * Next ID: 12
 */
export interface VariableType {
  $type?: "pb.v1alpha1.VariableType";
  text?: TextField | undefined;
  integer?: IntegerField | undefined;
  float?: FloatField | undefined;
  boolean?: BoolField | undefined;
  date?: DateField | undefined;
  money?: MoneyField | undefined;
  select?: SelectField | undefined;
  record?: RecordType | undefined;
  array?: ArrayType | undefined;
  resource?: ResourceType | undefined;
}

export interface RecordType {
  $type?: "pb.v1alpha1.RecordType";
  fields?: { [key: string]: VariableType } | undefined;
}

export interface RecordTypeFieldsEntry {
  $type?: "pb.v1alpha1.RecordType.FieldsEntry";
  key: string;
  value?: VariableType | undefined;
}

export interface ArrayType {
  $type?: "pb.v1alpha1.ArrayType";
  entryType?: VariableType | undefined;
}

/**
 * Represents a variable and contains its value.
 * Next ID: 14
 */
export interface VariableValue {
  $type?: "pb.v1alpha1.VariableValue";
  text?: TextFieldValue | undefined;
  integer?: IntegerFieldValue | undefined;
  float?: FloatFieldValue | undefined;
  boolean?: BoolFieldValue | undefined;
  date?: DateFieldValue | undefined;
  money?: MoneyFieldValue | undefined;
  select?: SelectFieldValue | undefined;
  record?: RecordValue | undefined;
  array?: ArrayValue | undefined;
  element?: Element | undefined;
  elementLocator?: ElementLocator | undefined;
  document?: Document | undefined;
}

export interface RecordValue {
  $type?: "pb.v1alpha1.RecordValue";
  fields?: { [key: string]: VariableValue } | undefined;
}

export interface RecordValueFieldsEntry {
  $type?: "pb.v1alpha1.RecordValue.FieldsEntry";
  key: string;
  value?: VariableValue | undefined;
}

export interface ArrayValue {
  $type?: "pb.v1alpha1.ArrayValue";
  entries?: VariableValue[] | undefined;
}

/** A variable is a named variable type and its value. */
export interface Variable {
  $type?: "pb.v1alpha1.Variable";
  name?: string | undefined;
  type?: VariableType | undefined;
  value?: VariableValue | undefined;
}

function createBaseVariableType(): VariableType {
  return {
    $type: "pb.v1alpha1.VariableType",
    text: undefined,
    integer: undefined,
    float: undefined,
    boolean: undefined,
    date: undefined,
    money: undefined,
    select: undefined,
    record: undefined,
    array: undefined,
    resource: undefined,
  };
}

export const VariableType = {
  $type: "pb.v1alpha1.VariableType" as const,

  encode(message: VariableType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.text !== undefined) {
      TextField.encode(message.text, writer.uint32(10).fork()).ldelim();
    }
    if (message.integer !== undefined) {
      IntegerField.encode(message.integer, writer.uint32(18).fork()).ldelim();
    }
    if (message.float !== undefined) {
      FloatField.encode(message.float, writer.uint32(26).fork()).ldelim();
    }
    if (message.boolean !== undefined) {
      BoolField.encode(message.boolean, writer.uint32(34).fork()).ldelim();
    }
    if (message.date !== undefined) {
      DateField.encode(message.date, writer.uint32(42).fork()).ldelim();
    }
    if (message.money !== undefined) {
      MoneyField.encode(message.money, writer.uint32(50).fork()).ldelim();
    }
    if (message.select !== undefined) {
      SelectField.encode(message.select, writer.uint32(58).fork()).ldelim();
    }
    if (message.record !== undefined) {
      RecordType.encode(message.record, writer.uint32(66).fork()).ldelim();
    }
    if (message.array !== undefined) {
      ArrayType.encode(message.array, writer.uint32(74).fork()).ldelim();
    }
    if (message.resource !== undefined) {
      writer.uint32(80).int32(message.resource);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VariableType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVariableType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.text = TextField.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.integer = IntegerField.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.float = FloatField.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.boolean = BoolField.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.date = DateField.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.money = MoneyField.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.select = SelectField.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.record = RecordType.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.array = ArrayType.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.resource = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VariableType {
    return {
      $type: VariableType.$type,
      text: isSet(object.text) ? TextField.fromJSON(object.text) : undefined,
      integer: isSet(object.integer) ? IntegerField.fromJSON(object.integer) : undefined,
      float: isSet(object.float) ? FloatField.fromJSON(object.float) : undefined,
      boolean: isSet(object.boolean) ? BoolField.fromJSON(object.boolean) : undefined,
      date: isSet(object.date) ? DateField.fromJSON(object.date) : undefined,
      money: isSet(object.money) ? MoneyField.fromJSON(object.money) : undefined,
      select: isSet(object.select) ? SelectField.fromJSON(object.select) : undefined,
      record: isSet(object.record) ? RecordType.fromJSON(object.record) : undefined,
      array: isSet(object.array) ? ArrayType.fromJSON(object.array) : undefined,
      resource: isSet(object.resource) ? resourceTypeFromJSON(object.resource) : undefined,
    };
  },

  toJSON(message: VariableType): unknown {
    const obj: any = {};
    if (message.text !== undefined) {
      obj.text = TextField.toJSON(message.text);
    }
    if (message.integer !== undefined) {
      obj.integer = IntegerField.toJSON(message.integer);
    }
    if (message.float !== undefined) {
      obj.float = FloatField.toJSON(message.float);
    }
    if (message.boolean !== undefined) {
      obj.boolean = BoolField.toJSON(message.boolean);
    }
    if (message.date !== undefined) {
      obj.date = DateField.toJSON(message.date);
    }
    if (message.money !== undefined) {
      obj.money = MoneyField.toJSON(message.money);
    }
    if (message.select !== undefined) {
      obj.select = SelectField.toJSON(message.select);
    }
    if (message.record !== undefined) {
      obj.record = RecordType.toJSON(message.record);
    }
    if (message.array !== undefined) {
      obj.array = ArrayType.toJSON(message.array);
    }
    if (message.resource !== undefined) {
      obj.resource = resourceTypeToJSON(message.resource);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VariableType>, I>>(base?: I): VariableType {
    return VariableType.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VariableType>, I>>(object: I): VariableType {
    const message = createBaseVariableType();
    message.text = (object.text !== undefined && object.text !== null) ? TextField.fromPartial(object.text) : undefined;
    message.integer = (object.integer !== undefined && object.integer !== null)
      ? IntegerField.fromPartial(object.integer)
      : undefined;
    message.float = (object.float !== undefined && object.float !== null)
      ? FloatField.fromPartial(object.float)
      : undefined;
    message.boolean = (object.boolean !== undefined && object.boolean !== null)
      ? BoolField.fromPartial(object.boolean)
      : undefined;
    message.date = (object.date !== undefined && object.date !== null) ? DateField.fromPartial(object.date) : undefined;
    message.money = (object.money !== undefined && object.money !== null)
      ? MoneyField.fromPartial(object.money)
      : undefined;
    message.select = (object.select !== undefined && object.select !== null)
      ? SelectField.fromPartial(object.select)
      : undefined;
    message.record = (object.record !== undefined && object.record !== null)
      ? RecordType.fromPartial(object.record)
      : undefined;
    message.array = (object.array !== undefined && object.array !== null)
      ? ArrayType.fromPartial(object.array)
      : undefined;
    message.resource = object.resource ?? undefined;
    return message;
  },
};

messageTypeRegistry.set(VariableType.$type, VariableType);

function createBaseRecordType(): RecordType {
  return { $type: "pb.v1alpha1.RecordType", fields: {} };
}

export const RecordType = {
  $type: "pb.v1alpha1.RecordType" as const,

  encode(message: RecordType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.fields || {}).forEach(([key, value]) => {
      RecordTypeFieldsEntry.encode(
        { $type: "pb.v1alpha1.RecordType.FieldsEntry", key: key as any, value },
        writer.uint32(18).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RecordType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRecordType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = RecordTypeFieldsEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.fields![entry2.key] = entry2.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RecordType {
    return {
      $type: RecordType.$type,
      fields: isObject(object.fields)
        ? Object.entries(object.fields).reduce<{ [key: string]: VariableType }>((acc, [key, value]) => {
          acc[key] = VariableType.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: RecordType): unknown {
    const obj: any = {};
    if (message.fields) {
      const entries = Object.entries(message.fields);
      if (entries.length > 0) {
        obj.fields = {};
        entries.forEach(([k, v]) => {
          obj.fields[k] = VariableType.toJSON(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RecordType>, I>>(base?: I): RecordType {
    return RecordType.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RecordType>, I>>(object: I): RecordType {
    const message = createBaseRecordType();
    message.fields = Object.entries(object.fields ?? {}).reduce<{ [key: string]: VariableType }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = VariableType.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

messageTypeRegistry.set(RecordType.$type, RecordType);

function createBaseRecordTypeFieldsEntry(): RecordTypeFieldsEntry {
  return { $type: "pb.v1alpha1.RecordType.FieldsEntry", key: "", value: undefined };
}

export const RecordTypeFieldsEntry = {
  $type: "pb.v1alpha1.RecordType.FieldsEntry" as const,

  encode(message: RecordTypeFieldsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      VariableType.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RecordTypeFieldsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRecordTypeFieldsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = VariableType.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RecordTypeFieldsEntry {
    return {
      $type: RecordTypeFieldsEntry.$type,
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? VariableType.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: RecordTypeFieldsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = VariableType.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RecordTypeFieldsEntry>, I>>(base?: I): RecordTypeFieldsEntry {
    return RecordTypeFieldsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RecordTypeFieldsEntry>, I>>(object: I): RecordTypeFieldsEntry {
    const message = createBaseRecordTypeFieldsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? VariableType.fromPartial(object.value)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(RecordTypeFieldsEntry.$type, RecordTypeFieldsEntry);

function createBaseArrayType(): ArrayType {
  return { $type: "pb.v1alpha1.ArrayType", entryType: undefined };
}

export const ArrayType = {
  $type: "pb.v1alpha1.ArrayType" as const,

  encode(message: ArrayType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.entryType !== undefined) {
      VariableType.encode(message.entryType, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArrayType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArrayType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.entryType = VariableType.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ArrayType {
    return {
      $type: ArrayType.$type,
      entryType: isSet(object.entryType) ? VariableType.fromJSON(object.entryType) : undefined,
    };
  },

  toJSON(message: ArrayType): unknown {
    const obj: any = {};
    if (message.entryType !== undefined) {
      obj.entryType = VariableType.toJSON(message.entryType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ArrayType>, I>>(base?: I): ArrayType {
    return ArrayType.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArrayType>, I>>(object: I): ArrayType {
    const message = createBaseArrayType();
    message.entryType = (object.entryType !== undefined && object.entryType !== null)
      ? VariableType.fromPartial(object.entryType)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(ArrayType.$type, ArrayType);

function createBaseVariableValue(): VariableValue {
  return {
    $type: "pb.v1alpha1.VariableValue",
    text: undefined,
    integer: undefined,
    float: undefined,
    boolean: undefined,
    date: undefined,
    money: undefined,
    select: undefined,
    record: undefined,
    array: undefined,
    element: undefined,
    elementLocator: undefined,
    document: undefined,
  };
}

export const VariableValue = {
  $type: "pb.v1alpha1.VariableValue" as const,

  encode(message: VariableValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.text !== undefined) {
      TextFieldValue.encode(message.text, writer.uint32(10).fork()).ldelim();
    }
    if (message.integer !== undefined) {
      IntegerFieldValue.encode(message.integer, writer.uint32(18).fork()).ldelim();
    }
    if (message.float !== undefined) {
      FloatFieldValue.encode(message.float, writer.uint32(26).fork()).ldelim();
    }
    if (message.boolean !== undefined) {
      BoolFieldValue.encode(message.boolean, writer.uint32(34).fork()).ldelim();
    }
    if (message.date !== undefined) {
      DateFieldValue.encode(message.date, writer.uint32(42).fork()).ldelim();
    }
    if (message.money !== undefined) {
      MoneyFieldValue.encode(message.money, writer.uint32(50).fork()).ldelim();
    }
    if (message.select !== undefined) {
      SelectFieldValue.encode(message.select, writer.uint32(58).fork()).ldelim();
    }
    if (message.record !== undefined) {
      RecordValue.encode(message.record, writer.uint32(66).fork()).ldelim();
    }
    if (message.array !== undefined) {
      ArrayValue.encode(message.array, writer.uint32(74).fork()).ldelim();
    }
    if (message.element !== undefined) {
      Element.encode(message.element, writer.uint32(82).fork()).ldelim();
    }
    if (message.elementLocator !== undefined) {
      ElementLocator.encode(message.elementLocator, writer.uint32(90).fork()).ldelim();
    }
    if (message.document !== undefined) {
      Document.encode(message.document, writer.uint32(98).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VariableValue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVariableValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.text = TextFieldValue.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.integer = IntegerFieldValue.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.float = FloatFieldValue.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.boolean = BoolFieldValue.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.date = DateFieldValue.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.money = MoneyFieldValue.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.select = SelectFieldValue.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.record = RecordValue.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.array = ArrayValue.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.element = Element.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.elementLocator = ElementLocator.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.document = Document.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VariableValue {
    return {
      $type: VariableValue.$type,
      text: isSet(object.text) ? TextFieldValue.fromJSON(object.text) : undefined,
      integer: isSet(object.integer) ? IntegerFieldValue.fromJSON(object.integer) : undefined,
      float: isSet(object.float) ? FloatFieldValue.fromJSON(object.float) : undefined,
      boolean: isSet(object.boolean) ? BoolFieldValue.fromJSON(object.boolean) : undefined,
      date: isSet(object.date) ? DateFieldValue.fromJSON(object.date) : undefined,
      money: isSet(object.money) ? MoneyFieldValue.fromJSON(object.money) : undefined,
      select: isSet(object.select) ? SelectFieldValue.fromJSON(object.select) : undefined,
      record: isSet(object.record) ? RecordValue.fromJSON(object.record) : undefined,
      array: isSet(object.array) ? ArrayValue.fromJSON(object.array) : undefined,
      element: isSet(object.element) ? Element.fromJSON(object.element) : undefined,
      elementLocator: isSet(object.elementLocator) ? ElementLocator.fromJSON(object.elementLocator) : undefined,
      document: isSet(object.document) ? Document.fromJSON(object.document) : undefined,
    };
  },

  toJSON(message: VariableValue): unknown {
    const obj: any = {};
    if (message.text !== undefined) {
      obj.text = TextFieldValue.toJSON(message.text);
    }
    if (message.integer !== undefined) {
      obj.integer = IntegerFieldValue.toJSON(message.integer);
    }
    if (message.float !== undefined) {
      obj.float = FloatFieldValue.toJSON(message.float);
    }
    if (message.boolean !== undefined) {
      obj.boolean = BoolFieldValue.toJSON(message.boolean);
    }
    if (message.date !== undefined) {
      obj.date = DateFieldValue.toJSON(message.date);
    }
    if (message.money !== undefined) {
      obj.money = MoneyFieldValue.toJSON(message.money);
    }
    if (message.select !== undefined) {
      obj.select = SelectFieldValue.toJSON(message.select);
    }
    if (message.record !== undefined) {
      obj.record = RecordValue.toJSON(message.record);
    }
    if (message.array !== undefined) {
      obj.array = ArrayValue.toJSON(message.array);
    }
    if (message.element !== undefined) {
      obj.element = Element.toJSON(message.element);
    }
    if (message.elementLocator !== undefined) {
      obj.elementLocator = ElementLocator.toJSON(message.elementLocator);
    }
    if (message.document !== undefined) {
      obj.document = Document.toJSON(message.document);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VariableValue>, I>>(base?: I): VariableValue {
    return VariableValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VariableValue>, I>>(object: I): VariableValue {
    const message = createBaseVariableValue();
    message.text = (object.text !== undefined && object.text !== null)
      ? TextFieldValue.fromPartial(object.text)
      : undefined;
    message.integer = (object.integer !== undefined && object.integer !== null)
      ? IntegerFieldValue.fromPartial(object.integer)
      : undefined;
    message.float = (object.float !== undefined && object.float !== null)
      ? FloatFieldValue.fromPartial(object.float)
      : undefined;
    message.boolean = (object.boolean !== undefined && object.boolean !== null)
      ? BoolFieldValue.fromPartial(object.boolean)
      : undefined;
    message.date = (object.date !== undefined && object.date !== null)
      ? DateFieldValue.fromPartial(object.date)
      : undefined;
    message.money = (object.money !== undefined && object.money !== null)
      ? MoneyFieldValue.fromPartial(object.money)
      : undefined;
    message.select = (object.select !== undefined && object.select !== null)
      ? SelectFieldValue.fromPartial(object.select)
      : undefined;
    message.record = (object.record !== undefined && object.record !== null)
      ? RecordValue.fromPartial(object.record)
      : undefined;
    message.array = (object.array !== undefined && object.array !== null)
      ? ArrayValue.fromPartial(object.array)
      : undefined;
    message.element = (object.element !== undefined && object.element !== null)
      ? Element.fromPartial(object.element)
      : undefined;
    message.elementLocator = (object.elementLocator !== undefined && object.elementLocator !== null)
      ? ElementLocator.fromPartial(object.elementLocator)
      : undefined;
    message.document = (object.document !== undefined && object.document !== null)
      ? Document.fromPartial(object.document)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(VariableValue.$type, VariableValue);

function createBaseRecordValue(): RecordValue {
  return { $type: "pb.v1alpha1.RecordValue", fields: {} };
}

export const RecordValue = {
  $type: "pb.v1alpha1.RecordValue" as const,

  encode(message: RecordValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.fields || {}).forEach(([key, value]) => {
      RecordValueFieldsEntry.encode(
        { $type: "pb.v1alpha1.RecordValue.FieldsEntry", key: key as any, value },
        writer.uint32(10).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RecordValue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRecordValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = RecordValueFieldsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.fields![entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RecordValue {
    return {
      $type: RecordValue.$type,
      fields: isObject(object.fields)
        ? Object.entries(object.fields).reduce<{ [key: string]: VariableValue }>((acc, [key, value]) => {
          acc[key] = VariableValue.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: RecordValue): unknown {
    const obj: any = {};
    if (message.fields) {
      const entries = Object.entries(message.fields);
      if (entries.length > 0) {
        obj.fields = {};
        entries.forEach(([k, v]) => {
          obj.fields[k] = VariableValue.toJSON(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RecordValue>, I>>(base?: I): RecordValue {
    return RecordValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RecordValue>, I>>(object: I): RecordValue {
    const message = createBaseRecordValue();
    message.fields = Object.entries(object.fields ?? {}).reduce<{ [key: string]: VariableValue }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = VariableValue.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

messageTypeRegistry.set(RecordValue.$type, RecordValue);

function createBaseRecordValueFieldsEntry(): RecordValueFieldsEntry {
  return { $type: "pb.v1alpha1.RecordValue.FieldsEntry", key: "", value: undefined };
}

export const RecordValueFieldsEntry = {
  $type: "pb.v1alpha1.RecordValue.FieldsEntry" as const,

  encode(message: RecordValueFieldsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      VariableValue.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RecordValueFieldsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRecordValueFieldsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = VariableValue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RecordValueFieldsEntry {
    return {
      $type: RecordValueFieldsEntry.$type,
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? VariableValue.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: RecordValueFieldsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = VariableValue.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RecordValueFieldsEntry>, I>>(base?: I): RecordValueFieldsEntry {
    return RecordValueFieldsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RecordValueFieldsEntry>, I>>(object: I): RecordValueFieldsEntry {
    const message = createBaseRecordValueFieldsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? VariableValue.fromPartial(object.value)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(RecordValueFieldsEntry.$type, RecordValueFieldsEntry);

function createBaseArrayValue(): ArrayValue {
  return { $type: "pb.v1alpha1.ArrayValue", entries: [] };
}

export const ArrayValue = {
  $type: "pb.v1alpha1.ArrayValue" as const,

  encode(message: ArrayValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.entries !== undefined && message.entries.length !== 0) {
      for (const v of message.entries) {
        VariableValue.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArrayValue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArrayValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.entries!.push(VariableValue.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ArrayValue {
    return {
      $type: ArrayValue.$type,
      entries: globalThis.Array.isArray(object?.entries)
        ? object.entries.map((e: any) => VariableValue.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ArrayValue): unknown {
    const obj: any = {};
    if (message.entries?.length) {
      obj.entries = message.entries.map((e) => VariableValue.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ArrayValue>, I>>(base?: I): ArrayValue {
    return ArrayValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArrayValue>, I>>(object: I): ArrayValue {
    const message = createBaseArrayValue();
    message.entries = object.entries?.map((e) => VariableValue.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(ArrayValue.$type, ArrayValue);

function createBaseVariable(): Variable {
  return { $type: "pb.v1alpha1.Variable", name: "", type: undefined, value: undefined };
}

export const Variable = {
  $type: "pb.v1alpha1.Variable" as const,

  encode(message: Variable, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== undefined && message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.type !== undefined) {
      VariableType.encode(message.type, writer.uint32(18).fork()).ldelim();
    }
    if (message.value !== undefined) {
      VariableValue.encode(message.value, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Variable {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVariable();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.type = VariableType.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.value = VariableValue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Variable {
    return {
      $type: Variable.$type,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      type: isSet(object.type) ? VariableType.fromJSON(object.type) : undefined,
      value: isSet(object.value) ? VariableValue.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: Variable): unknown {
    const obj: any = {};
    if (message.name !== undefined && message.name !== "") {
      obj.name = message.name;
    }
    if (message.type !== undefined) {
      obj.type = VariableType.toJSON(message.type);
    }
    if (message.value !== undefined) {
      obj.value = VariableValue.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Variable>, I>>(base?: I): Variable {
    return Variable.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Variable>, I>>(object: I): Variable {
    const message = createBaseVariable();
    message.name = object.name ?? "";
    message.type = (object.type !== undefined && object.type !== null)
      ? VariableType.fromPartial(object.type)
      : undefined;
    message.value = (object.value !== undefined && object.value !== null)
      ? VariableValue.fromPartial(object.value)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(Variable.$type, Variable);

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P> | "$type">]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
