import { updateCookiePreferencesAction } from '../redux/actions/user.action';
import store from '../redux/store';
import '../styles/cookieConsentManager.css'; // don't remove this import as this is responsible for styling the cookie banner
import { init } from '../cookieConsentManager'; // don't remove this import as this is responsible for showing the cookie banner
import { STORAGE_KEYS } from '../utils/constants';

export interface CookiePreferences {
  analytics: boolean;
  advertising: boolean;
  necessary: boolean;
}

class CookieConsentService {
  private static _instance: CookieConsentService;
  private _cookieBanner: any;
  lastUpdatedCookiePreferences: CookiePreferences | null = null;

  static getInstance(): CookieConsentService {
    if (!this._instance) {
      this._instance = new CookieConsentService();
      this._instance.lastUpdatedCookiePreferences =
        this._instance.getCookiePreferences();
    }
    return this._instance;
  }

  updateCookiePreferences = (preferences: CookiePreferences) => {
    this.lastUpdatedCookiePreferences = preferences;
  };

  getCookiePreferences = (): CookiePreferences => {
    const analyticsCookieConsent =
      localStorage.getItem(STORAGE_KEYS.COOKIE_CONSENT_ANALYTICS) === 'true';
    const advertisingCookieConsent =
      localStorage.getItem(STORAGE_KEYS.COOKIE_CONSENT_ADVERTISING) === 'true';

    return {
      necessary: true, // will always be true
      analytics: analyticsCookieConsent,
      advertising: advertisingCookieConsent,
    };
  };

  dispatchCookiePreferences = () => {
    store.dispatch(updateCookiePreferencesAction());
  };

  init(): void {
    if (!this._cookieBanner) {
      this._cookieBanner = init();
    }

    this._cookieBanner.updateCookieBannerConfig({
      background: {
        showBackground: true,
      },
      cookieIcon: {
        position: 'bottomRight',
      },
      cookieTypes: [
        {
          id: 'necessary',
          name: 'Necessary',
          description:
            '<p>These cookies are necessary for the website to function properly and cannot be switched off. They help with things like logging in and setting your privacy preferences.</p>',
          required: true,
        },
        {
          id: 'analytics',
          name: 'Analytical',
          description:
            '<p>These cookies help us improve the site by tracking which pages are most popular and how visitors move around the site.</p>',
          onAccept: () => this.dispatchCookiePreferences(),
          onReject: () => this.dispatchCookiePreferences(),
        },
        {
          id: 'advertising',
          name: 'Advertising',
          description:
            '<p>These cookies provide extra features and personalization to improve your experience. They may be set by us or by partners whose services we use.</p>',
          onAccept: () => this.dispatchCookiePreferences(),
          onReject: () => this.dispatchCookiePreferences(),
        },
      ],
      onAcceptAll: () => this.dispatchCookiePreferences(),
      onRejectAll: () => this.dispatchCookiePreferences(),
      text: {
        banner: {
          description:
            '<p>We use cookies on our site to enhance your user experience, provide personalized content, and analyze our traffic.</p>',
          acceptAllButtonText: 'Accept all',
          acceptAllButtonAccessibleLabel: 'Accept all cookies',
          rejectNonEssentialButtonText: 'Reject non-essential',
          rejectNonEssentialButtonAccessibleLabel: 'Reject non-essential',
          preferencesButtonText: 'Preferences',
          preferencesButtonAccessibleLabel: 'Toggle preferences',
        },
        preferences: {
          title: 'Customize your cookie preferences',
          description:
            '<p>We respect your right to privacy. You can choose not to allow some types of cookies. Your cookie preferences will apply across our website.</p>',
          creditLinkText: 'Get this banner for free',
          creditLinkAccessibleLabel: 'Get this banner for free',
        },
      },
    });
  }

  destroyCookieBanner() {
    if (this._cookieBanner) {
      this._cookieBanner.destroyCookieBanner();
    }
  }
}

export const cookieConsentService = CookieConsentService.getInstance();
