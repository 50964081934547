import React from 'react';
import { Box, Tooltip } from '@mui/material';
import OrbyTypography from '../typography/OrbyTypography';
import OrbyColorPalette from '../colors/ColorPalette';

const WorkflowVariableValue = (props: {
  value: string;
  enableTooltip?: boolean;
}) => {
  const { value, enableTooltip } = props;

  if (enableTooltip) {
    return (
      <Tooltip
        title={<div>{value}</div>}
        arrow
        PopperProps={{
          sx: {
            '& .MuiTooltip-arrow': { color: 'white' },
            '& .MuiTooltip-tooltip': {
              color: OrbyColorPalette['grey-700'],
              backgroundColor: 'white',
              borderRadius: '8px',
              boxShadow:
                '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
            },
          },
          modifiers: [{ name: 'offset', options: { offset: [0, -8] } }],
        }}
      >
        <Box>
          <VariableValue value={value} />
        </Box>
      </Tooltip>
    );
  }

  return <VariableValue value={value} />;
};

const VariableValue = (props: { value: string }) => {
  return (
    <OrbyTypography
      color={OrbyColorPalette['grey-700']}
      sx={{
        wordWrap: 'break-word',
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '400px',
      }}
    >
      {props.value}
    </OrbyTypography>
  );
};

export default WorkflowVariableValue;
