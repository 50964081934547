import produce from 'immer';
import {
  FeatureFlag,
  IsFeatureFlagEnabledResponse,
} from 'protos/pb/v1alpha2/feature_flag_service';
import { Reducer } from 'redux';
import { FeatureFlagActionType } from '../actions/actions.constants';
import { DataLoadingStatus } from '../../utils/constants';

export interface FeatureFlagsState {
  featureFlags: FeatureFlag[];
  totalSize?: number;
  loadingFeatureFlagsListStatus: DataLoadingStatus;
  listFeatureFlagsError?: Error;

  createdFeatureFlag?: FeatureFlag;
  updatedFeatureFlag?: FeatureFlag;

  processingFeatureFlagStatus: DataLoadingStatus;
  processFeatureFlagError?: Error;

  selectedFeatureFlag?: FeatureFlag;
  selectedFeatureFlagLoadingStatus: DataLoadingStatus;
  selectedFeatureFlagError?: Error;

  featureFlagsForOrgAndUser?: { [name: string]: boolean };
  featureFlagsForOrgAndUserLoadingStatus: DataLoadingStatus;
  featureFlagsForOrgAndUserError?: Error;

  isFeatureFlagEnabled?: boolean;
  isFeatureFlagEnabledLoadingStatus: DataLoadingStatus;
  isFeatureFlagEnabledError?: Error;
}

const initialState: FeatureFlagsState = {
  featureFlags: [],
  loadingFeatureFlagsListStatus: DataLoadingStatus.INITIAL,
  processingFeatureFlagStatus: DataLoadingStatus.INITIAL,
  selectedFeatureFlagLoadingStatus: DataLoadingStatus.INITIAL,
  featureFlagsForOrgAndUserLoadingStatus: DataLoadingStatus.INITIAL,
  isFeatureFlagEnabledLoadingStatus: DataLoadingStatus.INITIAL,
};

export const featureFlagsReducer: Reducer<FeatureFlagsState> = (
  state: FeatureFlagsState = initialState,
  action: any,
) =>
  produce(state, (draft: FeatureFlagsState) => {
    switch (action.type) {
      case FeatureFlagActionType.LIST_FEATURE_FLAGS:
        draft.loadingFeatureFlagsListStatus = DataLoadingStatus.LOADING;
        break;
      case FeatureFlagActionType.LIST_FEATURE_FLAGS_COMPLETED: {
        if (action.refresh) {
          draft.featureFlags = action.payload;
        } else {
          draft.featureFlags.push(...action.payload);
        }
        draft.totalSize = action.totalSize;
        draft.loadingFeatureFlagsListStatus = DataLoadingStatus.LOADED;
        break;
      }
      case FeatureFlagActionType.LIST_FEATURE_FLAGS_ERROR: {
        draft.loadingFeatureFlagsListStatus = DataLoadingStatus.ERROR;
        draft.listFeatureFlagsError = action.payload;
        break;
      }
      case FeatureFlagActionType.CREATE_FEATURE_FLAG:
        draft.processingFeatureFlagStatus = DataLoadingStatus.LOADING;
        break;
      case FeatureFlagActionType.CREATE_FEATURE_FLAG_COMPLETED: {
        draft.createdFeatureFlag = action.payload;
        draft.processingFeatureFlagStatus = DataLoadingStatus.LOADED;
        break;
      }
      case FeatureFlagActionType.CREATE_FEATURE_FLAG_ERROR: {
        draft.processingFeatureFlagStatus = DataLoadingStatus.ERROR;
        draft.processFeatureFlagError = action.payload;
        break;
      }
      case FeatureFlagActionType.UPDATE_FEATURE_FLAG:
        draft.processingFeatureFlagStatus = DataLoadingStatus.LOADING;
        break;
      case FeatureFlagActionType.UPDATE_FEATURE_FLAG_COMPLETED: {
        draft.updatedFeatureFlag = action.payload;
        draft.processingFeatureFlagStatus = DataLoadingStatus.LOADED;
        break;
      }
      case FeatureFlagActionType.UPDATE_FEATURE_FLAG_ERROR: {
        draft.processingFeatureFlagStatus = DataLoadingStatus.ERROR;
        draft.processFeatureFlagError = action.payload;
        break;
      }
      case FeatureFlagActionType.DELETE_FEATURE_FLAG:
        draft.processingFeatureFlagStatus = DataLoadingStatus.LOADING;
        break;
      case FeatureFlagActionType.DELETE_FEATURE_FLAG_COMPLETED: {
        draft.featureFlags = draft.featureFlags.filter(
          (ff) => ff.id !== action.payload,
        );
        draft.processingFeatureFlagStatus = DataLoadingStatus.LOADED;
        break;
      }
      case FeatureFlagActionType.DELETE_FEATURE_FLAG_ERROR: {
        draft.processingFeatureFlagStatus = DataLoadingStatus.ERROR;
        draft.processFeatureFlagError = action.payload;
        break;
      }
      case FeatureFlagActionType.GET_FEATURE_FLAG:
        draft.selectedFeatureFlagLoadingStatus = DataLoadingStatus.LOADING;
        break;
      case FeatureFlagActionType.GET_FEATURE_FLAG_COMPLETED: {
        draft.selectedFeatureFlag = action.payload;
        draft.selectedFeatureFlagLoadingStatus = DataLoadingStatus.LOADED;
        break;
      }
      case FeatureFlagActionType.GET_FEATURE_FLAG_ERROR: {
        draft.selectedFeatureFlagLoadingStatus = DataLoadingStatus.ERROR;
        draft.selectedFeatureFlagError = action.payload;
        break;
      }
      case FeatureFlagActionType.SET_SELECTED_FEATURE_FLAG: {
        draft.selectedFeatureFlag = action.payload;
        break;
      }
      case FeatureFlagActionType.CLEAR: {
        draft.selectedFeatureFlag = undefined;
        draft.selectedFeatureFlagError = undefined;
        break;
      }
      case FeatureFlagActionType.GET_FEATURE_FLAGS_FOR_ORG_AND_USER:
        draft.featureFlagsForOrgAndUserError = undefined;
        draft.featureFlagsForOrgAndUserLoadingStatus =
          DataLoadingStatus.LOADING;
        break;
      case FeatureFlagActionType.GET_FEATURE_FLAGS_FOR_ORG_AND_USER_COMPLETED: {
        draft.featureFlagsForOrgAndUser = action.payload;
        draft.featureFlagsForOrgAndUserLoadingStatus = DataLoadingStatus.LOADED;
        break;
      }
      case FeatureFlagActionType.GET_FEATURE_FLAGS_FOR_ORG_AND_USER_ERROR: {
        draft.featureFlagsForOrgAndUserLoadingStatus = DataLoadingStatus.ERROR;
        draft.featureFlagsForOrgAndUserError = action.payload;
        break;
      }
      case FeatureFlagActionType.UPDATE_FEATURE_FLAGS_LOADER_FOR_ORG_AND_USER: {
        draft.featureFlagsForOrgAndUserLoadingStatus = action.payload
          ? DataLoadingStatus.LOADING
          : DataLoadingStatus.LOADED;
        break;
      }
      case FeatureFlagActionType.IS_FEATURE_FLAG_ENABLED:
        draft.isFeatureFlagEnabledLoadingStatus = DataLoadingStatus.LOADING;
        break;
      case FeatureFlagActionType.IS_FEATURE_FLAG_ENABLED_COMPLETED: {
        draft.isFeatureFlagEnabled = (
          action.payload as IsFeatureFlagEnabledResponse
        ).enabled;
        draft.isFeatureFlagEnabledLoadingStatus = DataLoadingStatus.LOADED;
        break;
      }
      case FeatureFlagActionType.IS_FEATURE_FLAG_ENABLED_ERROR: {
        draft.isFeatureFlagEnabledLoadingStatus = DataLoadingStatus.ERROR;
        draft.isFeatureFlagEnabledError = action.payload;
        break;
      }
      default:
        break;
    }
  });
