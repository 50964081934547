export type FeatureFlagFormValues = {
  name: string;
  description: string;
  enabled: boolean;
  enabledUsers: string[];
  blockedUsers: string[];
  enabledOrgs: string[];
  blockedOrgs: string[];
  enabledWorkflows: string[];
  blockedWorkflows: string[];
};

// function to check if a feature flag is enabled
export const isFeatureFlagEnabled = (
  name: string,
  featureFlags?: { [name: string]: boolean },
): boolean => {
  return featureFlags ? featureFlags[name.toLowerCase()] : false;
};

// Check if arrays are different
export const areArraysDifferent = (
  sourceArray: string[],
  targetArray: string[],
): boolean => {
  if (sourceArray.length !== targetArray.length) return true;
  const sortedSourceArray = [...sourceArray].sort();
  const sortedTargetArray = [...targetArray].sort();
  return sortedSourceArray.some(
    (value, index) => value !== sortedTargetArray[index],
  );
};

// Helper function to format array values
export const formatArrayValue = (arr: string[]) =>
  arr.length ? arr.join(', ') : '(none)';
