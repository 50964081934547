/* eslint-disable */
// @ts-nocheck
import _m0 from "protobufjs/minimal";
import { messageTypeRegistry } from "../../typeRegistry";

export const protobufPackage = "pb.v1alpha1";

/**
 * MicrosoftApplicationConfig is the configuration for the Microsoft
 * application in our database.
 */
export interface MicrosoftApplicationConfig {
  $type?: "pb.v1alpha1.MicrosoftApplicationConfig";
  /**
   * Id of config in our database. This will point to the client id
   * and client secret of the corresponding Microsoft application.
   */
  id?:
    | string
    | undefined;
  /** Client id of the Microsoft application. */
  clientId?:
    | string
    | undefined;
  /** Client secret of the Microsoft application. */
  clientSecret?:
    | string
    | undefined;
  /** Name of the Microsoft application. */
  name?:
    | string
    | undefined;
  /** Application id of the Microsoft application. */
  applicationId?: string | undefined;
}

function createBaseMicrosoftApplicationConfig(): MicrosoftApplicationConfig {
  return {
    $type: "pb.v1alpha1.MicrosoftApplicationConfig",
    id: "",
    clientId: "",
    clientSecret: "",
    name: "",
    applicationId: "",
  };
}

export const MicrosoftApplicationConfig = {
  $type: "pb.v1alpha1.MicrosoftApplicationConfig" as const,

  encode(message: MicrosoftApplicationConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.clientId !== undefined && message.clientId !== "") {
      writer.uint32(18).string(message.clientId);
    }
    if (message.clientSecret !== undefined && message.clientSecret !== "") {
      writer.uint32(26).string(message.clientSecret);
    }
    if (message.name !== undefined && message.name !== "") {
      writer.uint32(34).string(message.name);
    }
    if (message.applicationId !== undefined && message.applicationId !== "") {
      writer.uint32(42).string(message.applicationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MicrosoftApplicationConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMicrosoftApplicationConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clientId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.clientSecret = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.name = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.applicationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MicrosoftApplicationConfig {
    return {
      $type: MicrosoftApplicationConfig.$type,
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      clientId: isSet(object.clientId) ? globalThis.String(object.clientId) : "",
      clientSecret: isSet(object.clientSecret) ? globalThis.String(object.clientSecret) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      applicationId: isSet(object.applicationId) ? globalThis.String(object.applicationId) : "",
    };
  },

  toJSON(message: MicrosoftApplicationConfig): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    if (message.clientId !== undefined && message.clientId !== "") {
      obj.clientId = message.clientId;
    }
    if (message.clientSecret !== undefined && message.clientSecret !== "") {
      obj.clientSecret = message.clientSecret;
    }
    if (message.name !== undefined && message.name !== "") {
      obj.name = message.name;
    }
    if (message.applicationId !== undefined && message.applicationId !== "") {
      obj.applicationId = message.applicationId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MicrosoftApplicationConfig>, I>>(base?: I): MicrosoftApplicationConfig {
    return MicrosoftApplicationConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MicrosoftApplicationConfig>, I>>(object: I): MicrosoftApplicationConfig {
    const message = createBaseMicrosoftApplicationConfig();
    message.id = object.id ?? "";
    message.clientId = object.clientId ?? "";
    message.clientSecret = object.clientSecret ?? "";
    message.name = object.name ?? "";
    message.applicationId = object.applicationId ?? "";
    return message;
  },
};

messageTypeRegistry.set(MicrosoftApplicationConfig.$type, MicrosoftApplicationConfig);

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P> | "$type">]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
