import {
  GetEmailLabelsRequest,
  GetEmailLabelsResponse,
  GetGmailLabelsRequest,
  GetGmailLabelsResponse,
  GetOnedriveFilesRequest,
  GetOnedriveFilesResponse,
  GetSignedGCSUriRequest,
  GetSignedGCSUriResponse,
  UtilityClientImpl,
} from 'protos/pb/v1alpha2/utility_service';
import {
  GetScheduleTasksRequest,
  GetScheduleTasksResponse,
} from 'protos/pb/v1alpha2/utility_service';
import { storageService } from './StorageService';
import { getMetaData, rpcWithErrorHandling } from '../utils/RpcUtills';

export class UtilityService {
  private static instance: UtilityService;
  private static client = new UtilityClientImpl(rpcWithErrorHandling);

  public static getInstance(): UtilityService {
    if (!this.instance) {
      this.instance = new UtilityService();
    }
    return this.instance;
  }

  async getGmailLabels(
    req: GetGmailLabelsRequest,
  ): Promise<{ response?: GetGmailLabelsResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await UtilityService.client.GetGmailLabels(
        req,
        getMetaData({ authorization }),
      );
      return {
        response,
      };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async getEmailLabels(
    req: GetEmailLabelsRequest,
  ): Promise<{ response?: GetEmailLabelsResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await UtilityService.client.GetEmailLabels(
        req,
        getMetaData({ authorization }),
      );
      return {
        response,
      };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async getScheduledTasks(
    req: GetScheduleTasksRequest,
  ): Promise<{ response?: GetScheduleTasksResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await UtilityService.client.GetScheduleTasks(
        req,
        getMetaData({ authorization }),
      );
      return {
        response,
      };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async getSignedGCSUri(
    req: GetSignedGCSUriRequest,
  ): Promise<{ response?: GetSignedGCSUriResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await UtilityService.client.GetSignedGCSUri(
        req,
        getMetaData({ authorization }),
      );
      return {
        response,
      };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async getOnedriveFiles(
    req: GetOnedriveFilesRequest,
  ): Promise<{ response?: GetOnedriveFilesResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await UtilityService.client.GetOnedriveFiles(
        req,
        getMetaData({ authorization }),
      );
      return {
        response,
      };
    } catch (error) {
      return { error: error as Error };
    }
  }
}

export const utilityService = UtilityService.getInstance();
