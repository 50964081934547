import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  CreateFeatureFlagRequest,
  DeleteFeatureFlagRequest,
  ListFeatureFlagsRequest,
  UpdateFeatureFlagRequest,
  GetFeatureFlagRequest,
  IsFeatureFlagEnabledRequest,
} from 'protos/pb/v1alpha2/feature_flag_service';
import { FeatureFlagActionType } from '../actions/actions.constants';
import { featureFlagService } from '../../services/FeatureFlagService';
import {
  createFeatureFlagCompletedAction,
  createFeatureFlagErrorAction,
  deleteFeatureFlagCompletedAction,
  deleteFeatureFlagErrorAction,
  getFeatureFlagCompletedAction,
  getFeatureFlagErrorAction,
  getFeatureFlagsForOrgAndUseCompletedAction,
  getFeatureFlagsForOrgAndUserErrorAction,
  isFeatureFlagEnabledCompletedAction,
  isFeatureFlagEnabledErrorAction,
  listFeatureFlagsCompletedAction,
  listFeatureFlagsErrorAction,
  updateFeatureFlagCompletedAction,
  updateFeatureFlagErrorAction,
} from '../actions/feature_flags.action';
import { GetFeatureFlagsForOrgAndUserRequest } from 'protos/pb/v1alpha2/feature_flag_service';

export function* listFeatureFlagsSaga(data: {
  type: FeatureFlagActionType;
  payload: ListFeatureFlagsRequest;
  refresh: boolean;
}): any {
  try {
    const { response, error } = yield call(
      featureFlagService.getFeatureFlags,
      data.payload,
    );
    if (response) {
      yield put(
        listFeatureFlagsCompletedAction(
          response.featureFlags,
          response.totalSize,
          data.refresh,
        ),
      );
    } else {
      yield put(listFeatureFlagsErrorAction(error));
    }
  } catch (error) {
    yield put(listFeatureFlagsErrorAction(error as Error));
  }
}

export function* createFeatureFlagSaga(data: {
  type: FeatureFlagActionType;
  payload: CreateFeatureFlagRequest;
}): any {
  try {
    const { response, error } = yield call(
      featureFlagService.createFeatureFlag,
      data.payload,
    );
    if (response) {
      yield put(createFeatureFlagCompletedAction(response));
    } else {
      yield put(createFeatureFlagErrorAction(error));
    }
  } catch (error) {
    yield put(createFeatureFlagErrorAction(error as Error));
  }
}

export function* updateFeatureFlagSaga(data: {
  type: FeatureFlagActionType;
  payload: UpdateFeatureFlagRequest;
}): any {
  try {
    const { response, error } = yield call(
      featureFlagService.updateFeatureFlag,
      data.payload,
    );
    if (response) {
      yield put(updateFeatureFlagCompletedAction(response));
    } else {
      yield put(updateFeatureFlagErrorAction(error));
    }
  } catch (error) {
    yield put(updateFeatureFlagErrorAction(error as Error));
  }
}

export function* deleteFeatureFlagSaga(data: {
  type: FeatureFlagActionType;
  payload: DeleteFeatureFlagRequest;
}): any {
  try {
    const { response, error } = yield call(
      featureFlagService.deleteFeatureFlag,
      data.payload,
    );
    if (response) {
      yield put(deleteFeatureFlagCompletedAction());
    } else {
      yield put(deleteFeatureFlagErrorAction(error));
    }
  } catch (error) {
    yield put(deleteFeatureFlagErrorAction(error as Error));
  }
}

export function* getFeatureFlagSaga(data: {
  type: FeatureFlagActionType;
  payload: GetFeatureFlagRequest;
}): any {
  try {
    const { response, error } = yield call(
      featureFlagService.getFeatureFlag,
      data.payload,
    );
    if (response) {
      yield put(getFeatureFlagCompletedAction(response));
    } else {
      yield put(getFeatureFlagErrorAction(error));
    }
  } catch (error) {
    yield put(getFeatureFlagErrorAction(error as Error));
  }
}

export function* GetFeatureFlagsForOrgAndUserSaga(data: {
  type: FeatureFlagActionType;
  payload: GetFeatureFlagsForOrgAndUserRequest;
}): any {
  try {
    const { response, error } = yield call(
      featureFlagService.getFeatureFlagsForOrgAndUser,
      data.payload,
    );
    if (response) {
      yield put(getFeatureFlagsForOrgAndUseCompletedAction(response));
    } else {
      yield put(getFeatureFlagsForOrgAndUserErrorAction(error));
    }
  } catch (error) {
    yield put(getFeatureFlagsForOrgAndUserErrorAction(error as Error));
  }
}

export function* IsFeatureFlagEnabledSaga(data: {
  type: FeatureFlagActionType;
  payload: IsFeatureFlagEnabledRequest;
}): any {
  try {
    const { response, error } = yield call(
      featureFlagService.isFeatureFlagEnabled,
      data.payload,
    );
    if (response) {
      yield put(isFeatureFlagEnabledCompletedAction(response));
    } else {
      yield put(isFeatureFlagEnabledErrorAction(error));
    }
  } catch (error) {
    yield put(isFeatureFlagEnabledErrorAction(error as Error));
  }
}

function* featureFlagsSaga() {
  yield all([
    takeLatest(FeatureFlagActionType.LIST_FEATURE_FLAGS, listFeatureFlagsSaga),
    takeLatest(
      FeatureFlagActionType.CREATE_FEATURE_FLAG,
      createFeatureFlagSaga,
    ),
    takeLatest(
      FeatureFlagActionType.UPDATE_FEATURE_FLAG,
      updateFeatureFlagSaga,
    ),
    takeLatest(
      FeatureFlagActionType.DELETE_FEATURE_FLAG,
      deleteFeatureFlagSaga,
    ),
    takeLatest(FeatureFlagActionType.GET_FEATURE_FLAG, getFeatureFlagSaga),
    takeLatest(
      FeatureFlagActionType.GET_FEATURE_FLAGS_FOR_ORG_AND_USER,
      GetFeatureFlagsForOrgAndUserSaga,
    ),
    takeLatest(
      FeatureFlagActionType.IS_FEATURE_FLAG_ENABLED,
      IsFeatureFlagEnabledSaga,
    ),
  ]);
}

export default featureFlagsSaga;
